import { Route, Routes } from 'react-router-dom';
    import Reports from './Reports';
    
    export const ReportsRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<Reports />} />
        </Routes>
      );
    };
    