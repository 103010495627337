import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { TableLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Colors, Layout } from '../../../../../../components/style';
import { Popup } from '../../../../../../components/templates/Popup';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import { Utils } from '../../../../../../constants';
import {
  instructionAssets,
  processingKeys,
} from '../../../../features/processing/controllers/variables';
import SideloadSnapshot from '../../../organisms/SideloadSnapshot/SideloadSnapshot';
import {
  ConfirmationButtons,
  Illustration,
  Subtitle,
  Title,
} from './Instruction.components';
import {
  Container,
  InstructionText,
  StatusIndicator,
} from './Instruction.style';

const Instruction = ({
  isLoading,
  details,
  headerElement,
  updatedConfirm,
  confirmationAction,
  showGallery = Utils.emptyFunction,
}) => {
  const statusStyle = details[processingKeys.situation.styleStatus];
  return (
    <Container className="popup-container">
      <Popup
        iPad
        fullWidth
        title={details[processingKeys.alias]}
        element={headerElement}
      >
        <StatusIndicator
          color={
            instructionAssets[statusStyle]?.[processingKeys.situation.color] ??
            Colors.Neutral._300
          }
        />
        <Content className="instruction-content">
          {isLoading ? (
            <TableLoader />
          ) : (
            <Flex column h justify="between">
              <div />
              {/* text and illustration */}
              <Flex
                gap="_M"
                align="start"
                justify={'between'}
                w
                padding={`${Layout.Spacing._2XS} ${Layout.Spacing._S} 0`}
              >
                <InstructionText>
                  <Flex column gap="_S">
                    <Title
                      text={details[processingKeys.situation.title] ?? 'Error'}
                    />
                    <Subtitle
                      text={
                        details[processingKeys.situation.description] ??
                        'There has been an error scanning this parcel. Please contact the office if this keeps happening.'
                      }
                    />
                    {confirmationAction && (
                      <ConfirmationButtons
                        details={details}
                        confirmationAction={confirmationAction}
                        updatedConfirm={updatedConfirm}
                      />
                    )}
                  </Flex>
                </InstructionText>
                <Illustration
                  instructionAssets={instructionAssets}
                  statusStyle={statusStyle}
                />
              </Flex>
              <SideloadSnapshot
                details={details[processingKeys.snapshotDetails]}
                image={details[processingKeys.image]}
                showGallery={showGallery(details[processingKeys.id])}
              />
            </Flex>
          )}
        </Content>
      </Popup>
    </Container>
  );
};
export default Instruction;
