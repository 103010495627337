import { isEqual } from '../../../lib/js';

export const matchImagesToRows = (rowKey, imageKey) => images => rows => {
  const rowsWithImage = rows.map((row, index) => {
    const { [rowKey]: entryID } = row;

    const { [imageKey]: imageID } = images[index] ?? {};

    if (imageID != null && entryID === imageID)
      return {
        ...row,
        // this key needs to be dynamic to match the key of the row
        photos: images[index].photos,
      };

    const imagesIndex = images.findIndex(imgs => imgs[imageKey] === entryID);
    if (imagesIndex === -1) return { ...row, photos: [] };

    return {
      ...row,
      // this key needs to be dynamic to match the key of the row
      photos: images[imagesIndex].photos,
    };
  });

  return rowsWithImage;
};

const hasClass = (element, className) =>
  Array.from(element.classList).includes(className);

export const getClickedRow = element => {
  while (element && !hasClass(element, 'table-row'))
    element = element.parentElement;

  return element;
};

export const getRowHeight = grid =>
  grid?.rowHeight ?? grid.gridAutoRows.replace(' 0px', '');

export const someDropdownIsEmpty = params =>
  Object.values(params).some(filter => isEqual(filter, []));

export const displayIfHasCount =
  (Component, { countKey }) =>
  (item, rowIndex, props) => {
    if (item[countKey] === 0) return <></>;
    return Component(item, rowIndex, props);
  };
