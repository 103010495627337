import { parseStringModifiers } from '../../../utils/utils';
import { NoData } from '../../atoms/Icon/Icon.options';

export const noResults = {
  description: parseStringModifiers(`You can use this space to add 
        attachment about the product`),
  icon: NoData.Attachments,
};

export const displayType = {
  card: 'card',
  unboxed: 'unboxed',
};
