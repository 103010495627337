import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Icon } from '../../atoms';
import { alignment } from '../../atoms/Flex/Flex.style';
import Text from '../../atoms/Text/Text';
import { Colors, Effects, FontKeys, Layout, Size } from '../../style';

export const KeyColor = styled.div`
  min-width: 12px;
  width: 12px;
  min-height: 12px;
  height: 12px;
  border-radius: ${Effects.Border_Radius._3XS};
  background: ${props => props.color};
`;

export const WholeKey = styled.div`
  display: flex;
  justify-content: ${props => alignment[props.justify]};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
  gap: ${Layout.Spacing._3XS};
  &:hover {
    .click-icon {
      visibility: visible;
    }
  }
`;

export const Key = styled.div`
  gap: ${Layout.Spacing._2XS};
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
  ${props => (props.column ? 'width: 100%' : '')};
  ${Styles.flex};
  align-items: flex-start;
`;

const ClickIconCSS = styled.div`
  visibility: hidden;
  margin-left: auto;
`;

export const ClickIcon = ({ name }) => {
  return (
    <ClickIconCSS className="click-icon">
      <Icon name={name} color={Colors.Neutral._400} size={Size.Icon._M} />
    </ClickIconCSS>
  );
};

export const KeyText = ({ text, bold, className }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._S}
    weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
    whiteSpace="nowrap"
    className={className}
  />
);
