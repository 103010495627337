import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Input, RadioButton } from '../../molecules';
import { Colors, FontKeys } from '../../style';

export const ModalText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._900}
    />
  );
};

export const Options = ({ options, selected, setSelected }) => {
  return options.map((option, i) => {
    const isSelected = selected.value === option.id;
    return (
      <Flex w gap="_XS" column>
        <RadioButton
          {...option}
          size="_L"
          key={i}
          bold={isSelected}
          selected={isSelected}
          setSelected={setSelected}
        />
        {isSelected && option.hasNote && (
          <Input name={'note'} placeholder={'Add a note about this choice'} />
        )}
      </Flex>
    );
  });
};
