import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { cloneDeep } from '../../../../../lib/js';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useCreateUser } from '../api/createUser';
import { useGetBrandServices } from '../api/getBrandServices';
import { useUpdateUserEmail } from '../api/updateUserEmail';
import { useUpdateUserName } from '../api/updateUserName';
import { useUpdateUserRoles } from '../api/updateUserRoles';
import { WriteUserLayout } from '../components/WriteUserLayout';
import {
  usersKeys,
  writeUserFields,
  writeUserInitialValues,
  writeUserValidation,
} from './variables';

const AddUser = ({ user, showUserList }) => {
  const [services, setServices] = useState([]);

  // all enabled is superflous now given that that no service is deactivated, just set to no access instead
  const [allEnabled, setAllEnabled] = useState(false);
  const [servicesCache, setServicesCache] = useState();
  const [allServicesRole, setAllServicesRole] = useState();

  const [somethingIsLoading, setSomethingIsLoading] = useState(false);
  const [somethingIsError, setSomethingIsError] = useState(false);

  const [triedSaving, setTriedSaving] = useState(false);

  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const isEditing = user != null;
  const isAdding = user == null;

  const {
    data: { services: brandServices, defaultRoles },
    isLoading: brandServicesIsLoading,
    isError: brandServicesIsError,
    error: brandServicesError,
  } = useGetBrandServices();

  const {
    body: createBody,
    isLoading: createIsLoading,
    isError: createIsError,
    error: createError,
    doCreateUser,
  } = useCreateUser();

  const {
    body: updateRolesBody,
    isLoading: updateRolesIsLoading,
    isError: updateRolesIsError,
    error: updateRolesError,
    doUpdateUserRoles,
  } = useUpdateUserRoles({ id: user?.[usersKeys.id] });

  const {
    body: usernameBody,
    isLoading: usernameIsLoading,
    isError: usernameIsError,
    error: usernameError,
    doUpdateUserName,
  } = useUpdateUserName({ id: user?.[usersKeys.id] });

  const {
    body: emailBody,
    isLoading: emailIsLoading,
    isError: emailIsError,
    error: emailError,
    doUpdateUserEmail,
  } = useUpdateUserEmail({ id: user?.[usersKeys.id] });

  useEffect(() => {
    if (brandServices == null || brandServicesIsLoading) return;
    if (brandServicesIsError) {
      showUserList();
      showSnackbarError(brandServicesError);
      return;
    }
    setServices(brandServices);
  }, [brandServices]);

  useEffect(() => {
    if (
      brandServices == null ||
      brandServices.length === 0 ||
      brandServicesIsLoading ||
      isAdding
    )
      return;

    const userRolesMap = {};
    user.roles.forEach(role => {
      userRolesMap[role.service_id] = role;
    });

    const clientBrandServices = brandServices.map(service => {
      const cbService = cloneDeep(service);
      const userRole = userRolesMap[service.id];
      const activeRole = service.roles.find(role => role.id === userRole?.id);
      cbService.selectedRole = activeRole;
      return cbService;
    });

    // all enabled is superflous now given that that no service is deactivated, just set to no access instead
    const allEnabled = clientBrandServices.every(s => s.is_enabled);
    const hasOnlyOneRole = clientBrandServices.every(
      s =>
        s.selectedRole &&
        s.selectedRole?.key === clientBrandServices[0]?.selectedRole?.key
    );

    if (hasOnlyOneRole && allEnabled) {
      setAllServicesRole(clientBrandServices[0].selectedRole);
      setAllEnabled(allEnabled);
    }

    setServices(clientBrandServices);
    setServicesCache(clientBrandServices);
  }, [brandServices]);

  useEffect(() => {
    if (createBody == null || createIsLoading) return;
    if (createIsError) {
      showSnackbarError(createError);
    }
    showSnackbarSuccess('Added user');
    showUserList();
  }, [createBody, createIsLoading, createIsError]);

  useEffect(() => {
    if (!triedSaving || somethingIsLoading) return;
    if (somethingIsError) {
      const error_msg = createIsError
        ? createError
        : updateRolesIsError
        ? updateRolesError
        : usernameIsError
        ? usernameError
        : emailIsError
        ? emailError
        : null;
      showSnackbarError(error_msg);
    }
    showSnackbarSuccess('Updated user');
    showUserList();
  }, [triedSaving, somethingIsLoading, somethingIsError]);

  useEffect(() => {
    const somethingIsLoading =
      createIsLoading ||
      updateRolesIsLoading ||
      usernameIsLoading ||
      emailIsLoading;

    setSomethingIsLoading(somethingIsLoading);
  }, [
    createIsLoading,
    updateRolesIsLoading,
    usernameIsLoading,
    emailIsLoading,
  ]);

  useEffect(() => {
    const somethingIsError =
      createIsError || updateRolesIsError || usernameIsError || emailIsError;
    setSomethingIsError(somethingIsError);
  }, [createIsError, updateRolesIsError, usernameIsError, emailIsError]);

  const onSubmit = (values, actions) =>
    isEditing ? saveUser(values, actions) : addUser(values, actions);

  const saveUser = values => {
    setTriedSaving(true);
    setSomethingIsLoading(true);
    updateUserPersonalDetails(values);
    doUpdateUserRoles(values, user);
  };

  const addUser = values => doCreateUser(values);

  const updateUserPersonalDetails = values => {
    if (
      user[usersKeys.firstName] != values[usersKeys.firstName] ||
      user[usersKeys.lastName] != values[usersKeys.lastName]
    )
      doUpdateUserName(values);
    if (user[usersKeys.email] != values[usersKeys.email])
      doUpdateUserEmail(values);
  };

  const updateServices =
    (values, setFieldValue) => (index, property, value) => () => {
      // TODO: shared 'services' key
      const services = cloneDeep(values['services']);
      services[index][property] = value;
      setFieldValue('services', services);
      setServicesCache(services);
      updateAllServicesRole(services);
    };

  // all enabled is superflous now given that that no service is deactivated, just set to no access instead
  const updateAllServicesRole = services => {
    const allEnabled = services.every(s => s.is_enabled);
    const hasOnlyOneRole = services.every(
      s =>
        s.selectedRole && s.selectedRole?.key === services[0]?.selectedRole?.key
    );

    if (hasOnlyOneRole && allEnabled) {
      setAllServicesRole(services[0].selectedRole);
      setAllEnabled(allEnabled);
    } else {
      setAllServicesRole(null);
    }
  };

  // all enabled is superflous now given that that no service is deactivated, just set to no access instead
  const enableAll =
    ({ setFieldValue }) =>
    () => {
      setAllEnabled(lastEnabled => {
        const nowEnabled = !lastEnabled;
        if (nowEnabled) {
          selectRoleAll({ services: servicesCache ?? services, setFieldValue })(
            allServicesRole
          );
        }
        if (!nowEnabled) setFieldValue('services', servicesCache ?? services);

        return !lastEnabled;
      });
    };

  const selectRoleAll =
    ({ services, setFieldValue }) =>
    role => {
      setAllServicesRole(role);
      const newServices = cloneDeep(services);
      newServices.forEach(s => {
        if (role) s.selectedRole = s.roles.find(({ key }) => role.key === key);
      });

      setFieldValue('services', newServices);
    };

  const allServices = ({ services, setFieldValue }) => {
    return {
      enabled: true,
      enableAll: enableAll({ setFieldValue }),
      roles: defaultRoles,
      selectedRole: allServicesRole,
      selectRoleAll: role => () =>
        selectRoleAll({ services, setFieldValue })(role),
    };
  };

  const modeValues = {
    title: isEditing ? 'Modify user' : 'Add new user',
    primaryText: isEditing ? 'Save' : 'Add',
  };

  return (
    <Formik
      initialValues={{
        ...writeUserInitialValues(user),
        services,
      }}
      validationSchema={writeUserValidation}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <Form>
            <WriteUserLayout
              {...modeValues}
              fields={writeUserFields({ isEditing })}
              showUserList={showUserList}
              disabled={!isValid || somethingIsLoading}
              actionsLoading={somethingIsLoading}
              allServices={allServices({
                services: values?.services,
                setFieldValue,
              })}
              services={values?.services}
              servicesLoading={brandServicesIsLoading}
              update={updateServices(values, setFieldValue)}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddUser;
