import SubViews from '../../../../../components/templates/SubViews/SubViews';
import { Labels, Pages } from '../../../routes/variables';

const BrandSettingsLayout = ({ components, options, styles }) => {
  return (
    <SubViews
      options={options}
      styles={styles}
      components={components}
      title={Labels[Pages.brand_settings]}
    />
  );
};

export default BrandSettingsLayout;
