import * as icons from 'lucide-react';
import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Input } from '../../../../../../components/molecules';
import { Colors, Size } from '../../../../../../components/style';
import { SectionTitle } from '../../../../../../components/templates/Popup/components';
import { fieldNames } from '../../controllers/variables';
import { PermissionsGrid, tableComponents } from '../molecules';
import {
  AdminServicesTableContainer,
  FieldsCSS,
  IconPreviewCSS,
} from '../style';

export const AdminServicesTable = ({ tableProps }) => {
  return (
    <AdminServicesTableContainer
      {...tableProps}
      components={tableComponents(tableProps.tableComponentProps)}
    />
  );
};

const IconPreview = ({ name }) => {
  const iconExists = icons[name];

  return (
    <IconPreviewCSS>
      {
        <Icon
          name={!!iconExists ? name : Icons.Bookmark}
          size={Size.Icon._M}
          color={iconExists ? undefined : Colors.Neutral._500}
        />
      }
    </IconPreviewCSS>
  );
};

export const Fields = ({ fields, values, selectOption }) => {
  return (
    <Flex column gap="_S">
      <SectionTitle text={'Service details'} />
      <FieldsCSS gap="_S" w>
        {fields.map(field => (
          <Flex relative className={field.name} w>
            <Input size="_M" {...field} selectOption={selectOption} />
            {field.name === fieldNames.icon ? (
              <IconPreview name={values[fieldNames.icon]} />
            ) : null}
          </Flex>
        ))}
      </FieldsCSS>
    </Flex>
  );
};

export const Permissions = ({ permissionGridProps }) => {
  return (
    <Flex column gap="_S">
      <SectionTitle text={'Permissions details'} />
      <PermissionsGrid {...permissionGridProps} />
      <div />
    </Flex>
  );
};
