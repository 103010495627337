import { Route, Routes } from 'react-router-dom';
import Authentication from './Authentication';

export const AuthenticationRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Authentication />} />
    </Routes>
  );
};
