import styled from '@emotion/styled';
import { Filters } from '../../../../../components/organisms/Filters';
import { Colors, Effects, Layout } from '../../../../../components/style';

export const styles = `
    .monthly-reports, .custom-reports {
        height: 100%;
    }
`;

export const MonthlyReportsFilter = styled(Filters)`
  min-width: 96px;
  .options-hoverable {
    width: 96px;
    min-width: unset;
  }
`;

export const ReportContainer = styled.div`
  .popup {
    height: 476px;
    min-height: 476px;
    width: 544px;
  }
  .calendar {
    height: unset !important;
    max-width: unset !important;
  }
`;

export const AlertCSS = styled.div`
  display: flex;
  gap: ${Layout.Spacing._2XS};
  padding: ${({ padding = '_S' }) => Layout.Spacing[padding]};
  background: ${Colors.Neutral._100};
  border-radius: ${Effects.Border_Radius._S};
`;
