import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { humanReadableDate } from '../../../../../utils/timeUtils';
import { overviewKeys } from '../variables';

export const formatOverviewCard =
  ({ type, hasPermission }) =>
  (data, options = { tooltipSide: 'bottom' }) => {
    if (!hasPermission) data = makeFakeData(type);
    const { title, description, titleIcon, keys, countKey, comparisonKey } =
      overviewKeys[type];

    const totals = keys.map(
      ({ key, title, link, tooltipContent, dataType }) => {
        const rawCount = data?.[countKey]?.[key];
        const count = magnitudeFormatter(rawCount);
        const countObject = { count };

        const { from_date, to_date, comparison } = data?.[comparisonKey] ?? {};
        const delta = comparison?.[key]?.percentage;

        const isMore = delta > 0;

        const period = `${Math.abs(delta)}% ${
          isMore ? 'more' : 'fewer'
        } ${dataType} compared to ${humanReadableDate(
          from_date
        )} through \n${humanReadableDate(to_date)}`;

        return {
          key,
          title,
          tooltipContent,
          navlink: link,
          count: countObject,
          delta,
          period,
          ...options,
        };
      }
    );

    return {
      titleIcon,
      title,
      description,
      totals,
      disabled: !hasPermission,
      className: `overview-card ${type}`,
    };
  };

// AI generated
export const makeFakeData = type => {
  const { countKey, comparisonKey } = overviewKeys[type];
  const keys = overviewKeys[type].keys;
  const fakeData = {
    [countKey]: keys.reduce((acc, key) => {
      acc[key] = null && Math.floor(Math.random() * 1000000);
      return acc;
    }, {}),
    [comparisonKey]: {
      from_date: '2021-01-01',
      to_date: '2021-01-31',
      comparison: keys.reduce((acc, key) => {
        acc[key] = {
          count: null && Math.floor(Math.random() * 1000000),
          percentage: Math.floor(Math.random() * 100),
        };
        return acc;
      }, {}),
    },
  };
  return fakeData;
};
