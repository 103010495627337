import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import Help from '../../../features/support/controllers/Help';
import { TicketTypeSelectionLayout } from '../components/TicketTypeSelectionLayout';
import CreateTicket from './CreateTicket';
import { setupChoice, ticketTypes } from './variables';

const TicketTypeSelection = ({ newTab }) => {
  const { dismiss, dismissAll, showModal } = useModal();

  const showCreateTicket = type => () =>
    showModal(CreateTicket, { type, goBack: dismiss, newTab }, { stack: true });

  const showHelp = () => showModal(Help, { onBack: dismiss }, { stack: true });

  const generalSupport = {
    ...setupChoice[ticketTypes.General],
    onClick: showHelp,
  };

  const parcelSupport = {
    ...setupChoice[ticketTypes.Parcel],
    onClick: showCreateTicket(ticketTypes.Parcel),
  };

  const productSupport = {
    ...setupChoice[ticketTypes.Product],
    onClick: showCreateTicket(ticketTypes.Product),
  };

  return (
    <TicketTypeSelectionLayout
      onCancel={() => dismissAll()}
      generalSupport={generalSupport}
      parcelSupport={parcelSupport}
      productSupport={productSupport}
    />
  );
};
export default Modal(TicketTypeSelection);
