import { Icon } from '../../../../components/atoms';
import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import { Colors, Size } from '../../../../components/style';
import { LeftSwipeCSS, RemoveInstanceCSS, RightActionCSS } from '../style';

export const RightAction = ({ addsMoreFiles, onClick }) => {
  return (
    <RightActionCSS addsMoreFiles={addsMoreFiles} onClick={onClick}>
      <Icon
        name={addsMoreFiles ? Icons.Plus : Icons.ChevronRight}
        color={addsMoreFiles ? Colors.Sharp.White : undefined}
        size={Size.Icon._XS}
      />
    </RightActionCSS>
  );
};

// % used directly
export const LeftSwipe = ({ onClick }) => {
  return (
    <LeftSwipeCSS onClick={onClick}>
      <Icon name={Icons.ChevronLeft} size={Size.Icon._XS} />
    </LeftSwipeCSS>
  );
};

export const RemoveInstance = ({ onClick }) => {
  return (
    <RemoveInstanceCSS onClick={onClick}>
      <Icon name={Icons.X} size={Size.Icon._XS} />
    </RemoveInstanceCSS>
  );
};
