import { useEffect, useState } from 'react';
import { formatInitValDates } from '../../../utils/timeUtils';
import { Icons } from '../../atoms/Icon/Icon.options';
import { FilterTitle } from '../FilterTitle/FilterTitle';
import { CalendarContainer, CalendarFilterPadding } from './Calendar.emotion';
import { Container } from './Calendar.style';
import DatePickerCustom from './library/DatepickerCustom';

export const Calendar = ({
  filter = () => {},
  title,
  timeFrameInitVals,
  fullWidth,
  disabled,
  size = '_S',
  onClick,
  open,
}) => {
  const {
    value: { fromDate, toDate },
  } = timeFrameInitVals;
  const isFilterApplied = fromDate && toDate;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(fullWidth);
  }, [fullWidth]);

  return (
    <Container
      className="calendar"
      disabled={disabled}
      fullWidth={fullWidth}
      isFilterApplied={isFilterApplied}
      someOptionSelected={true}
      onClick={onClick}
      open={open}
    >
      <FilterTitle
        title={title}
        iconStart={Icons.Calendar}
        iconEnd={Icons.ChevronDown}
        size={size}
        placeholder={'Select date range'}
      />
      <CalendarContainer isOpen={isOpen}>
        <DatePickerCustom
          filter={filter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          initialStart={formatInitValDates(timeFrameInitVals?.value?.fromDate)}
          initialEnd={formatInitValDates(timeFrameInitVals?.value?.toDate)}
        />
        {isOpen ? <CalendarFilterPadding /> : ''}
      </CalendarContainer>
    </Container>
  );
};
