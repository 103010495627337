import { createContext, useContext, useState } from 'react';
import { PropKeys } from '../constants';
import { strip } from '../lib/js';

const StoreContext = createContext();

export function useStore() {
  return useContext(StoreContext);
}

const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});

  const updateStore = newData => {
    const update = store => {
      return customTransform({
        ...store,
        // strip() prevents undefined values from overwriting non-null values.
        // use null if you want to clear the vlaue
        ...strip(newData),
      });
    };
    setStore(update);
  };

  return (
    <StoreContext.Provider value={{ store, updateStore }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;

const customTransform = newData => {
  // reflect any updates on firstName or lastName immediately on userName
  newData[PropKeys.username] =
    newData[PropKeys.firstName] + ' ' + newData[PropKeys.lastName];

  return newData;
};
