import styled from '@emotion/styled';
import { useState } from 'react';
import { scrollbarOffset, Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import {
  Colors,
  Effects,
  FontKeys,
  Layout,
  Size,
  UtilityColors,
} from '../../style';

const containerPadding = `0 ${Layout.Spacing._XS}`;
const optionPadding = `0 ${Layout.Spacing._2XS}`;

export const SelectContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  padding: ${containerPadding};
  ${Styles.flexBetweenCenter};
  input {
    font-weight: ${FontKeys.Weight.Semibold};
  }
`;

// done intentionally. options do not display otherwise
export const Options = ({
  children,
  size,
  noOfShownOptions,
  showOptions,
  scrollRef,
}) => {
  const Container = styled.div`
    width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: ${showOptions ? 1 : 0};
    padding: ${optionPadding};
    max-height: calc(${Size.Input[size].height} * 5);
    min-height: ${showOptions ? `calc(${Size.Input[size].height})` : 0};
    height: ${showOptions
      ? `calc(${Size.Input[size].height} * ${Math.min(noOfShownOptions, 5)})`
      : 0};
    top: calc(100% + ${Layout.Spacing._2XS});
    background: ${Colors.Sharp.White};
    border-radius: ${Effects.Border_Radius._S};
    border: 1px solid ${showOptions ? UtilityColors.Border : 'transparent'};
    box-shadow: ${Effects.Box_Shadow._XS};
    ${Styles.flexColumn};
    > div {
      width: 100%;
    }
  `;

  return (
    <Container>
      <Flex column scroll ref__={scrollRef}>
        {children}
      </Flex>
    </Container>
  );
};

const OptionContainer = styled.div`
  width: 100%;
  padding: ${optionPadding};
  border-radius: ${Effects.Border_Radius._M};
  min-height: ${props => Size.Input[props.size].height};
  background: ${props => (props.selected ? Colors.Neutral._100 : 'inherit')};
  ${Styles.flex};
  &:hover {
    border: ;
  }
`;

// done intentionally. options do not display otherwise
export const Option = ({
  onClick,
  value,
  label,
  selected,
  highlighted,
  size,
}) => {
  const [hover, setHover] = useState(false);
  const setHovering = to => () => setHover(to);

  return (
    <OptionContainer
      onPointerOver={setHovering(true)}
      onPointerLeave={setHovering(false)}
      onPointerDown={onClick}
      value={value}
      selected={selected}
      size={size}
    >
      <OptionText
        text={label}
        size={size}
        bold={selected || hover || highlighted}
      />
    </OptionContainer>
  );
};

export const OptionText = ({ text, size, bold, disabled, readOnly }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={
        disabled && !readOnly ? UtilityColors.Disabled : UtilityColors.Text
      }
      whiteSpace={'nowrap'}
    />
  );
};

// done intentionally. options do not display otherwise
export const NoResults = ({ size }) => {
  const Container = styled.div`
    width: calc(100% - ${scrollbarOffset});
    height: 100%;
    ${Styles.flexCenter};
  `;
  return (
    <Container>
      <OptionText text="No results" size={size} />
    </Container>
  );
};

export const OptionsFilter = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  font-weight: ${FontKeys.Weight.Semibold} !important;
  padding: ${containerPadding};
  ${Styles.flex};
  &:empty {
    line-height: calc(${props => Size.Input[props.size].height} - 2px);
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  inset: 0;
  .ignore .input {
    border: none;
  }
`;
