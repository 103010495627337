const { default: useFetchData } = require('../../../../../hooks/fetchHook');

export const useGetServiceKeysList = () => {
  const [{ data = [], isLoading, isError, error }] = useFetchData(
    'admin_services/keys',
    {
      formatter,
    }
  );

  return { data, isLoading, isError, error };
};

const formatter = data => {
  return data;
};
