import {
  BarchartResults,
  BrandDetails,
  Overview,
  ParcelStatuses,
  StatusDetails,
  SupportDetails,
} from './organisms/organisms';
import { DashboardContainer } from './style';

const DashboardLayout = ({
  parcelStatuses,
  statusBreakdownStats,
  overviewStats,
  onStatusNavigate,
  productsIsLoading,
  productsIsError,
  overviewIsLoading,
  supportIsLoading,
  overviewSupportStats,
  onParcelStatusClick,
  hasProductPermission,
  topProductsBarchartProps,
  topItemsBarchartProps,
  brandOverview,
}) => {
  return (
    <DashboardContainer>
      <Overview overviewStats={overviewStats} isLoading={overviewIsLoading} />
      <ParcelStatuses
        parcelStatuses={parcelStatuses}
        isLoading={productsIsLoading}
        isError={productsIsError}
        onClick={onParcelStatusClick}
        disabled={!hasProductPermission}
      />
      {statusBreakdownStats.map((item, index) => {
        const { data, isLoading, isError, disabled } = item ?? {};
        const { status, details, messageAndDelta } = data ?? {};

        return (
          <StatusDetails
            status={status}
            button={{ onClick: onStatusNavigate(item), text: 'View all' }}
            details={details}
            messageAndDelta={messageAndDelta}
            isError={isError}
            isLoading={isLoading}
            disabled={disabled}
            key={index}
          />
        );
      })}
      <BarchartResults
        {...topProductsBarchartProps}
        disabled={!hasProductPermission}
      />
      <Overview
        overviewStats={[overviewSupportStats]}
        isLoading={supportIsLoading}
        endSlot={<SupportDetails {...overviewSupportStats.details} />}
      />
      <BarchartResults
        {...topItemsBarchartProps}
        disabled={!hasProductPermission}
      />
      <Overview
        overviewStats={[brandOverview]}
        isLoading={productsIsLoading}
        endSlot={<BrandDetails {...brandOverview.brandDetails} />}
      />
    </DashboardContainer>
  );
};
export default DashboardLayout;
