import * as icons from 'lucide-react';
import { ccIcons, IllustrationsIcons } from './Icon.options';

const Icon = ({ name, color, size, fill = 'none', ...props }) => {
  const CCIcon = ccIcons[name];
  const Illustration = IllustrationsIcons[name];
  const LucideIcon = icons[name];

  if (CCIcon)
    return (
      <CCIcon
        fill={color}
        width={size}
        height={size}
        style={{ minWidth: size }}
        {...props}
        className={props.className}
      />
    );
  if (LucideIcon)
    return (
      <LucideIcon
        color={color}
        size={size}
        style={{ minWidth: size ?? 24 }}
        fill={fill}
        {...props}
        className={props.className}
      />
    );
  if (Illustration)
    return <Illustration className={props.className} {...props} />;

  return;
};

export default Icon;
