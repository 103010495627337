import styled from '@emotion/styled';
import { Table } from '../../../../../components/organisms/Table/Table';
import { Layout } from '../../../../../components/style';

export const AdminSortersTableContainer = styled(Table)`
  .label {
    height: 24px;
  }
  .table-cell, .table-header {
    &._0 {
      padding-left: ${Layout.Spacing._S};
    }
`;
