const withSelectedAtCenter = ({ images, selected }) => {
  const modulo = num => num % images.length;
  return [-3, -2, -1, 0, 1, 2, 3].map(
    distance => images[modulo(images.length + selected + distance)]
  );
};

export const hightlight = type =>
  type === 'rotating'
    ? index => index === 3
    : type === 'preview'
    ? (index, selected) => (selected < 3 ? index === selected : index === 3)
    : type === 'static'
    ? (index, selected) => index === selected
    : () => {
        throw Error(`Invalid Thumbnail Gallery Type: ${type}`);
      };

export const selectImage = ({ type, noOfImages, select }) =>
  type === 'rotating'
    ? (index, selected) => () => {
        const newImageIndex = (noOfImages + selected + index - 3) % noOfImages;
        select(newImageIndex);
      }
    : type === 'preview' || type === 'static'
    ? index => () => select(index)
    : (() => {
        throw Error(`Invalid Thumbnail Gallery Type: ${type}`);
      })();

export const displayImages = ({ type, images, selected }) => {
  if (images == null) return [];
  if (type === 'rotating') return withSelectedAtCenter({ images, selected });
  if (type === 'preview') return images.slice(0, 4);
  return images;
};
