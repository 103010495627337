import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { Evaluation } from '../molecules';
import { Container, ScrollContainer } from '../style';

export const Evaluations = ({ evalProps }) => {
  const { data } = evalProps;

  return (
    <Container column>
      <ScrollContainer column gap="_XS">
        <Spacer h="_S" />
        {data.map((item, index) => {
          return (
            <LazyLoadComponent>
              <Evaluation key={index} item={item} {...evalProps} />
            </LazyLoadComponent>
          );
        })}
        <Spacer h="_S" />
      </ScrollContainer>
    </Container>
  );
};
