import React from 'react';
import { Icon, Text } from '../../../../../../components/atoms';
import {
  FirstLoad,
  Icons,
} from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { DashboardClickIconCSS } from '../style';

export const ItemTitle = React.forwardRef((props, ref) => {
  return (
    <Text
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      ellipsis
      {...props}
      ref={ref}
    />
  );
});

export const ItemData = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H3}
      weight={FontKeys.Weight.Bold}
      color={Colors.Primary._600}
    />
  );
};

export const NavigateIcon = ({ onClick }) => {
  const icon = {
    name: Icons.ExternalLink,
    theme: 'dashboard_icon',
    onClick: onClick,
    size: Size.Icon._M,
  };

  return <OverviewIcon {...icon} onClick={onClick} animated />;
};

export const OverviewIcon = props => {
  const Component = props.animated ? AnimatedIcon : Icon;
  return <Component {...props} />;
};

export const StatusCardMessage = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
      whiteSpace="nowrap"
    />
  );
};

export const ItemSubdata = ({ text, bold, black }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={black ? undefined : Colors.Neutral._500}
      whiteSpace="pre"
    />
  );
};

export const DashboardItemLabel = ({ text, bold }) => {
  return (
    <Text
      className="dashboard-label"
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      whiteSpace="nowrap"
      ellipsis
    />
  );
};

export const DashboardClickIcon = () => {
  return (
    <DashboardClickIconCSS className="dashboard-click-icon">
      <Icon
        name={Icons.ChevronRight}
        color={Colors.Neutral._500}
        size={Size.Icon._M}
      />
    </DashboardClickIconCSS>
  );
};

export const LoadingAnimation = () => <CardLoader />;

// TODO: DRY this (product details)
export const ErrorText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
    />
  );
};

export const PopupTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H3}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
      alignment="center"
    />
  );
};

export const WelcomeIcon = () => <Icon name={FirstLoad.Welcome} />;

export const PopupDescription = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Regular}
      alignment="center"
    />
  );
};

export const DescriptiveText = ({ text, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      color={Colors.Neutral._500}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      ellipsis
    />
  );
};

export const ParcelAliasText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};
