import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';
import { Colors } from '../../style';
import { CenterLabel, Container } from './CircleProgressBar.style';

export const circleProgressBarDataKey = 'value';

export default function CircleProgressBar({
  circleSize = 80,
  innerRadius = 34,
  outerRadius = 34,
  barSize = 12,
  data = { value: 0, fill: Colors.Primary._600 },
  startAngle = 90,
  endAngle = -270,
  type = 'number',
  domain = [0, 100],
  angleAxisId = 0,
  tick = false,
  clockWise = false,
  dataKey = circleProgressBarDataKey,
  backgroundFill = Colors.Neutral._100,
  customLabel,
}) {
  const centerLabel = data.display != 'undefined' ? data.display ?? '' : '';
  return (
    <Container size={circleSize} className="circle-progress-bar">
      <RadialBarChart
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        barSize={barSize}
        data={[data]}
        startAngle={startAngle}
        endAngle={endAngle}
      >
        <PolarAngleAxis
          type={type}
          domain={domain}
          angleAxisId={angleAxisId}
          tick={tick}
        />
        <RadialBar
          background
          clockWise={clockWise}
          dataKey={dataKey}
          cornerRadius={circleSize / 2}
          fill={backgroundFill}
        />
      </RadialBarChart>
      <CenterLabel text={centerLabel} customLabel={customLabel} />
    </Container>
  );
}
