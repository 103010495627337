import styled from '@emotion/styled';
import { PropKeys, Utils } from '../../../constants';
import { useStore } from '../../../providers/StoreProvider';
import { Input } from '../../molecules';

const defaultProps = {
  size: '_M',
  type: 'select',
  name: 'brand',
  placeholder: 'Select a brand',
  // leftIcon: Icons.Building,
};

export const ActingBrandSelection = ({
  brandsFilter = Utils.unary,
  isAdmin = false,
  preselectedBrandId,
  ...props
}) => {
  const { store } = useStore();
  const actingBrandId = isAdmin
    ? preselectedBrandId
    : store[PropKeys.brandId] || preselectedBrandId;

  const mustSpecifyActingBrand = !actingBrandId || isAdmin;
  const storeActingBrand =
    store[PropKeys.clients].find(
      ({ [PropKeys.brandId]: brID }) => brID === actingBrandId
    ) ?? {};

  const actingBrand = {
    value: storeActingBrand[PropKeys.brandId],
    label: storeActingBrand[PropKeys.brandName],
  };

  const brands = (store[PropKeys.clients] ?? []).map(
    ({ [PropKeys.brandId]: brID, [PropKeys.brandName]: brName }) => ({
      value: brID,
      label: brName,
    })
  );
  const options = mustSpecifyActingBrand ? brandsFilter(brands) : [actingBrand];

  const { value, selectOption } = props;
  const name = props.name ?? defaultProps.name;

  if (actingBrandId && !value && options?.length)
    selectOption({ name, ...actingBrand })();

  return (
    <ActingBrandSelectionCSS
      disabled={!mustSpecifyActingBrand}
      {...defaultProps}
      selectOption={selectOption}
      options={options}
      {...props}
    />
  );
};

const ActingBrandSelectionCSS = styled(Input)`
  z-index: 10;
`;
