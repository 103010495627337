import { Route, Routes } from 'react-router-dom';
import Instruction from './Instruction';
import KnowledgeBase from './KnowledgeBase';

export const KnowledgeBaseRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<KnowledgeBase />} />
    </Routes>
  );
};

export const KnowledgeBaseDetailsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Instruction />} />
    </Routes>
  );
};
