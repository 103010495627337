import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { adminCategoriesKeys as keys } from '../controllers/variables';

export const useGetAdminCategories = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { categories = [], category_count: categoryCount = 0 } = {},
      isLoading = true,
      isError,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('admin_categories', {
    params,
    ignore,
    large: true,
  });

  return {
    data: {
      categories: formatter(categories, mask),
      categoryCount,
    },
    isLoading,
    isError,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const { SEARCH: search, CATEGORY, PAGE, ITEMS_PER_PAGE } = params;

  const category_id = extractSelectedValues(CATEGORY);

  const formattedParams = {
    search,
    category_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

export const formatter = data => {
  maskEntry(data, mask);
  return data;
};

export const mask = {
  // "product_name": keys.name,
  creator_name: keys.addedBy.name,
  creator_image: keys.addedBy.image,
  created_at: keys.addedAt,

  cpName: keys.subCategory.name,
  cpID: keys.subCategory.id,
  product_id: keys.subCategory.id,

  bcName: keys.category.name,
  bcID: keys.category.id,
};
