import { AlphabetBar, CategoryList } from '../organisms';
import { CategoryListScrollContainer, CategorySelectContainer } from '../style';

export const CategorySelect = ({
  values,
  options,
  getRef,
  setRef,
  selectCateogry,
}) => {
  return (
    <CategorySelectContainer>
      <AlphabetBar getRef={getRef} />
      <CategoryListScrollContainer>
        <CategoryList
          setRef={setRef}
          categories={options}
          selectCateogry={selectCateogry}
          chosenCategory={values}
        />
      </CategoryListScrollContainer>
    </CategorySelectContainer>
  );
};
