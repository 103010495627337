import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFooter } from '../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../providers/HeaderProvider/HeaderProvider';
import InViewMenu from '../../organisms/InViewMenu/InViewMenu';
import { ViewTitle } from '../ViewHeader/components';
import { Container } from './style';

const SubViews = ({ components, options, styles, title }) => {
  const [selectedView, setSelectedView] = useState(options[0]?.view);

  const location = useLocation();

  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();

  useEffect(() => {
    const headerChildren =
      typeof title !== 'string' ? (
        title
      ) : (
        <ViewTitle text={title} backPage={'/'} noNav />
      );

    setTimeout(() => setHeaderChildren(headerChildren), 0);
  }, []);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useLayoutEffect(() => {
    if (!options?.length) return;
    const { option } = location.state ?? {};
    let view = options[0];
    if (option) view = options.find(({ view }) => view === option);
    selectView(view)();
  }, [location]);

  const selectView = option => () => setSelectedView(option.view);

  return (
    <Container styles={styles}>
      <InViewMenu
        options={options}
        selectView={selectView}
        selectedView={selectedView}
      />
      <div className="component">{components[selectedView]}</div>
    </Container>
  );
};

export default SubViews;
