import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ViewFooter from '../../../../../components/templates/ViewFooter/ViewFooter';
import ViewHeader from '../../../../../components/templates/ViewHeader/ViewHeader';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { useUI } from '../../../../../providers/UIProvider';
import { elementDimensions } from '../../../../../utils/utils';
import { PrivateSidebar } from '../../organisms/Sidebar/PrivateSidebar';
import { Container, Content, InnerContent, PageContent } from './styles';

export const Layout = ({ children }) => {
  const [headerContentWidth, setHeaderContentWidth] = useState(0);

  const location = useLocation();

  const { showHeader, headerChildren } = useHeader();
  const { showFooter, footerChildren } = useFooter();
  const { sidebarWidth, layoutCustomStyles } = useUI().UI;
  const { modalComponents } = useModal();

  const contentRef = useRef(null);
  const contentContainerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    contentContainerRef.current?.scrollTo(0, 0);
    containerRef.current?.scrollTo(0, 0);
  }, [location]);

  useLayoutEffect(() => {
    const container = contentRef.current;
    const resizeObserver = new ResizeObserver(() => rszObserverCallback());
    resizeObserver.observe(container);
    return () => {
      resizeObserver.unobserve(container);
    };
  }, []);

  const rszObserverCallback = () => {
    const { width, height } = elementDimensions({
      currentTarget: contentRef.current,
    });

    setHeaderContentWidth(width);
  };

  useLayoutEffect(rszObserverCallback, []);

  return (
    <Container
      customStyle={layoutCustomStyles}
      sidebarWidth={sidebarWidth}
      modalsActive={!!modalComponents.length}
      ref={containerRef}
      className="container"
    >
      <PrivateSidebar />
      <PageContent className="page-content" sidebarWidth={sidebarWidth}>
        {showHeader && (
          <ViewHeader width={headerContentWidth} children={headerChildren} />
        )}
        <Content ref={contentContainerRef} className="content">
          <InnerContent ref={contentRef} className="inner-content">
            {children}
          </InnerContent>
        </Content>
        {showFooter && <ViewFooter children={footerChildren} />}
        {modalComponents}
      </PageContent>
    </Container>
  );
};
