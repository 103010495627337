import styled from '@emotion/styled';
import { Colors, Effects, Layout, Size } from '../../../components/style';
import { Styles, UtilityComponents } from '../../../constants';

export const styles = `
    form {
      height: 100%;
      .card {
        height: 100%;
      }
    }
`;

export const EditableAvatarCSS = styled.div`
  position: relative;
  border-radius: ${Effects.Border_Radius.Circle};
  background: ${props =>
    props.isLoading ? Colors.Neutral._200 : 'transparent'};
  ${props => Size.Avatar[props.size]}
  ${Styles.flexColumnEnd};
  &:hover {
    .edit-options {
      display: block;
    }
  }
`;

export const EditOptionsCSS = styled.div`
  position: absolute;
  top: 100%;
  display: none;
  left: 50%;
  z-index: 1;
  padding: ${Layout.Spacing._2XS};
  &:hover {
    display: block;
  }
`;

export const EditOptionsCentering = styled.div`
  box-shadow: ${Effects.Box_Shadow._L};
  gap: ${Layout.Spacing._2XS};
  padding: ${Layout.Spacing._XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  ${Styles.flexColumn};
`;

export const EditOption = styled.div`
  padding: ${Layout.Spacing._2XS} 0;
`;

export const EditAvatarIconCSS = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: ${Layout.Spacing._M};
  height: ${Layout.Spacing._M};
  box-shadow: ${Effects.Box_Shadow._S};
  border-radius: ${Effects.Border_Radius.Circle};
  background: ${Colors.Sharp.White};
  ${Styles.flexCenter};
`;

export const DetailsFormCSS = styled(UtilityComponents.Grid)`
  grid-row-gap: 0px;
  grid-auto-rows: max-content;
`;

export const ActionsCSS = styled.div`
  margin-top: auto;
  gap: ${Layout.Spacing._S};
  ${Styles.flex};
`;

export const PasswordFieldsCSS = styled.div`
  width: 50%;
  gap: ${Layout.Spacing._M};
  ${Styles.flexColumn};
`;
