import { Flex } from '../../atoms/Flex/Flex';
import { Alert } from '../../templates/Alert/Alert';
import Modal from '../../templates/Modal/Modal';
import Button from '../Button/Button';
import { Container, Description, Title } from './ConfirmModal.style';

const ConfirmModal = ({
  title,
  description,
  alertText,
  confirm,
  confirmText,
  cancel = 'Cancel',
  cancelText = 'Cancel',
  tertiary,
  tertiaryText,
  onConfirm,
  onCancel,
  onTertiary,
  content,
  dismiss,
  disabled,
  isLoading,
  textSize = '_M',
  ...props
}) => {
  return (
    <Container className={`confirm-modal ${props.className ?? ''}`}>
      <Title text={title} onCancel={onCancel ?? dismiss} size={textSize} />
      {description && <Description text={description} size={textSize} />}
      {content}
      {alertText && <Alert text={alertText} />}
      <Flex align="end" justify="end" gap="_S" margin={'auto 0 0 0'}>
        {onTertiary && (tertiary || tertiaryText) && (
          <Button
            size="_M"
            variant="Tertiary"
            text={tertiary ?? tertiaryText}
            onClick={onTertiary}
          />
        )}
        {onCancel && (
          <Button
            size="_M"
            variant="Secondary"
            text={cancel ?? cancelText}
            onClick={onCancel}
            disabled={isLoading}
          />
        )}
        {onConfirm && (
          <Button
            size="_M"
            variant="Primary"
            text={confirm ?? confirmText}
            onClick={onConfirm}
            disabled={disabled || isLoading}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </Container>
  );
};

export default Modal(ConfirmModal);
