import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { DismissIcon } from './molecules';
import { AuthenticationSlider } from './organisms';
import { AuthenticationGalleryContainer } from './style';

export const AuthenticationGalleryLayout = ({
  dismissAuthentication: dismiss,
  sidebarWidth,
  ...props
}) => {
  const {
    authSliderState: { isLoading },
  } = props;
  return (
    <AuthenticationGalleryContainer
      onClick={e => e.stopPropagation()}
      sidebarWidth={sidebarWidth}
    >
      <DismissIcon onClick={dismiss} />
      {isLoading ? <TableLoader /> : <AuthenticationSlider {...props} />}
    </AuthenticationGalleryContainer>
  );
};
