import useFetchData from '../../../../../hooks/fetchHook';

export const useGetMessages = ({ ticketId }) => {
  const [{ data = [], isLoading, isError, error }] = useFetchData(
    `office_support_ticket/${ticketId}/messages`,
    {
      formatter: formatMessages,
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatMessages = data => {
  if (data == null) return [];
  return data.map(message => formatMessage(message));
};

export const formatMessage = message_ => {
  const {
    client_type: type,
    usImage: image,
    message,
    usDisplayName: name,
    createdAt: date,
  } = message_;
  return {
    ...message_,
    type,
    image,
    message,
    name,
    date,
  };
};
