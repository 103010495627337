import { Text } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { FontKeys } from '../../../../../components/style';

export const SnapshotData = ({ details }) => {
  return (
    <Flex column gap="_2XS" w>
      {(details ?? []).map(detail => {
        const { title, text } = detail;
        return <Detail title={title} text={text} key={title} />;
      })}
    </Flex>
  );
};

const Detail = ({ title, text }) => {
  return (
    <Flex w>
      <Flex w0 grow gap="_3XS">
        <BaseDetailText text={title} ellipsis={false} />
        <BaseDetailText text={text} ellipsis={true} bold />
      </Flex>
    </Flex>
  );
};

const BaseDetailText = ({ text, bold, ellipsis }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      ellipsis={ellipsis}
    />
  );
};
