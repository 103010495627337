import { useDeepCompareEffect } from '@react-hookz/web';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  generateDropdown,
  hasCustomSearch,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import { Labels, Pages } from '../../../routes/variables';
import { useGetData } from '../api/getSorterData';
import { useGetFilters } from '../api/getSorterDataFilters';

import SorterDataLayout from '../components/SorterDataLayout';
import {
  DC,
  allOptions,
  filtersConstants,
  initialFilterValues,
  titles,
} from '../variables';
import { useSorterDataReducer } from './SorterData.hook';

const { SELF, VIEW_DATA } = PermissionKeys.Admin.OFFICE_SORTER_DATA;
const SorterData = () => {
  const [data, setData] = useState([]);

  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const location = useLocation();

  const { showSnackbarError } = useSnackbar();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_DATA);

  const resetFormRef = useRef(null);

  let ownOptions;

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useSorterDataReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]) ||
    // avoid searching again on normal navigation, unless a filter is set
    (!urlHasSearch && searchParams[DC.SET] && !searchParams[DC.FILTERS_SET]);

  useDeepCompareEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: sorter_data,
    isLoading,
    isError,
    error,
    refresh: refreshTable,
  } = useGetData({
    searchParams,
    ignore: ignoreDataFetch,
  });
  useEffect(() => {
    if (isLoading || sorter_data == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    setData(sorter_data);
  }, [sorter_data, isLoading, isError, searchParams]);

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    options,
    ownOptions,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    allOptions,
  });

  const filtersState = {
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.CATEGORY]: dropdown(DC.CATEGORY),
    [DC.SUBCATEGORY]: dropdown(DC.SUBCATEGORY),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.data]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  return (
    <SorterDataLayout
      data={data}
      isLoading={isLoading}
      isError={isError}
      hasViewPermission={hasViewPermission}
    />
  );
};

export default SorterData;
