import styled from '@emotion/styled';
import { Table } from '../../../../../components/organisms/Table/Table';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';
import { hexOpacity } from '../../../../../utils/styleUtils';

export const ProductsTableContainer = styled(Table)`
  ._0,
  ._1 {
    padding-left: 0;
  }
  .header-row {
    ._12 {
      span {
        overflow: unset !important;
      }
    }
  }
`;

const summaryPreviewStyles = {
  _M: {},
  _L: {
    gridColumn: 10,
  },
};

const previewGalleryStyles = {
  _M: {
    marginLeft: `-${Layout.Spacing._XS}`,
  },
  _L: {
    marginLeft: `-${Layout.Spacing._S}`,
  },
};

export const SummaryPreviewGalleryS = styled.div`
  ${props => summaryPreviewStyles[props.size]};

  .preview-gallery {
    ${props => previewGalleryStyles[props.size]}
  }
`;

export const ProductDetailsContainerCSS = styled(UtilityComponents.Grid)`
  grid-template-rows: min-content;
  grid-auto-rows: 308px;
  .card,
  .status-steps {
    height: 100%;
  }
  margin-bottom: ${Layout.Spacing._XS};
  *::-webkit-scrollbar {
    width: 8px;
    display: unset;
  }
  .parcel-details-summary {
    grid-column: 1 / 13;
    min-height: 232px;
    display: grid;
    > div {
      grid-column-gap: 64px;
    }
    .information-icon {
      z-index: 100;
    }
  }
  .product-details-summary {
    padding-left: ${Layout.Spacing._S};
    grid-column: 1 / 9;
  }
  .parcel-products {
    grid-column: 9 / 13;
    align-self: stretch;
    box-shadow: ${Effects.Box_Shadow._M};
    .scroll-list {
      border: none;
    }
  }
  .status-steps {
    gap: 46px;
    grid-column: 1 / 9;
  }
  .product-tickets {
    grid-column: 1 / 9;
  }
  .recent-activity {
    grid-row: 4 / 6;
    grid-column: 9 / 13;
    align-self: stretch;
    .fill-card {
      overflow: auto;
    }
  }
  .notes {
    grid-column: 9 / 13;
  }
  .attachments {
    align-self: stretch;
    grid-column: 1 / 5;
  }
  .authority-info {
    grid-column: 5 / 9;
    .fill-card {
      display: flex;
      flex-direction: column;
    }
  }
  .barcodes {
    gap: ${Layout.Spacing._3XS};
    .parcel-detail {
      width: 0;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: ${Layout.Spacing._2XS};
      span {
        white-space: nowrap;
      }
    }
  }
`;

export const ParcelDetailsS = styled.div`
  gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  grid-column: 1/9;
`;

export const ParcelDetailS = styled.div(props => ({
  filter: props.mock ? 'blur(3px)' : '',
  opacity: props.mock ? 0.4 : 1,
  userSelect: props.mock ? 'none' : 'inherit',
}));

const getColumns = index => (index + 1) * 3 - 2 + ' / ' + ((index + 1) * 3 + 1);

export const ParcelDetailsSectionS = styled.div`
  height: 100%;
  align-items: flex-start;
  grid-column: ${props => getColumns(props.index)};
  position: ${props => (props.mock ? 'static' : 'inherit')};
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const BlurContainer = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  grid-row: 2;
  grid-column: ${props => props.blurColumns};
  inset: ${Layout.Spacing._S} 0;
  border-radius: ${Effects.Border_Radius._S};
  background: ${Colors.Neutral._200}${hexOpacity(30)};
  ${Styles.flexCenter};
`;

export const ProductTicketsScroll = styled.div`
  grid-row: 2;
  grid-column: 1 / 13;
  width: 100%;
  height: 100%;
  ${Styles.flexColumn};
`;

export const TicketS = styled.div`
  min-height: 56px;
  cursor: pointer;
  padding: 0 ${Layout.Spacing._XS};
  max-width: calc(100% - ${Layout.Spacing._XS});
  border-radius: ${Effects.Border_Radius._S};
  gap: ${Layout.Spacing._S};
  box-shadow: ${Effects.Box_Shadow._S};
  ${Styles.flexBetween};
`;

export const InfoS = styled.div`
  width: 0;
  flex-grow: 1;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flex}
`;

export const AuthorityInfoCSS = styled.div`
  height: 0;
  flex-grow: 1;
  overflow: auto;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;
