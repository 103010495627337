import useFetchData from '../../../../../hooks/fetchHook';
import {
  DateTypes,
  formatDate,
  humanReadableDate,
} from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { reportsKeys } from '../controllers/variables';

export const useGetCustomReports = () => {
  const [{ data = [], isLoading = true, isError, error }, _, refresh] =
    useFetchData('reports', {
      formatter,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data = data.map(report => ({
    ...report,
    [reportsKeys.dateRange]: `${formatDate(
      report.brepStartDate,
      DateTypes.ddmmyy
    )} - ${formatDate(report.brepEndDate, DateTypes.ddmmyy)}`,
    [reportsKeys.dateRequested]: humanReadableDate(report.createdAt),
  }));
  return data;
};

const mask = {
  brepID: reportsKeys.id,
  brepTitle: reportsKeys.name,
  usDisplayName: reportsKeys.username,
  usImage: reportsKeys.userimage,
};
