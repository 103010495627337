import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { Utils } from '../../../constants';
import { parseStringModifiers } from '../../../utils/utils';

export const DC = {
  RESET: 'RESET',
  FILES: 'FILES',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  UPLOAD: 'UPLOAD',
  STORAGE_NAME: 'STORAGE_NAME',
  MORE_FILES: 'MORE_FILES',
  LESS_FILES: 'LESS_FILES',
};

export const initialState = preexistingFiles => ({
  files: preexistingFiles?.length ? [] : [null],
  isUploading: false,
  completedUpload: false,
  uploadSuccess: false,
  storageNames: [],
  errorType: null,
});

export const uploadStatusContent = ({ fileState, acceptsMultiple }) => {
  const { isUploading } = fileState;

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  // FileUploadLayout.js shows a file if it exists and is not loading
  return {
    icon: Icons.Image,
    // TODO: these should be varialbes (check examples)
    text: parseStringModifiers(
      `Drag and drop your image${acceptsMultiple ? 's' : ''}\n\nor`
    ),
    button: {
      onClick: Utils.emptyFunction,
      text: `Browse image${acceptsMultiple ? 's' : ''}`,
    },
  };
};
