import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../components/templates/Popup';
import { ScrollPopupContent } from '../../../../../components/templates/Popup/Popup.style';
import { Tabs } from './organisms';
import { ParcelSummaryCSS } from './style';

export const ParcelSummaryLayout = ({
  title,
  alias,
  tabs,
  openTab,
  setOpenTab,
  component,
  canSubmitInCurrentTab,
  onAlternate,
  alternateProps,
  onSecondary,
  onSecondaryText,
  onSecondaryDisabled,
  onSubmit,
  isLoading,
  dismiss,
}) => {
  return (
    <ParcelSummaryCSS>
      <Popup
        size="_M"
        title={<CopyableText text={title} copyText={alias} />}
        element={<AnimatedIcon onClick={dismiss} name={Icons.X} />}
      >
        <ScrollPopupContent>
          <Flex w h column className={'parcel-summary-content'}>
            <Tabs tabs={tabs} openTab={openTab} setOpenTab={setOpenTab} />
            {component}
            {onSubmit ? (
              <Actions
                onAlternate={onAlternate}
                alternateProps={alternateProps}
                onCancel={onSecondary}
                cancelText={onSecondaryText}
                onCancelDisabled={onSecondaryDisabled}
                disabled={!canSubmitInCurrentTab || isLoading}
                onPrimary={onSubmit}
                isLoading={isLoading}
                hidePrimary
              />
            ) : null}
          </Flex>
        </ScrollPopupContent>
      </Popup>
    </ParcelSummaryCSS>
  );
};
