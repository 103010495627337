import styled from '@emotion/styled';
import { Constants, Styles } from '../constants';
import {
  DisableContainer,
  extendStyledComponents,
  hexOpacity,
} from '../utils/styleUtils';
import { highlightActiveFilter } from './molecules/FilterTitle/utils';

export const Colors = {
  Neutral: {
    _050: '#fafbff',
    _100: '#f5f6fa',
    _200: '#e5ebf2',
    _300: '#ced7e4',
    _400: '#b1bed1',
    _500: '#92a2b9',
    _600: '#63738b',
    _700: '#4a5a73',
    _800: '#2a3648',
    _900: '#1f2025',
  },
  Primary: {
    _050: '#d9f1ff',
    _100: '#c1e8ff',
    _200: '#91D7FF',
    _300: '#66C7FF',
    _400: '#3DB0F2',
    _500: '#3077F2',
    _600: '#0c57bf',
    _700: '#0e499b',
    _800: '#002f6e',
    _900: '#002251',
  },
  Turquoise: {
    _100: '#8DD6C8',
    _300: '#50D4BD',
    _500: '#1DBFA2',
    _700: '#0DAB8F',
    _900: '#019077',
  },
  Success: {
    _050: '#a1f1b3',
    _300: '#74e08c',
    _500: '#5cd377',
    _700: '#32ab4d',
    _800: '#189233',
    _900: '#189233',
  },
  Warning: {
    _100: '#FFE994',
    _300: '#FFDB51',
    _500: '#FFCB22',
    _700: '#FFC226',
    _800: '#FFC226',
    _900: '#FAB300',
  },
  Orange: {
    _100: '#FFB15D',
    _300: '#FF9B30',
    _500: '#F77F00',
    _700: '#E07402',
    _900: '#BF6200',
  },
  Error: {
    _100: '#FDBBB0',
    _300: '#fa907d',
    _500: '#F45A3D',
    _700: '#D14026',
    _900: '#BB250B',
  },
  CherryRed: {
    _100: '#F2A5AB',
    _300: '#E76671',
    _500: '#E82637',
    _700: '#CD2130',
    _900: '#A40C19',
  },
  Violet: {
    _100: '#eec8ff',
    _300: '#e4a6ff',
    _500: '#d081f3',
    _700: '#b960e0',
    _900: '#9a38c6',
  },
  Sharp: {
    White: '#FDFDFE',
    Black: '#000000',
    White_050: '#FDFDFE' + hexOpacity(50),
  },
};

export const StatusColors = {
  [Constants.Status.Sideload]: Colors.Violet._500,
  [Constants.Status.Inspection]: Colors.Primary._300,
  [Constants.Status.Authentication]: Colors.Orange._500,
  [Constants.Status.Counterfeit]: Colors.CherryRed._500,
  [Constants.Status['Non-counterfeit']]: Colors.Primary._500,
  [Constants.Status.AuthenticationCompleted]: Colors.Error._500,
  [Constants.Status.Stopped]: Colors.Warning._500,
  [Constants.Status.Seized]: Colors.Neutral._800,
  [Constants.Status.Released]: Colors.Turquoise._500,
  [Constants.Status.Returned]: Colors.Neutral._400,
  [Constants.Status.Gray]: Colors.Neutral._200,
};

export const UtilityColors = {
  Border: Colors.Neutral._300,
  Disabled: Colors.Neutral._300,
  Text: Colors.Neutral._900,
  Background_App: Colors.Neutral._100,
};

export const AvatarColors = [
  {
    background: Colors.Primary._400,
  },
  {
    background: Colors.Primary._600,
  },
  {
    background: Colors.Error._300,
  },
  {
    background: Colors.Warning._300,
  },
  {
    background: Colors.Success._300,
  },
  {
    background: Colors.Violet._300,
  },
];

export const Effects = {
  Box_Shadow: {
    _XS: '1px 1px 8px rgba(0, 0, 0, 0.06)',
    _S: '4px 4px 12px rgba(0, 0, 0, 0.06)',
    _M: '6px 6px 16px rgba(0, 0, 0, 0.06)',
    _L: '8px 8px 20px rgba(0, 0, 0, 0.06)',
    _XL: '10px 10px 24px rgba(0, 0, 0, 0.06)',
  },
  Border_Radius: {
    _3XS: '2px',
    _2XS: '4px',
    _XS: '6px',
    _S: '8px',
    _M: '12px',
    _L: '16px',
    _XL: '24px',
    _2XL: '32px',
    _3XL: '40px',
    Circle: '50%',
  },
  Border: {
    _S: '1px',
    _M: '2px',
  },
  Transition: {
    EaseInOut: (...props) => spreadTransition(props, `300ms ease-in-out`),
    Filter: (...props) => spreadTransition(props, `750ms ease-in-out`),
    CarouselSlide: (...props) => spreadTransition(props, `450ms ease-out`),
    TableExpand: (...props) => spreadTransition(props, `600ms ease-in-out`),
    Graph: (...props) => spreadTransition(props, `450ms ease-in-out`),
    Quick: (...props) =>
      spreadTransition(props, `300ms cubic-bezier(.4, 1.11, .66, 1.49)`),
  },
};

const spreadTransition = (props, transition) => {
  return (props[0] ? props : ['all'])
    .map(prop => `${prop} ${transition}`)
    .join(', ');
};

export const UtilityEffects = {
  SidebarTransition: Effects.Transition.EaseInOut,
};

export const FontKeys = {
  Variant: {
    Display: 'Display',
    Heading: 'Heading',
    Button: 'Button',
    Paragraph: 'Paragraph',
    Caption: 'Caption',
  },
  Weight: {
    Thin: 'Thin',
    ExtraLight: 'ExtraLight',
    Light: 300,
    Regular: 400,
    Medium: 'Medium',
    Semibold: 600,
    Bold: 660,
    Extrabold: 800,
    Black: 'Black',
  },
  Size: {
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4',
    H5: 'H5',
    H6: 'H6',
    _L: '_L',
    _M: '_M',
    _S: '_S',
  },
};

export const Font_Properties = {
  Size: {
    _2XS: '8px',
    _XS: '10px',
    _S: '12px',
    _M: '14px',
    _L: '16px',
    _XL: '18px',
    _2XL: '20px',
    _3XL: '28px',
    _4XL: '32px',
    _5XL: '40px',
    _6XL: '48px',
    _7XL: '56px',
    _8XL: '64px',
    _9XL: '72px',
  },
  Weight: {
    [FontKeys.Weight.Thin]: 100,
    [FontKeys.Weight.ExtraLight]: 200,
    [FontKeys.Weight.Light]: 300,
    [FontKeys.Weight.Regular]: 400,
    [FontKeys.Weight.Medium]: 500,
    [FontKeys.Weight.Semibold]: 600,
    [FontKeys.Weight.Bold]: 700,
    [FontKeys.Weight.Extrabold]: 800,
    [FontKeys.Weight.Black]: 900,
  },
  Height: {
    _2XS: '10px',
    _XS: '12px',
    _S: '16px',
    _M: '20px',
    _L: '24px',
    _XL: '24px',
    _2XL: '24px',
    _3XL: '36px',
    _4XL: '40px',
    _5XL: '48px',
    _6XL: '60px',
    _7XL: '72px',
    _8XL: '80px',
    _9XL: '88px',
  },
};

export const Layout = {
  Margin: {
    _S: '8px',
    _M: '16px',
    _L: '24px',
  },
  Padding: {
    _S: '8px',
    _M: '16px',
    _L: '24px',
    _XL: '40px',
    _2XL: '60px',
  },
  Spacing: {
    _4XL: '128px',
    _3XL: '96px',
    _2XL: '64px',
    _XL: '48px',
    _L: '40px',
    _M: '32px',
    _S: '24px',
    _XS: '16px',
    _2XS: '8px',
    _3XS: '4px',
  },
};

export const Size = {
  Button: {
    _S: { width: 'fit-content', height: '32px' },
    _M: { width: 'fit-content', height: '40px' },
    _L: { width: 'fit-content', height: '48px' },
  },
  Input: {
    _S: { width: '100%', height: '32px' },
    _M: { width: '100%', height: '40px' },
    _L: { width: '100%', height: '48px' },
  },
  RadioButton: {
    _S: { width: '12px', height: '12px' },
    _M: { width: '16px', height: '16px' },
    _L: { width: '20px', height: '20px' },
  },
  Tooltip: {
    _S: { width: 'fit-content', height: '24px' },
    _M: { width: 'fit-content', height: '32px' },
    _L: { width: 'fit-content', height: '40px' },
  },
  Icon: {
    _5XL: '96px',
    _4XL: '64px',
    _3XL: '48px',
    _2XL: '40px',
    _XL: '32px',
    _L: '24px',
    _M: '20px',
    _S: '16px',
    _XS: '12px',
    _2XS: '10px',
    _3XS: '8px',
    _4XS: '6px',
  },
  StatusShares: {
    _L: '24px',
    _M: '20px',
    _S: '16px',
    _XS: '12px',
  },
  Avatar: {
    _3XL: {
      width: '96px',
      minWidth: '96px',
      height: '96px',
      minHeight: '96px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H3,
      },
    },
    _2XL: {
      width: '80px',
      minWidth: '80px',
      height: '80px',
      minHeight: '80px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H3,
      },
    },
    _XL: {
      width: '72px',
      minWidth: '72px',
      height: '72px',
      minHeight: '72px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H3,
      },
    },
    _L: {
      width: '64px',
      minWidth: '64px',
      height: '64px',
      minHeight: '64px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H4,
      },
      stackedText: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H5,
      },
    },
    _M: {
      width: '48px',
      minWidth: '48px',
      height: '48px',
      minHeight: '48px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H6,
      },
      stackedText: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H5,
      },
    },
    _S: {
      width: '40px',
      minWidth: '40px',
      height: '40px',
      minHeight: '40px',
      text: {
        variant: FontKeys.Variant.Heading,
        size: FontKeys.Size.H6,
      },
      stackedText: {
        variant: FontKeys.Variant.Paragraph,
        size: FontKeys.Size._L,
      },
    },
    _XS: {
      width: '32px',
      minWidth: '32px',
      height: '32px',
      minHeight: '32px',
      text: {
        variant: FontKeys.Variant.Paragraph,
        size: FontKeys.Size._L,
      },
      stackedText: {
        variant: FontKeys.Variant.Paragraph,
        size: FontKeys.Size._S,
      },
    },
    _2XS: {
      width: '24px',
      minWidth: '24px',
      height: '24px',
      minHeight: '24px',
      text: {
        variant: FontKeys.Variant.Caption,
        size: FontKeys.Size._M,
      },
    },
    _3XS: {
      width: '20px',
      minWidth: '20px',
      height: '20px',
      minHeight: '20px',
      text: {
        variant: FontKeys.Variant.Caption,
        size: FontKeys.Size._M,
      },
    },
  },
  StatusStep: {
    _S: '20px',
    _M: '24px',
  },
  iPad: {
    width: '800px',
    height: '544px',
  },
};

export const SizeConstants = {
  _5XL: '_5XL',
  _4XL: '_4XL',
  _3XL: '_3XL',
  _2XL: '_2XL',
  _XL: '_XL',
  _L: '_L',
  _M: '_M',
  _S: '_S',
};

export const Filter = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  width: 0;
  min-width: 40px;
  max-width: 400px;
  z-index: 2;
  padding: 0 ${Layout.Spacing._2XS};
  border: 1px solid ${Colors.Neutral._200};
  height: ${Layout.Spacing._M};
  border-radius: ${Effects.Border_Radius._S};
  background: ${({ open }) =>
    open ? Colors.Neutral._100 : Colors.Sharp.White};
`;

export const FilterContainer = styled(DisableContainer)`
  flex-grow: ${({ fullWidth }) => (fullWidth ? 1 : 0)};
  ${extendStyledComponents(Filter)};
  ${({ isFilterApplied, someOptionSelected }) =>
    highlightActiveFilter({ isFilterApplied, someOptionSelected })};
`;

export const FilterIcon = styled.div`
  ${Styles.flexCenter};
`;

export const Pagination = styled.div`
  height: 56px;
`;

export const TablePageContainer = styled.div`
  flex-grow: 1;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
  ${props =>
    props.noSearchResults || props.isLoading
      ? `#table,
        #pagination {
          display: none !important;
        }`
      : ''}
`;

export const Major = styled.div`
  grid-column: 1 / 9;
  height: 100%;
  gap: ${({ gap }) => Layout.Spacing[gap ?? '_XS']};
  ${Styles.flexColumn};
`;

export const Minor = styled.div`
  grid-column: 9 / 13;
  height: 100%;
  gap: ${({ gap }) => Layout.Spacing[gap ?? '_XS']};
  ${Styles.flexColumn};
`;
