import CountdownTimer from '../../../../../components/molecules/CountdownTimer/CountdownTimer';
import { Colors, FontKeys } from '../../../../../components/style';
import Instruction from '../../../components/templates/Layout/Instruction/Instruction';

export const CheckInInstructionLayout = ({
  isLoading,
  statusColor,
  details,
  imageIsLoading,
  timerDuration,
  onTimerEnd,
}) => {
  return (
    <Instruction
      isLoading={isLoading}
      statusColor={statusColor}
      details={details}
      imageIsLoading={imageIsLoading}
      headerElement={
        !isLoading && (
          <CountdownTimer
            onTimerEnd={onTimerEnd}
            time={timerDuration}
            textProps={{
              variant: FontKeys.Variant.Heading,
              size: FontKeys.Size.H5,
              weight: FontKeys.Weight.Semibold,
              color: Colors.Primary._600,
            }}
          />
        )
      }
    />
  );
};
