import { Colors } from '../../style';
import { Container } from './StaticSpinner.style';

const StaticSpinner = ({
  color = Colors.Neutral._200,
  size = 80,
  dotSize = 6,
  className,
}) => {
  return (
    <Container
      color={color}
      size={size}
      dotSize={dotSize}
      className={className ? className : ''}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Container>
  );
};
export default StaticSpinner;
