import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../../../components/atoms/Flex/Flex';
import { NoData } from '../../../../components/atoms/Icon/Icon.options';
import NoSearchResults from '../../../../components/templates/NoSearchResults/NoSearchResults';
import { useFooter } from '../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../providers/HeaderProvider/HeaderProvider';
import { usePermissions } from '../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionConsts } from '../../../../providers/PermissionProvider/variables';
import { possiblePermissions } from '../../routes/protected';

export const NoServices = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const { setShowHeader } = useHeader();
  const { setShowFooter } = useFooter();

  useEffect(() => {
    setShowHeader(false);
    setShowFooter(false);

    const hasSomeDefault = possiblePermissions.some(p =>
      hasPermission(p, PermissionConsts.Show)
    );

    if (!hasSomeDefault) return;
    const to = '/';
    navigate(to, { replace: true });
  }, []);

  return (
    <Flex column grow center>
      <NoSearchResults
        icon={NoData.Services}
        title="You have no services assigned"
        description="Contact your administrator to enable your Countercheck experience"
      />
    </Flex>
  );
};
