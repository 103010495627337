import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { cloneDeep } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useAddWhitelabelInput } from '../api/addWhitelabelInput';
import { EditWhiteLabelLayout } from '../components/EditWhiteLabelLayout';
import { formatInitialValues } from './utils';
import {
  editFieldProps,
  editFieldsPlacholders,
  getCancelButton,
  getPrintButton,
  getSaveButton,
  validationSchema,
} from './variables';

const { SELF, CREATE_WHITE_LABELS, EDIT_WHITE_LABELS } =
  PermissionKeys.Brand.WHITE_LABEL;

const EditWhiteLabel = ({
  data: prevData,
  onSuccess,
  customerDataOptions,
  parcelId,
  title,
  focusSearchBar,
}) => {
  const [onlySenders, setOnlySenders] = useState(false);

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarWarning, showSnackbarSuccess } =
    useSnackbar();

  const { initialValues, senderField, receiverFields } = formatInitialValues(
    cloneDeep(prevData),
    customerDataOptions
  );

  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasPrintPermission = hasPermission([
    EDIT_WHITE_LABELS,
    CREATE_WHITE_LABELS,
  ]);

  const { data, body, isLoading, isError, error, doAddWhitelabelInput } =
    useAddWhitelabelInput();

  useEffect(() => {
    if (isLoading || data == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess('Whitelabel successfully updated');
    const {
      shouldPrint,
      prev_cep_label: { barcodes },
      parcel_id,
    } = body;
    onSuccess({ ...data, shouldPrint, barcodes, newLabel: !parcel_id });
    dismiss();
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (prevData == null) {
      showSnackbarWarning();
      dismiss();
    }
  }, [prevData]);

  useEffect(() => {
    return () => focusSearchBar();
  }, []);

  const handleSubmit = shouldPrint => values => e => {
    const body = {
      ...values,
      // lazy implementation. sender and receiver don't need all of the values
      sender: { ...values },
      receiver: { ...values },
      prev_cep_label: {
        barcodes: [prevData.barcode?.data ?? prevData.barcode],
      },
      shouldPrint,
      should_return_to_sender: onlySenders,
    };
    if (parcelId) body.parcel_id = parcelId;
    doAddWhitelabelInput(body);
  };

  const handleSave = handleSubmit(false);
  const handlePrint = handleSubmit(true);

  const toggleOnlySenders = validateForm => () => () => {
    setOnlySenders(x => !x);
    setTimeout(() => {
      validateForm();
    }, 0);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(onlySenders)}
      validateOnMount={true}
    >
      {({ isValid, setFieldValue, validateForm, setTouched, values }) => {
        const selectOption =
          ({ name, value }) =>
          _ =>
            setFieldValue(name, value);

        return (
          <Form>
            <EditWhiteLabelLayout
              values={values}
              title={title}
              setTouched={setTouched}
              toggleOnlySenders={toggleOnlySenders(validateForm)}
              onlySenders={onlySenders}
              selectOption={selectOption}
              senderFields={senderField}
              receiverFields={receiverFields}
              placeholder={editFieldsPlacholders}
              fieldProps={editFieldProps}
              disablePrint={!isValid || isLoading}
              cancelButton={getCancelButton(dismiss)}
              saveButton={getSaveButton(handleSave(values))}
              printButton={
                hasPrintPermission && getPrintButton(handlePrint(values))
              }
              customerDataOptions={customerDataOptions}
              isLoading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(EditWhiteLabel);
