import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Sidebar } from '../../../../../components/organisms/Sidebar';
import {
  Brand,
  LogoAndVersion,
  MoreIconsScroll,
  NavigationIcons,
  Profile,
  SidebarSeparator,
} from '../../../../../components/organisms/Sidebar/components';
import { Layout } from '../../../../../components/style';
import { NotificationsIcon } from './components';
import { NavigationIconsScroll } from './style';
import { menuOptionKeys } from './variables';

const PrivateSidebarLayout = ({
  // sidebar props
  expanded,
  transition,
  // component props
  navigationIcons,
  image,
  username,
  email,
  brandImage,
  brandName,
  showMenuOption,
  menuOption,
  brandLogoRef,
  navigationIconScrollRef,
  showScrollUp,
  showScrollDown,
  notificationsIconsRef,
  userIconRef,
  hasUnreadNotifs,
  unreadCount,
}) => {
  return (
    <Flex column h w>
      <Brand
        brandImage={brandImage}
        brandName={brandName}
        expanded={expanded}
        onClick={showMenuOption(menuOptionKeys.brandOptions)}
        ref={brandLogoRef}
      />
      <Spacer height="_M" />
      {showScrollUp && (
        <MoreIconsScroll direction={'up'} ref={navigationIconScrollRef} />
      )}
      <NavigationIconsScroll ref={navigationIconScrollRef}>
        <NavigationIcons
          icons={navigationIcons}
          expanded={expanded}
          transition={transition}
          unreadCount={unreadCount}
        />
      </NavigationIconsScroll>
      {showScrollDown && (
        <MoreIconsScroll direction={'down'} ref={navigationIconScrollRef} />
      )}
      <SidebarSeparator />
      <NotificationsIcon
        expanded={expanded}
        transition={transition}
        hovered={menuOption === menuOptionKeys.notifications}
        onClick={showMenuOption(menuOptionKeys.notifications)}
        hasUnreadNotifs={hasUnreadNotifs}
        ref={notificationsIconsRef}
      />
      <Flex grow />
      <Flex column w justify="end" margin={`${Layout.Spacing._2XL} 0 0`}>
        <Profile
          expanded={expanded}
          image={image}
          username={username}
          email={email}
          userIconRef={userIconRef}
          onClick={showMenuOption(menuOptionKeys.account)}
        />
        <SidebarSeparator />
        <LogoAndVersion version={'Beta'} />
      </Flex>
    </Flex>
  );
};
export default Sidebar(PrivateSidebarLayout);
