import { useEffect, useRef, useState } from 'react';

// TODO: delay first trigger until they've stopped typing for a few 100ms
export const useSearchQueue = ({ searchParams, isLoading, triggerSearch }) => {
  const [searchQueue, setSearchQueue] = useState([]);
  const skipFirst = useRef(true);

  useEffect(() => {
    if (skipFirst.current) {
      skipFirst.current = false;
      return;
    }
    const newSearchValue = searchParams?.searchValue;
    const functionalUpdate = values => [...values, newSearchValue];

    setSearchQueue(functionalUpdate);
  }, [searchParams.searchValue]);

  useEffect(() => {
    if (!searchQueue?.length) return;
    if (isLoading) {
      const newValue = searchQueue[searchQueue.length - 1];
      if (newValue === searchQueue[0]) return;
      setSearchQueue([newValue]);
    } else {
      const newValues = [...searchQueue];
      const newValue = newValues.shift() ?? '';
      triggerSearch(newValue);
      setSearchQueue(newValues);
    }
  }, [searchQueue, isLoading]);
};
