import { Flex } from '../../components/atoms/Flex/Flex';
import { Label } from '../../components/molecules/Label/Label';
import { Colors } from '../../components/style';
import { Code2FAInput } from './molecules/Molecules';
import { InfoCard } from './organisms/Organisms';
import { Verify2FAContainer } from './style';

const Verify2FALayout = ({
  title,
  subtitle,
  codePlaceholder,
  codeName,
  codeMaxLength,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  minorRightIcon,
  validationSchema,
  initialValues,
  onSubmit,
}) => {
  return (
    <Verify2FAContainer>
      <InfoCard
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        title={title}
        minorButtonClick={minorButtonClick}
        minorButtonText={minorButtonText}
        mainButtonText={mainButtonText}
        minorRightIcon={minorRightIcon}
      >
        <Flex gap="_M" column>
          <Label text={subtitle} textColor={Colors.Neutral._900} size="_L" />
          <Code2FAInput
            placeholder={codePlaceholder}
            name={codeName}
            maxLength={codeMaxLength}
          />
          <div />
        </Flex>
      </InfoCard>
    </Verify2FAContainer>
  );
};
export default Verify2FALayout;
