import styled from '@emotion/styled';
import { Flex } from '../../../components/atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../../components/style';
import { Styles } from '../../../constants';

export const NotificationsContainer = styled.div`
  width: calc(280px - ${Layout.Spacing._S} * 2);
  height: 100vh;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const NotificationCSS = styled.div`
  cursor: ${props => (props.hasLink ? 'pointer' : 'auto')};
  gap: ${Layout.Spacing._XS};
  ${Styles.flex};
`;

export const UnreadCircle = styled.div`
  width: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius.Circle};
  background-color: ${Colors.Primary._600};
`;

export const AnnouncementCSS = styled(Flex)`
  * {
    cursor: pointer;
  }
`;

export const AnnouncementEmojiCSS = styled.div`
  ${Styles.flexCenter};
  width: ${Layout.Spacing._M};
  height: ${Layout.Spacing._M};
  border-radius: ${Effects.Border_Radius._2XS};
`;

export const AnnouncementsContainer = styled(Flex)`
  gap: ${Layout.Spacing._S};
`;

export const AnnouncementText = styled(Flex)`
  padding-right: ${Layout.Spacing._M};
`;

export const AnnouncementsScroll = styled(Flex)`
  max-height: 216px;
  overflow-y: auto;
`;
