import {
  Activity,
  Authentication,
  Categories,
  Origin,
  Overview,
  ParcelStatuses,
  Populars,
  Timeline,
} from './organisms/organisms';
import { DashboardContainer } from './style';

const DashboardLayout = ({
  parcelStatuses,
  overviewStats,
  authenticationStats,
  evalNavigateButton,
  populars,
  popularsBars,
  timeline,
  timelineLines,
  timelineKeys,
  origins,
  total,
  main,
  categories,
  activity,
  popularsTitle,
  overviewIsLoading,
  popularsIsLoading,
  timelineIsLoading,
  originsIsLoading,
  categoriesIsLoading,
  activityIsLoading,
  overviewIsError,
  popularsIsError,
  timelineIsError,
  originsIsError,
  categoriesIsError,
  activityIsError,
  originColors,
  inactiveBrand,
  onOverviewClick,
  onOriginClick,
  onAuthenticationClick,
  onParcelStatusClick,
  onCategoryClick,
  onActivityClick,
  hasProductPermission,
  hasReferencePermission,
}) => {
  return (
    <DashboardContainer>
      <Overview
        overviewStats={overviewStats}
        isLoading={overviewIsLoading}
        isError={overviewIsError}
        onClick={onOverviewClick}
      />
      <Authentication
        authenticationStats={authenticationStats}
        evalNavigateButton={evalNavigateButton}
        isLoading={overviewIsLoading}
        isError={overviewIsError}
        onClick={onAuthenticationClick}
        hasProductPermission={hasProductPermission}
        disabled={!hasProductPermission}
      />
      <Populars
        title={popularsTitle}
        populars={populars}
        popularsBars={popularsBars}
        isLoading={popularsIsLoading}
        isError={popularsIsError}
        inactiveBrand={inactiveBrand}
        disabled={!hasProductPermission}
      />
      <Timeline
        timeline={timeline}
        timelineLines={timelineLines}
        timelineKeys={timelineKeys}
        isLoading={timelineIsLoading}
        isError={timelineIsError}
        inactiveBrand={inactiveBrand}
        disabled={!hasProductPermission}
      />
      <ParcelStatuses
        parcelStatuses={parcelStatuses}
        isLoading={overviewIsLoading}
        isError={overviewIsError}
        onClick={onParcelStatusClick}
        disabled={!hasProductPermission}
      />
      <Origin
        origins={origins}
        total={total}
        main={main}
        isLoading={originsIsLoading}
        isError={originsIsError}
        colors={originColors}
        inactiveBrand={inactiveBrand}
        onClick={onOriginClick}
        disabled={!hasReferencePermission}
      />
      <Categories
        categories={categories}
        isLoading={categoriesIsLoading}
        isError={categoriesIsError}
        onClick={onCategoryClick}
        disabled={!hasProductPermission}
      />
      <Activity
        activity={activity}
        isLoading={activityIsLoading}
        isError={activityIsError}
        onClick={onActivityClick}
        disabled={!hasProductPermission}
      />
    </DashboardContainer>
  );
};
export default DashboardLayout;
