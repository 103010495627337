import Notes from '../../../../../components/organisms/Notes/Notes';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useCreateNote } from '../../../features_public/reference_index/api/createNote';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';
import ReferenceCases from '../controllers/ReferenceCases';
import ReferenceInfo from '../controllers/ReferenceInfo';
import { referenceNotesConstants } from '../controllers/variables';
import { ReferenceSummary } from './organisms/organisms';
import { Major, Minor, ReferenceDetailsContainer } from './style';

const { SELF, EDIT_REFERENCE_NOTES } = PermissionKeys.Brand.REFERENCES;

export const ReferenceDetailsLayout = ({
  isAdmin,
  onEditSuccess,
  goBackTo,
  metrics,
  date,
  active,
  referenceInfo,
  referenceId,
  notes,
  onSuccessCreateNote,
  isLoading,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <ReferenceDetailsContainer className="reference-details">
      <Major>
        <ReferenceSummary metrics={metrics} date={date} active={active} />
        <ReferenceInfo
          referenceInfo={referenceInfo}
          referenceId={referenceId}
          goBackTo={goBackTo}
          isAdmin={isAdmin}
          onEditSuccess={onEditSuccess}
        />
      </Major>
      <Minor>
        <ReferenceCases referenceId={referenceId} isAdmin={isAdmin} />
        <Notes
          isAdmin={isAdmin}
          notes={notes}
          onSuccessCreateNote={onSuccessCreateNote}
          isLoading={isLoading}
          hook={useCreateNote(isAdmin)}
          params={{ [referenceIndexKeys.id]: referenceId }}
          {...referenceNotesConstants}
          hasEditPermission={hasPermission(EDIT_REFERENCE_NOTES)}
        />
      </Minor>
    </ReferenceDetailsContainer>
  );
};
