import styled from '@emotion/styled';
import {
  Layout,
  UtilityColors,
  UtilityEffects,
} from '../../../../../components/style';
import { ResponsiveSizes, Styles } from '../../../../../constants';

export const Container = styled.div`
  display: inline-flex;
  flex-grow: 1;
  position: relative;
  height: 100%;
  background: ${UtilityColors.Background_App};
  overflow-y: ${({ modalsActive }) => (modalsActive ? 'hidden' : 'auto')};
  min-height: ${ResponsiveSizes.minWindowHeight}px;
  height: ${({ modalsActive }) => (modalsActive ? '100vh;' : '')};
  left: ${({ sidebarWidth }) => sidebarWidth};
  width: ${({ sidebarWidth }) => `calc(100% - ${sidebarWidth || 0})`};
  transition: ${UtilityEffects.SidebarTransition('width', 'min-width', 'left')};
  ${({ customStyle }) => customStyle};
`;

export const PageContent = styled.div`
  position: relative;
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  min-width: ${ResponsiveSizes.minPageWidth}px;
  min-height: ${ResponsiveSizes.minWindowHeight}px;
`;

export const Content = styled.div`
  display: inline-flex;
  flex-grow: 1;
  z-index: 0;
  justify-content: flex-start;
  background: ${UtilityColors.Background_App};
`;

export const InnerContent = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  max-width: ${ResponsiveSizes.maxContentWidth}px;
  padding: ${Layout.Spacing._XS} ${Layout.Spacing._M};
  ${Styles.flexColumn};
  display: inline-flex;
`;
