import { Route, Routes } from 'react-router-dom';
import ReferenceDetails from './ReferenceDetails';
import ReferenceIndex from './ReferenceIndex';

export const ReferenceIndexRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ReferenceIndex />} />
    </Routes>
  );
};

export const ReferenceIndexDetailsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ReferenceDetails />} />
    </Routes>
  );
};
