import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import Spacer from '../../atoms/Spacer/Spacer';
import Button from '../../molecules/Button/Button';
import { Colors } from '../../style';
import { Container, PlaceLeft } from './Actions.style';

// TODO: replace all other Actions buttons for this organism
const Actions = ({
  onCancel,
  cancelText = 'Cancel',
  onCancelDisabled,
  onPrimary,
  primaryText = 'Save',
  hidePrimary,
  onAlternate,
  alternateText,
  alternateProps,
  onBack,
  backText = 'Back',
  onDelete,
  deleteText = 'Delete',
  gap = '_S',
  size = '_M',
  isLoading,
  disabled,
  hasSeparator,
}) => {
  return (
    <>
      {hasSeparator ? (
        <Flex w relative>
          <Spacer height={size} />
          <Separator style={{ top: 0, background: Colors.Neutral._200 }} />
        </Flex>
      ) : null}
      <Container
        gap={gap}
        fullWidth={!!onBack || !!onDelete}
        className="actions"
      >
        {onBack && (
          <PlaceLeft>
            <Button
              onClick={onBack}
              text={backText}
              size={size}
              disabled={isLoading}
              leftIcon={Icons.ChevronLeft}
              variant="Tertiary"
            />
          </PlaceLeft>
        )}
        {onDelete && (
          <PlaceLeft>
            <Button
              onClick={onDelete}
              text={deleteText}
              size={size}
              disabled={isLoading}
              leftIcon={Icons.Trash}
              variant="Tertiary"
            />
          </PlaceLeft>
        )}
        {onAlternate && (
          <Button
            onClick={onAlternate}
            text={alternateText}
            size={size}
            disabled={isLoading}
            variant="Tertiary"
            {...(alternateProps ?? {})}
          />
        )}
        {onCancel && (
          <Button
            onClick={onCancel}
            text={cancelText}
            size={size}
            disabled={isLoading || onCancelDisabled}
            variant="Secondary"
          />
        )}
        {onPrimary ? (
          <Button
            onClick={onPrimary}
            text={primaryText}
            size={size}
            isLoading={isLoading}
            disabled={disabled || isLoading}
            variant="Primary"
          />
        ) : !hidePrimary ? (
          <Button
            type="submit"
            text={primaryText}
            size={size}
            isLoading={isLoading}
            disabled={disabled || isLoading}
            variant="Primary"
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};
export default Actions;
