import { Card, Icon } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import {
  Badge,
  BadgeType,
} from '../../../../../components/molecules/Badge/Badge';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { ItemPreviewImage } from '../../../../../components/molecules/ItemPreviewImage/ItemPreviewImage';
import { CardLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { StatusShares } from '../../../../../components/molecules/StatusShares/StatusShares';
import { Colors, Size } from '../../../../../components/style';
import { SnapshotData } from './molecules';
import { SnapshotConent } from './style';

export const CaseSnapshotLayout = ({
  color,
  status,
  image,
  caseDetails,
  shares,
  title,
  onClick,
  showGallery = () => {},
  caseImageRef,
  isLoading,
  imagesIsLoading,
}) => {
  return (
    <Card
      className="case-snapshot"
      color={color}
      title={
        title || onClick ? (
          <Flex h justify={'between'}>
            {title ? (
              <CopyableText text={`Parcel ${title}`} copyText={title} />
            ) : null}
            {onClick ? (
              <Icon
                name={Icons.ExternalLink}
                color={Colors.Neutral._400}
                size={Size.Icon._M}
              />
            ) : null}
          </Flex>
        ) : null
      }
      onClick={onClick}
    >
      {isLoading ? (
        <CardLoader />
      ) : (
        <Flex gap="_S">
          {imagesIsLoading ? (
            <div className="pulse">
              <ItemPreviewImage size="_XL" />
            </div>
          ) : (
            <ItemPreviewImage
              src={image}
              size="_XL"
              onClick={showGallery}
              caseImageRef={caseImageRef}
            />
          )}

          <SnapshotConent column gap="_XS" className="snapshot-content">
            <SnapshotData productDetails={caseDetails} />
            <Flex gap="_S">
              <Badge type={BadgeType.status} text={status} size={'_S'} />
              <StatusShares shares={shares} size="_L" maxWidth={120} />
            </Flex>
          </SnapshotConent>
        </Flex>
      )}
    </Card>
  );
};
