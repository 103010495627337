import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Colors, FontKeys } from '../../style';

export const Container = styled.div`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const CenterLabel = ({ text, customLabel }) => {
  const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    ${Styles.flexCenter}
  `;

  return (
    <Container>
      {customLabel ? (
        customLabel(text)
      ) : (
        <Text
          text={text}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Semibold}
          color={Colors.Primary._600}
        />
      )}
    </Container>
  );
};
