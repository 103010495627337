import useFetchData from '../../../hooks/fetchHook';

export const useGetAnnouncementDetails = ({ id }) => {
  const [{ data = { slides: [] }, isLoading = true, isError, error }, _] =
    useFetchData(`announcement/${id}`, {
      formatter,
      failureFormatter: () => ({ slides: [] }),
    });

  return { data, isLoading, isError, error };
};

const formatter = data => {
  const {
    annID: id,
    annIcon: icon,
    annTitle: title,
    annContent: content,
    annCTATitle: CTATitle,
    annCTALink: link,
  } = data;

  const slides = data.media.map(media => {
    const {
      annmMedia: image,
      annmType: type,
      annmTitle: title,
      annmDescription: description,
    } = media;
    return { image, type, title, description };
  });

  const announcement = {
    slides,
    id,
    icon,
    title,
    content,
    link,
    CTATitle,
  };

  return announcement;
};
