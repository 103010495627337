import { useReducer } from 'react';
import { customTableReducer } from '../../../../../hooks/tableHook';

export const useProcessingReducer = ({
  initialSearchParams,
  resetSearchParams,
}) => {
  const [searchParams, dispatch] = useReducer(
    customTableReducer({ resetSearchParams, customReducer }),
    initialSearchParams
  );
  return [searchParams, dispatch];
};

const customReducer = (state, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};
