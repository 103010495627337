import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actingBrandFilter } from '../../../../../components/organisms/ActingBrandSelection/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { NavURIs, Pages } from '../../../routes/variables';
import { createTicket, useCreateTicket } from '../api/createTicket';
import { HelpLayout } from '../components/HelpLayout';
import {
  HelpConstants,
  TicketConstants,
  helpValidationSchema,
} from './variables';

const { SELF: SUPPORT, CREATE_GENERAL_SUPPORT_TICKETS } =
  PermissionKeys.Brand.SUPPORT;

const Help = ({ onBack }) => {
  const [helpType, setHelpType] = useState();
  const [details, setDetails] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('Normal');

  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const { dismissAll } = useModal();
  const { showSnackbarError } = useSnackbar();
  const { permissions } = usePermissions();

  const { data, body, isLoading, isError, error, doCreateTicket } =
    useCreateTicket();

  const handleCreateTicket = values => () => {
    createTicket({
      selectedProduct: {},
      title: helpType,
      selectedPriority,
      details,
      brandId: values['brand']?.value,
      doCreateTicket,
      type: helpType,
    });
  };

  const selectHelpType = o => _ => setHelpType(o);

  const detailsChangeHandler = e => {
    const { value } = e.target;
    setDetails(value);
  };

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const to = `${NavURIs[Pages.support]}/${data}`;
    navigate(to);
    dismissAll();
  }, [isLoading, body, isError]);

  useEffect(() => {
    const isValid = helpType && selectedPriority && details && !isLoading;
    setIsValid(isValid);
  }, [helpType, selectedPriority, details, isLoading]);

  const selectPriority = v => _ => setSelectedPriority(v);

  return (
    <Formik
      initialValues={{ moreDetails: '' }}
      validationSchema={helpValidationSchema}
      validateOnMount={true}
    >
      {({ isValid: formikIsValid, setFieldValue, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });
        return (
          <HelpLayout
            actingBrandProps={{
              selectOption,
              value: values['brand'],
              label: 'Brand',
              brandsFilter: actingBrandFilter(
                permissions,
                SUPPORT,
                CREATE_GENERAL_SUPPORT_TICKETS
              ),
            }}
            selectHelpType={selectHelpType}
            chosenHelptype={helpType}
            helpTypeTitle={HelpConstants.Titles.helpType}
            helpTypeTSubtitle={HelpConstants.Titles.helpTypeSubtitle}
            moreDetailsTitle={HelpConstants.Titles.moreDetails}
            moreDetailsPlaceholder={HelpConstants.Titles.moreDetailsPlaceholder}
            options={HelpConstants.helpTypeOptions}
            detailsChangeHandler={detailsChangeHandler}
            onBack={onBack}
            cancel={() => dismissAll()}
            cancelText={HelpConstants.Actions.cancel}
            createTicket={handleCreateTicket(values)}
            createTicketText={HelpConstants.Actions.createTicket}
            disabled={!isValid || !formikIsValid}
            isLoading={isLoading}
            selectPriorityTitle={TicketConstants.Titles.selectPriority}
            priorityOptions={TicketConstants.priorityOptions}
            selectPriority={selectPriority}
            chosenPriority={selectedPriority}
          />
        );
      }}
    </Formik>
  );
};
export default Modal(Help);
