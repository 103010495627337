import { Text } from '../../atoms';
import { FontKeys } from '../../style';
import { MenuOptionCSS, MenuOptionsCSS, MenuOptionsHoverArea } from './style';

export const MenuOptions = ({
  options,
  optionClick,
  selectedOption,
  position,
  show,
  menuRef,
  onPointerOver,
  onPointerOut,
  optionDisplay,
}) => {
  return (
    <MenuOptionsHoverArea
      className="menu-options"
      {...position}
      show={show}
      ref={menuRef}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      <MenuOptionsCSS>
        {options.map(option => {
          const { value } = option;
          return (
            <MenuOption
              key={value}
              option={option}
              onClick={optionClick(option)}
              selected={selectedOption.value === value && !!value}
              customTextDisplay={optionDisplay}
            />
          );
        })}
      </MenuOptionsCSS>
    </MenuOptionsHoverArea>
  );
};

const MenuOption = ({ option, selected, onClick, customTextDisplay }) => {
  return (
    <MenuOptionCSS selected={selected} onClick={onClick}>
      {customTextDisplay ? (
        customTextDisplay(option, selected)
      ) : (
        <OptionTitle bold={selected} text={option.label} />
      )}
    </MenuOptionCSS>
  );
};

const OptionTitle = ({ text, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight[bold ? 'Semibold' : 'Regular']}
      whiteSpace="nowrap"
    />
  );
};
