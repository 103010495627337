import styled from '@emotion/styled';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { Icon } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, Size } from '../../style';

const CopyableIcon = ({ text, size = '_S', hide = false }) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const Component = hide ? HiddenCopyIcon : Icon;

  const copyText = text => e => {
    e.stopPropagation();
    return (
      text &&
      navigator.clipboard.writeText(text).then(
        () => showSnackbarSuccess('Copied to clipboard'),
        () => showSnackbarError('Please try again')
      )
    );
  };

  return (
    <Component
      name={Icons.Copy}
      size={Size.Icon[size]}
      onClick={copyText(text)}
      color={Colors.Neutral._400}
      // theme={hide ? 'neutral' : 'light'}
    />
  );
};

export default CopyableIcon;

const HiddenCopyIcon = styled(Icon)`
  opacity: 0;
  && {
    cursor: pointer;
  }
  &:hover {
    opacity: 1;
  }
`;
