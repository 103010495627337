import { isEmpty, uniqueId } from 'lodash';
import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import Button from '../../../../../../components/molecules/Button/Button';
import { Card } from '../../../../../../components/molecules/Card/Card';
import CopyableText from '../../../../../../components/molecules/CopyableText/CopyableTitle';
import { Delta } from '../../../../../../components/molecules/Delta/Delta';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import { disabledText } from '../../../../../../components/molecules/Disabled/variables';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import { Colors, Layout, Size } from '../../../../../../components/style';
import { isArray } from '../../../../../../lib/js';
import { colorCardPadding } from '../../../../../../utils/utils';
import {
  ErrorText,
  ItemData,
  ItemSubdata,
  ItemTitle,
  LoadingAnimation,
  NavigateIcon,
  OverviewIcon,
  ParcelAliasText,
  StatusCardMessage,
} from '../atoms/atoms';
import {
  AliasRowContainer,
  ParcelAliasContainer,
  ParcelListScrollContainer,
  ParcelStatusCSS,
  StatusDot,
  StyledDelta,
} from '../style';

const NavigateStat = ({
  title,
  tooltipContent,
  count,
  titleIcon,
  cumulative,
}) => {
  return (
    <Flex column gap="_XS">
      <Flex gap="_2XS">
        <Tooltip content={tooltipContent}>
          <ItemTitle text={title} />
        </Tooltip>
        {titleIcon && <OverviewIcon {...titleIcon} />}
      </Flex>
      <Flex column gap="_2XS">
        <ItemCount {...count} />
        {cumulative && <CumulativeCount {...cumulative} />}
      </Flex>
    </Flex>
  );
};

const CumulativeCount = ({ text, onClick, icon }) => {
  return (
    <Flex gap="_2XS" onClick={onClick} align={'center'}>
      <ItemSubdata text={text} />
      <OverviewIcon {...icon} />
    </Flex>
  );
};

const ItemCount = ({ count, countIcon, onClick }) => {
  return (
    <Flex onClick={onClick} gap="_2XS">
      <ItemData text={count} />
      {countIcon && <OverviewIcon {...countIcon} />}
    </Flex>
  );
};

export const OverviewStat = ({ total, index }) => {
  return (
    <Flex column gap={'_XS'} w0 grow key={index}>
      <NavigateStat {...total} key={uniqueId(total.key)} />
      <Delta {...total} />
    </Flex>
  );
};

export const ParcelStatus = ({
  status,
  tooltipContent,
  onClick,
  count,
  color,
  itemCount,
  parcelCount,
  navigate,
  isLoading,
  isError,
  disabled,
}) => {
  return (
    <ParcelStatusCSS>
      <Card color={color} padding={colorCardPadding()}>
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Flex justify="between">
              <Tooltip content={tooltipContent}>
                <ItemTitle text={status} />
              </Tooltip>
            </Flex>

            <ItemData text={count} />
            <Flex justify={'between'} align={'end'}>
              <Flex column>
                <ItemSubdata text={parcelCount ?? ''} black />
                <ItemSubdata text={itemCount ?? ''} />
              </Flex>
              {!!onClick && <NavigateIcon onClick={onClick} />}
            </Flex>
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText._1Row} />}
    </ParcelStatusCSS>
  );
};

const ItemSubdataDisplay = ({ itemSubdata }) => {
  const { parcelCount, itemCount } = itemSubdata;

  return (
    <Flex column>
      {parcelCount && <ItemSubdata text={parcelCount} black />}
      {itemCount && <ItemSubdata text={itemCount} />}
    </Flex>
  );
};

export const StatusDetailsContent = ({
  status,
  button,
  details,
  messageAndDelta,
}) => {
  return (
    <Flex column w h gap="_S">
      <Flex justify="between" w>
        <StatusDetailsTitleBadges status={status} />
        <Button
          {...button}
          size="_S"
          rightIcon={Icons.ChevronRight}
          variant="Tertiary"
        />
      </Flex>
      <StatusDetailsContentDetails
        details={details}
        messageAndDelta={messageAndDelta}
      />
      {messageAndDelta && <MessageAndDelta messageAndDelta={messageAndDelta} />}
    </Flex>
  );
};

const StatusDetailsTitleBadges = ({ status }) => {
  return (
    <Flex gap="_XS">
      {isArray(status) ? (
        status.map((status, index) => (
          <Badge type={BadgeType.status} text={status} size="_S" key={index} />
        ))
      ) : (
        <Badge type={BadgeType.status} text={status} size="_S" />
      )}
    </Flex>
  );
};

const StatusDetailsContentDetails = ({ details }) => {
  return (
    <Flex w justify="between">
      {details.map((detail, index) => (
        <StatusDetail {...detail} key={index} />
      ))}
    </Flex>
  );
};

const MessageAndDelta = ({ messageAndDelta }) => {
  const { message, delta } = messageAndDelta;
  return (
    <Flex justify="between" gap="_XS" w>
      <StatusCardMessage text={message} />
      <StyledDelta {...delta} horizontal={false} />
    </Flex>
  );
};

const StatusDetail = ({
  title,
  tooltipContent,
  onClick,
  statusDotColor,
  count,
  itemSubdata = {},
}) => {
  return (
    <Flex w0 grow column gap="_XS">
      <Flex gap="_2XS" align="center">
        <Tooltip content={tooltipContent}>
          <ItemTitle text={title} />
        </Tooltip>
        {statusDotColor && <StatusDot color={statusDotColor} />}
        {onClick && <NavigateIcon onClick={onClick} />}
      </Flex>
      <ItemData text={count} />
      {!isEmpty(itemSubdata) && (
        <ItemSubdataDisplay itemSubdata={itemSubdata} />
      )}
    </Flex>
  );
};

// TODO: DRY THIS
export const ErrorDisplay = () => (
  <Flex column gap="_S" justify="center" align="center" w h>
    <Icon
      name={Icons.AlertTriangle}
      color={Colors.Neutral._500}
      size={Size.Icon._L}
    />
    <ErrorText text={'Oops! Something went wrong'} />
  </Flex>
);

export const ParcelListScroll = ({ parcels }) => {
  const rows = [];

  for (let i = 0; i < parcels.length; i += 4) {
    rows.push(parcels.slice(i, i + 4));
  }
  return (
    <ParcelListScrollContainer>
      {rows.map((parcel, index) => (
        <AliasRow
          parcels={parcel}
          isLast={index === rows.length - 1}
          key={index}
        />
      ))}
    </ParcelListScrollContainer>
  );
};

const AliasRow = ({ parcels, isLast }) => {
  return (
    <AliasRowContainer
      w
      relative
      padding={`${Layout.Spacing._XS} 0 ${Layout.Spacing._XS} ${Layout.Spacing._S}`}
      justify={isLast ? 'start' : 'between'}
    >
      {parcels.map((parcel, index) => (
        <ParcelAlias parcel={parcel} key={index} />
      ))}
      {isLast ? '' : <Separator />}
    </AliasRowContainer>
  );
};

const ParcelAlias = ({ parcel }) => {
  return (
    <ParcelAliasContainer gap="_2XS" align={'center'} w justify={'between'}>
      <ParcelAliasText text={<CopyableText text={parcel.alias} />} />
      <AnimatedIcon
        name={Icons.ExternalLink}
        size={Size.Icon._M}
        theme="dashboard_icon"
        onClick={parcel.onClick}
      />
    </ParcelAliasContainer>
  );
};
