import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { filterOptionsFuzzy } from '../../../utils/utils';
import { Icon } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Layout, Size, UtilityColors } from '../../style';
import { Input } from '../Input/Input';
import {
  NoResults,
  Option,
  OptionText,
  Options,
  OptionsFilter,
  Placeholder,
  SelectContainer,
} from './Select.style';

const Select = ({
  disabled,
  field,
  onClick = () => {},
  options = [],
  placeholder,
  size = '_S',
  selectOption,
  setTouched = Utils.emptyFunction,
  readOnly,
  ...props
}) => {
  // setTouched might be an empty function. The field: ${field.name} will not show an error
  const [showOptions, setShowOptions] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(options);
  const [optionsFilter, setOptionsFilter] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [highlight, setHightlight] = useState(-1);

  const filterRef = useRef(null);
  const scrollRef = useRef();

  const hideOptions = e => {
    // NOTE: field.onBlur(e) should do the trick, but for some reason it's not working
    // it's a better solution since you don't need to remember to pass a setTouched function
    // everytime you use a select. Using setTouched is a working solution.
    setTouched({ [field.name]: true });
    //   // field.onBlur(e);
    setShowOptions(false);
    setHightlight(-1);
  };

  useEffect(() => {
    if (!showOptions) setFiltering(false);
    clearFiltering();
  }, [showOptions]);

  useEffect(() => {
    const filteredOptions = filterOptionsFuzzy(options, optionsFilter, [
      'country-code',
      'name',
      'value',
      'label',
      'titleLabel',
    ]);
    setDisplayOptions(filteredOptions);
  }, [options, optionsFilter]);

  useEffect(() => {
    if (highlight < 2) return;
    const optionHeight = Size.Input[size].height.replace('px', '');
    scrollRef.current.scrollBy({ top: (highlight - 2) * optionHeight });
  }, [highlight]);

  const toggleShowOptions = show => {
    setShowOptions(x => {
      setFiltering(show ?? !x);
      return show ?? !x;
    });
  };

  const handleClick = e => {
    e?.stopPropagation();
    if (!disabled) toggleShowOptions();
    onClick(e);
  };

  const handleSelect = fn => () => {
    fn();
    clearFiltering();
  };

  const handleKeyDown = e => {
    const { code } = e;
    toggleShowOptions(code !== 'Tab');

    if (code === 'ArrowDown') keyboardHighlight(1);
    if (code === 'ArrowUp') keyboardHighlight(-1);
    if (code === 'Enter') selectKeyboardSelectedOption(e);
  };

  const handleKeyUp = e => {
    const { innerHTML: value } = e.target;
    setOptionsFilter(value);
  };

  const clearFiltering = () => {
    if (filterRef.current) filterRef.current.innerHTML = '';
    setOptionsFilter('');
  };

  const displayLabel =
    field?.value?.label ?? field?.value?.value ?? field?.value;

  const fieldValue = field.value?.value ?? field.value;

  const keyboardHighlight = val =>
    setHightlight(
      h => (h + val + displayOptions.length) % displayOptions.length
    );

  const selectKeyboardSelectedOption = e => {
    if (highlight >= displayOptions.length) return;

    handleSelect(
      selectOption({ ...field, ...displayOptions[highlight], name: field.name })
    )(e);
    hideOptions(e);
    filterRef.current?.blur();
  };

  return (
    <>
      <SelectContainer disabled={disabled}>
        <div
          style={{
            opacity: filtering ? 0 : 1,
            paddingRight: Layout.Spacing._2XS,
            width: 0,
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <OptionText
            text={displayLabel}
            bold
            size={size}
            disabled={disabled}
            readOnly={readOnly}
          />
        </div>
        <Placeholder>
          {!showOptions && !fieldValue && (
            <Input
              placeholder={placeholder}
              size={size}
              name="ignore"
              tabIndex={-1}
            />
          )}
        </Placeholder>
        <Options
          size={size}
          noOfShownOptions={displayOptions.length}
          showOptions={showOptions}
          scrollRef={scrollRef}
        >
          {displayOptions.length ? (
            displayOptions.map((option, index) => {
              const { label, value } = option;
              const selected =
                value === field.value || value === field.value?.value;
              const highlighted = index === highlight;
              return (
                <Option
                  value={value}
                  selected={selected}
                  highlighted={highlighted}
                  label={label}
                  size={size}
                  key={value}
                  onClick={handleSelect(
                    selectOption({ ...field, ...option, name: field.name })
                  )}
                />
              );
            })
          ) : (
            <NoResults size={size} />
          )}
        </Options>
        <Icon
          name={Icons.ChevronDown}
          color={disabled ? UtilityColors.Disabled : UtilityColors.Text}
        />
      </SelectContainer>
      <OptionsFilter
        {...props}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onClick={handleClick}
        ref={filterRef}
        size={size}
        contentEditable={!disabled}
        tabIndex="0"
        onBlur={hideOptions}
      />
    </>
  );
};

export default Select;
