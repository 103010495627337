import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { Colors, Size } from '../../../../../components/style';
import { Constants, re } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry, parseStringModifiers } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import {
  activityToggleOptions,
  productKeys,
} from '../../../features_public/products/controllers/variables';
import { formatTickets } from '../../support/api/getTickets';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
  statusActionsProps,
} from '../controllers/variables';
import { statusStepsHistory } from '../variables';

const { fallback } = Constants;

export const useGetProductDetails = ({ productId, hasPermission, ignore }) => {
  const [
    {
      data = { images: [] },
      isLoading = !!productId && !ignore,
      isError,
      error,
    },
    getProductDetails,
    refetchData,
  ] = useFetchData(`office_case/${productId}`, {
    formatter: formatter({
      hasPermission,
    }),
    ignore: ignore || !productId,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    getProductDetails: productId => {
      getProductDetails({ url: `office_case/${productId}` });
    },
    refetchData,
  };
};

export const formatter =
  ({ hasPermission }) =>
  data => {
    const {
      activity: activityResponse,
      actors,
      attachments,
      auEmail: authorityEmail,
      auName: authorityName,
      auPhone: authorityPhone,
      //   caID: productId,
      caAlias: productAlias,
      caStatus: status,
      caStatusNext: productActions,
      notes,
      caParcel: parcelId,
      paAlias: caseAlias,
      paRiskType: riskType_,
      parcel_activity: parcelActivityResponse,

      //   // pdMatchType: matchType,
      should_show_actors,
      // soID: sorterId,
      // summary response
      // activity: activityResponse,
      caAlias: alias,
      caID: productId,
      // caStatus: status,
      soCity: city,
      soCountry: country,
      soName: sorter,
      bcName: category,
      cpName: subcategory,
      cspQuantity: quantity,
      is_case_owner: isProductOwner,
      // paRiskType: riskType_,
      // // pdMatchType: matchType,
      pdConfidence: confidence,
      photos,
      sender_brand_name: senderBrandName,
      // soID: sorterId,
      tickets: tickets_,
      brName: brandName,
      brID: brandId,
      product_name: categoryName,
      product_id: categoryId,
      brIsContracted: isContracted,
    } = data;

    const shouldBlurActors = actors == null || !should_show_actors;

    const activity = formatActivity(activityResponse);

    const authorityDetails = formatAuthorityDetails({
      authorityEmail,
      authorityName,
      authorityPhone,
    });

    const productCategory =
      joinStrings(category, subcategory, Constants.fallback) ??
      Constants.fallback;

    const productDetails = formatProductDetails({
      productCategory,
      [productKeys.brand]: brandName,
      [productKeys.sorterName]: sorter,
      [productKeys.productQuantity]: quantity,
      [productKeys.isContracted]: isContracted,
    });

    const reason = {
      title: getReasonTitle(senderBrandName),
      description: getSideloadExplanation(senderBrandName, riskType_),
      riskPotential: {
        value: confidence ?? 0,
        display: `${confidence == null ? 'n/a' : `${confidence}%`}`,
        fill: Colors.Primary._600,
      },
    };

    const tickets = formatTickets(tickets_);

    return {
      [productKeys.activity]: activity,
      [productKeys.productId]: productId,
      [productKeys.productDetails]: productDetails,
      [productKeys.photos]: photos,
      [productKeys.isProductOwner]: isProductOwner,
      [productKeys.reason]: reason,
      [productKeys.riskType]: getRiskTypes(riskType_),
      [productKeys.productStatus]: status,
      [productKeys.tickets]: tickets,
      [productKeys.productAlias]: alias,
      [productKeys.brand]: brandName,
      [productKeys.brandId]: brandId,
      [productKeys.categoryName]: categoryName,
      [productKeys.categoryId]: categoryId,
      [productKeys.productQuantity]: quantity,
      [productKeys.isContracted]: isContracted,
      id: productId,
      [caseKeys.alias]: caseAlias,
      [caseKeys.id]: parcelId,
      [productKeys.attachments]: formatAttachments(attachments),
      [productKeys.productAlias]: productAlias,
      [productKeys.authorityDetails]: authorityDetails,
      [productKeys.notes]: notes,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.steps]: statusStepsHistory[status],
      [productKeys.activity]: {
        [activityToggleOptions[0].value]: formatParcelActivity(
          parcelActivityResponse
        ),
        [activityToggleOptions[1].value]: formatActivity(activityResponse),
      },
      [productKeys.actions]: getProductActions({
        hasPermission,
        productActions,
      }),
    };
  };

const getProductActions = ({ hasPermission, productActions }) => {
  const actions = (productActions ?? []).map(status => {
    const props = statusActionsProps[status];
    return {
      Component: Button,
      props: {
        status,
        ...props,
        size: '_S',
        variant: 'Status',
        key: status,
      },
      permission: props.permission,
    };
  });

  try {
    return actions.filter(({ permission }) => hasPermission(permission));
  } catch {
    return [];
  }
};

const activityEntryFormatter =
  ({ dateKey, contentKey }) =>
  entry => {
    const { [dateKey]: date, [contentKey]: text } = entry;

    return {
      date: `${formatDate(date, DateTypes.ddmmyy)}, ${formatDate(
        date,
        DateTypes.time12
      )}`,
      text: parseStringModifiers(text),
    };
  };

const formatActivity = activity => {
  const format = activityEntryFormatter({
    dateKey: 'createdAt',
    contentKey: 'clContent',
  });

  return activity.map(format);
};

const formatParcelActivity = activity => {
  const format = activityEntryFormatter({
    dateKey: 'createdAt',
    contentKey: 'plContent',
  });

  return activity.map(format);
};

const formatAuthorityDetails = ({
  authorityEmail,
  authorityName,
  authorityPhone,
}) => {
  return authorityName || authorityEmail || authorityPhone
    ? [
        {
          title: 'NAME',
          info: authorityName ?? fallback,
        },
        {
          title: 'E-MAIL ADDRESS',
          info: authorityEmail ?? fallback,
        },
        {
          title: 'PHONE',
          info: authorityPhone ?? fallback,
        },
      ]
    : [];
};

const formatAttachments = attachments => {
  maskEntry(attachments, attachmentsMask);

  return attachments.map(attachment => {
    const url = attachment[productKeys.attachmentURL];
    const it = {
      ...attachment,
      [productKeys.attachmentURL]: url,
      [productKeys.attachmentName]: extractNameFromStorageURL(url),
    };

    return it;
  });
};

const extractNameFromStorageURL = name => {
  try {
    const [uri, storageFileName] = name.split('case_files/');
    const [uuidName, storageSignature] =
      storageFileName.split('?X-Goog-Algorithm=');

    const fileName = uuidName.replaceAll(re.uuid, '').replace('_.', '.');

    return decodeURIComponent(fileName);
  } catch {
    return name;
  }
};

export const formatProductDetails = ({
  productCategory,
  [productKeys.productQuantity]: quantity,
  [productKeys.isContracted]: isContracted,
  [productKeys.brand]: brand,
}) => {
  const customerIcon =
    isContracted && isContracted != '0'
      ? {
          name: Icons.CC_CheckCircle,
          size: Size.Icon._2XS,
          color: Colors.Primary._600,
        }
      : null;

  return [
    {
      title: 'Customer: ',
      text: brand,
      icon: customerIcon,
    },
    {
      title: 'Product category: ',
      text: productCategory,
    },
    {
      title: 'Item quantity: ',
      text: quantity,
    },
  ];
};

const attachmentsMask = {
  caID: productKeys.attachmentId,
  caName: productKeys.attachmentURL,
};
