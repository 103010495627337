import usePostData from '../../../../../hooks/postHook';

export const useAddSubcategory = ({ mode }) => {
  const [{ data, body, isLoading, isError, error }, doAddSubcategory] =
    usePostData(``, {
      type: mode === 'add' ? 'post' : 'put',
    });

  return { data, body, isLoading, isError, error, doAddSubcategory };
};
