import useFetchData from '../../../../../hooks/fetchHook';

export const useGetPlans = () => {
  const [{ data = [], isLoading = true, isError, error }] = useFetchData(
    `subscription_plans/`,
    {
      formatter,
    }
  );

  return { data, isLoading, isError, error };
};

const formatter = data => {
  return data.map(plan => ({
    name: plan.spName,
    pricing: {
      setupFee: plan.spSetupFee,
      priceMonthly: plan.spCostPerMonth,
      priceCatch: plan.spCostPerSeizure,
    },
    description: plan.spDetail,
    id: plan.spID,
  }));
};
