import useDeleteData from '../../../../../hooks/deleteHook';
import { actorTypes } from './addActor';

export const useRemoveActor = ({ id }) => {
  const [{ params, isLoading, isError, error }, doRemoveActor] = useDeleteData(
    `office_parcel/${id}/actors`
  );

  const removeOrigin = _ => {
    const params = {
      actor_type: actorTypes.sender,
    };
    return doRemoveActor({ params });
  };

  const removeDestination = _ => {
    const params = {
      actor_type: actorTypes.receiver,
    };
    return doRemoveActor({ params });
  };

  return {
    params,
    isLoading,
    isError,
    error,
    removeOrigin,
    removeDestination,
  };
};
