import { ReferenceInfo, ReferenceInfoSearch } from './organisms';
import { ReferenceLayoutCSS } from './style';

export const ReferenceLayout = ({
  searchProps,
  referenceInfoProps,
  closeSearch,
}) => {
  return (
    <ReferenceLayoutCSS column gap="_M" onClick={closeSearch}>
      <ReferenceInfoSearch searchProps={searchProps} />
      <ReferenceInfo referenceInfoProps={referenceInfoProps} />
    </ReferenceLayoutCSS>
  );
};
