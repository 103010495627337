import {
  RowDateHorizontal,
  RowOptionsMenu,
  RowSimpleText,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { adminCategoriesKeys as keys } from '../../controllers/variables';

export const tableComponents = ({ options, optionMenuOptionClick }) => ({
  [keys.category.name]: {
    display: RowSimpleText(keys.category.name, 'bold'),
  },
  [keys.subCategory.name]: {
    display: RowSimpleText(keys.subCategory.name),
  },
  [keys.addedAt]: {
    display: RowDateHorizontal(keys.addedAt, { time: false }),
  },
  [keys.addedBy.name]: {
    display: User(keys.addedBy.name, keys.addedBy.image),
  },
  options: RowOptionsMenu({ options, optionClick: optionMenuOptionClick }),
});
