import { DISPATCH_CONSTANTS as DC } from '../../../constants';
import { Calendar } from '../../molecules/Calendar/Calendar';
import { Dropdown } from '../../molecules/Dropdown/Dropdown';
import { FilterButton } from '../../molecules/FilterButton/FilterButton';
import { SearchFilter } from '../../molecules/SearchFilter/SearchFilter';
import { ToggleFilter } from '../../molecules/ToggleFilter.js/ToggleFilter';

export const FilterTypes = {
  search: 'search',
  dropdown: 'dropdown',
  calendar: 'calendar',
  button: 'button',
  toggle: 'toggle',
};

export const FilterComponents = {
  [FilterTypes.search]: props => <SearchFilter {...props} />,
  [FilterTypes.dropdown]: props => <Dropdown {...props} />,
  [FilterTypes.calendar]: props => <Calendar {...props} />,
  [FilterTypes.button]: props => <FilterButton {...props} />,
  [FilterTypes.toggle]: props => <ToggleFilter {...props} />,
};

export const resetSearchParamsCommon = {
  [DC.SEARCH]: '',
  [DC.TIME_FRAME]: {
    label: 'All time',
    value: { fromDate: '', toDate: '' },
  },
  [DC.PAGE]: 1,
  [DC.ITEMS_PER_PAGE]: 25,
  [DC.FILTERS_SET]: false,
  [DC.TIME_FRAME_SET]: false,
};
