import { Effects, FontKeys, Font_Properties } from '../../style';

const { Display, Heading, Button, Paragraph, Caption } = FontKeys.Variant;
const { Light, Regular, Medium, Semibold, Bold, Extrabold } = FontKeys.Weight;

export const Recipe = {
  [Display]: {
    _S: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._7XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._7XL,
      });
    },
    _M: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._8XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._8XL,
      });
    },
    _L: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._9XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._9XL,
      });
    },
  },
  [Heading]: {
    H1: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._6XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._6XL,
      });
    },
    H2: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._5XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._5XL,
      });
    },
    H3: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._4XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._4XL,
      });
    },
    H4: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._3XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._3XL,
      });
    },
    H5: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._2XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._2XL,
      });
    },
    H6: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._XL,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._XL,
      });
    },
  },
  [Button]: {
    _S: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._S,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._S,
      });
    },
    _M: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._M,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._M,
      });
    },
    _L: ({ weight }) => {
      if (![Regular, Semibold, Bold, Extrabold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._L,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._L,
      });
    },
  },
  [Paragraph]: {
    _S: ({ weight }) => {
      if (![Light, Regular, Medium, Semibold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._S,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._S,
      });
    },
    _M: ({ weight }) => {
      if (![Light, Regular, Medium, Semibold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._M,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._M,
      });
    },
    _L: ({ weight }) => {
      if (![Light, Regular, Semibold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._L,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._L,
      });
    },
  },
  [Caption]: {
    _S: ({ weight }) => {
      if (![Light, Regular, Semibold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._2XS,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._2XS,
      });
    },
    _M: ({ weight }) => {
      if (![Light, Regular, Semibold].includes(weight)) return;
      return includeConstants({
        fontSize: Font_Properties.Size._XS,
        fontWeight: Font_Properties.Weight[weight],
        lineHeight: Font_Properties.Height._XS,
      });
    },
  },
};

const includeConstants = style => {
  return {
    ...style,
    transition: Effects.Transition.EaseInOut('font-size'),
  };
};
