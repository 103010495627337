import { Route, Routes } from 'react-router-dom';
    import Analytics from './Analytics';
    
    export const AnalyticsRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<Analytics />} />
        </Routes>
      );
    };
    