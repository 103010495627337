import { Icon, Text } from '../../../../../../components/atoms';
import { AddIcon } from '../../../../../../components/atoms/AddIcon/AddIcon';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { StatusIndicator } from '../../../../../../components/atoms/StatusIndicator/StatusIndicator';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Button from '../../../../../../components/molecules/Button/Button';
import { NoResults } from '../../../../../../components/molecules/NoResults/NoResults';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import {
  JoinedStrings,
  LocationHorizontal,
  RowDateHorizontal,
  RowHowLongAgo,
  RowSimpleText,
  RowSingleImage,
  SimpleStatus,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { parseStringModifiers } from '../../../../../../utils/utils';
import {
  components,
  productKeys,
} from '../../../../features_public/products/controllers/variables';
import { SendMessageCSS } from '../../../support/style';
import { noResults } from '../../variables';
import {
  EntryInfo,
  EntryTitle,
  ErrorText,
  ParcelDetail,
  ParcelDetailTitle,
  PriorityBadge,
  StatusBadge,
} from '../atoms/atoms';
import { InfoS, ParcelDetailsSectionS, TicketCSS } from '../style';

export const tableComponents = ({ showGallery }) => ({
  [productKeys.statusIndicator]: {
    display: StatusIndicator(productKeys.productStatus),
  },
  [productKeys.productImage]: showGallery
    ? {
        display: RowSingleImage(productKeys.photos, {
          showGallery: () => Utils.emptyFunction,
        })('_S'),
        action: showGallery,
      }
    : { display: RowSingleImage(productKeys.photos)('_S') },
  [productKeys.productAlias]: {
    display: RowSimpleText(productKeys.productAlias, 'bold', true, {
      copyable: true,
    }),
  },
  [productKeys.brandName]: {
    display: User(productKeys.brandName, productKeys.brandImage, {
      bold: true,
    }),
  },
  [productKeys.productSideloadDate]: {
    display: RowDateHorizontal(productKeys.productSideloadDate),
  },
  [productKeys.sorter]: {
    display: JoinedStrings(productKeys.sorterName, productKeys.sorterCity),
  },
  [productKeys.productName]: {
    display: LocationHorizontal(productKeys.category, productKeys.subCategory),
  },
  [productKeys.productQuantity]: {
    display: RowSimpleText(productKeys.productQuantity, 'bold'),
  },
  [productKeys.productStatus]: {
    display: SimpleStatus(productKeys.productStatus, '_S'),
  },
  [productKeys.lastActivity]: {
    display: RowHowLongAgo(productKeys.lastActivity),
  },
});

export const ParcelDetailsSection = ({ section, index }) => {
  const { mock, data } = section;
  return (
    <ParcelDetailsSectionS index={index}>
      {data.map(detail => {
        const { title, info } = detail;
        return (
          <Flex column key={title}>
            <ParcelDetailTitle text={title} />
            <ParcelDetail mock={mock} text={parseStringModifiers(info)} />
          </Flex>
        );
      })}
    </ParcelDetailsSectionS>
  );
};

export const Ticket = ({ ticket, onClick, ...props }) => {
  const { ticketId, alias, mainTopic, priority, status } = ticket;
  const { leftIcon, rightIcon, className } = props;

  return (
    <TicketCSS
      onClick={onClick(ticketId)}
      className={`${className ?? ''}`}
      align="center"
    >
      {leftIcon && <AnimatedIcon {...leftIcon} size={20} />}
      <TicketInfo alias={alias} mainTopic={mainTopic} />
      <Badges priority={priority} status={status} />
      {rightIcon && <AnimatedIcon {...rightIcon} size={20} />}
    </TicketCSS>
  );
};

const TicketInfo = ({ alias, mainTopic }) => {
  return (
    <InfoS>
      <Text
        text={alias}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        color={Colors.Neutral._500}
        whiteSpace={'nowrap'}
      />
      <Text
        text={`• ${mainTopic}`}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        ellipsis
      />
    </InfoS>
  );
};

const Badges = ({ priority, status }) => {
  return (
    <Flex justify="center" align="center" gap="_XS">
      <PriorityBadge priority={priority} />
      <StatusBadge status={status} />
    </Flex>
  );
};

export const AuthorityEntry = ({ title, info }) => {
  return (
    <Flex column>
      <EntryTitle text={title} />
      <EntryInfo text={info} />
    </Flex>
  );
};

export const SendMessage = ({ onClick, isLoading, disabled }) => {
  return (
    <SendMessageCSS>
      <Button
        variant="Primary"
        onClick={onClick}
        text="Send"
        size="_S"
        disabled={disabled}
        isLoading={isLoading}
      />
    </SendMessageCSS>
  );
};

export const ProductTicketsContainer = ({
  tickets,
  onTicketClick,
  addTicket,
  rightIcon,
  supportButtonTooltipContent,
}) => {
  return (
    <Flex column scroll gap="_2XS">
      {tickets && tickets.length ? (
        tickets.map(ticket => {
          return (
            <Ticket
              onClick={onTicketClick}
              ticket={ticket}
              key={ticket}
              rightIcon={rightIcon}
            />
          );
        })
      ) : (
        <NoResults {...noResults[components.productTickets]} />
      )}
      {!!addTicket ? (
        <Tooltip content={supportButtonTooltipContent}>
          <AddIcon
            onClick={
              !!supportButtonTooltipContent ? Utils.emptyFunction : addTicket
            }
            disabled={!!supportButtonTooltipContent}
          />
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export const ErrorDisplay = () => (
  <Flex column gap="_S" justify="center" align="center" w h>
    <Icon
      name={Icons.AlertTriangle}
      color={Colors.Neutral._500}
      size={Size.Icon._L}
    />
    <ErrorText text={'Oops! Something went wrong'} />
  </Flex>
);
