import { useEffect, useState } from 'react';
import useDynamicRefs from '../../../utils/dynamicRefs';
import { elementDimensions, parentPosition } from '../../../utils/utils';
import { Flex } from '../../atoms/Flex/Flex';
import { TabHighlight } from '../../atoms/TabHighlight/TabHighlight';
import { Layout } from '../../style';
import { Tab } from './components';

export const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  const [getRef, setRef] = useDynamicRefs();
  const [width, setWidth] = useState();
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex(({ value }) => value === activeTab);
    const button = getRef(`tab${index}`)?.current;
    if (!button) return;

    const buttonWidth = elementDimensions(button).width ?? 0;
    setWidth(buttonWidth);

    const buttonLeft = parentPosition(button).x ?? 0;
    setLeft(buttonLeft);
  }, [activeTab]);

  const chooseTab = tab => () => setActiveTab(tab);

  return (
    <Flex
      gap={'_L'}
      relative
      h
      padding={`0 0 ${Layout.Spacing._XS}`}
      className={'tabs'}
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            {...tab}
            selected={tab.value === activeTab}
            key={tab.value}
            ref={setRef(`tab${index}`)}
            onClick={chooseTab(tab.value)}
          />
        );
      })}
      <TabHighlight left={left} width={width} />
    </Flex>
  );
};
