import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import ExportData from '../../../../../components/templates/ExportData/ExportData';
import { useDownloadTableData } from '../../../../../components/templates/ExportData/api/downloadTableData';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionConsts } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { GalleryPages, GalleryProps } from '../../../constants';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import ProductSummary from '../../../features_public/products/controllers/ProductSummary';
import { productKeys } from '../../../features_public/products/controllers/variables';
import CreateTicket from '../../../features_public/support/controllers/CreateTicket';
import { ticketTypes } from '../../../features_public/support/controllers/variables';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetCasesImages } from '../api/getCaseImages';
import { formatParams, useGetCases } from '../api/getCases';
import { useGetFilters } from '../api/getFilters';
import CasesLayout from '../components/pages/CasesLayout';
import { useCasesReducer } from './Cases.hook';
import { matchImagesToCases, matchImagesToProduct } from './utils';
import {
  DC,
  allOptions,
  expansionGrid,
  expansionHeaders,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Brand.PARCELS;

const {
  SELF: PRODUCTS,
  VIEW_PRODUCTS,
  VIEW_PRODUCTS_PHOTOS,
} = PermissionKeys.Brand.PRODUCTS;

const {
  SELF: SUPPORT,
  CREATE_GENERAL_SUPPORT_TICKETS,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const Cases = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);

  const location = useLocation();
  const navigate = useNavigate();
  const { dismiss, showModal } = useModal();
  const { showSnackbarError } = useSnackbar();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission } = usePermissions();

  const hasViewPermission = hasPermission(PARCELS, VIEW_PARCELS);

  const searchParamsRef = useRef(null);
  const resetFormRef = useRef(null);
  const exportModalRef = useRef(null);

  const ownOptions = undefined;
  // {
  //   [DC.BRAND]: {
  //     sectionTitle: 'Your brand',
  //     options: [
  //       {
  //         value: store[PropKeys.brandId],
  //         label: store[PropKeys.brandName],
  //       },
  //     ],
  //   },
  // };

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const {
    isLoading: exportIsLoading,
    isError: exportIsError,
    error: exportError,
    downloadTableData,
  } = useDownloadTableData();

  const [searchParams, _dispatch] = useCasesReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { cases, caseCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetCases({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getProductsImage,
  } = useGetProductsImage();

  const {
    data: casesImages,
    isLoading: casesImagesIsLoading,
    isError: casesImagesIsError,
    error: casesImagesError,
    getCasesImages,
  } = useGetCasesImages();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;

  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.cases];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    setData(cases);
    if (cases.length && hasViewPermission) getCasesImages({ cases });
  }, [cases]);

  useDeepCompareEffect(() => {
    if (casesImagesIsError || !hasViewPermission) {
      if (casesImagesIsError) showSnackbarError(casesImagesError);
      setData(matchImagesToCases([]));
      return;
    }
    if (cases?.length && casesImages !== null) {
      setData(matchImagesToCases(casesImages));
    }
  }, [casesImages]);

  useDeepCompareEffect(() => {
    if (
      imagesIsError ||
      !hasPermission(PRODUCTS, [VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    ) {
      if (imagesIsError) showSnackbarError(imagesError);

      const setPhotosToAvoidPulse = data => {
        if (data.length === 0 || selectedRow < 0) return data;
        const newData = cloneDeep(data);
        newData[selectedRow].products = matchImagesToProduct([])(
          newData[selectedRow]?.products ?? []
        );
        return newData;
      };

      setData(setPhotosToAvoidPulse);
      return;
    }

    const withMatchedImages = data => {
      const newData = cloneDeep(data);
      newData[selectedRow].products = matchImagesToProduct(images)(
        newData[selectedRow].products
      );
      return newData;
    };
    if (cases?.length && images != null) setData(withMatchedImages);
  }, [images]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const showProductSummary = item => {
    let {
      [productKeys.productId]: productId,
      [productKeys.productAlias]: productAlias,
    } = item;

    showModal(ProductSummary, {
      productId,
      productAlias,
      searchParams: searchParamsRef.current,
      updateUI: refreshView,
      navigate,
      from: location.pathname,
    });
  };

  // keep track, no need to call every time if it's already called
  const getItemImages = (item, clickedRow, _, willExpand) => {
    if (!willExpand) return;
    const products = item.products.filter(
      product => product[productKeys.productId] !== null
    );
    setSelectedRow(clickedRow);

    if (hasPermission(PRODUCTS, [VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS]))
      getProductsImage({ products });
  };

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRAND]: dropdown(DC.BRAND),
    [DC.LOCATION]: dropdown(DC.LOCATION),
    [DC.RISK_TYPE]: dropdown(DC.RISK_TYPE),
    [DC.PARCEL_STATUS]: dropdown(DC.PARCEL_STATUS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.cases]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          endSlot={
            <Button
              text="Export"
              size="_S"
              variant="Primary"
              leftIcon={Icons.Download}
              onClick={exportData({ isLoading })}
            />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  }, [isLoading, searchParams]);

  useEffect(() => {
    if (exportIsLoading) return;
    if (exportIsError) {
      showSnackbarError(exportError);
      exportData({ isLoading: false })();
      return;
    }
    dismiss();
  }, [exportIsLoading, exportIsError]);

  const exportData =
    ({ isLoading, value }) =>
    () => {
      const options = [
        {
          id: 'current',
          label: 'Current page',
        },
        {
          id: 'all',
          label: 'All pages',
        },
      ];

      const onConfirm = () => {
        const { value } = exportModalRef.current;
        const params = formatParams(searchParamsRef.current);
        if (value === 'all') params.limit = Constants.noLimit;
        downloadTableData({ params, url: 'parcels/export' });
        exportData({ isLoading: true, value })();
      };

      showModal(
        ExportData,
        {
          title: 'Export product list',
          values: exportModalRef,
          options,
          initialState: {
            subtitle: 'Please choose the pages you wish to export:',
            value: value ?? options[0].id,
          },
          confirm: 'Export',
          onCancel: dismiss,
          onConfirm,
          dismiss,
          isLoading,
        },
        { ignoreOverlayDismiss: true }
      );
    };

  const onSupportClick = item =>
    showModal(CreateTicket, {
      productData: item,
      productTitle: item[caseKeys.alias],
      type: ticketTypes.Parcel,
    });

  const showGallery =
    ({ item, initialSelected }) =>
    e => {
      e.stopPropagation();
      showModal(Gallery, {
        ...GalleryProps[GalleryPages.parcels],
        id: item[caseKeys.id],
        initialSelected,
        title: `Parcel ${item[caseKeys.alias] ?? ''}`,
      });
    };

  const hasSupportPermission =
    hasPermission(SUPPORT, PermissionConsts.Show) &&
    hasPermission(SUPPORT, [
      CREATE_GENERAL_SUPPORT_TICKETS,
      CREATE_PARCEL_SUPPORT_TICKETS,
      CREATE_PRODUCT_SUPPORT_TICKETS,
    ]);

  const noDataAction = undefined;

  const expandCountKey = caseKeys.products;

  return (
    <CasesLayout
      headers={headers}
      data={data}
      grid={grid}
      showGallery={showGallery}
      expansionHeaders={expansionHeaders}
      expansionGrid={expansionGrid}
      expansionRowAction={showProductSummary}
      isError={isError}
      error={error}
      isLoading={isLoading}
      onRowExpand={getItemImages}
      onSupportClick={onSupportClick}
      hasSupportPermission={hasSupportPermission}
      expandCountKey={expandCountKey}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default Cases;
