import { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Card } from '../../../../../../components/atoms';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import { disabledText2Rows } from '../../../../../../components/molecules/Disabled/variables';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import LineChart from '../../../../../../components/organisms/LineChart/LineChart';
import { ChartsCSS, DisableContainer } from '../style';

function getSyncIds(chartId, totalCharts) {
  if (chartId === -1) return new Array(totalCharts).fill(null);

  const totalRows = Math.ceil(totalCharts / 3);

  const hoverRow = Math.floor(chartId / 3);
  let result = new Array(totalCharts).fill(null);

  if (hoverRow === 0) {
    // If the first row is hovered, sync the first two rows
    result.fill('synced', 0, 6);
  } else if (hoverRow === totalRows - 1) {
    // If the last row is hovered, sync the last two rows
    const startIdx = (totalRows - 2) * 3;
    result.fill('synced', startIdx, totalCharts);
  } else {
    // If any middle row is hovered, sync the previous, current, and next rows
    const startIdx = (hoverRow - 1) * 3;
    const endIdx = (hoverRow + 2) * 3;
    result.fill('synced', startIdx, endIdx);
  }

  return result;
}

export const Charts = ({ data, disabled }) => {
  const [hoveredChart, setHoveredChart] = useState(-1);
  const noOfCharts = data.length;

  const syncIds = getSyncIds(hoveredChart, noOfCharts);

  return (
    <ChartsCSS>
      {data.map((chart, index) => (
        <LazyLoadComponent>
          <Chart
            data={chart}
            disabled={disabled}
            key={index}
            onMouseEnter={() => setHoveredChart(index)}
            syncId={syncIds[index]}
            onMouseLeave={() => setHoveredChart(-1)}
          />
        </LazyLoadComponent>
      ))}
    </ChartsCSS>
  );
};

export const Chart = ({
  data: data_,
  disabled,
  onMouseEnter,
  onMouseLeave,
  syncId,
}) => {
  const { title, description, data, lines } = data_;

  return (
    <DisableContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Card title={title} icon={<InfoIcon description={description} />}>
        <LineChart data={data} lines={lines} syncId={syncId} />
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DisableContainer>
  );
};
