import styled from '@emotion/styled';
import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Avatar from '../../../../../components/molecules/Avatar/Avatar';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { Disabled } from '../../../../../components/molecules/Disabled/Disabled';
import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import Tooltip from '../../../../../components/molecules/Tooltip/Tooltip';
import Attachments from '../../../../../components/organisms/Attachments/Attachments';
import Notes from '../../../../../components/organisms/Notes/Notes';
import { Table } from '../../../../../components/organisms/Table/Table';
import { separatorColor } from '../../../../../components/organisms/Table/Table.style';
import {
  Colors,
  Effects,
  Layout,
  Major as MajorColumn,
  Minor as MinorColumn,
} from '../../../../../components/style';
import { Carousel as Carousel_ } from '../../../../../components/templates/Carousel/Carousel';
import { Styles, UtilityComponents } from '../../../../../constants';
import { hexOpacity } from '../../../../../utils/styleUtils';
import { RiskAnalysis } from '../../../features_public/products/components/organisms';
import { Ticket } from '../../products/components/molecules';
import { Messages } from '../../support/components';

export const Container = styled(UtilityComponents.Grid)`
  width: 100%;
  height: 100%;
  .card {
    width: 100%;
    height: 100%;
  }
`;

export const Major = styled(MajorColumn)`
  .products-list {
    padding: 0;
  }
`;

export const Minor = styled(MinorColumn)`
  .card {
    height: unset;
  }
  .authentication-achivement {
    min-height: 223px;
  }
  .recent-activity {
    flex-grow: 1;
  }
  height: 100%;
`;

export const ProductsListContainer = styled(Card)`
  gap: 0;
`;

export const ProductListTable = styled(Table)`
  #tableShadow {
    display: none;
  }
  .table-row {
    ${({ selectedRows }) => selectedRows.map(row => `&._${row}`).join(', ')} {
      .table-cell {
        background: #fff4f2;
      }
    }
  }
  .table-cell._6 {
    padding-right: ${Layout.Spacing._S};
  }
  .separator {
    background: ${separatorColor};
  }
  .table-header._1 {
    span {
      overflow: visible !important;
    }
  }
`;

export const AchievementBadgeCSS = styled.div`
  position: absolute;
  right: 52px;
  top: 0;
`;

export const RecentActivityContainer = styled(Card)`
  padding: ${Layout.Spacing._L};
  .no-search-results {
    transform: scale(0.85);
  }
`;

export const AuthenticationGalleryContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  height: 100%;
  overflow: hidden;
  background: ${Colors.Neutral._100}${hexOpacity(95)};
  width: 100%;
  transition: ${Effects.Transition.CarouselSlide()};
  ${Styles.flexCenter};
`;

export const AuthGalleryDismissIcon = styled(AnimatedIcon)`
  position: absolute;
  top: ${Layout.Spacing._S};
  right: ${Layout.Spacing._S};
`;

const innerProductMinWidth = 817;
export const AuthenticationSliderContainer = styled(Flex)`
  margin: 0 auto;
  min-width: ${innerProductMinWidth + 48}px;
  width: 79vw;
  padding-bottom: ${Layout.Spacing._M};
  padding-top: ${Layout.Spacing._XS};
`;

export const ActionsContainer = styled(Flex)`
  padding: ${Layout.Spacing._S};
  border-radius: 0 0 ${Effects.Border_Radius._L} ${Effects.Border_Radius._L};
  background-color: ${Colors.Sharp.White};
  button button {
    margin: auto;
  }
`;

export const ProductDisplayContainer = styled(Flex)`
  border-radius: ${Effects.Border_Radius._L} ${Effects.Border_Radius._L} 0 0;
  overflow: hidden;
`;

const galleryDetailsTransition = Effects.Transition.CarouselSlide(
  'flex-grow',
  'flex-direction',
  'transform',
  'justify-content',
  'padding'
);

export const ProductGalleryContainer = styled(Flex)`
  border-radius: ${Effects.Border_Radius._L} ${Effects.Border_Radius._L} 0 0;
  overflow: hidden;
  transition: ${galleryDetailsTransition};
`;

export const ButtonAsLabel = styled(Button)`
  width: 350px !important;
  position: absolute;
  left: 0;
  transform: rotate(-45deg) translate(-30%, -25%);
`;

export const SupportAsLabel = styled(ButtonAsLabel)`
  background: ${Colors.Sharp.White} !important;
`;

export const ProductStatusLabelContainer = styled(ButtonAsLabel)``;

export const ProductDetailsContainer = styled(Flex)`
  background: ${Colors.Sharp.White};
  padding: 0 0 ${({ expanded }) => (expanded ? '0' : Layout.Spacing._S)} 0;
  transition: ${galleryDetailsTransition};
`;

export const ProductDetailsScroll = styled(Flex)`
  overflow-y: auto;
  padding: ${Layout.Spacing._S};
`;

export const ProductDetailsGrid = styled(UtilityComponents.Grid)`
  position: relative;
`;

export const ProductDetailsMajor = styled(MajorColumn)`
  position: relative;
`;

export const ProductDetailsMinor = styled(MinorColumn)``;

export const RelatedProductsContainer = styled(Flex)`
  .case-products {
    padding: 0;
    gap: ${Layout.Spacing._S};
  }
  .case-products-table {
    overflow: hidden;
    border: 1px solid ${Colors.Neutral._200};
    border-radius: ${Effects.Border_Radius._S};
    .table-header,
    .table-cell {
      &._0 {
        padding-left: 0px;
      }
    }
    .table-header._1 {
      span {
        overflow: visible !important;
      }
    }
  }
  .case-products-table-header {
    background: unset;
    padding-top: 0;
  }
  .no-search-results {
    margin-top: ${Layout.Spacing._M};
  }
`;

export const ExpandableTicketContainer = styled(Flex)`
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  height: ${({ expanded }) => (expanded ? '446px' : '56px')};
  transition: ${Effects.Transition.TableExpand()};
`;

export const AuthTicket = styled(Ticket)`
  box-shadow: unset;
  width: 100%;
  max-width: 100%;
  .animated-icon:first-of-type {
    transform: rotate(${({ expanded }) => (expanded ? '90deg' : '0deg')});
    transition: ${Effects.Transition.TableExpand()};
  }
`;

export const AuthTicketMessagesContainer = styled(Flex)``;

export const AuthTicketMessages = styled(Messages)`
  width: 100%;
  height: 390px;
  box-shadow: unset;
  .awaiting-response {
    display: none;
  }
`;

export const ParcelSummaryContainer = styled(Card)``;

export const AuthRiskAnalysisCustom = styled(RiskAnalysis)``;

export const ProductNotesCustom = styled(Notes)`
  max-height: 400px;
  .notes-scroll {
    height: auto;
  }
  .note-header {
    flex-direction: column;
    align-items: flex-start;
    gap: ${Layout.Spacing._2XS};
  }
`;

export const ProductAttachmentsContainer = styled(Attachments)`
  max-height: 400px;
  .attachment-scroll {
    height: auto;
  }
`;

export const ShowDetailsButtonContainer = styled(Flex)`
  flex-direction: ${({ expanded }) => (expanded ? 'column-reverse' : 'column')};
  transition: ${galleryDetailsTransition};
`;

export const ShowDetailsArrow = styled(AnimatedIcon)`
  transform: rotate(${({ expanded }) => (expanded ? '180deg' : '0deg')});
  transition: ${galleryDetailsTransition};
`;

export const ProductSummaryContainer = styled(Flex)`
  height: 128px;
  width: 100%;
  padding: ${Layout.Spacing._S};
  background: ${Colors.Sharp.White};
`;

export const Carousel = styled(Carousel_)`
  width: 100%;
  height: 100%;
`;

const sliderGutter = Layout.Spacing._S;
export const ProductAuth = styled(Flex)`
  z-index: 1;
  width: 100%;
  position: absolute;
  padding: 0 ${sliderGutter};
  transition: ${Effects.Transition.CarouselSlide()};
  ${({ index, activeSlideIndex }) => {
    const active = index === activeSlideIndex;
    const scaleFactor = active ? 1 : 0.81;

    const unscaledLeft = (index - activeSlideIndex) * 100;
    const left = !active ? unscaledLeft * 0.883 : unscaledLeft;

    return `
    left: ${left}%;
    transform: scale(${scaleFactor})
    `;
  }}
`;

export const ProductAuthLoader = styled(TableLoader)`
  position: absolute;
  height: 100%;
  width: calc(100% - ${sliderGutter} - ${sliderGutter});
  left: ${sliderGutter};
  background: ${Colors.Sharp.White}${hexOpacity(50)};
`;

export const HiddenSizer = styled.div`
  opacity: 0;
  z-index: -1;
`;

export const BrandAvatar = styled(Avatar)`
  border: 1px solid ${Colors.Neutral._200};
`;

export const ShowGalleryIconCSS = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${Layout.Spacing._S};
  right: ${Layout.Spacing._S};
`;

export const NextButton = styled(Button)`
  position: absolute;
  right: -${Layout.Spacing._XL};
  bottom: ${Layout.Spacing._S};
`;

export const PrevButton = styled(Button)`
  position: absolute;
  left: -${Layout.Spacing._XL};
  bottom: ${Layout.Spacing._S};
`;

export const DisableProduct = styled(Disabled)`
  background: unset;
`;

export const AuthGalleryModal = styled(ConfirmModal)`
  min-height: 400px;
  padding: ${Layout.Spacing._M};
  padding-top: ${Layout.Spacing._S};
  gap: ${Layout.Spacing._M};
  .confirm-modal {
    width: 544px;
  }
`;

export const StatusConfirmModal = styled(AuthGalleryModal)``;

export const FeedbackModalStyles = styled(AuthGalleryModal)``;

export const ActionTooltip = styled(Tooltip)`
  text-align: center;
`;
