import styled from '@emotion/styled';
import { Layout } from '../../style';

export const Container = styled.div(props => ({
  flexWrap: props.wrap && 'wrap',
  width: props.w && '100%',
  minWidth: props.width && Layout.Spacing[props.width],
  height: props.h && '100%',
  minHeight: props.height ? Layout.Spacing[props.height] : 0,
  flexGrow: props.grow && 1,
}));
