import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants, PropKeys } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import AddReference from '../../../features_public/reference_index/controllers/AddReference';
import { newBrandStatus } from '../../../features_public/reference_index/utils';
import { WelcomePopupLayout } from '../components/WelcomePopupLayout';

const WelcomePopup = () => {
  const { dismiss, showModal } = useModal();
  const { store, updateStore } = useStore();

  const onCreateSuccess = () => {
    const brandStatus = newBrandStatus(
      store[PropKeys.brandStatus],
      Constants.BrandStatus.Active
    );
    updateStore({ [PropKeys.brandStatus]: brandStatus });
  };

  const onUploadSuccess = () => {
    const brandStatus = newBrandStatus(
      store[PropKeys.brandStatus],
      Constants.BrandStatus.Processing
    );
    updateStore({ [PropKeys.brandStatus]: brandStatus });
  };

  const handleAddReference = () => {
    showModal(AddReference, { onCreateSuccess, onUploadSuccess });
  };

  return (
    <WelcomePopupLayout
      onCancelClick={dismiss}
      onPrimaryClick={handleAddReference}
    />
  );
};
export default Modal(WelcomePopup);
