import { Route, Routes } from 'react-router-dom';
import Parcels from './Parcels';

export const CasesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Parcels />} />
    </Routes>
  );
};
