import styled from '@emotion/styled';

export const Container = styled.div`
  ${({ size, color, direction }) => directionStyles({ size, color })[direction]}
`;

const directionStyles = ({ size, color }) => ({
  up: {
    borderBottom: `${size}px solid ${color}`,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
  },
  down: {
    borderTop: `${size}px solid ${color}`,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
  },
  left: {
    borderRight: `${size}px solid ${color}`,
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
  },
  right: {
    borderLeft: `${size}px solid ${color}`,
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
  },
});
