import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { maskEntry } from '../../../utils/utils';
import { NotesLayout } from './NotesLayout';
import { notesMask } from './variables';

export default function Notes({
  notes,
  title = 'Notes',
  params,
  onSuccessCreateNote = Utils.emptyFunction,
  isLoading: notesIsLoading,
  hook = Utils.emptyObjectFunction,
  charLimit = 450,
  placeholder = 'Write a note',
  doCreate = 'doCreateNote',
  noResults,
  hasEditPermission,
  ...props
}) {
  const [addingNote, setAddingNote] = useState(false);
  const [value, setValue] = useState('');

  const resetFormRef = useRef(null);

  const { showSnackbarError, showSnackbarWarning } = useSnackbar();

  const {
    data,
    body,
    isLoading,
    isError,
    error,
    [doCreate]: doCreateNote,
  } = hook(params);

  const toggleAddingNote = () => {
    setAddingNote(x => !x);
  };

  const createNote = note => {
    const body = {
      note,
    };
    doCreateNote(body);
  };

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      const { note } = body;
      showSnackbarError(error);
      setValue(note);
      return;
    }

    resetFormRef?.current();
    onSuccessCreateNote(body);
  }, [data, body, isLoading, isError]);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value.substring(0, charLimit));
    if (value.length >= charLimit)
      showSnackbarWarning(`Maximum allowed characters: ${charLimit}`);
  };

  const handleSend = e => {
    const thereIsAMessage = value?.trim().length;
    const notAllNewlines = value.replaceAll('\n', '').length;

    if (thereIsAMessage && notAllNewlines) {
      createNote(value.trim());
      setValue('');
    }
  };

  const handleEnterKey = e => {
    const { shiftKey, key } = e;
    let lineBrokenMsg = value + '\n';
    if (shiftKey && key === 'Enter') setValue(lineBrokenMsg);
    if (!shiftKey && key === 'Enter') {
      e?.preventDefault();
      handleSend();
    }
  };

  return (
    <Formik initialValues={{ note: '' }}>
      {({ resetForm }) => {
        if (!resetFormRef.current) resetFormRef.current = resetForm;

        return (
          <NotesLayout
            notes={maskEntry(notes, notesMask) ?? []}
            title={title}
            placeholder={placeholder}
            addingNote={addingNote}
            toggleAddingNote={toggleAddingNote}
            onChange={handleChange}
            handleSend={handleSend}
            handleEnterKey={handleEnterKey}
            isLoading={isLoading}
            notesIsLoading={notesIsLoading}
            noResults={noResults}
            hasEditPermission={hasEditPermission}
            props={props}
          />
        );
      }}
    </Formik>
  );
}
