import { useState } from 'react';
import UsersList from './UsersList';
import WriteUser from './WriteUser';
import { userOptions } from './variables';

const Users = () => {
  const [option, setOption] = useState(userOptions.list);
  const [editingUser, setEditingUser] = useState();

  const showWriteUser = user => {
    setEditingUser(user);
    setOption(userOptions.edit);
  };

  const showUserList = () => setOption(userOptions.list);

  const components = {
    [userOptions.list]: <UsersList showWriteUser={showWriteUser} />,
    [userOptions.edit]: (
      <WriteUser showUserList={showUserList} user={editingUser} />
    ),
  };

  return components[option];
};

export default Users;
