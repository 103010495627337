import InfoIcon from '../../../components/molecules/InfoIcon/InfoIcon';
import { FileDropzone } from '../../../components/organisms/FileDropzone/FileDropzone';
import { Popup } from '../../../components/templates/Popup';
import Actions from '../Actions/Actions';
import { AddAttachmentContent } from './style';

export const AttachmentUploadLayout = ({
  disabled,
  fileState,
  handleDrop,
  infoIcon,
  onCancel,
  percentageUploaded,
  primaryClick,
  saveText,
  title,
  uploadStatusContent,
}) => {
  return (
    <Popup
      size="_M"
      title={title}
      element={<InfoIcon description={infoIcon} />}
    >
      <AddAttachmentContent>
        <div />
        <FileDropzone
          handleDrop={handleDrop}
          fileState={fileState}
          percentageUploaded={percentageUploaded}
          uploadStatusContent={uploadStatusContent}
        />

        <Actions
          onPrimary={primaryClick}
          primaryText={saveText}
          disabled={disabled}
          onCancel={onCancel}
        />
      </AddAttachmentContent>
    </Popup>
  );
};
