import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { OptionFrame } from '../../../../../../components/organisms/OptionFrame/OptionFrame';
import { StepPopup } from '../../../../../../components/templates/StepPopup/StepPopup';

export const SetupChoiceLayout = ({
  onCancel,
  categorySetup,
  subCategorySetup,
}) => {
  return (
    <StepPopup
      title={'Add category'}
      sectionTitle={'How do you want to add a category?'}
      hidePrimary
      onCancel={onCancel}
    >
      <Flex gap="_S" h w>
        <OptionFrame {...categorySetup} />
        <OptionFrame {...subCategorySetup} />
      </Flex>
    </StepPopup>
  );
};
