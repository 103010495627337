import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Layout } from '../../style';

export const AddAttachmentContent = styled.div`
  grid-column: 1 / 13;
  width: 100%;
  height: 100%;
  gap: ${Layout.Spacing._M};
  ${Styles.flexColumn};
`;
