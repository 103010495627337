import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { PermissionKeys } from '../../../providers/PermissionProvider/PermissionsProvider';
import { ProcessingRoutes } from '../features/processing';
import { Labels, Pages, URIs } from './variables';

const { OFFICE_PARCEL_PROCESSING } = PermissionKeys.Facilitator;

export const children = [
  {
    path: `${URIs[Pages.processing]}/*`,
    label: Labels[Pages.processing],
    sidebarIcon: Icons.PackageSearch,
    service: OFFICE_PARCEL_PROCESSING.SELF,
    element: <ProcessingRoutes />,
  },
];
