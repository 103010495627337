import styled from '@emotion/styled';
import parcelPlaceholder from '../../../assets/svg/placeholder/parcel/40.svg';
import { Effects } from '../../style';

export const ImageStyle = styled.div(props => ({
  overflow: 'hidden',
  position: 'relative',
  width: imageSizes[props.size],
  minWidth: imageSizes[props.size],
  maxWidth: imageSizes[props.size],
  height: imageSizes[props.size],
  minHeight: imageSizes[props.size],
  maxHeight: imageSizes[props.size],
  borderRadius: Effects.Border_Radius._S,
  background: `center / contain no-repeat url(${parcelPlaceholder})`,
  cursor: props.isClickable ? 'pointer' : 'auto',
}));

const imageSizes = {
  _XL: '88px',
  _L: '60px',
  _M: '48px',
  _S: '40px',
  _XS: '32px',
  _2XS: '24px',
};
