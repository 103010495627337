import { DISPATCH_CONSTANTS as DC, Utils } from '../constants';
import { timeFrameIsSet } from '../utils/timeUtils';

export const customTableReducer =
  ({ resetSearchParams, customReducer }) =>
  (state, { type, payload }) => {
    return tableReducer({
      state,
      type,
      payload,
      resetSearchParams,
      customReducer,
    });
  };

const tableReducer = ({
  state,
  type,
  payload,
  resetSearchParams,
  customReducer,
}) => {
  if (type !== DC.PAGE && type !== DC.SEARCH)
    // go back to first page on any changes
    state = tableReducer({
      state,
      type: DC.PAGE,
      payload: 1,
      // explicitly stating we don't need resetSearchParams or customReducer
      resetSearchParams: {},
      customReducer: Utils.unary,
    });

  return customReducer(
    sharedReducer(state, { type, payload, resetSearchParams }),
    {
      type,
      payload,
    }
  );
};

const sharedReducer = (state, { type, payload, resetSearchParams }) => {
  switch (type) {
    case DC.SEARCH:
      return {
        ...state,
        searchValue: payload,
        [DC.FILTERS_SET]: true,
      };
    case DC.SEARCH_TRIGGER:
      return {
        ...state,
        [DC.SEARCH]: payload,
        [DC.FILTERS_SET]: true,
      };
    case DC.TIME_FRAME:
      return {
        ...state,
        [type]: payload,
        [DC.TIME_FRAME_SET]: !timeFrameIsSet(payload.value),
      };
    case DC.PAGE:
    case DC.ITEMS_PER_PAGE:
      return {
        ...state,
        [type]: payload,
        // should this be ignored? in support.hook it doesn't exist
        // should this be ignored? in support.hook it doesn't exist
        [DC.FILTERS_SET]: true,
      };
    case DC.RESET:
      return {
        ...resetSearchParams,
        [DC.HAS_RESET]: true,
      };
    case DC.HAS_RESET:
      return {
        ...state,
        [DC.HAS_RESET]: true,
      };
    case DC.SET:
      window.dispatchEvent(new CustomEvent('cleanUrl'));
      return { ...state, ...payload, [DC.SET]: true };
    default:
      return state;
  }
};

export const dispatchAction = dispatch => (type, props) => {
  switch (type) {
    case DC.SEARCH:
    case DC.PAGE:
    case DC.ITEMS_PER_PAGE:
      return payload => dispatch({ type, payload });
    case DC.TIME_FRAME:
      return payload => () =>
        dispatch({ type, payload: { value: payload, label: '' } });
    case DC.SEARCH_TRIGGER:
      return payload => {
        if (payload.length < 3) payload = '';
        dispatch({ type, payload });
      };
    case DC.RESET:
      return payload => {
        props.resetForm();
        dispatch({ type, payload });
      };
    default:
      throw Error(`No function assigned to type: ${type}`);
  }
};

export const useTableClear = ({
  resetFormRef,
  dispatch,
  refreshTable,
  refetchFilters,
}) => {
  const resetForm = () => {
    resetFormRef.current();
  };

  const refreshView = () => {
    // dispatch({ type: DC.HAS_RESET });
    refreshTable();
    refetchFilters();
  };

  return { resetForm, refreshView };
};
