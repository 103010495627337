import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { DISPATCH_CONSTANTS } from '../../../constants';
import { objectify } from '../../../lib/js';
import { Flex } from '../../atoms/Flex/Flex';
import Spacer from '../../atoms/Spacer/Spacer';
import { Filters } from '../../organisms/Filters';
import { ViewTitle } from '../ViewHeader/components';
import { Container } from './style';

const TablePageHeader = ({
  formInitialValues,
  resetFormRef,
  setFieldValueRef,
  hasViewPermission,
  searchParams,
  filters,
  isLoading,
  title,
  endSlot,
  customStyles,
  collapsible = false,
}) => {
  const [hasReset, setHasReset] = useState(false);
  const [resetInitialValues, setResetInitialValues] =
    useState(formInitialValues);

  useEffect(() => {
    const resetInitialValues = objectify(
      Object.keys(formInitialValues),
      () => ''
    );
    setResetInitialValues(resetInitialValues);
  }, [formInitialValues]);

  return (
    <Formik
      initialValues={hasReset ? resetInitialValues : formInitialValues}
      enableReinitialize
    >
      {formikBag => {
        const { resetForm, setFieldValue } = formikBag;
        if (!resetFormRef.current)
          resetFormRef.current = () => {
            setHasReset(Math.random());
            resetForm();
          };
        if (setFieldValueRef && !setFieldValueRef?.current)
          setFieldValueRef.current = setFieldValue;
        return (
          <Container
            justify="between"
            align="center"
            w
            customStyles={customStyles}
          >
            <ViewTitle text={title} noNav ellipsis={collapsible} />
            {collapsible ? null : <Spacer width={'_XL'} />}
            <Flex gap="_XS" w justify={'end'} className={'filters-container'}>
              {hasViewPermission && (
                <Filters
                  filters={filters}
                  searchValue={searchParams[DISPATCH_CONSTANTS.SEARCH]}
                  disabled={isLoading}
                  formikBag={formikBag}
                />
              )}
              {endSlot}
            </Flex>
          </Container>
        );
      }}
    </Formik>
  );
};
export default TablePageHeader;
