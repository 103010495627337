import { DISPATCH_CONSTANTS } from '../../../constants';
import { Icon } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Size } from '../../style';
import { Input } from '../Input/Input';
import { Container, themes } from './SearchFilter.style';

export const SearchFilter = ({
  filter: searchChangeHandler,
  placeholder,
  searchIsActive: fullWidth,
  handleSearchClick,
  handleSearchEnter,
  dropdown,
  name,
  size = '_S',
  icon = Icons.Search,
  searchTheme: theme = 'page',
  disabled,
  autoFocusSearch,
  searchIsClicked,
  value,
}) => {
  const handleInputChange = e => {
    const { value } = e.target;
    searchChangeHandler(value);
  };

  const onKeyPress = e => {
    const { key } = e;
    if (key === 'Enter') handleSearchEnter(e);
  };
  return (
    <Container
      onClick={handleSearchClick}
      theme={theme}
      // FilterContainer Props
      disabled={disabled}
      size={size}
      dropdown={dropdown}
      fullWidth={fullWidth}
      isFilterApplied={!!value}
      someOptionSelected
      className={`
      ${DISPATCH_CONSTANTS.SEARCH} ${searchIsClicked ? 'open' : ''}`}
    >
      <Icon
        name={icon}
        size={Size.Icon[size]}
        color={themes[theme].iconColor}
      />
      <Input
        name={name}
        placeholder={placeholder}
        variant="filter"
        size={size}
        onChange={handleInputChange}
        onClick={handleSearchClick}
        autoFocus={autoFocusSearch}
        onKeyPress={onKeyPress}
      />
    </Container>
  );
};
