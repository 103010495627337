import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import countryList from '../../../../../utils/countryList';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { dropdownValidation } from '../../../../../utils/validationUtils';
import { useCreateSorter } from '../api/createSorter';
import { useGetBrands } from '../api/getBrands';
import { useGetRepresentatives } from '../api/getRepresentatives';
import { AddSorterLayout } from '../components/AddSorterLayout';
import { adminSortersKeys as keys } from './variables';

const fields = [
  {
    name: keys.name,
    label: 'Sorter name',
    placeholder: 'Enter a sorter name',
  },
  {
    name: keys.brand.id,
    label: 'Sorter brand',
    placeholder: 'Enter a sorter brand',
    type: 'select',
    options: [],
  },
  {
    name: keys.city,
    label: 'City',
    placeholder: 'Enter a city',
  },
  {
    name: keys.country,
    label: 'Country',
    placeholder: 'Enter a country',
    type: 'select',
    options: countryList,
  },
  {
    name: keys.responsible.id,
    label: 'CC-Responsible',
    placeholder: 'Enter a CC-Responsible',
    type: 'select',
    options: [],
  },
];

const validationSchema = Yup.object().shape({
  [keys.name]: Yup.string().required('Required'),
  [keys.brand.id]: dropdownValidation,
  [keys.city]: Yup.string().required('Required'),
  [keys.country]: dropdownValidation,
  [keys.responsible.id]: dropdownValidation,
});

const sorterInitialValues = {};
fields.forEach(field => (sorterInitialValues[field.name] = ''));

const AddSorter = ({ data, onSuccess }) => {
  const isEditing = data != null;
  const [_, toggle] = useToggle(false);
  const [brand, setBrand] = useState(data?.[keys.brand.id]);

  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { dismiss } = useModal();

  const { data: representatives, doGetRepresentatives } = useGetRepresentatives(
    { ignore: true }
  );

  const { data: brands } = useGetBrands({});

  const {
    data: createData,
    body,
    isLoading,
    isError,
    error,
    doCreateSorter,
  } = useCreateSorter({
    isEditing,
  });

  useEffect(() => {
    if (!brands) return;
    fields.find(field => field.name === keys.brand.id).options = brands;
    toggle();
  }, [brands]);

  useEffect(() => {
    if (!representatives) return;
    fields.find(field => field.name === keys.responsible.id).options =
      representatives;
    toggle();
  }, [representatives]);

  useEffect(() => {
    if (!brand) return;
    doGetRepresentatives({ id: brand });
  }, [brand]);

  useEffect(() => {
    if (!representatives || !brands) return;
    setInitialValues(withPrevData(sorterInitialValues));
  }, [representatives, brands]);

  useEffect(() => {
    if (!body || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    onSuccess();
  }, [body, isLoading, isError]);

  const onSubmit = values => {
    const url = isEditing ? `/admin_sorter/${data.id}` : '/admin_sorters';
    doCreateSorter(
      {
        ...values,
        [keys.brand.id]: values?.[keys.brand.id]?.value,
        [keys.responsible.id]: values?.[keys.responsible.id]?.value,
        [keys.country]: values?.[keys.country]?.value,
      },
      url
    );
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: data,
        // mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const [initialValues, setInitialValues] = useState(
    withPrevData(sorterInitialValues)
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ dirty, setFieldValue, isValid, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });

        setBrand(oldBrandId => {
          const newBrandId = values?.[keys.brand.id]?.value;
          if (newBrandId && oldBrandId !== newBrandId)
            setFieldValue(keys.responsible.id, '');
          return newBrandId ?? oldBrandId;
        });
        return (
          <Form>
            <AddSorterLayout
              isEditing={isEditing}
              fields={fields}
              selectOption={selectOption}
              actionProps={{
                disabled: !isValid || !dirty || isLoading,
                primaryText: isEditing ? 'Save' : 'Add',
                isLoading,
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddSorter);
