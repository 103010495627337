import styled from '@emotion/styled';
import imagePlaecholder2XL from './assets/img/placeholder/image/2XL.png';
import imagePlaecholderXS from './assets/img/placeholder/image/2XS.png';
import imagePlaecholderL from './assets/img/placeholder/image/L.png';
import imagePlaecholderM from './assets/img/placeholder/image/M.png';
import imagePlaecholderS from './assets/img/placeholder/image/S.png';
import imagePlaecholderXL from './assets/img/placeholder/image/XL.png';
import imagePlaecholder2XS from './assets/img/placeholder/image/XS.png';

import companyPlaecholder2XL from './assets/img/placeholder/company/2XL.png';
import companyPlaecholderXS from './assets/img/placeholder/company/2XS.png';
import companyPlaecholderL from './assets/img/placeholder/company/L.png';
import companyPlaecholderM from './assets/img/placeholder/company/M.png';
import companyPlaecholderS from './assets/img/placeholder/company/S.png';
import companyPlaecholderXL from './assets/img/placeholder/company/XL.png';
import companyPlaecholder2XS from './assets/img/placeholder/company/XS.png';

export const Constants = {
  Logistics: 'Logistics',
  Brand: 'Brand',
  Status: {
    Sideload: 'Sideload',
    Inspection: 'Inspection',
    // getFilters.js filterStatusOptions uses this object to create status badges. DRY the function
    Authentication: 'Authentication',
    Counterfeit: 'Counterfeit',
    'Non-counterfeit': 'Non-counterfeit',
    AuthenticationCompleted: 'Authentication_Completed',
    Stopped: 'Stopped',
    Customs: 'Customs',
    Seized: 'Seized',
    Released: 'Released',
    Returned: 'Returned',
    // Office statues
    OfficeStop: 'Counterfeit',
    OfficeRelease: 'Non-counterfeit',
  },
  StatusLabel: {
    Sideload: 'Sideload',
    Inspection: 'Inspection',
    // getFilters.js filterStatusOptions uses this object to create status badges. DRY the function
    // Inspection: 'Inspection',
    Authentication: 'Authentication',
    // authentication completed
    Counterfeit: 'Counterfeit',
    'Non-counterfeit': 'Non-counterfeit',
    AuthenticationCompleted: 'Completed',
    Stopped: 'Stopped',
    Customs: 'Customs',
    Seized: 'Seized',
    Released: 'Released',
    Returned: 'Returned',
    // Office statues
    OfficeStop: 'Office Stop',
    OfficeRelease: 'Office Release',
  },
  StatusPastTense: {},
  BrandStatus: {
    Active: 'Active',
    Processing: 'Processing',
    Inactive: 'Inactive',
  },
  FileUpload: {
    extensionError: 'extensionError',
    fileSizeError: 'fileSizeError',
    uploadError: 'uploadError',
    permissionError: 'permissionError',
    unexpectedError: 'There was an unexpected error. Please, try again',
    uploadType: {
      image: 'image',
      spreadsheet: 'spreadsheet',
      knowledge_base: 'knowledge_base',
      attachments: 'attachments',
      brand_contract: 'brand_contract',
      brand_invoice: 'brand_invoice',
      brand_details: 'brand_details',
    },
    uploadTypeUtils: {
      image: {
        validMimeTypes: ['image/jpeg', 'image/png'],
        extensionError: 'Please upload a JPG or PNG file',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      spreadsheet: {
        validMimeTypes: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError: 'Please upload a XLS or CSV file',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      // Word, PDF, Excel, Image
      knowledge_base: {
        validMimeTypes: [
          'image/jpeg',
          'image/png',
          // 'application/vnd.ms-powerpoint',
          // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, Excel, CSV, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_details: {
        //  Image
        validMimeTypes: ['image/jpeg', 'image/png'],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_contract: {
        // Word, PDF, Image
        validMimeTypes: [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_invoice: {
        // Word, PDF, Image
        validMimeTypes: [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      // Word, PDF, Excel, Image
      attachments: {
        validMimeTypes: [
          'image/jpeg',
          'image/png',
          // 'application/vnd.ms-powerpoint',
          // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, Excel, CSV, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
    },
  },
  fallback: ' - ',
  empty: '',
  emptyKeyword: '!empty!',
  all: 'all',
  lsState: 'lsState',
  Snackbar: {
    error: 'Error',
    warning: 'Warning',
    success: 'Success',
  },
  OwnBrandKeyword: 'Your',
  DropdownStatus: {},
  noLimit: 1e9,
  excludeFilters: '!EXCLUDE!',
  unknownBrandIds: ['unk-id', 'ea6d68da-68b6-4566-8a4a-cdde9315bac5'],
  isSelf: 'self',
  Mock: {
    image: 'https://source.unsplash.com/random',
  },
};

export const Prose = {
  allBrandsDisabled: 'Switch to a specific brand in order to use this feature',
  noPermission: "You don't have permission to perform this action",
};

Constants.DropdownStatus = {
  [Constants.Status.Sideload]: Constants.Status.Sideload,
  [Constants.Status.Inspection]: Constants.Status.Inspection,
  [Constants.Status.Authentication]: Constants.Status.Authentication,
  [Constants.Status.Counterfeit]: Constants.Status.Counterfeit,
  [Constants.Status['Non-counterfeit']]: Constants.Status['Non-counterfeit'],
  [Constants.Status.Stopped]: Constants.Status.Stopped,
  [Constants.Status.Seized]: Constants.Status.Seized,
  [Constants.Status.Released]: Constants.Status.Released,
};

Constants.StatusPastTense = {
  [Constants.Status.Sideload]: 'Sideloaded',
  [Constants.Status.Inspection]: 'Sent for Inspection',
  [Constants.Status.Authentication]: 'Sent for Authentication',
  [Constants.Status.Stopped]: 'Stopped',
  [Constants.Status.Seized]: 'Seized',
  [Constants.Status.Released]: 'Released',
};

export const scrollbarOffset = '8px';

export const ResponsiveSizes = {
  minPageWidth: 1048,
  maxContentWidth: 1400,
  minWindowHeight: 700,
};

export const Styles = {
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexBetweenCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexAroundCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  flexColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  flexColumnEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  absoluteOveray: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  // Layout.Spacing._S
  gridColumnGap: '16px',
  scroll: {
    height: 0,
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    width: `calc(100% + ${scrollbarOffset})`,
  },
};

export const Utils = {
  emptyFunction: () => {},
  falseReturn: () => false,
  trueReturn: () => true,
  emptyObjectFunction: () => {
    return {};
  },
  emptyFragment: () => <></>,
  unary: x => x,
  stopPropagation: e => e?.stopPropagation(),
  expandRow: () => ({ expand: true }),
  avoidRowExpand: () => ({ expand: false }),
  avoidRowAction: () => false,
};

export const PropKeys = {
  addReferenceToggle: 'addReferenceToggle',
  authCode: 'authCode',
  authType: 'authType',
  brandId: 'brandId',
  brandImage: 'brandImage',
  brandName: 'brandName',
  brandStatus: 'brandStatus',
  favoritedBrand: 'favoritedBrand',
  clients: 'clients',
  clientId: 'clientId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  isCurrentBrand: 'isCurrentBrand',
  isLogisticsUser: 'isLogisticsUser',
  phone: 'phone',
  userImage: 'userImage',
  username: 'username',
  sorters: 'sorters',
  storageToken: 'storageToken',
  unreadSupportMessageCount: 'unreadSupportMessageCount',
  userId: 'userId',
  sorterId: 'sorter_id',
};

// TODO: update this to better key mask
export const PropKeysMask = {
  storage_token: PropKeys.storageToken,
  brID: PropKeys.brandId,
  brName: PropKeys.brandName,
  brImage: PropKeys.brandImage,
  bguaIsFavorite: PropKeys.favoritedBrand,
  brand_status: PropKeys.brandStatus,
  sorters: PropKeys.sorters,
};

// DON'T include any dropdowns here
export const DISPATCH_CONSTANTS = {
  ALL: 'ALL',
  ERROR: 'ERROR',
  FILTERS_SET: 'FILTERS_SET',
  HAS_RESET: 'HAS_RESET',
  ITEMS_PER_PAGE: 'ITEMS_PER_PAGE',
  OTHERS: 'OTHERS',
  OWN: 'OWN',
  PAGE: 'PAGE',
  REFRESH: 'REFRESH',
  RESET: 'RESET',
  SEARCH: 'SEARCH',
  SEARCH_TRIGGER: 'SEARCH_TRIGGER',
  SET: 'SET',
  TIME_FRAME: 'TIME_FRAME',
  TIME_FRAME_SET: 'TIME_FRAME_SET',
};

export const envVars = {
  environment: process.env.REACT_APP_ENV,
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.columns ?? 12},
    minmax(min(10px, 100%), 1fr)
  );
  height: 100%;
  width: 100%;
  align-items: flex-start;
  grid-gap: ${Styles.gridColumnGap};
`;

export const UtilityComponents = {
  Grid,
};

export const Placeholders = {
  image: {
    _2XL: imagePlaecholder2XL,
    _XL: imagePlaecholderXL,
    _L: imagePlaecholderL,
    _M: imagePlaecholderM,
    _S: imagePlaecholderS,
    _XS: imagePlaecholderXS,
    _2XS: imagePlaecholder2XS,
  },
  company: {
    _2XL: companyPlaecholder2XL,
    _XL: companyPlaecholderXS,
    _L: companyPlaecholderL,
    _M: companyPlaecholderM,
    _S: companyPlaecholderS,
    _XS: companyPlaecholderXL,
    _2XS: companyPlaecholder2XS,
  },
};

export const PlaceholdersTypes = {
  image: 'image',
  company: 'company',
};

export const re = {
  uuid: /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
  url: /(\S+\.(com|net|org|edu|gov|ms)\b(\/\S+)?)/,
  urlProtocol: /^http[s]?:\/\//,
  ddmmyy: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{2}$/,
};

// URL re: matches broadly, but we must specify what types of links are valid
// /(\S+\.(com|net|org|edu|gov|ms)\b(\/\S+)?)/
