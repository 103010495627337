import { useEffect, useRef } from 'react';
import { isEqual } from '../lib/js';

const isPrimitive = val => val !== Object(val);

const useCustomCompareEffect = (effect, deps, depsEqual) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    if (!(deps instanceof Array) || !deps.length) {
      // console.warn(
      //   '`useCustomCompareEffect` should not be used with no dependencies. Use React.useEffect instead.'
      // );
    }

    if (deps.every(isPrimitive)) {
      // console.warn(
      //   '`useCustomCompareEffect` should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
      // );
    }

    if (typeof depsEqual !== 'function') {
      // console.warn(
      //   '`useCustomCompareEffect` should be used with depsEqual callback for comparing deps list'
      // );
    }
  }

  const ref = useRef(undefined);

  if (!ref.current || !depsEqual(deps, ref.current)) {
    ref.current = deps;
  }

  useEffect(effect, ref.current);
};

const useDeepCompareEffect = (effect, deps) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    if (!(deps instanceof Array) || !deps.length) {
      // console.warn(
      //   '`useDeepCompareEffect` should not be used with no dependencies. Use React.useEffect instead.'
      // );
    }

    if (deps.every(isPrimitive)) {
      // console.warn(
      //   '`useDeepCompareEffect` should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
      // );
    }
  }

  useCustomCompareEffect(effect, deps, isEqual);
};

export default useDeepCompareEffect;
