import styled from '@emotion/styled';
import { Colors, Effects } from '../../style';

export const TabHighlight = styled.div`
  position: absolute;
  bottom: 0;
  height: 2px;
  z-index: 10;
  background-color: ${Colors.Primary._600};
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  transition: ${Effects.Transition.EaseInOut('left', 'width')};
`;
