import { forwardRef, useEffect } from 'react';
import { PropKeys } from '../../../constants';
import { useReauth } from '../../../providers/AuthProvider';
import { useStore } from '../../../providers/StoreProvider';
import { NotificationsLayout } from '../components/NotificationsLayout';
import { notificationKeys } from './variables';

const Notifications = ({ notificationProps }, ref) => {
  const { switchBrands } = useReauth();
  const { store } = useStore();

  const currentBrandId = store[PropKeys.brandId];
  const handleNavigate = notification => async () => {
    const newBrandId = notification[notificationKeys.brandId];
    const urlBrandId = `?brand_id=${newBrandId}`;
    const link = notification[notificationKeys.link];

    if (newBrandId !== currentBrandId)
      await switchBrands(notification[notificationKeys.brandId]);

    const to = '/' + link.replace('cases/', 'products/') + urlBrandId;

    window.location.href = to;
  };

  useEffect(() => {
    return () => notificationProps.onClose();
  }, []);

  return (
    <NotificationsLayout
      {...notificationProps}
      handleNavigate={handleNavigate}
      ref={ref}
    />
  );
};

export default forwardRef(Notifications);
