import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';

export const useGetParcelsAccumulated = ({ searchParams, hasPermission }) => {
  const [{ data, isLoading, isError, error }, _, __, refresh] = useFetchData(
    'office_dashboard/parcel_cum_counts',
    { ignore: !hasPermission, params: formatParams(searchParams) }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
