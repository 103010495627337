import { Cell, PieChart as ModulePieChart, Pie } from 'recharts';
import { Flex } from '../../atoms/Flex/Flex';
import { Labels, Main, PieContainer, defaultColors } from './PieChart.style';

function PieChart({
  data = [],
  total = 1,
  main,
  customDimensions = {},
  colors = defaultColors,
  justifyLabel,
  onClick,
}) {
  if (!colors.length) colors = defaultColors;
  const withMinSectionLength = data => {
    const degreesAlloted = 8;
    const degrees360 = 360;
    const degAvailable = degrees360 - data.length * degreesAlloted;
    const minSectionAlloted = (total * degreesAlloted) / degrees360;
    return data.map(entry => {
      const sectionAlloted =
        minSectionAlloted + (entry.value * degAvailable) / degrees360;
      return {
        ...entry,
        minSectionLengthValue: sectionAlloted,
      };
    });
  };

  // sample default. should always be overwritten by customDimensions
  const {
    width = 165,
    height = 165,
    cx = 80,
    cy = 80,
    innerRadius = 64,
    outerRadius = 80,
  } = customDimensions;

  return (
    <Flex justify="start" align="center" gap="_XL" className={'piechart-flex'}>
      <PieContainer>
        <ModulePieChart width={width} height={height}>
          <Pie
            data={withMinSectionLength(data)}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey="minSectionLengthValue"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </ModulePieChart>
        {main && <Main total={main.value} description={main.label} />}
      </PieContainer>
      <Labels
        data={data}
        colors={colors}
        justify={justifyLabel}
        onClick={onClick}
      />
    </Flex>
  );
}

export default PieChart;
