import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Effects } from '../../../../../components/style';
import { UtilityComponents } from '../../../../../constants';
import { DisableChartContainer } from '../../../../../utils/styleUtils';

export const Container = styled(Flex)`
  flex-grow: 1;
`;

export const ChartsCSS = styled(UtilityComponents.Grid)`
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  grid-auto-rows: 376px;
`;

export const DashboardCard = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
  .card {
    height: 100%;
    filter: ${({ disabled }) => (disabled ? 'blur(1px)' : '')};
  }
  .recharts-responsive-container {
    height: 0 !important;
    flex-grow: 1;
  }
`;

export const DisableContainer = styled(DisableChartContainer)`
  height: 100%;
`;

export const FilterStyles = styled.div`
  width: 100%;
`;
