import { useToggle } from '@react-hookz/web';
import { Formik } from 'formik';
import { isEqual } from '../../../../../lib/js';
import { barcodesTableComponents } from '../components';
import { DetailsLayout } from '../components/DetailsLayout';
import {
  addBarcodeFormProps,
  barcodeField,
  barcodesGrid,
  barcodesHeaders,
  detailsValidationSchema,
  parcelKeys,
} from './variables';

const Details = ({
  initialData,
  data,
  dbData,
  updateData,
  toggleCanSubmitInCurrentTab,
}) => {
  const [isAddingBarcode, toggleIsAddingBarcode] = useToggle(false);

  const barcodesStagedForRemoval = data?.[parcelKeys.barcodesRemoving] ?? [];
  const barcodesStagedForAddition = data?.[parcelKeys.barcodesAdding] ?? [];

  const removeBarcode = barcode => _ => {
    const existsInStagedForAddition = barcodesStagedForAddition.some(
      b => b[parcelKeys.barcode] === barcode[parcelKeys.barcode]
    );

    if (existsInStagedForAddition) {
      const additionWithoutBarcode = barcodesStagedForAddition.filter(
        b => b[parcelKeys.barcode] !== barcode[parcelKeys.barcode]
      );
      updateData(x => ({
        ...x,
        [parcelKeys.barcodesAdding]: additionWithoutBarcode,
      }));
    } else {
      const removalWithBarcode = barcodesStagedForRemoval.concat(barcode);
      updateData(x => ({
        ...x,
        [parcelKeys.barcodesRemoving]: removalWithBarcode,
      }));
    }
  };

  const addBarcode = values => {
    const { [barcodeField]: barcode } = values;
    updateData(x => ({
      ...x,
      [parcelKeys.barcodesAdding]: (x[parcelKeys.barcodesAdding] ?? []).concat({
        [parcelKeys.barcode]: barcode.trim(),
      }),
    }));
    toggleIsAddingBarcode();
  };

  const onTrackingNumberChange = e => {
    updateData(x => ({
      ...x,
      [parcelKeys.trackingNumber]: e.target.value,
    }));
  };

  const addBarcodeIsLoading = false;
  const barcodesWithoutStagedForRemoval = (
    data[parcelKeys.barcodes] ?? []
  ).filter(
    barcode =>
      !barcodesStagedForRemoval.some(
        b => b[parcelKeys.barcode] === barcode[parcelKeys.barcode]
      )
  );

  const displayData = [
    ...barcodesStagedForAddition,
    ...barcodesWithoutStagedForRemoval,
  ];

  const isSameValue = values => isEqual(values, dbData);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={detailsValidationSchema}
      validateOnMount
    >
      {({ isValid, values }) => {
        toggleCanSubmitInCurrentTab(
          (isValid && !isSameValue(values)) ||
            barcodesStagedForAddition?.length ||
            barcodesStagedForRemoval?.length
        );
        return (
          <DetailsLayout
            trackingNumberProps={{
              title: 'TRACKING NUMBER',
              inputProps: {
                size: '_M',
                placeholder: 'Enter tracking number',
                name: parcelKeys.trackingNumber,
                onChange: onTrackingNumberChange,
              },
            }}
            barcodesProps={{
              title: 'BARCODES',
              tableProps: {
                headers: barcodesHeaders,
                data: displayData,
                grid: barcodesGrid,
                components: barcodesTableComponents({ removeBarcode }),
              },
              addBarcodeProps: {
                toggleAddBarcode: toggleIsAddingBarcode,
                addBarcode,
                isAddingBarcode,
                formProps: {
                  ...addBarcodeFormProps,
                  onCancel: toggleIsAddingBarcode,
                  dismiss: toggleIsAddingBarcode,
                  primaryText: 'Add',
                  isValid,
                  isLoading: addBarcodeIsLoading,
                },
              },
            }}
          />
        );
      }}
    </Formik>
  );
};
export default Details;
