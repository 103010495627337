import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Button from '../../../../../components/molecules/Button/Button';
import FormCheckBox from '../../../../../components/molecules/CheckBox/FormCheckBox';
import { Table } from '../../../../../components/organisms/Table/Table';
import {
  SubtableCSS,
  TableHeaderText,
} from '../../../../../components/organisms/Table/Table.exports';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { fieldNames } from '../controllers/variables';

export const AdminServicesTableContainer = styled(Table)`
  .label {
    height: 24px;
  }
  .table-cell, .table-header {
    &._0 {
      padding-left: ${Layout.Spacing._S};
    }
`;

export const AddContent = styled(Content)`
  display: flex;
  flex-direction: column;
  *::-webkit-scrollbar {
    width: 8px;
    display: block;
  }
`;

export const FieldsCSS = styled(Flex)`
  flex-wrap: wrap;
  .${fieldNames.type},
    .${fieldNames.icon},
    .${fieldNames.name},
    .${fieldNames.systemName} {
    width: 40%;
    flex-grow: 1;
  }
`;

export const IconPreviewCSS = styled.div`
  position: absolute;
  right: ${10}px;
  bottom: ${10}px;
  z-index: 1;
`;

const optionWidth = '48px';

const PermissionGrid = styled.div`
  display: grid;
  grid-template-columns: 0px 1fr 1fr repeat(3, ${optionWidth}) 60px;
  column-gap: ${Layout.Spacing._S};
`;

export const PermissionsGridCSS = styled(Flex)`
  overflow: hidden;
  border-radius: 0 0 ${Effects.Border_Radius._S} ${Effects.Border_Radius._S};
  border: 1px solid ${Colors.Neutral._300};
  border-top: none;
`;

export const PermissionsGridHeadersCSS = styled(PermissionGrid)`
  grid-template-rows: 24px;
  background: ${Colors.Primary._600};
  border-radius: ${Effects.Border_Radius._S} ${Effects.Border_Radius._S} 0 0;
  overflow: hidden;
  align-items: center;
`;

export const PermissionRowCSS = styled(PermissionGrid)`
  height: 72px;
  width: 100%;
  position: relative;
  align-items: center;
`;

export const PermissionGridHeaderCSS = styled(Flex)`
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

export const AddPermisionButtonCSS = styled.div`
  padding: 0 ${Layout.Spacing._S};
  grid-column: 1 / 8;
`;

export const AddPermisionButtonCustom = styled(Button)``;

export const PermissionTableHeaderText = styled(TableHeaderText)`
  padding-left: ${({ center }) => (center ? '' : Layout.Spacing._2XS)};
`;

export const PermissionCheckBox = styled(FormCheckBox)`
  justify-content: center;
  align-items: center;
`;

export const PermissionSubtableCSS = styled(SubtableCSS)`
  .header-row {
    grid-template-rows: 24px;
    .table-header {
      background: ${Colors.Primary._600};
      span {
        color: ${Colors.Sharp.White} !important;
      }
    }
  }
`;
