import { Outlet } from 'react-router-dom';
import { Layout } from '../components/templates/Layout';

export const ProtectedLayout = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
