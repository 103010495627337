import { Flex } from '../../../../components/atoms/Flex/Flex';
import { Separator } from '../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../components/atoms/Spacer/Spacer';
import { CardLoader } from '../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { SectionTitle } from '../../../../components/organisms/Sidebar/components';
import { Announcement, Notification } from '../molecules';
import { AnnouncementsContainer, AnnouncementsScroll } from '../style';

export const Announcements = ({
  announcements,
  clearMenu,
  showFeaturePreview,
}) => {
  return (
    <AnnouncementsContainer column>
      <SectionTitle text={'Announcements'} />
      <AnnouncementsScroll column gap={'_XS'}>
        {announcements.map((announcement, index) => (
          <Announcement
            showFeaturePreview={showFeaturePreview}
            announcement={announcement}
            clearMenu={clearMenu}
            key={index}
          />
        ))}
      </AnnouncementsScroll>
    </AnnouncementsContainer>
  );
};

export const NotificationsDisplay = ({
  notifications,
  handleNavigate,
  isLoading,
  scrollRef,
}) => {
  return (
    <Flex relative column grow>
      <SectionTitle text={'Notifications'} />
      <Spacer height="_S" />
      <Flex column gap="_XS" scroll ref__={scrollRef} relative>
        {notifications.map((notification, index) => (
          <Notification
            notification={notification}
            handleNavigate={handleNavigate}
            key={index}
          />
        ))}
        {isLoading && <CardLoader />}
      </Flex>
      <Separator style={{ bottom: 0 }} />
    </Flex>
  );
};
