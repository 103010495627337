import usePostData from '../../../../../hooks/postHook';

export const useUpdateBrand = ({ id }) => {
  const [{ data, body, isLoading, isError, error }, updateBrand] = usePostData(
    `office_case/${id}/brand`,
    { type: 'put' }
  );

  return { data, body, isLoading, isError, error, updateBrand };
};
