import { useToggle } from '@react-hookz/web';
import { cloneDeep } from 'lodash';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Constants, PropKeys, Prose, Utils } from '../../../../../constants';
import { useScrollLoad } from '../../../../../hooks/scrollLoadHook';
import { flow, moveItemToStart } from '../../../../../lib/js';
import { useReauth } from '../../../../../providers/AuthProvider';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import {
  extractPropFromURL,
  maskEntry,
  openNewTab,
} from '../../../../../utils/utils';
import { GalleryProps } from '../../../constants';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { useGetParcelDetails } from '../../../features_public/parcels/api/getParcelDetails';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { useGetProducts } from '../../../features_public/products/api/getProducts';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import ProductSummary from '../../../features_public/products/controllers/ProductSummary';
import { matchImagesToProduct } from '../../../features_public/products/controllers/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';
import CreateTicket from '../../../features_public/support/controllers/CreateTicket';
import { ticketTypes } from '../../../features_public/support/controllers/variables';
import { Labels, NavURIs, Pages, URIs } from '../../../routes/variables';
import { useGetProductDetails } from '../../products/api/getProductDetails';
import { useUpdateProductStatus } from '../../products/api/updateProductStatus';
import { useGetAuthenticationActivity } from '../api/getAuthenticationActivity';
import { useGetAuthenticationPerformance } from '../api/getAuthenticationPerformance';
import { useGetFeedbackQuestions } from '../api/getFeedbackQuestions';
import { useSendAuthenticationFeedback } from '../api/sendAuthenticationFeedback';
import AuthenticationLayout from '../components/AuthenticationLayout';
import { StatusConfirmModal } from '../components/style';
import {
  actionRequiredStatus,
  feedbackModalReducer,
  getProductsToAuthenticate,
  getRowsThatNeedHighlighting,
  slideReducer,
} from '../utils';
import {
  DC,
  headers as allHeaders,
  confirmModalDescription,
  confirmModalTitle,
  feedbackModalTitle,
  grid,
  initialSliderState,
  authenticationKeys as keys,
  noResultsState,
  parcelMask,
  relatedProductsGrid,
  relatedProductsHeaders,
  searchParams,
  statusIcons,
} from '../variables';
import FeedbackModal from './FeedbackModal';

const {
  SELF: SUPPORT,
  CREATE_PRODUCT_SUPPORT_TICKETS,
  VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  VIEW_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const { SELF: PRODUCTS, VIEW_PRODUCTS } = PermissionKeys.Brand.PRODUCTS;

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Brand.PARCELS;

const Authentication = () => {
  const [activeTab, setActiveTab] = useState('required');
  const [moreActivityClicked, setMoreActivityClicked] = useState(false);
  const [authSliderState, dispatch] = useReducer(
    slideReducer,
    initialSliderState
  );
  const [hasDoneFirstLoad, toggleHasDoneFirstLoad] = useToggle(false);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { cleanUrl } = useReauth();

  const { setHeaderChildren, setShowHeader, setHeaderIndex } = useHeader();
  const { setShowFooter } = useFooter();
  const { showSnackbarError } = useSnackbar();
  const { dismiss, showModal } = useModal();
  const { store } = useStore();
  const { hasPermission } = usePermissions();

  const hasTicketCreatePermission = hasPermission(
    SUPPORT,
    CREATE_PRODUCT_SUPPORT_TICKETS
  );

  const hasSupportViewPermission = hasPermission(SUPPORT, [
    VIEW_PRODUCT_SUPPORT_TICKETS,
    VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  ]);

  const hasProductViewPermission = hasPermission(PRODUCTS, VIEW_PRODUCTS);

  const hasParcelViewPermission = hasPermission(PARCELS, VIEW_PARCELS);

  const hasCaseViewPermission = hasParcelViewPermission;

  const hasActivityViewPermission =
    hasSupportViewPermission || hasProductViewPermission;

  const activityRef = useRef(null);
  const waitForImagesRef = useRef(false);
  const imageStartIndexRef = useRef(null);
  const feedbackModalRef = useRef(null);

  const isAllBrands = !store[PropKeys.brandId];

  const ownProducts = {};

  if (store[PropKeys.brandId])
    ownProducts[DC.BRANDS] = [
      {
        options: [
          {
            value: store[PropKeys.brandId],
            selected: true,
          },
        ],
      },
    ];

  const {
    data: { products },
    isLoading: productsIsLoading,
    isError: productsIsError,
    error: productsError,
    getProducts,
  } = useGetProducts({
    searchParams: searchParams(ownProducts),
    is_authentication_page: true,
  });

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getProductsImage,
  } = useGetProductsImage();

  const imagesIsLoadingRef = useRef(imagesIsLoading);

  const {
    data: authenticationPerformance,
    isLoading: authenticationPerformanceIsLoading,
    isError: authenticationPerformanceIsError,
    getPerformance,
  } = useGetAuthenticationPerformance();

  const {
    data: parcel,
    isLoading: parcelsIsLoading,
    isError: parcelsIsError,
    getParcelDetails,
  } = useGetParcelDetails({ searchParams: {}, ignore: true });

  const {
    data: relatedProductsImages,
    body: relatedProductsBody,
    isLoading: relatedProductsImagesIsLoading,
    isError: relatedProductsImagesIsError,
    error: relatedProductsImagesError,
    getProductsImage: getRelatedProductsImage,
  } = useGetProductsImage();

  const {
    data: productDetails,
    isLoading: productDetailsIsLoading,
    isError: productDetailsIsError,
    error: productDetailsError,
    getProductDetails,
  } = useGetProductDetails({ hasTicketCreatePermission });

  const {
    data: activity,
    isLoading: activityIsLoading,
    isError: activityIsError,
    fetchMore,
    refetch,
  } = useScrollLoad({
    hook: useGetAuthenticationActivity,
    getMore: 'getActivity',
    moreActivityClicked,
    scrollRef: activityRef,
    initialLimit: 10,
    ignore: !hasActivityViewPermission,
  });

  const currentProduct =
    authSliderState?.productsBeingAuthenticated[
      authSliderState?.activeSlideIndex
    ];

  const {
    doProductStatusUpdate,
    body: productStatusUpdateBody,
    isLoading: productStatusUpdateIsLoading,
    isError: productStatusUpdateIsError,
    error: productStatusUpdateError,
  } = useUpdateProductStatus({
    id: currentProduct?.[keys.id],
  });

  const { doGetFeedbackQuestions } = useGetFeedbackQuestions({});

  const { doSendFeedback } = useSendAuthenticationFeedback();

  useDeepCompareEffect(() => {
    if (productsIsLoading || products == null) return;
    setData(products);
    if (
      products?.length
      // && hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    )
      getProductsImage({
        products,
        params: { only_thumbs: false, limit: null },
      });
  }, [productsIsLoading, productsIsError, products]);

  useDeepCompareEffect(() => {
    if (activityIsLoading || productsIsLoading) return;
    toggleHasDoneFirstLoad(true);
  }, [activityIsLoading, activity, productsIsLoading]);

  useDeepCompareEffect(() => {
    if (
      imagesIsError
      // || !hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    ) {
      if (imagesIsError) showSnackbarError(imagesError);
      setData(matchImagesToProduct([]));
      return;
    }

    if (products?.length && images != null)
      setData(data => {
        const matchedData = matchImagesToProduct(images)(data);
        if (waitForImagesRef.current) {
          waitForImagesRef.current = false;
          runAuthGalleryDispatch(imageStartIndexRef.current)(matchedData);
          imageStartIndexRef.current = null;
        }
        return matchedData;
      });
  }, [images]);

  useDeepCompareEffect(() => {
    if (productDetailsIsLoading || productDetails == null) return;
    if (productDetailsIsError) {
      showSnackbarError(productDetailsError);
      return;
    }

    const payload = {
      [productDetails[caseKeys.id]]: productDetails,
    };
    dispatch({ type: 'product_details', payload });
  }, [productDetailsIsLoading, productDetailsIsError, productDetails]);

  useEffect(() => {
    imagesIsLoadingRef.current = imagesIsLoading;
    if (imagesIsLoading || !waitForImagesRef.current) return;
  }, [imagesIsLoading]);

  useDeepCompareEffect(() => {
    if (parcelsIsLoading || parcel == null) return;
    if (parcelsIsError) return;
    const parcel_ = parcel;
    const parcelId = parcel_[caseKeys.id];
    dispatch({
      type: 'save_parcel_results',
      payload: {
        [parcelId]: maskEntry(parcel_, parcelMask),
      },
    });
    const products = parcel_[parcelKeys.products].filter(
      product => product[productKeys.productId]
    );
    getRelatedProductsImage({ products, params: { parcelId } });
  }, [parcelsIsLoading, parcelsIsError, parcel]);

  useDeepCompareEffect(() => {
    if (
      relatedProductsImagesIsError
      // || !hasPermission([VIEW_PRODUCTS_PHOTOS, VIEW_PRODUCTS])
    ) {
      showSnackbarError(relatedProductsImagesError);
    }
    const parcelId = relatedProductsBody?.params?.parcelId;
    const parcel = Object.assign({}, authSliderState.parcel_results[parcelId]);
    const products = parcel?.[parcelKeys.products];

    const images = relatedProductsImagesIsError ? [] : relatedProductsImages;

    if (products?.length && relatedProductsImages != null) {
      const productsWithImages = matchImagesToProduct(images)(
        parcel[parcelKeys.products]
      );

      parcel[parcelKeys.products] = productsWithImages;

      dispatch({
        type: 'save_parcel_results',
        payload: {
          [parcelId]: parcel,
        },
      });
    }
  }, [relatedProductsImages]);

  useEffect(() => {
    if (productStatusUpdateIsLoading || productStatusUpdateBody == null) return;
    if (productStatusUpdateIsError) {
      showSnackbarError(productStatusUpdateError);
      return;
    }
    const { index, status } = productStatusUpdateBody;
    const product = authSliderState.productsBeingAuthenticated[index];
    updateProductStatus({ status, product, index });
  }, [
    productStatusUpdateIsLoading,
    productStatusUpdateIsError,
    productStatusUpdateBody,
  ]);

  useEffect(() => {
    const { search } = location;

    const activeTab = extractPropFromURL('activeTab', search);

    if (!activeTab) return;
    setActiveTab(activeTab);
    const to = `${NavURIs[Pages.authentication]}`;

    cleanUrl(to);
  }, [store[PropKeys.brandId], location.pathname]);

  useEffect(() => {
    return () => setHeaderIndex(100);
  }, []);

  useDeepCompareEffect(() => {
    if (hasDoneFirstLoad && !activity?.length) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <Flex justify="between" w align="center">
          <ViewTitle text={Labels[Pages.authentication]} noNav />
          <Button
            text="Start authentication"
            leftIcon={Icons.Play}
            onClick={startAuthentication(0)}
            size="_S"
            variant="Primary"
            disabled={getProductsToAuthenticate(data).length === 0}
          />
        </Flex>
      );

      setTimeout(() => setHeaderChildren(headerChildren), 0);
    }
  }, [data, authSliderState, activity, hasDoneFirstLoad]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  const startAuthentication = index => () => {
    if (imagesIsLoading || imagesIsLoadingRef.current) {
      waitForImagesRef.current = true;
      imageStartIndexRef.current = index;
      dispatch({ type: 'loading' });
      return;
    }
    setData(runAuthGalleryDispatch(index));
  };

  const runAuthGalleryDispatch = index => data => {
    const payload = flow([getProductsToAuthenticate, moveItemToStart(index)])(
      data
    );

    setHeaderIndex(0);
    dispatch({ type: 'init', payload });
    return data;
  };

  const showGallery = (item, index) => () => {
    showModal(Gallery, {
      id: item[productKeys.productId],
      title: `Product ${item[productKeys.productAlias]}`,
      initialSelected: index,
      ...GalleryProps.products.formatter([item]),
      ...GalleryProps.products,
    });
  };

  const filterHeaders = (allHeaders, activeTab) => {
    const headers = cloneDeep(allHeaders);
    if (activeTab === 'required') delete headers[keys.status];
    if (activeTab === 'completed') delete headers[keys.remainingAuthTime];

    return headers;
  };

  const headers = filterHeaders(allHeaders, activeTab);

  const loadProductDetails = item => {
    const { [keys.parcel.id]: parcelId, [keys.id]: id } = item;

    getParcelDetails(parcelId);
    getProductDetails(id);
  };

  const rowAction = (_, index) => startAuthentication(index)();

  const handleNotificationClick = (activityItem, index) => () => {
    const { type, itemId } = activityItem;

    const destination = {
      Product: `${NavURIs[Pages.products]}/${itemId}`,
      Support: `${NavURIs[Pages.support]}/${itemId}`,
    };

    const to = destination[type];
    openNewTab(to);
  };

  const showProductSummary = item => {
    // if (!(hasPermission(VIEW_PRODUCTS_SUMMARY) || hasViewPermission)) return;
    let {
      [productKeys.productId]: productId,
      [productKeys.productAlias]: productAlias,
    } = item;

    showModal(ProductSummary, {
      productId,
      productAlias,
      navigate,
      from: location.pathname,
      newTab: true,
    });
  };

  const firstActivityLoad = () => {
    setMoreActivityClicked(true);
    fetchMore();
  };

  const dismissAuthentication = () => {
    setHeaderIndex(100);
    dispatch({ type: 'dismiss' });
  };

  const authenticate =
    status =>
    (product, index) =>
    ({ disabled = false }) => {
      showModal(StatusConfirmModal, {
        title: confirmModalTitle[status],
        description: confirmModalDescription[status],
        confirm: 'Confirm',
        onConfirm: onAuthenticateConfirm({ status, product, index }),
        onCancel: dismiss,
        textSize: '_L',
        disabled,
      });
    };

  const onAuthenticateConfirm =
    ({ status, product, index }) =>
    async () => {
      try {
        const productId = product[productKeys.productId];

        authenticate(status)(product, index)({ disabled: true });

        let res = await doProductStatusUpdate({ status, index });
        const { error, data } = res;
        if (error && !data) {
          authenticate(status)(product, index)({ disabled: false });
          return;
        }
        const [question] = await doGetFeedbackQuestions({
          url: `case/${productId}/authentication_feedback_questions`,
        });

        if (question == null) {
          close();

          return;
        }

        const questionTitle = question[keys.feedback.question.title];
        const questionId = question[keys.feedback.question.id];

        const options = question.answers.map(option => {
          return {
            label: option[keys.feedback.answer.title],
            id: option[keys.feedback.answer.id],
            hasNote: !!option[keys.feedback.answer.hasNote],
          };
        });

        if (!options.length) close();

        const recordFeedback = () => {
          const { value: answer_id, note } = feedbackModalRef.current;

          const url = `case/${productId}/authentication_feedback`;
          const body = {
            question_id: questionId,
            answer_id,
            note,
          };

          doSendFeedback(body, url);
        };

        const onConfirm = () => {
          recordFeedback();
          close();
        };

        const onTertiary = () => close();

        showModal(
          FeedbackModal,
          {
            title: feedbackModalTitle[status],
            values: feedbackModalRef,
            options,
            reducer: feedbackModalReducer[status],
            initialState: {
              subtitle: questionTitle,
              value: options[0].id,
            },
            confirm: 'Confirm',
            tertiary: 'Skip',
            onConfirm,
            onTertiary,
            dismiss,
          },
          { ignoreOverlayDismiss: true }
        );
      } catch (error) {
        close();
      }
    };

  const createSupportTicket = (product, index) => () => {
    showModal(CreateTicket, {
      productData: product,
      productTitle: product[productKeys.productAlias],
      type: ticketTypes.Product,
      onCreateTicket: onCreateTicket(product, index),
    });
  };

  const onCreateTicket = (product, index) => () => {
    markSupportTicketCreated(product, index);
    loadProductDetails(product);
  };

  const markSupportTicketCreated = (product, index) => {
    const products = authSliderState.productsBeingAuthenticated;
    products[index][keys.hasSupportTickets] = true;
    dispatch({ type: 'update_status', payload: products });
    close();
  };

  const updateProductStatus = ({ status, product, index }) => {
    const newAuthingProducts = authSliderState.productsBeingAuthenticated;
    if (newAuthingProducts[index] == null) return;
    newAuthingProducts[index][keys.status] = status;
    dispatch({ type: 'update_status', payload: newAuthingProducts });

    const productId = product[productKeys.productId];
    setData(data => {
      const productIndex = data.findIndex(
        ({ [productKeys.productId]: id }) => id === productId
      );
      data[productIndex][keys.status] = status;
      return data;
    });
  };

  const dismissAuthenticationGalleryWhenDone = () => {
    const { atEnd } = authSliderState;
    if (atEnd) {
      getPerformance();
      dispatch({ type: 'dismiss' });
    }
  };

  const onTicketClick = ticketId => () => {
    if (ticketId == null) {
      showSnackbarError(
        'There is an error with this ticket. Please, try again'
      );
      return;
    }
    const to = `/${URIs[Pages.support]}/${ticketId}`;
    openNewTab(to);
  };

  const actions = [
    {
      leftIcon: statusIcons[Constants.Status.Counterfeit],
      text: Constants.StatusLabel.Counterfeit,
      variant: 'Status',
      status: Constants.Status.Counterfeit,
      onClick: authenticate(Constants.Status.Counterfeit),
      size: '_M',
      fullWidth: true,
    },
    {
      leftIcon: statusIcons[Constants.Status.Authentication],
      text: 'Not sure? Request support',
      variant: 'Tertiary',
      status: Constants.Status.Counterfeit,
      onClick: createSupportTicket,
      size: '_M',
      disabled: !hasTicketCreatePermission,
      tooltipContent: isAllBrands ? Prose.allBrandsDisabled : '',
    },
    {
      leftIcon: statusIcons[Constants.Status['Non-counterfeit']],
      text: Constants.StatusLabel['Non-counterfeit'],
      variant: 'Status',
      status: Constants.Status['Non-counterfeit'],
      onClick: authenticate(Constants.Status['Non-counterfeit']),
      size: '_M',
      fullWidth: true,
    },
  ];

  const filterDataByTab = (data, activeTab) => {
    const check = s =>
      activeTab === 'required'
        ? actionRequiredStatus.includes(s)
        : !actionRequiredStatus.includes(s);

    return data.filter(({ [keys.status]: status }) => check(status));
  };

  const remainingAuthenticationsCount =
    authSliderState.productsBeingAuthenticated.filter(s =>
      actionRequiredStatus.includes(s[keys.status])
    ).length;

  const handleSlideChange = step => () => dispatch({ type: step });

  const close = () => {
    refetch();
    setMoreActivityClicked(false);
    setTimeout(() => dispatch({ type: 'next' }), 250);
    dismissAuthenticationGalleryWhenDone();
    dismiss();
  };

  const tabs = [
    {
      label: 'Required',
      value: 'required',
      count: filterDataByTab(data, 'required').length,
    },
    {
      label: 'Completed',
      value: 'completed',
      count: filterDataByTab(data, 'completed').length,
    },
  ];

  return (
    <AuthenticationLayout
      hasDoneFirstLoad={hasDoneFirstLoad}
      productListTabsProps={{
        tabs,
        activeTab,
        setActiveTab,
      }}
      productListContentProps={{
        headers,
        data: filterDataByTab(data, activeTab),
        grid,
        isLoading: productsIsLoading,
        isError: productsIsError,
        error: productsError,
        rowAction: activeTab === 'completed' ? showProductSummary : rowAction,
        tableStatusProps: {
          fullPage: false,
          noData: filterDataByTab(data, activeTab).length === 0,
          noDataDisplay: noResultsState.noSearchResults,
        },
        tableComponentsProps: {},
        selectedRows: getRowsThatNeedHighlighting(data, activeTab),
      }}
      authenticationAchievementProps={{
        data: authenticationPerformance,
        isLoading: authenticationPerformanceIsLoading,
        isError: authenticationPerformanceIsError,
      }}
      recentActivityProps={{
        activity,
        handleNotificationClick,
        activityRef,
        isLoading: activityIsLoading,
        isError: activityIsError,
        showClickable: true,
        moreActivityClicked,
        loadMoreActivity: firstActivityLoad,
        show: hasActivityViewPermission,
      }}
      authenticationGalleryProps={{
        actions,
        dismissAuthentication,
        handleSlideChange,
        authSliderState,
        showGallery,
        remainingAuthenticationsCount,
        isLoading: productStatusUpdateIsLoading,
        relatedProductsProps: {
          headers: relatedProductsHeaders,
          grid: relatedProductsGrid,
          rowAction: Utils.emptyFunction,
          parcelsIsLoading,
          parcelsIsError,
          productDetailsIsLoading,
          productDetailsIsError,
          loadProductDetails,
          onTicketClick,
          hasSupportViewPermission,
          hasProductViewPermission,
          hasCaseViewPermission,
        },
      }}
    />
  );
};

export default Authentication;
