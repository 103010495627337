import { Utils } from '../../../constants';
import { FlexContainer } from './Flex.style';

export const Flex = ({
  column,
  relative,
  justify,
  align,
  gap,
  scroll,
  hideScrollbar,
  children,
  className,
  margin,
  padding,
  wrap__,
  onClick,
  isClickable,
  ref__,
  w,
  h,
  w0,
  h0,
  height,
  width,
  grow,
  center,
  overflow,
  ...props
}) => {
  return (
    <FlexContainer
      column={column}
      relative={relative}
      justify={justify}
      align={align}
      gap={gap}
      scroll={scroll}
      hideScrollbar={hideScrollbar}
      className={className}
      margin={margin}
      padding={padding}
      wrap__={wrap__}
      onClick={onClick}
      isClickable={
        isClickable || (!!onClick && onClick !== Utils.emptyFunction)
      }
      ref={ref__}
      w={w}
      h={h}
      w0={w0}
      h0={h0}
      height={height}
      width={width}
      grow={grow}
      center={center}
      overflow={overflow}
      maxW
      {...props}
    >
      {children}
    </FlexContainer>
  );
};
