import {
  Counterfeits,
  DangerousGoods,
  DepotArea,
  DetectionDetails,
  LQ,
  Overview,
  ParcelCount,
  ProcessingTime,
  RequestsSeconds,
  Return,
  ShippingLabels,
} from './organisms/organisms';
import { AnalyticsContainer } from './style';

const AnalyticsLayout = ({
  detectionDetailsEnabled,
  overviewEnabled,
  parcelCountEnabled,
  requestsSecondsEnabled,
  processingTimeEnabled,
  counterfeitsEnabled,
  returnsEnabled,
  lqEnabled,
  depotEnabled,
  hostName,
  shippingLabelsEnabled,
  dangerousGoodsEnabled,
  isLoading,
  isError,
  data,
  disabledData,
}) => {
  return (
    <AnalyticsContainer>
      <Overview
        data={(overviewEnabled ? data : disabledData).overview}
        disabled={!overviewEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <DetectionDetails
        data={(detectionDetailsEnabled ? data : disabledData).detectionDetails}
        disabled={!detectionDetailsEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <ParcelCount
        data={(parcelCountEnabled ? data : disabledData).parcelCount}
        disabled={!parcelCountEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <RequestsSeconds
        data={(requestsSecondsEnabled ? data : disabledData).requestsSeconds}
        disabled={!requestsSecondsEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <ProcessingTime
        data={(processingTimeEnabled ? data : disabledData).processingTime}
        disabled={!processingTimeEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <Counterfeits
        data={(counterfeitsEnabled ? data : disabledData).counterfeits}
        disabled={!counterfeitsEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <Return
        data={(returnsEnabled ? data : disabledData).returns}
        disabled={!returnsEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <LQ
        data={(lqEnabled ? data : disabledData).lq}
        disabled={!lqEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <DepotArea
        data={(depotEnabled ? data : disabledData).depot}
        disabled={!depotEnabled}
        isLoading={isLoading}
        isError={isError}
      />
      <ShippingLabels
        data={(shippingLabelsEnabled ? data : disabledData).shippingLabels}
        disabled={!shippingLabelsEnabled}
        hostName={hostName}
        isLoading={isLoading}
        isError={isError}
      />
      <DangerousGoods
        data={(dangerousGoodsEnabled ? data : disabledData).dangerousGoods}
        disabled={!dangerousGoodsEnabled}
        isLoading={isLoading}
        isError={isError}
      />
    </AnalyticsContainer>
  );
};
export default AnalyticsLayout;
