import { Form, Formik } from 'formik';
import { Text } from '../../../../../../components/atoms';
import { AddIcon } from '../../../../../../components/atoms/AddIcon/AddIcon';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { InPlaceFormContent } from '../../../../../../components/organisms/InPlaceForm/InPlaceForm';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  RowAction,
  RowLabel,
  RowSimpleText,
  Space,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, FontKeys } from '../../../../../../components/style';
import { Alert } from '../../../../../../components/templates/Alert/Alert';
import { AlertCSS } from '../../../../../brand/features/reports/components/style';
import { CasesListSmallCSS } from '../../../../../brand/features_public/products/components/style';
import { ProductText } from '../../../products/components/atoms';
import { NoResultRowCSS } from '../../../products/components/style';
import {
  barcodeField,
  parcelKeys,
  referenceIndexKeys,
} from '../../controllers/variables';
import { AddBarcodeFormCSS, DetailCSS } from '../style';

export const Detail = ({ title, text }) => {
  return (
    <DetailCSS w gap="_3XS">
      <Flex>
        <Text
          text={title}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Regular}
          whiteSpace={'nowrap'}
        />
      </Flex>
      <div>
        <Text
          text={text}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Semibold}
        />
      </div>
    </DetailCSS>
  );
};

export const Alerts = ({ alerts }) => {
  return (
    <Flex scroll column gap="_2XS">
      {(alerts ?? []).map(text => (
        <AlertCSS padding="_2XS">
          <Alert text={text} size="_S" padding="_2XS" />
        </AlertCSS>
      ))}
    </Flex>
  );
};

export const barcodesTableComponents = ({ removeBarcode }) => ({
  [parcelKeys.barcode]: { display: RowSimpleText(parcelKeys.barcode, 'bold') },
  [parcelKeys.action]: {
    display: RowAction({ action: removeBarcode, icon: Icons.Trash }),
  },
});

export const AddBarcode = ({
  toggleAddBarcode,
  isAddingBarcode,
  addBarcode,
  formProps,
}) => {
  const handleSubmit = (values, actions) => {
    addBarcode(values);
    actions.resetForm();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ [barcodeField]: '' }}>
      {({ values }) => {
        return (
          <Form>
            <Flex relative className="add-barcodes-icon">
              <AddIcon active={isAddingBarcode} onClick={toggleAddBarcode} />
              {isAddingBarcode && (
                <AddBarcodeFormCSS>
                  <InPlaceFormContent
                    {...formProps}
                    isValid={values?.[barcodeField].trim().length}
                  />
                </AddBarcodeFormCSS>
              )}
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export const ReferenceSearchResults = ({
  showResults,
  headers,
  results,
  components,
  grid,
  rowAction,
  scrollRef,
  rowHoverIn,
  rowHoverOut,
}) => {
  if (!showResults) return <></>;
  return (
    <CasesListSmallCSS search>
      {results?.length ? (
        <Table
          headers={headers}
          data={results}
          components={components}
          grid={grid}
          rowAction={rowAction}
          scrollRef={scrollRef}
          rowHoverIn={rowHoverIn}
          rowHoverOut={rowHoverOut}
        />
      ) : (
        <NoResultRow />
      )}
    </CasesListSmallCSS>
  );
};

const NoResultRow = () => {
  return (
    <NoResultRowCSS>
      <ProductText text={'No results'} />
    </NoResultRowCSS>
  );
};

export const searchComponents = {
  [referenceIndexKeys.leftPadding]: { display: Space },
  [referenceIndexKeys.name]: {
    display: RowSimpleText(
      [
        referenceIndexKeys.name,
        referenceIndexKeys.company,
        referenceIndexKeys.address,
      ],
      'bold'
    ),
  },
  [referenceIndexKeys.country]: {
    display: RowSimpleText(referenceIndexKeys.country, 'bold'),
  },
  [referenceIndexKeys.city]: {
    display: RowSimpleText(referenceIndexKeys.city, 'bold'),
  },
};

export const filesTableComponents = ({ downloadFile }) => ({
  [parcelKeys.file.alias]: {
    display: RowLabel(parcelKeys.file.alias, {
      iconLeft: Icons.FileCode,
      color: Colors.Neutral._900,
    }),
  },
  [parcelKeys.file.url]: {
    display: RowAction({
      action: downloadFile,
      icon: Icons.Download,
      color: Colors.Neutral._900,
    }),
  },
});
