import useFetchData from '../../../../../hooks/fetchHook';
import { API } from '../../../../../lib/api';
import { useAuth } from '../../../../../providers/AuthProvider';
import { reportsKeys } from '../controllers/variables';

export const useDownloadCustomReports = () => {
  const [{ data, isLoading, isError, error }, _, doDownloadCustomReport] =
    useFetchData('reports/id/export', {
      ignore: true,
    });
  const [{ token }] = useAuth();

  const downloadCustomReport = ({ [reportsKeys.id]: id }) => {
    API.GET(`report/${id}/export`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]), {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        res.headers?.['content-disposition']?.split('"')?.[1] ||
          `Report-${Date.now()}.zip`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    downloadCustomReport,
  };
};
