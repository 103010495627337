import {
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

export const adminSortersKeys = {
  id: 'id',
  name: 'name',
  brand: {
    self: 'brand',
    name: 'brand.name',
    id: 'brand_id',
    image: 'brand.image',
    isContracted: 'brand.isContracted',
  },
  city: 'city',
  country: 'country',
  responsible: {
    self: 'responsible',
    id: 'rep_user_id',
    name: 'responsible.name',
    image: 'responsible.image',
  },
};

const keys = adminSortersKeys;

export const DC = {
  ...DISPATCH_CONSTANTS,
  SORTERS: 'SORTERS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
};

export const headers = {
  [keys.name]: 'Sorter name',
  [keys.brand.name]: 'Sorter brand',
  [keys.city]: 'Sorter city',
  [keys.country]: 'Sorter country',
  [keys.responsible.name]: 'CC-Responsible',
  options: '',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  // sorter name //
  minmax(140px, 1fr)
  // sorter brand //
  minmax(140px, 1fr)
  // sorter city //
  minmax(140px, 1fr)
  // sorter country //
  minmax(140px, 1fr)
  // cc-responsible //
  minmax(140px, 1fr)
  // options //
  48px
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const initialFilterValues = {
  [DC.SERVICE]: '',
};

export const titles = {
  [DC.SERVICE]: 'Service type',
};

export const allOptions = {
  [DC.SERVICE]: { label: 'All', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No services',
    description: 'Start by adding a new service',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all services',
  },
};
