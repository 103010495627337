import styled from '@emotion/styled';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../style';

const heightMap = {
  _M: '_S',
};

export const Container = styled(Flex)`
  border-width: 1px;
  border-style: solid;

  border-color: ${({ borderColor, background }) => borderColor ?? background};
  background: ${({ background }) => background};
  padding: 0 ${Layout.Spacing._2XS};
  width: ${({ width }) => width ?? '88px'};
  height: ${({ size }) => Layout.Spacing[heightMap[size]]};
  border-radius: ${Effects.Border_Radius._L};
`;

const dotSizes = {
  _S: '4px',
  _M: '6px',
  _L: '8px',
};

export const Dot = styled.div`
  min-width: ${({ size }) => dotSizes[size]};
  min-height: ${({ size }) => dotSizes[size]};
  max-width: ${({ size }) => dotSizes[size]};
  max-height: ${({ size }) => dotSizes[size]};
  background: ${({ color }) => color};
  border-radius: ${Effects.Border_Radius.Circle};
`;

export const presetStyles = {
  default: {
    color: Colors.Primary._600,
    borderColor: Colors.Primary._600,
    background: Colors.Sharp.White,
    size: '_M',
    dot: true,
  },
};
