import { Route, Routes } from 'react-router-dom';
    import BrandSettings from './BrandSettings';
    
    export const BrandSettingsRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<BrandSettings />} />
        </Routes>
      );
    };
    