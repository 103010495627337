import styled from '@emotion/styled';
import { Table } from '../../../../../components/organisms/Table/Table';

export const AdminReferencesTableCSS = styled(Table)`
  .table-cell {
    &._5,
    &._6,
    &._7 {
      padding-left: 20px;
    }
  }
`;
