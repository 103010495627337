import { v4 as uuidv4 } from 'uuid';
import { Constants } from '../../../constants';

export const areValidFileType = (uploads, uploadType) => {
  const filesTypesAreValid = uploads.every(file => {
    const { type } = file;

    return Constants.FileUpload.uploadTypeUtils[uploadType].validMimeTypes.some(
      valid => type === valid
    );
  });

  return filesTypesAreValid;
};

export const areValidFileSizes = (uploads, uploadType) => {
  const fileSizesAreValid = uploads.every(file => {
    const { size } = file;

    return Constants.FileUpload.uploadTypeUtils[uploadType].fileSize >= size;
  });

  return fileSizesAreValid;
};

export const createUploadName = fileName => {
  let uploadName = fileName;
  const extensionStartIndex = fileName.lastIndexOf('.');
  if (extensionStartIndex === -1) return uploadName;

  const name = fileName.slice(0, extensionStartIndex);
  const uuid = uuidv4();
  const extension = fileName.slice(extensionStartIndex);
  if (name == null || extension == null) return uploadName;

  uploadName = `${name}_${uuid}${extension}`;

  return uploadName;
};

export const extractImageName = (url = '', storageEndpoint) => {
  // TODO: firebase delete is failing on recently uploaded images (Instruction.js)
  const isLocal = url.includes(`${storageEndpoint}%2F`);
  const imageURL = url;
  if (imageURL == null || imageURL === '') return '';

  const withoutBaseURL = imageURL.split(
    isLocal ? `${storageEndpoint}%2F` : `${storageEndpoint}/`
  )[1];
  if (withoutBaseURL == null) return '';

  const withoutURLParams = withoutBaseURL.split(
    isLocal ? '?alt' : '?X-Goog'
  )[0];
  if (withoutURLParams == null) return '';

  return withoutURLParams;
};

export const buildFileSelector = (multiple = true) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  if (multiple) fileSelector.setAttribute('multiple', 'multiple');
  return fileSelector;
};

export const replaceFiles = (prevFiles, { userUpload: newFiles, index }) => {
  return [
    ...prevFiles.slice(0, index),
    ...newFiles,
    ...prevFiles.slice(index + 1),
  ];
};
