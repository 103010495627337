const { REACT_APP_SERVER: platform } = process.env;

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const SERVER = {
  fetch: (endpoint, config) => {
    config.headers = { ...defaultHeaders, ...config.headers };
    return fetch(platform + '/' + endpoint, {
      credentials: 'include',
      ...config,
    });
  },
};

const attachQueryStringsToEndpoint = (endpoint, params) => {
  if (params) {
    const final_params = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v != null)
    );
    const param_keys = Object.keys(final_params);
    if (param_keys.length > 0) {
      endpoint += '?';
      param_keys.forEach((key, index) => {
        if (final_params[key] != null) {
          if (index > 0) endpoint += '&';
          if (Array.isArray(final_params[key])) {
            final_params[key].forEach((value, i) => {
              if (i > 0) endpoint += '&';
              endpoint += `${key}=${value}`;
            });
          } else {
            endpoint += `${key}=${final_params[key]}`;
          }
        }
      });
    }
  }
  return endpoint;
};

export const API = {
  GET: async (endpoint, config = {}) => {
    config.method = 'GET';
    endpoint = attachQueryStringsToEndpoint(endpoint, config.params);

    const res = await SERVER.fetch(endpoint, config);
    const headers = Object.fromEntries(res.headers.entries());
    let baseData;
    if (config.responseType === 'arraybuffer') {
      baseData = await res.arrayBuffer();
    } else {
      baseData = await res.json();
    }
    if (
      typeof baseData === 'object' &&
      (baseData.data || baseData.data == 0 || baseData.error) &&
      baseData.status
    ) {
      const { data, status, error } = baseData;
      return { data, status, error, headers };
    } else {
      return { data: baseData, headers };
    }
  },

  GETLARGE: async (endpoint, config = {}) => {
    config.method = 'POST';
    const { params, ...configRest } = config;
    configRest.body = JSON.stringify(params);

    const res = await SERVER.fetch(endpoint, configRest);
    const headers = Object.fromEntries(res.headers.entries());
    const { authorization: token } = headers;
    let baseData;
    if (config.responseType === 'arraybuffer') {
      baseData = await res.arrayBuffer();
    } else {
      baseData = await res.json();
    }
    if (
      typeof baseData === 'object' &&
      (baseData.data || baseData.data == 0 || baseData.error) &&
      baseData.status
    ) {
      const { data, status, error } = baseData;
      return { data, status, error, headers, token };
    } else {
      return { data: baseData, headers, token };
    }
  },
  POST: async (endpoint, body, config = {}) => {
    config.method = 'POST';
    config.body = JSON.stringify(body);

    const res = await SERVER.fetch(endpoint, config);
    const { authorization: token } = Object.fromEntries(res.headers.entries());
    const { data, status, error } = await res.json();

    const response = { data, status, error };
    if (token) response.token = token;

    return response;
  },

  PUT: async (endpoint, body, config = {}) => {
    config.method = 'PUT';
    config.body = JSON.stringify(body);

    const res = await SERVER.fetch(endpoint, config);
    const { data, status, error } = await res.json();

    return { data, status, error };
  },

  DELETE: async (endpoint, config = {}) => {
    config.method = 'DELETE';
    endpoint = attachQueryStringsToEndpoint(endpoint, config.params);

    const res = await SERVER.fetch(endpoint, config);
    const { data, status, error } = await res.json();

    return { data, status, error };
  },
};
