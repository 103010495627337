import styled from '@emotion/styled';
import { Layout } from '../../../../../../components/style';

const leftSpacing = Layout.Spacing._M;

export const Container = styled.div`
  .popup {
    overflow: hidden;
    padding-top: ${Layout.Spacing._XS};
    max-height: unset;
    .popup-header {
      padding-left: calc(${leftSpacing} + ${Layout.Spacing._S});
    }
    .separator {
      display: none;
    }
  }
  .instruction-content {
    padding-left: ${leftSpacing};
  }
`;

export const StatusIndicator = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  width: ${Layout.Spacing._S};
  background: ${({ color }) => color};
`;

export const InstructionText = styled.div`
  max-width: 420px;
`;
