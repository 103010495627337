import { Constants } from '../../../../../constants';
import { humanReadableDate } from '../../../../../utils/timeUtils';
import { dashboardKeys } from '../variables';
import { formatOverviewCard } from './formatOverview';

export const formatSupport =
  ({ hasPermission }) =>
  data => {
    const overviewSupportStats = {
      ...formatOverviewCard({
        type: dashboardKeys.supportCount,
        hasPermission,
      })(data),
      ...formatSupportExtraInfo(data),
    };

    return { overviewSupportStats };
  };

const formatSupportExtraInfo = data => {
  const { average_resolution_time, avg_resolution_comparison } = data;

  const { from_date, to_date, comparison } = avg_resolution_comparison ?? {};
  const delta = comparison?.count?.percentage;

  const isMore = delta > 0;
  const dataType = 'average resolution time';

  const period = `${Math.abs(delta)}% ${
    isMore ? 'longer' : 'shorter'
  } ${dataType} compared to ${humanReadableDate(
    from_date
  )} through \n${humanReadableDate(to_date)}`;

  const avgResolvedTime = `Average resolution time: ${getAverageResolutionTime(
    average_resolution_time ?? {}
  )}`;

  const response = {
    avgResolvedTime,
    period,
    delta,
  };

  return { details: response };
};

const getAverageResolutionTime = ({ hours, minutes, seconds }) => {
  if (!hours && !minutes && !seconds) return Constants.fallback;
  const readable = `${hours ? `${hours}hr ` : ''}${
    minutes ? `${minutes}min ` : ''
  }${seconds && !hours ? `${seconds}sec` : ''}`;

  return readable;
};
