import usePostData from '../../../../../hooks/postHook';

export const useAddPlan = ({ isEditing }) => {
  const [{ data, body, isLoading, isError, error }, doAddPlan] = usePostData(
    ``,
    {
      type: isEditing ? 'put' : 'post',
    }
  );

  return { data, body, isLoading, isError, error, doAddPlan };
};
