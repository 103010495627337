import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { hexOpacity } from '../../../utils/styleUtils';
import { Text } from '../../atoms';
import { Colors, FontKeys, Layout } from '../../style';

const DisabledCSS = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${Layout.Spacing._S};
  background: ${Colors.Neutral._400}${hexOpacity(30)};
  ${Styles.flexCenter};
  z-index: 100;
`;

const DisabledText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      alignment="center"
    />
  );
};

export const Disabled = ({ text, ...props }) => {
  return (
    <DisabledCSS className={props.className}>
      <DisabledText text={text} />
    </DisabledCSS>
  );
};
