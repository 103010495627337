import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { AdminBrandsTable } from './organisms';

const AdminBrandsLayout = ({
  tableProps,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <TableStatus
      {...tableStatusProps}
      isLoading={isLoading}
      isError={isError}
      error={error}
      table={<AdminBrandsTable tableProps={tableProps} />}
    />
  );
};
export default AdminBrandsLayout;
