import usePostData from '../../../../../hooks/postHook';

export const useUpdateInstruction = ({ instructionId }) => {
  const [{ data, body, isLoading, isError, error }, doUpdateInstruction] =
    usePostData(`knowledge_base/${instructionId}`, { type: 'put' });

  // TODO: document expected request shape
  // const { product_id, title, content } = req.body;

  return { data, body, isLoading, isError, error, doUpdateInstruction };
};
