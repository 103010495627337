import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { AddReferenceLayout } from '../components/AddReferenceLayout';
import ReferenceEntry from './ReferenceEntry';
import ReferenceUpload from './ReferenceUpload';
import { infoDescription, options, title, views } from './variables';

const view = views.addReference;

const AddReference = ({ onCreateSuccess, onUploadSuccess }) => {
  const { dismiss, showModal } = useModal();

  const showFileUpload = () =>
    showModal(
      ReferenceUpload,
      { view: views.spreadhseet, onUploadSuccess },
      { stack: true, ignoreOverlayDismiss: true }
    );

  const showManualUpload = () =>
    showModal(
      ReferenceEntry,
      { onCreateSuccess },
      { stack: true, ignoreOverlayDismiss: true }
    );

  const showImageUpload = () =>
    showModal(
      ReferenceUpload,
      { view: views.image, onUploadSuccess },
      { stack: true, ignoreOverlayDismiss: true }
    );

  const selectOptions = [showFileUpload, showManualUpload, showImageUpload];

  const withActions = options =>
    options.map((o, i) => ({
      ...o,
      selectOption: { ...o.selectOption, onClick: selectOptions[i] },
    }));

  return (
    <AddReferenceLayout
      title={title[view]}
      infoIcon={<InfoIcon description={infoDescription[view]} />}
      onCancel={dismiss}
      options={withActions(options)}
      referenceTemplateLink={`${process.env.PUBLIC_URL}/assets/files/Counterfeit_Reference_Template.xlsx`}
    />
  );
};

export default Modal(AddReference);
