import { BrandOverview, BrandSnapshots } from './organisms';
import { AdminBrandsDetailsContainer } from './style';

export const BrandDetailsLayout = ({
  brandImage,
  startColumnProps,
  endColumnProps,
  snapshotsProps,
}) => {
  return (
    <AdminBrandsDetailsContainer gap="_XS" column>
      <BrandOverview
        brandImage={brandImage}
        startColumnProps={startColumnProps}
        endColumnProps={endColumnProps}
      />
      <BrandSnapshots snapshotsProps={snapshotsProps} {...snapshotsProps} />
    </AdminBrandsDetailsContainer>
  );
};
