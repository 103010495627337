import { Input } from '../../../../../../components/molecules';
import {
  JoinedStrings,
  RowDateHorizontal,
  RowSimpleText,
  RowSingleImage,
  SimpleStatus,
  Space,
} from '../../../../../../components/organisms/Table/Table.exports';
import { TicketConstants } from '../../../../features/support/controllers/variables';
import { Detail } from '../../../products/components/molecules';
import { productKeys } from '../../../products/controllers/variables';
import { DC } from '../../controllers/variables';
import { BadgeLabel } from '../atoms';
import {
  OptionsCSS,
  OtherTitleCSS,
  SnapshotDataCSS,
  TicketTitleInputCSS,
} from '../style';

export const SnapshotData = ({ productDetails }) => {
  return (
    <SnapshotDataCSS>
      {(productDetails ?? []).map(detail => {
        const { title, text } = detail;
        return <Detail title={title} text={text} key={detail.title} />;
      })}
    </SnapshotDataCSS>
  );
};

export const SectionOptions = ({
  options,
  selectOption,
  chosenOption,
  customOptions,
}) => {
  return (
    <OptionsCSS>
      {options.map(option => {
        const { value } = option;
        const selected = value === chosenOption;
        return (
          <BadgeLabel
            onClick={selectOption(value)}
            text={value}
            selected={selected}
            key={value}
          />
        );
      })}
      {(customOptions ?? []).map(option => option)}
    </OptionsCSS>
  );
};

export const OtherTitle = ({
  titleInputRef,
  selected,
  selectTitle,
  handleOtherChange,
  otherMaxLength,
}) => {
  // TODO: fix this import path
  const { label, value } = TicketConstants.Titles.otherTicket;

  return (
    <OtherTitleCSS onClick={selectTitle(value)} selected={selected}>
      <BadgeLabel selected={selected} text={selected ? '' : label} />
      <TicketTitleInputCSS selected={selected}>
        <Input
          name={DC.OTHER_TITLE}
          onChange={handleOtherChange}
          maxLength={otherMaxLength}
          placeholder={'Describe your problem in a few words'}
          ref={titleInputRef}
        />
      </TicketTitleInputCSS>
    </OtherTitleCSS>
  );
};

export const productListTableComponents = {
  [productKeys.leftPadding]: { display: Space },
  [productKeys.productImage]: {
    display: RowSingleImage(productKeys.photos)('_XS'),
  },
  [productKeys.productAlias]: {
    display: RowSimpleText(productKeys.productAlias, 'bold', true, {
      copyable: true,
    }),
  },
  [productKeys.productSideloadDate]: {
    display: RowDateHorizontal(productKeys.productSideloadDate),
  },
  [productKeys.sorter]: {
    display: JoinedStrings(productKeys.sorterName, productKeys.sorterCity),
  },
  [productKeys.productQuantity]: {
    display: RowSimpleText(productKeys.productQuantity, 'bold'),
  },
  [productKeys.productStatus]: {
    display: SimpleStatus(productKeys.productStatus, '_S'),
  },
};
