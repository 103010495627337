import { Styles } from '../../../constants';
import { Colors, Effects, FontKeys, Layout, StatusColors } from '../../style';
import { BadgeType } from './Badge';
import {
  containerHeight,
  iconSize,
  statusWidth,
  ticketStyle,
  ticketWidth,
} from './variables';
export const styles = ({ type, text, status, size, icon }) => {
  if (!type || (type === BadgeType.status && !size))
    throw Error('Missing type or size property');

  // type-depdendent properties
  let background, color, borderColor, width;
  if (type === BadgeType.status) {
    background = StatusColors[status] ?? StatusColors.Gray;
    color = Colors.Sharp.White;
    borderColor = background;
    width = statusWidth[size];
  }

  if (type === BadgeType.ticket) {
    background = ticketStyle[text]?.background;
    color = ticketStyle[text]?.color;
    borderColor = ticketStyle[text]?.borderColor;
    width = ticketWidth[size];
  }

  return {
    container: {
      background,
      border: `1px solid ${borderColor}`,
      borderRadius: Effects.Border_Radius._2XL,
      ...Styles.flex,
      justifyContent: 'center',
      gap: Layout.Spacing._2XS,
      height: containerHeight[size],
      width,
    },
    text: {
      color,
      ...textSizes(size),
    },
    icon: {
      color,
      size: iconSize[size],
      name: icon,
    },
  };
};

const textSizes = size => {
  switch (size) {
    case '_L':
    case '_M':
    case '_S':
      return {
        variant: FontKeys.Variant.Paragraph,
        size: FontKeys.Size[size],
        weight: FontKeys.Weight.Semibold,
      };
    case '_XS': {
      return {
        variant: FontKeys.Variant.Caption,
        size: FontKeys.Size._M,
        weight: FontKeys.Weight.Semibold,
      };
    }
    default:
      throw Error('Incorrect size property');
  }
};
