import usePostData from '../../../../../hooks/postHook';
import { usersKeys } from '../controllers/variables';

export const useUpdateUserName = ({ id }) => {
  const [{ body, isLoading, isError, error }, updateUserName] = usePostData(
    `client/${id}/username`
  );

  const doUpdateUserName = values => {
    const body = {
      first_name: values[usersKeys.firstName],
      last_name: values[usersKeys.lastName],
    };
    updateUserName(body);
  };

  return { body, isLoading, isError, error, doUpdateUserName };
};
