import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { parcelKeys, referenceIndexKeys } from '../controllers/variables';

export const useGetParcelDetails = ({ id }) => {
  const [
    { data, isLoading = true, isError, error },
    refreshParcelDetails,
    getParcelDetails_,
  ] = useFetchData(`office_parcel/${id}`, {
    formatter,
    ignore: id == null,
  });

  const getParcelDetails = id => {
    const url = `office_parcel/${id}`;
    getParcelDetails_({ url });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    refreshParcelDetails: getParcelDetails_,
    getParcelDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data[parcelKeys.statusShares] = formatStatusShares(
    data[parcelKeys.statusShares]
  );

  return data;
};

const mask = {
  brName: parcelKeys.reason,
  case_count: parcelKeys.caseCount,
  paAlias: parcelKeys.alias,
  paID: parcelKeys.id,
  paRiskType: parcelKeys.riskType,
  paSideloadDate: parcelKeys.sideloadDate,
  paStatus: parcelKeys.status,
  paVolume: parcelKeys.parcelVolume,
  paWeight: parcelKeys.parcelWeight,
  paTracking: parcelKeys.trackingNumber,
  barcodes: parcelKeys.barcodes,
  soCountry: parcelKeys.sorterCountry,
  soCity: parcelKeys.sorterCity,
  last_activity: parcelKeys.lastActivity,
  soName: parcelKeys.sorter,
  status_ratios: parcelKeys.statusShares,
  //
  // Files
  //
  files: parcelKeys.file.self,
  filename: parcelKeys.file.alias,
  url: parcelKeys.file.url,
  //
  // Reference Keys
  //
  pacType: referenceIndexKeys.type,
  seID: referenceIndexKeys.id,
  seName: referenceIndexKeys.name,
  seCompany: referenceIndexKeys.company,
  seAddress: referenceIndexKeys.address,
  sePhone: referenceIndexKeys.phone,
  sePostcode: referenceIndexKeys.zipcode,
  seCity: referenceIndexKeys.city,
  seState: referenceIndexKeys.state,
  seCountry: referenceIndexKeys.country,
};
