import { matchImagesToRows } from '../../../../../components/organisms/Table/utils';
import { caseKeys } from '../../../../admin/features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const matchImagesToProduct = matchImagesToRows(
  productKeys.productId,
  'case_id'
);

export const matchImagesToCases = matchImagesToRows([caseKeys.id], 'parcel_id');
