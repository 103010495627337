import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../components/templates/Popup';
import { Fields, Permissions } from './organisms';
import { AddContent } from './style';

const AddServiceLayout = ({
  isEditing,
  fieldProps,
  permissionGridProps,
  actionsProps,
  dismiss,
}) => {
  return (
    <Popup
      size={'_M'}
      title={isEditing ? 'Edit service' : 'Add service'}
      element={<AnimatedIcon name={Icons.X} onClick={dismiss} />}
    >
      <AddContent>
        <Flex scroll grow column>
          <div
            style={{
              dispay: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 8px)',
            }}
          >
            <Spacer height={'_M'} />
            <Fields {...fieldProps} />
            <Spacer height={'_S'} />
            <Permissions permissionGridProps={permissionGridProps} />
          </div>
        </Flex>
        <Actions {...actionsProps} />
      </AddContent>
    </Popup>
  );
};
export default AddServiceLayout;
