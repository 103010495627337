import styled from '@emotion/styled';
import { Layout } from '../../style';

const containerPadding = 24;

export const AddNoteCSS = styled.div`
  position: absolute;
  bottom: ${containerPadding}px;
  left: ${containerPadding}px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 86px;
  max-height: 86px;
  height: 86px;
  background: white;
  display: flex;
  width: calc(100% - ${containerPadding * 2}px);
  .input > div {
    width: calc(100% - ${containerPadding}px) !important;
    *::-webkit-scrollbar {
      display: none;
    }
  }
  .animated-icon {
    position: absolute;
    top: ${Layout.Spacing._XS};
    right: ${Layout.Spacing._XS};
  }
`;
