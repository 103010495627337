import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Layout } from '../../style';

export const Container = styled.div`
  margin-left: auto;
  justify-self: flex-end;
  margin-top: auto;
  gap: ${props => Layout.Spacing[props.gap]};
  ${props => props.fullWidth && 'width: 100%;'}
  ${Styles.flex};
`;

export const PlaceLeft = styled.div`
  margin-right: auto;
`;
