import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { Popup } from '../../../../../components/templates/Popup';
import { Utils } from '../../../../../constants';
import { UploadOptions } from './organisms';
import { AddReferenceContent, BottomRow, DownloadTag } from './style';

export const AddReferenceLayout = ({
  title,
  infoIcon,
  onCancel,
  referenceTemplateLink,
  options,
}) => {
  return (
    <Popup size="_M" title={title} element={infoIcon}>
      <AddReferenceContent>
        <div />
        <UploadOptions options={options} />
        <BottomRow>
          <DownloadTag href={referenceTemplateLink} download>
            <Button
              variant="Tertiary"
              text="Download CSV Template"
              size="_M"
              rightIcon={Icons.Download}
              onClick={Utils.emptyFunction}
            />
          </DownloadTag>
          <Button
            onClick={onCancel}
            variant="Secondary"
            text={'Cancel'}
            size="_M"
          />
        </BottomRow>
      </AddReferenceContent>
    </Popup>
  );
};
