import { ModalSectionTitle } from '../../../../../../components/templates/StepPopup/style';
import { AddLayout } from './AddLayout';
import { Fields } from './organisms';

const sectionTitleAdd = 'Select a category and add a new subcategory';
const sectionTitleEdit = 'Add the requested information to edit a subcategory';

export const AddSubcategoryLayout = ({
  isEditing,
  fieldProps,
  actionsProps,
}) => {
  return (
    <AddLayout
      title={isEditing ? 'Edit subcategory' : 'Add category - New subcategory'}
      actionsProps={actionsProps}
    >
      <ModalSectionTitle
        text={isEditing ? sectionTitleEdit : sectionTitleAdd}
      />
      <Fields {...fieldProps} />
    </AddLayout>
  );
};
