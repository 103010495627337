// locatino, catch date, own/total items, products

import { Constants } from '../../../../../constants';
import { joinStrings } from '../../../../../utils/stringUtils';
import {
  DateTypes,
  formatDate,
  howLongAgo,
} from '../../../../../utils/timeUtils';
import { caseKeys } from '../controllers/variables';

export const formatCaseDetails = ({
  [caseKeys.sorter]: sorter,
  [caseKeys.city]: city,
  [caseKeys.sideloadDate]: catchDate,
  [caseKeys.ownQuantity]: ownItems,
  [caseKeys.totalQuantity]: totalItems,
  [caseKeys.productQuantity]: quantity,
  [caseKeys.riskType]: riskType,
  [caseKeys.lastActivity]: lastActivity_,
}) => {
  const location = joinStrings(sorter, city, ' - ') ?? Constants.fallback;

  const calendarDate =
    formatDate(catchDate, DateTypes.ddmmyyyy) ?? Constants.fallback;
  // const time = formatDate(catchDate, DateTypes.time12);
  const date = joinStrings(calendarDate, null, ' - ');

  const itemCount =
    joinStrings(ownItems, totalItems, ' / ') ?? Constants.fallback;

  const lastActivity = howLongAgo(lastActivity_);

  return [
    {
      title: 'Sideload date: ',
      text: date,
    },
    {
      title: 'Location: ',
      text: location,
    },
    {
      title: 'Products: ',
      text: quantity,
    },
    {
      title: 'Own / total items: ',
      text: itemCount,
    },
    {
      title: 'Risk type: ',
      text: riskType,
    },
    {
      title: 'Last activity: ',
      text: lastActivity,
    },
  ];
};
