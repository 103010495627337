import { useState } from 'react';
import {} from '../../../utils/stringUtils';
import { Text } from '../../atoms';
import Icon from '../../atoms/Icon/Icon';
import { FontKeys, Size } from '../../style';
import {
  Container,
  ContentPadding,
  Description,
  DescriptionContent,
  styles,
} from './InfoIcon.style';

export default function InfoIcon({ description, size = '_L' }) {
  const [hover, setHover] = useState(false);
  const handleHover = to => () => setHover(to);

  if (!description) return;
  return (
    <Container
      className="information-icon"
      onPointerOver={handleHover(true)}
      onPointerLeave={handleHover(false)}
    >
      <Icon {...styles.icon(hover)} size={Size.Icon[size]} />
      <Description>
        <DescriptionContent className="description-content">
          <ContentPadding>
            <Text
              text={description}
              variant={FontKeys.Variant.Paragraph}
              size={FontKeys.Size._S}
              weight={FontKeys.Weight.Regular}
            />
          </ContentPadding>
        </DescriptionContent>
      </Description>
    </Container>
  );
}
