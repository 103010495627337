import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Colors, Effects, FontKeys, Layout } from '../../style';

const sizeStyles = {
  _M: {
    height: Layout.Spacing._M,
    padding: Layout.Spacing._XS,
  },
  _S: {
    height: Layout.Spacing._S,
    padding: Layout.Spacing._2XS,
  },
};

export const Container = styled.div`
  background-color: ${({ color }) => color};
  border-radius: ${Effects.Border_Radius._2XS};
  ${({ size }) => sizeStyles[size]};
  ${Styles.flexCenter};
`;

export const TypeText = ({ text, size, color }) => {
  return (
    <Text
      text={transformTypeText(text)}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
      color={color}
    />
  );
};

export const ticketBadgeTypes = {
  General: 'General',
  Parcel: 'Parcel',
  Product: 'Product',
};

export const colors = type => {
  switch (type) {
    case ticketBadgeTypes.Parcel:
      return { background: Colors.Violet._100, text: Colors.Violet._900 };
    case ticketBadgeTypes.Product:
      return { background: Colors.Warning._100, text: Colors.Orange._700 };
    default:
      return { background: Colors.Primary._100, text: Colors.Primary._600 };
  }
};

const transformTypeText = type => {
  switch (type) {
    case ticketBadgeTypes.Parcel:
    case ticketBadgeTypes.Product:
      return type;
    default:
      return ticketBadgeTypes.General;
  }
};
