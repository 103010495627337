import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { OptionFrame } from '../../../../../components/organisms/OptionFrame/OptionFrame';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';

const {
  SELF,
  CREATE_GENERAL_SUPPORT_TICKETS,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

export const TicketTypeSelectionLayout = ({
  onCancel,
  generalSupport,
  parcelSupport,
  productSupport,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <StepPopup
      title={'New support ticket'}
      sectionTitle={'How can we help you?'}
      hidePrimary
      onCancel={onCancel}
    >
      <Flex gap="_S" h w>
        {hasPermission(CREATE_GENERAL_SUPPORT_TICKETS) && (
          <OptionFrame {...generalSupport} />
        )}
        {hasPermission(CREATE_PARCEL_SUPPORT_TICKETS) && (
          <OptionFrame {...parcelSupport} />
        )}
        {hasPermission(CREATE_PRODUCT_SUPPORT_TICKETS) && (
          <OptionFrame {...productSupport} />
        )}
      </Flex>
    </StepPopup>
  );
};
