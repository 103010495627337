import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Colors, FontKeys, Layout } from '../../style';

export const Container = styled.div`
  height: 72px;
  width: 100%;
  position: relative;
  grid-column: 1 / 13;
  ${Styles.flexBetweenCenter}
`;

export const Title = styled.div`
  gap: ${Layout.Spacing._XS};
  ${Styles.flex};
`;

export const TitleText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
      className="popup-title"
    />
  );
};
