import usePostData from '../../../../../hooks/postHook';

export const useAddCharacteristicItem = (instructionId, characteristicId) => {
  const [{ data, body, isLoading, isError, error }, doAddCharacteristicItem] =
    usePostData(
      `office_knowledge_base/${instructionId}/characteristic/${characteristicId}/items`
    );

  //    const { type, image, detail } = req.body;
  return { data, body, isLoading, isError, error, doAddCharacteristicItem };
};
