import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionConsts } from '../../../../../providers/PermissionProvider/variables';
import Help from '../../../features/support/controllers/Help';
import CreateTicket from '../../../features_public/support/controllers/CreateTicket';
import TicketTypeSelection from '../../../features_public/support/controllers/TicketTypeSelection';
import { ticketTypes } from '../../../features_public/support/controllers/variables';

const {
  SELF,
  CREATE_GENERAL_SUPPORT_TICKETS,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const ContactSupportButton = ({
  productData,
  productTitle,
  goBack,
  disabled,
  type,
  newTab,
}) => {
  const { showModal } = useModal();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const showNewTicket = () => {
    if (type == null)
      showModal(TicketTypeSelection, { newTab }, { stack: true });
    if (type === ticketTypes.General)
      showModal(Help, { onBack: goBack }, { stack: true });
    if (type === ticketTypes.Product || type === ticketTypes.Parcel)
      showModal(
        CreateTicket,
        { productData, productTitle, goBack, type, newTab },
        { stack: true }
      );
  };
  const supportButton = {
    text: 'Contact support',
    onClick: showNewTicket,
    variant: 'Primary',
    size: '_S',
    rightIcon: Icons.ChevronRight,
  };

  if (
    !(
      hasPermission(CREATE_GENERAL_SUPPORT_TICKETS) ||
      hasPermission(CREATE_PARCEL_SUPPORT_TICKETS) ||
      hasPermission(CREATE_PRODUCT_SUPPORT_TICKETS)
    ) ||
    !hasPermission(PermissionConsts.Show)
  )
    return;

  return <Button {...supportButton} disabled={disabled} />;
};

export default ContactSupportButton;
