import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects } from '../../style';

const buttonHeight = '16px';

const circle = {
  height: buttonHeight,
  width: buttonHeight,
  background: Colors.Sharp.White,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderTop: `1px solid ${Colors.Neutral._200}`,
  borderBottom: `1px solid ${Colors.Neutral._200}`,
};

const constantStyle = {
  zIndex: 1,
  boxShadow: Effects.Box_Shadow._S,
  background: Colors.Sharp.White,
  height: buttonHeight,
  ...Styles.flexCenter,
  borderRadius: buttonHeight,
  leftCircle: {
    ...circle,
    borderRadius: ` ${buttonHeight} 0 0 ${buttonHeight}`,
    borderLeft: `1px solid ${Colors.Neutral._200}`,
  },
  rightCircle: {
    ...circle,
    borderRadius: `0 ${buttonHeight} ${buttonHeight} 0`,
    borderRight: `1px solid ${Colors.Neutral._200}`,
  },
  rectangle: {
    background: Colors.Sharp.White,
    flexGrow: 1,
  },
  arrow: {
    position: 'absolute',
    width: buttonHeight,
    height: buttonHeight,
    ...Styles.flexCenter,
    zIndex: 1,
  },
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: ({ hover }, { expanded }) => {
    const collapsed = !expanded;
    return {
      width: buttonHeight,
      leftCircle: {
        marginLeft: hover && expanded ? '-12px' : '',
      },
      rightCircle: {
        marginRight: hover && collapsed ? '-12px' : '',
      },
      arrow: {
        transform: `rotate(${expanded ? 180 : 0}deg)`,
        right: collapsed ? (hover ? '-10px' : 'unset') : '',
        left: expanded ? (hover ? '-11px' : '1px') : '',
      },
    };
  },
};

export const HoverArea = styled.div`
  padding: 2px;
  transform: translate(-2px, 2px);
  cursor: pointer;
  ${Styles.flexCenter};
`;
