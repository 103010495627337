import styled from '@emotion/styled';
import {
  Effects,
  FontKeys,
  Font_Properties,
  Layout,
} from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';

export const DashboardContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: 168px 320px 156px 296px;
  .calendar {
    min-width: 268px !important;
    z-index: 200 !important;
  }
`;

export const AnimationCSS = styled.div`
  width: 100%;
  height: 100%;
  ${Styles.flexColumnCenter};
`;

export const DashboardCard = styled.div`
  height: 100%;
  position: relative;
  border-radius: ${Effects.Border_Radius._S};
  .card {
    height: 100%;
    overflow: hidden;
    filter: ${({ disabled }) => (disabled ? 'blur(1px)' : '')};
  }
`;

export const OverviewCSS = styled(DashboardCard)`
  grid-row: 1;
  grid-column: 1 / 7;
`;

export const AuthenticationCSS = styled(DashboardCard)`
  grid-row: 1;
  grid-column: 7 / 13;
`;

export const PopularsCSS = styled(DashboardCard)`
  grid-row: 2;
  grid-column: 1 / 5;
`;

export const TimelineCSS = styled(DashboardCard)`
  grid-row: 2;
  grid-column: 5 / 13;
`;

export const ParcelStatusesCSS = styled(DashboardCard)`
  grid-row: 3;
  grid-column: 1 / 13;
  gap: ${Layout.Spacing._XS};
  ${Styles.flexBetween};
`;

export const ParcelStatusCSS = styled(DashboardCard)`
  width: 0;
  flex-grow: 1;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  .card {
    padding: ${Layout.Spacing._XS};
    padding-left: ${Layout.Spacing._S};
    gap: ${Layout.Spacing._XS};
  }
`;

export const OriginCSS = styled(DashboardCard)`
  grid-row: 4;
  grid-column: 1 / 5;
  .piechart-flex {
    margin-top: ${Layout.Spacing._XS};
  }
  .legend-value {
    ${props => (props.inactiveBrand ? 'display: none;' : '')}
  }
`;

export const CategoriesCSS = styled(DashboardCard)`
  grid-row: 4;
  grid-column: 5 / 9;
`;

export const ActivityCSS = styled(DashboardCard)`
  grid-row: 4;
  grid-column: 9 / 13;
`;

export const ProgressBarsCSS = styled.div`
  gap: ${Layout.Spacing._XS};
  ${Styles.flexColumn};
`;

const ClickableLabel = styled.div`
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
  ${props =>
    props.isClickable &&
    `&:hover {
    .dashboard-label {
      font-weight: ${
        Font_Properties.Weight[FontKeys.Weight.Semibold]
      } !important;
    }
    .dashboard-click-icon {
      display: block;
    }
  }`}
`;

export const ProgressItemCSS = styled(ClickableLabel)`
  gap: ${Layout.Spacing._S};
  ${Styles.flex};
`;

export const DashboardClickIconCSS = styled.div`
  display: none;
`;

export const CategoryLabelCSS = styled.div`
  width: 112px;
  max-width: 112px;
  min-width: 112px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ActivitLogCSS = styled(ClickableLabel)`
  width: 100%;
  position: relative;
  gap: ${Layout.Spacing._S};
  ${Styles.flex};
  .dashboard-click-icon {
    margin-left: auto;
  }
`;

export const WelcomeActionsCSS = styled.div`
  align-self: flex-end;
`;
