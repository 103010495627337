import { useReducer } from 'react';
import { customTableReducer } from '../../../../../hooks/tableHook';
import { DC } from './variables';

export const useAdminServicesReducer = ({
  initialSearchParams,
  resetSearchParams,
}) => {
  const [searchParams, dispatch] = useReducer(
    customTableReducer({ resetSearchParams, customReducer }),
    initialSearchParams
  );
  return [searchParams, dispatch];
};

const customReducer = (state, { type, payload }) => {
  switch (type) {
    case DC.SERVICE:
      return {
        ...state,
        [type]: [...payload],
        [DC.FILTERS_SET]: true,
      };
    default:
      return state;
  }
};
