import { Constants } from '../../../constants';
import { magnitudeFormatter } from '../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../utils/timeUtils';
import {
  calculateThird,
  ignoreNaNs,
  numWithCommas,
} from '../../../utils/utils';

export const countThird = (data = []) => {
  let max = 0;

  data.forEach(entry => {
    max = Math.max(max, ...ignoreNaNs(entry));
  });

  const third = calculateThird(max);

  return third;
};

export const percentageTickFormatter = tick => {
  if (!tick) return tick;
  return tick + '%';
};

export const countFormatter = (domainMax, decimals) => number =>
  magnitudeFormatter(number, domainMax, decimals);

export const calculateMargin = (third, tickFormatter) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = '14px Source Sans Pro';
  const widestTick = tickFormatter(third * 4);
  const width = ctx.measureText(widestTick).width;

  return width;
};

export const countTooltipFormatter = value => {
  if (isNaN(parseFloat(value))) return ' - ';

  return numWithCommas(parseFloat(value));
};

export const leftMargin = ({ third, tickFormatter }) => {
  const ticksMultiplier = [0, 1, 2, 3, 4];
  const length = Math.max(
    ...ticksMultiplier.map(
      fraction => String(tickFormatter(third * fraction)).length
    )
  );

  if (length == 2) return -35;
  if (length == 1) return -40;
  // pulls left less if the number is longer
  return -100 / (length * 1.1);
};

export const lineChartDateformat = date =>
  formatDate(date, DateTypes.short) ?? formatTime(date) ?? Constants.fallback;

const formatTime = time => {
  try {
    let [hour, min] = time.split(':');
    if (hour == null || min == null) throw Error;

    hour = parseInt(hour);
    const isPm = hour > 11;
    return `${formatHourString(hour, isPm)}:${min} ${isPm ? 'pm' : 'am'}`;
  } catch {
    return null;
  }
};

const formatHourString = (hour, isPm) => {
  if (hour % 12 === 0) return 12;
  return isPm ? hour - 12 : hour;
};
