import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Constants, PropKeys, Prose } from '../../../../../constants';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import ContactSupportButton from '../../../components/molecules/ContactSupportButton/ContactSupportButton';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import CreateTicket from '../../../features_public/support/controllers/CreateTicket';
import { ticketTypes } from '../../../features_public/support/controllers/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { useGetCaseDetails } from '../api/getCaseDetails';
import { useGetProductDetails } from '../api/getProductDetails';
import { ProductDetailsLayout } from '../components/pages/ProductDetailsLayout';
import { statusStepsText, statusStepsTextNonOwner } from '../variables';
import { actions } from './variables';

const { Status } = Constants;

const { SELF: SUPPORT, CREATE_PRODUCT_SUPPORT_TICKETS } =
  PermissionKeys.Brand.SUPPORT;

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Brand.PARCELS;

const ProductDetails = () => {
  const [notes, setNotes] = useState([]);

  const { productId } = useParams();
  const navigate = useNavigate();
  const { store } = useStore();
  const { showSnackbarError } = useSnackbar();
  const { showModal } = useModal();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission } = usePermissions();

  const hasTicketCreatePermission = hasPermission(
    SUPPORT,
    CREATE_PRODUCT_SUPPORT_TICKETS
  );
  const hasParcelViewPermission = hasPermission(PARCELS, VIEW_PARCELS);

  const hasCaseViewPermission = hasParcelViewPermission;

  const isAllBrands = !store[PropKeys.brandId];

  const ticketsRef = useRef([]);
  const dataRef = useRef({});

  const goToAuthentication = () => {
    const to = `${NavURIs[Pages.authentication]}`;
    navigate(to);
  };

  const actionFunctions = {
    [Status.Authentication]: {
      [actions.authentication]: goToAuthentication,
    },
  };

  const {
    data: caseData,
    isLoading: caseIsLoading,
    isError: caseIError,
    getCaseDetails,
  } = useGetCaseDetails({
    ignore: !hasCaseViewPermission,
  });

  const { data, isLoading, isError, refetchData } = useGetProductDetails({
    productId,
    actionFunctions,
    hasTicketCreatePermission,
  });

  useEffect(() => {
    if (!data[productKeys.productAlias]) return;
    const alias = data[productKeys.productAlias];
    const header = `Product ${alias}`;

    const headerChildren = (
      <Flex justify="between" align="center" w>
        <ViewTitle
          text={<CopyableText text={header} copyText={alias} />}
          backPage={NavURIs[Pages.products]}
        />
        <ContactSupportButton
          productData={data}
          disabled={isLoading}
          productTitle={data[productKeys.productAlias]}
          type={ticketTypes.Product}
        />
      </Flex>
    );

    setHeaderChildren(headerChildren);
  }, [data[productKeys.productAlias]]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    if (data == null || data[caseKeys.id] == null || isLoading) return;
    if (isError) return;

    if (hasCaseViewPermission) getCaseDetails(data[caseKeys.id]);
  }, [data]);

  useEffect(() => {
    ticketsRef.current = data.tickets;
    setNotes(data[productKeys.notes]);
  }, [data]);

  useEffect(() => {
    ticketsRef.current = data.tickets;
    dataRef.current = data;
  }, [data]);

  const onSuccessCreateNote = ({ note: content }) => {
    const note = {
      userName: store[PropKeys.username],
      userImage: store[PropKeys.userImage],
      date: new Date(),
      content,
    };
    setNotes(notes => [note, ...notes]);
  };

  const handleTicketClick = ticketId => () => {
    if (ticketId == null) {
      showSnackbarError(
        'There is an error with this ticket. Please, try again'
      );
      return;
    }
    const to = `/support/${ticketId}`;
    navigate(to);
  };

  const addTicket = () => showSupportModal();

  const showSupportModal = () =>
    showModal(CreateTicket, {
      productData: dataRef.current,
      productTitle: dataRef.current[productKeys.productAlias],
      type: ticketTypes.Product,
    });

  const goToCase = caseAlias => () => {
    const page = hasCaseViewPermission
      ? Pages.cases
      : hasParcelViewPermission
      ? Pages.parcels
      : '';
    const to = `${NavURIs[page]}?search=${caseAlias}`;
    navigate(to);
  };

  const supportButtonTooltipContent = isAllBrands
    ? Prose.allBrandsDisabled
    : '';

  return (
    <ProductDetailsLayout
      productId={productId}
      productAlias={data[productKeys.productAlias]}
      productDetails={data[productKeys.productDetails]}
      status={data[productKeys.productStatus]}
      images={data[productKeys.photos]}
      steps={data[productKeys.steps]}
      statusInstructions={
        data[productKeys.isProductOwner]
          ? statusStepsText[data[productKeys.productStatus]]
          : statusStepsTextNonOwner[data[productKeys.productStatus]]
      }
      actions={data[productKeys.actions]}
      riskType={caseData?.[productKeys.riskType]}
      reason={caseData?.[productKeys.reason]}
      activity={data[productKeys.activity]}
      componentSize="_L"
      goToCase={goToCase}
      parcelAlias={caseData?.[parcelKeys.alias]}
      parcelDetails={caseData?.[productKeys.parcelDetails]}
      parcelDetailsIsLoading={caseIsLoading}
      parcelDetailsIsError={caseIError}
      shouldBlurActors={caseData?.[productKeys.shouldBlurActors]}
      notes={notes}
      onSuccessCreateNote={onSuccessCreateNote}
      tickets={data[productKeys.tickets]}
      authorityDetails={data[productKeys.authorityDetails]}
      isLoading={isLoading}
      isError={isError}
      productData={data}
      handleTicketClick={handleTicketClick}
      addTicket={addTicket}
      ticketsRightIcon={{ name: Icons.ChevronRight }}
      attachments={data[productKeys.attachments]}
      hasTicketCreatePermission={hasTicketCreatePermission}
      hasCaseViewPermission={hasCaseViewPermission}
      supportButtonTooltipContent={supportButtonTooltipContent}
    />
  );
};

export default ProductDetails;
