import styled from '@emotion/styled';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects, FontKeys, Size } from '../../style';

const constantStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: Effects.Border_Radius.Circle,
  text: {
    color: Colors.Sharp.White,
    weight: FontKeys.Weight.Semibold,
  },
  image: {
    borderRadius: Effects.Border_Radius.Circle,
    width: '100%',
    height: '100%',
    position: 'absolute',
    inset: 0,
    margin: 'auto',
    objectFit: 'contain',
  },
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

export const Recipes = {
  Default: ({ opacity, size, background, parentBG }) => {
    return {
      ...Size.Avatar[size],
      background: opacity ? parentBG ?? 'transparent' : background,
      image: {
        opacity,
      },
    };
  },
  Stackable: ({ opacity, size, background, parentBG }) => {
    const showUserImage = !!opacity;
    return {
      ...Size.Avatar[size],
      text: Size.Avatar[size].stackedText,
      background: showUserImage ? Colors.Sharp.White : parentBG ?? background,
      border: `2px solid ${Colors.Sharp.White}`,
      image: { opacity },
    };
  },

  Count: ({ _, size, background }) => {
    return {
      ...Size.Avatar[size],
      background,
      image: {
        opacity: 0,
      },
    };
  },
};

export const Container = styled.div`
  border: ${props =>
    props.border ? `1px solid ${Colors.Neutral._200}` : 'none'};
`;
