import usePostData from '../../../../../hooks/postHook';

export const useUpdateTicketBadge = _ => {
  const [{ data, body, isLoading, isError, error }, updateTicketBadge] =
    usePostData('will set');

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    updateTicketBadge,
  };
};
