import styled from '@emotion/styled';
import { Input } from '../../../../../components/molecules';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';
import { referenceIndexKeys } from '../controllers/variables';

export const AddReferenceContent = styled.div`
  grid-column: 1 / 13;
  width: 100%;
  height: 100%;
  gap: ${Layout.Spacing._M};
  ${Styles.flexColumn};
  .${referenceIndexKeys.additionalDetails} {
    height: 100%;
    grid-column: 1 / 3;
  }
`;

export const UploadOptionsCSS = styled.div`
  display: flex;
  height: 100%;
  gap: ${Layout.Spacing._S};
`;

export const UploadOptionCSS = styled.div`
  width: 0;
  flex-grow: 1;
  background: ${Colors.Neutral._100};
  border-radius: ${Effects.Border_Radius._S};
  padding: calc(${Layout.Spacing._L} + ${Layout.Spacing._2XS});
  ${Styles.flexColumnCenter};
`;

export const BottomRow = styled.div`
  align-items: center;
  ${Styles.flexBetween};
`;

export const Actions = styled.div`
  display: flex;
  gap: ${Layout.Spacing._S};
`;

export const DownloadTag = styled.a`
  text-decoration: none;
`;

export const ReferenceEntryFormCSS = styled(UtilityComponents.Grid)``;

export const WeightInputCSS = styled(Input)`
  max-width: 90px;
`;
