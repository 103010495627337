import { pointerSize } from './components';

const { screenPosition, elementDimensions } = require('../../../utils/utils');

export const getFullHeightPointerTop = e => {
  const { x, y } = screenPosition(e);
  const { height, width } = elementDimensions(e);

  return y - pointerSize + height / 2;
};
