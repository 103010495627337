import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { Input } from '../../../../../../components/molecules/Input/Input';
import { ActingBrandSelection } from '../../../../../../components/organisms/ActingBrandSelection/ActingBrandSelection';
import BadgeDropdown from '../../../../../../components/organisms/BadgeDropdown/BadgeDropdown';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { PopupActions } from '../../../../../../components/templates/Popup/Popup.style';
import { Utils } from '../../../../../../constants';
import {
  FilesGallery,
  LeftSwipe,
  RightAction,
} from '../../../../../../features/file_upload/components';
import {
  descriptionValidation,
  getCharacteristicInitialValues,
  knowledgeBaseKeys,
} from '../../controllers/variables';
import { DetailsSectionTitle } from '../atoms/atoms';
import {
  AddedBy,
  Brand,
  CharacteristicCard,
  CreationDate,
  InstructionDescriptionFields,
  InstructionType,
  Link,
  tableComponents,
} from '../molecules/molecules';
import {
  AuthenticityBadgeCSS,
  FieldsCSS,
  InstructionDescriptionCSS,
  InstructionTypesCSS,
  SectionFlex,
} from '../style';

export const KnowledgeBaseTable = ({ headers, data, rowAction, grid }) => {
  return (
    <Table
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      components={tableComponents}
    />
  );
};

export const Fields = ({
  fields,
  selectOption,
  setTouched,
  actingBrandProps,
}) => {
  return (
    <FieldsCSS>
      <ActingBrandSelection {...actingBrandProps} />
      <Spacer height="_S" />
      {fields.map(field => (
        <Input
          {...field}
          size="_M"
          selectOption={selectOption}
          setTouched={setTouched}
          key={field.name}
        />
      ))}
    </FieldsCSS>
  );
};

export const InstructionDescription = ({
  initialValues,
  fields,
  isEditing,
  toggleEditing,
  allowEditing,
  updateInstruction,
  updateIsLoading,
}) => {
  const [focus, setFocus] = useState(false);
  const focusOnDescription = () => {
    toggleEditing(true)();
    setFocus(true);
  };

  const handleCancel = resetForm => () => {
    resetForm();
    toggleEditing(false)();
  };

  return (
    <InstructionDescriptionCSS isEditing={isEditing}>
      <Card
        title="Knowledge base"
        icon={
          allowEditing && (
            <AnimatedIcon
              name={Icons.Edit3}
              active={isEditing}
              theme="neutral"
              onClick={focusOnDescription}
            />
          )
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={descriptionValidation}
          enableReinitialize
        >
          {({
            isValid,
            setFieldValue,
            setTouched,
            values,
            dirty,
            resetForm,
          }) => {
            const selectOption =
              ({ name, value, label }) =>
              _ => {
                setFieldValue(name, { value, label });
              };
            return (
              <Form>
                <InstructionDescriptionFields
                  fields={fields}
                  selectOption={selectOption}
                  setTouched={setTouched}
                  focus={focus}
                  onFocus={toggleEditing(true)}
                />
                {isEditing && (
                  <Flex w justify="end">
                    <PopupActions
                      onCancel={handleCancel(resetForm)}
                      onPrimary={updateInstruction(values, resetForm)}
                      primaryText="Update"
                      disabled={!isValid || !dirty || updateIsLoading}
                      isLoading={updateIsLoading}
                    />
                  </Flex>
                )}
              </Form>
            );
          }}
        </Formik>
      </Card>
    </InstructionDescriptionCSS>
  );
};

export const Characteristics = ({
  characteristics,
  beforeElement,
  afterElement,
  instructionTypeLabels,
  infoIconDescription,
  showCharacteristicGallery,
  showEditCharacteristic,
}) => {
  return (characteristics ?? [])
    .filter(c => c.items?.length)
    .map(characteristic => (
      <Formik
        initialValues={getCharacteristicInitialValues(
          (characteristic.items ?? []).map(
            i => i[knowledgeBaseKeys.characteristicDescription]
          )
        )}
        key={characteristic[knowledgeBaseKeys.characteristicId]}
        enableReinitialize
      >
        {() => {
          return (
            <Form>
              <Characteristic
                key={knowledgeBaseKeys.charactersticId}
                characteristic={characteristic}
                beforeElement={beforeElement}
                afterElement={afterElement}
                instructionTypeLabels={instructionTypeLabels}
                infoIconDescription={infoIconDescription}
                showCharacteristicGallery={showCharacteristicGallery}
                showEditCharacteristic={showEditCharacteristic(characteristic)}
              />
            </Form>
          );
        }}
      </Formik>
    ));
};

export const Characteristic = ({
  characteristic,
  beforeElement,
  afterElement,
  instructionTypeLabels,
  infoIconDescription,
  showCharacteristicGallery,
  showEditCharacteristic,
}) => {
  const [rightSwipe, setRightSwipe] = useState(0);

  const swipeRight = () => setRightSwipe(x => x + 1);
  const swipeLeft = () => setRightSwipe(x => x - 1);
  const { items: files, [knowledgeBaseKeys.characteristicType]: title } =
    characteristic;
  const showAmount = 3;
  return (
    <CharacteristicCard
      title={instructionTypeLabels[title]}
      infoIconDescription={infoIconDescription[title]}
      showEditCharacteristic={showEditCharacteristic}
    >
      {files.length > showAmount && !!rightSwipe && (
        <LeftSwipe onClick={swipeLeft} />
      )}
      <FilesGallery
        showAmount={showAmount}
        files={files}
        fileState={{}}
        rightSwipe={rightSwipe}
        beforeElement={beforeElement}
        afterElement={afterElement}
        isLoading={false}
        handleDrop={Utils.emptyFunction}
        storageNames={[]}
        percentageShares={{}}
        uploadStatusContent={{}}
        showMultiple={true}
        parentIsLoading={false}
        removeInstance={false}
        onPreviewClick={showCharacteristicGallery(characteristic)}
      />
      {files.length > showAmount && rightSwipe < files.length - showAmount && (
        <RightAction onClick={swipeRight} addsMoreFiles={false} />
      )}
    </CharacteristicCard>
  );
};

export const Details = ({ username, image, brandName, brandImage, date }) => {
  return (
    <SectionFlex>
      <DetailsSectionTitle text={'Details'} />
      <CreationDate date={date} />
      <Brand name={brandName} image={brandImage} />
      <AddedBy name={username} image={image} />
    </SectionFlex>
  );
};

export const Links = ({
  links = [],
  showAddLinks,
  handleEdit,
  hasEditPermission,
}) => {
  return (
    <SectionFlex>
      <Flex align="center" justify="between" className={'header'}>
        <DetailsSectionTitle text={'Links'} />
        {hasEditPermission && (
          <AnimatedIcon
            name={Icons.PlusCircle}
            onClick={showAddLinks}
            theme="popup"
          />
        )}
      </Flex>
      <Flex column gap="_2XS">
        {links.map(link => (
          <Link
            link={link}
            key={link[knowledgeBaseKeys.linkId]}
            handleEdit={handleEdit}
            hasEditPermission={hasEditPermission}
          />
        ))}
      </Flex>
    </SectionFlex>
  );
};

export const AuthenticityBadge = ({
  file,
  options,
  color,
  selectOption,
  selectedOption,
  icon,
  readOnly,
}) => {
  return (
    <AuthenticityBadgeCSS>
      <BadgeDropdown
        size="_S"
        prevIcon={icon}
        text={selectedOption?.label}
        options={options}
        color={color}
        disabled={file === null}
        selectOption={selectOption}
        selectedOption={selectedOption}
        readOnly={readOnly}
      />
    </AuthenticityBadgeCSS>
  );
};

export const InstructionTypes = ({ types, selectType, typeSelected }) => {
  return (
    <InstructionTypesCSS>
      {types.map((type, index) => (
        <InstructionType
          type={type}
          onClick={selectType(type)}
          selected={typeSelected.value === type.value}
          key={index}
        />
      ))}
    </InstructionTypesCSS>
  );
};
