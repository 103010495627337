import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import {
  disabledText1Row,
  disabledText2Rows,
} from '../../../../../../components/molecules/Disabled/variables';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import Legend from '../../../../../../components/molecules/Legend/Legend';
import BarChart from '../../../../../../components/organisms/BarChart/BarChart';
import LineChart from '../../../../../../components/organisms/LineChart/LineChart';
import PieChart from '../../../../../../components/organisms/PieChart/PieChart';
import { Constants } from '../../../../../../constants';
import { parseStringModifiers } from '../../../../../../utils/utils';
import { components, infoDescription } from '../../variables';
import { ItemTitle, LoadingAnimation } from '../atoms/atoms';
import {
  DetectionData,
  ErrorDisplay,
  OverviewData,
  ParcelDetectionTotalsAndInfo,
  ShippingLabelsAndInfo,
} from '../molecules/molecules';
import {
  CounterfeitsCSS,
  DangerousGoodsCSS,
  DepotAreaCSS,
  DetectionDetailsCSS,
  LQCSS,
  OverviewCSS,
  ParcelCountCSS,
  ProcessingTimeCSS,
  RequestsSecondsCSS,
  ReturnCSS,
  ShippingLabelsCSS,
} from '../style';

export const Overview = ({ data: overview, disabled, isLoading, isError }) => {
  return (
    <OverviewCSS disabled={disabled}>
      <Card
        title="Overview"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.overview]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <OverviewData data={overview} />
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </OverviewCSS>
  );
};

export const DetectionDetails = ({
  data: detectionDetails,
  disabled,
  isLoading,
  isError,
}) => {
  return (
    <DetectionDetailsCSS disabled={disabled}>
      <Card
        title="Detection details"
        icon={ParcelDetectionTotalsAndInfo(
          detectionDetails.parcelCount ?? Constants.fallback
        )}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <DetectionData
            data={detectionDetails.data ?? []}
            disabled={disabled}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </DetectionDetailsCSS>
  );
};

export const ParcelCount = ({ data: data_, disabled, isLoading, isError }) => {
  const { data, lines, keys } = data_;
  return (
    <ParcelCountCSS disabled={disabled}>
      <Card
        title="Parcel count"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.parcelCount]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} justify="between" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </ParcelCountCSS>
  );
};

export const RequestsSeconds = ({
  data: data_,
  disabled,
  isLoading,
  isError,
}) => {
  const { data, lines, keys } = data_;
  return (
    <RequestsSecondsCSS disabled={disabled}>
      <Card
        title="Over 3/4/5 seconds"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.requestsSeconds]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} justify="between" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </RequestsSecondsCSS>
  );
};

export const ProcessingTime = ({
  data: data_,
  disabled,
  isLoading,
  isError,
}) => {
  const { data, lines, keys } = data_;
  return (
    <ProcessingTimeCSS disabled={disabled}>
      <Card
        title="Processing time"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.processingTime]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} gap="_L" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </ProcessingTimeCSS>
  );
};

export const Counterfeits = ({ data: data_, disabled, isLoading, isError }) => {
  const { data, lines, keys } = data_;
  return (
    <CounterfeitsCSS disabled={disabled}>
      <Card
        title="Counterfeits"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.counterfeits]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} gap="_L" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </CounterfeitsCSS>
  );
};

export const Return = ({ data: data_, disabled, isLoading, isError }) => {
  const { data, lines, keys } = data_;
  return (
    <ReturnCSS disabled={disabled}>
      <Card
        title="Return"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.return]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} gap="_L" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </ReturnCSS>
  );
};

export const LQ = ({ data: data_, disabled, isLoading, isError }) => {
  const { data, lines, keys } = data_;
  return (
    <LQCSS disabled={disabled}>
      <Card
        title="LQ"
        icon={
          <InfoIcon
            description={parseStringModifiers(infoDescription[components.LQ])}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} gap="_L" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </LQCSS>
  );
};

export const DepotArea = ({ data: data_, disabled, isLoading, isError }) => {
  const { data, lines, keys } = data_;
  return (
    <DepotAreaCSS disabled={disabled}>
      <Card
        title="Host Depot Area"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.depot]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex column gap="_S" grow>
            <Legend keys={keys} gap="_L" />
            <LineChart data={data} lines={lines} syncId="analytics" />
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DepotAreaCSS>
  );
};

export const ShippingLabels = ({
  data: data_,
  hostName,
  disabled,
  isLoading,
  isError,
}) => {
  const { data, bars, totalLabels, hostLabels } = data_;
  return (
    <ShippingLabelsCSS disabled={disabled}>
      <Card
        title="Shipping labels"
        icon={
          <ShippingLabelsAndInfo
            totalLabels={totalLabels}
            hostName={hostName ?? Constants.fallback}
            hostLabels={hostLabels}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <BarChart data={data} bars={bars} ignoreTotalTooltip />
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </ShippingLabelsCSS>
  );
};

export const DangerousGoods = ({
  data: data_,
  disabled,
  isLoading,
  isError,
}) => {
  const { data, total, main, colors } = data_;
  return (
    <DangerousGoodsCSS disabled={disabled} className="dangerous-goods">
      <Card
        title="Dangerous goods labels"
        icon={
          <InfoIcon
            description={parseStringModifiers(
              infoDescription[components.dangerousGoods]
            )}
          />
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <ItemTitle text={'Detection of hazard class and dangerous goods'} />
            <PieChart
              data={data}
              total={total}
              main={main}
              colors={colors}
              justifyLabel="between"
              customDimensions={{
                width: 215,
                height: 215,
                cx: 100,
                cy: 100,
                innerRadius: 80,
                outerRadius: 100,
              }}
            />
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DangerousGoodsCSS>
  );
};
