import styled from '@emotion/styled';
import { Table } from '../../../../../components/organisms/Table/Table';
import { Layout } from '../../../../../components/style';
import { Content } from '../../../../../components/templates/Popup/Popup.style';

export const AdminCategoriesTableContainer = styled(Table)`
  .table-cell, .table-header {
    &._0 {
      padding-left: ${Layout.Spacing._S};
    }
  `;

export const AddContent = styled(Content)`
  display: flex;
  flex-direction: column;
  *::-webkit-scrollbar {
    width: 8px;
    display: block;
  }
`;
