import useFetchData from '../../../../../hooks/fetchHook';
import { API } from '../../../../../lib/api';
import { useAuth } from '../../../../../providers/AuthProvider';

export const useDownloadMonthlyReports = () => {
  const [{ data, isLoading, isError, error }, _, doDownloadMonthlyReport] =
    useFetchData('reports/export', {
      ignore: true,
    });

  const [{ token }] = useAuth();

  const downloadMonthlyReport = ({ startDate, endDate }) => {
    const params = {
      from_date: startDate,
      to_date: endDate,
    };
    API.GET('reports/export', {
      params,
      responseType: 'arraybuffer',
      headers: {
        Authorization: token,
      },
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]), {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        res.headers?.['content-disposition']?.split('"')?.[1] ||
          `Report-${Date.now()}.zip`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    downloadMonthlyReport,
  };
};
