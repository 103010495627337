import { parseStringModifiers } from '../../../utils/utils';
import { Icon } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import Button from '../../molecules/Button/Button';
import { Description as NoResultsDescription } from '../../molecules/NoResults/NoResults.style';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import { Container, Description, Title } from './NoSearchResults.style';

const NoSearchResults = ({
  show = true,
  icon,
  title,
  description,
  subDescription,
  button,
}) => {
  return show ? (
    <Container
      className="no-search-results"
      showTitle={!!title && title !== 0 && title !== '0'}
    >
      <Icon name={icon} />
      <Flex column gap="_XS" align="center">
        {title && <Title text={title} />}
        {description && (
          <Description text={parseStringModifiers(description)} />
        )}
        {subDescription && (
          <NoResultsDescription description={subDescription} />
        )}
      </Flex>
      {button && (
        <Tooltip content={button.tooltipContent}>
          <Button {...button} />
        </Tooltip>
      )}
    </Container>
  ) : (
    <></>
  );
};
export default NoSearchResults;
