import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Popup } from '../../../../../components/templates/Popup/Popup';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { parseStringModifiers } from '../../../../../utils/utils';
import { PopupDescription, PopupTitle, WelcomeIcon } from './atoms/atoms';
import { WelcomeActions } from './molecules/molecules';

export const WelcomePopupLayout = ({ onCancelClick, onPrimaryClick }) => {
  return (
    <Popup size="_M">
      <Content>
        <Flex column h justify="between" align="center">
          <PopupTitle text={'Welcome to Countercheck!'} />
          <WelcomeIcon />
          <PopupDescription
            text={parseStringModifiers(`Add new reference data to help us start catching
                parcels with your counterfeit goods.`)}
          />
          <WelcomeActions
            onCancelClick={onCancelClick}
            onPrimaryClick={onPrimaryClick}
          />
        </Flex>
      </Content>
    </Popup>
  );
};
