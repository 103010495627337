import { Text } from '../../../../atoms';
import { FontKeys } from '../../../../style';
import { Container, OverlayS, PreviewGalleryContainerS } from '../style';

export const Overlay = ({ sidebarWidth, children }) => {
  return <OverlayS sidebarWidth={sidebarWidth}>{children}</OverlayS>;
};

export const GalleryContainer = ({
  onKeyDown,
  onClick,
  containerRef,
  tabIndex,
  id,
  sidebarWidth,
  children,
}) => {
  return (
    <Container
      onKeyDown={onKeyDown}
      onClick={onClick}
      ref={containerRef}
      tabIndex={tabIndex}
      id={id}
      sidebarWidth={sidebarWidth}
    >
      {children}
    </Container>
  );
};

export const PreviewGalleryContainer = ({ className, children }) => {
  return (
    <PreviewGalleryContainerS className={className}>
      {children}
    </PreviewGalleryContainerS>
  );
};

export const Title = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
