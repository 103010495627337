import * as RadixTooltip from '@radix-ui/react-tooltip';
import { TooltipContent } from './styles';

const Tooltip = ({
  children,
  content,
  delayDuration = 0,
  sizeOffset = 8,
  side = 'top',
  size = '_S',
  asChild = true,
  ...props
}) =>
  content ? (
    <RadixTooltip.Provider delayDuration={delayDuration} side>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild={asChild}>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <TooltipContent
            // library props
            side={side}
            sideOffset={sizeOffset}
            className={`content ${props.className ?? ''}`}
            // custom props
            size={size}
          >
            <RadixTooltip.Arrow />
            {content}
          </TooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  ) : (
    children
  );

export default Tooltip;
