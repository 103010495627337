import { useEffect } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useAddLinks } from '../api/addLinks';
import { useUpdateLink } from '../api/updateLink';
import { centerStyles } from '../components/style';
import { LinkUploadLayout } from '../pages/LinkUploadLayout';
import {
  linkUploadFields,
  linkUploadInitialValues,
  linkUploadNames,
  linkUploadValidation,
} from './variables';

const { SELF, EDIT_KNOWLEDGE_BASE_LINKS } = PermissionKeys.Brand.KNOWLEDGE_BASE;

const LinkUpload = ({
  instructionId,
  onUploadSuccess = Utils.emptyFunction,
  e,
  type = 'add',
  element,
  linkId,
  prevLinkValues,
}) => {
  const { data, body, isLoading, isError, error, doAddLinks } = useAddLinks({
    instructionId,
  });

  const {
    data: updateLinkData,
    body: updateBody,
    isLoading: updateLinkIsLoading,
    isError: updateLinkIsError,
    error: updateLinkError,
    doUpdateLink,
  } = useUpdateLink();

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError } = useSnackbar();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  useEffect(() => {
    if (!hasPermission(EDIT_KNOWLEDGE_BASE_LINKS)) dismiss();
  }, []);

  useEffect(() => {
    if (isLoading || data == null) return;
    if (isError) {
      showSnackbarError(error);
    }

    onUploadSuccess(data);
    dismissAll();
  }, [isLoading, isError, data, body]);

  useEffect(() => {
    if (updateLinkIsLoading || updateLinkData == null) return;
    if (updateLinkIsError) {
      showSnackbarError(updateLinkError);
    }

    onUploadSuccess({ data: updateLinkData, body: updateBody });
    dismissAll();
  }, [updateLinkIsLoading, updateLinkIsError, updateLinkData]);

  const addLinks = values => {
    let { urls } = values;
    const body = { urls: [urls] };
    doAddLinks(body);
  };

  const updateLink = value => {
    const { [linkUploadNames.urls]: url } = value;
    const body = { url };
    const updateUrl = `knowledge_base/${instructionId}/link/${linkId}`;
    doUpdateLink(body, updateUrl);
  };

  // TODO: rethink this. it might be better to pass in title, initial Valuese,
  // and on submit instead of choosing by type. Everything can go directly to InPlaceForm
  // instead of having a controller in the middle
  return (
    <LinkUploadLayout
      title={type === 'add' ? 'Add link' : 'Edit link'}
      element={element}
      initialValues={type === 'add' ? linkUploadInitialValues : prevLinkValues}
      validationSchema={linkUploadValidation}
      onSubmit={type === 'add' ? addLinks : updateLink}
      fields={linkUploadFields}
      e={e}
      offsetX={-240}
      offsetY={36}
      isLoading={isLoading || updateLinkIsLoading}
    />
  );
};

export default Modal(LinkUpload, false, { centerStyles });
