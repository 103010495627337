import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { avoidPropagation, setImagePlaceholder } from '../../../utils/utils';
import { Icon, Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Image } from '../../atoms/Image/Image';
import Button from '../../molecules/Button/Button';
import {
  Colors,
  Effects,
  FontKeys,
  Layout,
  Size,
  UtilityColors,
} from '../../style';

// %
export const DropzoneCSS = styled.div`
  position: relative;
  flex-grow: 1;
  border-radius: ${Effects.Border_Radius._S};
  background: ${Colors.Neutral._100};
  ${Styles.flexCenter};
`;

export const DropzoneClickarea = styled.div`
  width: 100%;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  ${Styles.flexCenter};
`;

const LoadingBarShape = styled.div`
  height: ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._S};
`;

export const LoadingTrack = styled(LoadingBarShape)`
  position: relative;
  background: ${Colors.Neutral._200};
  max-width: 466px;
  width: 90%;
`;

export const LoadingBarCSS = styled(LoadingBarShape)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${props => props.percentage}%;
  background: ${Colors.Primary._600};
  transition: ${Effects.Transition.EaseInOut()};
`;

const PreexistingPreventClick = styled.div`
  position: absolute;
  inset: 0;
  cursor: auto;
  ${Styles.flexCenter};
`;

const PreexistingImageCSS = styled.div`
  width: 320px;
  height: 320px;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
  border: 1px solid ${UtilityColors.Border};
  img {
    object-fit: contain !important;
  }
`;

export const FileStatus = ({
  isUploading,
  uploadStatusContent,
  percentageUploaded,
}) => {
  return isUploading ? (
    <LoadingBar percentage={percentageUploaded} {...uploadStatusContent} />
  ) : uploadStatusContent.preexistingFile ? (
    <PreexistingImage image={uploadStatusContent.preexistingFile} />
  ) : (
    <IconTextButton {...uploadStatusContent} />
  );
};

const PreexistingImage = ({ image }) => {
  return (
    <PreexistingPreventClick onClick={avoidPropagation()}>
      <PreexistingImageCSS>
        <Image src={image} onError={setImagePlaceholder('_2XL')} />
      </PreexistingImageCSS>
    </PreexistingPreventClick>
  );
};

const IconTextButton = ({ icon, title, text, button }) => {
  return (
    <Flex column gap="_M" align="center" w>
      <Icon name={icon} size={Size.Icon._XL} />
      {title && <PromptTitle text={title} />}
      <PromptText text={text} />
      {button && <Button {...button} size="_M" variant="Primary" />}
    </Flex>
  );
};

const LoadingBar = ({ percentage, title }) => {
  return (
    <Flex column gap="_M" className="loading-bar" align="center" w>
      <LoadingTrack>
        <LoadingBarCSS className="loading-progress" percentage={percentage} />
      </LoadingTrack>
      <PromptTitle text={title} />
    </Flex>
  );
};

const PromptTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
    />
  );
};

const PromptText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      alignment="center"
    />
  );
};
