import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { useAddService } from '../api/addServices';
import { AddServiceLayout } from '../components/AddServiceLayout';

const AddService = ({ services, onSuccess }) => {
  const [selected, setSelected] = useState(-1);

  const { brandId } = useParams();

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { data, body, isLoading, isError, error, doAddService } = useAddService(
    {
      id: brandId,
    }
  );

  useDeepCompareEffect(() => {
    // const selected = services.map(_ => '0').join('');
    // setSelected(selected);
  }, []);

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess('Service added');
    onSuccess();
  }, [body, isError, isLoading]);

  const onSubmit = () => {
    const selectedServices =
      services.filter((_, i) => selected == i).map(({ id }) => id) || [];
    doAddService({ service_id: selectedServices[0] });
  };

  const toggleSelected = index => () => setSelected(index);
  // setSelected(expanded => {
  //   if (isLoading) return expanded;
  //   const isExpanded = expanded[index] === '1' ? '0' : '1';
  //   return replaceCharAt(expanded, index, isExpanded);
  // });

  // const noneSelected = !selected.includes('1');

  return (
    <AddServiceLayout
      listProps={{
        services,
        selected,
        toggleSelected,
      }}
      actionsProps={{
        onCancel: dismiss,
        onPrimary: onSubmit,
        primaryText: 'Add',
        isLoading: isLoading,
        disabled: selected < 0 || isLoading,
      }}
    />
  );
};
export default Modal(AddService);
