import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Layout, UtilityEffects } from '../../style';

export const headerHeight = Layout.Spacing._2XL;

export const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ headerIndex }) => headerIndex};
  width: 100%;
  border-bottom: 1px solid ${Colors.Neutral._100};
  padding: 0 ${Layout.Spacing._M};
  min-height: ${headerHeight};
  background: ${Colors.Sharp.White};
  transition: ${UtilityEffects.SidebarTransition('width')};
  ${Styles.flexCenter}
`;

export const Centering = styled.div`
  margin: 0 auto;
  width: 100%;
`;
