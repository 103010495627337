import usePostData from '../../../../../hooks/postHook';
import { fieldNames, roleTypes } from '../controllers/variables';

export const useAddService = ({ isEditing }) => {
  const [{ data, body, isLoading, isError, error }, doAddService] = usePostData(
    ``,
    {
      type: isEditing ? 'put' : 'post',
    }
  );

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doAddService,
  };
};

export const formatServiceRoles = values => {
  const roles = [
    {
      name: 'Admin',

      key: 'ADMIN',

      detail: values[fieldNames.adminRoleDescription],

      order: '1',

      permissions: [],
    },

    {
      name: 'Editor',

      key: 'EDITOR',

      detail: values[fieldNames.editorRoleDescription],

      order: '2',

      permissions: [],
    },

    {
      name: 'Viewer',

      key: 'VIEWER',

      detail: values[fieldNames.viewerRoleDescription],

      order: '3',

      permissions: [],
    },
  ];

  values[fieldNames.permissions.row].forEach(permission => {
    const permissionIdentifier = {
      name: permission[fieldNames.permissions.name],
      key: permission[fieldNames.permissions.systemName],
    };

    roleTypes.forEach((roleType, index) => {
      roles[index].permissions.push({
        ...permissionIdentifier,
        value: permission[roleType] ? 1 : 0,
      });
    });
  });

  return roles;
};
