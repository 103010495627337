import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Icon, Text } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, FontKeys, Layout, Size } from '../../style';

const PasswordRuleCSS = styled.div`
  gap: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._M};
  ${Styles.flex};
`;

export const PasswordRule = ({ text, valid }) => {
  return (
    <PasswordRuleCSS>
      <Icon
        name={valid ? Icons.CheckCircle : Icons.XCircle}
        color={valid ? Colors.Success._500 : Colors.Error._500}
        size={Size.Icon._S}
        text={text}
      />
      <Text
        variant="Paragraph"
        size="_S"
        weight={FontKeys.Weight.Regular}
        text={text}
      />
    </PasswordRuleCSS>
  );
};
