import { forwardRef } from 'react';
import { Text } from '../../../../../../components/atoms';
import { Colors, FontKeys } from '../../../../../../components/style';
import { TabCSS } from '../style';

export const Tab = forwardRef(({ tab, selected, onClick }, ref) => {
  return (
    <TabCSS onClick={onClick} ref={ref}>
      <Text
        text={tab}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        color={selected ? Colors.Primary._600 : Colors.Neutral._500}
      />
    </TabCSS>
  );
});

export const StatusDescriptionText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const SectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const ReferenceSearchDescription = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};
