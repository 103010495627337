import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import { Container, Slide, SlidesContainer } from './exports';
import { Dots } from './style';

export const Carousel = ({
  slides,
  options = { loop: true },
  clickableElement,
  clickableElementProps,
  ...props
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  //   const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  //   const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onInit = useCallback(emblaApi => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(emblaApi => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    // setPrevBtnEnabled(emblaApi.canScrollPrev());
    // setNextBtnEnabled(emblaApi.canScrollNext());
  }, []);

  const scrollTo = useCallback(
    index => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  const ClickableElement = clickableElement;

  return (
    <>
      <Container
        ref={emblaRef}
        className={`carousel ${props.className}`}
        onClick={clickableElementProps?.onClick(
          slides[selectedIndex],
          selectedIndex
        )}
      >
        <SlidesContainer>
          {slides.map((slide, index) => (
            <Slide key={index}>{slide}</Slide>
          ))}
        </SlidesContainer>
      </Container>
      <Dots
        stepTo={scrollTo}
        activeIndex={selectedIndex}
        amount={scrollSnaps.length}
        {...props}
      />
      {clickableElement && (
        <ClickableElement
          {...clickableElementProps}
          onClick={clickableElementProps?.onClick(
            slides[selectedIndex],
            selectedIndex
          )}
        />
      )}
    </>
  );
};
