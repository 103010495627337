import usePostData from '../../../../../hooks/postHook';
import { usersKeys } from '../controllers/variables';

export const useCreateUser = () => {
  const [{ body, isLoading, isError, error }, createUser] =
    usePostData(`clients`);

  const doCreateUser = values => {
    const body = {
      first_name: values[usersKeys.firstName],
      last_name: values[usersKeys.lastName],
      email: values[usersKeys.email],
      // updates here must happen on updateUserRoles.js
      roles: values.services.map(service => ({
        id: service.selectedRole?.id ?? null,
        service_id: service.id,
      })),
    };
    createUser(body);
  };

  return {
    body,
    isLoading,
    isError,
    error,
    doCreateUser,
  };
};
