import SubViews from '../../../components/templates/SubViews/SubViews';
import { Labels, Pages } from '../../../routes/variables';

const UserSettingsLayout = ({ components, options, styles }) => {
  return (
    <SubViews
      options={options}
      styles={styles}
      components={components}
      title={Labels[Pages.user_settings]}
    />
  );
};
export default UserSettingsLayout;
