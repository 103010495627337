import styled from '@emotion/styled';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Recipe } from '../../atoms/Text/Text.style';
import { Colors, Effects, FontKeys, Layout } from '../../style';

export const TooltipContent = styled(Tooltip.Content)`
  max-width: 200px;
  background-color: ${Colors.Neutral._900};
  padding: ${Layout.Spacing._2XS};
  color: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._3XS};
  ${({ size }) =>
    Recipe[FontKeys.Variant.Paragraph][size]({
      weight: FontKeys.Weight.Semibold,
    })}
`;

export const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${Colors.Neutral._900};
`;
