import { forwardRef } from 'react';
import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, FontKeys } from '../../style';
import { TabCountContainer } from './style';

export const Tab = forwardRef(({ label, count, selected, onClick }, ref) => {
  return (
    <Flex gap={'_2XS'} onClick={onClick} ref__={ref} align={'center'}>
      <TabLabel text={label} selected={selected} />
      <TabCount text={count} selected={selected} />
    </Flex>
  );
});

export const TabLabel = ({ text, selected }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={selected ? Colors.Primary._600 : Colors.Neutral._500}
    />
  );
};

export const TabCount = ({ text, selected }) => {
  return (
    <TabCountContainer selected={selected}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Semibold}
        color={selected ? Colors.Sharp.White : Colors.Neutral._500}
      />
    </TabCountContainer>
  );
};
