import styled from '@emotion/styled';
import { Styles, Utils } from '../../../constants';
import { Text } from '../../atoms';
import Legend from '../../molecules/Legend/Legend';
import { Colors, FontKeys, Layout } from '../../style';

export const PieContainer = styled.div`
  position: relative;
`;

const MainCSS = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumnCenter};
`;

export const Main = ({ total, description }) => {
  return (
    <MainCSS>
      <TotalCount text={total} />
      <TotalDescription text={description} />
    </MainCSS>
  );
};

const LabelsCSS = styled.div`
  flex-grow: 1;
`;

export const Labels = ({
  data,
  colors = defaultColors,
  justify,
  onClick = Utils.emptyFunction,
}) => {
  const keys = data.map((entry, index) => {
    const { color = colors[index], value, valueLabel, key } = entry;
    return {
      key,
      value,
      valueLabel,
      column: false,
      color,
      onClick: onClick(entry),
    };
  });

  return (
    <LabelsCSS className="piechart-labels">
      <Legend keys={keys} column gap="_XS" justify={justify} />
    </LabelsCSS>
  );
};

export const TotalCount = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Heading}
    size={FontKeys.Size.H4}
    weight={FontKeys.Weight.Bold}
    color={Colors.Primary._600}
  />
);

export const TotalDescription = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Caption}
    size={FontKeys.Size._M}
    weight={FontKeys.Weight.Regular}
    color={Colors.Neutral._500}
  />
);

export const defaultColors = [
  Colors.Primary._050,
  Colors.Primary._200,
  Colors.Primary._400,
  Colors.Primary._500,
  Colors.Primary._600,
  Colors.Primary._800,
];
