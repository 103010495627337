import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import ProgressBar from '../../../../../../components/molecules/ProgressBar/ProgressBar';
import { Colors, Size } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import { BaselineAlignText } from '../../../../../../utils/stringUtils';
import { parseStringModifiers } from '../../../../../../utils/utils';
import { components, infoDescription } from '../../variables';
import {
  AnalyticsItemLabel,
  AnalyticsItemSublabel,
  ErrorText,
  GraphTotalLabel,
  GraphTotalValue,
  ItemData,
  ItemTitle,
} from '../atoms/atoms';
import { DetectionDataCSS, OverviewDataCSS } from '../style';

export const OverviewData = ({ data }) => {
  return (
    <OverviewDataCSS>
      {data.map(item => (
        <OverviewItem item={item} key={item.title} />
      ))}
    </OverviewDataCSS>
  );
};

const OverviewItem = ({ item }) => {
  const { title, data } = item;
  return (
    <Flex column gap="_XS">
      <ItemTitle text={title} />
      <ItemData text={data} />
    </Flex>
  );
};

export const DetectionData = ({ data, disabled }) => {
  return (
    <DetectionDataCSS>
      {data.map(item => (
        <DetectionItem item={item} disabled={disabled} key={item.title} />
      ))}
    </DetectionDataCSS>
  );
};

const DetectionItem = ({ item, disabled }) => {
  const { title, value, percentage } = item;
  return (
    <Flex column gap="_2XS">
      <Flex gap="_2XS">
        <AnalyticsItemLabel text={title} />
        <AnalyticsItemSublabel text={value} />
      </Flex>
      <Flex align="center" gap="_XS">
        <ProgressBar
          percentage={percentage}
          color={disabled ? Colors.Neutral._200 : undefined}
        />
        <AnalyticsItemLabel
          text={disabled ? Constants.fallback : `${percentage}%`}
        />
      </Flex>
    </Flex>
  );
};

export const ParcelDetectionTotalsAndInfo = parcelCount => {
  return (
    <Flex gap="_S">
      <BaselineAlignText>
        <GraphTotalLabel text={'Parcels: '} />
        <GraphTotalValue text={parcelCount} />
      </BaselineAlignText>
      {
        <InfoIcon
          description={parseStringModifiers(
            infoDescription[components.detectionDetails]
          )}
        />
      }
    </Flex>
  );
};

export const ShippingLabelsAndInfo = ({
  totalLabels,
  hostName,
  hostLabels,
}) => {
  return (
    <Flex gap="_S">
      <Flex gap="_L">
        <BaselineAlignText>
          <BaselineAlignText>
            <GraphTotalLabel text={'Total labels: '} />
            <GraphTotalValue text={totalLabels} />
          </BaselineAlignText>
        </BaselineAlignText>
        <BaselineAlignText>
          <GraphTotalLabel text={`${hostName} labels: `} />
          <GraphTotalValue text={hostLabels} />
        </BaselineAlignText>
      </Flex>
      {
        <InfoIcon
          description={parseStringModifiers(
            infoDescription[components.shippingLabels]
          )}
        />
      }
    </Flex>
  );
};

export const ErrorDisplay = () => (
  <Flex column gap="_S" justify="center" align="center" w h>
    <Icon
      name={Icons.AlertTriangle}
      color={Colors.Neutral._500}
      size={Size.Icon._L}
    />
    <ErrorText text={'Oops! Something went wrong'} />
  </Flex>
);
