import usePostData from '../../../../../hooks/postHook';
import { usersKeys } from '../controllers/variables';

export const useUpdateUserEmail = ({ id }) => {
  const [{ body, isLoading, isError, error }, updateUserEmail] = usePostData(
    `client/${id}/email`
  );

  const doUpdateUserEmail = values => {
    const body = {
      email: values[usersKeys.email],
    };
    updateUserEmail(body);
  };

  return { body, isLoading, isError, error, doUpdateUserEmail };
};
