import { Route, Routes } from 'react-router-dom';
import { NoServices } from './NoServices';

export const NoServicesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<NoServices />} />
    </Routes>
  );
};
