import styled from '@emotion/styled';
import { ResponsiveSizes, Styles } from '../../../constants';
import { hexOpacity } from '../../../utils/styleUtils';
import { Colors, UtilityEffects } from '../../style';

export const Overlay = styled.div`
  position: absolute;
  top: ${({ scrollTop }) => scrollTop}px;
  cursor: pointer;
  z-index: 225;
  width: 100%;
  grid-template-columns: repeat(12, minmax(min(10px, 100%), 1fr));
  display: ${props => (props.grid ? 'grid' : 'flex')};
  min-height: ${props =>
    props.overlay ? ResponsiveSizes.minWindowHeight + 'px' : 0};
  height: ${props => (props.overlay ? '100vh' : 0)};
  grid-column-gap: ${Styles.gridColumnGap};
  background: ${props =>
    props.hideOverlay ? 'none' : Colors.Primary._900 + hexOpacity(40)};
  transition: ${UtilityEffects.SidebarTransition('width', 'left')};
  ${Styles.flexCenter};
`;

export const Center = styled.div`
  margin: auto;
  grid-column: 2 / 12;
  min-height: ${ResponsiveSizes.minWindowHeight}px;
  ${Styles.flexColumnCenter};
`;
