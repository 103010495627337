import { Icon, Text } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, FontKeys, Size } from '../../style';
import { Container } from './style';

export const Alert = ({ text, size = '_M', ...props }) => {
  return (
    <Container {...props}>
      <Icon
        name={Icons.Info}
        color={Colors.Primary._600}
        size={Size.Icon[size]}
      />
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size[size]}
        weight={FontKeys.Weight.Regular}
        color={Colors.Primary._600}
      />
    </Container>
  );
};
