import { PropKeys } from '../../../../../constants';
import usePostData from '../../../../../hooks/postHook';

export const useCreateTicket = () => {
  const [{ data, body, isLoading, isError, error }, doCreateTicket] =
    usePostData('support_tickets/create');

  return {
    isLoading,
    isError,
    error,
    data,
    body,
    doCreateTicket,
  };
};

export const createTicket = ({
  type,
  selectedProduct,
  title,
  selectedPriority,
  details,
  brandId,
  doCreateTicket,
}) => {
  const {
    productId,
    id,
    caseId,
    parcelId,
    [PropKeys.sorterId]: sorter_id,
  } = selectedProduct;

  const body = {
    title,
    type,
    priority: selectedPriority,
    message: details,
    reference_id: productId || id || caseId || parcelId,
    brand_id: brandId,
    sorter_id,
  };

  doCreateTicket(body);
};
