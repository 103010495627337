import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { optionViews } from '../../../features/user_settings/controllers/variables';
import { useAuth } from '../../../providers/AuthProvider';
import { Pages, URIs } from '../../../routes/variables';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import Button from '../../molecules/Button/Button';
import { menuXAxisPadding } from '../Sidebar/Sidebar.style';
import { SectionTitle } from '../Sidebar/components';

const Container = styled.div`
  width: calc(200px - calc(${menuXAxisPadding} * 2));
`;

const UserProfileCard = ({ clearMenu }) => {
  const [_, setCredentials] = useAuth();

  const navigate = useNavigate();

  const logout = () => setCredentials({ logout: true });

  const goToUserSettings = option => () => {
    const to = URIs[Pages.user_settings];
    const state = { option };
    navigate(to, { state });
    clearMenu();
  };

  const profiletOptions = [
    {
      leftIcon: Icons.UserCog,
      text: 'Profile settings',
      onClick: goToUserSettings(optionViews.userInfo),
    },
    {
      leftIcon: Icons.ShieldAlert,
      text: 'Security',
      onClick: goToUserSettings(optionViews.security),
    },
  ];

  return (
    <Container>
      <Flex gap="_2XS" column w>
        <Flex padding={'8px 0'}>
          <SectionTitle text="Account" />
        </Flex>
        {profiletOptions.map((option, index) => (
          <Button {...option} variant="Tertiary" size="_S" black key={index} />
        ))}
        <Flex relative w children={<Separator />} />
        <Button
          size="_S"
          text="Log out"
          variant="Tertiary"
          leftIcon={Icons.LogOut}
          onClick={logout}
          gray
        />
      </Flex>
    </Container>
  );
};

export default UserProfileCard;
