import usePostData from '../../../../../hooks/postHook';

export const useGetProductsImage = () => {
  const [{ data, isLoading, isError, error }, getProductsImage] = usePostData(
    'office_cases/images'
  );

  const doGetProductsImage = ({ case_ids, limit = 1 }) => {
    if (case_ids == null || case_ids.length === 0) return;
    const body = {
      case_ids,
      only_thumbs: true,
      limit,
    };
    getProductsImage(body);
  };

  return {
    data,
    isLoading,
    isError,
    error,
    getProductsImage: doGetProductsImage,
  };
};
