import SubViews from '../../../../../components/templates/SubViews/SubViews';
import { Labels, Pages } from '../../../routes/variables';

const ReportsLayout = ({ components, options, styles }) => {
  return (
    <SubViews
      options={options}
      styles={styles}
      components={components}
      title={Labels[Pages.reports]}
    />
  );
};

export default ReportsLayout;
