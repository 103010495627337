import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { formatPopulars, inactiveBarchart } from '../formatters/formatPopulars';

export const useGetPopulars = ({ searchParams, inactiveBrand, ignore }) => {
  const [{ data = [], isLoading, isError, error }, _, __, refresh] =
    useFetchData('dashboard/populars', {
      params: formatParams(searchParams),
      formatter: formatPopulars,
      ignore,
    });

  return {
    data: inactiveBrand || ignore ? inactiveBarchart() : data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
