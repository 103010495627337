import { Rectangle } from 'recharts';
import { Colors } from '../../style';

export const CustomBar = ({
  x,
  y,
  fill,
  height,
  width,
  barSize = '8',
  index,
  colors,
  clickable,
}) => {
  return (
    <g
      transform={`translate(${x},${y})`}
      style={{ cursor: clickable ? 'pointer' : 'auto' }}
    >
      <rect
        width={barSize ?? width}
        height={height}
        rx="2"
        fill={colors?.[index] ?? fill}
      />
    </g>
  );
};

export const CustomCursor = ({ x, y, width, height }) => {
  return (
    <Rectangle
      fill={Colors.Neutral._100}
      x={x}
      y={y}
      height={height}
      width={width}
    />
  );
};
