import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import { ParcelsTable } from '../organisms';

const ParcelsLayout = ({
  headers,
  data,
  grid,
  rowAction,
  onRowExpand,
  isLoading,
  isError,
  error,
  tableStatusProps,
  checkedRows,
  tableComponentsProps,
  avoidSingleExpand,
  expandCountKey,
}) => {
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        <ParcelsTable
          headers={headers}
          data={data}
          onRowExpand={onRowExpand}
          grid={grid}
          rowAction={rowAction}
          avoidSingleExpand={avoidSingleExpand}
          tableComponentsProps={tableComponentsProps}
          checkedRows={checkedRows}
          expandCountKey={expandCountKey}
        />
      }
    />
  );
};
export default ParcelsLayout;
