import ImageZoom from '../../../../../lib/ImageZoom';
import { avoidPropagation } from '../../../../../utils/utils';
import { Text } from '../../../../atoms';
import Icon from '../../../../atoms/Icon/Icon';
import { Icons } from '../../../../atoms/Icon/Icon.options';
import { Image } from '../../../../atoms/Image/Image';
import { Separator } from '../../../../atoms/Separator/Separator';
import {
  TableLoader,
  ThumbnailLoader,
} from '../../../../molecules/LoadingAnimation/LoadingAnimation.exports';
import { Colors, FontKeys, Size } from '../../../../style';
import { displayImages, hightlight, selectImage } from '../../utils';
import {
  ArrowCSS,
  DisplayedImageS,
  LoadingPositionS,
  MainPreviewImageCSS,
  MainSliderContainer,
  RemainingCountOverlay,
  ThumbnailCSS,
  ThumbnailHighlight,
  ThumbnailsContainer,
  TransformActionS,
  TransformationSeparatorS,
  styles,
} from '../style';

export const TransformAction = ({ icon, onClick }) => {
  const handleClick = avoidPropagation(onClick);
  return (
    <TransformActionS onClick={handleClick}>
      <Icon name={icon} size={Size.Icon._M} />
    </TransformActionS>
  );
};

export const DisplayedImage = ({
  image,
  zoomRef,
  rotation,
  maxZoom,
  isLoading,
  onLoad,
}) => {
  return (
    <DisplayedImageS onClick={avoidPropagation()}>
      <ImageZoom
        ref={zoomRef}
        animDuration={0.1}
        rotate={rotation}
        maxZoom={maxZoom}
      >
        <Image src={image} onLoad={onLoad} />
      </ImageZoom>
      <LoadingPositionS>{isLoading && <TableLoader />}</LoadingPositionS>
    </DisplayedImageS>
  );
};

const swipeIconSize = {
  preview: '16px',
};

export const Swipe = ({ type, direction, swipe }) => {
  const icon = {
    prev: Icons.ChevronLeft,
    next: Icons.ChevronRight,
  };

  const handleClick = avoidPropagation(swipe(direction));

  return (
    <ArrowCSS direction={direction} onClick={handleClick} type={type}>
      <Icon name={icon[direction]} size={swipeIconSize[type]} />
    </ArrowCSS>
  );
};

export const RemainingCount = ({
  noOfImages,
  index,
  type,
  amount,
  size = '_M',
}) => {
  if (type !== 'preview' || index !== amount - 1 || noOfImages < amount + 1)
    return;
  const text = `+${noOfImages - amount + 1}`;
  return (
    <RemainingCountOverlay className="remaining-count">
      <Text
        text={text}
        variant={FontKeys.Variant.Caption}
        weight={FontKeys.Weight.Semibold}
        size={FontKeys.Size[size]}
        color={Colors.Sharp.White}
      />
    </RemainingCountOverlay>
  );
};

export const Thumbnail = ({
  image,
  highlight,
  onClick,
  noOfImages,
  index,
  type,
  amount,
}) => {
  return (
    <ThumbnailCSS onClick={onClick} type={type}>
      <Image src={image} />
      <ThumbnailHighlight highlight={highlight} type={type} />
      <RemainingCount
        noOfImages={noOfImages}
        index={index}
        type={type}
        amount={amount}
      />
    </ThumbnailCSS>
  );
};

export const Thumbnails = ({
  type,
  images,
  selected,
  select,
  amount,
  isLoading,
}) => {
  const noOfImages = images.length;

  const isHighlighted = hightlight(type);

  const handleClick = selectImage({ type, noOfImages, select });

  const thumbnails = displayImages({ type, images, selected });

  return (
    <ThumbnailsContainer type={type}>
      {thumbnails.map((image, index) => (
        <Thumbnail
          key={image + index}
          image={image}
          highlight={isHighlighted(index, selected)}
          onClick={avoidPropagation(handleClick(index, selected))}
          noOfImages={noOfImages}
          type={type}
          index={index}
          showLoading={index && isLoading}
          amount={amount}
        />
      ))}
      {!!thumbnails.length && isLoading && (
        <ThumbnailCSS>
          <ThumbnailLoader />
        </ThumbnailCSS>
      )}
    </ThumbnailsContainer>
  );
};

export const TransformationSeparator = () => {
  return (
    <TransformationSeparatorS>
      <Separator style={styles.separator} />
    </TransformationSeparatorS>
  );
};

export const MainSlider = ({ onClick, image }) => {
  return (
    <MainSliderContainer onClick={onClick}>
      <MainPreviewImageCSS>
        <Image src={image} />
      </MainPreviewImageCSS>
    </MainSliderContainer>
  );
};
