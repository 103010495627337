import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import ReferenceEntry from '../../../features_public/reference_index/controllers/ReferenceEntry';
import {
  newReferenceLayout,
  referenceIndexKeys,
  referenceInfoValidationSchema,
} from '../../../features_public/reference_index/controllers/variables';
import { useDeleteReference } from '../api/deleteReference';
import { useUpdateReference } from '../api/updateReference';
import { ReferenceInfoLayout } from '../components/ReferenceInfoLayout';
import { formikFormat } from './utils';
import { senderInfoInitValuesKeys as initValuesKeys } from './variables';

const ReferenceInfo = ({
  referenceInfo: referenceInfo_,
  referenceId,
  isAdmin,
  onEditSuccess,
  goBackTo,
}) => {
  const referenceInfo = formikFormat(referenceInfo_);

  const initialValues__ = {};
  initValuesKeys.forEach(
    key => (initialValues__[key] = referenceInfo[key] ?? '')
  );

  const [initialValues, setInitialValues] = useState({});

  useDeepCompareEffect(() => {
    const initialValues__ = {};
    initValuesKeys.forEach(
      key => (initialValues__[key] = referenceInfo[key] ?? '')
    );
    setInitialValues(initialValues__);
  }, [referenceInfo]);

  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { body, isLoading, isError, actions, doUpdate } = useUpdateReference({
    [referenceIndexKeys.id]: referenceId,
    isAdmin,
  });

  const {
    params: deleteParams,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
    doDelete,
  } = useDeleteReference({ [referenceIndexKeys.id]: referenceId });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      actions.resetForm({ values: initialValues });
      return;
    }
    showSnackbarSuccess('Changes saved.');
    setInitialValues(body.values);
  }, [body, isLoading, isError]);

  useEffect(() => {
    if (deleteIsLoading || deleteParams == null) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);
      return;
    }
    showSnackbarSuccess('Reference deleted.');
    goBackTo();
  }, [deleteIsLoading, deleteParams, deleteIsError]);

  const onClick = () => {
    showModal(ReferenceEntry, {
      data: referenceInfo_,
      isAdmin,
      onCreateSuccess: onEditSuccess,
    });
  };

  const showConfirmDelete = () => {
    showModal(ConfirmModal, {
      title: 'Delete reference',
      description: parseStringModifiers(`Are you sure you want to delete **${
        initialValues[referenceIndexKeys.name] ||
        initialValues[referenceIndexKeys.company] ||
        initialValues[referenceIndexKeys.address] ||
        'this reference'
      }?**
      
      **This cannot be undone.**`),
      confirm: 'Delete',
      onConfirm: handleConfirm,
      onCancel: dismiss,
    });
  };

  const handleConfirm = () => {
    doDelete();
    dismiss();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={referenceInfoValidationSchema}
      onSubmit={doUpdate}
      enableReinitialize
    >
      {() => {
        return (
          <Form>
            <ReferenceInfoLayout
              showConfirmDelete={showConfirmDelete}
              referenceInfo_={referenceInfo_}
              fields={newReferenceLayout.referenceFields}
              onClick={onClick}
              isAdmin={isAdmin}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReferenceInfo;
