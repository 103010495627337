import { Card } from '../../../../../components/atoms';
import { CardLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import AddProductWizard from '../controllers/AddProductWizard';
import CaseInfo from '../controllers/CaseInfo';
import { stepKeys } from '../controllers/variables';
import { InspectionStepHeader } from './organisms';
import { ParcelInspectionCSS } from './style';

export const ParcelInspectionLayout = ({
  stepTitle,
  buttons,
  step,
  stepTo,
  parcel,
  editProduct,
  productInitialValues,
  setSaveValues,
  validation,
  setValidation,
  goToAddProduct,
  parcelIsLoading,
  sidebarWidth,
  disabled,
}) => {
  const Display = getDisplayComponent(step);

  return (
    <ParcelInspectionCSS sidebarWidth={sidebarWidth}>
      <Card className="parcel-inspection">
        {parcelIsLoading ? (
          <CardLoader />
        ) : (
          <>
            <InspectionStepHeader stepTitle={stepTitle} buttons={buttons} />
            <Display
              step={step}
              stepTo={stepTo}
              parcel={parcel}
              editProduct={editProduct}
              values={productInitialValues}
              setSaveValues={setSaveValues}
              validation={validation}
              setValidation={setValidation}
              goToAddProduct={goToAddProduct}
              disabled={disabled}
            />
          </>
        )}
      </Card>
    </ParcelInspectionCSS>
  );
};

const getDisplayComponent = step => {
  switch (step) {
    case stepKeys.details:
      return CaseInfo;
    case stepKeys.selectBrand:
    case stepKeys.category:
    case stepKeys.itemQuantity:
    case stepKeys.photos:
    case stepKeys.summary:
      return AddProductWizard;
    default:
      throw Error('wrong step');
  }
};
