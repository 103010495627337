import usePostData from '../../../hooks/postHook';

export const useMarkAnnouncementRead = ({ id }) => {
  const [{ data = [], isLoading, isError, error }, doMark] = usePostData(
    `announcement/${id}/status`,
    { type: 'put' }
  );

  const doMarkAnnouncementRead = (status = 'Read') => {
    return doMark({ status });
  };

  return { data, isLoading, isError, error, doMarkAnnouncementRead };
};
