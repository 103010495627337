import { useToggle } from '@react-hookz/web';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import { cloneDeep, isEmpty, isOnlyEmptyValues } from '../../../../../lib/js';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import {
  referenceFields as _referenceFields,
  referenceFieldProps,
  referenceFieldsPlaceholders,
  referenceIndexKeys,
  referenceInfoValidationSchema,
} from '../../../../brand/features_public/reference_index/controllers/variables';
import { formatParams, useGetReferences } from '../api/getReferences';
import { searchComponents } from '../components';
import { ReferenceLayout } from '../components/ReferenceLayout';
import { DC, filtersConstants, searchGrid, searchHeaders } from './variables';

const referenceFields = cloneDeep(_referenceFields);
delete referenceFields[referenceIndexKeys.additionalDetails];

const { SELF, VIEW_PARCELS } = PermissionKeys.Admin.OFFICE_PARCELS;

const Reference = ({
  initialData,
  dbData,
  toggleCanSubmitInCurrentTab,
  referenceValuesRef,
}) => {
  const [search, setSearch] = useState('');
  const [showResults, toggleShowResults] = useToggle(false);

  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasEditPermission = hasPermission(VIEW_PARCELS);

  const {
    data: { references },
    isLoading,
    isError,
    doSearch,
  } = useGetReferences({
    searchParams: {},
  });

  useEffect(() => {
    if (search.length < 3) return;
    const searchParams = {
      [DC.SEARCH]: search,
      [DC.TIME_FRAME]: null,
      [DC.PAGE]: 1,
      [DC.ITEMS_PER_PAGE]: 10,
    };
    const params = formatParams(searchParams);
    doSearch({ params });
    toggleShowResults(true);
  }, [search]);

  const filtersState = {
    [DC.SEARCH]: {
      filter: setSearch,
      value: search,
      name: [DC.SEARCH],
    },
  };

  const filters = mergeFilterStateAndConsts({
    filtersState,
    filtersConstants,
  });

  const results = references;

  const description =
    'Search for an exact match. If none is available, input reference information below.';

  const isSameReferenceAsDb = values =>
    values[referenceIndexKeys.id] == dbData[referenceIndexKeys.id];

  const closeSearch = e => {
    toggleShowResults(false);
  };

  const handleSearchClick = e => {
    e.stopPropagation();
    if (search?.length && results?.length) toggleShowResults(true);
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={referenceInfoValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({
        isValid,
        setFieldValue,
        values,
        validateForm,
        resetForm,
        errors,
      }) => {
        const selectOption =
          ({ name, value }) =>
          _ =>
            setFieldValue(name, value);

        const selectReference = item => {
          Object.entries(item).forEach(([key, value]) => {
            setFieldValue(key, value);
          });
          toggleShowResults(false);
          setTimeout(() => validateForm(), 0);
        };

        const hadSender = dbData[referenceIndexKeys.id] != null;
        const isCleared = isOnlyEmptyValues(values);

        const customIsValid =
          (!hadSender && !isCleared && isValid) ||
          (hadSender &&
            ((isValid && !isSameReferenceAsDb(values)) || isCleared));

        if (isOnlyEmptyValues(values) && !isEmpty(errors)) {
          resetForm({ values, errors: null });
        }

        toggleCanSubmitInCurrentTab(customIsValid);
        referenceValuesRef.current = values;

        const senderIsSelected = !!values[referenceIndexKeys.id];
        const readOnly = senderIsSelected || !hasEditPermission;
        const disabled = dbData[referenceIndexKeys.id] != null;

        return (
          <ReferenceLayout
            searchProps={{
              filters,
              results,
              showResults,
              handleSearchClick,
              selectReference,
              headers: searchHeaders,
              components: searchComponents,
              grid: searchGrid,
              description,
              disabled,
            }}
            referenceInfoProps={{
              referenceFields,
              referenceFieldProps,
              selectOption,
              placeholder: referenceFieldsPlaceholders,
              readOnly,
            }}
            closeSearch={closeSearch}
          />
        );
      }}
    </Formik>
  );
};
export default Reference;
