import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects } from '../../style';

export const Container = styled.div`
  cursor: pointer;
  padding: ${({ padding }) => padding};
  background: ${({ backgroundColor }) =>
    backgroundColor ?? Colors.Neutral._200};
  border-radius: ${Effects.Border_Radius._S};
  .label {
    cursor: pointer;
    span,
    svg {
      transition: ${Effects.Transition.EaseInOut()} !important;
    }
  }
`;

export const Track = styled.div`
  position: relative;
  visibility: ${({ show }) => (show ? 'visibile' : 'hidden')};
  ${Styles.flexAroundCenter}
`;

export const LabelCSS = styled.div`
  z-index: 1;
  min-width: ${({ width }) => width}px;
`;

export const ActiveSwitch = styled.div`
  position: absolute;
  display: ${({ activeSlot, slots }) =>
    activeSlot >= 0 && activeSlot < slots ? 'block' : 'none'};
  background: ${({ color }) => color};
  width: ${({ slots }) => 100 / slots}%;
  height: 100%;
  left: calc(${({ activeSlot, slots }) => (activeSlot / slots) * 100}% + 0px);
  transition: ${Effects.Transition.EaseInOut('left')};
  border-radius: ${Effects.Border_Radius._S};
`;
