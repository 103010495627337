import styled from '@emotion/styled';
import { UtilityComponents } from '../../../constants';
export const Container = styled(UtilityComponents.Grid)`
  .in-view-menu {
    grid-column: 1 / 5;
  }
  .component {
    grid-column: 5 / 13;
    height: 100%;
  }
  ${({ styles }) => styles};
`;
