import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HeaderContext = createContext();

export function useHeader() {
  return useContext(HeaderContext);
}

const HeaderProvider = ({ children }) => {
  const location = useLocation();

  const [headerChildren, setHeaderChildren] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [headerIndex, setHeaderIndex] = useState(100);

  useEffect(() => {
    if (headerChildren == null) return;
    setShowHeader(true);
  }, [headerChildren]);

  useEffect(() => {
    setHeaderChildren(null);
  }, [location?.pathname]);

  return (
    <HeaderContext.Provider
      value={{
        headerChildren,
        showHeader,
        setHeaderChildren,
        setShowHeader,
        headerIndex,
        setHeaderIndex,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
