import { forwardRef } from 'react';
import AnimatedIcon from '../../molecules/AnimatedIcon/AnimatedIcon';
import { Icons } from '../Icon/Icon.options';
import { AddIconCSS } from './AddIcon.style';

export const AddIcon = forwardRef(
  ({ active, onClick, disabled, top, right, ...props }, ref) => {
    return (
      <AddIconCSS active={active} top={top} right={right} {...props} ref={ref}>
        <AnimatedIcon
          theme="neutral"
          name={Icons.PlusCircle}
          onClick={onClick}
          disabled={disabled}
        />
      </AddIconCSS>
    );
  }
);
