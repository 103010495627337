import { Colors, Size } from '../../style';
import Icon from '../Icon/Icon';
import { Icons } from '../Icon/Icon.options';
import { useInlineStyle } from './SidebarWidthToggle.hook';
import { HoverArea, Recipe } from './SidebarWidthToggle.style';

export const SidebarWidthToggle = ({ onClick, expanded, placement }) => {
  const coreStyle = Recipe('default');
  const [style, setStyle] = useInlineStyle(coreStyle, { expanded });

  const setHover = to => () => setStyle('hover', to);

  return (
    <HoverArea
      onClick={onClick}
      style={placement}
      onPointerOver={setHover(true)}
      onPointerOut={setHover(false)}
    >
      <div style={style}>
        <div style={style.leftCircle}></div>
        <div style={style.rectangle}></div>
        <div style={style.rightCircle}></div>
        <div style={style.arrow}>
          <Icon
            name={Icons.ChevronRight}
            color={Colors.Primary._600}
            size={Size.Icon._XS}
          />
        </div>
      </div>
    </HoverArea>
  );
};
