import { replaceFiles } from './utils';
import { DC, initialState } from './variables';

export const fileUploadReducer = (state, { type, payload }) => {
  switch (type) {
    case DC.FILES:
      return {
        ...state,
        files: replaceFiles(state.files, payload),
      };
    case DC.SUCCESS:
      return {
        ...state,
        errorType: null,
        isUploading: false,
        completedUpload: true,
        uploadSuccess: true,
      };
    case DC.UPLOAD:
      return {
        ...state,
        isUploading: true,
        completedUpload: false,
      };
    case DC.STORAGE_NAME:
      return {
        ...state,
        storageNames: [...state.storageNames, payload],
      };
    case DC.ERROR:
      return {
        ...state,
        errorType: payload,
        completedUpload: true,
        uploadSuccess: false,
        isUploading: false,
      };
    case DC.MORE_FILES:
      return {
        ...state,
        files: [...state.files, null],
      };
    case DC.LESS_FILES:
      return {
        ...state,
        files: state.files
          .slice(0, payload)
          .concat(state.files.slice(payload + 1)),
      };
    case DC.RESET:
      return initialState([]);
    default:
      throw Error('Invalid action type');
  }
};
