import useFetchData from '../../../../../hooks/fetchHook';
import { format } from '../formatters/formatRecentActivity';

const initialActivityLimit = 10;
const params_ = {
  limit: initialActivityLimit,
  offset: 0,
};

export const useGetRecentActivity = params => {
  const [{ data, isLoading, isError, error }, _, getActivity] = useFetchData(
    'support_tickets/activity',
    { formatter: format, params: params ?? params_ }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    getActivity,
  };
};
