import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { Evaluations } from './organisms';

const ParcelEvaluationLayout = ({
  evalProps,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={<Evaluations evalProps={evalProps} />}
    />
  );
};
export default ParcelEvaluationLayout;
