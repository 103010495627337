import {
  RowOptionsMenu,
  RowSimpleText,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { adminSortersKeys as keys } from '../../controllers/variables';

export const tableComponents = ({ options, optionClick }) => ({
  [keys.name]: {
    display: RowSimpleText(keys.name, 'bold'),
  },
  [keys.brand.name]: {
    display: User(keys.brand.name, keys.brand.image, { bold: true }),
  },
  [keys.city]: {
    display: RowSimpleText(keys.city),
  },
  [keys.country]: {
    display: RowSimpleText(keys.country, 'bold'),
  },
  [keys.responsible.name]: {
    display: User(keys.responsible.name, keys.responsible.image),
  },
  options: RowOptionsMenu({ options, optionClick }),
});
