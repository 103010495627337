import { ActingBrandSelection } from '../../../../../components/organisms/ActingBrandSelection/ActingBrandSelection';
import { FileDropzone } from '../../../../../components/organisms/FileDropzone/FileDropzone';
import { Popup } from '../../../../../components/templates/Popup';
import { Cancel, PrimaryAction, ToMainView } from './atoms/atoms';
import { Actions, AddReferenceContent, BottomRow } from './style';

export const ReferenceUploadLayout = ({
  backToAdd,
  disabled,
  fileState,
  handleDrop,
  infoIcon,
  onCancel,
  percentageUploaded,
  primaryClick,
  saveText,
  title,
  uploadStatusContent,
  actingBrandProps,
}) => {
  return (
    <Popup size="_M" title={title} element={infoIcon}>
      <AddReferenceContent>
        <div />
        <ActingBrandSelection {...actingBrandProps} />
        <FileDropzone
          handleDrop={handleDrop}
          fileState={fileState}
          percentageUploaded={percentageUploaded}
          uploadStatusContent={uploadStatusContent}
        />

        <BottomRow>
          <ToMainView backToAdd={backToAdd} />
          <Actions>
            <Cancel onCancel={onCancel} />
            <PrimaryAction
              onClick={primaryClick}
              text={saveText}
              disabled={disabled}
            />
          </Actions>
        </BottomRow>
      </AddReferenceContent>
    </Popup>
  );
};
