import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Button from '../../../../../../components/molecules/Button/Button';
import {
  CardLoader,
  TableLoader,
} from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import { defaultNoActivity } from '../../../../../../components/templates/TableStatus/variables';
import { Activity, RecentActivityTitle } from '../../../support/components';
import { getAuthenticationScoreTime } from '../../utils';
import { AchievementTitle } from '../atoms';
import {
  AchievementBadge,
  AchievementDescription,
  AchievementScore,
  ProductListContent,
  ProductsAuthSizer,
  ProductsListHeader,
  ProductsToAuth,
  RemainingCount,
  SlideChangeButtons,
} from '../molecules';
import {
  AuthenticationSliderContainer,
  ProductsListContainer,
  RecentActivityContainer,
} from '../style';

export const ProductsList = ({
  productListTabsProps,
  productListContentProps,
}) => {
  return (
    <ProductsListContainer className={'products-list'}>
      <ProductsListHeader productListTabsProps={productListTabsProps} />
      <ProductListContent productListContentProps={productListContentProps} />
    </ProductsListContainer>
  );
};

export const AuthenticationAchievement = ({
  data = {},
  isLoading,
  isError,
}) => {
  const {
    average_authentication_time,
    avg_authentication_time_comparison,
    medal,
  } = data;
  const { hours, minutes } = average_authentication_time ?? {};
  const delta =
    avg_authentication_time_comparison?.comparison.avg_time?.percentage;
  const time = getAuthenticationScoreTime(hours, minutes);
  const { description, title, illustration } = medal ?? {};

  return (
    <Card className={'authentication-achivement'}>
      {isLoading ? (
        <CardLoader />
      ) : (
        <Flex column w gap="_XS">
          <AchievementTitle text={'Average authentication time'} />
          <AchievementScore time={time} delta={delta} />
          <AchievementDescription
            description={isError ? undefined : description}
            title={isError ? undefined : title}
          />
        </Flex>
      )}
      <AchievementBadge illustration={illustration} />
    </Card>
  );
};

export const RecentActivity = ({ recentActivityProps }) => {
  const { show, isLoading, moreActivityClicked, loadMoreActivity, activity } =
    recentActivityProps;

  return (
    !!show && (
      <RecentActivityContainer className="recent-activity">
        <RecentActivityTitle text="Recent activity" />
        {isLoading && !moreActivityClicked ? (
          <TableLoader />
        ) : activity == null || activity.length === 0 ? (
          <NoSearchResults {...defaultNoActivity} />
        ) : (
          <Activity {...recentActivityProps} />
        )}
        {!moreActivityClicked &&
          !isLoading &&
          activity &&
          !!activity.length && (
            <Button
              variant="Secondary"
              text="Show more"
              fullWidth
              size="_M"
              onClick={loadMoreActivity}
            />
          )}
      </RecentActivityContainer>
    )
  );
};

export const AuthenticationSlider = props => {
  return (
    <AuthenticationSliderContainer
      column
      gap="_M"
      h
      align="center"
      className={'authentication-slider'}
    >
      <RemainingCount {...props} />
      <ProductsCarousel {...props} />
    </AuthenticationSliderContainer>
  );
};

const ProductsCarousel = props => {
  const { authSliderState } = props;
  return (
    <Flex gap="_S" h w relative>
      <ProductsAuthSizer {...authSliderState} />
      <ProductsToAuth {...authSliderState} {...props} />
      <SlideChangeButtons {...authSliderState} {...props} />
    </Flex>
  );
};
