import styled from '@emotion/styled';
import { Icon } from '../../../components/atoms';
import { Flex } from '../../../components/atoms/Flex/Flex';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import {
  sidebarBackgroundColor,
  sidebarIconDefaultColor,
} from '../../../components/organisms/Sidebar/Sidebar.style';
import { Colors, Effects, UtilityEffects } from '../../../components/style';
import { PermissionKeys } from '../../../providers/PermissionProvider/PermissionsProvider';
import { ParcelEvaluationRoutes } from '../../brand/features/parcel_evaluation';
import {
  AdminBrandsDetailsRoutes,
  AdminBrandsRoutes,
} from '../features/admin_brands';
import { AdminCategoriesRoutes } from '../features/admin_categories';
import {
  AdminReferencesDetailsRoutes,
  AdminReferencesRoutes,
} from '../features/admin_references';
import { AdminServicesRoutes } from '../features/admin_services';
import { AdminSortersRoutes } from '../features/admin_sorters';
import { DashboardRoutes } from '../features/dashboard';
import {
  KnowledgeBaseDetailsRoutes,
  KnowledgeBaseRoutes,
} from '../features/knowledge_base/controllers';
import { CasesRoutes } from '../features/parcels/controllers';
import { ProductDetailsRoutes, ProductsRoutes } from '../features/products';
import { SorterDataRoutes } from '../features/sorter_data';
import { SupportDetailRoutes, SupportRoutes } from '../features/support';
import { Labels, Pages, URIs } from './variables';

const showAdminIndicator = ({ selected, expanded, hovered }) => (
  <AdminIndicator selected={selected} expanded={expanded} hovered={hovered} />
);

const {
  ADMIN_BRANDS,
  ADMIN_CATEGORIES,
  ADMIN_SERVICES,
  ADMIN_SORTERS,
  ADMIN_REFERENCES,
  OFFICE_REVIEW,
  OFFICE_PARCELS,
  OFFICE_PRODUCTS,
  OFFICE_KNOWLEDGE_BASE,
  OFFICE_SUPPORT,
  OFFICE_DASHBOARD,
  OFFICE_SORTER_DATA,
} = PermissionKeys.Admin;

export const children = [
  {
    path: `${URIs[Pages.data]}/*`,
    label: Labels[Pages.data],
    sidebarIcon: Icons.Data,
    service: OFFICE_SORTER_DATA.SELF,
    customElement: showAdminIndicator,
    element: <SorterDataRoutes />,
  },
  {
    path: `${URIs[Pages.dashboard]}/*`,
    label: Labels[Pages.dashboard],
    sidebarIcon: Icons.Dashboard,
    service: OFFICE_DASHBOARD.SELF,
    customElement: showAdminIndicator,
    element: <DashboardRoutes />,
  },
  {
    path: `${URIs[Pages.parcel_evaluation]}/*`,
    label: Labels[Pages.parcel_evaluation],
    sidebarIcon: Icons.SearchCheck,
    service: OFFICE_REVIEW.SELF,
    customElement: showAdminIndicator,
    element: <ParcelEvaluationRoutes />,
  },
  {
    path: `${URIs[Pages.parcels]}/*`,
    label: Labels[Pages.parcels],
    sidebarIcon: Icons.Parcels,
    service: OFFICE_PARCELS.SELF,
    customElement: showAdminIndicator,
    element: <CasesRoutes />,
  },
  {
    path: `${URIs[Pages.products]}/*`,
    label: Labels[Pages.products],
    sidebarIcon: Icons.Products,
    service: OFFICE_PRODUCTS.SELF,
    customElement: showAdminIndicator,
    element: <ProductsRoutes />,
  },
  {
    path: `${URIs[Pages.products]}/:productId/*`,
    sidebarIcon: null,
    service: OFFICE_PRODUCTS.SELF,
    permission: OFFICE_PRODUCTS.VIEW_PRODUCTS,
    customElement: showAdminIndicator,
    element: <ProductDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.knowledge_base]}/*`,
    label: Labels[Pages.knowledge_base],
    sidebarIcon: Icons.KnowledgeBase,
    service: OFFICE_KNOWLEDGE_BASE.SELF,
    customElement: showAdminIndicator,
    element: <KnowledgeBaseRoutes />,
  },
  {
    path: `${URIs[Pages.knowledge_base]}/:instructionId/*`,
    sidebarIcon: null,
    service: OFFICE_KNOWLEDGE_BASE.SELF,
    permission: OFFICE_KNOWLEDGE_BASE.VIEW_KNOWLEDGE_BASE,
    customElement: showAdminIndicator,
    element: <KnowledgeBaseDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.support]}/*`,
    label: Labels[Pages.support],
    sidebarIcon: Icons.Support,
    service: OFFICE_SUPPORT.SELF,
    customElement: ({ selected, expanded, hovered, unreadCount }) => {
      return (
        <Flex>
          <AdminIndicator
            selected={selected}
            expanded={expanded}
            hovered={hovered}
          />
          {!!unreadCount && <HasUnreadSupportTickets selected={selected} />}
        </Flex>
      );
    },
    element: <SupportRoutes />,
  },
  {
    path: `${URIs[Pages.support]}/:ticketId/*`,
    sidebarIcon: null,
    service: OFFICE_SUPPORT.SELF,
    permission: [
      OFFICE_SUPPORT.VIEW_GENERAL_SUPPORT_TICKETS,
      OFFICE_SUPPORT.VIEW_PARCEL_SUPPORT_TICKETS,
      OFFICE_SUPPORT.VIEW_PRODUCT_SUPPORT_TICKETS,
    ],
    element: <SupportDetailRoutes />,
  },
  {
    path: `${URIs[Pages.admin_brands]}/*`,
    label: Labels[Pages.admin_brands],
    sidebarIcon: Icons.Building,
    service: ADMIN_BRANDS.SELF,
    customElement: showAdminIndicator,
    element: <AdminBrandsRoutes />,
  },
  {
    path: `${URIs[Pages.admin_brands]}/:brandId/*`,
    sidebarIcon: null,
    service: ADMIN_BRANDS.SELF,
    customElement: showAdminIndicator,
    element: <AdminBrandsDetailsRoutes />,
  },
  {
    path: `${URIs[Pages.admin_categories]}/*`,
    label: Labels[Pages.admin_categories],
    sidebarIcon: Icons.Tag,
    service: ADMIN_CATEGORIES.SELF,
    customElement: showAdminIndicator,
    element: <AdminCategoriesRoutes />,
  },
  {
    path: `${URIs[Pages.admin_services]}/*`,
    label: Labels[Pages.admin_services],
    sidebarIcon: Icons.Component,
    service: ADMIN_SERVICES.SELF,
    customElement: showAdminIndicator,
    element: <AdminServicesRoutes />,
  },
  {
    path: `${URIs[Pages.admin_sorters]}/*`,
    label: Labels[Pages.admin_sorters],
    sidebarIcon: Icons.Factory,
    service: ADMIN_SORTERS.SELF,
    customElement: showAdminIndicator,
    element: <AdminSortersRoutes />,
  },
  {
    path: `${URIs[Pages.admin_references]}/*`,
    label: Labels[Pages.admin_references],
    sidebarIcon: Icons.ListChecks,
    service: ADMIN_REFERENCES.SELF,
    customElement: showAdminIndicator,
    element: <AdminReferencesRoutes />,
  },
  {
    path: `${URIs[Pages.admin_references]}/:referenceId/*`,
    label: Labels[Pages.admin_references],
    sidebarIcon: null,
    service: ADMIN_REFERENCES.SELF,
    permission: ADMIN_REFERENCES.VIEW_REFERENCES,
    customElement: showAdminIndicator,
    element: <AdminReferencesDetailsRoutes />,
  },
];

const HasUnreadSupportTickets = styled.div`
  width: 6px;
  height: 6px;
  background: ${({ selected }) =>
    selected ? sidebarBackgroundColor : Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius.Circle};
  position: absolute;
  top: 8px;
  left: 6px;
`;

const AdminIndicatorCSS = styled.div`
  position: absolute;
  top: 4px;
  right: calc(4px + ${({ expanded }) => (expanded ? '8px' : '0px')});
  transition: ${UtilityEffects.SidebarTransition('right')};
  svg {
    min-width: 6px;
  }
`;

const AdminIndicator = ({ selected, expanded, hovered }) => {
  return (
    <AdminIndicatorCSS expanded={expanded}>
      <Icon
        name={Icons.AdminIndicator}
        color={
          selected
            ? sidebarBackgroundColor
            : hovered
            ? Colors.Sharp.White
            : sidebarIconDefaultColor
        }
      />
    </AdminIndicatorCSS>
  );
};
