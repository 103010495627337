import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Layout } from '../../../../../components/style';
import { stepKeys } from '../controllers/variables';
import { AddProductStepIndicator } from './organisms';
import { BrandSelect } from './page/BrandSelect';
import { CategorySelect } from './page/CategorySelect';
import { ItemQuantityInput } from './page/ItemQuantityInput';
import { ProductPhotos } from './page/ProductPhotos';
import { ProductSummary } from './page/ProductSummary';
import { AddProductWizardContainer } from './style';

export const AddProductWizardLayout = ({
  step,
  values,
  displayValues,
  options,
  validation,
  stepTo,
  getRef,
  setRef,
  disabled,
  typeNumber,
  deleteNumber,
  toggleNumbersSelected,
  numbersSelected,
  productPhotosProps,
  selectBrand,
  selectCateogry,
}) => {
  const Display = getDisplayComponent(step);
  return (
    <AddProductWizardContainer>
      <Flex
        justify="between"
        gap="_XS"
        h
        grow
        padding={` ${Layout.Spacing._M} 0`}
      >
        <AddProductStepIndicator
          step={step}
          displayValues={displayValues}
          stepTo={stepTo}
          validation={validation}
        />
        <Flex grow className="display-container">
          <Flex w column>
            <Display
              values={values[step]}
              options={options[step]}
              getRef={getRef}
              setRef={setRef}
              typeNumber={typeNumber}
              deleteNumber={deleteNumber}
              toggleNumbersSelected={toggleNumbersSelected}
              numbersSelected={numbersSelected}
              {...productPhotosProps}
              selectBrand={selectBrand}
              selectCateogry={selectCateogry}
              disabled={disabled}
            />
          </Flex>
        </Flex>
      </Flex>
    </AddProductWizardContainer>
  );
};

const getDisplayComponent = step => {
  switch (step) {
    case stepKeys.selectBrand:
      return BrandSelect;
    case stepKeys.category:
      return CategorySelect;
    case stepKeys.itemQuantity:
      return ItemQuantityInput;
    case stepKeys.photos:
      return ProductPhotos;
    case stepKeys.summary:
      return ProductSummary;
    default:
      throw Error('wrong step');
  }
};
