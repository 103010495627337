import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { envVars } from '../../constants';
import { useAuth } from '../../providers/AuthProvider';
import { NavURIs, Pages } from '../../routes/variables';
import { verify2FAcode } from '../api/verify2FAcode';
import Verify2FALayout from '../components/Verify2FALayout';
import { Fields, FormValues, Texts } from './variables';

const { environment } = envVars;

const { verify2FA, login2FA, setup2FA } = Pages;
const { [login2FA]: loginRoute, [setup2FA]: setup2FAroute } = NavURIs;
const {
  title,
  subtitle,
  codePlaceholder,
  codeMaxLength,
  minorButtonText,
  mainButtonText,
  minorRightIcon,
} = Texts[verify2FA];

const { initialValues, validationSchema } = FormValues[verify2FA];

const { codeField } = Fields[verify2FA];

const ignoredAuthCodes = ['local', 'dev'];

const Verify2FA = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [credentials, setCredentials, { initVerifiedUser }] = useAuth();

  const { authCode, userId } = credentials;
  const { authType } = state ?? {};

  useEffect(() => {
    if (authType == null || userId == null) {
      const to = authType ? loginRoute : setup2FAroute;
      navigate(to);
    }
  }, []);

  const minorButtonClick = () => {
    const to = setup2FAroute;
    navigate(to);
  };

  const onSubmit = async ({ code }) => {
    const key = ignoredAuthCodes.includes(environment) ? authCode : code;

    const response = await verify2FAcode({ user_id: userId, key });
    initVerifiedUser({ response });
  };

  return (
    <Verify2FALayout
      title={title}
      subtitle={subtitle(authType)}
      codePlaceholder={codePlaceholder}
      codeName={codeField}
      codeMaxLength={codeMaxLength}
      minorButtonClick={minorButtonClick}
      minorButtonText={minorButtonText}
      mainButtonText={mainButtonText}
      minorRightIcon={minorRightIcon}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
export default Verify2FA;
