import { Route, Routes } from 'react-router-dom';
import SorterData from './SorterData';

export const SorterDataRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SorterData />} />
    </Routes>
  );
};
