import { CarouselDot, Dots } from './style';

const SlideDots = ({ stepTo, activeIndex, amount, ...props }) => {
  const Dot = props.CustomDot ?? CarouselDot;

  return (
    <Dots justify="center" align="center" gap="_2XS" {...props}>
      {Array(amount)
        .fill('')
        .map((_, index) => (
          <Dot
            key={index}
            selected={index === activeIndex}
            onClick={() => stepTo(index)}
          />
        ))}
    </Dots>
  );
};
export default SlideDots;
