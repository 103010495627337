import logo from '../../../assets/img/logo.png';
import { includeConstants } from '../../../utils/styleUtils';
import { sidebarLogoSize } from '../../organisms/Sidebar/Sidebar.style';
import { Colors, Effects, Layout } from '../../style';

const constantStyle = {};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  brand: ({ src = '', height, transition, scrolled, border, selected }) => {
    return {
      src,
      height,
      width: height,
      transition,
      borderRadius: Effects.Border_Radius._S,
      objectFit: 'contain',
      border: border
        ? `1px solid ${selected ? Colors.Primary._600 : Colors.Neutral._300}`
        : '',
    };
  },
  auth: () => {
    return {
      src: logo,
      height: '24px',
      cursor: 'pointer',
      position: 'absolute',
      top: Layout.Spacing._M,
      left: Layout.Spacing._M,
    };
  },
  sidebar: ({ src = '' }) => {
    return {
      src,
      minHeight: sidebarLogoSize,
      maxHeight: sidebarLogoSize,
      minWidth: sidebarLogoSize,
      maxWidth: sidebarLogoSize,
      objectFit: 'contain',
      background: Colors.Sharp.White,
      borderRadius: Effects.Border_Radius._S,
    };
  },
};
