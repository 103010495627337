import { useState } from 'react';
import { numWithCommas } from '../../../utils/utils';
import { Icon, Text } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, FontKeys, Size } from '../../style';

const ellipsis = '...';

export const PageSelection = ({ numbers, page, handlePagination, style }) => {
  return (
    <div style={style.pageSelection}>
      <Arrow
        side={Icons.ChevronLeft}
        style={style.arrow}
        onClick={handlePagination(page - 1)}
      />
      {numbers.map((number, index) => {
        const isEllipsis = number === ellipsis;
        const selected = page === number;
        return (
          <div
            style={style.page({
              isEllipsis,
              selected,
            })}
            onClick={handlePagination(number)}
            key={number + index}
          >
            <Text
              text={numWithCommas(number) ?? number}
              variant={FontKeys.Variant.Paragraph}
              size={FontKeys.Size._S}
              weight={FontKeys.Weight.Semibold}
              color={style.page({ selected, isEllipsis }).color}
            />
          </div>
        );
      })}
      <Arrow
        side={Icons.ChevronRight}
        style={style.arrow}
        onClick={handlePagination(page + 1)}
      />
    </div>
  );
};

const Arrow = ({ side, style, onClick }) => {
  return (
    <div style={style} onClick={onClick}>
      <Icon name={side} />
    </div>
  );
};

export const NoOfResults = ({
  style,
  toggleShowPageOptions,
  changeItemsPerPage,
  itemsPerPage,
  options,
  resultsRef,
}) => {
  const [optionHovered, setOptionHovered] = useState(-1);
  const clearHovered = () => setOptionHovered(-1);
  const handleHover = index => () => setOptionHovered(index);

  return (
    <div style={style.noOfResults} ref={resultsRef}>
      <Text
        text={'Results per page'}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
        color={Colors.Neutral._600}
      />
      <div style={style.selectionBox} onClick={toggleShowPageOptions}>
        <Text
          text={itemsPerPage}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._S}
          weight={FontKeys.Weight.Medium}
          color={Colors.Neutral._600}
        />
        <Icon
          name={Icons.ChevronDown}
          size={Size.Icon._S}
          color={Colors.Neutral._600}
        />
        <div style={style.itemsPerPageOptions} onPointerLeave={clearHovered}>
          {options.map((option, index) => {
            const selected = itemsPerPage === option;
            const hover = optionHovered === index;
            const _style = style.option({ selected, hover });
            return (
              <div
                style={_style}
                onClick={changeItemsPerPage(option)}
                key={option}
                onPointerOver={handleHover(index)}
              >
                <Text
                  text={option}
                  variant={FontKeys.Variant.Paragraph}
                  size={FontKeys.Size._S}
                  weight={_style.fontWeight}
                  color={Colors.Neutral._600}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
