import Dropzone from 'react-dropzone';
import { Flex } from '../../atoms/Flex/Flex';
import {
  DropzoneClickarea,
  DropzoneCSS,
  FileStatus,
} from './FileDropzone.style';

export const FileDropzone = ({
  handleDrop,
  fileState,
  percentageUploaded,
  uploadStatusContent,
  multiple = true,
  children,
  disabled: forceDisabled,
  ...props
}) => {
  const { isUploading, completedUpload } = fileState;

  const disabled = isUploading || completedUpload || forceDisabled;
  return (
    <Dropzone onDrop={handleDrop} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <DropzoneCSS
          {...props}
          className={`file-dropzone ${props.className ?? ''}`}
        >
          <DropzoneClickarea {...getRootProps()} disabled={disabled}>
            <input {...getInputProps()} multiple={multiple} />
            <Flex justify="center" align="center" w>
              <FileStatus
                isUploading={isUploading}
                uploadStatusContent={uploadStatusContent}
                percentageUploaded={percentageUploaded}
              />
            </Flex>
          </DropzoneClickarea>
          {children}
        </DropzoneCSS>
      )}
    </Dropzone>
  );
};
