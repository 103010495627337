import usePostData from '../../../../../hooks/postHook';

export const useAddBarcodes = ({ id }) => {
  const [{ body, isLoading, isError, error }, doAddBarcodes] = usePostData(
    `office_parcel/${id}/barcodes`,
    {}
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doAddBarcodes,
  };
};
