import { Centering, Container } from './style';

const ViewFooter = ({ children }) => {
  return (
    <Container>
      <Centering>{children}</Centering>
    </Container>
  );
};

export default ViewFooter;
