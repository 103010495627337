import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Button from '../../../../../../components/molecules/Button/Button';
import FloatingMenu from '../../../../../../components/molecules/FloatingMenu/FloatingMenu';
import ToggleText from '../../../../../../components/molecules/ToggleText/ToggleText';
import { StackedAvatars } from '../../../../../../components/organisms/StackedAvatars/StackedAvatars';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  ExpansionArrow,
  RowAction,
  RowFormalDate,
  RowHumanReadableDate,
  RowLabel,
  RowSimpleText,
  Space,
  User,
  runIfHasProducts,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { clickedBy, parseStringModifiers } from '../../../../../../utils/utils';
import {
  contractKeys,
  invoiceKeys,
  usersKeys,
} from '../../controllers/variables';
import {
  PlanDetailText,
  ServiceDescriptionText,
  ServiceNameText,
} from '../atoms/atoms';
import {
  AllServicesContainer,
  PlanDetailCSS,
  ServicePermissionContainer,
  UserServicesSubtableCSS,
} from '../style';

export const PlanDetails = ({ planDetails }) => {
  return (
    <Flex column>
      {planDetails.map(detail => (
        <PlanDetail detail={detail} key={detail} />
      ))}
    </Flex>
  );
};

const PlanDetail = ({ detail }) => {
  return (
    <PlanDetailCSS>
      <Icon
        name={Icons.CheckCircle}
        size={Size.Icon._S}
        color={Colors.Primary._600}
      />
      <PlanDetailText text={detail} />
    </PlanDetailCSS>
  );
};

export const invoicesTableComponents = {
  [invoiceKeys.leftPadding]: { display: Space },
  [invoiceKeys.alias]: {
    display: RowSimpleText(
      [invoiceKeys.alias, invoiceKeys.invoiceName],
      'bold',
      true
    ),
  },
  [invoiceKeys.date]: { display: RowSimpleText(invoiceKeys.date) },
  [invoiceKeys.amount]: { display: RowSimpleText(invoiceKeys.amount, 'bold') },
  [invoiceKeys.download]: { display: RowAction({ icon: Icons.Download }) },
};

export const Services = item => {
  const { [usersKeys.services]: services } = item;

  return <UserServices services={services} />;
};

const OptionsMenu =
  ({ handleAction, options }) =>
  item => {
    const refId = item[usersKeys.id];
    const clickareaRef = document.querySelector(`#clickarea${refId}`);

    const stopPropagation = e => {
      if (clickedBy(clickareaRef, e)) e.stopPropagation();
    };

    return (
      <div id={`clickarea${refId}`} onClick={stopPropagation}>
        <FloatingMenu
          options={options}
          optionClick={handleAction(item)}
          optionDisplay={({ label, icon }) => {
            return (
              <Button
                variant="Tertiary"
                size="_S"
                text={label}
                leftIcon={icon}
                black
                // cursor pointer
                onClick={Utils.emptyFunction}
              />
            );
          }}
        >
          <Icon
            name={Icons.MoreVertical}
            color={Colors.Neutral._500}
            size={Size.Icon._M}
          />
        </FloatingMenu>
      </div>
    );
  };

export const usersTableComponents = ({
  handleAction,
  options,
  expansionHeaders,
  expansionGrid,
}) => ({
  [usersKeys.expansionArrow]: {
    display: ExpansionArrow({ countKey: usersKeys.services }),
    action: runIfHasProducts({
      action: Utils.expandRow,
      countKey: usersKeys.services,
    }),
  },
  [usersKeys.user]: {
    display: User(usersKeys.name, usersKeys.image, { bold: true, blue: true }),
  },
  [usersKeys.email]: { display: RowSimpleText(usersKeys.email, 'bold') },
  [usersKeys.services]: { display: Services },
  [usersKeys.actions]: options?.length
    ? {
        display: OptionsMenu({ handleAction, options }),
        action: Utils.avoidRowExpand,
      }
    : { display: () => <></> },
  expand: UserServicesSubtable({
    headers: expansionHeaders,
    grid: expansionGrid,
    components: userServiceTableComponents,
  }),
});

export const UserServicesSubtable =
  ({ headers, grid, components }) =>
  item => {
    if (item == null) return <></>;
    return (
      <UserServicesSubtableCSS>
        <Table
          headers={headers}
          grid={grid}
          data={item?.services ?? []}
          components={components}
        />
      </UserServicesSubtableCSS>
    );
  };

const userServiceTableComponents = {
  [usersKeys.leftPadding]: { display: Space },
  [usersKeys.service.title]: {
    display: RowLabel(usersKeys.service.title, {
      iconLeft: usersKeys.service.icon,
    }),
  },
  [usersKeys.service.dateAdded]: {
    display: RowHumanReadableDate(usersKeys.service.dateAdded),
  },
  [usersKeys.service.addedBy.image]: {
    display: User(
      usersKeys.service.addedBy.name,
      usersKeys.service.addedBy.image
    ),
  },
  [usersKeys.service.addedBy.name]: {
    display: RowSimpleText(usersKeys.service.addedBy.name),
  },
  [usersKeys.service.role]: {
    display: RowLabel(usersKeys.service.role, {
      size: '_XS',
      hasBorder: true,
      noPadding: false,
    }),
  },
};

export const contractsTableComponents = ({ downloadContract }) => ({
  [contractKeys.leftPadding]: { display: Space },
  [contractKeys.name]: {
    display: RowSimpleText(contractKeys.name, 'bold', true),
  },
  [contractKeys.date]: { display: RowFormalDate(contractKeys.date) },
  [contractKeys.download]: {
    display: RowAction({ action: downloadContract, icon: Icons.Download }),
  },
});

const UserServices = ({ services = [] }) => {
  const noMaxIcons = 6;
  const avatars = services.slice(0, noMaxIcons).map(s => ({
    icon: {
      name: s[usersKeys.service.icon],
      size: Size.Icon._S,
      color: Colors.Primary._600,
    },
    background: Colors.Neutral._100,
  }));

  if (services.length > noMaxIcons)
    avatars[noMaxIcons - 1] = {
      background: Colors.Primary._600,
      text: `+${services.length - noMaxIcons + 1}`,
    };

  return <StackedAvatars avatars={avatars} size={'_XS'} />;
};

export const ServicePermissions = ({ service, index, update, isLast }) => {
  const {
    is_enabled: servicePurchased,
    title,
    icon,
    detail: serviceDescription,
    roles,
    selectedRole = {},
  } = service;

  const { key, description: roleDescription, id: roleId } = selectedRole;

  const serviceIsActive = (servicePurchased && roleId != null) || true;

  return (
    // should be a container for border
    <ServicePermissionContainer enabled={serviceIsActive}>
      {/* <ServiceActive
        enabled={serviceIsActive}
        onClick={update(
          index,
          'selectedRole',
          serviceIsActive
            ? { ...selectedRole, id: null }
            : roles[roles.length - 1]
        )}
      /> */}
      <ServiceOverview
        title={title}
        icon={icon}
        serviceDescription={parseStringModifiers(serviceDescription)}
        roleDescription={
          serviceIsActive && parseStringModifiers(roleDescription)
        }
        enabled={serviceIsActive}
        toggle={
          <ToggleText
            activeIndex={
              serviceIsActive ? roles.findIndex(r => r.key === key) : -1
            }
            select={(_, roleIndex) =>
              serviceIsActive
                ? update(index, 'selectedRole', roles[roleIndex])
                : Utils.emptyFunction
            }
            options={roles}
            size="_S"
            indicatorBg={Colors.Primary._600}
            indicatorTextColor={Colors.Sharp.White}
            indicatorInactiveTextColor={Colors.Neutral._900}
          />
        }
      />
      {!isLast ? <Separator /> : ''}
    </ServicePermissionContainer>
  );
};

const allServicesText =
  'Select this option to enable access to all services with the given permission.';

export const AllServices = ({
  enabled,
  enableAll,
  roles,
  selectedRole,
  selectRoleAll,
}) => {
  return (
    <AllServicesContainer enabled={enabled}>
      {/* <ServiceActive enabled={enabled} onClick={enableAll} /> */}
      <Flex align={'center'} margin=" auto 0">
        <ServiceNameText text={'All services'} enabled={enabled} black />
        {/* <ServiceDescriptionText text={allServicesText} enabled={enabled} /> */}
      </Flex>
      <ToggleText
        activeIndex={
          enabled ? roles.findIndex(r => r.key === selectedRole?.key) : -1
        }
        select={role => (enabled ? selectRoleAll(role) : Utils.emptyFunction)}
        options={roles.map(r => ({ ...r, text: r.title }))}
        size="_S"
        indicatorBg={Colors.Primary._600}
        indicatorTextColor={Colors.Sharp.White}
        indicatorInactiveTextColor={Colors.Neutral._900}
      />
    </AllServicesContainer>
  );
};

const ServiceOverview = ({
  title,
  icon,
  serviceDescription,
  roleDescription,
  enabled,
  toggle,
}) => {
  return (
    <Flex column gap="_S" w relative>
      <Flex w justify={'between'}>
        <Flex gap="_XS" align="center">
          <Icon
            name={icon}
            size={Size.Icon._L}
            color={enabled ? Colors.Primary._600 : Colors.Neutral._500}
          />
          <ServiceNameText text={title} enabled={enabled} />
        </Flex>
        {toggle}
      </Flex>
      <Flex column w gap="_2XS">
        {/* <ServiceDescriptionText text={serviceDescription} enabled={enabled} /> */}
        <ServiceDescriptionText text={roleDescription} enabled={enabled} />
      </Flex>
    </Flex>
  );
};
