import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Image } from '../../../../../../components/atoms/Image/Image';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import Button from '../../../../../../components/molecules/Button/Button';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import { Colors, Size } from '../../../../../../components/style';
import { Prose } from '../../../../../../constants';
import {
  openNewTab,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { FieldContent, FieldLabel } from '../atoms';
import {
  ButtonFieldCSS,
  EvaluationCSS,
  FieldContainer,
  ImageFieldCSS,
  ParcelImageCSS,
  TextFieldCSS,
} from '../style';

export const Evaluation = ({
  item,
  handleButtonClick,
  showGallery,
  actionIsLoading,
  hasEditPermission,
}) => {
  return (
    <EvaluationCSS column gap="_S" relative>
      <Flex column gap="_2XS">
        {item.body.map((field, index) => {
          switch (field.type) {
            case 'text':
              return <TextField key={index} body={field.body} />;
            case 'image':
              return (
                <ImageField body={field.body} onClick={showGallery(item.id)} />
              );
            case 'button':
              return (
                <ButtonField
                  body={field.body}
                  actionIsLoading={actionIsLoading}
                  hasEditPermission={hasEditPermission}
                  handleButtonClick={handleButtonClick}
                />
              );
            default:
              return <></>;
          }
        })}
      </Flex>
    </EvaluationCSS>
  );
};

const TextField = ({ body }) => {
  if (body.length === 0) return;
  return (
    <TextFieldCSS gap="_XS">
      {body.map(({ title, content, customColor, link }, index) => {
        const onClick = link ? () => openNewTab(link) : undefined;
        const isClickable = !!onClick;
        return (
          <FieldContainer column onClick={onClick} isClickable={isClickable}>
            <Flex gap="_2XS" align={'center'}>
              <FieldLabel text={title} />
              {onClick ? (
                <Icon
                  name={Icons.ExternalLink}
                  size={Size.Icon._S}
                  color={Colors.Neutral._500}
                />
              ) : null}
            </Flex>
            <FieldContent
              text={parseStringModifiers(content)}
              color={customColor}
            />
          </FieldContainer>
        );
      })}
    </TextFieldCSS>
  );
};

const ImageField = ({ body, onClick }) => {
  if (body.length === 0) return;
  return (
    <>
      <Spacer size="_S" />
      <ImageFieldCSS gap="_XS">
        {body.map((image, index) => {
          return (
            <ParcelImageCSS onClick={onClick}>
              <Image src={image.source} alt="Review Image" />
            </ParcelImageCSS>
          );
        })}
      </ImageFieldCSS>
      <Spacer size="_S" />
    </>
  );
};

const ButtonField = ({
  body,
  handleButtonClick,
  actionIsLoading,
  hasEditPermission,
}) => {
  if (body.length === 0) return;
  return (
    <>
      <Separator style={{ position: 'relative' }} />
      <Spacer size="_S" />
      <ButtonFieldCSS gap="_XS">
        {body.map((button, index) => {
          return (
            <Tooltip content={hasEditPermission ? '' : Prose.noPermission}>
              <Button
                key={index}
                size="_L"
                fullWidth
                isLoading={actionIsLoading}
                disabled={!hasEditPermission || actionIsLoading}
                {...button}
                onClick={handleButtonClick(button)}
              />
            </Tooltip>
          );
        })}
      </ButtonFieldCSS>
      <Spacer size="_S" />
    </>
  );
};
