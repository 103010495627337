import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import { timeFrameInitialFilterValues } from '../../../../components/organisms/Filters/utils';
import { FilterTypes } from '../../../../components/organisms/Filters/variables';
import { Colors } from '../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../constants';

export const DC = {
  ...DISPATCH_CONSTANTS,
  CATEGORY: 'CATEGORY',
  SUBCATEGORY: 'SUBCATEGORY',
};

export const filtersConstants = {
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    initial: timeFrameInitialFilterValues.last28days,
  },
  [DC.CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Filter,
  },
  [DC.SUBCATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Filter,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const titles = {
  [DC.TIME_FRAME]: '',
  [DC.CATEGORY]: 'Categories',
  [DC.SUBCATEGORY]: 'Subcategories',
};

export const allOptions = {
  [DC.CATEGORY]: { label: 'All', value: Constants.all },
  [DC.SUBCATEGORY]: { label: 'All', value: Constants.all },
};

export const initialFilterValues = {
  [DC.CATEGORY]: '',
  [DC.SUBCATEGORY]: '',
};

export const colors = [
  Colors.Primary._400,
  Colors.Warning._500,
  Colors.Warning._900,
  Colors.Error._500,
  Colors.Violet._500,
  Colors.Primary._600,
  Colors.Success._500,
];

// prettier-ignore
export const inactiveSorterData = [{ title: 'Data', description: '', data: [  { value: 407, date: 'Nov 23' },  { value: 486, date: 'Dec 03' },  { value: 366, date: 'Dec 04' },  { value: 438, date: 'Dec 05' },  { value: 487, date: 'Dec 07' },  { value: 428, date: 'Dec 08' },  { value: 475, date: 'Dec 09' },  { value: 498, date: 'Dec 10' },  { value: 316, date: 'Dec 11' },  { value: 372, date: 'Dec 12' },  { value: 308, date: 'Dec 13' },  { value: 380, date: 'Dec 14' },  { value: 476, date: 'Dec 15' },],lines: [{ dataKey: 'value', stroke: Colors.Neutral._200 }]}];
