import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Button from '../../../../../../components/molecules/Button/Button';
import { CoverSubtitle, CoverTitle } from '../atoms/atoms';
import { UploadOptionCSS } from '../style';

export const UploadOption = ({ option }) => {
  const { icon, title, subtitle, selectOption } = option;
  return (
    <UploadOptionCSS>
      <Flex gap="_M" column align="center">
        <OptionCover icon={icon} title={title} subtitle={subtitle} />
        <ChooseOption selectOption={selectOption} />
      </Flex>
    </UploadOptionCSS>
  );
};

export const OptionCover = ({ icon, title, subtitle }) => {
  return (
    <Flex gap="_L" column align="center">
      <Icon name={icon} />
      <Flex gap="_M" column>
        <CoverTitle text={title} />
        <CoverSubtitle text={subtitle} />
      </Flex>
    </Flex>
  );
};

export const ChooseOption = ({ selectOption }) => {
  const { onClick, text } = selectOption;
  return <Button onClick={onClick} text={text} size="_M" variant="Primary" />;
};
