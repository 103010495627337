import TicketTypeBadge from '../../../../../components/molecules/TicketTypeBadge/TicketTypeBadge';
import { TicketConversationDetailsContainer } from '../style';
import { TicketDetailsTitle } from './atoms/atoms';
import { CreationData } from './molecules/molecules';
import { StatusAndPriority, UsersInvolved } from './organisms/organisms';

export const TicketConversationDetailsLayout = ({
  title,
  dateCreated,
  ticketCreator,
  statusBadge,
  priorityBadge,
  usersInvolved,
  type,
  hasEditPermission,
}) => {
  return (
    <TicketConversationDetailsContainer>
      <TicketDetailsTitle text={title} />
      <TicketTypeBadge type={type} size="_M" />
      <CreationData dateCreated={dateCreated} ticketCreator={ticketCreator} />
      <StatusAndPriority
        statusBadge={statusBadge}
        priorityBadge={priorityBadge}
        hasEditPermission={hasEditPermission}
      />
      <UsersInvolved usersInvolved={usersInvolved} />
    </TicketConversationDetailsContainer>
  );
};
