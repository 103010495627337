import { PropKeys } from '../../../../../constants';

export const caseKeys = {
  alias: 'alias',
  createdDate: 'createdDate',
  city: 'city',
  expansionArrow: 'expansionArrow',
  id: 'id',
  image: 'image',
  itemQuantity: 'itemQuantity',
  lastActivity: 'lastActivity',
  leftPadding: 'leftPadding',
  optionsMenu: 'optionsMenu',
  ownQuantity: 'ownQuantity',
  photos: 'photos',
  productQuantity: 'productQuantity',
  products: 'products',
  sideloadDate: 'sideloadDate',
  sorter: 'sorter',
  sorterId: PropKeys.sorterId,
  statusShares: 'statusShares',
  totalQuantity: 'totalQuantity',
};
