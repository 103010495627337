import { Card } from '../../../../../components/atoms';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { AddUsersIcon } from './atoms/atoms';
import { UsersTable } from './organisms';
import { UsersListContainer } from './style';

const { SELF, VIEW_BRAND_USERS, CREATE_BRAND_USERS } =
  PermissionKeys.Brand.BRAND_MANAGEMENT;

export const UsersListLayout = ({
  showWriteUser,
  headers,
  data,
  grid,
  expansionHeaders,
  expansionGrid,
  isLoading,
  isError,
  error,
  tableStatusProps,
  handleAction,
  options,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);
  return (
    <UsersListContainer>
      <Card
        title="Users"
        icon={
          hasPermission(CREATE_BRAND_USERS) && (
            <AddUsersIcon onClick={() => showWriteUser()} />
          )
        }
        className="users"
      >
        {hasPermission(VIEW_BRAND_USERS) && (
          <TableStatus
            isLoading={isLoading}
            isError={isError}
            error={error}
            {...tableStatusProps}
            table={
              <UsersTable
                headers={headers}
                data={data}
                grid={grid}
                expansionHeaders={expansionHeaders}
                expansionGrid={expansionGrid}
                handleAction={handleAction}
                options={options}
              />
            }
          />
        )}
      </Card>
    </UsersListContainer>
  );
};
