import { Separator } from '../../../../../components/atoms/Separator/Separator';
import {
  AuthTicket,
  AuthTicketMessages,
  AuthTicketMessagesContainer,
  ExpandableTicketContainer,
} from './style';

export const ExpandableTicketLayout = ({
  ticket,
  onClick,
  leftIcon,
  rightIcon,
  expanded,
  messages,
}) => {
  return (
    <ExpandableTicketContainer w column expanded={expanded}>
      <AuthTicket
        ticket={ticket}
        onClick={onClick}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        expanded={expanded}
      />
      <AuthTicketMessagesContainer relative grow w>
        {!!messages && <AuthTicketMessages messages={messages} />}
        {expanded && <Separator style={{ top: 0 }} />}
      </AuthTicketMessagesContainer>
    </ExpandableTicketContainer>
  );
};
