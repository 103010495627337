import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects } from '../../style';

export const Track = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._2XL};
  background: ${props =>
    props.active ? Colors.Primary._600 : Colors.Neutral._200};
  transition: ${Effects.Transition.EaseInOut()};
  ${props => trackDimensions[props.size]};
  ${Styles.flex}
`;

export const Handle = styled.div`
  aspect-ratio: 1;
  position: absolute;
  border-radius: ${Effects.Border_Radius.Circle};
  background: ${Colors.Sharp.White};
  left: ${props =>
    props.active
      ? `calc(100% - ${handleimensions[props.size].height} - 2px)`
      : '2px'};
  transition: ${Effects.Transition.EaseInOut()};
  ${props => handleimensions[props.size]};
`;

const trackDimensions = {
  _L: { height: '40px', width: '80px' },
  _M: { height: '32px', width: '64px' },
  _S: { height: '24px', width: '48px' },
  _XS: { height: '20px', width: '40px' },
};

const handleimensions = {
  _L: { height: '36px' },
  _M: { height: '28px' },
  _S: { height: '20px' },
  _XS: { height: '16px' },
};
