import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const FooterContext = createContext();

export function useFooter() {
  return useContext(FooterContext);
}

const FooterProvider = ({ children }) => {
  const location = useLocation();

  const [footerChildren, setFooterChildren] = useState(null);
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (footerChildren == null) return;
    setShowFooter(true);
  }, [footerChildren]);

  useEffect(() => {
    setFooterChildren(null);
  }, [location?.pathname]);

  return (
    <FooterContext.Provider
      value={{
        footerChildren,
        showFooter,
        setFooterChildren,
        setShowFooter,
      }}
    >
      {children}
    </FooterContext.Provider>
  );
};

export default FooterProvider;
