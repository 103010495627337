import { Text } from '../../../../../../components/atoms';
import { Label } from '../../../../../../components/molecules/Label/Label';
import { Colors, FontKeys } from '../../../../../../components/style';

export const SectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const BadgeLabel = ({ onClick, text, selected }) => {
  return (
    <Label
      text={text}
      size="_S"
      borderColor={selected ? Colors.Primary._600 : Colors.Neutral._500}
      borderWidth={selected ? '2px' : undefined}
      textColor={Colors.Neutral._900}
      hasBorder
      selected={selected}
      onClick={onClick}
    />
  );
};
