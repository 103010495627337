export const exportReducer = (state, { type, payload }) => {
  switch (type) {
    case 'select':
    default:
      return {
        ...state,
        value: payload,
      };
  }
};
