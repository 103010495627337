import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { SetupChoiceLayout } from '../components/organisms/SetupChoiceLayout';
import AddCategory from './AddCategory';
import AddSubcategory from './AddSubcategory';
import { setupChoice } from './variables';

const SetupChoice = ({ categories, onSuccess }) => {
  const { dismissAll, showModal } = useModal();

  const showCategory = () =>
    showModal(AddCategory, { onSuccess }, { stack: true });

  const showSubCategory = () =>
    showModal(AddSubcategory, { categories, onSuccess }, { stack: true });

  const categorySetup = {
    ...setupChoice.category,
    onClick: showCategory,
  };

  const subCategorySetup = {
    ...setupChoice.subCategory,
    onClick: showSubCategory,
  };

  return (
    <SetupChoiceLayout
      onCancel={() => dismissAll()}
      categorySetup={categorySetup}
      subCategorySetup={subCategorySetup}
    />
  );
};
export default Modal(SetupChoice);
