import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import SidebarPageIcon from '../../../../../components/molecules/SidebarPageIcon/SidebarPageIcon';
import { Colors, Effects } from '../../../../../components/style';

const UnreadNotifications = styled.div`
  width: 6px;
  height: 6px;
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius.Circle};
  position: absolute;
  top: 6px;
  left: 32px;
`;

const NotificationsIcon_ = ({ hasUnreadNotifs, ...rest }, ref) => {
  return (
    <div style={{ position: 'relative', paddingLeft: '6px' }}>
      <SidebarPageIcon
        {...rest}
        label={'Notifications'}
        name={Icons.Bell}
        ref={ref}
      />
      {hasUnreadNotifs && <UnreadNotifications />}
    </div>
  );
};

export const NotificationsIcon = forwardRef(NotificationsIcon_);
