import { Colors } from '../../style';

export const highlightActiveFilter = ({
  isFilterApplied,
  someOptionSelected,
}) => ({
  [isFilterApplied || !someOptionSelected ? 'border' : '']: `1px solid ${
    !someOptionSelected ? Colors.Error._500 : Colors.Primary._600
  }`,
  boxShadow: `inset 0 0 0 1px ${
    isFilterApplied
      ? Colors.Primary._600
      : !someOptionSelected
      ? Colors.Error._500
      : 'transparent'
  }`,
});
