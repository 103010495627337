import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import ActivityConnection from '../../../../../../components/molecules/ActivityConnection/ActivityConnection';
import Button from '../../../../../../components/molecules/Button/Button';
import { Card } from '../../../../../../components/molecules/Card/Card';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import Legend from '../../../../../../components/molecules/Legend/Legend';
import ProgressBar from '../../../../../../components/molecules/ProgressBar/ProgressBar';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { colorCardPadding } from '../../../../../../utils/utils';
import { components, infoDescription } from '../../variables';
import {
  DashboardClickIcon,
  DashboardItemLabel,
  ErrorText,
  ItemData,
  ItemSubdata,
  ItemTitle,
  LoadingAnimation,
  NavigateIcon,
} from '../atoms/atoms';
import {
  ActivitLogCSS,
  CategoryLabelCSS,
  ParcelStatusCSS,
  ProgressItemCSS,
  WelcomeActionsCSS,
} from '../style';

const disabledText1Row =
  'To visualize this data, you need to have access to this service';

const NavigateStat = ({ stats, onClick = Utils.emptyFunction }) => {
  const { title, count, navigate } = stats;
  return (
    <Flex column gap="_XS" onClick={onClick(stats)}>
      <Flex justify="between" gap="_2XS">
        <ItemTitle text={title} />
        {navigate && <NavigateIcon onClick={Utils.emptyFunction} />}
      </Flex>
      <ItemData text={count} />
    </Flex>
  );
};

export const OverviewStat = ({ stats, onClick }) => (
  <NavigateStat stats={stats} onClick={onClick} />
);

export const AuthenticationStat = ({ stats, onClick }) => (
  <NavigateStat stats={stats} onClick={onClick} />
);

export const ParcelStatus = ({
  status,
  onClick,
  count,
  color,
  countDetail,
  navigate,
  isLoading,
  isError,
  disabled,
}) => {
  return (
    <ParcelStatusCSS onClick={onClick}>
      <Card color={color} padding={colorCardPadding()}>
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Flex justify="between">
              <ItemTitle text={status} />
            </Flex>
            <ItemData text={count} />
            <Flex justify={'between'} align={'end'}>
              <Flex column>
                <ItemSubdata text={countDetail?.parcelCount ?? ''} black />
                <ItemSubdata text={countDetail?.itemCount ?? ''} />
              </Flex>
              {navigate && <NavigateIcon onClick={Utils.emptyFunction} />}
            </Flex>
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </ParcelStatusCSS>
  );
};

export const TimelineLegendAndInfo = ({ keys }) => {
  return (
    <Flex align="center" gap="_S">
      <Legend keys={keys} />
      {<InfoIcon description={infoDescription[components.timeline]} />}
    </Flex>
  );
};

export const ProgressBars = ({ bars, onClick }) => {
  bars = bars.slice(0, 6);
  return (
    <Flex column gap="_XS">
      {bars.map(bar => (
        <ProgressItem item={bar} onClick={onClick} key={bar.label} />
      ))}
    </Flex>
  );
};

export const ProgressItem = ({ item, onClick }) => {
  const { label, percentage, value, color, mock } = item;
  return (
    <ProgressItemCSS onClick={onClick(item)} isClickable={!mock}>
      <CategoryLabelCSS>
        <DashboardItemLabel text={label} ellipsis />
      </CategoryLabelCSS>
      <ProgressBar percentage={percentage} color={color} />
      <Flex gap="_2XS">
        <DashboardItemLabel text={value} bold />
        <DashboardClickIcon />
      </Flex>
    </ProgressItemCSS>
  );
};

export const ActivityLog = ({ activity, onClick }) => {
  const { info, date, isFirst, isLast, inactive } = activity;
  return (
    <ActivitLogCSS
      onClick={onClick(activity)}
      isClickable={!!onClick && !inactive}
      inactive={inactive}
    >
      <ActivityConnection
        isFirst={isFirst}
        isLast={isLast}
        inactive={inactive}
      />
      <Flex gap="_2XS" align="center" w>
        <Flex align="center" w0 grow>
          <Text
            text={[
              <DashboardItemLabel text={info} />,
              <ItemSubdata text={date} />,
            ]}
            // weight & variant are ignored, but size sets line-height
            size={FontKeys.Size._M}
            weight={FontKeys.Weight.Semibold}
            variant={FontKeys.Variant.Paragraph}
            ellipsis
          />
        </Flex>
        <DashboardClickIcon />
      </Flex>
    </ActivitLogCSS>
  );
};

// TODO: DRY THIS
export const ErrorDisplay = () => (
  <Flex column gap="_S" justify="center" align="center" w h>
    <Icon
      name={Icons.AlertTriangle}
      color={Colors.Neutral._500}
      size={Size.Icon._L}
    />
    <ErrorText text={'Oops! Something went wrong'} />
  </Flex>
);

export const WelcomeActions = ({ onCancelClick, onPrimaryClick }) => {
  return (
    <WelcomeActionsCSS>
      <Flex gap="_S">
        <Button
          onClick={onCancelClick}
          variant="Tertiary"
          size="_M"
          text="Skip"
        />
        <Button
          onClick={onPrimaryClick}
          variant="Primary"
          size="_M"
          text="Add reference data"
        />
      </Flex>
    </WelcomeActionsCSS>
  );
};
