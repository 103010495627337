import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Input } from '../../../../../../components/molecules/Input/Input';
import { ActingBrandSelection } from '../../../../../../components/organisms/ActingBrandSelection/ActingBrandSelection';
import { Colors } from '../../../../../../components/style';
import { FieldLabel } from '../../../../features/parcel_evaluation/components';
import { FieldsCSS } from '../../../../features/reference_index/components/style';
import {
  newReferenceLayout,
  referenceIndexKeys,
} from '../../controllers/variables';
import { FieldValue } from '../atoms';
import { UploadOption } from '../molecules';
import { UploadOptionsCSS, WeightInputCSS } from '../style';

export const UploadOptions = ({ options }) => {
  return (
    <UploadOptionsCSS>
      {options.map(option => (
        <UploadOption option={option} key={option.title} />
      ))}
    </UploadOptionsCSS>
  );
};

export const TemplateDownload = () => {};

export const ReferenceEntryForm = ({
  referenceFields,
  referenceFieldProps,
  selectOption,
  placeholder,
  className,
  ...rest
}) => {
  return (
    <FieldsCSS columns={2} className={className}>
      {Object.entries(referenceFields).map(([name, field]) => {
        return (
          <Input
            {...rest}
            hasError
            name={name}
            selectOption={selectOption}
            placeholder={placeholder[name]}
            key={name}
            size="_M"
            {...referenceFieldProps[name]}
          />
        );
      })}
    </FieldsCSS>
  );
};

export const ReferenceEntryFormNewLayout = ({
  referenceFields,
  selectOption,
  className,
  actingBrandProps,
  isAdmin,
  ...rest
}) => {
  return (
    <FieldsCSS columns={2} className={className}>
      <ActingBrandSelection {...actingBrandProps} />
      {referenceFields.map(field => {
        return (
          <Flex gap="_XS">
            <Input
              {...rest}
              {...field}
              key={field.name}
              selectOption={selectOption}
              hasError
              size="_M"
            />
            {isAdmin ? <WeightInput field={field} /> : null}
          </Flex>
        );
      })}
    </FieldsCSS>
  );
};

const WeightInput = ({ field }) => {
  const name = newReferenceLayout.fieldWeightMatch[field.name];
  const label = field.limit ? 'Limit' : 'Weight';
  const placeholder = field.limit ? '-∞ - ∞' : '1 - 100%';

  return (
    <WeightInputCSS
      name={name}
      label={label}
      size="_M"
      placeholder={placeholder}
      hasError
    />
  );
};

const getWeight = ({ referenceInfo, name, limit }) => {
  const weight = referenceInfo[newReferenceLayout.fieldWeightMatch[name]] ?? '';
  if (!weight) return '';

  return weight + (limit ? '' : '%');
};

export const ReferenceDetailDisplay = ({ fields, referenceInfo, isAdmin }) => {
  return (
    <Flex column gap="_XS">
      <RefDetail
        label="BRAND"
        value={referenceInfo[referenceIndexKeys.brand.name]}
        weight={isAdmin ? '' : ''}
      />
      {fields.map(({ name, label, limit }) => {
        return (
          <RefDetail
            key={name}
            name={name}
            label={label.toUpperCase()}
            value={referenceInfo[name]}
            weight={isAdmin ? getWeight({ referenceInfo, name, limit }) : ''}
          />
        );
      })}
    </Flex>
  );
};

const RefDetail = ({ label, value, weight }) => {
  return (
    <Flex justify={'between'} w>
      <Flex align={'center'} gap={'_2XS'} w0 grow>
        <FieldLabel text={label} />
        <FieldValue text={value} />
      </Flex>
      <FieldValue text={weight} color={Colors.Primary._600} />
    </Flex>
  );
};
