import styled from '@emotion/styled';
import { SubtableCSS } from '../../../../../components/organisms/Table/Table.exports';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Styles } from '../../../../../constants';

export const CaseProductsCSS = styled(SubtableCSS)`
  padding: 0;
  #tableContainer {
    border-radius: 0 0 ${Effects.Border_Radius._XS} ${Effects.Border_Radius._XS};
  }
  .header-row {
    display: none;
  }
`;

// share across apps to avoid 2 changes if style changes
export const CaseProductsTableHeaderContainer = styled.div`
  z-index: 10;
  position: sticky;
  top: 0px;
  width: 100%;
  padding-top: ${Layout.Spacing._S};
  background: ${Colors.Neutral._200};
  ${Styles.flexColumn};
  .header-row {
    display: grid;
  }
`;

export const CaseProductsTableHeaderCSS = styled.div`
  height: ${Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S} ${Effects.Border_Radius._S} 0 0;
  background: ${({ own }) => (own ? Colors.Primary._600 : Colors.Neutral._100)};
  padding-left: ${Layout.Spacing._M};
  ${Styles.flex};
`;
