import styled from '@emotion/styled';
import { Form } from 'formik';
import ConfirmModal from '../../molecules/ConfirmModal/ConfirmModal';
import { Layout } from '../../style';
import { Alert } from '../Alert/Alert';

export const ModalStyles = styled(ConfirmModal)`
  min-height: 400px;
  padding: ${Layout.Spacing._M};
  padding-top: ${Layout.Spacing._S};
  gap: ${Layout.Spacing._M};
  .confirm-modal {
    width: 544px;
  }
`;

export const ExportAlert = styled(Alert)`
  margin-top: ${Layout.Spacing._M};
`;

export const ExportForm = styled(Form)`
  height: 100%;
`;
