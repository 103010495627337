import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import {
  RowAction,
  RowSimpleText,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { reportsKeys } from '../../controllers/variables';

export const monthlyTableComponents = ({ handleAction }) => ({
  [reportsKeys.leftPadding]: { display: () => <></> },
  [reportsKeys.name]: {
    display: RowSimpleText(reportsKeys.name, 'bold', true),
  },
  [reportsKeys.action]: {
    display: RowAction({ icon: Icons.Download, action: handleAction }),
  },
});

export const customTableComponents = ({ handleAction }) => ({
  [reportsKeys.leftPadding]: { display: () => <></> },
  [reportsKeys.name]: {
    display: RowSimpleText(reportsKeys.name, 'bold', true),
  },
  [reportsKeys.dateRange]: { display: RowSimpleText(reportsKeys.dateRange) },
  [reportsKeys.requestedBy]: {
    display: User(reportsKeys.username, reportsKeys.userimage),
  },
  [reportsKeys.dateRequested]: {
    display: RowSimpleText(reportsKeys.dateRequested, 'light'),
  },
  [reportsKeys.action]: {
    display: RowAction({ icon: Icons.Download, action: handleAction }),
  },
});
