const { default: useDeleteData } = require('../../../../../hooks/deleteHook');

export const useDeleteService = () => {
  const [{ isCompleted, isLoading, isError, error }, doDeleteService] =
    useDeleteData();

  return {
    isCompleted,
    isLoading,
    isError,
    error,
    doDeleteService,
  };
};
