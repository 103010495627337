import { Card } from '../../../../../components/molecules/Card/Card';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import { FileDropzone } from '../../../../../components/organisms/FileDropzone/FileDropzone';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { infoDescription, optionViews } from '../controllers/variables';
import { Cancel, PrimaryAction } from './atoms/atoms';
import { Actions, EditBrandLogoContent } from './style';

const { SELF, EDIT_BRAND_DETAILS } = PermissionKeys.Brand.BRAND_MANAGEMENT;

export const EditBrandLogoLayout = ({
  handleDrop,
  fileState,
  percentageUploaded,
  uploadStatusContent,
  onCancel,
  primaryClick,
  primaryText,
  disabled,
  startUpload,
}) => {
  const { hasPermission: _ } = usePermissions();

  const hasPermission = permission => _(SELF, permission);

  return (
    <Card
      title="Logo"
      icon={<InfoIcon description={infoDescription[optionViews.brandLogo]} />}
    >
      <EditBrandLogoContent>
        <FileDropzone
          handleDrop={handleDrop}
          fileState={fileState}
          percentageUploaded={percentageUploaded}
          uploadStatusContent={uploadStatusContent}
          multiple={false}
        />
        {hasPermission(EDIT_BRAND_DETAILS) && (
          <Actions>
            {startUpload && <Cancel onCancel={onCancel} />}
            <PrimaryAction
              onClick={primaryClick}
              text={primaryText}
              disabled={disabled}
            />
          </Actions>
        )}
      </EditBrandLogoContent>
    </Card>
  );
};
