import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
  withContractedLabel,
} from '../../../../../components/organisms/Filters/utils';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import {
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../../products/controllers/variables';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('office_parcels/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const actor_data = [
  {
    value: 'has_both',
    label: 'Has both',
  },
  {
    value: 'has_none',
    label: 'Has none',
  },
  {
    value: 'has_only_origin',
    label: 'Only origin',
  },
  {
    value: 'has_only_destination',
    label: 'Only destination',
  },
];

export const formatter = data => {
  const { risk_types, brands, sorters, special_filters } = data;
  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(sorters, 'soName', 'soID');

  newOps[DC.BRAND] = prepareDataForDropdown(brands, 'brName', 'brID', {
    sectionTitle: 'Brands',
    customLabel: item =>
      withContractedLabel(item, {
        contractedKey: 'brIsContracted',
        labelKey: 'brName',
        size: '_S',
      }),
    bulkSelectableTags: [
      {
        column: 'brIsContracted',
        columnValue: [1, true],
        selectableByValue: productBrandIsContracted,
      },
      {
        column: 'brIsContracted',
        columnValue: [0, false, null, undefined],
        selectableByValue: productBrandIsNotContracted,
      },
    ],
  });

  newOps[DC.RISK_TYPE] = prepareDataForDropdown(
    risk_types,
    'paRiskType',
    'paRiskType'
  );

  newOps[DC.SPECIAL_FILTER] = prepareDataForDropdown(
    special_filters,
    'name',
    'id'
  );

  newOps[DC.PARCEL_STATUS] = [
    { section: DC.OTHERS, options: filterStatusOptions() },
  ];

  newOps[DC.ACTOR_DATA] = prepareDataForDropdown(actor_data, 'label', 'value');

  return newOps;
};

const filterStatusOptions = () => {
  const parcelStatus = mergeDeep({}, Constants.DropdownStatus);
  delete parcelStatus[Constants.Status.Counterfeit];
  delete parcelStatus[Constants.Status['Non-counterfeit']];

  const singleStatus = Object.values(parcelStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
