import { Flex } from '../../components/atoms/Flex/Flex';
import { PropKeys } from '../../constants';
import { cloneDeep } from '../../lib/js';
import { BrandsCount } from '../components';

export const markCurrentClient = (clients, currentCliendId) => {
  clients = cloneDeep(clients);
  const currentClientIndex = clients.findIndex(
    client => client[PropKeys.brandId] === currentCliendId
  );
  if (currentClientIndex !== -1)
    clients[currentClientIndex][PropKeys.isCurrentBrand] = true;
  return clients;
};

export const formatAllBrandsSelection = ({ clients, isAllBrands }) => {
  const images = clients.map(client => client[PropKeys.brandImage]);

  const allBrand = [
    {
      [PropKeys.brandName]: (
        <Flex gap="_2XS">
          {'All brands'}
          <BrandsCount count={clients.length} />
        </Flex>
      ),
      [PropKeys.brandId]: null,
      [PropKeys.brandImage]: images,
      [PropKeys.isCurrentBrand]: isAllBrands,
    },
  ];

  return allBrand;
};
