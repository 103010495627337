import Text from '../../../../../../components/atoms/Text/Text';
import { Colors, FontKeys } from '../../../../../../components/style';

export const CaseProductsTableHeaderText = ({ text, main }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={main ? Colors.Sharp.White : Colors.Neutral._600}
    />
  );
};
