import { Icon } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors } from '../../style';
import { BrandName, sizeProps } from './components';

const ContractedBrandName = ({
  brand,
  isContracted,
  size = '_M',
  bold = true,
}) => {
  const { iconSize } = sizeProps[size];

  return (
    <Flex gap="_2XS" align="center" maxW justify="start">
      <BrandName text={brand} size={size} bold={bold} />
      {isContracted ? (
        <Icon
          name={Icons.CC_CheckCircle}
          size={iconSize}
          color={Colors.Primary._600}
        />
      ) : null}
    </Flex>
  );
};

export default ContractedBrandName;
