import styled from '@emotion/styled';
import { Layout } from '../../../../../components/style';
import { Popup } from '../../../../../components/templates/Popup';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { Styles } from '../../../../../constants';

export const CreateTicketPopup = styled(Popup)`
  padding-right: 0;
  .popup-header {
    width: calc(100% - ${Layout.Spacing._M});
  }
`;

export const CreateTicketContent = styled(Content)`
  overflow-y: auto;
  padding-right: ${Layout.Spacing._M};
  > div {
    height: 100%;
  }
  .product-snapshot,
  .case-snapshot,
  .parcel-snapshot {
    padding-left: ${Layout.Spacing._M};
  }
  .filters {
    > div {
      max-width: unset !important;
    }
  }
`;

export const SnapshotConent = styled.div`
  width: 100%;
  height: 100%;
  gap: ${Layout.Spacing._XS};
  ${Styles.flexColumn};
`;

export const SnapshotDataCSS = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: minmax(10px, 1fr);
  align-content: space-between;
  width: 100%;
  grid-auto-flow: column;
  grid-row-gap: ${Layout.Spacing._2XS};
`;

export const OptionsCSS = styled.div`
  display: flex;
  gap: ${Layout.Spacing._XS};
`;

export const OtherTitleCSS = styled.div`
  position: relative;
  cursor: pointer;
  width: ${props => (props.selected ? '248px' : 'unset')};
  .input,
  input {
    cursor: pointer;
  }
`;

export const TicketTitleInputCSS = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  .input {
    opacity: ${props => (props.selected ? 1 : 0)};
    margin: 0 auto;
    border-color: transparent !important;
  }
`;

export const MoreDetailsInputCSS = styled.div`
  width: 100%;
  .moreDetails {
    height: 64px;
  }
  .input {
    height: 100%;
    padding: 0;
  }
`;
