import { Icon, Text } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Button from '../../../../../../components/molecules/Button/Button';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { ServiceActiveCSS } from '../style';

export const Cancel = ({ onCancel, disabled }) => (
  <Button
    onClick={onCancel}
    variant="Secondary"
    text="Cancel"
    size="_M"
    disabled={disabled}
  />
);

export const PrimaryAction = ({ onClick, text, disabled, isLoading }) => (
  <Button
    onClick={onClick}
    variant="Primary"
    text={text}
    size="_M"
    disabled={disabled}
    isLoading={isLoading}
    type="submit"
  />
);

export const PlanTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H2}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const PlanPrice = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

const BaseText = ({ text, blue, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={blue ? Colors.Primary._600 : ''}
      ellipsis
    />
  );
};

export const PlanDetailText = ({ text }) => <BaseText text={text} />;

export const ContractNameText = ({ text }) => (
  <BaseText text={text} blue bold />
);

export const ContractDateText = ({ text }) => <BaseText text={text} />;

export const AddUsersIcon = ({ onClick }) => (
  <AnimatedIcon onClick={onClick} name={Icons.UserPlus} theme="neutral" />
);

export const UserNameText = ({ text }) => <BaseText text={text} bold blue />;

export const UserTableText = ({ text }) => <BaseText text={text} bold />;

export const CardSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};

const ServiceBaseText = ({
  text,
  size,
  enabled,
  blue,
  bold,
  black,
  ellipsis,
}) => {
  const color = black
    ? undefined
    : !enabled
    ? Colors.Neutral._500
    : blue
    ? Colors.Primary._600
    : '';
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={color}
      ellipsis={ellipsis}
    />
  );
};

export const ServiceNameText = ({ text, enabled, black }) => (
  <ServiceBaseText
    text={text}
    bold
    blue
    black={black}
    enabled={enabled}
    size="_M"
    ellipsis
  />
);

export const ServiceDescriptionText = ({ text, enabled }) => (
  <ServiceBaseText text={text} size="_S" enabled={enabled} />
);

export const ServiceActive = ({ enabled, onClick }) => {
  return (
    <ServiceActiveCSS onClick={onClick} enabled={enabled}>
      <Icon
        name={enabled ? Icons.CheckSquare : Icons.Square}
        size={Size.Icon._M}
        color={enabled ? Colors.Neutral._900 : Colors.Neutral._200}
      />
    </ServiceActiveCSS>
  );
};
