import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
} from '../../../../../components/organisms/Filters/utils';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('parcels/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { sorters, brands, risk_types } = data;

  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(sorters, 'soName', 'soID');

  newOps[DC.BRAND] = prepareDataForDropdown(brands, 'brName', 'brID', {
    sectionTitle: 'Others',
  });

  newOps[DC.RISK_TYPE] = prepareDataForDropdown(
    risk_types,
    'paRiskType',
    'paRiskType'
  );

  newOps[DC.PARCEL_STATUS] = [
    { section: DC.OTHERS, options: filterStatusOptions() },
  ];

  return newOps;
};

const filterStatusOptions = () => {
  const casesStatus = mergeDeep({}, Constants.DropdownStatus);
  delete casesStatus[Constants.Status.Counterfeit];
  delete casesStatus[Constants.Status['Non-counterfeit']];
  const singleStatus = Object.values(casesStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
