import { timeFrames } from '../../../../utils/timeUtils';

export const presetOptions = [
  { dates: timeFrames.getToday, text: 'Today' },
  { dates: timeFrames.getYesterday, text: 'Yesterday' },
  //   { dates: timeFrames.getLastThreeDays, text: 'Last 3 Days' },
  { dates: timeFrames.getThisWeek, text: 'This week' },
  { dates: timeFrames.getLastWeek, text: 'Last week' },
  { dates: timeFrames.getThisMonth, text: 'This month' },
  { dates: timeFrames.getLastMonth, text: 'Last month' },
  // { dates: timeFrames.getThisQuarter, text: 'This Quarter' },
  // { dates: timeFrames.getLastQuarter, text: 'Last Quarter' },
  { dates: timeFrames.getLast3Months, text: 'Last 3 months' },
  { dates: timeFrames.getLast6Months, text: 'Last 6 months' },
  { dates: timeFrames.getThisYear, text: 'This year' },
  { dates: timeFrames.getLastYear, text: 'Last year' },
  { dates: timeFrames.getAllTime, text: 'All time' },
].map((option, index) => {
  return {
    ...option,
    value: index,
    label: option.text,
  };
});

export const actions = {
  apply: 'apply',
  cancel: 'cancel',
};
