import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects, FontKeys, Layout } from '../../style';
import Text from '../Text/Text';

export const Container = styled.div`
  ${Styles.flex};
  padding: ${Layout.Spacing._3XS} ${Layout.Spacing._2XS};
  background: ${Colors.Neutral._400};
  border-radius: ${Effects.Border_Radius._2XS};
`;

export const AtomText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Sharp.White}
      whiteSpace="nowrap"
    />
  );
};
