import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { adminSortersKeys as keys } from '../controllers/variables';

export const useGetRepresentatives = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, doGetRepresentatives] =
    useFetchData(``, {
      formatter,
      failureFormatter: () => [],
      ignore,
    });

  const get = ({ id }) => {
    const url = `admin_sorters/rep_users`;
    const params = {
      brand_id: id,
    };
    doGetRepresentatives({ url, params });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    doGetRepresentatives: get,
  };
};

const formatter = data => {
  maskEntry(data, mask);

  return data.map(entry => ({
    ...entry,
    value: entry[keys.responsible.id],
    label: entry[keys.responsible.name],
  }));
};

const mask = {
  usID: keys.responsible.id,
  usDisplayName: keys.responsible.name,
  //  usEmail: keys.responsible.,
};
