import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { sortByChar } from '../../../../../utils/arrayUtils';
import { alphabetChars, joinStrings } from '../../../../../utils/stringUtils';
import { maskEntry } from '../../../../../utils/utils';

export const useGetCategories = () => {
  const [{ data: { categories = [] } = {}, isLoading, isError, error }] =
    useFetchData('categories', {
      failureFormatter: _ => [],
    });

  return { data: formatter(categories), isLoading, isError, error };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data.forEach(
    p =>
      (p.subcategory_category = joinStrings(
        p.subCategory,
        p.mainCategory,
        ' - '
      ))
  );

  data =
    prepareDataForDropdown(data, 'subcategory_category', 'product_id')[0]
      ?.options ?? [];

  data = organizeAlphabetically(data);
  return data;
};

// % this can be shared with getFilters.js
const organizeAlphabetically = data => {
  const alphabetObject = {};
  alphabetChars.forEach(char => (alphabetObject[char] = []));
  data.forEach(category => {
    const char = category.label.slice(0, 1).toUpperCase();
    if (alphabetObject[char] == null) alphabetObject[char] = [];
    alphabetObject[char].push(category);
  });
  const sortedAlphabetArray = Object.entries(alphabetObject)
    .sort(sortByChar)
    .map(([char, brand]) => ({ [char]: brand }));

  return sortedAlphabetArray;
};

export const defaultCategory_Other = {
  value: 'Other',
  label: 'Other - Other',
};

const mask = {
  bcName: 'mainCategory',
  cpName: 'subCategory',
};
