import { Icon } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Alert } from '../../../../../components/templates/Alert/Alert';
import { Popup } from '../../../../../components/templates/Popup';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { Form } from './organisms';
import { ReportContainer } from './style';

export const NewReportLayout = ({
  dismiss,
  disabled,
  isLoading,
  changeDates,
  title,
  timeFrameInitVals,
  alertText,
  onSubmit,
}) => {
  return (
    <ReportContainer>
      <Popup
        size={'_S'}
        title={'New report'}
        element={
          <div onClick={dismiss} style={{ cursor: 'pointer' }}>
            <Icon name={Icons.X} />
          </div>
        }
      >
        <Content>
          <Flex column gap="_S" w>
            <div />
            <Form
              changeDates={changeDates}
              title={title}
              timeFrameInitVals={timeFrameInitVals}
            />
            <Alert text={alertText} iconSize="_L" />
            <Actions
              onCancel={dismiss}
              primaryText={'Create'}
              onPrimary={onSubmit}
              disabled={disabled}
              isLoading={isLoading}
            />
          </Flex>
        </Content>
      </Popup>
    </ReportContainer>
  );
};
