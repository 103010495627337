import { Input } from '../../../../../components/molecules/Input/Input';

import { MessageInputContainer } from '../style';
import { SendMessage } from './molecules';

export const MessageInputLayout = ({
  handleEnterKey,
  handleChange,
  placeholder,
  handleSend,
  isLoading,
  disabled,
  inputDisabled,
}) => {
  return (
    <MessageInputContainer onKeyPress={handleEnterKey}>
      <Input
        name="message"
        type="textarea"
        variant="textarea"
        onChange={handleChange}
        placeholder={placeholder}
        disabled={inputDisabled}
      />
      <SendMessage
        onClick={handleSend}
        isLoading={isLoading}
        disabled={disabled}
      />
    </MessageInputContainer>
  );
};
