import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';
import { Container, styles } from './Label.style';

export const Label = ({
  text,
  textColor,
  size,
  hasBorder,
  borderColor,
  background,
  selected,
  leftIcon,
  rightIcon,
  onClick,
  noPadding,
  ...props
}) => {
  const style = styles({ size, textColor });
  return (
    <Container
      size={size}
      hasBorder={hasBorder}
      borderColor={borderColor ?? textColor}
      background={background}
      selected={selected}
      onClick={onClick}
      isClickable={!!onClick}
      noPadding={noPadding}
      {...props}
      className={`label ${props.className ?? ''}`}
    >
      {leftIcon && <Icon name={leftIcon} {...style.icon} />}
      <Text text={text} {...style.text} ellipsis />
      {rightIcon && <Icon name={rightIcon} {...style.icon} />}
    </Container>
  );
};
