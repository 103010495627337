import * as React from 'react';
import { Utils } from '../constants';
const notProduction = process.env.REACT_APP_ENV !== 'prod';

const map = new Map();

const setRef = key => {
  if (key == null)
    return notProduction
      ? console.warn(`useDynamicRefs: Cannot set ref without key `)
      : Utils.emptyFunction;
  const ref = React.createRef();
  map.set(key, ref);
  return ref;
};

const getRef = key => {
  if (key == null)
    return notProduction
      ? console.warn(`useDynamicRefs: Cannot get ref without key`)
      : Utils.emptyFunction;
  return map.get(key);
};

const useDynamicRefs = () => {
  return [getRef, setRef];
};

export default useDynamicRefs;
