import { ParcelsTableCSS } from '../../style';
import { CaseProducts, tableComponents } from '../molecules';

export const ParcelsTable = ({
  headers,
  data,
  rowAction,
  onRowExpand,
  grid,
  avoidSingleExpand,
  checkedRows,
  tableComponentsProps,
  expandCountKey,
}) => {
  return (
    <ParcelsTableCSS
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      selectedRows={checkedRows}
      onRowExpand={onRowExpand}
      avoidSingleExpand={avoidSingleExpand}
      expandCountKey={expandCountKey}
      components={tableComponents({
        CaseProducts,
        ...tableComponentsProps,
      })}
    />
  );
};
