import styled from '@emotion/styled';
import { Flex } from '../../../../components/atoms/Flex/Flex';
import Avatar from '../../../../components/molecules/Avatar/Avatar';
import { BadgeType } from '../../../../components/molecules/Badge/Badge';
import {
  Colors,
  Effects,
  Layout,
  UtilityColors,
} from '../../../../components/style';
import { Content } from '../../../../components/templates/Popup/Popup.style';
import {
  Styles,
  UtilityComponents,
  scrollbarOffset,
} from '../../../../constants';

export const TicketContainerCSS = styled.div`
  min-height: 196px;
  cursor: pointer;
  padding: ${Layout.Spacing._XS};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._M};
  background: ${Colors.Sharp.White};
  width: calc(100% - ${Layout.Spacing._XS});
  ${Styles.flexColumn};
  justify-content: space-between;
  .ticket-label {
    width: 80%;
  }
`;

export const UnreadCircle = styled.div`
  margin: auto;
  margin-left: ${Layout.Spacing._2XS};
  width: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius.Circle};
  background: ${Colors.Primary._600};
`;

export const TicketOptionsCSS = styled.div`
  position: relative;
  ${Styles.flexColumnEnd}
  &:hover {
    .mark-read-unread {
      display: block;
    }
  }
`;

export const MarkReadUnreadCSS = styled.div`
  position: absolute;
  top: 100%;
  display: none;
  right: -${Layout.Spacing._XS};
  padding: ${Layout.Spacing._2XS};
  &:hover {
    display: block;
  }
`;

export const MarkReadUnreadCenteringCSS = styled.div`
  width: 112px;
  height: 32px;
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  ${Styles.flexCenter};
`;

export const RecentActivityCSS = styled.div`
  grid-column: 9 / 13;
  margin-top: ${Layout.Spacing._XS};
  background: ${Colors.Sharp.White};
  padding: ${Layout.Padding._XL};
  gap: ${Layout.Padding._XL};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._L};
  ${Styles.flexColumn}
  height: calc(100% - ${Layout.Spacing._M});
  .notification {
    width: calc(100% - ${scrollbarOffset});
  }
  *::-webkit-scrollbar {
    width: 8px;
    display: unset;
  }
`;

export const NoResultsCSS = styled.div`
  height: 100%;
  grid-row-start: 2;
  grid-column: 1 / 13;
  ${Styles.flexCenter};
`;

export const ActivityScroll = styled(Flex)`
  overflow-y: ${({ moreActivityClicked }) =>
    moreActivityClicked ? 'auto' : 'hidden'};
`;

export const NotificationCSS = styled.div`
  width: 100%;
  cursor: ${({ showClickable }) => (showClickable ? 'pointer' : 'default')};
  gap: ${Layout.Spacing._S};
  ${Styles.flex}
  .notification-click-icon {
    display: none;
  }
  span {
    cursor: ${({ showClickable }) => (showClickable ? 'pointer' : 'default')};
  }
  &:hover {
    .notification-click-icon {
      display: flex;
    }
  }
`;

export const NotificationTextCSS = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const TicketConversationContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: min(184px) auto 104px 8px;
  grid-row-gap: 16px;
  max-height: 900px;
  .product-snapshot,
  .case-snapshot,
  .parcel-snapshot {
    grid-column: 1 / 9;
    grid-row: 1;
    cursor: pointer;
    align-self: stretch;
    padding-left: ${Layout.Spacing._M};
    .snapshot-content {
      margin-top: -${Layout.Spacing._2XS};
    }
    > .card-title {
      margin: auto 0;
      span {
        width: 100%;
      }
    }
  }
`;

export const TicketConversationDetailsContainer = styled.div`
  grid-column: 9 / 13;
  grid-row: 1 / 3;
  align-items: flex-start;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const DetailsSubsectionCSS = styled.div`
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const BrandAvatar = styled(Avatar)`
  background: ${Colors.Sharp.White} !important;
`;

export const BadgeDropdownCSS = styled.div`
  position: relative;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
  ${({ hasEditPermission, display }) =>
    hasEditPermission &&
    `
    .menu-options {
      display: ${display ? 'block' : 'false'}
    }
  `}
  .${BadgeType.ticket}-badge {
    min-width: 124px;
  }
`;

export const MenuOptionsHoverArea = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding-top: ${Layout.Spacing._2XS};
`;

export const MenuOptionsCSS = styled.div`
  cursor: pointer;
  padding: ${Layout.Spacing._2XS} ${Layout.Spacing._2XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const MenuOptionCSS = styled.div`
  width: 88px;
  height: ${Layout.Spacing._S};
  padding: 0px ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._S};
  background: ${props =>
    props.selected ? UtilityColors.Background_App : 'transparent'};
  ${Styles.flex};
`;

export const InvolvedUserAvatarCSS = styled.div`
  position: relative;
  margin-left: ${props => `-${(props.index ? 1 : 0) * 16}px`};
`;

export const MessagesCSS = styled.div`
  grid-column: 1 / 9;
  grid-row: ${props => (props.hasProduct ? '2' : '1 / 3')};
  widht: 100%;
  align-self: stretch;
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S};
  box-shadow: ${Effects.Box_Shadow._M};
  background: ${Colors.Sharp.White};
  ${Styles.flexColumn};
  *::-webkit-scrollbar {
    display: unset;
  }
`;

export const MessageCSS = styled.div`
  display: flex;
  width: 100%;
  gap: ${Layout.Spacing._S};
  padding-right: ${Layout.Spacing._S};
  flex-direction: ${props => (props.fromClient ? 'row-reverse' : 'row')};
  .message-background {
    max-width: 438px;
  }
`;

export const MessageContentCSS = styled.div`
  word-break: break-word;
  display: flex;
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._XS};
  background: ${props =>
    props.fromClient ? Colors.Primary._050 : Colors.Neutral._100};
`;

export const AwaitingResponseCSS = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: unset;
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._2XS} ${Layout.Spacing._XS};
  background: ${Colors.Neutral._100};
`;

export const MessageInputContainer = styled.div`
  grid-column: 1 / 9;
  grid-row: 3;
  height: 100%;
  display: flex;
  padding: ${Layout.Spacing._XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._M};
  .input {
    border-color: transparent !important;
    padding: 0 !important;
  }
`;

export const SendMessageCSS = styled.div`
  align-self: flex-end;
`;

export const FacilitatorDetailsContainer = styled.div`
  grid-column: 9 / 13;
  grid-row: 3;
  align-items: flex-start;
  gap: ${Layout.Spacing._XS};
  ${Styles.flexColumn};
`;

export const BottomMargin = styled.div`
  grid-row: 5;
  height: 100%;
`;

export const HelpContent = styled(Content)`
  padding-top: ${Layout.Spacing._S};
  gap: ${Layout.Spacing._M};
  ${Styles.flexColumn};
`;

export const SupportContainer = styled.div`
  flex-grow: 1;
  position: relative;
  ${Styles.flexColumn};
  .view-loader {
    grid-column: 1 / 9;
  }
`;

export const SupportContent = styled(UtilityComponents.Grid)`
  grid-column: 1 / 13;
  height: 0;
  flex-grow: 1;
  .tickets {
    grid-column: 1 / 9;
    align-self: stretch;
    margin-bottom: 0;
    width: calc(100% + ${Layout.Spacing._XS});
  }
`;
