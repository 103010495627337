import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { FilterIcon, Layout } from '../../style';

export const Container = styled.div`
  ${Styles.flex};
  flex-grow: 1;
  min-width: ${Layout.Spacing._M};
  gap: ${Layout.Spacing._2XS};
`;

export const IconStart = styled(FilterIcon)``;

export const IconEnd = styled(FilterIcon)`
  margin-left: auto;
`;

export const TextContainer = styled.div`
  ${Styles.flex};
  width: 0;
  flex-grow: 1;
  opacity: 1;
`;
