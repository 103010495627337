import { useEffect, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import { Constants } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDynamicRefs from '../../../../../utils/dynamicRefs';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import useDeepCompareLayoutEffect from '../../../../../utils/useDeepCompareLayoutEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import { GalleryPages, GalleryProps } from '../../../constants';
import { useGetBrands } from '../api/getBrands';
import { defaultCategory_Other, useGetCategories } from '../api/getCategories';
import { AddProductWizardLayout } from '../components/AddProductWizardLayout';
import {
  hasEnoughImages,
  updateDbImages,
  updateEnoughImages,
  updateIsContractedInPhotos,
  updateMemoryImages,
  updateSummaryCategory,
  updateSummaryImages,
  updateSummaryQuantity,
  updateSummarySelectedBrand,
} from './utils';
import { processingKeys, stepKeys } from './variables';

const {
  OFFICE_PARCEL_PROCESSING: { SELF, EDIT_PARCEL_PRODUCTS },
} = PermissionKeys.Facilitator;

const _initialValues = {
  [stepKeys.category]: defaultCategory_Other,
  [stepKeys.itemQuantity]: '1',
  [stepKeys.summary]: {
    details: {
      brand: {
        title: 'BRAND',
      },
      category: {
        title: 'CATEGORY',
      },
      quantity: {
        title: 'QUANTITY',
      },
    },
  },
};

const AddProductWizard = ({
  step,
  stepTo,
  values: initialValues = _initialValues,
  setSaveValues,
  setValidation,
  validation,
  disabled,
}) => {
  const [displayValues, setDisplayValues] = useState({});
  const [values, setValues] = useState(initialValues);

  const [options, setOptions] = useState({});

  const [filesStagedForDeletion, setFilesStagedForDeletion] = useState([]);

  const [numbersSelected, setNumbersSelected] = useState(true);

  const [getRef, setRef] = useDynamicRefs();

  const { showSnackbarError } = useSnackbar();
  const { dismiss, showModal } = useModal();
  const { handleDrop, handleImport, fileState, resetFileUpload, deleteFile } =
    useFileUpload();
  const loadFiles = handleDrop(Constants.FileUpload.uploadType.image);

  const { hasPermission } = usePermissions();

  const goBackToStart = stepTo(stepKeys.details);

  useEffect(() => {
    if (!hasPermission(SELF, EDIT_PARCEL_PRODUCTS)) goBackToStart();
    return () => resetFileUpload();
  }, []);

  const {
    data: brands,
    isError: brandsIsError,
    error: brandsError,
  } = useGetBrands();

  const {
    data: categories,
    isError: categoriesIsError,
    error: categoriesError,
  } = useGetCategories();

  useEffect(() => {
    if (brandsIsError || categoriesIsError) {
      const error_msg = brandsIsError
        ? brandsError
        : categoriesIsError
        ? categoriesError
        : null;
      showSnackbarError(error_msg);
      goBackToStart();
    }
  }, [brandsIsError, categoriesIsError]);

  useDeepCompareEffect(() => {
    setValues(x => {
      const vBrand = x[stepKeys.selectBrand] != null;
      const vCategory = x[stepKeys.category] != null;
      const vQuantity =
        String(x[stepKeys.itemQuantity]).length && x[stepKeys.itemQuantity] > 0;
      const vPhotos = hasEnoughImages(x);
      setValidation({
        [stepKeys.selectBrand]: true,
        [stepKeys.category]: [vBrand].every(v => v),
        [stepKeys.itemQuantity]: [vBrand, vCategory].every(v => v),
        [stepKeys.photos]: [vBrand, vCategory, vQuantity].every(v => v),
        [stepKeys.summary]: [vBrand, vCategory, vQuantity, vPhotos].every(
          v => v
        ),
      });
      return x;
    });
  }, [values]);

  // give component parent access to values so it can add product
  useDeepCompareEffect(() => {
    setSaveValues({ ...values, filesStagedForDeletion });
  }, [values, filesStagedForDeletion]);

  // SET OPTIONS
  //
  useDeepCompareEffect(() => {
    setOptions(x => ({
      ...x,
      [stepKeys.selectBrand]: brands,
    }));
  }, [brands]);

  useDeepCompareEffect(() => {
    setOptions(x => ({
      ...x,
      [stepKeys.category]: categories,
    }));
  }, [categories]);

  // UPDATE DISPLAY VALUES
  //
  useDeepCompareEffect(() => {
    if (values[stepKeys.selectBrand] == null) return;

    const updateSelectedBrandDisplay = x => ({
      ...x,
      [stepKeys.selectBrand]:
        values[stepKeys.selectBrand][processingKeys.brand.name],
    });

    setDisplayValues(updateSelectedBrandDisplay);

    setValues(updateSummarySelectedBrand);
    setValues(updateIsContractedInPhotos);
  }, [values[stepKeys.selectBrand]]);

  useDeepCompareEffect(() => {
    if (
      values[stepKeys.category] == null ||
      values[stepKeys.category].label == null
    )
      return;

    const updateCategoryDisplay = x => ({
      ...x,
      [stepKeys.category]: values[stepKeys.category].label,
    });

    setDisplayValues(updateCategoryDisplay);

    setValues(updateSummaryCategory);
  }, [values[stepKeys.category]]);

  useDeepCompareEffect(() => {
    setDisplayValues(x => ({
      ...x,
      [stepKeys.itemQuantity]: values[stepKeys.itemQuantity],
    }));

    setValues(updateSummaryQuantity);
  }, [values[stepKeys.itemQuantity]]);

  useDeepCompareEffect(() => {
    if (
      values[stepKeys.photos]?.dbImages == null &&
      values[stepKeys.photos]?.memoryImages == null
    )
      return;

    const imageCount =
      (values[stepKeys.photos]?.dbImages ?? []).length +
      (values[stepKeys.photos]?.memoryImages ?? []).length;

    setDisplayValues(x => ({
      ...x,
      [stepKeys.photos]: `${imageCount} photos added`,
    }));

    setValues(updateSummaryImages);
  }, [values[stepKeys.photos]]);

  useDeepCompareEffect(() => {
    selectNumbers();
  }, [step]);

  // METHODS - setValues()
  //
  const selectBrand = brand => () =>
    setValues(x => ({ ...x, [stepKeys.selectBrand]: brand }));

  const selectCateogry = category => () =>
    setValues(x => ({ ...x, [stepKeys.category]: category }));

  const selectNumbers = () => setNumbersSelected(true);
  const deselectNumbers = () => setNumbersSelected(false);
  const toggleNumbersSelected = () => setNumbersSelected(x => !x);

  const withoutLeading0s = numString =>
    numString.replaceAll('0', ' ').trimStart().replaceAll(' ', '0');

  const typeNumber = number => () => {
    if (numbersSelected) {
      deselectNumbers();
      setValues(x => ({
        ...x,
        [stepKeys.itemQuantity]: String(number),
      }));
    } else
      setValues(x => ({
        ...x,
        [stepKeys.itemQuantity]: withoutLeading0s(
          (x[stepKeys.itemQuantity] ?? '') + String(number)
        ),
      }));
  };

  const deleteNumber = e => {
    e.stopPropagation();
    if (numbersSelected) {
      deselectNumbers();
      setValues(x => ({
        ...x,
        [stepKeys.itemQuantity]: '',
      }));
    } else {
      setValues(x => ({
        ...x,
        [stepKeys.itemQuantity]: String(x[stepKeys.itemQuantity]).slice(0, -1),
      }));
    }
  };

  // useEffect here bc other images methods change fileState, not setValue(),
  // therefore i'm placing expected setValue update here when fileState changes
  useDeepCompareLayoutEffect(() => {
    if (fileState.files == null) return;
    setValues(updateMemoryImages(fileState));
    setValues(updateEnoughImages);
  }, [fileState]);

  useDeepCompareLayoutEffect(() => {
    setValues(updateEnoughImages);
  }, [values[stepKeys.selectBrand]]);

  const handleAddImages = e =>
    loadFiles([...Array.from(e.target.files), ...(fileState.files ?? [])]);

  const deleteFileFromDb = file => () => {
    const description = parseStringModifiers(
      `Are you sure you want to delete this image? This image will be completely removed once you save.
  
        **This action cannot be undone.**`
    );
    const onConfirm = () => {
      stageDbFilesForDeletion(file);
      dismiss();
    };

    confirmBackToParcel({ onConfirm, description });
  };

  const deleteFileFromMemory = file => () => {
    const description = parseStringModifiers(
      `Are you sure you want to delete this image? This image is new, and you must re-upload it if you change your mind.
  
        **This action cannot be undone.**`
    );
    const onConfirm = () => {
      removeFileFromFilestate(file);
      dismiss();
    };

    confirmBackToParcel({ onConfirm, description });
  };

  const confirmBackToParcel = ({ onConfirm, description }) => {
    const title = 'Delete image';
    const confirm = 'Delete';
    const onCancel = dismiss;

    showModal(
      ConfirmModal,
      { title, description, confirm, onCancel, onConfirm },
      { stack: true, keepPrev: true }
    );
  };

  const stageDbFilesForDeletion = file => {
    const fileIndex = values[stepKeys.photos].dbImages.findIndex(
      file_ => file_ === file
    );
    if (fileIndex === -1) return;

    setFilesStagedForDeletion(x => [
      ...x,
      values[stepKeys.photos].dbImages[fileIndex],
    ]);

    setValues(updateDbImages(fileIndex));
    setValues(updateEnoughImages);
  };

  const removeFileFromFilestate = file => {
    const fileIndex = fileState.files.findIndex(file_ => file_ === file);
    if (fileIndex === -1) return;
    loadFiles([
      ...fileState.files.slice(0, fileIndex),
      ...fileState.files.slice(fileIndex + 1),
    ]);
  };

  const showGallery = initialSelected => () => {
    showModal(
      Gallery,
      {
        initialSelected,
        images: fileState.files?.map(file => URL.createObjectURL(file)) ?? [],
        id: values.item[processingKeys.product.id],
        ...GalleryProps[GalleryPages.products],
        appendRequested: true,
      },
      { keepPrev: true }
    );
  };

  const getKnowledgeBase = () => {
    showModal(
      Gallery,
      {
        customImagesProps: {
          brand_id: values[stepKeys.selectBrand][processingKeys.brand.id],
          category_id: values[stepKeys.category].value,
        },
        ...GalleryProps[GalleryPages.knowledge_base],
      },
      { keepPrev: true }
    );
  };

  const accesorySlotEnd = (
    <Button
      leftIcon={Icons.FileText}
      text={'Knowledge base'}
      variant={'Tertiary'}
      size="_M"
      onClick={getKnowledgeBase}
    />
  );

  return (
    <AddProductWizardLayout
      step={step}
      values={values}
      displayValues={displayValues}
      options={options}
      validation={validation}
      stepTo={stepTo}
      getRef={getRef}
      setRef={setRef}
      disabled={disabled}
      // TODO: put functions in a bag marked by step
      typeNumber={typeNumber}
      deleteNumber={deleteNumber}
      toggleNumbersSelected={toggleNumbersSelected}
      numbersSelected={numbersSelected}
      productPhotosProps={{
        handleAddImages,
        deleteFileFromMemory,
        deleteFileFromDb,
        showGallery,
        accesorySlotEnd,
      }}
      selectBrand={selectBrand}
      selectCateogry={selectCateogry}
    />
  );
};

export default AddProductWizard;
