import { Card } from '../../../components/atoms';
import { Flex } from '../../../components/atoms/Flex/Flex';
import {
  Actions,
  DetailsForm,
  EditableAvatar,
  UserDetails,
} from './organisms/organisms';

export const UserInfoLayout = ({
  username,
  image,
  isEditing,
  avatarEditOptions,
  fields,
  onCancel,
  cancelDisabled,
  submitDisabled,
  isLoading,
  imageAvatarLoading,
}) => {
  return (
    <Card title="User settings">
      <Flex column gap="_M" grow justify="start">
        <Flex align="center" gap="_L">
          <EditableAvatar
            username={username}
            image={image}
            isEditing={isEditing}
            options={avatarEditOptions}
            isLoading={imageAvatarLoading}
          />
          <UserDetails username={username} />
        </Flex>
        <DetailsForm fields={fields} />
        <Actions
          submitDisabled={submitDisabled}
          cancelDisabled={cancelDisabled}
          onCancel={onCancel}
          isLoading={isLoading}
        />
      </Flex>
    </Card>
  );
};
