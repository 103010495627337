import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { authenticationKeys } from '../../../features/authentication/variables';
import { attachmentsMask } from '../../../features/products/api/getProductDetails';
import { productKeys } from '../controllers/variables';

export const useGetProducts = ({
  searchParams,
  ignore,
  is_authentication_page,
}) => {
  const params = formatParams(searchParams, is_authentication_page);

  const [
    {
      data: { cases: products = [], case_count: productCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('cases', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { products: maskData(products, mask), productCount },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

export const formatParams = (params, is_authentication_page) => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PRODUCT_STATUS,
    LOCATION,
    BRANDS,
    PRODUCT_CATEGORY,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PRODUCT_STATUS);
  const sorter_id = extractSelectedValues(LOCATION);
  const brand_id = extractSelectedValues(BRANDS);
  const product_id = extractSelectedValues(PRODUCT_CATEGORY);

  const formattedParams = {
    search,
    status,
    sorter_id,
    brand_id,
    from_date,
    to_date,
    product_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  if (is_authentication_page) formattedParams.is_authentication_page = true;

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data.map(entry => ({ ...entry, id: entry[productKeys.productId] }));
};

const mask = {
  ...attachmentsMask,
  brName: productKeys.brandName,
  brImage: productKeys.brandImage,
  brIsContracted: productKeys.isContracted,
  caBrand: productKeys.brand,
  caID: productKeys.productId,
  caAlias: productKeys.productAlias,
  caStatus: productKeys.productStatus,
  caSideloadDate: productKeys.productSideloadDate,
  cpName: productKeys.subCategory,
  pdConfidence: productKeys.confidence,
  createdAt: productKeys.createdAt,
  is_case_owner: productKeys.isProductOwner,
  caStatusUpdatedAt: productKeys.lastActivity,
  caParcel: productKeys.parcelId,
  soName: productKeys.sorterName,
  soID: productKeys.sorterId,
  soCity: productKeys.sorterCity,
  soCountry: productKeys.sorterCountry,
  bcName: productKeys.category,
  cspQuantity: productKeys.productQuantity,
  remaining_auth_time: authenticationKeys.remainingAuthTime,
  has_product_support_tickets: productKeys.hasSupportTickets,
};
