import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
  withContractedLabel,
} from '../../../../../components/organisms/Filters/utils';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import { actor_data } from '../../parcels/api/getFilters';
import {
  DC,
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('office_cases/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { products, brands, sorters } = data;

  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(sorters, 'soName', 'soID');

  newOps[DC.CUSTOMER] = prepareDataForDropdown(brands, 'brName', 'brID', {
    sectionTitle: 'Brands',
    customLabel: item =>
      withContractedLabel(item, {
        contractedKey: 'brIsContracted',
        labelKey: 'brName',
        size: '_S',
      }),
    bulkSelectableTags: [
      {
        column: 'brIsContracted',
        columnValue: [1, true],
        selectableByValue: productBrandIsContracted,
      },
      {
        column: 'brIsContracted',
        columnValue: [0, false, null, undefined],
        selectableByValue: productBrandIsNotContracted,
      },
    ],
  });

  newOps[DC.PRODUCT_CATEGORY] = prepareDataForDropdown(
    products,
    'product_name',
    'product_id'
  );

  newOps[DC.PRODUCT_STATUS] = [
    { section: DC.OTHERS, options: filterStatusOptions() },
  ];

  newOps[DC.ACTOR_DATA] = prepareDataForDropdown(actor_data, 'label', 'value');

  return newOps;
};

const filterStatusOptions = () => {
  const casesStatus = mergeDeep({}, Constants.DropdownStatus);
  delete casesStatus.Sideload;
  const singleStatus = Object.values(casesStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
