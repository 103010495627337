import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { includeConstants } from '../../../utils/styleUtils';
import {
  sidebarBackgroundColor,
  sidebarIconDefaultColor,
} from '../../organisms/Sidebar/Sidebar.style';
import { Colors, Effects, Layout, UtilityEffects } from '../../style';

export const squareLength = '36px';

const constantStyle = {
  cursor: 'pointer',
  height: squareLength,
  padding: Layout.Padding._S,
  borderRadius: Effects.Border_Radius._2XS,
  gap: Layout.Spacing._2XS,
  overflow: 'hidden',
  position: 'relative',
  ...Styles.flex,
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: (_, { expanded, transition, active, comingSoon, hovered }) => {
    return {
      cursor: comingSoon ? 'auto' : 'pointer',
      color: active
        ? sidebarBackgroundColor
        : hovered && !comingSoon
        ? Colors.Sharp.White
        : sidebarIconDefaultColor,
      width: expanded ? '204px' : squareLength,
      transition,
    };
  },
};

export const ComingSoonPosition = styled.div`
  position: absolute;
  left: 120px;
  top: -16px;
`;

export const WhiteBG = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: ${UtilityEffects.SidebarTransition()};
  background: ${props => (props.selected ? Colors.Sharp.White : 'unset')};
  border-radius: ${Effects.Border_Radius._XS};
  height: ${squareLength};
  width: ${props => (props.expanded ? `calc(100% - 8px)` : squareLength)};
`;
