import { cloneDeep } from '../../../../../lib/js';
import { fieldNames, adminServicesKeys as keys } from './variables';

export const prefillCustomInitialValues = (_, prevData) => {
  const initialValues = cloneDeep(_);

  const descKeys = [
    fieldNames.adminRoleDescription,
    fieldNames.editorRoleDescription,
    fieldNames.viewerRoleDescription,
  ];

  prevData.roles.forEach((role, index) => {
    initialValues[descKeys[index]] = role[keys.role.detail];
  });

  initialValues[fieldNames.permissions.row] = prevData.permissions;

  initialValues[fieldNames.systemName] = prevData[keys.systemName];

  return initialValues;
};
