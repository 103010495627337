import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { formatterDots } from '../../../features/parcels/api/getDots';
import { productKeys } from '../controllers/variables';

export const useGetProducts = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { cases: products = [], case_count: productCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('office_cases', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { products: maskData(products, mask), productCount },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PRODUCT_STATUS,
    CUSTOMER,
    PRODUCT_CATEGORY,
    PAGE,
    ITEMS_PER_PAGE,
    LOCATION,
    ACTOR_DATA,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PRODUCT_STATUS);
  const brand_id = extractSelectedValues(CUSTOMER);
  const product_id = extractSelectedValues(PRODUCT_CATEGORY);
  const sorter_id = extractSelectedValues(LOCATION);
  const actor_data = extractSelectedValues(ACTOR_DATA);

  const formattedParams = {
    search,
    status,
    brand_id,
    sorter_id,
    from_date,
    to_date,
    product_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    actor_data,
  };

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data.map(entry => ({
    ...entry,
    ...formatterDots(entry),
    id: entry[productKeys.productId],
  }));
};

const mask = {
  caBrand: productKeys.brand,
  caID: productKeys.productId,
  caAlias: productKeys.productAlias,
  caStatus: productKeys.productStatus,
  caStatusNext: productKeys.productActions,
  caSideloadDate: productKeys.productSideloadDate,
  createdAt: productKeys.inspectionDate,
  cpName: productKeys.subCategory,
  pdConfidence: productKeys.confidence,
  is_case_owner: productKeys.isProductOwner,
  caStatusUpdatedAt: productKeys.lastActivity,
  caParcel: productKeys.parcelId,
  paAlias: productKeys.parcel.alias,
  seID: productKeys.senderId,
  sender_brand_name: productKeys.senderBrandName,
  seName: productKeys.senderName,
  seCompany: productKeys.senderCompany,
  soID: productKeys.sorterId,
  soName: productKeys.sorterName,
  soCity: productKeys.sorterCity,
  soCountry: productKeys.sorterCountry,
  bcName: productKeys.category,
  cspQuantity: productKeys.productQuantity,
  brIsContracted: productKeys.isContracted,
  brName: productKeys.brandObj.name,
};
