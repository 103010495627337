import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import {
  formatTimeline,
  inactiveLinechart,
} from '../formatters/formatTimeline';

export const useGetTimeline = ({ searchParams, inactiveBrand, ignore }) => {
  const [
    { data = { data: [], lines: [], keys: [] }, isLoading, isError, error },
    _,
    __,
    refresh,
  ] = useFetchData('dashboard/timeline', {
    formatter: formatTimeline,
    params: formatParams(searchParams),
    ignore,
  });

  return {
    data: inactiveBrand || ignore ? inactiveLinechart() : data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
