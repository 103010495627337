import * as Yup from 'yup';
import { Icons } from '../../components/atoms/Icon/Icon.options';
import { Pages } from '../../routes/variables';
import { parseStringModifiers } from '../../utils/utils';

const {
  login,
  setup2FA,
  verify2FA,
  forgotPassword,
  setPassword,
  resetPassword,
  register,
  ssoLogin,
} = Pages;

const RequestFields = {
  emailReqField: 'email',
  passwordReqField: 'password',
};

export const Fields = {
  [login]: {
    emailField: RequestFields.emailReqField,
    passwordField: RequestFields.passwordReqField,
  },
  [setup2FA]: {
    emailField: RequestFields.emailReqField,
    smsField: 'sms',
    authenticatorField: 'authenticator',
  },
  [verify2FA]: {
    codeField: 'code',
  },
  [forgotPassword]: {
    emailField: RequestFields.emailReqField,
    captchaResponseField: 'response',
  },
  [setPassword]: {
    passwordField: RequestFields.passwordReqField,
    confirmPasswordField: 'confirmPassword',
  },
  [ssoLogin]: {
    emailField: RequestFields.emailReqField,
  },
};

const { emailField, passwordField } = Fields[login];
const {
  emailField: setupEmailField,
  smsField,
  authenticatorField,
} = Fields[setup2FA];

const { codeField } = Fields[verify2FA];

const { emailField: forgotEmailField } = Fields[forgotPassword];

const { passwordField: resetPasswordField, confirmPasswordField } =
  Fields[setPassword];

export const Texts = {
  [login]: {
    title: 'Welcome back!',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    minorButtonText: 'Forgot password?',
    mainButtonText: 'Log in',
    ssoLoginButtonText: 'Use Single Sign-On (SSO)',
  },
  [setup2FA]: {
    title: 'Two Factor Authentication',
    subtitle: 'Select how to receive your verification code',
    [setupEmailField]: 'Email',
    [smsField]: 'SMS',
    [authenticatorField]: 'Authenticator App',
    minorButtonText: 'Back',
    mainButtonText: 'Next',
    minorLeftIcon: Icons.ChevronLeft,
    mainRightIcon: Icons.ChevronRight,
    tooltipText: {
      [smsField]: 'Add your phone number to your profile',
      [authenticatorField]: 'Coming soon!',
    },
  },
  [verify2FA]: {
    title: 'Two Factor Authentication',
    subtitle: authType =>
      `Enter the 2FA code that you received via ${text2FA(authType)}`,
    codePlaceholder: 'Enter 2FA Code',
    codeMaxLength: 6,
    minorButtonText: 'Other options',
    mainButtonText: 'Verify',
    minorRightIcon: Icons.ChevronRight,
  },
  [forgotPassword]: {
    title: 'Forgot your password?',
    subtitle: 'Enter your email to receive the reset link',
    emailPlaceholder: 'Enter your email',
    mainButtonText: 'Send me the link',
    minorButtonText: 'Back',
    minorLeftIcon: Icons.ChevronLeft,
    successTitle: 'Reset link sent!',
    successSubtitle: 'Check your email inbox',
    successMinorButtonText: 'Back',
    successMinorLeftIcon: Icons.ChevronLeft,
  },
  [setPassword]: {
    passwordPlaceholder: 'Enter your new password',
    confirmPasswordPlaceholder: 'Confirm your new password',
    minorButtonText: 'Back',
    minorLeftIcon: Icons.ChevronLeft,
  },
  [resetPassword]: {
    title: 'Reset password',
    subtitle: parseStringModifiers(`In order to protect your account, your
    new password must contain:`),
    mainButtonText: 'Reset password',
  },
  [register]: {
    title: 'Welcome to Countercheck',
    subtitle: parseStringModifiers(`In order to protect your account, you need a
    safe password that must contain:`),
    mainButtonText: 'Set password',
  },
  [ssoLogin]: {
    title: 'Single Sign-on (SSO)',
    emailPlaceholder: 'Company Email',
    mainButtonText: 'Sign on',
    minorButtonText: 'Back',
    minorLeftIcon: Icons.ChevronLeft,
  },
};

export const FormValues = {
  [login]: {
    initialValues: {
      [emailField]: '',
      [passwordField]: '',
    },
    validationSchema: Yup.object({
      [emailField]: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
      [passwordField]: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum')
        .required('Please enter your password'),
    }),
  },
  [setup2FA]: {
    initialValues: {},
    validationSchema: Yup.object({}),
  },
  [verify2FA]: {
    initialValues: { [codeField]: '' },
    validationSchema: Yup.object({
      [codeField]: Yup.string()
        .required('Please enter an authentication code')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(
          Texts[verify2FA].codeMaxLength,
          `Must be exactly ${Texts[verify2FA].codeMaxLength} digits`
        )
        .max(
          Texts[verify2FA].codeMaxLength,
          `Must be exactly ${Texts[verify2FA].codeMaxLength} digits`
        ),
    }),
  },
  [forgotPassword]: {
    initialValues: { [forgotEmailField]: '' },
    validationSchema: Yup.object({
      [forgotEmailField]: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
    }),
  },
  [setPassword]: {
    initialValues: {
      [resetPasswordField]: '',
      [confirmPasswordField]: '',
    },
    validationSchema: Yup.object({
      [resetPasswordField]: Yup.string().required('Please enter your password'),
      [confirmPasswordField]: Yup.string()
        .oneOf([Yup.ref(resetPasswordField), null], 'Passwords must match')
        .required(''),
    }),
  },
  [ssoLogin]: {
    initialValues: {
      [emailField]: '',
    },
    validationSchema: Yup.object({
      [emailField]: Yup.string()
        .email('Invalid email address')
        .required('Please enter your company email address'),
    }),
  },
};

const text2FA = text => {
  switch (text) {
    case 'sms':
      return 'SMS';
    case 'email':
    default:
      return 'email';
  }
};
