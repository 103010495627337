import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import { timeFrameInitialFilterValues } from '../../../../components/organisms/Filters/utils';
import { FilterTypes } from '../../../../components/organisms/Filters/variables';
import { Colors } from '../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../constants';

export const DC = { ...DISPATCH_CONSTANTS };

export const filtersConstants = {
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
    initial: timeFrameInitialFilterValues.last28days,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
};

export const analyticsKeys = {
  date: 'date',
  parcelCount: 'parcelCount',
  imageCount: 'imageCount',
  parcelCoverage: 'parcelCoverage',
  processingPerformance: 'processingPerformance',
};

export const components = {
  overview: 'overview',
  detectionDetails: 'detectionDetails',
  parcelCount: 'parcelCount',
  requestsSeconds: 'requestsSeconds',
  processingTime: 'processingTime',
  counterfeits: 'counterfeits',
  return: 'return',
  LQ: 'LQ',
  depot: 'depot',
  shippingLabels: 'shippingLabels',
  dangerousGoods: 'dangerousGoods',
};

export const infoDescription = {
  [components.overview]:
    'This section shows Countercheck parcel count and processing statistics over the selected period.',
  [components.detectionDetails]:
    'This section shows the detection details of all scanned parcels over the selected period.',
  [components.parcelCount]:
    'This section shows the number of parcels processed in the facility.',
  [components.requestsSeconds]:
    'This section shows the number of requests returned over 3, 4 or 5 seconds.',
  [components.processingTime]:
    "This section shows Countercheck's processing times.",
  [components.counterfeits]:
    'This section shows the number of counterfeits detected.',
  [components.return]: 'This section shows return label detections.',
  [components.LQ]: 'This section shows Limited Quantity label detections.',
  [components.depot]: 'This section shows depot area label detections.',
  [components.shippingLabels]:
    'This section shows the detection of shipping label types over the selected period.',
  [components.dangerousGoods]:
    'This section shows the detection of hazard class and dangerous goods labels over the selected period.',
};

export const overviewStats = [
  analyticsKeys.parcelCount,
  analyticsKeys.imageCount,
  analyticsKeys.parcelCoverage,
  analyticsKeys.processingPerformance,
];

export const overviewTitles = {
  [analyticsKeys.parcelCount]: 'Parcel count',
  [analyticsKeys.imageCount]: 'Image count',
  [analyticsKeys.parcelCoverage]: 'Parcel coverage',
  [analyticsKeys.processingPerformance]: 'Processing performance',
};

export const detectionDetailsStats = [
  analyticsKeys.parcelCount,
  analyticsKeys.shippingLabelCount,
  analyticsKeys.barcodeCount,
  analyticsKeys.senderCount,
  analyticsKeys.receiverCount,
  analyticsKeys.ocrCount,
  analyticsKeys.aztecBarcodeCount,
];

export const detectionDetailsTitles = {
  [analyticsKeys.shippingLabelCount]: 'Shipping Label',
  [analyticsKeys.barcodeCount]: 'Barcode',
  [analyticsKeys.senderCount]: 'Sender',
  [analyticsKeys.receiverCount]: 'Receiver',
  [analyticsKeys.ocrCount]: 'OCR',
  [analyticsKeys.aztecBarcodeCount]: 'Aztec Barcode',
};

export const parcelCountColors = [
  Colors.Primary._100,
  Colors.Primary._400,
  Colors.Primary._600,
];

export const requestSecondsColor = [
  Colors.Warning._100,
  Colors.Warning._500,
  Colors.Warning._900,
];

export const processingTimeColors = [Colors.Success._050, Colors.Success._700];

export const counterfeitsColors = [Colors.Primary._400, Colors.Primary._600];

export const returnsColors = [Colors.Error._100, Colors.Error._700];

export const lqColors = [Colors.Violet._100, Colors.Violet._700];

export const depotColors = [Colors.Turquoise._100, Colors.Turquoise._500];

export const titles = {
  [DC.TIME_FRAME]: 'Last 28 days',
};

export const allOptions = undefined;

export const inactiveGraphs = [
  // prettier-ignore
  {"data":[{"Host":40713,"Beumer":38406,"Countercheck":34374,"date":"Nov 23"},{"Host":48693,"Beumer":41637,"Countercheck":30350,"date":"Dec 03"},{"Host":36668,"Beumer":42491,"Countercheck":37983,"date":"Dec 04"},{"Host":43866,"Beumer":30857,"Countercheck":31858,"date":"Dec 05"},{"Host":48744,"Beumer":44198,"Countercheck":30964,"date":"Dec 07"},{"Host":42891,"Beumer":37502,"Countercheck":44012,"date":"Dec 08"},{"Host":47531,"Beumer":44003,"Countercheck":38121,"date":"Dec 09"},{"Host":49822,"Beumer":40963,"Countercheck":44679,"date":"Dec 10"},{"Host":31689,"Beumer":30603,"Countercheck":37427,"date":"Dec 11"},{"Host":37217,"Beumer":43651,"Countercheck":30952,"date":"Dec 12"},{"Host":30830,"Beumer":30091,"Countercheck":46584,"date":"Dec 13"},{"Host":38094,"Beumer":38943,"Countercheck":32028,"date":"Dec 14"},{"Host":47637,"Beumer":35278,"Countercheck":44533,"date":"Dec 15"}],"keys":[{"key":"Host","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"Beumer","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"Countercheck","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"Host","stroke":Colors.Neutral._200},{"dataKey":"Beumer","stroke":Colors.Neutral._200},{"dataKey":"Countercheck","stroke":Colors.Neutral._200}]},
  // prettier-ignore
  {"data":[{"3 seconds":11,"4 seconds":5,"5 seconds":4,"date":"Nov 23"},{"3 seconds":10,"4 seconds":5,"5 seconds":3,"date":"Dec 03"},{"3 seconds":14,"4 seconds":8,"5 seconds":3,"date":"Dec 04"},{"3 seconds":13,"4 seconds":6,"5 seconds":3,"date":"Dec 05"},{"3 seconds":12,"4 seconds":7,"5 seconds":4,"date":"Dec 07"},{"3 seconds":10,"4 seconds":6,"5 seconds":0,"date":"Dec 08"},{"3 seconds":11,"4 seconds":7,"5 seconds":4,"date":"Dec 09"},{"3 seconds":12,"4 seconds":5,"5 seconds":3,"date":"Dec 10"},{"3 seconds":12,"4 seconds":8,"5 seconds":1,"date":"Dec 11"},{"3 seconds":10,"4 seconds":5,"5 seconds":0,"date":"Dec 12"},{"3 seconds":11,"4 seconds":6,"5 seconds":2,"date":"Dec 13"},{"3 seconds":11,"4 seconds":5,"5 seconds":4,"date":"Dec 14"},{"3 seconds":14,"4 seconds":8,"5 seconds":3,"date":"Dec 15"}],"keys":[{"key":"3 seconds","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"4 seconds","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"5 seconds","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"3 seconds","stroke":Colors.Neutral._200},{"dataKey":"4 seconds","stroke":Colors.Neutral._200},{"dataKey":"5 seconds","stroke":Colors.Neutral._200}]},
  // prettier-ignore
  {"data":[{"Processing time":0.22,"OCR Processing time":1.76,"date":"Nov 23"},{"Processing time":0.77,"OCR Processing time":1.63,"date":"Nov 28"},{"Processing time":0.74,"OCR Processing time":1.54,"date":"Nov 29"},{"Processing time":0.8,"OCR Processing time":1.66,"date":"Nov 30"},{"Processing time":0.68,"OCR Processing time":1.43,"date":"Dec 01"},{"Processing time":0.61,"OCR Processing time":1.27,"date":"Dec 02"},{"Processing time":0.92,"OCR Processing time":3.09,"date":"Dec 03"},{"Processing time":1.48,"OCR Processing time":2.42,"date":"Dec 04"},{"Processing time":0.9,"OCR Processing time":3.14,"date":"Dec 05"},{"Processing time":0.36,"OCR Processing time":1.05,"date":"Dec 07"},{"Processing time":0.11,"OCR Processing time":1.21,"date":"Dec 08"},{"Processing time":0.54,"OCR Processing time":1.31,"date":"Dec 09"},{"Processing time":0.96,"OCR Processing time":1,"date":"Dec 10"},{"Processing time":0.74,"OCR Processing time":1.94,"date":"Dec 11"},{"Processing time":0.27,"OCR Processing time":1.04,"date":"Dec 12"},{"Processing time":0.66,"OCR Processing time":1.31,"date":"Dec 13"},{"Processing time":0.78,"OCR Processing time":1.9,"date":"Dec 14"},{"Processing time":0.12,"OCR Processing time":1.84,"date":"Dec 15"}],"keys":[{"key":"Processing time","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"OCR Processing time","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"Processing time","stroke":Colors.Neutral._200},{"dataKey":"OCR Processing time","stroke":Colors.Neutral._200}]},
  // prettier-ignore
  {"data":[{"50% probability":335,"75% probability":32,"date":"Nov 23"},{"50% probability":417,"75% probability":12,"date":"Dec 03"},{"50% probability":342,"75% probability":47,"date":"Dec 04"},{"50% probability":171,"75% probability":49,"date":"Dec 05"},{"50% probability":447,"75% probability":18,"date":"Dec 07"},{"50% probability":478,"75% probability":10,"date":"Dec 08"},{"50% probability":193,"75% probability":17,"date":"Dec 09"},{"50% probability":112,"75% probability":43,"date":"Dec 10"},{"50% probability":321,"75% probability":45,"date":"Dec 11"},{"50% probability":494,"75% probability":11,"date":"Dec 12"},{"50% probability":246,"75% probability":16,"date":"Dec 13"},{"50% probability":403,"75% probability":42,"date":"Dec 14"},{"50% probability":445,"75% probability":24,"date":"Dec 15"}],"keys":[{"key":"50% probability","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"75% probability","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"50% probability","stroke":Colors.Neutral._200},{"dataKey":"75% probability","stroke":Colors.Neutral._200}]},
  // prettier-ignore
  {"data":[{"All":219,"Pre-alert":195,"date":"Nov 23"},{"All":477,"Pre-alert":107,"date":"Dec 03"},{"All":451,"Pre-alert":440,"date":"Dec 04"},{"All":279,"Pre-alert":290,"date":"Dec 05"},{"All":159,"Pre-alert":258,"date":"Dec 07"},{"All":205,"Pre-alert":287,"date":"Dec 08"},{"All":181,"Pre-alert":133,"date":"Dec 09"},{"All":180,"Pre-alert":482,"date":"Dec 10"},{"All":436,"Pre-alert":331,"date":"Dec 11"},{"All":346,"Pre-alert":175,"date":"Dec 12"},{"All":394,"Pre-alert":474,"date":"Dec 13"},{"All":463,"Pre-alert":177,"date":"Dec 14"},{"All":270,"Pre-alert":127,"date":"Dec 15"}],"keys":[{"key":"All","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"Pre-alert","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"All","stroke":Colors.Neutral._200},{"dataKey":"Pre-alert","stroke":Colors.Neutral._200}]},
  // prettier-ignore
  {"data":[{"Depot":261,"Door 47":388,"date":"Nov 23"},{"Depot":252,"Door 47":476,"date":"Dec 03"},{"Depot":302,"Door 47":317,"date":"Dec 04"},{"Depot":217,"Door 47":494,"date":"Dec 05"},{"Depot":257,"Door 47":394,"date":"Dec 07"},{"Depot":347,"Door 47":224,"date":"Dec 08"},{"Depot":496,"Door 47":494,"date":"Dec 09"},{"Depot":482,"Door 47":351,"date":"Dec 10"},{"Depot":444,"Door 47":286,"date":"Dec 11"},{"Depot":371,"Door 47":227,"date":"Dec 12"},{"Depot":129,"Door 47":128,"date":"Dec 13"},{"Depot":369,"Door 47":116,"date":"Dec 14"},{"Depot":140,"Door 47":293,"date":"Dec 15"}],"keys":[{"key":"Depot","color":Colors.Neutral._200,"column":true,"value":Constants.fallback},{"key":"Door 47","color":Colors.Neutral._200,"column":true,"value":Constants.fallback}],"lines":[{"dataKey":"Depot","stroke":Colors.Neutral._200},{"dataKey":"Door 47","stroke":Colors.Neutral._200}]},
];

// prettier-ignore
export const inactiveShippingLabels = {"data":[{"name":"Green","total":396550},{"name":"Black","total":379609},{"name":"Purple","total":335746},{"name":"Yellow","total":331654},{"name":"Pink","total":331090},{"name":"Violet","total":317708},{"name":"Orange","total":310540},{"name":"Blue","total":295569},{"name":"Red","total":294053},{"name":"Brown","total":268119}],"bars":[{"dataKey":"total","fill":Colors.Neutral._200}],"totalLabels":Constants.fallback}

// prettier-ignore
export const inactiveDangerousGoods = {"data":[{"key":"LQ","value":970,"valueLabel": Constants.fallback,"tooltipContent":"Limited Quantity","tooltipStyles":{"xShift":5.5,"yShift":57}},{"key":"ADR - Class 1:8","value":600,"valueLabel": Constants.fallback,"tooltipContent":"Class 1-8 Hazard Tooltip Text","tooltipStyles":{"tooltipWidth":"333px","xShift":5.5,"yShift":355}},{"key":"ADR - Class 9","value":400,"valueLabel": Constants.fallback,"tooltipContent":"Class 9 Hazard Tooltip Text","tooltipStyles":{"tooltipWidth":"253px","xShift":5.5,"yShift":115}},{"key":"UN 1XXX","value":321,"valueLabel": Constants.fallback,"tooltipContent":"UN 1XXX Tooltip Text","tooltipStyles":{"xShift":5.5,"yShift":115}},{"key":"UN 3XXX","value":208,"valueLabel": Constants.fallback,"tooltipContent":"Lithium Batteries","tooltipStyles":{"xShift":5.5,"yShift":57}},{"key":"EHS","value":156,"valueLabel": Constants.fallback,"tooltipContent":"Environmentally Hazardous Substance","tooltipStyles":{"xShift":5.5,"yShift":74}}],"total":4204,"main":{"value":Constants.fallback,"label":"Total Labels"}, "colors": [Colors.Neutral._300,Colors.Neutral._200,Colors.Neutral._100,Colors.Neutral._300,Colors.Neutral._200,Colors.Neutral._100]}
