import Button from '../../../../../components/molecules/Button/Button';
import { Label } from '../../../../../components/molecules/Label/Label';
import { Colors } from '../../../../../components/style';
import { Constants, re } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { formatTickets } from '../../support/api/getTickets';
import {
  actionIcon,
  actionTitles,
  actions,
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../controllers/variables';
import { statusStepsHistory } from '../variables';

const { fallback } = Constants;

export const useGetProductDetails = ({
  productId,
  actionFunctions,
  hasTicketCreatePermission,
}) => {
  const [
    { data = { images: [] }, isLoading = !!productId, isError, error },
    _,
    getProductDetails,
    refetchData,
  ] = useFetchData(`case/${productId}`, {
    formatter: formatter(actionFunctions, hasTicketCreatePermission),
    ignore: !productId,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    getProductDetails: productId =>
      getProductDetails({ url: `case/${productId}` }),
    refetchData,
  };
};

export const formatter =
  (actionFunctions, hasTicketCreatePermission) => data => {
    const {
      actors,
      attachments,
      auEmail: authorityEmail,
      auName: authorityName,
      auPhone: authorityPhone,
      //   caID: productId,
      caAlias: productAlias,
      caStatus: status,
      notes,
      caParcel: parcelId,
      paAlias: caseAlias,
      //   // pdMatchType: matchType,
      should_show_actors,
      // summary response
      activity: activityResponse,
      caAlias: alias,
      caID: productId,
      caBrand: brandId,
      // caStatus: status,
      soCity: city,
      soCountry: country,
      soName: sorter,
      bcName: category,
      cpName: subcategory,
      cspQuantity: quantity,
      is_case_owner: isProductOwner,
      paRiskType: riskType_,
      // // pdMatchType: matchType,
      pdConfidence: confidence,
      photos,
      sender_brand_name: senderBrandName,
      brName: brandName,
      soID: sorterId,
      tickets: tickets_,
    } = data;

    const shouldBlurActors = actors == null || !should_show_actors;

    const activity = formatActivity(activityResponse);

    const authorityDetails = formatAuthorityDetails({
      authorityEmail,
      authorityName,
      authorityPhone,
    });

    const productDetails = formatProductDetails({
      [productKeys.sorterCity]: city,
      [productKeys.sorterCountry]: country,
      [productKeys.category]: category,
      [productKeys.subCategory]: subcategory,
      [productKeys.sorterName]: sorter,
      [productKeys.productQuantity]: quantity,
      [productKeys.brandName]: brandName,
    });

    const reason = {
      title: getReasonTitle(senderBrandName),
      description: getSideloadExplanation(senderBrandName, riskType_),
      riskPotential: {
        value: confidence ?? 0,
        display: `${confidence == null ? 'n/a' : `${confidence}%`}`,
        fill: Colors.Primary._600,
      },
    };

    const tickets = formatTickets(tickets_);

    return {
      [productKeys.brandId]: brandId,
      [productKeys.actions]: actionsProps({
        actionStatus: status,
        actionFunctions,
        isProductOwner,
        hasTicketCreatePermission,
      }),
      [productKeys.activity]: activity,
      [productKeys.productId]: productId,
      [productKeys.productDetails]: productDetails,
      [productKeys.photos]: photos,
      [productKeys.isProductOwner]: isProductOwner,
      [productKeys.reason]: reason,
      [productKeys.riskType]: getRiskTypes(riskType_),
      [productKeys.productStatus]: status,
      [productKeys.tickets]: tickets,
      [productKeys.productAlias]: alias,
      [productKeys.sorterId]: sorterId,
      id: productId,
      [caseKeys.id]: parcelId,
      [caseKeys.alias]: caseAlias,
      [productKeys.attachments]: formatAttachments(attachments),
      [productKeys.productAlias]: productAlias,
      [productKeys.authorityDetails]: authorityDetails,
      [productKeys.notes]: notes,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.steps]: statusStepsHistory[status],
    };
  };

const formatAuthorityDetails = ({
  authorityEmail,
  authorityName,
  authorityPhone,
}) => {
  return authorityName || authorityEmail || authorityPhone
    ? [
        {
          title: 'NAME',
          info: authorityName ?? fallback,
        },
        {
          title: 'E-MAIL ADDRESS',
          info: authorityEmail ?? fallback,
        },
        {
          title: 'PHONE',
          info: authorityPhone ?? fallback,
        },
      ]
    : [];
};

export const actionsProps = ({
  actionStatus,
  actionFunctions,
  isProductOwner,
  hasTicketCreatePermission,
}) => {
  if (!actionFunctions) return;
  if (!isProductOwner) return [];

  const text = actionTitles[actionStatus];
  const onClick = actionFunctions[actionStatus];
  const icon = actionIcon[actionStatus];
  switch (actionStatus) {
    case Constants.Status.Inspection:
    case Constants.Status.Counterfeit:
    case Constants.Status['Non-counterfeit']:
    case Constants.Status.Stopped:
    case Constants.Status.Seized:
    case Constants.Status.Released:
      return [
        {
          Component: Label,
          props: {
            text: text,
            rightIcon: icon,
            size: '_S',
            hasBorder: true,
            key: 'label',
          },
        },
      ];
    case Constants.Status.Authentication:
      return [
        {
          Component: Button,
          props: {
            text: text[actions.authentication],
            onClick: onClick[actions.authentication],
            size: '_S',
            variant: 'Primary',
            key: 'Primary',
          },
        },
      ];

    default:
      throw Error();
  }
};

export const formatActivity = activityResponse => {
  return (activityResponse ?? []).map(entry => {
    const { createdAt: dateResponse, clContent: text } = entry;
    const date = `${formatDate(dateResponse, DateTypes.date)}, ${formatDate(
      dateResponse,
      DateTypes.time12
    )}`;
    return {
      text: text,
      date,
    };
  });
};

export const formatProductDetails = ({
  [productKeys.sorterCity]: city,
  [productKeys.sorterCountry]: country,
  [productKeys.category]: category,
  [productKeys.subCategory]: subcategory,
  [productKeys.sorterName]: sorter,
  [productKeys.productQuantity]: quantity,
  [productKeys.brandName]: brandName,
}) => {
  const location = joinStrings(city, country) ?? Constants.fallback;
  const productCategory =
    joinStrings(category, subcategory, Constants.fallback) ??
    Constants.fallback;
  return [
    {
      title: 'Location: ',
      text: location,
    },
    {
      title: 'Sortation center: ',
      text: sorter,
    },
    {
      title: 'Brand: ',
      text: brandName,
    },
    {
      title: 'Product category: ',
      text: productCategory,
    },
    {
      title: 'Item quantity: ',
      text: quantity,
    },
  ];
};

const formatAttachments = attachments => {
  maskEntry(attachments, attachmentsMask);

  return attachments.map(attachment => {
    const url = attachment[productKeys.attachmentURL];
    const it = {
      ...attachment,
      [productKeys.attachmentURL]: url,
      [productKeys.attachmentName]: extractNameFromStorageURL(url),
    };

    return it;
  });
};

const extractNameFromStorageURL = name => {
  try {
    const [uri, storageFileName] = name.split('case_files/');
    const [uuidName, storageSignature] =
      storageFileName.split('?X-Goog-Algorithm=');

    const fileName = uuidName.replaceAll(re.uuid, '').replace('_.', '.');

    return decodeURIComponent(fileName);
  } catch {
    return name;
  }
};

export const attachmentsMask = {
  caID: productKeys.attachmentId,
  caName: productKeys.attachmentURL,
};
