import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetAdminSorters } from '../api/getSorters';
import AdminSortersLayout from '../components/AdminSortersLayout';
import AddSorter from './AddSorter';
import { useAdminSortersReducer } from './AdminServices.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const { SELF, VIEW_SORTERS } = PermissionKeys.Admin.ADMIN_SORTERS;

const AdminSorters = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { dismiss, showModal } = useModal();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_SORTERS);
  const hasEditPermission = hasViewPermission;
  const resetFormRef = useRef(null);

  const ownOptions = {};

  const options = {};
  const filtersIsError = false;
  const filtersIsLoading = false;
  const refetchFilters = Utils.emptyFunction;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useAdminSortersReducer({
    initialSearchParams,
    resetSearchParams,
  });
  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location) || true;
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { sorters, sorterCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetAdminSorters({
    searchParams,
    ignore: ignoreDataFetch,
    with_permissions: true,
  });

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcel_evaluation];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError || sorters == null) return;
    setData(sorters);
  }, [sorters, isLoading, isError]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ``;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.admin_sorters]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
          endSlot={
            <Button text="Add sorter" onClick={showAddSorterModal} size="_S" />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  const showAddSorterModal = () =>
    showModal(AddSorter, {
      onSuccess,
    });

  const showEditSorterModal = ({ item }) =>
    showModal(
      AddSorter,
      {
        data: item,
        onSuccess,
      },
      {
        ignoreOverlayDismiss: true,
      }
    );

  const onSuccess = () => {
    refreshView();
    dismiss();
  };

  const editSorter = {
    value: uniqueId(),
    label: 'Edit Sorter',
    id: 'edit-Sorter',
    leftIcon: Icons.Edit3,
    onClick: showEditSorterModal,
  };

  const floatingMenuOptions = [editSorter];
  const optionClick = item => (option, e) => {
    floatingMenuOptions
      .find(o => o.id === option.id)
      ?.onClick({ item, option, e });
  };

  const rowAction = undefined;
  const noDataAction = undefined;

  return (
    <AdminSortersLayout
      tableProps={{
        data,
        headers,
        grid,
        rowAction,
        tableComponentProps: {
          options: floatingMenuOptions,
          optionClick,
        },
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default AdminSorters;
