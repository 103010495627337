import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { adminSortersKeys as keys } from '../controllers/variables';

export const useGetAdminSorters = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { sorters = [], sorter_count: sorterCount = [] } = {},
      isLoading = true,
      isError,
    },
    _,
    __,
    refreshTable,
  ] = useFetchData(`admin_sorters`, {
    params,
    ignore,
  });

  return {
    data: { sorters: formatter(sorters), sorterCount },
    isLoading,
    isError,
    refreshTable,
  };
};

const formatter = data => {
  maskEntry(data, mask);
  return data;
};

export const formatParams = params => {
  const { SEARCH: search, PAGE, ITEMS_PER_PAGE } = params;

  const formattedParams = {
    search,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

const mask = {
  soID: keys.id,
  soName: keys.name,
  soCity: keys.city,
  soCountry: keys.country,
  brID: keys.brand.id,
  brName: keys.brand.name,
  brImage: keys.brand.image,
  brIsContracted: keys.brand.isContracted,
  rep_name: keys.responsible.name,
  rep_image: keys.responsible.image,
  rep_id: keys.responsible.id,
};
