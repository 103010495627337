import { Constants } from '../../../constants';

const { default: styled } = require('@emotion/styled');
const { Effects, Size } = require('../../style');

const Bar = styled.div`
  height: ${props => Size.StatusShares[props.size]};
  border-radius: ${Effects.Border_Radius._2XL};
`;

export const Track = styled(Bar)`
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  position: relative;
  overflow: hidden;
`;

export const ProgressBar = styled(Bar)`
  position: absolute;
  width: ${({ percentage }) => percentage ?? 0}%;
  background: ${({ color }) => color};
  transition: ${Effects.Transition.Graph()};
  ${({ isLast, percentage }) =>
    isLast && percentage < 23
      ? `border-radius: ${Effects.Border_Radius.Circle}`
      : ''};
`;

const initialPercentage = 0;
export const initialShares = [
  {
    status: Constants.Status.Seized,
    percentage: initialPercentage,
  },
  {
    status: Constants.Status.Released,
    percentage: initialPercentage,
  },
  {
    status: Constants.Status.Stopped,
    percentage: initialPercentage,
  },
  {
    status: Constants.Status['Non-counterfeit'],
    percentage: initialPercentage,
  },
  {
    status: Constants.Status.Counterfeit,
    percentage: initialPercentage,
  },
  {
    status: Constants.Status.Authentication,
    percentage: initialPercentage,
  },
  {
    status: Constants.Status.Inspection,
    percentage: initialPercentage,
  },
];

export const errorShares = [
  {
    status: Constants.Gray,
    percentage: 100,
  },
];
