import usePostData from '../../../hooks/postHook';
import { userSettingsKeys } from '../controllers/variables';

export const useUpdatePassword = () => {
  const [{ body, isLoading, isError, error }, doUpdate] = usePostData(
    'user_profile/password'
  );

  const updatePassword = values => {
    const body = {
      old_password: values[userSettingsKeys.currentPassword],
      new_password: values[userSettingsKeys.newPassword],
    };

    doUpdate(body);
  };

  return { body, isLoading, isError, error, updatePassword };
};
