import { Icon, Text } from '../../../../../../components/atoms';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { MessageContentCSS, TicketContainerCSS } from '../../style';

export const RecentActivityTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H5}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const SectionSubtitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral._500}
    />
  );
};

export const TicketContainer = ({ children, onClick }) => {
  return <TicketContainerCSS onClick={onClick}>{children}</TicketContainerCSS>;
};

export const MetadataText = ({ text, bold, read }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Button}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight[bold ? 'Semibold' : 'Regular']}
      color={read ? Colors.Neutral._900 : Colors.Primary._600}
    />
  );
};

export const MetadataIcon = ({ name, read }) => (
  <Icon
    name={name}
    size={Size.Icon._S}
    color={read ? Colors.Neutral._900 : Colors.Primary._600}
  />
);

export const TicketTitleText = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Heading}
    size={FontKeys.Size.H6}
    weight={FontKeys.Weight.Semibold}
    ellipsis
  />
);

export const TicketSubitleText = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={FontKeys.Weight.Regular}
    color={Colors.Neutral._600}
    ellipsis
  />
);

export const TickerCreatorName = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._S}
    weight={FontKeys.Weight.Semibold}
  />
);

export const NotificationText = ({ text, bold, date }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight[bold ? 'Semibold' : 'Regular']}
      color={Colors.Neutral[date ? '_500' : '_900']}
      className={'notification-text'}
    />
  );
};

export const TicketDetailsTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const SubsectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const SubsectionText = ({ text, regular }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight[regular ? 'Regular' : 'Semibold']}
    />
  );
};

export const TicketStatusText = ({ text, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight[bold ? 'Semibold' : 'Regular']}
    />
  );
};

export const MessageContent = ({ text, fromClient }) => {
  return (
    <MessageContentCSS fromClient={fromClient}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
      />
    </MessageContentCSS>
  );
};

export const MessageData = ({ text, date }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral[date ? '_500' : '_900']}
    />
  );
};

export const FacilitatorDetailsTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
