import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Input } from '../../../../../../components/molecules';
import { tableComponents } from '../molecules';
import { AdminCategoriesTableContainer } from '../style';

export const AdminCategoriesTable = ({ tableProps }) => {
  return (
    <AdminCategoriesTableContainer
      {...tableProps}
      components={tableComponents(tableProps.tableComponentProps)}
    />
  );
};

export const Fields = ({ fields, selectOption }) => {
  return (
    <Flex column w gap="_S">
      {fields.map((field, index) => (
        <Input {...field} key={index} selectOption={selectOption} />
      ))}
    </Flex>
  );
};
