import { Route, Routes } from 'react-router-dom';
    import AdminSorters from './AdminSorters';
    
    export const AdminSortersRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<AdminSorters />} />
        </Routes>
      );
    };
    