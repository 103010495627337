import usePostData from '../../../../../hooks/postHook';

export const useRemoveCharacteristicItem = (
  instructionId,
  characteristicId
) => {
  const [
    { data, body, isLoading, isError, error },
    doRemoveCharacteristicItem,
  ] = usePostData(
    `office_knowledge_base/${instructionId}/characteristic/${characteristicId}/items`
  );

  // { characteristic_item_ids: string[] } = req.body
  return { data, body, isLoading, isError, error, doRemoveCharacteristicItem };
};
