import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { AdminReferencesTable } from './organisms/organisms';

const { SELF, VIEW_REFERENCES } = PermissionKeys.Brand.REFERENCES;

const AdminReferencesLayout = ({
  isLoading,
  isError,
  error,
  tableProps,
  tableStatusProps,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        hasPermission(VIEW_REFERENCES) && (
          <AdminReferencesTable {...tableProps} />
        )
      }
    />
  );
};

export default AdminReferencesLayout;
