import { Card } from '../../atoms';
import { AddNote, NotesContainer } from './Notes.components';

export const NotesLayout = ({
  notes,
  toggleAddingNote,
  addingNote,
  placeholder,
  onChange,
  handleSend,
  handleEnterKey,
  isLoading,
  notesIsLoading,
  title,
  noResults,
  hasEditPermission,
  props,
}) => {
  return (
    <Card title={title} className={`${props.className ?? ''} notes`}>
      <NotesContainer
        notes={notes}
        addingNote={addingNote}
        toggleAddingNote={toggleAddingNote}
        notesIsLoading={notesIsLoading}
        noResults={noResults}
        hasEditPermission={hasEditPermission}
      />
      {addingNote && (
        <AddNote
          placeholder={placeholder}
          onChange={onChange}
          handleSend={handleSend}
          handleEnterKey={handleEnterKey}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
};
