import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { productKeys } from '../../../features_public/products/controllers/variables';

const { Status } = Constants;

export const headers = {
  [productKeys.statusIndicator]: '',
  [productKeys.productImage]: 'Product ID',
  [productKeys.productAlias]: '',
  [productKeys.brandName]: 'Brand',
  [productKeys.productSideloadDate]: 'Catch date',
  [productKeys.sorter]: 'Catch location',
  [productKeys.productName]: 'Category',
  [productKeys.productQuantity]: 'Items',
  [productKeys.productStatus]: 'Status',
  [productKeys.lastActivity]: 'Last activity',
};

//  customer //
// ${!isLogistics ? '//' : ''} minmax(124px , 0.7fr) ${!isLogistics ? '//' : ''}
export const grid = isLogistics => ({
  gridTemplateColumns: cleanGridStyle(`
  // status indicator //
  16px
  // image //
  64px 
  // case ID //
  minmax(166px , 0.4fr)
  // brand //
  minmax(116px , 0.6fr)
  //  catch date //
  minmax(110px , 0.4fr)
  //   location //
  minmax(106px , 0.4fr)
  // product category //
  minmax(106px , 0.44fr)
  //  quantity //
  54px
  // status //
  144px
  // last activity //
  minmax(104px , 0.3fr) `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
});

export const DC = {
  ...DISPATCH_CONSTANTS,
  PRODUCT_STATUS: 'PRODUCT_STATUS',
  LOCATION: 'LOCATION',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  BRANDS: 'BRANDS',
  OWN: 'OWN',
  OTHERS: 'OTHERS',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
  },
  [DC.BRANDS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Archive,
    param: 'ownBrandId',
  },
  [DC.LOCATION]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
  },
  [DC.PRODUCT_CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
    param: 'category',
  },
  [DC.PRODUCT_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
    param: 'status',
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.BRANDS]: '',
  [DC.PRODUCT_CATEGORY]: '',
  [DC.PRODUCT_STATUS]: '',
  [DC.LOCATION]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'All time',
  [DC.BRANDS]: 'Brand',
  [DC.LOCATION]: 'Location',
  [DC.PRODUCT_CATEGORY]: 'Category',
  [DC.PRODUCT_STATUS]: 'Status',
};

export const allOptions = {
  [DC.BRANDS]: { label: 'All brands', value: Constants.all },
  [DC.PRODUCT_CATEGORY]: { label: 'All categories', value: Constants.all },
  [DC.PRODUCT_STATUS]: { label: 'All status', value: Constants.all },
  [DC.LOCATION]: { label: 'All locations', value: Constants.all },
};

export const actions = {
  authentication: 'authentication',
};

const noActionRequired = 'No action required';

const authenticationRequiredActionTitles = {
  [actions.authentication]: 'Authenticate now',
};

export const actionTitles = {
  [Status.Inspection]: noActionRequired,
  [Status.Authentication]: authenticationRequiredActionTitles,
  [Status.Counterfeit]: Status.Counterfeit,
  [Status['Non-counterfeit']]: Status['Non-counterfeit'],
  [Status.Stopped]: noActionRequired,
  [Status.Seized]: noActionRequired,
  [Status.Released]: noActionRequired,
};

export const actionIcon = {
  [Status.Counterfeit]: Icons.Check,
  [Status['Non-counterfeit']]: Icons.Check,
};

const ownBrand = Constants.OwnBrandKeyword;

const counterfeitParcel = 'Counterfeit Parcel';
const counterfeit = 'Counterfeit';
const nonDPDParcel = 'Non-DPD Parcel';
const adrParcel = 'ADR Parcel';
const illicitGoods = 'Illicit Parcel';

export const getReasonTitle = riskType => {
  switch (riskType) {
    case ownBrand:
      return 'Your Reference Database';
    case null:
    case undefined:
      return 'Countercheck AI';
    default:
      return 'Countercheck Reference Database';
  }
};

export const getSideloadExplanation = (senderName, riskType) => {
  return `According to ${who(senderName)}, this parcel may contain ${
    what[riskType]
  } goods`;
};

const who = senderName => {
  switch (senderName) {
    case ownBrand:
      return 'a reference you provided';
    case undefined:
    case null:
      return 'our detection technology';
    default:
      return "a partner brand's reference";
  }
};

const what = {
  [counterfeitParcel]: 'counterfeit',
  [counterfeit]: 'counterfeit',
  [adrParcel]: 'adr',
  [illicitGoods]: 'illicit',
  [nonDPDParcel]: '3rd party',
  null: 'suspicious',
  undefined: 'suspicious',
};

export const getRiskTypes = riskType => {
  switch (riskType) {
    case adrParcel:
      return 'Potential ADR';
    case illicitGoods:
      return 'Potential Illicit';
    case nonDPDParcel:
      return 'Potential 3rd party';
    case counterfeitParcel:
    case counterfeit:
    case undefined:
    case null:
    default:
      return 'Potential Counterfeit';
  }
};
