import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mapToLightweightState } from '../../../../../components/organisms/Filters/utils';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { clickedBy } from '../../../../../utils/utils';
import { NavURIs, Pages } from '../../../routes/variables';
import { useToggleRead } from '../api/toggleRead';
import TicketLayout from '../components/TicketLayout';
import { sortOptions } from './variables';

const from = NavURIs[Pages.support];

export default function Ticket({ ticket, sort, searchParams }) {
  const navigate = useNavigate();

  const {
    ticketId,
    alias,
    lastMessage,
    dateCreated,
    messageCount,
    read: initialRead,
    mainTopic,
    description,
    ticketCreator: { brandName, brandImage } = {},
    priority,
    status,
    type,
  } = ticket;

  const showDateCreated = sort === sortOptions.creationDate;
  const showLastMessage = sort === sortOptions.lastMessageDate;

  const [read, setRead] = useState(initialRead);
  const [shouldShowOptions, setShouldShowOptions] = useState(false);

  const { showSnackbarError } = useSnackbar();

  const ticketActionsRef = useRef();

  const { body, isLoading, isError, error, doToggleRead } = useToggleRead({
    ticketId,
  });

  const handleClick = e => {
    if (clickedBy(ticketActionsRef.current, e)) return;
    const state = {
      searchParams: mapToLightweightState(searchParams),
      from,
    };
    const to = ticketId;

    navigate(to, { state });
  };

  const markReadUnread = e => {
    const body = {
      read: read ? 0 : 1,
    };
    doToggleRead(body);
    setShouldShowOptions(false);
  };

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const { read } = body;
    setRead(read);
  }, [isLoading, body]);

  const showOptions = () => setShouldShowOptions(true);

  return (
    <TicketLayout
      handleClick={handleClick}
      read={read}
      alias={alias}
      showDateCreated={showDateCreated}
      dateCreated={dateCreated}
      showLastMessage={showLastMessage}
      lastMessage={lastMessage}
      messageCount={messageCount}
      showOptions={showOptions}
      shouldShowOptions={shouldShowOptions}
      markReadUnread={markReadUnread}
      ticketActionsRef={ticketActionsRef}
      mainTopic={mainTopic}
      description={description}
      image={brandImage}
      name={brandName}
      priority={priority}
      status={status}
      type={type}
    />
  );
}
