import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import { re } from '../../../../../constants';
import { cloneDeep } from '../../../../../lib/js';
import { knowledgeBaseKeys, manualInstructionNames } from './variables';

export const formatCreateValues = values_ => {
  const values = cloneDeep(values_);
  values.label = values[manualInstructionNames.productCategory].label;
  values[manualInstructionNames.productCategory] =
    values[manualInstructionNames.productCategory].value;
  values.urls = [];
  if (values[manualInstructionNames.cloudStorage])
    values.urls.push(values[manualInstructionNames.cloudStorage]);
  if (values[manualInstructionNames.video])
    values.urls.push(values[manualInstructionNames.video]);

  values.brand_id = values['brand'].value;

  return values;
};

export const withStateVars = (fields, categories) => {
  const categoryField = fields.find(
    field => field.name === manualInstructionNames.productCategory
  );

  const [{ options: dropdownCategories }] = prepareDataForDropdown(
    categories,
    knowledgeBaseKeys.productCategory,
    knowledgeBaseKeys.categoryId
  );
  categoryField.options = dropdownCategories;
  return fields;
};

export const extractNameFromStorageURL = name => {
  try {
    const [uri, storageFileName] = name.split('instruction_files/');
    const [uuidName, storageSignature] =
      storageFileName.split('?X-Goog-Algorithm=');

    const fileName = uuidName.replaceAll(re.uuid, '').replace('_.', '.');

    return decodeURIComponent(fileName);
  } catch {
    return name;
  }
};
