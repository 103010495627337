import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Layout } from '../../style';

export const AttachmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: ${Layout.Spacing._2XS};
`;

export const DownloadTag = styled.a`
  text-decoration: none;
  width: 100%;
  ${Styles.flex};
`;

export const SectionItem = styled.div`
  display: flex;
  width: 0;
  flex-grow: 1;
  gap: ${Layout.Spacing._2XS};
  // TODO: should be removed once all texts ellipsis PR is merged
  span {
    width: 0;
    flex-grow: 1;
  }
`;

export const UnboxedContainer = styled.div`
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
  .header .animated-icon {
    margin-right: -2px;
  }
`;
