import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { cloneDeep } from '../../../../../lib/js';
import { DC, staticOptions } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [
    { data = staticOptions, isLoading = true, isError, error },
    _,
    refetchFilters,
  ] = useFetchData('office_support_tickets/filters', {
    formatter,
    ignore,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { brands } = data;

  const newOps = cloneDeep(staticOptions);

  newOps[DC.CUSTOMER] = prepareDataForDropdown(
    brands,
    'brName',
    'brID'
    // {
    //   sectionTitle: 'Brands',
    //   bulkSelectableTags: [
    //     {
    //       column: 'is_contracted',
    //       columnValue: [1, true],
    //       selectableByValue: productBrandIsContracted,
    //     },
    //     {
    //       column: 'is_contracted',
    //       columnValue: [0, false, null, undefined],
    //       selectableByValue: productBrandIsNotContracted,
    //     },
    //   ],
    //   customLabel: item =>
    //     withContractedLabel(item, {
    //       contractedKey: 'is_contracted',
    //       labelKey: 'brName',
    //     }),
    // }
  );

  return newOps;
};
