import Dropzone from 'react-dropzone';
import { Flex } from '../../atoms/Flex/Flex';
import {
  DropzoneClickarea,
  DropzoneCSS,
  FileStatus,
} from './FileDropzoneNew.style';

export const FileDropzoneNew = ({
  handleDrop,
  fileState,
  percentageUploaded,
  uploadStatusContent,
  multiple = true,
}) => {
  const { isUploading, completedUpload } = fileState;

  return (
    <Dropzone onDrop={handleDrop} disabled={isUploading || completedUpload}>
      {({ getRootProps, getInputProps }) => (
        <DropzoneCSS className="file-dropzone">
          <DropzoneClickarea {...getRootProps()}>
            <input {...getInputProps()} multiple={multiple} />
            <Flex justify="center" align="center" w>
              <FileStatus
                isUploading={isUploading}
                uploadStatusContent={uploadStatusContent}
                percentageUploaded={percentageUploaded}
              />
            </Flex>
          </DropzoneClickarea>
        </DropzoneCSS>
      )}
    </Dropzone>
  );
};
