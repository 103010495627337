import styled from '@emotion/styled';
import { Colors, Effects, Layout } from '../../../components/style';
import { Styles } from '../../../constants';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HideTrack = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 0;
  position: relative;
  overflow: hidden;
  ${Styles.flex}
`;

const actionHeight = Layout.Spacing._S;
const actionOffset = `calc(${actionHeight} / -2)`;

export const FloatingAction = styled.div`
  position: absolute;
  z-index: 1;
  height: ${actionHeight};
  width: ${actionHeight};
  box-shadow: ${Effects.Box_Shadow._M};
  border-radius: ${Effects.Border_Radius.Circle};
  ${Styles.flexCenter};
`;

export const RightActionCSS = styled(FloatingAction)`
  inset: 0;
  margin: auto 0;
  left: unset;
  right: ${actionOffset};
  background: ${props => {
    return props.addsMoreFiles ? Colors.Primary._600 : Colors.Sharp.White;
  }};
`;

export const LeftSwipeCSS = styled(FloatingAction)`
  inset: 0;
  margin: auto 0;
  right: unset;
  left: ${actionOffset};
  background: ${Colors.Sharp.White};
`;

export const FileInstanceCSS = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${Styles.flexColumn};
  gap: ${Layout.Spacing._S};
  .file-dropzone {
    height: 100%;
  }
`;

export const RemoveInstanceCSS = styled(FloatingAction)`
  position: absolute;
  top: ${Layout.Spacing._2XS};
  right: ${Layout.Spacing._2XS};
  background: ${Colors.Sharp.White};
`;

// TODO: use a variable with amount shown instead of 25
export const InstanceTrack = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  left: ${props => -props.rightSwipe * (100 / props.showAmount)}%;
  transition: ${Effects.Transition.EaseInOut('left')};
  min-width: ${props =>
    Math.max(100, props.instanceNumber * (100 / props.showAmount))}%;
  gap: ${Layout.Spacing._XS};
`;

export const FilePreviewCSS = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex-grow: 1;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
`;
