import useDeleteData from '../../../../../hooks/deleteHook';

export const useRemoveCharacteristic = (instructionId, characteristicId) => {
  const [{ data, body, isLoading, isError, error }, doRemoveCharacteristic] =
    useDeleteData(
      `office_knowledge_base/${instructionId}/characteristic/${characteristicId}`
    );

  return { data, body, isLoading, isError, error, doRemoveCharacteristic };
};
