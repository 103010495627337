import { Popup } from '../../../../../components/templates/Popup';
import { Cancel, PrimaryAction, ToMainView } from './atoms/atoms';
import { ReferenceEntryFormNewLayout } from './organisms';
import { Actions, AddReferenceContent, BottomRow } from './style';

export const ReferenceEntryLayout = ({
  actingBrandProps,
  backToAdd,
  disabled,
  infoIcon,
  referenceFields,
  onCancel,
  primaryClick,
  saveText,
  selectOption,
  title,
  isAdmin,
}) => {
  return (
    <Popup size="_M" title={title} element={infoIcon}>
      <AddReferenceContent>
        <div />
        <ReferenceEntryFormNewLayout
          className="manual-add-reference"
          referenceFields={referenceFields}
          selectOption={selectOption}
          hasEditPermission={true}
          actingBrandProps={actingBrandProps}
          isAdmin={isAdmin}
        />
        <BottomRow>
          <ToMainView backToAdd={backToAdd} />
          <Actions>
            <Cancel onCancel={onCancel} />
            <PrimaryAction
              onClick={primaryClick}
              text={saveText}
              disabled={disabled}
            />
          </Actions>
        </BottomRow>
      </AddReferenceContent>
    </Popup>
  );
};
