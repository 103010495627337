import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const useGetReferences = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data = { references: [], referenceCount: 0 },
      isLoading = true,
      isError,
      error,
    },
    _,
    refreshTable,
  ] = useFetchData('senders', {
    params,
    ignore,
    formatter,
    large: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refreshTable,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);

  const { senders } = data;

  senders.forEach(sender => {
    // sender.total_case_count =
    //   sender[referenceIndexKeys.caseStatistics]?.total_case_count;
    // sender.active_case_count =
    //   sender[referenceIndexKeys.caseStatistics]?.active_case_count;
    sender.seized_case_count =
      sender[referenceIndexKeys.caseStatistics]?.seized_case_count;
  });

  return {
    references: data?.senders ?? [],
    referenceCount: data?.sender_count ?? 0,
  };
};

const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    COUNTRY,
    ADDED_BY,
    ONLY_ACTIVE: active_senders,
    PAGE,
    BRANDS,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const brand_id = extractSelectedValues(BRANDS);
  const country = extractSelectedValues(COUNTRY);
  const adding_client_id = extractSelectedValues(ADDED_BY);

  const formattedParams = {
    search,
    brand_id,
    country,
    adding_client_id,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };
  if (active_senders) formattedParams.active_senders = true;
  return formattedParams;
};

const mask = {
  seID: referenceIndexKeys.id,
  seAlias: referenceIndexKeys.alias,
  seName: referenceIndexKeys.name,
  seCompany: referenceIndexKeys.company,
  seAddress: referenceIndexKeys.address,
  notes: referenceIndexKeys.notes,
  sePhone: referenceIndexKeys.phone,
  // sePostcode: referenceIndexKeys.zipcode,
  seCity: referenceIndexKeys.city,
  // seState: referenceIndexKeys.state,
  seCountry: referenceIndexKeys.country,
  case_stats: referenceIndexKeys.caseStatistics,
  createdAt: referenceIndexKeys.dateCreated,
  brName: referenceIndexKeys.brand.name,
  brImage: referenceIndexKeys.brand.image,
};
