import styled from '@emotion/styled';
import { Colors, Effects, FilterContainer, Layout, Size } from '../../style';

export const themes = {
  page: {
    background: Colors.Neutral._100,
    'border-radius': Effects.Border_Radius._3XL,
    'overflow-x': 'hidden',
    border: 'unset',
    '.input': {
      'padding-right': 0,
      'padding-left': Layout.Spacing._2XS,
      input: {
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      },
    },
    iconColor: Colors.Neutral._500,
  },
  popup: {
    overflow: 'unset',
  },
  'full-page': {
    overflow: 'unset',
    background: Colors.Sharp.White,
    cursor: 'text',
    'max-width': 'unset',
    'border-radius': Effects.Border_Radius._3XL,
  },
};

export const Container = styled(FilterContainer)`
  ${({ theme }) => themes[theme ?? 'page']};
  align-items: center;
  padding: 0 ${Layout.Spacing._XS};
  height: ${({ size }) => Size.Input[size].height};
  .input {
  }
`;
