import { Image } from '../Image/Image';

const BrandImage = ({ src, style: customStyle, onLoad, onError }) => {
  return (
    <Image
      style={{ objectFit: 'contain', ...customStyle }}
      src={src}
      onLoad={onLoad}
      onError={onError}
    />
  );
};

export default BrandImage;
