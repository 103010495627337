import { useEffect, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  BarChart as ModuleBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Utils } from '../../../constants';
import { ChartTooltip } from '../../molecules/ChartTooltip/ChartTooltip';
import { Colors, Font_Properties, StatusColors } from '../../style';
import { leftMargin } from '../LineChart/utils';
import { CustomBar, CustomCursor } from './BarChart.style';
import { formatTick, getChartTicks } from './utils';

const BarChart = ({
  data = [],
  bars = [],
  type,
  colors = [],
  inactive,
  onBarClick = Utils.emptyFunction,
  ignoreTotalTooltip,
}) => {
  let tooltipFormatter;
  let xAxisTickFormatter;

  const [third, setThird] = useState(4);
  const [domainMax, setDomainMax] = useState(0);

  useEffect(() => {
    const { third, domainMax } = getChartTicks(data);
    setThird(third);
    setDomainMax(domainMax);
  }, [data]);

  switch (type) {
    default:
      tooltipFormatter = x => x;
      xAxisTickFormatter = x => x;
      break;
  }

  const filteredBars = bars.filter(x => {
    if (type === 'shipping_labels') return x.dataKey === 'total';
    return true;
  });

  const clickable =
    !inactive && onBarClick && onBarClick !== Utils.emptyFunction;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ModuleBarChart
        data={data}
        margin={{
          top: 8,
          left: leftMargin({ third, tickFormatter: xAxisTickFormatter }),
        }}
      >
        <CartesianGrid
          strokeDasharray="1"
          vertical={false}
          stroke={Colors.Neutral._300}
        />
        <XAxis
          dataKey="name"
          fontSize={Font_Properties.Size._S}
          stroke={Colors.Neutral._500}
          axisLine={false}
          tickLine={false}
          tickMargin={12}
          height={32}
          angle={0}
          tickFormatter={xAxisTickFormatter}
        />
        <YAxis
          orientation="left"
          domain={[0, third * 4]}
          tickFormatter={formatTick(domainMax)}
          ticks={[0, third, third * 2, third * 3, third * 4]}
          fontSize={Font_Properties.Size._S}
          stroke={Colors.Neutral._500}
          axisLine={false}
          tickLine={false}
        />
        {!inactive && (
          <Tooltip
            cursor={<CustomCursor />}
            formatter={tooltipFormatter}
            content={<ChartTooltip ignoreTotalTooltip={ignoreTotalTooltip} />}
            wrapperStyle={{ outline: 'none' }}
          />
        )}
        {filteredBars.map(bar => {
          const {
            dataKey,
            fill = StatusColors.Seized,
            backgroundBarSize,
            barSize,
          } = bar;

          if (!dataKey) return <></>;
          return (
            <Bar
              key={dataKey}
              dataKey={dataKey}
              fill={fill}
              barSize={backgroundBarSize ?? barSize}
              minPointSize={5}
              shape={
                <CustomBar
                  barSize={barSize}
                  colors={colors}
                  clickable={clickable}
                />
              }
              onClick={onBarClick}
            />
          );
        })}
      </ModuleBarChart>
    </ResponsiveContainer>
  );
};
export default BarChart;
