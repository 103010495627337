const { dateToUKFormat } = require('../../../../utils/timeUtils');

export const createParams = (startDate, endDate) => {
  const from = startDate && dateToUKFormat(startDate);
  const to = startDate && dateToUKFormat(endDate);

  const params = {
    fromDate: from && from.replace(/\//g, '-').split('-').reverse().join('-'),

    toDate: to && to.replace(/\//g, '-').split('-').reverse().join('-'),
  };
  return params;
};

export const markSelected = (options, selectedIndex) => {
  options.forEach(option => delete option.selected);
  if (selectedIndex >= 0 && selectedIndex < options.length)
    options[selectedIndex].selected = true;

  return options;
};
