import { useEffect, useState } from 'react';
import { Constants, PropKeys } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import { EditBrandLogoLayout } from '../components/EditBrandLogoLayout';
import { optionViews, primaryText, uploadStatusContent } from './variables';

const EditBrandLogo = () => {
  const [startUpload, setStartUpload] = useState(false);
  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();

  const { store, updateStore } = useStore();

  const onPrimaryClick = () => {
    if (!startUpload) setStartUpload(true);
    else
      handleImport({
        folder: 'brand_logos',
        url: 'profile/image',
        isPublic: true,
      });
  };

  const onCancel = () => {
    resetFileUpload();
    setStartUpload(false);
  };

  const tryAnotherFile = () => {
    resetFileUpload();
    setTimeout(() => setStartUpload(true), 0);
  };

  useEffect(() => {
    if (!startUpload) return;
    // this depends on other components using filestate to reset it on mount. reasonable assumption
    // however, better to have each component using the FileProvider to create their own separate instance
    if (fileState.files == null) setStartUpload(false);
    if (fileState.uploadSuccess) {
      const file = fileState?.files[0];
      updateStore({ [PropKeys.brandImage]: URL.createObjectURL(file) });
      onCancel();
    }
  }, [fileState]);

  return (
    <EditBrandLogoLayout
      handleDrop={handleDrop(Constants.FileUpload.uploadType.image, true)}
      fileState={fileState}
      disabled={
        startUpload &&
        (!fileState.files || fileState.errorType || fileState.isUploading)
      }
      percentageUploaded={percentage}
      uploadStatusContent={uploadStatusContent({
        fileState,
        resetFileUpload: tryAnotherFile,
        view: optionViews.brandLogo,
        preexistingFile: store[PropKeys.brandImage],
        startUpload,
      })}
      onCancel={onCancel}
      primaryClick={onPrimaryClick}
      startUpload={startUpload}
      primaryText={primaryText({
        startUpload,
      })}
    />
  );
};

export default EditBrandLogo;
