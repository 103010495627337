import {
  TableLoader,
  ViewLoader,
} from '../../molecules/LoadingAnimation/LoadingAnimation.exports';
import { TablePageContainer } from '../../style';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import { defaultErrorDisplay, defaultNoPermissionDisplay } from './variables';

const TableStatus = ({
  isLoading,
  isError,
  error,
  hasViewPermission,
  noSearchResults,
  noData,
  noDataDisplay,
  noSearchResultsDisplay,
  errorDisplay = defaultErrorDisplay,
  noPermissionDisplay = defaultNoPermissionDisplay,
  table,
  className,
  firstLoad,
  fullPage = true,
}) => {
  return hasViewPermission && firstLoad && isLoading ? (
    <ViewLoader />
  ) : (
    <TablePageContainer
      className={className ? className : ''}
      fullPage={fullPage}
    >
      {hasViewPermission === false || hasViewPermission == 0 ? (
        <>{<NoSearchResults {...noPermissionDisplay} />}</>
      ) : isError ? (
        <>
          {error ? (
            <NoSearchResults {...errorDisplay} description={error} />
          ) : (
            <NoSearchResults {...errorDisplay} />
          )}
        </>
      ) : isLoading ? (
        <>{<TableLoader />}</>
      ) : noData ? (
        <NoSearchResults {...noDataDisplay} />
      ) : noSearchResults ? (
        <>
          <NoSearchResults {...noSearchResultsDisplay} />
        </>
      ) : (
        <>{table}</>
      )}
    </TablePageContainer>
  );
};
export default TableStatus;
