import styled from '@emotion/styled';
import { useState } from 'react';
import { Colors } from '../../style';
import { RemainingCount } from '../../templates/Gallery/components';
import { Image } from '../Image/Image';

const handleIfEmpty = img => {
  return img || '';
};

export const Quadrant = ({ images: _imgs, style }) => {
  const images = [_imgs[0], _imgs[1], _imgs[2], _imgs[3]];
  return (
    <QuadrantCSS style={style}>
      {images.map((src, index) => {
        return (
          <ImageBox
            src={src}
            index={index}
            noOfImages={_imgs.length}
            key={index}
          />
        );
      })}
    </QuadrantCSS>
  );
};

const ImageBox = ({ src, index, noOfImages }) => {
  const [error, setError] = useState(false);
  return (
    <ImageBoxCSS className={`image-box _${index}`} error={error}>
      <Image src={handleIfEmpty(src)} onError={() => setError(true)} />
      {index === 3 && (
        <RemainingCount
          noOfImages={noOfImages}
          index={3}
          amount={4}
          type={'preview'}
          size="_S"
        />
      )}
    </ImageBoxCSS>
  );
};

const QuadrantCSS = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  overflow: hidden;

  .remaining-count {
    span {
      font-size: 6px !important;
    }
  }
`;

const ImageBoxCSS = styled.div`
  position: relative;
  background: ${({ error }) => (error ? Colors.Neutral._200 : 'transparent')};
  img {
    object-fit: contain !important;
  }
  &._0,
  &._1 {
    border-bottom: 1px solid ${Colors.Neutral._400};
  }
  &._0,
  &._2 {
    border-right: 1px solid ${Colors.Neutral._400};
  }
`;
