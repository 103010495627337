import { Styles } from '../../../constants';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects, FontKeys, Layout } from '../../style';

const sides = {
  ...Styles.flex,
  minWidth: '160px',
};

const constantStyle = {
  container: {
    ...Styles.flexBetween,
    // reflect/bind changes to this height in ViewFooter
    height: '56px',
    background: Colors.Sharp.White,
    width: '100%',
    '*': {
      userSelect: 'none',
    },
  },
  results: {
    ...sides,
  },
  noOfResults: {
    ...sides,
    gap: '16px',
  },
  selectionBox: {
    ...Styles.flex,
    position: 'relative',
    minWidth: '48px',
    gap: Layout.Spacing._2XS,
    borderRadius: Effects.Border_Radius._2XS,
    padding: Layout.Spacing._2XS,
    paddingRight: Layout.Spacing._2XS,
    cursor: 'pointer',
    border: `1px solid ${Colors.Neutral._300}`,
  },
  pageSelection: {
    ...Styles.flexCenter,
    gap: Layout.Spacing._2XS,
  },
  arrow: {
    cursor: 'pointer',
  },
  itemsPerPageOptions: {
    ...Styles.flexColumnCenter,
    position: 'absolute',
    bottom: '40px',
    width: '100%',
    left: '0',
    background: Colors.Sharp.White,
    padding: Layout.Spacing._2XS,
    gap: Layout.Spacing._2XS,
    boxShadow: Effects.Box_Shadow._XS,
    '> div': {
      paddingleft: '4px',
    },
  },
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: (_, { showPageOptions, isLoading }) => {
    return {
      page: ({ isEllipsis, selected }) => {
        return {
          cursor: isEllipsis ? 'auto' : 'pointer',
          background: selected ? Colors.Primary._600 : 'transparent',
          color: selected ? Colors.Sharp.White : Colors.Neutral._900,
          padding: `0 ${Layout.Spacing._2XS}`,
          borderRadius: Effects.Border_Radius._XL,
          height: Layout.Spacing._S,
          minWidth: Layout.Spacing._S,
          opacity: isLoading ? 0.5 : 1,
          ...Styles.flexCenter,
        };
      },
      itemsPerPageOptions: {
        display: showPageOptions ? 'flex' : 'none',
        opacity: isLoading ? 0.5 : 1,
      },
      pageSelection: {
        opacity: isLoading ? 0.5 : 1,
      },
      noOfResults: {
        opacity: isLoading ? 0.5 : 1,
      },
      results: {
        opacity: isLoading ? 0.5 : 1,
      },
      selectionBox: {
        opacity: isLoading ? 0.5 : 1,
      },
      arrow: {
        opacity: isLoading ? 0.5 : 1,
      },
      option: ({ selected, hover }) => {
        return {
          width: '100%',
          cursor: 'pointer',
          borderRadius: Effects.Border_Radius._XS,
          padding: Layout.Spacing._2XS,
          opacity: isLoading ? 0.5 : 1,
          ...Styles.flex,
          color: Colors.Neutral._900,
          fontWeight:
            selected || hover
              ? FontKeys.Weight.Semibold
              : FontKeys.Weight.Medium,
          background: selected ? Colors.Neutral._100 : 'transparent',
        };
      },
    };
  },
};
