import FuzzySearch from 'fuzzy-search';
import { Colors, Layout, StatusColors } from '../components/style';
import { Placeholders, PlaceholdersTypes, envVars } from '../constants';
import { cloneDeep, isArray, isObject } from '../lib/js';

const { environment } = envVars;

export const safeValue = (object, value) => {
  if (object === undefined) return;
  const allValues = [];
  const printValues = obj => {
    for (var key in obj) {
      if (typeof obj[key] === 'object') {
        printValues(obj[key]);
      } else {
        allValues.push(obj[key]);
      }
    }
  };
  printValues(object);
  if (allValues.includes(value)) return value;
  return;
};

const bold = '**';
const normal = '&&';
const bullet = '##';
const newline = '\n';
const enclosingSymbols = [bold, bullet, normal];

const parseStringToJSX = (text, symbol) => {
  if (!text || !symbol) return [''];
  let matchIndex = 0;
  let parsedJSX = [];
  let piece;
  let iteration = 0;
  do {
    matchIndex = text.indexOf(symbol);
    // if no symbol match, push the whole piece of text
    piece = text.substring(0, matchIndex === -1 ? text.length : matchIndex);
    if (piece) parsedJSX.push(piece);
    //remove previous text and symbol or all text if no symbol match
    text = text.substring(
      matchIndex === -1 ? text.length : matchIndex + symbol.length
    );

    const isEnclosing = enclosingSymbols.includes(symbol);
    if (isEnclosing) matchIndex = text.indexOf(symbol);
    //ignore if only one set of **
    if (matchIndex === -1) {
      if (text) parsedJSX.push(text);
    } else {
      if (isEnclosing) {
        const parsedPiece = text.substring(0, matchIndex);

        if (symbol === bold) {
          parsedJSX.push(
            <span style={{ fontWeight: '600' }} key={parsedPiece}>
              {parsedPiece}
            </span>
          );
        }
        if (symbol === normal) {
          parsedJSX.push(
            <span style={{ fontWeight: '400' }} key={parsedPiece}>
              {parsedPiece}
            </span>
          );
        }

        if (symbol === bullet) {
          parsedJSX.push(
            <span key={text.substring(0, 3) + matchIndex}>
              <span style={{ margin: '0 8px' }}> •</span>
              {parsedPiece}
            </span>
          );
        }
        text = text.substring(matchIndex + symbol.length);
      }
      if (symbol === newline)
        parsedJSX.push(<br key={text.substring(0, 3) + iteration} />);

      iteration++;
    }
  } while (text);
  return parsedJSX;
};

const formatTextArray = symbol => textArray => {
  let formattedJSX = [];
  textArray.flat(Infinity).forEach(piece => {
    if (typeof piece === 'string') {
      formattedJSX.push(parseStringToJSX(piece, symbol));
    } else formattedJSX.push(piece);
  });

  return formattedJSX;
};

const makeBold = formatTextArray(bold);
const makeNormal = formatTextArray(normal);
const makeBullet = formatTextArray(bullet);
const makeSpaceBreaks = formatTextArray(newline);

export const parseStringModifiers = data => {
  if (data == null || data === '' || isObject(data)) return;
  if (!isArray(data)) data = [data];
  const formatters = [makeBold, makeSpaceBreaks, makeBullet, makeNormal];
  const formatted = formatters.reduce((formatted, fn) => fn(formatted), data);
  return formatted.flat();
};

export const pascalToSpace = string => {
  const withSpaces = string.replace(/([A-Z])/g, ' $1').trim();
  return withSpaces;
};

export const pascalToSnake = string => {
  const snakey = string
    .replace(/\.?([A-Z]+)/g, function (x, y) {
      return '_' + y.toLowerCase();
    })
    .replace(/^_/, '');
  return snakey;
};

export const screenPosition = e => {
  const { x, y } =
    e?.currentTarget?.getBoundingClientRect() ??
    e?.target?.getBoundingClientRect() ??
    e?.getBoundingClientRect() ??
    {};

  return { x, y };
};

export const parentPosition = e => {
  const { offsetLeft: x, offsetTop: y } =
    e.currentTarget ?? e.target ?? e ?? {};

  return { x, y };
};

export const positionWithLeftPadding = e => {
  let { x, y } = screenPosition(e);
  const paddingLeft = parseInt(
    window.getComputedStyle(e.currentTarget).paddingLeft.slice(0, -2)
  );
  x += paddingLeft;
  return { x, y };
};

export const elementDimensions = e => {
  const { offsetWidth: width = 0, offsetHeight: height = 0 } =
    e.currentTarget ?? e.target ?? e ?? {};
  return { width, height };
};

export const distanceFromEdges = e => {
  const rect =
    e?.currentTarget?.getBoundingClientRect() ??
    e?.target?.getBoundingClientRect() ??
    e?.getBoundingClientRect() ??
    {};
  return {
    top: rect.top,
    right: window.innerWidth - (rect.right ?? 0),
    bottom: window.innerHeight - (rect.bottom ?? 0),
    left: rect.left,
  };
};

export const handleImageError = callback => e => {
  if (typeof callback === 'function') callback(e);
  e.target.onerror = null;
  e.target.style.opacity = 0;
};

export const handleImageLoad = callback => e => {
  if (typeof callback === 'function') callback(e);
  if (e.target.failed) e.target.failed = null;
  e.target.style.opacity = 1;
};

export const setImagePlaceholder =
  (size, type = PlaceholdersTypes.image) =>
  e => {
    e.target.src = Placeholders[type][size];
    e.target.onerror = null;
  };

export const numWithCommas = num => {
  if (isNaN(num) || num == null) return;

  let stringed = String(num);
  let [numStr, decimals] = stringed.split('.');

  for (let i = numStr.length - 3; i > 0; i -= 3) {
    numStr = numStr.slice(0, i) + ',' + numStr.slice(i);
  }

  return numStr + (decimals ? `.${decimals}` : '');
};

export const getURLParams = (location, queryParam) => {
  // coming from an external/shared url
  const urlParams = location.state?.search ?? location.search;
  if (!urlParams) return;
  const params = extractPropFromURL(queryParam, urlParams);
  return params;
};

export const extractPropFromURL = (prop, url) => {
  if (url == null || prop == null) return;
  const propValue = url
    ?.split('?')
    .filter(x => x.includes(prop))[0]
    ?.split(`${prop}=`)[1]
    ?.split('&')[0]
    ?.split('+');

  return propValue?.length > 1 ? propValue : propValue?.[0];
};

export const avoidPropagation = callback => e => {
  e?.stopPropagation();
  if (typeof callback === 'function') callback(e);
};

export const withoutPx = string => parseInt(string?.replace('px', '')) ?? 0;

export const getStatusColor = status => {
  const [baseStatus] = status?.split('_') ?? [];
  return StatusColors[baseStatus] ?? StatusColors.Gray;
};

export const Padded = ({ symbol, pad = '4px' }) => {
  return <span style={{ margin: `0 ${pad}` }}>{symbol}</span>;
};

export const filterOptionsFuzzy = (
  options,
  filter,
  keys,
  config = { caseSensitive: false, sort: true }
) => {
  if (filter == null || filter == '') return options;
  const searcher = new FuzzySearch(options, keys, config);

  const result = searcher.search(filter);
  return result;
};

export const lightweightState = data => {
  try {
    data = cloneDeep(data);
  } catch (err) {
    data = { ...data };
  }

  const lightweightData = {};
  Object.entries(data).forEach(([key, value]) => {
    delete value?.display;
    lightweightData[key] = value;
  });
  return lightweightData;
};

export const reverseKeyValue = obj =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));

// TODO: DOCUMENT HOW THIS WORKS
export const maskEntry = (entry, mask) => {
  if (isArray(entry)) {
    entry.map(_entry => maskEntry(_entry, mask));
  }
  if (isObject(entry)) doMask(entry, mask);
  return entry;
};

const doMask = (entry, mask) => {
  Object.entries(entry).forEach(([responseKey, value]) => {
    if (isObject(value) || isArray(value)) maskEntry(value, mask);
    const newKey = mask[responseKey] ?? responseKey;
    if (newKey !== responseKey) {
      // tries entry[newKey] first in case entry is being remasked
      entry[newKey] = entry[newKey] ?? value;
      delete entry[responseKey];
    }
  });
};

export const reverseMaskEntry = (entry, mask) => {
  mask = reverseKeyValue(mask);
  return maskEntry(entry, mask);
};

export const isValidField = (field, min, max) =>
  field && field.length >= min && field.length <= max;

const bargraphReserverdKeywords = [
  'name',
  'barSize',
  'tooltipColor',
  'customLabel',
];
export const getBargraphKeys = (
  entry = {},
  colors = [StatusColors.Sideload, StatusColors.Seized],
  hiddenKeys = [],
  { barSize = 8 } = {}
) => {
  const keys = Object.keys(entry)
    .filter(
      item =>
        !bargraphReserverdKeywords.includes(item) && !hiddenKeys.includes(item)
    )
    .map((key, index) => {
      return { key, color: colors[index] };
    });

  const bars = keys.map(item => {
    const { key, color } = item;
    return {
      dataKey: key,
      fill: hiddenKeys.includes(key) ? 'transparent' : color,
      barSize,
    };
  });

  return { keys, bars };
};

export const getLinechartKeysAndLines = (
  entry = {},
  colors = [StatusColors.Sideload, StatusColors.Seized],
  subs = []
) => {
  const keys = Object.keys(entry)
    .filter(item => item != 'date')
    .map((key, index) => {
      const value = subs?.[index];
      return { key, color: colors[index], column: value !== undefined, value };
    });

  const lines = keys.map(item => {
    const { key, color } = item;
    return {
      dataKey: key,
      stroke: color,
    };
  });

  return { keys, lines };
};

export const colorCardPadding = (paddingSize = '_S', colorWidth = '_2XS') => {
  const pad = Layout.Spacing[paddingSize];
  const colorPad = Layout.Spacing[colorWidth];
  return `${pad} ${pad} ${pad} calc(${pad} + ${colorPad})`;
};

export const ignoreNaNs = object =>
  Object.values(object).filter(value => !isNaN(value));

// maybe doable like this
// e.currentTarget.contains(e.relatedTarget)
export const clickedBy = (stopper, e, minDepth = 0) => {
  if (!stopper || !e) return;
  const clickedElement = e.target;
  let element = clickedElement;
  let depth = 0;

  while (element) {
    if (stopper == element) {
      return true;
    }
    element = element.parentNode;
    depth++;
  }

  if (depth < minDepth) return true;
  return false;
};

export const pct = (calc, decimals = 1) => (calc * 100).toFixed(decimals ?? 1);

export const downloadFile = url => window.open(url, '_blank');

export const logError = error => {
  if (environment === 'prod') return;
  console.error(error);
};

export const calculateThird = max => {
  if (!max) return 0.5;
  if (max == 9) return 3;

  const third = max / 3;
  //  the smaller the maxHeightOnChart, the closer the max will be to the range.
  //  don't go below 0.75 or above 1.5
  // 1.5 ~= 1 of the height
  // 1.0 ~= 0.75 of the height
  // 0.75 ~= 0.5 of the height
  // 0.9 ~= 0.85 of the height
  const maxHeightOnChart = 0.9;
  const scaled = third * maxHeightOnChart;

  const isPreciseNumber = String(max).includes('.');
  if (max < 4) return isPreciseNumber ? scaled : third;

  const rounded = String(Math.ceil(scaled));

  const padded = rounded.slice(0, 2).padEnd(rounded.toString().length, '0');

  const ans = padded;

  return ans;
};

export const openNewTab = url =>
  window.dispatchEvent(new CustomEvent('openNewTab', { detail: { url } }));

export const parseColors = ({ statusColor, group, shade } = {}) => {
  if ((!group || !shade) && !statusColor) return undefined;
  if (statusColor) return StatusColors[statusColor];
  return Colors[group]?.[shade];
};

export const prefillDataForEditing = ({
  initialValues: _,
  prevData,
  fields,
  mapFieldsToResponse = {},
  optionValueKeys = {},
}) => {
  const initialValues = cloneDeep(_);
  const selectFields = fields.filter(field => field.type === 'select');

  Object.keys(initialValues).forEach(key => {
    const responseValue = prevData[mapFieldsToResponse[key] ?? key];
    initialValues[key] = responseValue;
    const isDropdown = selectFields.some(field => field.name === key);
    if (isDropdown) {
      const field = selectFields.find(field => field.name === key);
      const optionValueKey = optionValueKeys[key] ?? 'value';
      initialValues[key] = (field.options ?? []).find(
        o => o[optionValueKey] === responseValue
      );
    }
  });

  return initialValues;
};

export const withStringDropdownValues = values => {
  const body = { ...values };
  Object.keys(body).forEach(key => {
    const value = body[key];
    if (typeof value === 'object') {
      body[key] = value?.value;
    }
  });

  return body;
};

export const getAlias = title => {
  try {
    const [_, alias] = title?.split(' ');
    return alias;
  } catch {}
};
