import { Flex } from '../../components/atoms/Flex/Flex';
import { Label } from '../../components/molecules/Label/Label';
import { Colors } from '../../components/style';
import { EmailInput, PasswordRequestSuccess } from './molecules/Molecules';
import { InfoCard, SuccessCard } from './organisms/Organisms';

const ForgotPasswordLayout = ({
  initialValues,
  validationSchema,
  onSubmit,
  title,
  subtitle,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  minorLeftIcon,
  emailPlaceholder,
  emailName,
  success,
  successSubtitle,
  successTitle,
  successMinorButtonClick,
  successMinorButtonText,
  successMinorLeftIcon,
  showCaptcha,
  captcha,
  onVerify,
  isLoading,
}) => {
  return (
    <>
      {!success ? (
        <InfoCard
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          title={title}
          minorButtonClick={minorButtonClick}
          minorButtonText={minorButtonText}
          mainButtonText={mainButtonText}
          minorLeftIcon={minorLeftIcon}
          isLoading={isLoading}
          showCaptcha={showCaptcha}
          captcha={captcha}
          onCaptchaVerify={onVerify}
        >
          <Flex column gap="_M">
            <Label text={subtitle} textColor={Colors.Neutral._900} size="_L" />
            <EmailInput placeholder={emailPlaceholder} name={emailName} />
          </Flex>
        </InfoCard>
      ) : (
        <SuccessCard
          title={successTitle}
          minorButtonClick={successMinorButtonClick}
          minorButtonText={successMinorButtonText}
          minorLeftIcon={successMinorLeftIcon}
        >
          <PasswordRequestSuccess text={successSubtitle} />
        </SuccessCard>
      )}
    </>
  );
};
export default ForgotPasswordLayout;
