import { Formik } from 'formik';
import { useEffect, useReducer, useRef } from 'react';
import { Flex } from '../../atoms/Flex/Flex';
import { ModalText, Options } from './components';
import { ExportAlert, ExportForm, ModalStyles } from './style';
import { exportReducer } from './utils';

export default function ExportData({
  values: valuesRef,
  options,
  reducer = exportReducer,
  initialState,
  alert = 'Exporting all pages might take a few moments',
  ...props
}) {
  const [selected, dispatch] = useReducer(reducer, initialState);
  valuesRef.current = selected;

  useEffect(() => {
    changedSelectionRef.current = true;
  }, [selected]);

  const changedSelectionRef = useRef(false);

  const setSelected = id => () => dispatch({ type: 'select', payload: id });

  const content = (
    <Formik initialValues={{ note: '' }}>
      {({ values, resetForm }) => {
        setTimeout(() => {
          if (changedSelectionRef.current) {
            resetForm();
            changedSelectionRef.current = false;
          }
        }, 0);
        valuesRef.current = { ...values, ...selected };

        return (
          <ExportForm>
            <Flex column w h gap="_S">
              <ModalText text={initialState.subtitle} />
              <Options
                options={options}
                selected={selected}
                setSelected={setSelected}
              />
              {}
            </Flex>
            {alert ? <ExportAlert text={alert} /> : null}
          </ExportForm>
        );
      }}
    </Formik>
  );
  return <ModalStyles {...props} content={content} />;
}
