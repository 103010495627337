import usePostData from '../../../../../hooks/postHook';
import { formatter, mask, reverseMask } from './getParcelsToEvaluate';

export const useDoParcelAction = () => {
  const [{ data, body, isLoading, isError, error }, doAction] = usePostData(
    '',
    {
      formatter: customFormatter,
    }
  );

  // if we need more REST types, keep data on a separate useState
  // and use different useEffects to listen for changes and udpate data
  // the doParcelAction should just check for req type and call the appropriate

  const doParcelAction = action => {
    doAction(formatter(action, reverseMask), action.post_url);
  };

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doParcelAction,
  };
};

const customFormatter = data => {
  const asArray = formatter([data], mask);
  return asArray[0];
};
