import { Icon } from '../../atoms';
import { BadgeText } from './components';
import { Container, Dot } from './style';

const DynamicBadge = ({
  text,
  iconLeft = {},
  iconRight = {},
  size = '_M',
  background,
  color,
  borderColor,
  dot,
  ...props
}) => {
  const styleProps = {
    background,
    size,
    borderColor,
  };
  return (
    <Container
      justify="center"
      align="center"
      {...styleProps}
      {...props}
      className={`dynamic-badge ${props.className}`}
      gap="_2XS"
    >
      {dot ? <Dot color={color} size={size} /> : <Icon {...iconLeft} />}
      <BadgeText text={text} size={size} color={color} />
      <Icon {...iconRight} />
    </Container>
  );
};

export default DynamicBadge;
