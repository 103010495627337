import { useState } from 'react';
import { Input } from '../Input/Input';
import { EyeIcon } from './PasswordInput.style';

export const PasswordInput = ({
  name,
  placeholder,
  onChange,
  size = '_M',
  leftIcon,
  readOnly,
  value,
}) => {
  const [revealPassword, setRevealPassword] = useState(false);
  const toggleRevealPassword = () => setRevealPassword(x => !x);

  return (
    <Input
      name={name}
      placeholder={placeholder}
      size={size}
      onChange={onChange}
      type={revealPassword ? 'text' : 'password'}
      leftIcon={leftIcon}
      rightIcon={
        <EyeIcon
          revealPassword={revealPassword}
          toggleRevealPassword={toggleRevealPassword}
        />
      }
      hasError
      readOnly={readOnly}
      value={value}
    />
  );
};
