import { API } from '../../lib/api';

export const verify2FAcode = async ({ user_id, key, credential }) => {
  const body = {
    user_id,
    key,
    credential,
  };

  const { data, status, error, token } = await API.POST('authenticate', body);

  if (error || status !== 200) return false;

  return { data, token };
};
