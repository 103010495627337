import { useReducer, useState } from 'react';
import { API } from '../lib/api';
import { useAuth, useReauth } from '../providers/AuthProvider';
import { sanitizeStrings } from '../utils/stringUtils';
import useDeepCompareEffect from '../utils/useDeepCompareEffect';

const dataDeleteReducer = (state, action) => {
  const { payload, params } = action;
  switch (action.type) {
    case 'DELETE_INIT':
      return { ...state, isLoading: true, isError: false, isCompleted: false };
    case 'DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload,
        isCompleted: true,
        params,
      };
    case 'DELETE_FAILURE':
      let error_msg;
      try {
        error_msg = JSON.parse(action.error.message).message;
      } catch (_) {
        error_msg = action.error?.message ?? 'Error';
      }
      return {
        ...state,
        data: payload,
        error: error_msg,
        params,
        isLoading: false,
        isError: true,
        isCompleted: true,
      };
    default:
      throw new Error();
  }
};

const useDeleteData = (
  initialUrl,
  {
    initialData = undefined,
    params: initialParams = undefined,
    formatter = data => data,
    failureFormatter = error => error,
  } = {}
) => {
  const [propsUrl, setPropsUrl] = useState(initialUrl);
  const [propsParams, setPropsParams] = useState(initialParams);

  useDeepCompareEffect(() => {
    setPropsParams(initialParams);
  }, [initialParams]);

  const manualDelete = ({ url = propsUrl, params = propsParams } = {}) =>
    withReauth(deleteData, token, [{ url, params_: params, didCancel: false }]);

  const [state, dispatch] = useReducer(dataDeleteReducer, {
    isLoading: false,
    isError: false,
    error: { message: undefined },
    data: initialData,
  });

  const [{ token }] = useAuth();
  const { withReauth, handleApiError, handleResponseError } = useReauth();

  const deleteData = async (
    token,
    { url = propsUrl, params_ = propsParams, didCancel }
  ) => {
    dispatch({ type: 'DELETE_INIT' });

    const oldParams = params_ || {};

    const params = {
      ...oldParams,
    };

    const config = {
      params: sanitizeStrings(params),
      headers: {
        Authorization: token,
      },
    };

    try {
      if (didCancel) return;
      const { data, status, error } = await API.DELETE(url, config);

      handleResponseError({ status, error });

      dispatch({
        type: 'DELETE_SUCCESS',
        payload: formatter(data ?? status),
        params,
      });
      return formatter(data, params);
    } catch (error) {
      if (didCancel) return;
      handleApiError(error);

      dispatch({
        type: 'DELETE_FAILURE',
        payload: failureFormatter(error),
        params,
        error,
      });
      return failureFormatter(error, params);
    }
  };

  return [state, manualDelete];
};

export default useDeleteData;
