import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { authenticationKeys as keys } from '../variables';

export const useGetFeedbackQuestions = ({ ignore = true }) => {
  const [{ data, body, isLoading, isError, error }, _, doGetFeedbackQuestions] =
    useFetchData('', {
      formatter,
      failureFormatter: _ => [],
      ignore,
    });

  return { data, body, isLoading, isError, error, doGetFeedbackQuestions };
};

const formatter = data => {
  data = maskEntry(data, mask);
  return data;
};

const mask = {
  afaDescription: keys.feedback.answer.description,
  afaID: keys.feedback.answer.id,
  afaTitle: keys.feedback.answer.title,
  afaHasNote: keys.feedback.answer.hasNote,

  afqDescription: keys.feedback.question.description,
  afqID: keys.feedback.question.id,
  afqTitle: keys.feedback.question.title,
};
