import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  clickedBy,
  distanceFromEdges,
  elementDimensions,
  screenPosition,
} from '../../../utils/utils';
import { MenuOptions } from './components';
import { Container } from './style';

const FloatingMenu = ({
  disabled,
  children,
  options,
  optionClick,
  selectedOption = {},
  placeHorizontal = 'left',
  optionDisplay,
}) => {
  const [position, setPosition] = useState({ x: null, y: null });
  const [isHovering, setIsHovering] = useState(false);
  const [menuIsHovering, setMenuIsHovering] = useState(false);

  const containerRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleHoverIn = e => {
    setIsHovering(true);
    setEventPosition(e);
  };

  const handleHoverOut = _ => setIsHovering(false);

  const handleMenuHover = bool => () => setMenuIsHovering(bool);

  const setEventPosition = e => {
    let { x, y } = screenPosition(e);
    const { height } = elementDimensions(e);
    const { width: menuWidth, height: menuHeight } = elementDimensions(
      menuRef.current
    );
    y += height;
    if (placeHorizontal === 'left') x -= menuWidth - 24;
    if (placeHorizontal === 'right') x += 0;

    const { bottom } = distanceFromEdges(e);
    if (bottom < menuHeight) y -= menuHeight + height;

    setPosition({ x, y });
  };

  const handleOptionClick = option => e => {
    setMenuIsHovering(false);
    setIsHovering(false);
    if (typeof optionClick === 'function') optionClick(option, e);
  };

  const handleOutsideClick = e => {
    if (clickedBy(containerRef.current, e)) return;
    handleHoverOut();
    setMenuIsHovering(false);
  };

  return (
    <Container
      onClick={handleHoverIn}
      className="floating-menu"
      ref={containerRef}
    >
      {/* icon */}
      {children}
      {createPortal(
        <MenuOptions
          options={options}
          optionClick={handleOptionClick}
          selectedOption={selectedOption}
          position={position}
          show={(isHovering || menuIsHovering) && !disabled}
          placeHorizontal={placeHorizontal}
          menuRef={menuRef}
          onPointerOver={handleMenuHover(true)}
          onPointerOut={handleMenuHover(false)}
          // optionDisplay is a function that takes an option and returns a React element
          // ({label, ...props}) => <Button />
          optionDisplay={optionDisplay}
        />,
        document.body
      )}
    </Container>
  );
};
export default FloatingMenu;
