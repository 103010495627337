import { Colors } from '../../../../components/style';
import { Constants } from '../../../../constants';
import { initialSliderState, authenticationKeys as keys } from './variables';

const { Icons } = require('../../../../components/atoms/Icon/Icon.options');

const getColor = minutes => {
  if (minutes < 30) {
    return Colors.Error._500;
  } else if (minutes < 60) {
    return Colors.Warning._900;
  } else if (minutes < 180) {
    return Colors.Warning._500;
  } else {
    return Colors.Success._700;
  }
};

const getTimeString = minutes => {
  if (minutes < 30) {
    return 'Last call';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    const hours = Math.floor(minutes / 60);
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  }
};

export const formatRemainingTime = minutes => {
  const time = getTimeString(minutes);
  const color = getColor(minutes);
  return {
    time,
    color,
    icon: minutes < 30 ? Icons.AlertCircle : '',
  };
};

export const slideReducer = (state, { type, payload }) => {
  const { activeSlideIndex, productsBeingAuthenticated } = state;
  switch (type) {
    case 'next':
      return {
        ...state,
        activeSlideIndex: activeSlideIndex + 1,
        atStart: false,
        atEnd: activeSlideIndex + 1 === productsBeingAuthenticated.length - 1,
      };
    case 'prev':
      return {
        ...state,
        activeSlideIndex: activeSlideIndex - 1,
        atStart: activeSlideIndex - 1 === 0,
        atEnd: false,
      };
    case 'init':
      return {
        ...state,
        activeSlideIndex: 0,
        isLoading: false,
        productsBeingAuthenticated: payload,
        atStart: true,
        atEnd: payload.length === 1,
      };
    case 'update_status':
      return {
        ...state,
        productsBeingAuthenticated: payload,
      };
    case 'loading': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'save_parcel_results':
      return {
        ...state,
        parcel_results: { ...state.parcel_results, ...payload },
      };
    case 'product_details':
      return {
        ...state,
        product_details: { ...state.product_details, ...payload },
      };
    case 'dismiss':
    default:
      return {
        ...initialSliderState,
        parcel_results: state.parcel_results,
        product_details: state.product_details,
      };
  }
};

export const actionRequiredStatus = [Constants.Status.Authentication];

export const getProductsToAuthenticate = data =>
  data.filter(({ [keys.status]: status }) =>
    actionRequiredStatus.includes(status)
  );

const feedbackReducer = (state, { type, payload }) => {
  switch (type) {
    case 'select':
    default:
      return {
        ...state,
        value: payload,
      };
  }
};

export const feedbackModalReducer = {
  [Constants.Status.Counterfeit]: feedbackReducer,
  [Constants.Status['Non-counterfeit']]: feedbackReducer,
};

export const setAllMessagesLeft = messages => {
  return messages.map(message => {
    return {
      ...message,
      // either is_office or type works
      is_office: 1,
      // type: 'Brand',
    };
  });
};

export const getAuthenticationScoreTime = (hours, mins) => {
  if (hours == null && mins == null) return Constants.fallback;
  if (hours == 0 && mins == 0) return Constants.fallback;

  const time = `${hours ? `${hours} h` : ''} ${mins ? `${mins} min` : ''}`;

  return time;
};

export const getRowsThatNeedHighlighting = (data, tab) => {
  const rows = [];

  if (tab !== 'required') return rows;

  getProductsToAuthenticate(data).forEach(
    ({ [keys.remainingAuthTime]: remainingAuthTime }, index) => {
      if (remainingAuthTime?.value?.includes('Last call')) rows.push(index);
    }
  );

  return rows;
};
