import { handleImageError, handleImageLoad } from '../../../utils/utils';
import { styles } from './Image.style';

export const Image = ({
  src,
  style: customStyle,
  onLoad,
  onError,
  ...props
}) => {
  const style = styles({ customStyle });
  return (
    <img
      src={src}
      onError={handleImageError(onError)}
      onLoad={handleImageLoad(onLoad)}
      style={style}
      alt=""
      {...props}
    />
  );
};
