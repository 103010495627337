import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({
  ignore,
  [DC.ONLY_ACTIVE]: active_senders,
  isAdmin,
}) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData(`${isAdmin ? 'admin_' : ''}senders/filters`, {
      formatter,
      ignore,
      params: { active_senders },
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

const formatter = data => {
  const { brands, countries, adding_clients, types } = data;

  const newOps = {};

  newOps[DC.BRANDS] = prepareDataForDropdown(brands, 'brName', 'brID');

  newOps[DC.COUNTRY] = prepareDataForDropdown(
    countries,
    'seCountry',
    'seCountry'
  );

  newOps[DC.ADDED_BY] = prepareDataForDropdown(
    adding_clients,
    'usDisplayName',
    'bguaID',
    { sectionTitle: 'Others' }
  );

  const types_ = types.map(type => ({
    label: type,
    value: type,
  }));
  newOps[DC.TYPE] = prepareDataForDropdown(types_, 'label', 'value');

  return newOps;
};
