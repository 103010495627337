import { Flex } from '../../components/atoms/Flex/Flex';
import { Icons } from '../../components/atoms/Icon/Icon.options';
import { TableLoader } from '../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { PasswordInput } from '../../components/molecules/PasswordInput/PasswordInput';
import PasswordRules from '../../components/organisms/PasswordRules/PasswordRules';
import { CardText } from './atoms/Atoms';
import { InfoCardFlexibleHeight } from './organisms/Organisms';

const SetPasswordLayout = ({
  initialValues,
  validationSchema,
  onSubmit,
  title,
  subtitle,
  minorButtonClick,
  minorButtonText,
  minorLeftIcon,
  mainButtonText,
  passwordPlaceholder,
  passwordName,
  confirmPasswordPlaceholder,
  confirmPasswordName,
  passwordRules,
  onChange,
  isLoading,
  customIsValid,
}) => {
  return isLoading ? (
    <TableLoader />
  ) : (
    <InfoCardFlexibleHeight
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      title={title}
      subtitle={subtitle}
      minorButtonClick={minorButtonClick}
      minorButtonText={minorButtonText}
      minorLeftIcon={minorLeftIcon}
      mainButtonText={mainButtonText}
      customIsValid={customIsValid}
    >
      <Flex gap="_M" column>
        <CardText text={subtitle} />
        <PasswordRules rules={passwordRules} />
      </Flex>
      <Flex column>
        <PasswordInput
          placeholder={passwordPlaceholder}
          name={passwordName}
          onChange={onChange}
          leftIcon={Icons.Lock}
        />
        <PasswordInput
          placeholder={confirmPasswordPlaceholder}
          name={confirmPasswordName}
          leftIcon={Icons.Lock}
        />
      </Flex>
    </InfoCardFlexibleHeight>
  );
};
export default SetPasswordLayout;
