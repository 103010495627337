import usePostData from '../../../../../hooks/postHook';

export const useCreateSorter = ({ isEditing }) => {
  const [{ data, body, isLoading, isError, error }, doCreateSorter] =
    usePostData(``, {
      type: isEditing ? 'put' : 'post',
    });

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doCreateSorter,
  };
};
