import { getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import React, { useContext, useEffect, useState } from 'react';
import { PropKeys } from '../constants';
import { useStore } from './StoreProvider';

const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function FirebaseProvider({ children }) {
  const [firebaseStorage, setFirebaseStorage] = useState(null);
  const [publicFirebaseStorage, setPublicFirebaseStorage] = useState(null);

  const {
    store: { [PropKeys.storageToken]: storageToken },
  } = useStore();

  function setFirebaseConfig(config) {
    const hasApps = getApps().length;
    if (hasApps >= 2) return;

    initializeApp(config);
    setFirebaseStorage(getStorage());
  }

  function setPublicFirebaseConfig(config) {
    const hasApps = getApps().length;
    if (hasApps >= 2) return;
    const publicApp = 'publicApp';

    const publicApp_ = initializeApp(config, publicApp);
    setPublicFirebaseStorage(getStorage(publicApp_));
  }

  useEffect(() => {
    const hasApps = getApps().length;
    if (!hasApps || storageToken == null) return;
    authenticateUserForFirebaseUploads(storageToken);
  }, [firebaseStorage, storageToken]);

  const authenticateUserForFirebaseUploads = async token => {
    const auth = getAuth();
    signInWithCustomToken(auth, token)
      .then(userCredential => {})
      .catch(error => {});
  };

  return (
    <FirebaseContext.Provider
      value={{
        firebaseStorage,
        publicFirebaseStorage,
        setFirebaseConfig,
        setPublicFirebaseConfig,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
