import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { ItemPreviewImage } from '../../../../../components/molecules/ItemPreviewImage/ItemPreviewImage';
import { CardLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Utils } from '../../../../../constants';
import { SnapshotData } from './SideloadSnapshot.components';

const SideloadSnapshot = ({
  isLoading,
  imageIsLoading,
  image,
  showGallery = Utils.emptyFunction,
  details,
}) => {
  return (
    <Card className="sideload-snapshot">
      {isLoading ? (
        <CardLoader />
      ) : (
        <Flex gap="_M" align="center">
          {imageIsLoading ? (
            <div className="pulse">
              <ItemPreviewImage size="_XL" />
            </div>
          ) : (
            <ItemPreviewImage src={image} size="_XL" onClick={showGallery} />
          )}

          <SnapshotData details={details} />
        </Flex>
      )}
    </Card>
  );
};
export default SideloadSnapshot;
