import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { KnowledgeBaseTable } from './organisms/organisms';

const { SELF, CREATE_KNOWLEDGE_BASE, VIEW_KNOWLEDGE_BASE } =
  PermissionKeys.Brand.KNOWLEDGE_BASE;

const KnowledgeBaseLayout = ({
  headers,
  data,
  grid,
  rowAction,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        hasPermission(VIEW_KNOWLEDGE_BASE) && (
          <KnowledgeBaseTable
            headers={headers}
            data={data}
            rowAction={rowAction}
            grid={grid}
          />
        )
      }
    />
  );
};
export default KnowledgeBaseLayout;
