import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import { useUI } from '../../../providers/UIProvider';
import { clickedBy } from '../../../utils/utils';
import { Icon } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, Layout } from '../../style';
import { Center, Overlay } from './Modal.style';

// TODO: might be better to handle non-overlay modals as a completely different file.
export default function Modal(
  Component,
  overlay = true,
  { grid = true, centerStyles = {}, overlayStyles = {} } = {}
) {
  return function WithModal(props) {
    const { sidebarWidth } = useUI().UI;
    const { dismiss } = useModal();

    const skipRef = useRef(true);

    const nonOverlayDismiss = e => {
      if (skipRef.current) {
        skipRef.current = false;
        return;
      }
      if (clickedBy(props?.componentRef?.current, e)) return;
      dismiss();
    };

    useEffect(() => {
      if (!overlay) window.addEventListener('click', nonOverlayDismiss);
      return () => {
        if (!overlay) window.removeEventListener('click', nonOverlayDismiss);
      };
    }, []);

    const { scrollTop } = document.querySelector('.container') ?? {
      scrollTop: 0,
    };

    return (
      <Overlay
        sidebarWidth={sidebarWidth}
        overlay={overlay}
        hideOverlay={props?.hideOverlay}
        className={`modal ${props?.className ?? ''}`}
        grid={grid}
        scrollTop={scrollTop}
        style={overlayStyles}
      >
        <Center ref={props?.componentRef} style={centerStyles}>
          <Component {...props} />
        </Center>
        {props.xDismiss && (
          <XDismiss onClick={dismiss}>
            <Icon name={Icons.X} color={Colors.Sharp.White} />
          </XDismiss>
        )}
      </Overlay>
    );
  };
}

const XDismiss = styled.div`
  position: fixed;
  top: ${Layout.Spacing._S};
  right: ${Layout.Spacing._S};
`;
