import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Actions from '../../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../../components/templates/Popup';
import { AddContent } from '../style';

export const AddLayout = ({ title, children, actionsProps }) => {
  return (
    <Popup title={title} size={'_M'}>
      <AddContent>
        <Flex w grow h0 column justify="start">
          {children}
        </Flex>
        <Actions {...actionsProps} />
      </AddContent>
    </Popup>
  );
};
