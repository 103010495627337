import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import { useCreateReport } from '../api/createReport';
import { NewReportLayout } from '../components/NewReportLayout';
import {
  alertText,
  newReportInitialValues,
  newReportValidationSchema,
} from './variables';

const NewReport = ({ onSuccess }) => {
  const [time, setTime] = useState({ fromDate: null, toDate: null });

  const { dismiss } = useModal();
  const { showSnackbarError } = useSnackbar();

  const { body, isLoading, isError, error, doCreateReport } = useCreateReport();

  const onSubmit = values => () => {
    const body = {
      title: values.name,
      from_date: time.fromDate,
      to_date: time.toDate,
    };

    doCreateReport(body);
  };

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    onSuccess();
    dismiss();
  }, [body, isLoading, isError]);

  const changeDates = dates => () => setTime(dates);

  return (
    <Formik
      initialValues={newReportInitialValues}
      validationSchema={newReportValidationSchema}
      validateOnMount
    >
      {({ isValid, values }) => {
        return (
          <NewReportLayout
            dismiss={dismiss}
            disabled={!isValid || !(time.fromDate && time.toDate) || isLoading}
            isLoading={isLoading}
            changeDates={changeDates}
            title={titleFromCalendar({
              searchParams: {
                key: {
                  value: { fromDate: time.fromDate, toDate: time.toDate },
                },
              },
              titles: {},
              key: 'key',
            })}
            timeFrameInitVals={time}
            alertText={alertText}
            onSubmit={onSubmit(values)}
          />
        );
      }}
    </Formik>
  );
};
export default Modal(NewReport, undefined, {
  centerStyles: { height: '880px' },
  overlayStyles: { overflow: 'auto' },
});
