import useFetchData from '../../../../../hooks/fetchHook';

export const useGetWhitelabelCustomers = () => {
  const [{ data = [], isLoading, isError, error }] = useFetchData(
    'parcels/whitelabel_customers',
    {
      formatter: formatGetWhitelabelCustomers,
    }
  );

  return { data, isLoading, isError, error };
};

const formatGetWhitelabelCustomers = data => {
  // maskEntry(data, mask);

  let formatted = data.map(customer => {
    const {
      pwnCustomerID: value,
      pwnCustomerID: label,
      pwnActionNotes: action,
    } = customer;
    if (value == null || label == null) return null;
    return { value, label, action };
  });

  return formatted;
};
