import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { formatOrigins, inactiveOrigins } from '../formatters/formatOrigins';

export const useGetOrigins = ({ searchParams, inactiveBrand, ignore }) => {
  const [
    { data = { data: [], total: 0, main: '' }, isLoading, isError, error },
    _,
    __,
    refresh,
  ] = useFetchData('dashboard/reference_origin', {
    formatter: formatOrigins,
    params: formatParams(searchParams),
    ignore,
  });

  return {
    data: inactiveBrand || ignore ? inactiveOrigins() : data,
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
