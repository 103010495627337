import { PropKeys } from '../../../../../constants';
import { caseKeys } from '../../cases/controllers/variables';

export const parcelKeys = {
  leftPadding: 'leftPadding',
  caseCount: 'caseCount',
  confidence: 'confidence',
  id: 'id',
  optionsMenu: 'optionsMenu',
  alias: 'alias',
  parcelId: 'parcelId',
  parcelImages: 'parcelImages',
  parcelVolume: 'parcelVolume',
  parcelWeight: 'parcelWeight',
  products: 'products',
  status: 'status',
  photos: 'photos',
  reason: 'reason',
  riskType: 'riskType',
  riskAnalysis: 'riskAnalysis',
  sideloadDate: 'sideloadDate',
  sorterId: PropKeys.sorterId,
  sorterCountry: 'sorterCountry',
  sorterCity: 'sorterCity',
  sorterName: 'sorterName',
  statusIndicator: 'statusIndicator',
  trackingNumber: 'trackingNumber',
  ownQuantity: caseKeys.ownQuantity,
  totalQuantity: caseKeys.totalQuantity,
};
