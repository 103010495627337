import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { sortByLabel } from '../../../../../utils/arrayUtils';
import { joinStrings } from '../../../../../utils/stringUtils';
import { maskEntry } from '../../../../../utils/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const useGetCategories = () => {
  const [{ data: { categories = [] } = {}, isLoading, isError, error }] =
    useFetchData('categories', {
      failureFormatter: _ => [],
    });

  return { data: formatter(categories), isLoading, isError, error };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data.forEach(
    p =>
      (p.subcategory_category = joinStrings(
        p[productKeys.subCategory],
        p[productKeys.category],
        ' - '
      ))
  );

  data = prepareDataForDropdown(
    data,
    'subcategory_category',
    productKeys.categoryId
  )[0];

  data.options.sort(sortByLabel);

  return data;
};

export const defaultCategory_Other = {
  value: 'Other',
  label: 'Other - Other',
};

const mask = {
  bcName: productKeys.category,
  cpName: productKeys.subCategory,
  cpID: productKeys.categoryId,
};
