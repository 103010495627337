import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { NavURIs, Pages } from '../../routes/variables';
import { request2FAcode } from '../api/request2FAcode';
import Setup2FALayout from '../components/Setup2FALayout';
import { Fields, FormValues, Texts } from './variables';

const { setup2FA, login, verify2FA } = Pages;
const { [login]: loginRoute, [verify2FA]: verify2FAroute } = NavURIs;
const {
  title,
  subtitle,
  minorButtonText,
  mainButtonText,
  minorLeftIcon,
  mainRightIcon,
  tooltipText,
} = Texts[setup2FA];

const { initialValues, validationSchema } = FormValues[setup2FA];

const { emailField, smsField, authenticatorField } = Fields[setup2FA];

const Setup2FA = () => {
  const [credentials, setCredentials] = useAuth();
  const { email, password, authType, phone } = credentials;
  const [selected, setSelected] = useState(authType || emailField);
  const [manualEnterLoading, setManualEnterLoading] = useState(false);

  const navigate = useNavigate();
  const { showSnackbarError } = useSnackbar();

  useEffect(() => {
    if (email == null || password == null) {
      const to = loginRoute;
      navigate(to);
    }
  }, []);

  useEffect(() => {
    const keydownSubmit = e => {
      if (e?.key !== 'Enter') return;
      setManualEnterLoading(true);
      onSubmit();
    };
    window.addEventListener('keypress', keydownSubmit);
    return () => window.removeEventListener('keypress', keydownSubmit);
  }, []);

  const disabled = {
    [emailField]: false,
    [smsField]: !phone,
    [authenticatorField]: true,
  };

  const options = Object.values(Fields[setup2FA]).map(field => {
    return {
      selected: selected === field,
      setSelected: id => () => setSelected(id),
      id: field,
      label: Texts[setup2FA][field],
      disabled: disabled[field],
      key: field,
      tooltipText: tooltipText[field],
    };
  });

  const minorButtonClick = () => {
    const to = -1;
    navigate(to);
  };

  const onSubmit = async () => {
    const code = await request2FAcode({
      email,
      password,
      authType: selected,
    });

    setManualEnterLoading(false);
    if (!code) {
      showSnackbarError('Error requesting 2FA code. Please, try again');
      return;
    }

    const { userId, authCode } = code;
    setCredentials({ ...credentials, userId, authCode });

    const state = { authType: selected };
    const to = verify2FAroute;
    navigate(to, { state });
  };

  return (
    <Setup2FALayout
      title={title}
      subtitle={subtitle}
      options={options}
      minorButtonClick={minorButtonClick}
      minorButtonText={minorButtonText}
      minorLeftIcon={minorLeftIcon}
      mainRightIcon={mainRightIcon}
      mainButtonText={mainButtonText}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={manualEnterLoading}
    />
  );
};
export default Setup2FA;
