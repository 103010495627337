import { Text } from '../../../../../../components/atoms';
import { Colors, FontKeys } from '../../../../../../components/style';

export const ReportFormTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};
