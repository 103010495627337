import { Icon, Text } from '../../../../components/atoms';
import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../components/molecules/Button/Button';
import { Colors, FontKeys } from '../../../../components/style';
import { EditAvatarIconCSS } from '../style';

export const UsernameText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const EditAvatarIcon = () => {
  return (
    <EditAvatarIconCSS>
      <Icon name={Icons.Camera} />
    </EditAvatarIconCSS>
  );
};

export const Cancel = ({ onClick, disabled }) => {
  return (
    <Button
      onClick={onClick}
      text="Cancel"
      variant="Secondary"
      size="_M"
      disabled={disabled}
    />
  );
};

export const Submit = ({ disabled, isLoading }) => {
  return (
    <Button
      type="submit"
      text="Save"
      variant="Primary"
      size="_M"
      disabled={disabled}
      isLoading={isLoading}
    />
  );
};

export const PasswordFieldsTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const PasswordRulesTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
