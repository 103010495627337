import styled from '@emotion/styled';
import { Colors, Effects, Layout } from '../../style';

const ProgressBar = styled.div`
  height: ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._S};
`;

export const ProgressTrack = styled(ProgressBar)`
  position: relative;
  flex-grow: 1;
  width: 0;
  background: ${Colors.Neutral._100};
`;

export const Progress = styled(ProgressBar)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.percentage}%;
  background: ${props => props.color};
`;
