import { useToggle } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { Utils } from '../../../../../constants';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { openNewTab } from '../../../../../utils/utils';
import { useGetMessages } from '../../support/api/getMessages';
import { ExpandableTicketLayout } from '../components/ExpandableTicketLayout';
import { setAllMessagesLeft } from '../utils';

const ExpandableTicket = ({ ticket }) => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [messages, setMessages] = useState(null);

  const { showSnackbarError } = useSnackbar();

  const {
    data: messagesResponse,
    isLoading: messagesIsLoading,
    isError: messagesIsError,
    error: messagesError,
    getMessages,
  } = useGetMessages({});

  useEffect(() => {
    if (
      messagesIsLoading ||
      messagesResponse == null ||
      messagesResponse.length === 0
    )
      return;
    if (messagesIsError) {
      showSnackbarError(messagesError);
      return;
    }
    setMessages(setAllMessagesLeft(messagesResponse));
  }, [messagesResponse, messagesIsLoading, messagesIsError]);

  useDeepCompareEffect(() => {
    if (!messages) toggleExpanded(false);
  }, [messages]);

  const onClick = id => () => {
    if (messages == null) getMessages({ id });
    toggleExpanded();
  };

  const goToTicket = e => {
    e.stopPropagation();
    const to = `/support/${ticket.ticketId}`;
    openNewTab(to);
    toggleExpanded(true);
  };

  return (
    <ExpandableTicketLayout
      ticket={ticket}
      onClick={onClick}
      leftIcon={{ name: Icons.ChevronRight, onClick: Utils.emptyFunction }}
      rightIcon={{ name: Icons.ExternalLink, onClick: goToTicket }}
      expanded={expanded}
      messages={messages}
    />
  );
};

export default ExpandableTicket;
