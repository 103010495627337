import usePostData from '../../../../../hooks/postHook';

export const useToggleVisibility = ({ id }) => {
  const [{ data, body, isLoading, isError, error }, doToggleVisibility] =
    usePostData(`office_knowledge_base/${id}/visibility`, {
      type: 'put',
    });

  return { data, body, isLoading, isError, error, doToggleVisibility };
};
