import styled from '@emotion/styled';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Utils } from '../constants';
import { clickedBy } from '../utils/utils';
import { useUI } from './UIProvider';

const ModalContext = React.createContext();

const Modal = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  height: 100%;
  position: absolute;
  inset: 0;
`;

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const [modals, setModals] = useState([]);
  const [modalComponents, setModalComponents] = useState([]);

  const [adjustedIgnoreOverlayDismiss, setAdjustedIgnoreOverlayDismiss] =
    useState();

  const updateIgnoreOverlayDismiss = value =>
    setAdjustedIgnoreOverlayDismiss(value);

  const keepOn = useRef(new Set([]));
  const { sidebarWidth } = useUI().UI;

  const location = useLocation();

  const componentRef = useRef(null);

  useEffect(() => {
    dismissAll();
  }, [location?.pathname]);

  // passes the triggered event only if it happens on the overlay
  // all other dismiss() around the app who accept e get ignored and nothing changes
  // backwards compatible but consider changing the rest of the dismiss calls
  const overlayDismiss = e => dismiss('', e);

  const dismiss = (_, e) => {
    if (clickedBy(componentRef.current, e)) return;

    setModals(x => {
      if (x.length <= 1) keepOn.current.clear();
      keepOn.current.delete(modals.length - 2);
      return x.slice(0, -1);
    });
  };

  const dismissAll = e => {
    if (clickedBy(componentRef.current, e)) return;
    setModals([]);
    keepOn.current.clear();
  };

  const showModal = (
    Component,
    props,
    { stack = false, keepPrev = false, ignoreOverlayDismiss, ...rest } = {}
  ) => {
    const modal = { Component, props, ignoreOverlayDismiss, keepPrev, ...rest };
    if (stack || keepPrev) keepOn.current.add(modals.length - 1);
    setModals(x => (stack || keepPrev ? [...x, modal] : [modal]));
  };

  useEffect(() => {
    setModalComponents(
      modals.map(
        ({ Component, props, ignoreOverlayDismiss, keepPrev, ...rest }, i) => {
          const show = i + 1 === modals.length || keepOn.current.has(i);
          return (
            <Modal
              show={show}
              key={i}
              onClick={
                adjustedIgnoreOverlayDismiss ?? ignoreOverlayDismiss
                  ? Utils.emptyFunction
                  : // on keepPrev you see the modal behind your current modal. expected behavior
                  // is to keep seeing that prev modal when you dismiss current modal,
                  // when clicking on current modal overlay to dismiss
                  keepPrev
                  ? overlayDismiss
                  : dismissAll
              }
              {...rest}
              sidebarWidth={sidebarWidth}
            >
              <Component
                {...props}
                componentRef={i + 1 === modals.length ? componentRef : null}
                hideOverlay={i + 1 !== modals.length}
                {...rest}
              />
            </Modal>
          );
        }
      )
    );
  }, [modals, adjustedIgnoreOverlayDismiss]);

  return (
    <ModalContext.Provider
      value={{
        isModalActive: modals.length > 0,
        dismiss,
        dismissAll,
        showModal,
        modalComponents,
        updateIgnoreOverlayDismiss,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
