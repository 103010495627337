import { Flex } from '../../atoms/Flex/Flex';
import { Dot, Line } from './ActivityConnection.style';

const ActivityConnection = ({ isFirst, isLast, inactive }) => {
  return (
    <Flex justify="center">
      <Dot isFirst={isFirst} isLast={isLast} inactive={inactive} />
      {!isFirst ? <Line className="line" up inactive={inactive} /> : ''}
      {!isLast ? <Line className="line" down inactive={inactive} /> : ''}
    </Flex>
  );
};

export default ActivityConnection;
