import { Constants } from '../../../../../constants';
import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { overviewTitles } from '../variables';

export const formatOverviewStat =
  overviewStats =>
  (data, { hasPermission }) => {
    return overviewStats.map((stat, index) => ({
      title: overviewTitles[stat],
      status: stat,
      count:
        data[stat] && hasPermission
          ? magnitudeFormatter(data[stat])
          : Constants.fallback,
      navigate: hasPermission,
    }));
  };
