import { Text } from '../../../../../../components/atoms';
import { Colors, FontKeys } from '../../../../../../components/style';

export const BaseText = ({
  text,
  color,
  bold = true,
  ellipsis,
  noWrap = true,
  size = '_M',
}) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={color}
      ellipsis={ellipsis}
      whiteSpace={noWrap ? 'noWrap' : undefined}
    />
  );
};

export const SnapshotTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const SectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};
