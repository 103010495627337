import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../../components/molecules/Button/Button';
import { toggleProps } from '../../../../../components/molecules/ToggleFilter.js/utils';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mapToLightweightState,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { lightweightState } from '../../../../../utils/utils';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetAdminReferences } from '../api/getAdminReferences';
import { useGetFilters } from '../api/getFilters';
import AdminReferencesLayout from '../components/AdminReferencesLayout';
import AddReference from './AddReference';
import { useAdminReferencesReducer } from './AdminReferences.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  referenceIndexKeys,
  titles,
} from './variables';

const { SELF, VIEW_REFERENCES } = PermissionKeys.Admin.ADMIN_REFERENCES;

const AdminReferences = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { dismiss, showModal } = useModal();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_REFERENCES);
  const hasEditPermission = hasViewPermission;
  const resetFormRef = useRef(null);

  const ownOptions = {};

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useAdminReferencesReducer({
    initialSearchParams,
    resetSearchParams,
  });
  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location) || true;
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { references, referenceCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetAdminReferences({
    searchParams,
    ignore: ignoreDataFetch,
    with_permissions: true,
  });

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcel_evaluation];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError || references == null) return;
    setData(references);
  }, [references, isLoading, isError]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    // [DC.TIME_FRAME]: {
    //   filter: dispatch(DC.TIME_FRAME),
    //   title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
    //   timeFrameInitVals: searchParams[DC.TIME_FRAME],
    // },
    [DC.COUNTRY]: dropdown(DC.COUNTRY),
    [DC.ADDED_BY]: dropdown(DC.ADDED_BY),
    [DC.BRANDS]: dropdown(DC.BRANDS),
    [DC.ONLY_ACTIVE]: toggleProps({
      searchParams,
      dispatch: _dispatch,
      type: DC.ONLY_ACTIVE,
    }),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ``;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.admin_references]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
          endSlot={
            <Button text="Add reference" onClick={showAddReference} size="_S" />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  const showAddReference = () => showModal(AddReference, { onSuccess });

  const goToReferenceDetails = referenceInfo => {
    if (!hasViewPermission) return;
    const { [referenceIndexKeys.id]: senderId } = referenceInfo;

    const from = location.pathname;

    const state = {
      searchParams: mapToLightweightState(searchParams),
      referenceInfo: lightweightState(referenceInfo),
      from,
    };

    const to = `${NavURIs[Pages.admin_references]}/${senderId}`;

    navigate(to, { state });
  };

  const onSuccess = () => {
    refreshView();
    dismiss();
  };

  const rowAction = goToReferenceDetails;
  const noDataAction = undefined;

  return (
    <AdminReferencesLayout
      tableProps={{
        data,
        headers,
        grid,
        rowAction,
        tableComponentProps: {},
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default AdminReferences;
