import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Popup } from '../../../../../components/templates/Popup';
import { BaseText } from './atoms';
import {
  AddServiceActions,
  ContractDropzone,
  ContractFields,
} from './organisms';
import { ContractContent } from './style';

const sectionTitleAdd = 'Add the requested information to add a new contract';
const sectionTitleEdit =
  'Edit the requested information to update the contract';

export const AddContractLayout = ({
  isEditing,
  contractFieldsProps,
  contractDropzoneProps,
  actionsProps,
}) => {
  return (
    <Popup size={'_M'} title={isEditing ? 'Edit contract' : 'Add contract'}>
      <ContractContent>
        <Spacer height={'_M'} />
        <Flex w justify={'center'}>
          <BaseText
            text={isEditing ? sectionTitleEdit : sectionTitleAdd}
            size="_L"
          />
        </Flex>
        <Spacer height={'_M'} />
        <Flex gap="_S" grow>
          <ContractFields contractFieldsProps={contractFieldsProps} />
          <ContractDropzone contractDropzoneProps={contractDropzoneProps} />
        </Flex>
        <AddServiceActions actionsProps={actionsProps} hideSeparator />
      </ContractContent>
    </Popup>
  );
};
