import { Text } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../../components/molecules/Button/Button';
import { Colors, FontKeys } from '../../../../../../components/style';
import { BrandNameCSS, ProductStepNumberCSS } from '../style';

export const ScanningModeTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H5}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const InspectionStepTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H5}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const CaseProductsTableHeaderText = ({ text, own }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={own ? Colors.Sharp.White : Colors.Neutral._600}
    />
  );
};

export const AddProductButton = ({ onClick }) => {
  return (
    <div className="add-product-button">
      <Button
        leftIcon={Icons.PlusCircle}
        text="Add product"
        onClick={onClick}
        size="_M"
        variant="Tertiary"
      />
    </div>
  );
};

export const ProductStepNumber = ({
  stepNumber,
  selected,
  beforeCurrentStep,
}) => {
  return (
    <ProductStepNumberCSS
      selected={selected}
      beforeCurrentStep={beforeCurrentStep}
    >
      <StepNumberText
        text={stepNumber}
        selected={selected}
        beforeCurrentStep={beforeCurrentStep}
      />
    </ProductStepNumberCSS>
  );
};

const StepNumberText = ({ text, selected, beforeCurrentStep }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={
        selected
          ? Colors.Sharp.White
          : beforeCurrentStep
          ? Colors.Primary._600
          : Colors.Neutral._400
      }
    />
  );
};

export const StepLabel = ({ text, selected }) => {
  return (
    <Text
      text={text}
      variant={
        selected ? FontKeys.Variant.Paragraph : FontKeys.Variant.Paragraph
      }
      size={selected ? FontKeys.Size._L : FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};
export const StepValueLabel = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      ellipsis
      className="step-value-label"
    />
  );
};

export const SummaryTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H5}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const SummaryDetailTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const SummaryDetailText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const SummaryDetailSubtext = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const SectionLetter = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._400}
    />
  );
};

export const BrandName = ({ name, selected }) => {
  return (
    <BrandNameCSS selected={selected}>
      <Text
        text={name}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
        color={selected ? Colors.Sharp.White : undefined}
        ellipsis
      />
    </BrandNameCSS>
  );
};

export const ItalicizedSecond = ({ text }) => {
  const [first, second] = text.split(' - ');
  return (
    <span>
      {first} - <span style={{ color: Colors.Neutral._500 }}>{second}</span>{' '}
    </span>
  );
};
