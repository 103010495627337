import { useState } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { CharacteristicTypesLayout } from '../pages/CharacteristicTypesLayout';
import CharactersticUpload from './CharactersticUpload';
import { instructionTypes } from './variables';

const CharacteristicTypes = ({
  instructionId,
  type,
  onSuccess,
  fieldValues,
}) => {
  const [typeSelected, setTypeSelected] = useState({});

  const { dismiss, dismissAll, showModal } = useModal();

  const selectType = type => () => setTypeSelected(type);

  const showCharactesticUpload = () => {
    showModal(
      CharactersticUpload,
      {
        instructionId,
        characteristicType: typeSelected,
        onSuccess,
        fieldValues,
      },
      { stack: true, ignoreOverlayDismiss: true }
    );
  };

  return (
    <CharacteristicTypesLayout
      instructionTypes={instructionTypes}
      selectType={selectType}
      typeSelected={typeSelected}
      onPrimary={showCharactesticUpload}
      disabled={typeSelected.value == null}
      onCancel={() => dismissAll()}
      onBack={type === 'wizard' ? dismiss : null}
    />
  );
};
export default Modal(CharacteristicTypes);
