import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, Effects, Layout } from '../../style';

export const styles = {
  icon: hover => {
    return {
      name: Icons.Info,
      color: hover ? Colors.Neutral._900 : Colors.Neutral._400,
    };
  },
};

export const Container = styled.div`
  position: relative;
  z-index: 10;
  &:hover {
    .description-content {
      display: block;
    }
  }
`;

export const Description = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  &:hover {
    .description-content {
      display: block;
    }
  }
`;

export const DescriptionContent = styled.div`
  position: relative;
  top: ${Layout.Spacing._XS};
  ${Styles.flexCenter};
  display: none;
`;

export const ContentPadding = styled.div`
  position: relative;
  min-width: 200px;
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  background: ${Colors.Sharp.White};
  top: ${Layout.Spacing._2XS};
  padding: ${Layout.Spacing._XS};
`;
