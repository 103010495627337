import { useRef } from 'react';
import { someDropdownIsEmpty } from '../../../../../components/organisms/Table/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { isEqual } from '../../../../../lib/js';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../controllers/variables';

export const useGetReferences = ({ searchParams, ignore }) => {
  const prevParams = useRef({});
  const params = formatParams(searchParams);
  const ignoreIf = [
    someDropdownIsEmpty(params),
    isEqual(searchParams, prevParams.current),
  ];

  if (ignoreIf.some(condition => condition)) ignore = true;
  if (!ignore) prevParams.current = searchParams;

  const [
    {
      data: { senders: references = [], sender_count: referenceCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    doSearch,
  ] = useFetchData('office_references/all', {
    params,
    ignore,
  });

  return {
    data: { references: maskEntry(references, mask), referenceCount },
    isLoading,
    isError,
    error,
    doSearch,
  };
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    ONLY_ACTIVE: active_senders,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    search,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };
  if (active_senders) formattedParams.active_senders = true;
  return formattedParams;
};

const mask = {
  seID: referenceIndexKeys.id,
  seAlias: referenceIndexKeys.alias,
  seName: referenceIndexKeys.name,
  seCompany: referenceIndexKeys.company,
  seAddress: referenceIndexKeys.address,
  notes: referenceIndexKeys.notes,
  sePhone: referenceIndexKeys.phone,
  sePostcode: referenceIndexKeys.zipcode,
  seCity: referenceIndexKeys.city,
  seState: referenceIndexKeys.state,
  seCountry: referenceIndexKeys.country,
  case_stats: referenceIndexKeys.caseStatistics,
};
