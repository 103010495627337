import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { useState } from 'react';
import { Utils } from '../../../constants';
import { DisableContainer } from '../../../utils/styleUtils';
import { Icon, Text } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import { Colors, FontKeys, Size } from '../../style';
import { Input } from '../Input/Input';
import {
  OptionsContainerCSS,
  OptionsHoverable,
  OptionsScrollbarPosition,
  Recipe,
} from './Dropdown.components.style';
import {
  FakeBottomPadding,
  FakeTopPadding,
  OptionsSearch,
} from './Dropdown.style';

export const OptionsContainer = ({
  open,
  maxHeight,
  sections,
  isError,
  sectionProps,
}) => {
  return (
    <OptionsHoverable className="options-hoverable">
      <OptionsScrollbarPosition open={open}>
        <OptionsContainerCSS
          maxHeight={maxHeight}
          disabled={isError}
          open={open}
          id="dropdownOptionsContainer"
        >
          <FakeTopPadding>
            {sections?.map((section, sectionIndex) => (
              <Section {...sectionProps(section, sectionIndex)} />
            ))}
            <FakeBottomPadding className="bottom" />
          </FakeTopPadding>
        </OptionsContainerCSS>
      </OptionsScrollbarPosition>
    </OptionsHoverable>
  );
};

export const Section = ({
  filterAction,
  isError,
  height,
  section,
  highlight,
  showCheckbox,
  index,
  setInputFocus = Utils.emptyFunction,
  inputProps,
}) => {
  const { sectionTitle, options, section: sectionSelector } = section;

  return (
    <div style={Recipe('section')({ height })}>
      {options.length ? (
        <>
          {index ? (
            <div style={Recipe('separatorContainer')()}>
              {' '}
              <Separator style={Recipe('separatorContainer')().separator} />
            </div>
          ) : (
            <></>
          )}
          {sectionTitle ? (
            <SectionTitle
              title={sectionTitle}
              height={height}
              showCheckbox={showCheckbox}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <></>
      )}
      {/* filters through options in drodpown */}
      {index == 0 && !inputProps?.hideSearch ? (
        <OptionsSearch>
          <Input {...inputProps} />
        </OptionsSearch>
      ) : (
        ''
      )}
      <LazyLoadComponent>
        {options.map((option, index) => {
          const { label, selected } = option;
          const handleClick = e => {
            e.stopPropagation();
            filterAction(option, sectionSelector)(e);
            setInputFocus(Math.random());
          };
          return (
            <Option
              onClick={handleClick}
              selected={selected}
              height={height}
              highlight={highlight}
              showCheckbox={showCheckbox}
              text={label}
              key={label + index}
              isError={isError}
            />
          );
        })}
      </LazyLoadComponent>
    </div>
  );
};

export const SectionTitle = ({ title, height, showCheckbox }) => {
  return (
    <div style={Recipe('sectionTitle')({ height, showCheckbox })}>
      <Text
        text={title}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
        color={Colors.Neutral._400}
      />
    </div>
  );
};

export const Option = ({
  text = '',
  selected,
  highlight,
  height,
  showCheckbox,
  onClick,
  textStyles,
  isError,
}) => {
  const [hover, setHover] = useState(false);
  const setHovering = to => () => setHover(to);

  return (
    <DisableContainer
      disabled={isError}
      // can possibly remove height
      style={Recipe('option')({ selected, highlight, height })}
      onPointerOver={setHovering(true)}
      onPointerLeave={setHovering(false)}
      onPointerDown={isError ? Utils.emptyFunction : onClick}
    >
      {!isError && showCheckbox ? (
        <Icon
          name={selected ? Icons.CheckSquare : Icons.Square}
          color={selected ? undefined : Colors.Neutral._300}
          size={Size.Icon._S}
        />
      ) : (
        <></>
      )}
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={
          hover || selected ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular
        }
        {...textStyles}
        ellipsis
      />
      {/* {isError && <DisableContainer className="DISABBLE" />} */}
    </DisableContainer>
  );
};
