import { Flex } from '../../../../components/atoms/Flex/Flex';
import Avatar from '../../../../components/molecules/Avatar/Avatar';
import {} from '../../../../utils/stringUtils';
import { notificationKeys } from '../../controllers/variables';
import {
  AnnouncementDate,
  AnnouncementEmoji,
  AnnouncementTitle,
  Date,
  Description,
  Title,
} from '../atoms/atoms';
import { AnnouncementCSS, NotificationCSS, UnreadCircle } from '../style';

export const Notification = ({ notification, handleNavigate }) => {
  return (
    <NotificationCSS
      onClick={handleNavigate(notification)}
      hasLink={!!notification[notificationKeys.link]}
    >
      <Avatar
        username={notification[notificationKeys.username]}
        image={notification[notificationKeys.userImage]}
        size="_S"
      />
      <Content notification={notification} />
    </NotificationCSS>
  );
};

const Content = ({ notification }) => {
  const isRead = notification[notificationKeys.read] === 'Read';
  return (
    <Flex column isClickable>
      <Flex justify="between">
        <Title text={notification[notificationKeys.title]} read={isRead} />
        {!isRead && <UnreadCircle />}
      </Flex>
      <Description text={notification[notificationKeys.description]} />
      <Date text={notification[notificationKeys.date]} />
    </Flex>
  );
};

export const Announcement = ({
  announcement,
  clearMenu,
  showFeaturePreview,
}) => {
  const { icon, title, date, type, id, read } = announcement;
  const isRead = read === 'Read';

  const showFeature = () => {
    clearMenu();
    showFeaturePreview({ id, type });
  };

  return (
    <AnnouncementCSS gap="_XS" onClick={showFeature}>
      <AnnouncementEmoji src={icon} />
      <Flex column>
        <AnnouncementTitle text={title} isRead={isRead} />
        <AnnouncementDate text={date} />
      </Flex>
      {!isRead && <UnreadCircle />}
    </AnnouncementCSS>
  );
};
