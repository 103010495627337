import * as Yup from 'yup';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

const { VIEW_REPORTS } = PermissionKeys.Brand.REPORTS;

export const optionViews = {
  monthly: 'monthly',
  custom: 'custom',
};

export const options = [
  {
    icon: Icons.FileClock,
    title: 'Monthly reports',
    description: 'See and download monthly reports',
    view: optionViews.monthly,
    permission: VIEW_REPORTS,
  },
  {
    icon: Icons.FileEdit,
    title: 'Custom reports',
    description: 'Generate and download custom reports',
    view: optionViews.custom,
    permission: VIEW_REPORTS,
  },
];

export const reportsKeys = {
  action: 'action',
  id: 'id',
  name: 'name',
  dateRange: 'dateRange',
  dateRequested: 'dateRequested',
  requestedBy: 'requestedBy',
  username: 'username',
  userimage: 'userimage',
  leftPadding: 'leftPadding',
};

export const monthlyReportsHeaders = {
  [reportsKeys.leftPadding]: '',
  [reportsKeys.name]: 'Name',
  [reportsKeys.action]: '',
};

export const monthlyReportsGrid = {
  gridTemplateColumns: cleanGridStyle(`
  //  space  //
  16px
  //  name  //
  minmax(120px , 1fr)
  // actions //
  58px`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const customReportsHeaders = {
  [reportsKeys.leftPadding]: '',
  [reportsKeys.name]: 'Name',
  [reportsKeys.dateRange]: 'Date range',
  [reportsKeys.requestedBy]: 'Requested by',
  [reportsKeys.dateRequested]: 'Requested on',
  [reportsKeys.action]: '',
};

export const customReportsGrid = {
  gridTemplateColumns: cleanGridStyle(`
  //  space  //
  16px
  //  name  //
  minmax(120px , 0.3fr)
  //  date range  //
  minmax(60px , 0.3fr)
  //  requested by   //
  minmax(150px , 0.4fr)
  //  date requested  //
  minmax(60px , 0.25fr)
  // actions //
  58px`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const noResultsState = {
  monthly: {
    noData: onClick => ({
      icon: NoData.Default,
      title: 'No reports yet',
      description:
        'The first report will be generated once the current month ends',
    }),
    noSearchResults: {
      icon: NoResults.Default,
      title: 'No reports yet',
      description:
        'The first report will be generated once the current month ends',
    },
  },
  custom: {
    noData: onClick => ({
      icon: NoData.Default,
      title: 'No reports yet',
      description: 'Created reports will appear here',
    }),
    noSearchResults: {
      icon: NoResults.Default,
      title: 'No reports yet',
      description: 'Created reports will appear here',
    },
  },
};

export const alertText =
  'Creating a new report might take a while, depending on the date range selection. You will able to download the report in the custom reports page. ';

const newReportFields = {
  name: 'name',
};

export const newReportInitialValues = {
  [newReportFields.name]: '',
};

export const newReportValidationSchema = Yup.object().shape({
  [newReportFields.name]: Yup.string()
    .min(4, '4 chars minimum')
    .max(100, '100 chars maximum')
    .required(),
});
