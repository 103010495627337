import { Icon } from '../../../../../components/atoms';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import Tooltip from '../../../../../components/molecules/Tooltip/Tooltip';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Colors, Size } from '../../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import {
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../../products/controllers/variables';

const { Authentication, Sideload, Inspection, Seized, Released, Stopped } =
  Constants.Status;

const {
  EDIT_PARCEL_STATUS_SIDELOAD,
  EDIT_PARCEL_STATUS_INSPECTION,
  EDIT_PARCEL_STATUS_AUTHENTICATION,
  EDIT_PARCEL_STATUS_STOPPED,
  EDIT_PARCEL_STATUS_SEIZED,
  EDIT_PARCEL_STATUS_RELEASED,
} = PermissionKeys.Admin.OFFICE_PARCELS;

const headerToolipText = {
  [parcelKeys.checked]: 'Check-in status',
  [parcelKeys.nextStep]: 'Next parcel status',
  [parcelKeys.origin]: 'Parcel origin',
  [parcelKeys.destination]: 'Parcel destination',
  [parcelKeys.product.count]: 'Number of products in parcel',
};

const tooltipChildren = {
  [parcelKeys.checked]: (
    <Icon
      name={Icons.PackageCheck}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [parcelKeys.nextStep]: (
    <Icon
      name={Icons.Forward}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [parcelKeys.origin]: (
    <Icon
      name={Icons.PackageOrigin}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [parcelKeys.destination]: (
    <Icon
      name={Icons.PackageDestination}
      size={Size.Icon._M}
      color={Colors.Neutral._500}
    />
  ),
  [parcelKeys.product.count]: (
    <Icon name={Icons.Tag} size={Size.Icon._M} color={Colors.Neutral._500} />
  ),
};

export const headers = {
  [parcelKeys.indicator]: '',
  [parcelKeys.selected]: '',
  [parcelKeys.expansionArrow]: '',
  [parcelKeys.photos]: 'Scanned images',
  [parcelKeys.alias]: 'Parcel ID',
  [parcelKeys.sideloadDate]: 'Sideload date',
  [parcelKeys.riskType]: 'Risk type',
  [parcelKeys.status]: 'Parcel status',
  [parcelKeys.checked]: (
    <Tooltip
      content={headerToolipText[parcelKeys.checked]}
      children={tooltipChildren[parcelKeys.checked]}
      asChild={false}
    ></Tooltip>
  ),
  [parcelKeys.nextStep]: (
    <Tooltip
      content={headerToolipText[parcelKeys.nextStep]}
      children={tooltipChildren[parcelKeys.nextStep]}
      asChild={false}
    ></Tooltip>
  ),
  [parcelKeys.origin]: (
    <Tooltip
      content={headerToolipText[parcelKeys.origin]}
      children={tooltipChildren[parcelKeys.origin]}
      asChild={false}
    ></Tooltip>
  ),
  [parcelKeys.destination]: (
    <Tooltip
      content={headerToolipText[parcelKeys.destination]}
      children={tooltipChildren[parcelKeys.destination]}
      asChild={false}
    ></Tooltip>
  ),
  [parcelKeys.statusShares]: 'Product status',
  [parcelKeys.product.count]: (
    <Tooltip
      content={headerToolipText[parcelKeys.product.count]}
      children={tooltipChildren[parcelKeys.product.count]}
      asChild={false}
    ></Tooltip>
  ),
  [parcelKeys.product.navigate]: '',
};

export const expansionHeaders = {
  [parcelKeys.leftPadding]: '',
  [parcelKeys.product.photos]: 'Product Images',
  [parcelKeys.product.alias]: 'Product ID',
  [parcelKeys.product.brand]: 'Brand',
  [parcelKeys.product.category]: 'Product category',
  [parcelKeys.product.itemQuantity]: 'Items',
  [parcelKeys.product.status]: 'Status',
  [parcelKeys.product.lastActivity]: 'Last activity',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  // indicator //
  16px
  // selected //
  32px
  // expansion arrow //
  32px
  // photos //
  152px
  // parcel alias //
  minmax(166px, 0.1fr)
  // sideload date //
  minmax(20px, 0.7fr)
  //  risk type //
  minmax(20px, 0.5fr)
  // parcel status //
  150px
  // checked //
   32px
  // next step //
   32px
  // origin //
   32px
  // destination //
   32px
  //   product status //
  minmax(120px, 0.3fr)
  // count //
  32px
  // navigate //
  48px
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const expansionGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // photos //
  196px
  // product alias //
  minmax(82px, 156px)
  // brand //
  minmax(82px, 0.75fr)
  // product category //
  minmax(82px, 0.85fr)
  //  quantity //
  64px
  // parcel status //
  166px
  // last activity //
  minmax(122px, 0.3fr)
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
  headerHeight: '40px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  BRAND: 'BRAND',
  LOCATION: 'LOCATION',
  PARCEL_STATUS: 'PARCEL_STATUS',
  RISK_TYPE: 'RISK_TYPE',
  CONTRACTED: 'CONTRACTED',
  SCAN: 'SCAN',
  OWN: 'OWN',
  OTHERS: 'OTHERS',
  ACTOR_DATA: 'ACTOR_DATA',
  SPECIAL_FILTER: 'SPECIAL_FILTER',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
  },
  [DC.BRAND]: {
    type: FilterTypes.dropdown,
    icon: Icons.Building,
  },
  [DC.LOCATION]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
  },
  [DC.SPECIAL_FILTER]: {
    type: FilterTypes.dropdown,
    icon: Icons.Filter,
    param: 'specialFilter',
  },
  [DC.RISK_TYPE]: {
    type: FilterTypes.dropdown,
    icon: Icons.AlertCircle,
    param: 'riskType',
  },
  [DC.ACTOR_DATA]: {
    type: FilterTypes.dropdown,
    icon: Icons.PackageDestination,
    param: 'actorData',
  },
  [DC.PARCEL_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
    param: 'status',
  },
  [DC.CONTRACTED]: {
    type: FilterTypes.toggle,
    title: 'Only contracted',
    icon: Icons.CheckCircle,
    param: 'contracted',
  },
  [DC.SCAN]: {
    type: FilterTypes.button,
    icon: Icons.ScanLine,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const initialFilterValues = {
  [DC.RISK_TYPE]: '',
  [DC.PARCEL_STATUS]: '',
  [DC.CONTRACTED]: '',
  [DC.SPECIAL_FILTER]: '',
  [DC.LOCATION]: '',
  [DC.BRAND]: '',
  [DC.ACTOR_DATA]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'All time',
  [DC.RISK_TYPE]: 'Risk type',
  [DC.PARCEL_STATUS]: 'Status',
  [DC.SPECIAL_FILTER]: 'Filter',
  [DC.LOCATION]: 'Location',
  [DC.BRAND]: 'Customer',
  [DC.ACTOR_DATA]: 'Actor data',
};

export const allOptions = {
  [DC.RISK_TYPE]: { label: 'All', value: Constants.all },
  [DC.PARCEL_STATUS]: { label: 'All', value: Constants.all },
  [DC.SPECIAL_FILTER]: { label: 'All', value: Constants.all },
  [DC.BRAND]: [
    { label: 'All customers', value: Constants.all },
    {
      label: 'All non-contracted customers',
      value: productBrandIsNotContracted,
    },
    { label: 'All contracted customers', value: productBrandIsContracted },
  ],
  [DC.ACTOR_DATA]: { label: 'All status', value: Constants.all },
  [DC.LOCATION]: { label: 'All locations', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Parcels,
    title: 'No parcels',
    description: 'As soon as we catch a new parcel, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.Parcels,
    title: 'No results found',
    description: 'Please try another search or view all parcels',
  },
};

export const parcelActions = doParcelAction => ({
  [Inspection]: {
    text: 'Inspect',
    onClick: doParcelAction(Inspection),
    leftIcon: Icons.Search,
  },
  [Authentication]: {
    text: 'Authenticate',
    onClick: doParcelAction(Authentication),
    leftIcon: Icons.CheckCircle,
  },
  [Stopped]: {
    text: 'Stop',
    onClick: doParcelAction(Stopped),
    leftIcon: Icons.PauseCircle,
  },
  [Seized]: {
    text: 'Seize',
    onClick: doParcelAction(Seized),
    leftIcon: Icons.Lock,
  },
  [Released]: {
    text: 'Release',
    onClick: doParcelAction(Released),
    leftIcon: Icons.ArrowRight,
  },
});

export const statusActions = {
  [Sideload]: [Inspection, Stopped, Seized, Released, Authentication],
  [Inspection]: [Stopped, Seized, Released, Authentication],
  [Authentication]: [Inspection, Stopped, Seized, Released],
  [Stopped]: [Inspection, Seized, Released],
  [Seized]: [Inspection, Stopped, Released],
  [Released]: [Inspection, Stopped, Seized, Released],
};

export const statusActionPermission = {
  [Sideload]: EDIT_PARCEL_STATUS_SIDELOAD,
  [Inspection]: EDIT_PARCEL_STATUS_INSPECTION,
  [Authentication]: EDIT_PARCEL_STATUS_AUTHENTICATION,
  [Stopped]: EDIT_PARCEL_STATUS_STOPPED,
  [Seized]: EDIT_PARCEL_STATUS_SEIZED,
  [Released]: EDIT_PARCEL_STATUS_RELEASED,
};
