import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Popup } from '../../../../../components/templates/Popup';
import { BaseText } from './atoms';
import { AddServiceActions, InvoiceDropzone, InvoiceFields } from './organisms';
import { SnapshotPopupContent } from './style';

const sectionTitleAdd = 'Add the requested information to add a new invoice';
const sectionTitleEdit = 'Edit the requested information to update the invoice';

export const AddInvoiceLayout = ({
  isEditing,
  invoiceFieldsProps,
  invoiceDropzoneProps,
  actionsProps,
}) => {
  return (
    <Popup size={'_M'} title={isEditing ? 'Edit invoice' : 'Add invoice'}>
      <SnapshotPopupContent>
        <Spacer height={'_M'} />
        <Flex w justify={'center'}>
          <BaseText
            text={isEditing ? sectionTitleEdit : sectionTitleAdd}
            size="_L"
          />
        </Flex>
        <Spacer height={'_M'} />
        <Flex gap="_S" grow>
          <InvoiceFields invoiceFieldsProps={invoiceFieldsProps} />
          <InvoiceDropzone invoiceDropzoneProps={invoiceDropzoneProps} />
        </Flex>
        <AddServiceActions actionsProps={actionsProps} />
      </SnapshotPopupContent>
    </Popup>
  );
};
