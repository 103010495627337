import usePostData from '../../../../../hooks/postHook';

export const useAddContract = ({ isEditing }) => {
  const [{ data, body, isLoading, isError, error }, doAddContract] =
    usePostData(``, {
      type: isEditing ? 'put' : 'post',
    });

  return { data, body, isLoading, isError, error, doAddContract };
};
