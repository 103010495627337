import { useRef } from 'react';
import usePostData from '../../../../../hooks/postHook';
import { senderPayloadFormat } from '../../../features_public/reference_index/api/createReference';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const useUpdateReference = ({
  [referenceIndexKeys.id]: senderId,
  isAdmin,
}) => {
  const actionsRef = useRef({});

  const [{ body, isLoading, isError, error }, doUpdate_] = usePostData(
    `${isAdmin ? 'admin_' : ''}sender/${senderId}`,
    { type: 'put' }
  );

  const doUpdate = (values, actions) => {
    const body = senderPayloadFormat(values);
    actionsRef.current = actions;
    doUpdate_(body);
  };

  return {
    isLoading,
    isError: body?.name?.includes('error'),
    error,
    actions: actionsRef.current,
    body,
    doUpdate,
  };
};
