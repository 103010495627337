import { Card } from '../../../../../components/atoms';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { ButtonTertiaryMedium } from '../../../../../components/molecules/Button/Button.export';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { ReferenceDetailDisplay } from '../../../features_public/reference_index/components/organisms';
import { UpdateSenderDetailsButton } from './atoms/atoms';

const { SELF, EDIT_REFERENCE_INFO, DELETE_REFERENCES } =
  PermissionKeys.Brand.REFERENCES;

export const ReferenceInfoLayout = ({
  showConfirmDelete,
  onClick,
  referenceInfo_,
  fields,
  isAdmin,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasEditPermission = hasPermission(EDIT_REFERENCE_INFO);
  const hasDeletePermission = hasPermission(DELETE_REFERENCES);

  return (
    <Card
      title={'Reference Info'}
      className="reference-info"
      icon={
        hasEditPermission && <UpdateSenderDetailsButton onClick={onClick} />
      }
    >
      <ReferenceDetailDisplay
        fields={fields}
        referenceInfo={referenceInfo_}
        isAdmin={isAdmin}
      />
      {hasDeletePermission && (
        <ButtonTertiaryMedium
          text={'Delete reference'}
          leftIcon={Icons.Trash2}
          onClick={showConfirmDelete}
        />
      )}
    </Card>
  );
};
