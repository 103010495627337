import { Icon } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Illustrations } from '../../../../../components/atoms/Icon/Icon.options';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import { Fields } from '../components';

export const WizardDetailsLayout = ({
  onPrimary,
  onCancel,
  onBack,
  primaryText,
  disabled,
  fields,
  actingBrandProps,
  selectOption,
  setTouched,
}) => {
  return (
    <StepPopup
      title={`New knowledge base - setup wizard`}
      sectionTitle="Add the requested information to create a new knowledge base"
      onPrimary={onPrimary}
      primaryText={primaryText}
      disabled={disabled}
      onCancel={onCancel}
      onBack={onBack}
    >
      <Flex w h gap="_L">
        <Fields
          fields={fields}
          selectOption={selectOption}
          setTouched={setTouched}
          actingBrandProps={actingBrandProps}
        />
        <Icon name={Illustrations.InstructionWizard} />
      </Flex>
    </StepPopup>
  );
};
