import { Card } from '../../../../../components/atoms';
import { Table } from '../../../../../components/organisms/Table/Table';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { contractsTableComponents } from './molecules';

export const ContractsLayout = ({
  headers,
  data,
  rowAction,
  grid,
  isLoading,
  isError,
  error,
  tableStatusProps,
  downloadContract,
}) => {
  return (
    <Card title="Contracts" className="contracts">
      <TableStatus
        isLoading={isLoading}
        isError={isError}
        error={error}
        {...tableStatusProps}
        table={
          <Table
            headers={headers}
            data={data}
            rowAction={rowAction}
            grid={grid}
            components={contractsTableComponents({ downloadContract })}
            downloadContract={downloadContract}
          />
        }
      />
    </Card>
  );
};
