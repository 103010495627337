import * as Yup from 'yup';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { Constants, Utils } from '../../../../../constants';
import { objectify } from '../../../../../lib/js';
import countryList from '../../../../../utils/countryList';
import { isValidField, parseStringModifiers } from '../../../../../utils/utils';
import {
  dropdownValidation,
  dropdownValidationUnrequired,
} from '../../../../../utils/validationUtils';

export const referenceIndexKeys = {
  accuracy: 'accuracy',
  active: 'active',
  addedBy: 'addedBy',
  address: 'address',
  additionalDetails: 'details',
  alias: 'alias',
  caseStatistics: 'caseStatistics',
  casesActive: 'casesActive',
  casesTotal: 'casesTotal',
  casesSeized: 'casesSeized',
  city: 'city',
  company: 'company',
  country: 'country',
  dateCreated: 'dateCreated',
  id: 'sender_id',
  lastSideload: 'lastSideload',
  name: 'name',
  notes: 'notes',
  leftPadding: 'leftPadding',
  phone: 'phone',
  popularLocation: 'popularLocation',
  state: 'state',
  zipcode: 'postcode',
  type: 'type',
  brand: {
    self: 'brand',
    name: 'brand.name',
    image: 'brand.image',
    id: 'brand.id',
  },
  weights: {
    type: 'sideload_limit',
    name: 'name_weight',
    company: 'company_weight',
    address: 'address_weight',
    phone: 'phone_weight',
    zipcode: 'postcode_weight',
    city: 'city_weight',
    state: 'state_weight',
    country: 'country_weight',
  },
};

export const views = {
  addReference: 'addReference',
  spreadhseet: 'spreadsheet',
  image: 'image',
  manual: 'manual',
};

export const title = {
  [views.addReference]: 'Add reference data',
  [views.spreadhseet]: 'Add reference data - Import file',
  [views.image]: 'Add reference data - Upload images',
  [views.manual]: 'Add reference data - Add manually',
};

export const editingTitle = {
  [views.manual]: 'Edit reference',
};

export const infoDescription = {
  [views.addReference]:
    parseStringModifiers(`**Choose an option to add your reference data.**
  The data you add will be used to intercept your counterfeit and illicit items.`),
  [views.spreadhseet]: 'Import a CSV or Excel file containing reference data.',
  [views.image]: 'Upload images of previously intercepted parcels.',
  [views.manual]: 'Enter your reference data manually.',
};

export const editingInfoDescription = {
  [views.manual]: 'Edit your reference data manually.',
};

export const saveText = ({ view, successfulUpload, isEditing }) => {
  if (successfulUpload) return 'Add more';
  const _saveText = {
    [views.spreadhseet]: 'Import',
    [views.image]: 'Upload',
    [views.manual]: isEditing ? 'Save' : 'Add',
  };

  return _saveText[view];
};

export const promptText = {
  [views.spreadhseet]: parseStringModifiers(
    `Drag and drop your file(s) \n\nor`
  ),
  [views.image]: parseStringModifiers(`Drag and drop your images \n\nor`),
};

export const uploadIcon = {
  [views.spreadhseet]: Icons.FileInput,
  [views.image]: Icons.Image,
};

export const browseFileButtonText = {
  [views.spreadhseet]: 'Browse file',
  [views.image]: 'Browse file',
};

export const fileType = {
  [views.spreadhseet]: Constants.FileUpload.uploadType.spreadsheet,
  [views.image]: Constants.FileUpload.uploadType.image,
};

const errorText = {
  [Constants.FileUpload.extensionError]: {
    [views.spreadhseet]: parseStringModifiers(
      `We only accept CSV or Excel files.\nPlease try again with another file type.`
    ),
    [views.image]: parseStringModifiers(
      `We only accept JPG or PNG files.\nPlease try again with another file type.`
    ),
  },
  [Constants.FileUpload.uploadError]: {
    [views.spreadhseet]: parseStringModifiers(
      `There was an error uploading this file.\nPlease try again.`
    ),
    [views.image]: parseStringModifiers(
      `There was an error uploading this file.\nPlease try again.`
    ),
  },
  [Constants.FileUpload.unexpectedError]: {
    [views.spreadhseet]: parseStringModifiers(
      `There was an error uploading this file.\nPlease try again.`
    ),
    [views.image]: parseStringModifiers(
      `There was an error uploading this file.\nPlease try again.`
    ),
  },
  [Constants.FileUpload.fileSizeError]: {
    [views.spreadhseet]: parseStringModifiers(
      `${Constants.FileUpload.uploadTypeUtils.spreadsheet.fileSizeError}\nPlease try again.`
    ),
    [views.image]: parseStringModifiers(
      `${Constants.FileUpload.uploadTypeUtils.image.fileSizeError}\nPlease try again.`
    ),
  },
};

export const uploadStatusContent = ({ fileState, resetFileUpload, view }) => {
  const { files, isUploading, completedUpload, uploadSuccess, errorType } =
    fileState;

  if (!files) {
    return {
      icon: uploadIcon[view],
      text: promptText[view],
      button: {
        onClick: Utils.emptyFunction,
        text: browseFileButtonText[view],
      },
    };
  }

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  if (!completedUpload) {
    const numOfFiles = files.length;
    let text = `Your file: **${files[0].name}** is ready to import`;
    if (numOfFiles > 1)
      text = `Your **${numOfFiles}** files are ready to import`;
    return {
      icon: Icons.Upload,
      title: 'Upload ready',
      text: parseStringModifiers(text),
    };
  }

  if (!uploadSuccess)
    return {
      icon: Icons.Warning,
      title: 'Upload failed',
      text: errorText[errorType][view],
      button: {
        onClick: resetFileUpload,
        text: 'Try again',
      },
    };

  if (uploadSuccess)
    return {
      icon: Icons.CheckCircle,
      title: 'Uploading success',
      text: 'Your file has been successfully imported',
    };
};

export const options = [
  {
    icon: Icons.FileInput,
    title: 'Import files',
    subtitle: 'Import a CSV or Excel file(s) containing reference data',
    selectOption: {
      text: 'Import files',
    },
  },
  {
    icon: Icons.ListPlus,
    title: 'Add manually',
    subtitle: 'Enter your reference data manually',
    selectOption: {
      text: 'Add manually',
    },
  },
  {
    icon: Icons.Image,
    title: 'Upload images',
    subtitle: 'Upload images of previously intercepted parcels',
    selectOption: {
      text: 'Upload images',
    },
  },
];

export const referenceFieldsPlaceholders = {
  [referenceIndexKeys.name]: 'Name',
  [referenceIndexKeys.company]: 'Company',
  [referenceIndexKeys.address]: 'Address',
  [referenceIndexKeys.phone]: 'Phone',
  [referenceIndexKeys.zipcode]: 'Post code',
  [referenceIndexKeys.city]: 'City',
  [referenceIndexKeys.state]: 'Province/State',
  [referenceIndexKeys.country]: 'Country',
  [referenceIndexKeys.additionalDetails]: 'Additional information',
};

export const referenceFieldsKeys = Object.keys(referenceFieldsPlaceholders);

export const referenceFields = objectify(referenceFieldsKeys, _ => '');

export const referenceFieldProps = {
  [referenceIndexKeys.country]: { type: 'select', options: countryList },
  [referenceIndexKeys.additionalDetails]: { type: 'textarea', hasError: false },
};

export const referenceInfoValidationSchema = Yup.object().shape(
  {
    [referenceIndexKeys.name]: Yup.string().when(
      [referenceIndexKeys.address, referenceIndexKeys.company],
      {
        is: (addr, comp) => {
          const addressIsValid = isValidField(addr, 2, 200);
          const companyIsValid = isValidField(comp, 2, 100);
          return addressIsValid || companyIsValid;
        },
        then: Yup.string()
          .min(4, '4 chars minimum')
          .max(100, '100 chars maximum')
          .notRequired()
          .nullable(),
        otherwise: Yup.string()
          .min(4, '4 chars minimum')
          .max(100, '100 chars maximum')
          .required("Please enter an actor's name, company, or address")
          .nullable(),
      }
    ),
    [referenceIndexKeys.company]: Yup.string().when(
      [referenceIndexKeys.name, referenceIndexKeys.address],
      {
        is: (name, addr) => {
          const nameIsValid = isValidField(name, 4, 100);
          const addressIsValid = isValidField(addr, 2, 200);
          return nameIsValid || addressIsValid;
        },
        then: Yup.string()
          .min(2, '2 chars minimum')
          .max(100, '100 chars maximum')
          .notRequired()
          .nullable(),
        otherwise: Yup.string()
          .min(2, '2 chars minimum')
          .max(100, '100 chars maximum')
          .required("Please enter an actor's name, company, or address")
          .nullable(),
      }
    ),
    [referenceIndexKeys.address]: Yup.string().when(
      [referenceIndexKeys.name, referenceIndexKeys.company],
      {
        is: (name, comp) => {
          const nameIsValid = isValidField(name, 4, 100);
          const companyIsValid = isValidField(comp, 2, 100);
          return nameIsValid || companyIsValid;
        },
        then: Yup.string()
          .min(2, '2 chars minimum')
          .max(200, '200 chars maximum')
          .notRequired()
          .nullable(),
        otherwise: Yup.string()
          .min(2, '2 chars minimum')
          .max(200, '200 chars maximum')
          .required("Please enter an actor's name, company, or address")
          .nullable(),
      }
    ),
    [referenceIndexKeys.country]: dropdownValidationUnrequired.nullable(),
    [referenceIndexKeys.zipcode]: Yup.string()
      .min(3, 'Must be minimum 3 digits')
      .max(15, 'Must be maximum 15 digits')
      .notRequired("Please enter the sender's post code")
      .nullable(),
    [referenceIndexKeys.city]: Yup.string()
      .min(3, '3 chars minimum')
      .max(50, '50 chars maximum')
      .notRequired("Please enter the reference's city")
      .nullable(),
    [referenceIndexKeys.state]: Yup.string()
      .min(2, '2 chars minimum')
      .max(50, '50 chars maximum')
      .notRequired("Please enter the reference's city")
      .nullable(),
    [referenceIndexKeys.phone]: Yup.string()
      .matches(
        /^[0-9-\s\+\/\(\)\#]+$/,
        'Use digits, spaces, or one of the following: - / + () #'
      )
      .min(5, 'Must be minimum 5 digits')
      .max(20, 'Must be maximum 20 digits')
      .notRequired("Please enter the sender's phone number")
      .nullable(),
    [referenceIndexKeys.additionalDetails]: Yup.string()
      // .min(5, '5 chars minimum')
      .max(999, '999 chars maximum')
      .notRequired('')
      .nullable(),
  },
  [
    [referenceIndexKeys.name, referenceIndexKeys.company],
    [referenceIndexKeys.name, referenceIndexKeys.address],
    [referenceIndexKeys.address, referenceIndexKeys.company],
  ]
);

const weightValidation = Yup.number()
  .typeError('Positive integer')
  .integer('Positive integer')
  .moreThan(0, 'Positive integer');
const limitValidation = Yup.number()
  .typeError('Integer only')
  .integer('Integer only');

export const newReferenceLayout = {
  referenceFields: [
    {
      name: referenceIndexKeys.type,
      label: 'Reference type',
      placeholder: 'Select a reference type',
      type: 'select',
      options: [],
      limit: true,
    },
    {
      name: referenceIndexKeys.name,
      label: 'Name',
      placeholder: 'Enter an actor name',
    },
    {
      name: referenceIndexKeys.company,
      label: 'Company',
      placeholder: 'Enter a company name',
    },
    {
      name: referenceIndexKeys.address,
      label: 'Address',
      placeholder: 'Enter an address',
    },
    {
      name: referenceIndexKeys.phone,
      label: 'Phone',
      placeholder: 'Enter a phone number',
    },
    {
      name: referenceIndexKeys.zipcode,
      label: 'Post code',
      placeholder: 'Enter a post code',
    },
    {
      name: referenceIndexKeys.city,
      label: 'City',
      placeholder: 'Enter a city',
    },
    {
      name: referenceIndexKeys.state,
      label: 'Province/State',
      placeholder: 'Enter a province/state',
    },
    {
      name: referenceIndexKeys.country,
      label: 'Country',
      placeholder: 'Enter a country',
      type: 'select',
      options: countryList,
    },
  ],
  validationSchema: Yup.object().shape(
    {
      brand: dropdownValidation,
      [referenceIndexKeys.name]: Yup.string().when(
        [referenceIndexKeys.address, referenceIndexKeys.company],
        {
          is: (addr, comp) => {
            const addressIsValid = isValidField(addr, 2, 200);
            const companyIsValid = isValidField(comp, 2, 100);
            return addressIsValid || companyIsValid;
          },
          then: Yup.string()
            .min(4, '4 chars minimum')
            .max(100, '100 chars maximum')
            .notRequired()
            .nullable(),
          otherwise: Yup.string()
            .min(4, '4 chars minimum')
            .max(100, '100 chars maximum')
            .required("Please enter an actor's name, company, or address")
            .nullable(),
        }
      ),
      [referenceIndexKeys.company]: Yup.string().when(
        [referenceIndexKeys.name, referenceIndexKeys.address],
        {
          is: (name, addr) => {
            const nameIsValid = isValidField(name, 4, 100);
            const addressIsValid = isValidField(addr, 2, 200);
            return nameIsValid || addressIsValid;
          },
          then: Yup.string()
            .min(2, '2 chars minimum')
            .max(100, '100 chars maximum')
            .notRequired()
            .nullable(),
          otherwise: Yup.string()
            .min(2, '2 chars minimum')
            .max(100, '100 chars maximum')
            .required("Please enter an actor's name, company, or address")
            .nullable(),
        }
      ),
      [referenceIndexKeys.address]: Yup.string().when(
        [referenceIndexKeys.name, referenceIndexKeys.company],
        {
          is: (name, comp) => {
            const nameIsValid = isValidField(name, 4, 100);
            const companyIsValid = isValidField(comp, 2, 100);
            return nameIsValid || companyIsValid;
          },
          then: Yup.string()
            .min(2, '2 chars minimum')
            .max(200, '200 chars maximum')
            .notRequired()
            .nullable(),
          otherwise: Yup.string()
            .min(2, '2 chars minimum')
            .max(200, '200 chars maximum')
            .required("Please enter an actor's name, company, or address")
            .nullable(),
        }
      ),
      [referenceIndexKeys.country]: dropdownValidationUnrequired.nullable(),
      [referenceIndexKeys.type]: dropdownValidation.nullable(),
      [referenceIndexKeys.zipcode]: Yup.string()
        .min(3, 'Must be minimum 3 digits')
        .max(15, 'Must be maximum 15 digits')
        .notRequired("Please enter the sender's post code")
        .nullable(),
      [referenceIndexKeys.city]: Yup.string()
        .min(3, '3 chars minimum')
        .max(50, '50 chars maximum')
        .notRequired("Please enter the reference's city")
        .nullable(),
      [referenceIndexKeys.state]: Yup.string()
        .min(2, '2 chars minimum')
        .max(50, '50 chars maximum')
        .notRequired("Please enter the reference's city")
        .nullable(),
      [referenceIndexKeys.phone]: Yup.string()
        .matches(
          /^[0-9-\s\+\/\(\)\#]+$/,
          'Use digits, spaces, or one of the following: - / + () #'
        )
        .min(5, 'Must be minimum 5 digits')
        .max(20, 'Must be maximum 20 digits')
        .notRequired("Please enter the sender's phone number")
        .nullable(),
    },
    [
      [referenceIndexKeys.name, referenceIndexKeys.company],
      [referenceIndexKeys.name, referenceIndexKeys.address],
      [referenceIndexKeys.address, referenceIndexKeys.company],
    ]
  ),
  weightsValidationSchema: Yup.object().shape({
    [referenceIndexKeys.weights.address]: weightValidation,
    [referenceIndexKeys.weights.city]: weightValidation,
    [referenceIndexKeys.weights.company]: weightValidation,
    [referenceIndexKeys.weights.country]: weightValidation,
    [referenceIndexKeys.weights.name]: weightValidation,
    [referenceIndexKeys.weights.phone]: weightValidation,
    [referenceIndexKeys.weights.postcode]: weightValidation,
    [referenceIndexKeys.weights.state]: weightValidation,
    [referenceIndexKeys.weights.type]: limitValidation.required('Required'),
  }),
  fieldWeightMatch: {
    [referenceIndexKeys.name]: referenceIndexKeys.weights.name,
    [referenceIndexKeys.company]: referenceIndexKeys.weights.company,
    [referenceIndexKeys.address]: referenceIndexKeys.weights.address,
    [referenceIndexKeys.phone]: referenceIndexKeys.weights.phone,
    [referenceIndexKeys.zipcode]: referenceIndexKeys.weights.zipcode,
    [referenceIndexKeys.city]: referenceIndexKeys.weights.city,
    [referenceIndexKeys.state]: referenceIndexKeys.weights.state,
    [referenceIndexKeys.country]: referenceIndexKeys.weights.country,
    [referenceIndexKeys.type]: referenceIndexKeys.weights.type,
  },
};
