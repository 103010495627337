const { default: usePostData } = require('../../../../../hooks/postHook');

export const useUpdateTracking = ({ id }) => {
  const [{ body, isLoading = true, isError, error }, doUpdateTracking] =
    usePostData(`office_parcel/${id}/tracking`, {
      type: 'put',
    });

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdateTracking,
  };
};
