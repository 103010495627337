import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Barcodes, TrackingNumber } from './organisms';
import { DetailsLayoutCSS } from './style';

export const DetailsLayout = ({ trackingNumberProps, barcodesProps }) => {
  return (
    <DetailsLayoutCSS>
      <Flex column h w gap="_M">
        <TrackingNumber {...trackingNumberProps} />
        <Barcodes {...barcodesProps} />
      </Flex>
    </DetailsLayoutCSS>
  );
};
