import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  prefillDataForEditing,
  withStringDropdownValues,
} from '../../../../../utils/utils';
import { dropdownValidation } from '../../../../../utils/validationUtils';
import { formatServiceRoles, useAddService } from '../api/addService';
import { useGetServiceKeysList } from '../api/getServiceKeysList';
import AddServiceLayout from '../components/AddServiceLayout';
import { prefillCustomInitialValues } from './utils';
import {
  fieldNames,
  adminServicesKeys as keys,
  permissionGridHeaders,
  permissionInit,
} from './variables';

const fields = [
  {
    name: fieldNames.type,
    label: 'Service type',
    type: 'select',
    placeholder: 'Select service type',
    options: [],
  },
  {
    name: fieldNames.icon,
    label: 'Service icon',
    placeholder: 'Enter a Lucide Icon name in CamelCase style',
  },
  {
    name: fieldNames.name,
    label: 'Service name',
    placeholder: 'Enter a service name',
  },
  {
    name: fieldNames.systemName,
    label: 'Service ID',
    placeholder: 'Enter a service ID',
  },
  {
    name: fieldNames.description,
    label: 'Service description',
    placeholder: 'Enter a description for the service',
    type: 'textarea',
  },
  {
    name: fieldNames.adminRoleDescription,
    label: 'Admin role description',
    placeholder: 'Enter a description for the admin role',
    type: 'textarea',
  },
  {
    name: fieldNames.editorRoleDescription,
    label: 'Editor role description',
    placeholder: 'Enter a description for the editor role',
    type: 'textarea',
  },
  {
    name: fieldNames.viewerRoleDescription,
    label: 'Viewer role description',
    placeholder: 'Enter a description for the viewer role',
    type: 'textarea',
  },
];

const initialValues = {};
fields.forEach(field => (initialValues[field.name] = ''));
initialValues[fieldNames.permissions.row] = [permissionInit];

const validationSchema = serviceKeys =>
  Yup.object().shape({
    [fieldNames.type]: dropdownValidation,
    [fieldNames.icon]: Yup.string().required('Required'),
    [fieldNames.name]: Yup.string().required('Required'),
    [fieldNames.systemName]: Yup.string()
      .notOneOf(serviceKeys, 'This key is already being used')
      .required('Required'),
    [fieldNames.description]: Yup.string().required('Required'),
    [fieldNames.adminRoleDescription]: Yup.string().required('Required'),
    [fieldNames.editorRoleDescription]: Yup.string().required('Required'),
    [fieldNames.viewerRoleDescription]: Yup.string().required('Required'),
    [fieldNames.permissions.row]: Yup.array().of(
      Yup.object({
        [fieldNames.permissions.name]: Yup.string().required('Required'),
        [fieldNames.permissions.systemName]: Yup.string().required('Required'),
        [fieldNames.permissions.admin]: Yup.boolean(),
        [fieldNames.permissions.editor]: Yup.boolean(),
        [fieldNames.permissions.viewer]: Yup.boolean(),
      }).test(
        'at-least-one-boolean',
        'At least one boolean must be true',
        item =>
          item[fieldNames.permissions.admin] ||
          item[fieldNames.permissions.editor] ||
          item[fieldNames.permissions.viewer]
      )
    ),
  });

const mapFieldsToResponse = {
  [fieldNames.type]: keys.type,
  [fieldNames.icon]: keys.icon,
  [fieldNames.name]: keys.name,
  [fieldNames.systemName]: keys.systemName,
  [fieldNames.description]: keys.description,
};

const AddService = ({ mode = 'add', services, prevData, onSuccess }) => {
  const isEditing = mode === 'edit';

  const [_, toggle] = useToggle(false);

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { body, isLoading, isError, error, doAddService } = useAddService({
    isEditing,
  });

  const { data: serviceKeys } = useGetServiceKeysList();

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    onSuccess();
  }, [body, isLoading, isError]);

  useEffect(() => {
    if (services == null) return;
    fields.find(field => field.name === fieldNames.type).options = services;

    fields.find(field => field.name === fieldNames.systemName).readOnly =
      isEditing;

    toggle();
  }, []);

  const onSubmit = values => {
    const url = isEditing
      ? `/admin_service/${prevData[keys.id]}`
      : '/admin_services';

    const roles = formatServiceRoles(values);
    const body = {
      ...withStringDropdownValues(values),
      roles,
    };

    doAddService(body, url);
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData,
        mapFieldsToResponse,
        fields,
      });
      initialValues = prefillCustomInitialValues(initialValues, prevData);
    }
    return initialValues;
  };

  const permitOwnServiceKey = (serviceKeys, serviceKey) => {
    return serviceKeys.filter(key => key !== serviceKey);
  };

  return (
    <Formik
      initialValues={withPrevData(initialValues)}
      validationSchema={validationSchema(
        permitOwnServiceKey(serviceKeys, prevData?.[keys.systemName])
      )}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, values, setFieldValue, dirty }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });

        return (
          <Form>
            <AddServiceLayout
              isEditing={isEditing}
              dismiss={dismiss}
              fieldProps={{
                fields,
                selectOption,
                values,
              }}
              permissionGridProps={{
                headers: permissionGridHeaders,
                values,
                isEditing,
              }}
              actionsProps={{
                onCancel: dismiss,
                primaryText: isEditing ? 'Save' : 'Add',
                disabled: !isValid || !dirty,
                isLoading,
                hasSeparator: true,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(AddService);
