const { default: usePostData } = require('../../../../../hooks/postHook');

export const useUpdateProductCategory = ({ id }) => {
  const [{ body, isLoading, isError, error }, doUpdateProductCategory] =
    usePostData(`office_case/${id}/category`, {
      type: 'put',
    });

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdateProductCategory,
  };
};
