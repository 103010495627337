import usePostData from '../../../../../hooks/postHook';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';

export const useAddLinks = ({ instructionId }) => {
  const { showSnackbarError } = useSnackbar();
  const [{ data, body, isLoading, isError, error }, doAddLinks] = usePostData(
    `knowledge_base/${instructionId}/links`,
    {
      errorCallback: () => showSnackbarError(error),
    }
  );

  //const { urls } = req.body;
  return { data, body, isLoading, isError, error, doAddLinks };
};
