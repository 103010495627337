import usePostData from '../../../../../hooks/postHook';

export const useUpdateProduct = ({ id }) => {
  const [{ body, isLoading, isError, error }, updateProduct] = usePostData(
    `office_case/${id}/category`,
    { type: 'put' }
  );

  return { body, isLoading, isError, error, updateProduct };
};
