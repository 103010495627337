import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';

export const useGetBrandServices = ({ keepDisabled = false, ignore } = {}) => {
  const [
    {
      data = { services: [], defaultRoles: [] },
      isLoading = true,
      isError,
      error,
    },
  ] = useFetchData(`services`, {
    formatter: formatter({ keepDisabled }),
    ignore,
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatter =
  ({ keepDisabled }) =>
  data => {
    data.services.forEach(
      service => (service.roles = maskEntry(service.roles, rolesMask))
    );

    data = maskEntry(data, mask);

    if (!keepDisabled) data.services = data.services.filter(s => s.is_enabled);

    return data;
  };

const rolesMask = {
  name: 'text',
};

const mask = {
  name: 'title',
  default_roles: 'defaultRoles',
};
