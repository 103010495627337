import { SidebarWidthToggle } from '../../atoms/SidebarWidthToggle/SidebarWidthToggle';
import { Menu } from './components';
import { Container, Scroll, TogglePositionContainer } from './Sidebar.style';

export const SidebarLayout = ({
  sidebarRef,
  toggleExpanded,
  togglePlacement,
  Layout,
  expanded,
  transition,
  props,
}) => {
  return (
    <TogglePositionContainer expanded={expanded} className="toggle-container">
      {!props.hideToggle && (
        <SidebarWidthToggle
          expanded={expanded}
          onClick={toggleExpanded}
          placement={togglePlacement}
        />
      )}
      <Container ref={sidebarRef} expanded={expanded}>
        <Scroll>
          <Layout expanded={expanded} transition={transition} {...props} />
        </Scroll>
      </Container>
      <Menu {...props} />
    </TogglePositionContainer>
  );
};
