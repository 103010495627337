import { Constants } from '../../../../../constants';
import { maskEntry } from '../../../../../utils/utils';
import { invoiceKeys } from '../controllers/variables';

export const formatPlan = data => {
  data = maskEntry(data, subscriptionPlanMask);
  const plan = {};

  plan.title = data?.[invoiceKeys.planName];

  const monthlyCost = data[invoiceKeys.monthlyCost];
  plan.monthlyPrice =
    monthlyCost != null ? `${monthlyCost} / month` : Constants.fallback;

  const monthDetail =
    monthlyCost != null
      ? `${monthlyCost == 0 ? 'Free' : monthlyCost} monthly subscription`
      : Constants.fallback;

  const catchCost = data[invoiceKeys.costPerCatch];
  const catchDetail =
    catchCost != null
      ? `${catchCost == 0 ? 'Free' : catchCost} per seizure`
      : Constants.fallback;

  const details = [monthDetail, catchDetail];
  plan.details = details;

  return plan;
};

const subscriptionPlanMask = {
  spCostPerSeizure: [invoiceKeys.costPerCatch],
  spCostPerMonth: [invoiceKeys.monthlyCost],
  spCostPerYear: [invoiceKeys.yearlyCost],
  spFreeUsersCount: [invoiceKeys.freeUsersCount],
  spName: [invoiceKeys.planName],
};
