import { Constants, PropKeys } from '../../constants';
import { API } from '../../lib/api';
import { formatClient } from './getClients';

const { Logistics, Brand } = Constants;

export const getUserInfo = async ({ token, saveUserInfo }) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { data, status, error } = await API.GET('user_profile', config);

  if (status !== 200 || error) return;
  saveUserInfo(format(data));
};

const format = data => {
  if (data == null) return {};
  // pulls from ./getClients to ensure naming consistency of properties
  const formattedClientData = formatClient(data);
  const formattedUserData = formatUser(data);
  const formattedData = { ...formattedClientData, ...formattedUserData };

  return formattedData;
};

const formatUser = data => {
  if (data == null) return {};
  const { usEmail, usPhone, usImage, usDisplayName, usFirstName, usLastName } =
    data;
  return {
    [PropKeys.email]: usEmail,
    [PropKeys.phone]: usPhone,
    [PropKeys.userImage]: usImage,
    [PropKeys.username]: usDisplayName,
    [PropKeys.firstName]: usFirstName,
    [PropKeys.lastName]: usLastName,
  };
};
