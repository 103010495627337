import { TableLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';

import Attachments from '../../../../../../components/organisms/Attachments/Attachments';
import Notes from '../../../../../../components/organisms/Notes/Notes';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import { defaultErrorDisplay } from '../../../../../../components/templates/TableStatus/variables';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../../providers/PermissionProvider/PermissionsProvider';
import {
  ProductDetailsSummary,
  RecentActivity,
  RiskAnalysis,
  StatusSteps,
} from '../../../../features_public/products/components/organisms';

import { NoResultsCSS } from '../../../../features_public/products/components/style';
import { productKeys } from '../../../../features_public/products/controllers/variables';
import { useCreateNote } from '../../api/createNote';
import { caseNotesConstants } from '../../variables';
import { CaseDetailsContainer } from '../atoms';
import {
  AuthorityInfo,
  ParcelDetailsSummary,
  ProductTickets,
} from '../organisms';

const {
  SELF,
  EDIT_PRODUCT_NOTES,
  EDIT_PRODUCT_ATTACHMENTS,
  DELETE_PRODUCT_ATTACHMENTS,
} = PermissionKeys.Brand.PRODUCTS;

export const ProductDetailsLayout = ({
  productId,
  productAlias,
  parcelAlias,
  goToCase,
  productDetails,
  status,
  images,
  steps,
  statusInstructions,
  actions,
  riskType,
  reason,
  activity,
  componentSize,
  parcelDetails,
  parcelDetailsIsError,
  parcelDetailsIsLoading,
  shouldBlurActors,
  notes,
  onSuccessCreateNote,
  tickets,
  authorityDetails,
  isLoading,
  isError,
  handleTicketClick,
  addTicket,
  ticketsRightIcon,
  attachments,
  hasTicketCreatePermission,
  hasCaseViewPermission,
  supportButtonTooltipContent,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);
  return isLoading ? (
    <NoResultsCSS>
      <TableLoader />
    </NoResultsCSS>
  ) : isError ? (
    <NoResultsCSS>
      <NoSearchResults {...defaultErrorDisplay} />
    </NoResultsCSS>
  ) : (
    <CaseDetailsContainer>
      <ParcelDetailsSummary
        sections={parcelDetails}
        shouldBlurActors={shouldBlurActors}
        caseAlias={parcelAlias}
        goToCase={goToCase}
        isLoading={parcelDetailsIsLoading}
        isError={parcelDetailsIsError}
        disabled={!hasCaseViewPermission}
      />
      <ProductDetailsSummary
        productId={productId}
        productDetails={productDetails}
        status={status}
        photos={images}
        size={componentSize}
        productAlias={productAlias}
      />
      <RiskAnalysis
        riskType={riskType}
        reason={reason}
        size={componentSize}
        isLoading={parcelDetailsIsLoading}
        icon
        disabled={!hasCaseViewPermission}
      />
      <StatusSteps
        steps={steps}
        status={status}
        statusInstructions={statusInstructions}
        actions={actions}
        size={componentSize}
      />
      <Notes
        notes={notes}
        onSuccessCreateNote={onSuccessCreateNote}
        isLoading={isLoading}
        hook={useCreateNote}
        params={{ productId }}
        {...caseNotesConstants}
        hasEditPermission={hasPermission(EDIT_PRODUCT_NOTES)}
      />
      <ProductTickets
        tickets={tickets}
        handleTicketClick={handleTicketClick}
        addTicket={hasTicketCreatePermission ? addTicket : null}
        rightIcon={ticketsRightIcon}
        supportButtonTooltipContent={supportButtonTooltipContent}
      />
      <RecentActivity activity={activity} icon />
      <Attachments
        route="case"
        folder="case_files"
        id={productId}
        attachmentIdKey={productKeys.attachmentId}
        urlKey={productKeys.attachmentURL}
        nameKey={productKeys.attachmentName}
        attachments={attachments}
        isLoading={isLoading}
        hasEditPermission={hasPermission(EDIT_PRODUCT_ATTACHMENTS)}
        hasDeletePermission={hasPermission(DELETE_PRODUCT_ATTACHMENTS)}
      />
      <AuthorityInfo authorityDetails={authorityDetails} />
    </CaseDetailsContainer>
  );
};
