import styled from '@emotion/styled';
import { Styles, Utils } from '../../../constants';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects, Layout } from '../../style';

export const OptionsHoverable = styled.div`
  position: absolute;
  top: 100%;
  padding-top: ${Layout.Spacing._2XS};
  // compensate for filter's container left padding + border
  left: -9px;
  width: 100%;
  min-width: 240px;
`;

export const OptionsContainerCSS = styled.div`
  overflow: auto;
  z-index: 10;
  margin: ${({ open }) => (open ? '8px 4px 0px 8px' : '')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  max-height: ${({ open, maxHeight = '298px' }) =>
    open ? `calc(${maxHeight} + ${optionsContainerVerticalPadding})` : 0};
  ${Styles.flexColumn};
  &::-webkit-scrollbar {
    display: unset;
    width: 8px;
  }
`;

export const OptionsScrollbarPosition = styled.div`
  box-shadow: ${Effects.Box_Shadow._S};
  border-radius: ${Effects.Border_Radius._S};
  background: ${Colors.Sharp.White};
  border: ${({ open }) => (open ? '1px' : '0px')} solid ${Colors.Neutral._200};
`;

const constantStyle = {
  option: {
    ...Styles.flex,
    gap: Layout.Spacing._2XS,
    padding: Layout.Spacing._2XS,
    borderRadius: Effects.Border_Radius._S,
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  section: {
    background: Colors.Sharp.White,
    position: 'relative',
    alignUtems: 'flex-start',
    width: '100%',
  },
  sectionTitle: {
    ...Styles.flex,
    padding: Layout.Spacing._2XS,
    width: '100%',
    overflow: 'auto',
  },
  separatorContainer: {
    position: 'relative',
    height: Layout.Spacing._XS,
    separator: {
      top: 0,
      margin: 'auto',
    },
  },
};

// Keep all transitions effects to one property.
// Otherwise, setting a padding always shows the padding even when the dropdown is collapsed.
const optionsContainerVerticalPadding = Layout.Spacing._2XS;
export const Recipe = variant => {
  return includeConstants(
    Recipes[variant] ?? Utils.emptyObjectFunction,
    constantStyle[variant]
  );
};

export const Recipes = {
  option: ({ selected, highlight }) => {
    return {
      background: selected && highlight ? Colors.Neutral._100 : 'transparent',
    };
  },
  sectionTitle: ({ showCheckbox }) => {
    return {
      paddingLeft: showCheckbox ? Layout.Spacing._M : Layout.Spacing._2XS,
    };
  },
};
