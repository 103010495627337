import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import {
  defaultErrorDisplay,
  defaultNoResults,
} from '../../../../../components/templates/TableStatus/variables';
import { Charts } from './organisms';
import { Container } from './style';

const SorterDataLayout = ({
  data,
  disabledData,
  isLoading,
  isError,
  hasViewPermission,
}) => {
  return (
    <Container>
      {isError ? (
        <NoSearchResults {...defaultErrorDisplay} />
      ) : isLoading ? (
        <TableLoader />
      ) : !data.length ? (
        <NoSearchResults {...defaultNoResults} />
      ) : (
        <Charts
          data={hasViewPermission ? data : disabledData}
          disabled={!hasViewPermission}
        />
      )}
      <Spacer height={'_S'} />
    </Container>
  );
};

export default SorterDataLayout;
