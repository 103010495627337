import { Card } from '../../../../../../components/atoms';
import { AddIcon } from '../../../../../../components/atoms/AddIcon/AddIcon';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Input } from '../../../../../../components/molecules';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import CopyableText from '../../../../../../components/molecules/CopyableText/CopyableTitle';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { NoResults } from '../../../../../../components/molecules/NoResults/NoResults';
import { Size } from '../../../../../../components/style';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import { isEmpty } from '../../../../../../lib/js';
import { parseStringModifiers } from '../../../../../../utils/utils';
import { DisableContainer } from '../../../../features_public/products/components/style';
import {
  componentTitle,
  components,
  infoDescription,
} from '../../../../features_public/products/controllers/variables';
import {
  authorityFields,
  authorityTitles,
  productFields,
  productProps,
  productTitles,
} from '../../controllers/variables';
import { noResults } from '../../variables';
import { Blur, InputTitle, ParcelAlias } from '../atoms';
import {
  AuthorityEntry,
  ErrorDisplay,
  ParcelDetailsSection,
  ProductTicketsContainer,
  tableComponents,
} from '../molecules/molecules';
import {
  AuthorityInfoCSS,
  ProductTicketsScroll,
  ProductsTableContainer,
} from '../style';

const disabledText2Rows = parseStringModifiers(
  'To visualize this data, you need to\nhave access to this service'
);

const disabledText1Row =
  'To visualize this data, you need to have access to this service';

export const ProductsTable = ({
  headers,
  data,
  grid,
  rowAction,
  checkedRows,
  tableComponentsProps,
}) => {
  return (
    <ProductsTableContainer
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      selectedRows={checkedRows}
      components={tableComponents({ ...tableComponentsProps })}
    />
  );
};

export const ParcelDetails = ({ sections }) => {
  return (
    <>
      {sections != null &&
        sections.length &&
        sections.map((section, sIndex) => {
          return (
            <ParcelDetailsSection
              section={section}
              index={sIndex}
              key={sIndex}
            />
          );
        })}
    </>
  );
};

export const AuthorityInfo = ({ authorityDetails, showEditAuthority }) => {
  const hasAuthority = authorityDetails && !isEmpty(authorityDetails);
  const onClick = showEditAuthority(authorityDetails);
  const headerElement = hasAuthority ? (
    <AnimatedIcon name={Icons.Edit3} theme={'light'} onClick={onClick} />
  ) : (
    <AddIcon onClick={onClick} top={0} right={0} />
  );

  return (
    <Card
      title={componentTitle[components.authorityInfo]}
      icon={headerElement}
      className="authority-info"
    >
      <AuthorityInfoCSS>
        {authorityDetails && authorityDetails.length ? (
          authorityDetails.map(entry => {
            const { title, info } = entry;
            return <AuthorityEntry title={title} info={info} />;
          })
        ) : (
          <NoResults {...noResults[components.authorityInfo]} />
        )}
      </AuthorityInfoCSS>
    </Card>
  );
};

const columns = 12;
const blurColumns = '7 / 13';

export const ParcelDetailsSummary = ({
  sections,
  shouldBlurActors,
  parcelAlias,
  showEditParcel,
  isLoading,
  isError,
  disabled,
}) => {
  return (
    <DisableContainer className="parcel-details-summary" disabled={disabled}>
      <Card
        title={
          <Flex gap="_XS">
            <ParcelAlias
              text={
                <CopyableText
                  text={`Parcel ${parcelAlias ?? 'details'}`}
                  copyText={parcelAlias}
                />
              }
            />
          </Flex>
        }
        columns={columns}
        icon={
          <Flex align={'center'} gap="_2XS">
            {parcelAlias && (
              <AnimatedIcon
                onClick={showEditParcel}
                name={Icons.Edit3}
                theme="dashboard_icon"
                size={Size.Icon._L}
              />
            )}
            <InfoIcon description={infoDescription[components.parcelDetails]} />
          </Flex>
        }
      >
        {isError ? (
          <Content>
            <ErrorDisplay />
          </Content>
        ) : isLoading ? (
          <Content>
            <CardLoader />
          </Content>
        ) : (
          <>
            <ParcelDetails sections={sections} />
            {shouldBlurActors && <Blur blurColumns={blurColumns} />}
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </DisableContainer>
  );
};

export const ProductTickets = ({
  tickets,
  handleTicketClick: onTicketClick,
}) => {
  return (
    <Card
      className="product-tickets"
      title={componentTitle[components.productTickets]}
    >
      <ProductTicketsScroll>
        <ProductTicketsContainer
          tickets={tickets}
          onTicketClick={onTicketClick}
        />
      </ProductTicketsScroll>
    </Card>
  );
};

export const AuthorityOptionsSelect = ({ options, selectOption }) => {
  return (
    <Flex w column gap="_XS">
      <InputTitle text={'Select an authority'} />
      <Input
        name={'dropdown'}
        size="_M"
        hasError
        type="select"
        options={options}
        selectOption={selectOption}
      />
    </Flex>
  );
};

export const AuthorityEditFields = () => {
  return (
    <Flex w column gap="_XS">
      {Object.values(authorityFields).map(name => {
        return (
          <Flex w column gap="_XS">
            <InputTitle text={authorityTitles[name]} />
            <Input name={name} key={name} size="_M" readOnly />
          </Flex>
        );
      })}
    </Flex>
  );
};

export const ProductDetailsEditFields = ({
  options = {},
  selectOption,
  disabled,
}) => {
  return (
    <Flex w column gap="_">
      {Object.keys(productFields).map(name => {
        return (
          <Flex w column gap="_XS">
            <InputTitle text={productTitles[name]} />
            <Input
              name={name}
              key={name}
              size="_M"
              hasError
              {...productProps[name]}
              options={options[name]}
              selectOption={selectOption}
              disabled={disabled[name]}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};
