const { default: usePostData } = require('../../../../../hooks/postHook');

export const useUpdateAuthority = ({ id }) => {
  const [{ body, isLoading, isError, error }, doAuthorityUpdate] = usePostData(
    `office_parcel/${id}/authority`,
    {
      type: 'put',
    }
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doAuthorityUpdate,
  };
};
