import styled from '@emotion/styled';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors } from '../../style';

export const Container = styled.div`
  overflow: hidden;
  background: ${Colors.Sharp.White_050};
`;

export const SlidesContainer = styled(Flex)``;

export const Slide = styled.div`
  flex: 0 0 100%;
  min-width: 0;
  background: ${Colors.Sharp.White_050};
`;
