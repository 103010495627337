import { ViewLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import AuthenticationGallery from '../controllers/AuthenticationGallery';
import { noResultsState } from '../variables';
import {
  AuthenticationAchievement,
  ProductsList,
  RecentActivity,
} from './organisms';
import { Container, Major, Minor } from './style';

const AuthenticationLayout = ({
  hasDoneFirstLoad,
  productListTabsProps,
  productListContentProps,
  authenticationAchievementProps,
  recentActivityProps,
  authenticationGalleryProps,
}) => {
  return (
    <>
      {!hasDoneFirstLoad && recentActivityProps.isLoading ? (
        <ViewLoader />
      ) : recentActivityProps.activity?.length === 0 ? (
        <NoSearchResults {...noResultsState.noData} />
      ) : (
        <Container>
          <Major>
            <ProductsList
              productListTabsProps={productListTabsProps}
              productListContentProps={productListContentProps}
            />
          </Major>
          <Minor>
            <AuthenticationAchievement {...authenticationAchievementProps} />
            <RecentActivity recentActivityProps={recentActivityProps} />
          </Minor>
        </Container>
      )}
      <AuthenticationGallery {...authenticationGalleryProps} />
    </>
  );
};
export default AuthenticationLayout;
