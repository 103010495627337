import { Icon, Text } from '../../../../../../components/atoms';
import Button from '../../../../../../components/molecules/Button/Button';
import { Colors, FontKeys } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import { InstructionIconCSS } from '../style';

export const AddInstructionButton = ({ onClick }) => (
  <Button
    text="Add knowledge base"
    variant="Primary"
    size="_S"
    onClick={onClick}
  />
);

export const DetailsSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const DetailsSectionSubttle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const DetailsText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      ellipsis
    />
  );
};

export const Cancel = ({ onCancel }) => (
  <Button onClick={onCancel} variant="Secondary" text="Cancel" size="_M" />
);

export const PrimaryAction = ({ onClick, text, disabled }) => (
  <Button
    onClick={onClick}
    variant="Primary"
    text={text}
    size="_M"
    disabled={disabled}
    type="submit"
  />
);

export const AddCharacteristicsText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Button}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const InstructionIcon = ({ name }) => (
  <InstructionIconCSS>
    <Icon name={name} />
  </InstructionIconCSS>
);

export const InstructionTypeTitle = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._L}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Primary._600}
  />
);

export const InstructionTypeDescription = ({ text }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._S}
    weight={FontKeys.Weight.Regular}
  />
);
