import paperAirplane from '../../../assets/img/paper_airplane.png';
import { Icon, Text } from '../../../components/atoms';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { Colors, FontKeys, Size } from '../../../components/style';
import {
  BrandsCountCSS,
  FavoriteIconCSS,
  SidebarBrandsCountCSS,
  styles,
} from '../style';

export const PaperAirplane = () => {
  return (
    <img src={paperAirplane} style={styles.passwordRequestSuccess.image} />
  );
};

export const TitleText = ({ text }) => {
  return (
    <Text
      variant="Heading"
      size="H4"
      weight={FontKeys.Weight.Semibold}
      alignment="center"
      text={text}
      color={Colors.Primary._600}
    />
  );
};

export const CardText = ({ text }) => {
  return (
    <Text
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
      text={text}
    />
  );
};

export const BrandCatalogSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const Placeholder = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._S}
    weight={FontKeys.Weight.Regular}
    color={Colors.Neutral._400}
  />
);

export const BrandName = ({ text, selected }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={selected ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={selected ? Colors.Primary._600 : undefined}
    />
  );
};

export const FavoriteIcon = ({ favorite, onClick }) => {
  return (
    <FavoriteIconCSS onClick={onClick}>
      <Icon
        name={Icons.Star}
        color={favorite ? Colors.Primary._600 : Colors.Neutral._400}
        fill={favorite ? Colors.Primary._600 : 'transparent'}
        size={Size.Icon._XS}
      />
    </FavoriteIconCSS>
  );
};

export const SidebarBrandCount = ({ count }) => {
  return (
    <SidebarBrandsCountCSS>
      <Text
        text={count}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Semibold}
        color={Colors.Primary._700}
      />
    </SidebarBrandsCountCSS>
  );
};

export const BrandsCount = ({ count }) => {
  return (
    <BrandsCountCSS>
      <Text
        text={count}
        variant={FontKeys.Variant.Caption}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
      />
    </BrandsCountCSS>
  );
};
