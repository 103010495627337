import { Icon } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { StatusIndicator } from '../../../../../../components/atoms/StatusIndicator/StatusIndicator';
import Button from '../../../../../../components/molecules/Button/Button';
import FloatingMenu from '../../../../../../components/molecules/FloatingMenu/FloatingMenu';
import {
  ExpansionArrow,
  JoinedStrings,
  QuantityBox,
  RowDateHorizontal,
  RowHowLongAgo,
  RowSimpleText,
  RowSingleImage,
  RowStatusShares,
  SimpleStatus,
  Space,
  User,
  runIfHasProducts,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { productKeys } from '../../../products/controllers/variables';
import { caseKeys } from '../../controllers/variables';

const options = [
  {
    label: 'Contact support',
    value: 'contact support',
  },
];

const OptionsMenu = onSupportClick => item => {
  const showSupport = item => () => onSupportClick(item);

  return (
    <FloatingMenu
      options={options}
      optionClick={showSupport(item)}
      optionDisplay={({ label }) => (
        <Button
          variant="Tertiary"
          size="_S"
          text={label}
          black
          // cursor pointer
          onClick={Utils.emptyFunction}
        />
      )}
    >
      <Icon
        name={Icons.MoreVertical}
        color={Colors.Neutral._500}
        size={Size.Icon._M}
      />
    </FloatingMenu>
  );
};

export const tableComponents = ({
  expansionHeaders,
  expansionGrid,
  expansionRowAction,
  maxWidth,
  CaseProducts = Utils.emptyFunction,
  onSupportClick,
  hasProductPermission,
  hasSupportPermission,
  expandCountKey,
  showGallery,
}) => ({
  [caseKeys.statusIndicator]: {
    display: StatusIndicator(caseKeys.status),
  },
  [caseKeys.expansionArrow]: {
    display: hasProductPermission
      ? ExpansionArrow({ countKey: expandCountKey })
      : Utils.emptyFunction,
    action: runIfHasProducts({
      action: Utils.expandRow,
      countKey: caseKeys.products,
    }),
  },
  [caseKeys.image]: {
    display: RowSingleImage(caseKeys.photos, { showGallery })('_XS'),
  },
  [caseKeys.alias]: {
    display: RowSimpleText(caseKeys.alias, 'bold', true, { copyable: true }),
  },
  [caseKeys.productQuantity]: {
    display: QuantityBox({ key: caseKeys.productQuantity, size: '_M' }),
  },
  [caseKeys.sideloadDate]: {
    display: RowDateHorizontal(caseKeys.sideloadDate),
  },
  [caseKeys.sorter]: { display: RowSimpleText(caseKeys.sorter, 'bold', null) },
  [caseKeys.trackingNumber]: {
    display: RowSimpleText(caseKeys.trackingNumber, null, null),
  },
  [caseKeys.riskType]: {
    display: RowSimpleText(caseKeys.riskType, 'bold', null),
  },
  [caseKeys.itemQuantity]: {
    display: JoinedStrings(caseKeys.ownQuantity, caseKeys.totalQuantity, '/'),
  },
  [caseKeys.statusShares]: {
    display: RowStatusShares(caseKeys.statusShares, maxWidth),
  },
  [caseKeys.status]: { display: SimpleStatus(caseKeys.status) },
  // [caseKeys.lastActivity]: { display: RowHowLongAgo(caseKeys.lastActivity) },
  [caseKeys.productQuantity]: {
    display: RowSimpleText(caseKeys.productQuantity, 'bold', null),
  },
  [caseKeys.optionsMenu]: hasSupportPermission
    ? { display: OptionsMenu(onSupportClick), action: Utils.avoidRowExpand }
    : { display: () => <></> },
  expand: CaseProducts({
    headers: expansionHeaders,
    grid: expansionGrid,
    rowAction: expansionRowAction,
  }),
});

export const caseProductsTableComponents = disabled => ({
  [productKeys.leftPadding]: { display: Space },
  [productKeys.productImage]: {
    display: RowSingleImage(productKeys.photos, { disabled })('_S'),
  },
  [productKeys.productAlias]: {
    display: RowSimpleText([productKeys.productAlias], 'bold', null, {
      disabled,
      copyable: true,
    }),
  },
  [productKeys.brand]: {
    display: User(productKeys.brand, productKeys.brandImage, {
      bold: true,
      disabled,
    }),
  },
  [productKeys.productName]: {
    display: JoinedStrings(
      productKeys.subCategory,
      productKeys.category,
      undefined,
      { disabled }
    ),
  },
  [productKeys.productQuantity]: {
    display: RowSimpleText(productKeys.itemQuantity, 'bold', null, {
      disabled,
    }),
  },
  [productKeys.productStatus]: {
    display: SimpleStatus(productKeys.productStatus, '_S', { disabled }),
  },
  [productKeys.lastActivity]: { display: RowHowLongAgo(caseKeys.lastActivity) },
});
