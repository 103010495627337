import usePostData from '../../../../../hooks/postHook';
import { maskEntry } from '../../../../../utils/utils';
import { processingKeys } from '../controllers/variables';

export const useCheckInParcel = () => {
  const [{ data, body, isLoading, isError, error }, doCheckin] = usePostData(
    'office_parcels/checkin',
    { formatter }
  );

  return { data, body, isLoading, isError, error, doCheckin };
};

const formatter = data => {
  data = maskEntry(data, mask);
  data[processingKeys.image] = data[processingKeys.photos]?.[0]?.ppThumb;
  try {
    data.situation[processingKeys.situation.headerButtons] = JSON.parse(
      data.situation.psButtons
    );
  } catch {
    data.situation[processingKeys.situation.headerButtons] = [];
  }
  try {
    data.situation[processingKeys.situation.descriptionButtons] = JSON.parse(
      data.situation.psConfirmButtons
    );
  } catch {
    data.situation[processingKeys.situation.descriptionButtons] = [];
  }

  return { ...data, ...data.situation };
};

const mask = {
  paID: processingKeys.id,
  paAlias: processingKeys.alias,
  paRiskType: processingKeys.riskType,
  paSideloadDate: processingKeys.sideloadDate,
  photos: processingKeys.photos,
  psCode: processingKeys.situationCode,
  psTitle: processingKeys.situation.title,
  psInstructions: processingKeys.situation.description,
  psStyle: processingKeys.situation.styleStatus,
  barcode: processingKeys.barcode,
};
