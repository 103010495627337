// This file only places local and global components, and connects dynamic data
// like event handlers or state. No styles are applied here.

import { GoogleLogin } from '@react-oauth/google';
import { Icons } from '../../components/atoms/Icon/Icon.options';
import { PasswordInput } from '../../components/molecules/PasswordInput/PasswordInput';
import { EmailInput, SectionSeparator } from './molecules/Molecules';

import { useEffect, useState } from 'react';
import { InfoCard } from './organisms/Organisms';

const LoginLayout = ({
  title,
  emailPlaceholder,
  emailName,
  passwordPlaceholder,
  passwordName,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  validationSchema,
  initialValues,
  onSubmit,
  handleGoogleLogin,
  isLoading,
  onSSOLoginClick,
  ssoLoginButtonText,
}) => {
  const [inputsWidth, setInputsWidth] = useState(0);

  useEffect(() => {
    const input = document.querySelector('.input');
    const width = input.offsetWidth;
    setInputsWidth(width);
  }, []);

  return (
    <>
      <InfoCard
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        title={title}
        minorButtonClick={minorButtonClick}
        minorButtonText={minorButtonText}
        mainButtonText={mainButtonText}
        isLoading={isLoading}
      >
        {/* <Button
          variant="Secondary"
          size="_S"
          isLoading={isLoading}
          fullWidth={true}
          leftIcon={Icons.Shield}
          rightIcon={Icons.ChevronRight}
          text={ssoLoginButtonText}
          onClick={onSSOLoginClick}
          // leftIcon={minorLeftIcon}
          // rightIcon={mainRightIcon}
        />
        <SectionSeparator text="OR" /> */}
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          onError={handleGoogleLogin}
          width={inputsWidth}
          text={'continue_with'}
          logo_alignment={'center'}
        />
        <SectionSeparator text="OR" />
        <EmailInput placeholder={emailPlaceholder} name={emailName} />
        <PasswordInput
          placeholder={passwordPlaceholder}
          name={passwordName}
          leftIcon={Icons.Lock}
        />
      </InfoCard>
    </>
  );
};

export default LoginLayout;
