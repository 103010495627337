import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import Button from '../../molecules/Button/Button';
import { Colors, FontKeys } from '../../style';

const size = '_L';

export const ViewTitle = ({
  text,
  backPage = '/',
  noNav,
  ellipsis = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateBack = () => {
    const { searchParams, from } = location.state ?? {};
    const hasPreviousState =
      location.key !== 'default' && !location.state?.navedFromLink;

    const to = from ? from : hasPreviousState ? -1 : backPage;
    //  we don't mapToLightweightState(searchParams) because we're on a title page
    // searchParams that are passed to the title page are already lightweight
    const state = { searchParams };
    navigate(to, { state });
  };

  return noNav ? (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
      ellipsis={ellipsis}
      whiteSpace="nowrap"
      className="view-title"
    />
  ) : (
    <Button
      leftIcon={Icons.ChevronLeft}
      onClick={navigateBack}
      variant="Tertiary"
      text={text}
      size={size}
      className="view-title"
    />
  );
};
