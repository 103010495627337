import { createContext, useContext, useEffect, useState } from 'react';
import { protectedRoutes } from '../routes/protected';
import {
  PermissionConsts,
  usePermissions,
} from './PermissionProvider/PermissionsProvider';

const PlatformContext = createContext();

export function usePlatform() {
  return useContext(PlatformContext);
}

const PlatformProvider = ({ children }) => {
  const [platformRoutes, setPlatformRoutes] = useState([]);
  const [isRoutesSet, setIsRoutesSet] = useState(false);

  const { isPermissionSet, hasPermission } = usePermissions();

  useEffect(() => {
    setPlatformRoutes(
      excludeUnauthorizedRoutes(protectedRoutes, hasPermission)
    );
    setIsRoutesSet(isPermissionSet);
  }, [isPermissionSet]);

  return (
    <PlatformContext.Provider value={{ platformRoutes, isRoutesSet }}>
      {children}
    </PlatformContext.Provider>
  );
};

export default PlatformProvider;

// TODO: use only hasPermission
const permitted = (children, hasPermission) =>
  children.filter(({ service, permission }) => {
    return (
      // check for general access and for details access if any
      hasPermission(service, PermissionConsts.Show) &&
      hasPermission(service, permission ?? PermissionConsts.Show)
    );
  });

const excludeUnauthorizedRoutes = ({ children, layout }, hasPermission) => {
  return [
    {
      ...layout,
      children: permitted(children, hasPermission),
    },
  ];
};
