import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { brandManagementKeys } from '../controllers/variables';

export const useGetBrand = ({ id, ignore }) => {
  const [
    { data = [], isLoading = true, isError, error },
    _,
    getInstruction,
    refresh,
  ] = useFetchData(`admin_brand/${id}`, {
    formatter,
    ignore,
  });

  return { data, isLoading, isError, error, getInstruction, refresh };
};

export const formatter = data => {
  maskEntry(data, mask);

  return data;
};

const mask = {
  bgName: brandManagementKeys.brandGroup.name,
  brAlias: brandManagementKeys.brand.alias,
  brID: brandManagementKeys.brand.id,
  brImage: brandManagementKeys.brand.image,
  brIndustry: brandManagementKeys.industry,
  brIsContracted: brandManagementKeys.brand.isContracted,
  brName: brandManagementKeys.brand.name,
  has_sorters: brandManagementKeys.hasSorters,
  plan_end_date: brandManagementKeys.plan.endDate,
  plan_name: brandManagementKeys.plan.name,
  rep_email: brandManagementKeys.representative.email,
  rep_image: brandManagementKeys.representative.image,
  rep_name: brandManagementKeys.representative.name,
  has_sso_idp: brandManagementKeys.hasSSOIdP,
  // counts
  client_count: brandManagementKeys.user.count,
  contract_count: brandManagementKeys.contract.count,
  invoice_count: brandManagementKeys.invoice.count,
  plan_count: brandManagementKeys.plan.count,
  service_count: brandManagementKeys.service.count,
};
