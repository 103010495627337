import { dashboardKeys } from '../variables';
import { formatOverviewCard } from './formatOverview';

export const formatParcels =
  ({ hasPermission }) =>
  data => {
    const overviewParcelStats = formatOverviewCard({
      type: dashboardKeys.parcelCount,
      hasPermission,
    })(data, { tooltipSide: 'bottom' });

    return {
      overviewParcelStats,
      parcelCounts: data?.parcel_ids ?? {},
    };
  };
