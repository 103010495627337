import { getAlias } from '../../utils/utils';

const formatter = ([{ photos }]) => {
  const images = photos.map(imageObject => imageObject.ppImage);
  const thumbnails = photos.map(imageObject => imageObject.ppThumb);

  return { images, thumbnails };
};

const knowledgeBaseRulesFormatter = photos => {
  const images = photos.map(imageObject => imageObject.ppImage);
  const thumbnails = photos.map(imageObject => imageObject.ppThumb);
  return { images, thumbnails };
};

export const GalleryPages = {
  knowledge_base: 'knowledge_base',
  products: 'products',
};

export const GalleryProps = {
  [GalleryPages.knowledge_base]: {
    url: `office_knowledge_base/facilitator_guidelines`,
    idsKey: 'unnecessary',
    galleryAliasExtractor: getAlias,
    formatter: knowledgeBaseRulesFormatter,
  },
  [GalleryPages.products]: {
    url: 'cases/images',
    idsKey: 'case_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
};
