import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { SetupChoiceLayout } from '../pages/SetupChoiceLayout';
import ManualInstruction from './ManualInstruction';
import { setupChoice } from './variables';
import WizardDetails from './WizardDetails';

const SetupChoice = ({ categories, appendNewRule }) => {
  const { dismissAll, showModal } = useModal();

  const showWizard = () =>
    showModal(WizardDetails, { categories }, { stack: true });

  const showManual = () =>
    showModal(
      ManualInstruction,
      { categories, appendNewRule },
      { stack: true, ignoreOverlayDismiss: true }
    );

  const wizardSetup = {
    ...setupChoice.wizard,
    onClick: showWizard,
  };

  const manualSetup = {
    ...setupChoice.manual,
    onClick: showManual,
  };

  return (
    <SetupChoiceLayout
      onCancel={() => dismissAll()}
      wizardSetup={wizardSetup}
      manualSetup={manualSetup}
    />
  );
};
export default Modal(SetupChoice);
