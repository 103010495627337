import { Size } from '../../style';
import { FilterTitle } from '../FilterTitle/FilterTitle';
import { Toggle } from '../Toggle/Toggle';
import { Container } from './ToggleFilter.style';

export const ToggleFilter = ({
  title,
  icon,
  active,
  toggleActive,
  fullWidth,
  disabled,
}) => {
  return (
    <Container
      onClick={toggleActive}
      fullWidth={fullWidth}
      disabled={disabled}
      active={active}
      isFilterApplied={active}
      someOptionSelected
    >
      <FilterTitle title={title} iconStart={icon} iconSize={Size.Icon._S} />
      <Toggle size="_XS" active={active} />
    </Container>
  );
};
