import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects, FilterContainer, Layout } from '../../style';

export const Container = styled(FilterContainer)``;

export const FakeTopPadding = styled.div`
  margin-right: 8px;
`;

export const FakeBottomPadding = styled.div`
  min-height: ${Layout.Spacing._2XS};
`;

export const OptionsSearch = styled.div`
  .input {
    padding: ${Layout.Spacing._2XS};
    background: ${Colors.Neutral._100};
    margin-bottom: ${Layout.Spacing._2XS};
    gap: ${Layout.Spacing._2XS};
    input::placeholder {
      color: ${Colors.Neutral._500};
    }
  }
`;

export const SelectedCount = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 10px;
  font-weight: 500;
  color: ${Colors.Sharp.White};
  background: ${Colors.Primary._600};
  border-radius: ${Effects.Border_Radius.Circle};
  ${Styles.flexCenter};
  display: ${({ isFilterApplied, someOptionSelected }) =>
    isFilterApplied && someOptionSelected ? 'flex' : 'none'};
`;
