export const magnitudeFormatter = (numb, domainMax, decimals = 1) => {
  const number = numb ?? domainMax;
  // DRY here LineChart>utils>countFormatter
  if (number == null || number == 0) return number;
  if (number < 2 && String(number).includes('.')) return formatDecimal(number);
  return formatInteger(number, decimals);
};

const formatInteger = (number, decimals) => {
  let power = String(Math.floor(number)).length;

  const units = ['', 'k', 'm', 'b', 't'];

  const unitIdx = Math.ceil(power / 3) - 1;

  const divisor = Math.pow(10, unitIdx * 3);

  const numbersSplit = String(parseFloat(number / divisor)).split('.');

  if (numbersSplit[1]) {
    numbersSplit[1] = numbersSplit[1].slice(0, decimals);
    if (numbersSplit[1][1] == '0')
      numbersSplit[1] = numbersSplit[1].slice(0, 1);
  }
  return numbersSplit.join('.') + units[unitIdx];
};

const formatDecimal = number => {
  number = String(number);
  if (number.length === 1) return number;
  if (number > 1) return number.slice(0, 3);

  number = number.replace('0.', '');

  let power = findFirstNonZeroDigitIndex(number) + 1;

  // remove all leading 0s of the number
  number = number.replace(/^0+/, '');

  // remove all trailing 0s of the number
  number = number.replace(/0+$/, '');
  const hasMoreSignificantDigits = number.length > 1;
  if (power == 2) return '0.0' + number.slice(0, 1).replace(/0+$/, '');
  if (power == 1) return '0.' + number.slice(0, 2).replace(/0+$/, '');
  if (!hasMoreSignificantDigits) return number + 'e-' + power;
  return number[0] + '.' + number[1] + 'e-' + power;
};

function findFirstNonZeroDigitIndex(str) {
  // Match the first non-zero digit
  const match = str.match(/[1-9]/);
  if (match) {
    return str.indexOf(match[0]);
  }
  return -1;
}

export const BaselineAlignText = ({ children }) => <span>{children}</span>;

export const joinStrings = (
  first,
  second,
  separator = ', ',
  { boldFirst = false } = {}
) => {
  const hasFirst = first != null && first !== '';
  const hasSecond = second != null && second !== '';
  if (hasFirst && boldFirst) first = `**${first}**`;
  if (hasFirst && hasSecond) return `${first}${separator}${second}`;
  if (hasFirst || hasSecond) return `${first ?? ''}${second ?? ''}`;
};

// prettier-ignore
export const alphabetChars = [ 'A',  'B',  'C',  'D',  'E',  'F',  'G',  'H',  'I',  'J',  'K',  'L',  'M',  'N',  'O',  'P',  'Q',  'R',  'S',  'T',  'U',  'V',  'W',  'X',  'Y',  'Z', ];

export const sanitizeStrings = obj => {
  // sanitize strings in an object and return a new object
  if (typeof obj === 'string') {
    return obj.trim();
  } else if (Array.isArray(obj)) {
    return obj.map(item => sanitizeStrings(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};

    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'string') {
        newObj[key] = obj[key].trim();
      } else if (Array.isArray(obj[key])) {
        newObj[key] = obj[key].map(item => sanitizeStrings(item));
      } else if (typeof obj[key] === 'object') {
        newObj[key] = sanitizeStrings(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    });

    return newObj;
  } else {
    return obj;
  }
};
