import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { cloneDeep } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useAddCharacteristic } from '../api/addCharacteristic';
import { useAddCharacteristicItem } from '../api/addCharacteristicItem';
import { useCreateInstruction } from '../api/createInstruction';
import { CharacteristicUploadLayout } from '../pages/CharacteristicUploadLayout';
import { formatCreateValues } from './utils';
import {
  authenticityBadgeColors,
  authenticityBadgeIcons,
  authenticityBadgeLabels,
  authenticityBadgeMenuOptions,
  authenticityBadgeValues,
  getCharacteristicInitialValues,
  getCharacteristicValidationSchema,
  knowledgeBaseKeys,
} from './variables';

const CharactersticUpload = ({
  instructionId,
  characteristicType,
  onSuccess,
  fieldValues,
}) => {
  const [handleImport, setHandleImport] = useState(null);
  const [types, setTypes] = useState([
    authenticityBadgeValues['non-counterfeit'],
  ]);
  const [details, setDetails] = useState(['']);
  const [missingFiles, setMissingFiles] = useState(true);
  const [filesUploading, setFilesUploading] = useState(false);
  const [somethingIsLoading, setSomethingIsLoading] = useState(false);

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const {
    data: instructionData,
    body: instructionBody,
    isLoading: instructionIsLoading,
    isError: instructionIsError,
    error: instructionError,
    doCreateInstruction,
  } = useCreateInstruction();

  const { data, body, isLoading, isError, error, doAddCharacteristic } =
    useAddCharacteristic();

  const {
    data: itemData,
    body: itemBody,
    isLoading: itemIsLoading,
    isError: itemIsError,
    error: itemError,
    doAddCharacteristicItem,
  } = useAddCharacteristicItem({});

  useEffect(() => {
    if (instructionIsLoading || instructionData == null) return;
    if (instructionIsError) {
      showSnackbarError(instructionError);
      return;
    }

    const instructionId = instructionData;
    addCharacteristic(instructionId);
  }, [instructionData, instructionIsLoading, instructionIsError]);

  useEffect(() => {
    if (isLoading || data == null) return;
    if (isError) {
      showSnackbarError(error);
    }
    const characteristicId = data;
    const { instructionId } = body;
    handleImport({
      // no url because this method does not come from FileUploadProvider
      folder: `instruction_files`,
      successCallback: addCharacteristicItems(
        characteristicId,
        instructionId,
        types,
        details
      ),
      errorCallback: () =>
        showSnackbarError('Error uploading files. Please try again.'),
    });
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (itemIsLoading || itemBody == null) return;
    if (itemIsError) {
      showSnackbarError(itemError);
      // TODO: save the created characteristic id and make it try again
      // current behavior: a new charactersitic is created, and the old characteristic is left floating with no items
      return;
    }

    const { items, instructionId } = itemBody;
    const characteristic = {
      // TODO: can this be formatted with getInstruction.js' formatter?
      items: items.map(item => ({
        authenticity: {
          value: item.type,
          label: authenticityBadgeLabels[item.type],
        },
        [knowledgeBaseKeys.imageUrl]: item[knowledgeBaseKeys.imageUrl],
        [knowledgeBaseKeys.characteristicDescription]: item.detail,
      })),
      [knowledgeBaseKeys.characteristicId]:
        itemBody[knowledgeBaseKeys.characteristicId],
      [knowledgeBaseKeys.characteristicType]: characteristicType.value,
      instructionId,
    };

    if (typeof onSuccess === 'function') onSuccess(characteristic);
    showSnackbarSuccess('Characteristic added');
    dismissAll();
  }, [itemData, itemIsLoading, itemIsError]);

  useEffect(() => {
    const somethingIsLoading = isLoading || itemIsLoading || filesUploading;

    setSomethingIsLoading(somethingIsLoading);
  }, [isLoading, itemIsLoading, filesUploading]);

  const assignHandleImport = childFunction => setHandleImport(childFunction);
  const handleSave = () => {
    if (instructionId) addCharacteristic(instructionId);
    else doCreateInstruction(formatCreateValues(fieldValues));
  };

  const addCharacteristic = instructionId =>
    doAddCharacteristic(
      { type: characteristicType.value, instructionId },
      `office_knowledge_base/${instructionId}/characteristics`
    );

  const addCharacteristicItems =
    (characteristicId, instructionId, types, details) => files => {
      const items = files.map((file, index) => ({
        [knowledgeBaseKeys.imageUrl]: URL.createObjectURL(file.file),
        [knowledgeBaseKeys.characteristicId]: characteristicId,
        image: file.uploadName,
        type: types[index],
        detail: details[index],
      }));
      const body = {
        items,
        [knowledgeBaseKeys.characteristicId]: characteristicId,
        instructionId,
      };
      const url = `office_knowledge_base/${instructionId}/characteristic/${characteristicId}/items`;
      doAddCharacteristicItem(body, url);
    };

  const onDetailChange = index => e => {
    const { value } = e.target;
    setDetails(x => {
      x.splice(index, 1, value);
      return x;
    });
  };

  const onRemoveItem = index => {
    const update = x => {
      x.splice(index, 1);
      return x;
    };
    setTypes(update);
    setDetails(update);
  };

  const updateItemInfoAmounts = ({ files }) => {
    // TODO: update() and types() can be in utils.js file
    const update = x => {
      const difference = files.length - x.length;
      if (difference > 0) [...Array(difference)].forEach((_, i) => x.push(''));
      if (difference < 0) x.splice(x.length - 1 - difference);
      return x;
    };

    const types = x =>
      x.map(x => (x === '' ? authenticityBadgeValues['non-counterfeit'] : x));

    setTypes(x => types(update(x)));
    setDetails(update);
  };

  const checkMissingFiles = ({ files }) =>
    setMissingFiles(files.some(file => file == null));

  const updateFilesLoading = ({ isUploading }) =>
    setFilesUploading(isUploading);

  const onFileStateUpdate = fileState => {
    updateItemInfoAmounts(fileState);
    checkMissingFiles(fileState);
    updateFilesLoading(fileState);
  };

  const selectAuthenticity = index => option => () => {
    const update = x => {
      // prevX !== newX so it triggers useState re-render
      x = cloneDeep(x);
      x[index] = option.value;
      return x;
    };
    setTypes(update);
  };

  const getSelectedOption = index =>
    authenticityBadgeMenuOptions.find(x => x.value === types[index]) ??
    authenticityBadgeMenuOptions[0];

  return (
    <Formik
      initialValues={getCharacteristicInitialValues(details)}
      validationSchema={getCharacteristicValidationSchema(details)}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form>
            <CharacteristicUploadLayout
              onPrimary={handleSave}
              assignHandleImport={assignHandleImport}
              onRemoveItem={onRemoveItem}
              onFileStateUpdate={onFileStateUpdate}
              onDetailChange={onDetailChange}
              disabled={!isValid || missingFiles || somethingIsLoading}
              isLoading={somethingIsLoading}
              filesUploading={filesUploading}
              // Authenticity dropdown
              authenticityBadgeMenuOptions={authenticityBadgeMenuOptions}
              authenticityBadgeColors={authenticityBadgeColors}
              authenticityBadgeIcons={authenticityBadgeIcons}
              selectOption={selectAuthenticity}
              getSelectedOption={getSelectedOption}
              characteristicType={characteristicType.label}
              onCancel={() => dismissAll()}
              onBack={dismiss}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(CharactersticUpload);
