import usePostData from '../../../../../hooks/postHook';

export const useUpdateProductStatusBulk = () => {
  const [{ body, isLoading, isError, error }, doUpdate] = usePostData(
    'office_cases/status'
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdate,
  };
};
