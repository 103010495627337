import { useState } from 'react';
import { Icon, Text } from '../../atoms';
import { Container, Recipe } from './Avatar.style';
import { getTextAndBackground } from './Avatar.utils';

const Avatar = ({
  image,
  icon,
  username,
  background: parentBG,
  count,
  size,
  variant = 'Default',
  text: customText,
  border,
  ...props
}) => {
  if (!size) throw Error('Missing size property');
  const [opacity, setOpacity] = useState(0);

  const { text, background } = getTextAndBackground({
    username,
    count,
    customText,
  });

  const style = Recipe(variant)({
    size,
    background,
    parentBG,
    opacity,
  });

  const setImageOpacity = to => () => setOpacity(to);

  return (
    <Container
      style={style}
      className={`avatar ${props.className || ''}`}
      border={border}
    >
      <Text text={opacity ? '' : text} {...style.text} />
      <Icon {...icon} />
      <img
        src={image || ''}
        onError={setImageOpacity(0)}
        onLoad={setImageOpacity(1)}
        style={style.image}
        alt=""
      />
    </Container>
  );
};

export default Avatar;
