import { PlaceholdersTypes } from '../../../constants';
import { isArray } from '../../../lib/js';
import {
  handleImageError,
  handleImageLoad,
  setImagePlaceholder,
} from '../../../utils/utils';
import { Recipe } from './BrandLogo.style';
import { Quadrant } from './components';

export const BrandLogo = ({
  variant,
  src,
  height,
  transition,
  onClick,
  scrolled,
  border,
  selected,
}) => {
  const style = Recipe(variant)({
    height,
    transition,
    src,
    scrolled,
    border,
    selected,
  });

  const isArrayOfImages = isArray(style.src);
  const isMultipleImages = isArrayOfImages && style.src.length > 1;

  return isArrayOfImages && isMultipleImages ? (
    <Quadrant images={style.src} style={style} />
  ) : (
    <img
      src={isArrayOfImages ? style.src[0] : style.src}
      style={style}
      onClick={onClick}
      alt=""
      onError={handleImageError(
        setImagePlaceholder('_M', PlaceholdersTypes.company)
      )}
      onLoad={handleImageLoad()}
    />
  );
};
