import { Route, Routes } from 'react-router-dom';
    import AdminServices from './AdminServices';
    
    export const AdminServicesRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<AdminServices />} />
        </Routes>
      );
    };
    