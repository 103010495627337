import { tableComponents } from '../molecules';
import { AdminReferencesTableCSS } from '../style';

export const AdminReferencesTable = ({ headers, data, rowAction, grid }) => {
  return (
    <AdminReferencesTableCSS
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      components={tableComponents}
    />
  );
};
