export const Pages = {
  processing: 'processing',
};

const { processing } = Pages;

export const Labels = {
  [processing]: 'Parcel Processing',
};

export const URIs = {
  [processing]: 'processing',
};

export const NavURIs = {
  [processing]: `/${URIs[processing]}`,
};
