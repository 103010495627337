import { useEffect, useRef } from 'react';
import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { StatusIndicator } from '../../../../../../components/atoms/StatusIndicator/StatusIndicator';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  DotCompleted,
  DotStatus,
  ExpansionArrow,
  JoinedStrings,
  RowDateHorizontal,
  RowHowLongAgo,
  RowMultipleImages,
  RowSimpleText,
  RowStatusShares,
  Selected,
  SimpleStatus,
  Space,
  TableContractedBrandName,
  TableHeader,
  runIfHasProducts,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Layout, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import {
  parcelKeys,
  tabs,
} from '../../../../features_public/parcels/controllers/variables';
import {
  CaseProductsCSS,
  CaseProductsTableHeaderCSS,
  CaseProductsTableHeaderContainer,
} from '../../style';
import { CaseProductsTableHeaderText } from '../atoms';

export const NavigateIcon = navigateToProduct => item => {
  return (
    <Icon
      name={Icons.ExternalLink}
      color={Colors.Neutral._500}
      onClick={navigateToProduct(item)}
      size={Size.Icon._M}
    />
  );
};

export const tableComponents = ({
  expansionHeaders,
  expansionGrid,
  expansionRowAction,
  CaseProducts = Utils.emptyFunction,
  hasProductViewPermission,
  hasStatusEditPermission,
  navigateToProduct,
  showGallery,
  showProductGallery,
  toggleRowChecked,
  checkedRows,
  openParcelSummary,
  expandCountKey,
}) => ({
  [parcelKeys.indicator]: { display: StatusIndicator(parcelKeys.status) },
  [parcelKeys.expansionArrow]: hasProductViewPermission
    ? {
        display: ExpansionArrow({
          countKey: expandCountKey,
        }),
        action: runIfHasProducts({
          action: Utils.expandRow,
          countKey: expandCountKey,
        }),
      }
    : {
        display: Utils.emptyFunction,
      },
  [parcelKeys.selected]: {
    display: hasStatusEditPermission
      ? Selected(toggleRowChecked, checkedRows)
      : Utils.emptyFragment,
    action: hasStatusEditPermission ? Utils.avoidRowExpand : Utils.trueReturn,
  },
  [parcelKeys.photos]: {
    display: RowMultipleImages({
      photosKey: parcelKeys.photos,
      size: '_S',
      amount: 3,
      showGallery,
      hoverZoom: true,
    }),
  },
  [parcelKeys.alias]: {
    display: RowSimpleText(parcelKeys.alias, 'bold', true, {
      copyable: true,
    }),
  },
  [parcelKeys.sideloadDate]: {
    display: RowDateHorizontal(parcelKeys.sideloadDate),
  },
  [parcelKeys.riskType]: { display: RowSimpleText(parcelKeys.riskType) },
  [parcelKeys.statusShares]: {
    display: RowStatusShares(parcelKeys.statusShares),
  },
  [parcelKeys.status]: { display: SimpleStatus(parcelKeys.status) },
  [parcelKeys.checked]: {
    display: DotCompleted(parcelKeys.checked),
    action: openParcelSummary(tabs.details),
  },
  [parcelKeys.nextStep]: {
    display: DotStatus(parcelKeys.nextStep),
    action: openParcelSummary(tabs.details),
  },
  [parcelKeys.origin]: {
    display: DotCompleted(parcelKeys.origin),
    action: openParcelSummary(tabs.origin),
  },
  [parcelKeys.destination]: {
    display: DotCompleted(parcelKeys.destination),
    action: openParcelSummary(tabs.destination),
  },
  [parcelKeys.product.count]: {
    display: hasProductViewPermission
      ? RowSimpleText(parcelKeys.product.count, 'bold')
      : Utils.emptyFragment,
  },
  [parcelKeys.product.navigate]: {
    display: hasProductViewPermission
      ? item =>
          item[parcelKeys.product.count]
            ? NavigateIcon(navigateToProduct)(item)
            : Utils.emptyFragment
      : Utils.emptyFragment,
    action: Utils.falseReturn,
  },
  expand: CaseProducts({
    headers: expansionHeaders,
    grid: expansionGrid,
    rowAction: expansionRowAction,
    showGallery: showProductGallery,
  }),
});

const contractedBrands = parcel =>
  parcel.filter(p => p[parcelKeys.product.isContracted]);

const nonContractedBrands = parcel =>
  parcel?.filter(p => !p[parcelKeys.product.isContracted]);

export const CaseProducts =
  ({ headers, grid, rowAction, showGallery }) =>
  item => {
    if (item == null) return <></>;
    const props = {
      headers,
      grid,
      rowAction,
      showGallery,
    };
    return (
      <Flex w h padding={`0 ${Layout.Spacing._S}`} column>
        <CaseProductsTable
          {...props}
          main
          title="CONTRACTED BRANDS"
          data={contractedBrands(item[parcelKeys.product.self])}
        />
        <CaseProductsTable
          {...props}
          title="NON-CONTRACTED BRANDS"
          data={nonContractedBrands(item[parcelKeys.product.self])}
        />
        <Spacer w height="_S" />
      </Flex>
    );
  };

export const caseProductsTableComponents = ({ showGallery }) => ({
  [parcelKeys.leftPadding]: { display: Space },
  [parcelKeys.product.photos]: {
    display: RowMultipleImages({
      photosKey: 'photos',
      size: '_S',
      amount: 4,
      showGallery,
    }),
  },
  [parcelKeys.product.alias]: {
    display: RowSimpleText(parcelKeys.product.alias, 'bold', false, {
      copyable: true,
    }),
  },
  [parcelKeys.product.brand]: {
    display: TableContractedBrandName(
      parcelKeys.product.brand,
      parcelKeys.product.isContracted
    ),
  },
  [parcelKeys.product.category]: {
    display: JoinedStrings(
      parcelKeys.product.subCategory,
      parcelKeys.product.category
    ),
  },
  [parcelKeys.product.itemQuantity]: {
    display: RowSimpleText(parcelKeys.product.itemQuantity, 'bold'),
  },
  [parcelKeys.product.status]: {
    display: SimpleStatus(parcelKeys.product.status),
  },
  [parcelKeys.product.lastActivity]: {
    display: RowHowLongAgo(parcelKeys.product.lastActivity, 'light'),
  },
});

const CaseProductsTableHeader = ({ main, title, grid, headers, headerRef }) => {
  return (
    <CaseProductsTableHeaderContainer ref={headerRef}>
      <CaseProductTitle main={main} title={title} />
      <TableHeader grid={grid} headers={headers} />
    </CaseProductsTableHeaderContainer>
  );
};

const CaseProductTitle = ({ main, title }) => (
  <CaseProductsTableHeaderCSS main={main}>
    <CaseProductsTableHeaderText text={title} main={main} />
  </CaseProductsTableHeaderCSS>
);

export const CaseProductsTable = ({
  headers,
  data,
  rowAction,
  grid,
  main,
  title,
  showGallery,
}) => {
  const headerRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current;
    const table = tableRef.current;
    if (header == null || table == null) return;
    header.addEventListener('scroll', syncScroll);
    table.addEventListener('scroll', syncScroll);
  }, []);

  const syncScroll = event => {
    const target = event.target;
    const scrollLeft = target.scrollLeft;
    const header = headerRef.current;
    const table = tableRef.current;

    if (header == null || table == null) return;

    if (target === table) {
      header.scrollLeft = scrollLeft;
    } else if (target === header) {
      table.scrollLeft = scrollLeft;
    }
  };

  if (data == null || data.length === 0) return <></>;

  return (
    <CaseProductsCSS>
      <CaseProductsTableHeader
        main={main}
        title={title}
        headers={headers}
        grid={grid}
        headerRef={headerRef}
      />
      <Table
        headers={headers}
        data={data}
        grid={grid}
        rowAction={rowAction}
        components={caseProductsTableComponents({ showGallery })}
        scrollRef={tableRef}
      />
    </CaseProductsCSS>
  );
};
