import usePostData from '../../../../../hooks/postHook';

export const useUpdateUserRoles = ({ brandId, clientId }) => {
  const [{ body, isLoading, isError, error }, doUpdateUserRoles] = usePostData(
    `admin_brand/${brandId}/client/${clientId}/roles`
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdateUserRoles,
  };
};
