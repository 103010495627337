import { useNavigate } from 'react-router-dom';
import { openNewTab } from '../../../utils/utils';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Size } from '../../style';
import AnimatedIcon from '../AnimatedIcon/AnimatedIcon';

export const NavigateIcon = ({
  to,
  replace = false,
  state = {},
  size = '_M',
  theme = 'neutral',
  disabled,
  newTab,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (disabled) return;
    if (newTab) openNewTab(to, state);
    else navigate(to, { state, replace });
  };

  return (
    <AnimatedIcon
      name={Icons.ExternalLink}
      size={Size.Icon[size]}
      onClick={onClick}
      disabled={disabled}
      theme={theme}
    />
  );
};
