import { useState } from 'react';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import CopyableIcon from '../CopyableIcon/CopyableIcon';
import { Container } from './style';

const CopyableText = ({ text, copyText, size = '_S' }) => {
  const [hover, setHover] = useState(false);
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const setHoverTo = to => () => setHover(to);

  const doCopyText = text => e => {
    e.stopPropagation();
    return (
      text &&
      navigator.clipboard.writeText(text).then(
        () => showSnackbarSuccess('Copied to clipboard'),
        () => showSnackbarError('Please try again')
      )
    );
  };

  return (
    <Container
      align={'center'}
      gap="_2XS"
      onPointerEnter={setHoverTo(true)}
      onPointerLeave={setHoverTo(false)}
      onClick={doCopyText(copyText ?? text)}
    >
      {text}
      {copyText ?? text ? (
        <CopyableIcon text={copyText ?? text} hide={!hover} size={size} />
      ) : null}
    </Container>
  );
};

export default CopyableText;
