import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Table } from '../../../../../components/organisms/Table/Table';
import {
  Layout,
  Major as MajorColumn,
  Minor as MinorColumn,
  TablePageContainer,
} from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';

export const ReferenceIndexTableCSS = styled(Table)`
  .table-cell {
    &._5,
    &._6,
    &._7 {
      padding-left: 20px;
    }
  }
`;

export const ReferenceIndexTablePageContainer = styled(TablePageContainer)``;

export const ReferenceDetailsContainer = styled(UtilityComponents.Grid)`
  .table-status {
    margin-bottom: 0;
  }
`;

export const Major = styled(MajorColumn)`
  .reference-summary {
    height: 234px;
  }
  .reference-info {
    min-height: 424px;
  }
`;

export const Minor = styled(MinorColumn)`
  .notes {
    min-height: 264px;
    height: 264px;
  }
`;

export const MetricsCSS = styled(Flex)`
  ${Styles.flexBetween};
`;

export const MetricCSS = styled(Flex)`
  flex-grow: ${props => (props.last ? 8 : 5)};
  padding-right: ${Layout.Spacing._2XS};
`;

export const ReferenceActivityCSS = styled.div`
  margin-top: auto;
  align-items: flex-end;
  ${Styles.flexBetween};
`;

export const FieldsCSS = styled(UtilityComponents.Grid)`
  grid-template-rows: repeat(4, min-content);
  grid-auto-rows: auto;
  grid-row-gap: 0;
  grid-column-gap: ${Layout.Spacing._S};
`;

export const UpdateSenderDetailsButtonCSS = styled.div``;

export const ReferenceCasesContainer = styled.div`
  .reference-cases {
    height: 394px;
    *::-webkit-scrollbar {
      width: 8px;
      display: unset;
    }
  }
  #table {
    ._0 {
      padding-left: unset;
    }
    ._3 {
      padding: unset;
    }
  }
`;

export const BrandDetails = styled.div`
  position: absolute;
  left: ${Layout.Spacing._S};
  bottom: ${Layout.Spacing._S};
`;
