import styled from '@emotion/styled';
import { Effects, Layout, StatusColors } from '../../style';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 3px;
  height: ${Layout.Spacing._S};
  border-radius: 0px ${Effects.Border_Radius._2XS} ${Effects.Border_Radius._2XS}
    0px;
  background: ${props => props.background};
`;

export const StatusIndicator = (key, background) => item => {
  const { [key]: status } = item;
  const mainStatus = status?.split('_')[0];
  const background_ =
    background ?? StatusColors[mainStatus] ?? StatusColors.Gray;

  return <Container background={background_} />;
};
