import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../../../../components/style';

export const Container = styled(Flex)`
  margin: 0 auto;
  width: 100%;
  max-width: 620px;
  height: 100%;
`;

export const ScrollContainer = styled(Flex)`
  overflow: auto;
  height: 0;
  flex-grow: 1;
`;

export const EvaluationCSS = styled(Flex)`
  width: 100%;
  min-height: 'min-content';
  max-height: 'min-content';
  padding: ${Layout.Spacing._S};
  background-color: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._L};
  overflow: unset;
`;

export const FieldContainer = styled(Flex)`
  ${({ isClickable }) => (isClickable ? '* { cursor: pointer; }' : '')}
`;

export const ParcelImageCSS = styled(Flex)`
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._2XS};
`;

export const ImageFieldCSS = styled(Flex)`
  width: 100%;
  height: min-content;
  .img {
    width: 0 !important;
    flex-grow: 1;
    padding-left: ${Layout.Spacing._2XS} !important;
    padding-right: ${Layout.Spacing._2XS} !important;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ButtonFieldCSS = styled(Flex)`
  width: 100%;
  height: min-content;
  .button {
    width: 0 !important;
    flex-grow: 1;
    padding-left: ${Layout.Spacing._2XS} !important;
    padding-right: ${Layout.Spacing._2XS} !important;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TextFieldCSS = styled(Flex)`
  width: 100%;
  height: min-content;
  .div {
    width: 0 !important;
    flex: 1 1 0;
    padding-left: ${Layout.Spacing._2XS} !important;
    padding-right: ${Layout.Spacing._2XS} !important;
  }
`;
