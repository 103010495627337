import {
  createStatusBadgeOptions,
  prepareDataForDropdown,
} from '../../../../../components/organisms/Filters/utils';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { mergeDeep } from '../../../../../lib/js';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('cases/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { sorters, products, brands } = data;

  const newOps = {};

  newOps[DC.LOCATION] = prepareDataForDropdown(sorters, 'soName', 'soID');

  newOps[DC.BRANDS] = prepareDataForDropdown(brands, 'brName', 'brID', {
    sectionTitle: 'Others',
  });

  newOps[DC.PRODUCT_CATEGORY] = prepareDataForDropdown(
    products,
    'product_name',
    'product_id'
  );

  newOps[DC.PRODUCT_STATUS] = [
    {
      section: DC.OTHERS,
      options: filterStatusOptions(),
    },
  ];

  return newOps;
};

const filterStatusOptions = () => {
  const casesStatus = mergeDeep({}, Constants.DropdownStatus);
  delete casesStatus.Sideload;
  const singleStatus = Object.values(casesStatus).filter(
    status => status.split('_').length < 2
  );

  return createStatusBadgeOptions(singleStatus);
};
