import styled from '@emotion/styled';
import { useToggle } from '@react-hookz/web';
import { useEffect } from 'react';
import {
  LazyLoadComponent,
  LazyLoadImage,
} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import Button from '../../../../../../components/molecules/Button/Button';
import CopyableText from '../../../../../../components/molecules/CopyableText/CopyableTitle';
import { Delta } from '../../../../../../components/molecules/Delta/Delta';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import { disabledText2Rows } from '../../../../../../components/molecules/Disabled/variables';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { TableLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import {
  JoinedStrings,
  RowSimpleText,
  RowSingleImage,
  SimpleStatus,
  Space,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Tabs } from '../../../../../../components/organisms/Tabs/Tabs';
import { TabCount } from '../../../../../../components/organisms/Tabs/components';
import { Colors, Layout, Size } from '../../../../../../components/style';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import {
  defaultErrorDisplay,
  defaultNoPermission,
} from '../../../../../../components/templates/TableStatus/variables';
import { Constants, Utils } from '../../../../../../constants';
import { isEmpty } from '../../../../../../lib/js';
import { joinStrings } from '../../../../../../utils/stringUtils';
import { DisableChartContainer } from '../../../../../../utils/styleUtils';
import { DateTypes, formatDate } from '../../../../../../utils/timeUtils';
import { CaseProducts } from '../../../../features_public/cases/components';
import { parcelKeys } from '../../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../../features_public/products/controllers/variables';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../../../products/controllers/variables';
import ExpandableTicket from '../../controllers/ExpandableTicket';
import {
  achievementDescriptionFallback,
  achievementTypes,
  achivementStyles,
  authenticationKeys as keys,
  productActionTakenTooltipContent,
  statusIcons,
} from '../../variables';
import {
  AchievementDescriptionText,
  AchievementDescriptionTitle,
  AchievementTime,
  AuthDetailsSectionTitle,
  BoldDetail,
  BrandName,
  DetailTitle,
  GrayBoldDetail,
  ParcelDetailText,
  ParcelDetailTitle,
  ProductSummaryAlias,
  RemainingCountText,
  RemainingTimeLabel,
} from '../atoms';
import {
  AchievementBadgeCSS,
  ActionTooltip,
  ActionsContainer,
  AuthGalleryDismissIcon,
  AuthRiskAnalysisCustom,
  BrandAvatar,
  Carousel,
  DisableProduct,
  HiddenSizer,
  NextButton,
  ParcelSummaryContainer,
  PrevButton,
  ProductAttachmentsContainer,
  ProductAuth,
  ProductAuthLoader,
  ProductDetailsContainer,
  ProductDetailsGrid,
  ProductDetailsMajor,
  ProductDetailsMinor,
  ProductDetailsScroll,
  ProductDisplayContainer,
  ProductGalleryContainer,
  ProductListTable,
  ProductNotesCustom,
  ProductStatusLabelContainer,
  ProductSummaryContainer,
  RelatedProductsContainer,
  ShowDetailsArrow,
  ShowDetailsButtonContainer,
  ShowGalleryIconCSS,
  SupportAsLabel,
} from '../style';

export const ProductsListHeader = ({ productListTabsProps }) => {
  return (
    <Flex w padding={`${Layout.Spacing._XS} ${Layout.Spacing._S} 0 `} relative>
      <Tabs {...productListTabsProps} />
      <Separator />
    </Flex>
  );
};

export const ProductListContent = ({ productListContentProps }) => {
  const {
    headers,
    data,
    grid,
    rowAction,
    tableComponentsProps,
    isLoading,
    isError,
    error,
    tableStatusProps,
    selectedRows,
  } = productListContentProps;
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        <ProductListTable
          headers={headers}
          data={data}
          grid={grid}
          rowAction={rowAction}
          components={tableComponents({ ...tableComponentsProps })}
          selectedRows={selectedRows}
        />
      }
    />
  );
};

const RemainingAuthTime = item => {
  let { value: text, color, icon } = item[keys.remainingAuthTime];
  const { [keys.hasSupportTickets]: hasSupportTickets } = item;

  const [type, shade] = color.split('.');

  color = Colors[type][shade];

  return (
    <RemainingTimeLabel
      text={text}
      color={color}
      icon={icon}
      hasSupportTickets={hasSupportTickets}
    />
  );
};

const tableComponents = () => ({
  [keys.space]: { display: Space },
  [keys.photos]: {
    display: RowSingleImage(keys.photos, {})('_S'),
  },
  [keys.alias]: {
    display: RowSimpleText(keys.alias, 'bold', true, { copyable: true }),
  },
  [keys.category]: { display: JoinedStrings(keys.subCategory, keys.category) },
  [keys.itemQuantity]: { display: RowSimpleText(keys.itemQuantity, 'bold') },
  [keys.brand.self]: {
    display: User(keys.brand.name, keys.brand.logo, { bold: true }),
  },
  [keys.remainingAuthTime]: { display: RemainingAuthTime },
  [keys.status]: {
    display: SimpleStatus(keys.status),
  },
});

export const AchievementScore = ({ time, delta }) => {
  const isSlower = delta > 0;
  const deltaExtraText =
    delta == null || delta == 0 ? '' : isSlower ? 'slower' : 'faster';

  return (
    <Flex column>
      <div style={{ maxWidth: '66%' }}>
        <AchievementTime text={time} />
      </div>
      <Delta
        delta={delta}
        deltaExtraText={deltaExtraText}
        deltaLabelExtraElement={
          <AchievementDeltaInfo delta={delta} isSlower={isSlower} />
        }
        size="_M"
        positiveIsGood={false}
        deltaL
      />
    </Flex>
  );
};

const AchievementDeltaInfo = ({ delta, isSlower }) => {
  const description = `Your brand's product authentication performance is ${Math.abs(
    delta
  )}% ${
    isSlower ? 'slower' : 'faster'
  } in the past 30 days compare to the all time average.`;

  return <InfoIcon description={description} size="_S" />;
};

export const AchievementDescription = ({ description, title }) => {
  description = description ?? achievementDescriptionFallback.description;
  title = title ?? achievementDescriptionFallback.title;
  return (
    <Flex column>
      <AchievementDescriptionTitle text={title} />
      <AchievementDescriptionText text={description} />
    </Flex>
  );
};

export const AchievementBadge = ({ illustration }) => {
  let style = {};
  if (illustration === Icons.AlarmClock)
    style = achivementStyles[achievementTypes.failed];

  return (
    <AchievementBadgeCSS style={style}>
      <Icon
        name={illustration}
        color={Colors.Error._700}
        size={Size.Icon._4XL}
      />
    </AchievementBadgeCSS>
  );
};

export const DismissIcon = ({ onClick }) => {
  return (
    <AuthGalleryDismissIcon
      onClick={onClick}
      theme={'neutral'}
      name={Icons.X}
    />
  );
};

const ProductSlide = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const badgeStatuses = [
  Constants.Status.Counterfeit,
  Constants.Status['Non-counterfeit'],
];

export const ProductGallery = ({
  product,
  showGallery = Utils.emptyFunction,
  expanded,
}) => {
  const { [keys.status]: status, [keys.hasSupportTickets]: hasSupportTickets } =
    product;

  const photos = product[keys.photos] ?? [];
  const slides = photos.map((photo, index) => (
    <LazyLoadImage
      // onClick={showGallery(product, index)}
      src={photo.ppImage}
      alt={index}
      delayTime={0}
      placeholderSrc={photo.ppImage}
      effect="blur"
      wrapperProps={{
        // If you need to, you can tweak the effect transition using the wrapper style.
        style: { transitionDelay: '0.5s' },
      }}
    />
  ));
  const onClickableElementClick = (_, index) => showGallery(product, index);

  return (
    <ProductGalleryContainer expanded={expanded} h0 grow={expanded} w relative>
      <Carousel
        slides={slides}
        clickableElement={ShowGalleryIcon}
        clickableElementProps={{
          onClick: onClickableElementClick,
        }}
      />
      {hasSupportTickets && <ProductSupportBadge />}
      {badgeStatuses.includes(status) && <ProductStatusBadge status={status} />}
    </ProductGalleryContainer>
  );
};

const ProductSupportBadge = () => {
  return (
    <SupportAsLabel
      variant="Tertiary"
      size="_L"
      leftIcon={Icons.HelpCircle}
      text={'Support requested'}
    />
  );
};

const ProductStatusBadge = ({ status }) => {
  return (
    <ProductStatusLabelContainer
      size="_L"
      variant="Status"
      text={Constants.StatusLabel[status]}
      status={status}
      leftIcon={statusIcons[status]}
    />
  );
};

const ShowGalleryIcon = ({ onClick }) => {
  return (
    <ShowGalleryIconCSS
      onClick={onClick}
      children={<Icon name={Icons.Expand} size={Size.Icon._XL} />}
    />
  );
};

export const ProductSummary = ({
  expanded,
  toggleShowDetails,
  loadProductDetails,
  parcelData,
  product,
}) => {
  const {
    [keys.alias]: alias,
    [keys.category]: category,
    [keys.subCategory]: subcategory,
    [keys.itemQuantity]: itemQuantity,
    [keys.brand.name]: brandName,
    [keys.brand.logo]: brandLogo,
  } = product;
  const handleDetailsButtonClick = () => {
    toggleShowDetails();

    const hasLoadedDetails = parcelData && !isEmpty(parcelData);

    if (expanded || hasLoadedDetails) return;
    loadProductDetails(product);
  };

  return (
    <ProductSummaryContainer column relative>
      <Flex justify={'between'}>
        <Flex column gap="_2XS" w0 grow>
          <ProductSummaryAlias text={<CopyableText text={alias} />} />
          <Category category={category} subcategory={subcategory} />
          <Quantity itemQuantity={itemQuantity} />
        </Flex>
        <ShowDetailsButton
          onClick={handleDetailsButtonClick}
          expanded={expanded}
        />
        <ProductBrand name={brandName} logo={brandLogo} />
      </Flex>
    </ProductSummaryContainer>
  );
};

const Category = ({ category, subcategory }) => {
  return (
    <Flex gap="_3XS">
      <DetailTitle text={`Product category: `} />
      <BoldDetail text={category} />
      {subcategory && <BoldDetail text={` - `} />}
      <GrayBoldDetail text={subcategory} />
    </Flex>
  );
};

const Quantity = ({ itemQuantity }) => {
  return (
    <Flex gap="_3XS">
      <DetailTitle text={`Item quantity: `} />
      <BoldDetail text={itemQuantity} />
    </Flex>
  );
};

const ShowDetailsButton = ({ onClick, expanded }) => {
  const text = expanded ? 'Hide details' : 'Show details';
  return (
    <ShowDetailsButtonContainer
      column
      justify={'end'}
      align={'center'}
      h
      isClickable
      onClick={onClick}
      expanded={expanded}
    >
      <ShowDetailsArrow
        name={Icons.ChevronUp}
        color={Colors.Neutral._500}
        expanded={expanded}
        theme_="light"
      />
      <GrayBoldDetail text={text} />
    </ShowDetailsButtonContainer>
  );
};

const ProductBrand = ({ name, logo }) => {
  return (
    <Flex margin={'auto 0'} gap="_S" align="center" w0 grow justify={'end'}>
      <BrandName text={name} />
      <BrandAvatar image={logo} size={'_2XL'} username={name} />
    </Flex>
  );
};

const ProductDetails = ({
  expanded,
  relatedProductsProps,
  parcelData,
  productDetails,
}) => {
  return (
    <ProductDetailsContainer
      w
      h0
      grow={expanded}
      relative
      expanded
      column
      className={'product-details'}
    >
      <Separator
        style={{
          top: 0,
          bottom: 'unset',
          position: 'sticky',
          minHeight: '0.5px',
          width: 'calc(100%)',
          zIndex: 100,
        }}
      />
      <ProductDetailsScroll w h relative column>
        <ProductDetailsGrid>
          <ProductDetailsMajorColumn
            relatedProductsProps={relatedProductsProps}
            parcelData={parcelData}
            productDetails={productDetails}
          />
          <RelatedProductsMinorColumn
            relatedProductsProps={relatedProductsProps}
            parcelData={parcelData}
            productDetails={productDetails}
          />
        </ProductDetailsGrid>
      </ProductDetailsScroll>
    </ProductDetailsContainer>
  );
};

const ProductDetailsMajorColumn = ({
  relatedProductsProps,
  parcelData,
  productDetails,
}) => {
  const tickets = productDetails?.[productKeys.tickets] ?? [];
  const { hasSupportViewPermission } = relatedProductsProps;
  const hasPermission = hasSupportViewPermission;
  return (
    <ProductDetailsMajor>
      <Flex w column gap="_XS" h>
        <RelatedProducts
          relatedProductsProps={relatedProductsProps}
          parcelData={parcelData}
        />
      </Flex>
      {hasPermission && !!tickets.length && (
        <Flex w column gap="_XS" h>
          <Separator style={{ top: 0, bottom: 'unset', position: 'sticky' }} />
          <ProductTickets tickets={tickets} />
        </Flex>
      )}
      <Spacer height="_S" />
    </ProductDetailsMajor>
  );
};

const RelatedProductsMinorColumn = ({
  relatedProductsProps,
  parcelData,
  productDetails,
}) => {
  const { hasCaseViewPermission } = relatedProductsProps;

  return (
    <ProductDetailsMinor>
      <ParcelSummary
        parcelData={parcelData}
        hasPermission={hasCaseViewPermission}
      />
      {hasCaseViewPermission && (
        <ProductNotes productDetails={productDetails} />
      )}
      {hasCaseViewPermission && (
        <ProductAttachments productDetails={productDetails} />
      )}
      <Spacer height="_S" />
    </ProductDetailsMinor>
  );
};

const RelatedProducts = ({ relatedProductsProps, parcelData }) => {
  const component = CaseProducts({ ...relatedProductsProps, hideSpacer: true })(
    parcelData
  );
  const { parcelsIsLoading: isLoading, parcelsIsError: isError } =
    relatedProductsProps;
  const { hasCaseViewPermission } = relatedProductsProps;

  const hasPermission = hasCaseViewPermission;
  return (
    <RelatedProductsContainer column gap="_S" h>
      <AuthDetailsSectionTitle text={`Related products in the same parcel`} />
      {!hasPermission ? (
        <NoSearchResults {...defaultNoPermission} />
      ) : isLoading ? (
        <TableLoader />
      ) : isError ? (
        <NoSearchResults {...defaultErrorDisplay} />
      ) : (
        component
      )}
    </RelatedProductsContainer>
  );
};

const ProductTickets = ({ tickets }) => {
  return (
    <Flex column w gap="_S">
      <AuthDetailsSectionTitle text={`Support tickets`} />
      {tickets.map(ticket => (
        <ExpandableTicket ticket={ticket} />
      ))}
      <Spacer />
    </Flex>
  );
};

const ParcelSummary = ({ parcelData, hasPermission }) => {
  return (
    <DisableChartContainer disabled={!hasPermission}>
      <ParcelSummaryContainer>
        <ParcelDetails parcelData={parcelData} />
        <AuthRiskAnalysis parcelData={parcelData} />
      </ParcelSummaryContainer>
      {!hasPermission && <Disabled text={disabledText2Rows} />}
    </DisableChartContainer>
  );
};

const ParcelDetails = ({ parcelData }) => {
  const {
    [parcelKeys.sorterName]: sorterName,
    [parcelKeys.sideloadDate]: sideloadDate,
    [parcelKeys.sorterCity]: sorterCity,
    [parcelKeys.ownQuantity]: ownQuantity,
    [parcelKeys.totalQuantity]: totalQuantity,
    [parcelKeys.parcelVolume]: volume,
    [parcelKeys.parcelWeight]: weight,
    [parcelKeys.parcelTrackingNumber]: trackingNumber,
  } = parcelData;

  const calendarDate =
    formatDate(sideloadDate, DateTypes.ddmmyy) ?? Constants.fallback;

  const time = formatDate(sideloadDate, DateTypes.time12);

  const details = [
    {
      title: 'LOCATION',
      value: joinStrings(sorterName, sorterCity, ' - '),
    },
    {
      title: 'CATCH DATE',
      value: (
        <Flex column>
          <ParcelDetailText text={calendarDate} />
          <ParcelDetailText text={time} bold={false} />
        </Flex>
      ),
    },
    {
      title: 'OWN / TOTAL ITEMS',
      value: joinStrings(ownQuantity, totalQuantity, '/ '),
    },
    {
      title: 'VOLUME',
      value: volume,
    },
    {
      title: 'WEIGHT',
      value: weight,
    },
    {
      title: 'TRACKING NUMBER',
      value: trackingNumber,
    },
  ];

  return (
    <>
      <AuthDetailsSectionTitle text="Parcel details" />
      {details.map(({ title, value }) => (
        <Flex w gap="_S" key={title}>
          <Flex w0 grow>
            <ParcelDetailTitle text={title} />
          </Flex>
          <Flex w0 grow>
            <ParcelDetailText text={value ?? Constants.fallback} />
          </Flex>
        </Flex>
      ))}
    </>
  );
};

const AuthRiskAnalysis = ({ parcelData }) => {
  const {
    [parcelKeys.reason]: reason,
    [parcelKeys.confidence]: confidence,
    [parcelKeys.riskType]: riskType,
  } = parcelData;
  const riskAnalysisProps = {
    reason: {
      description:
        getSideloadExplanation(reason, riskType) ?? Constants.fallback,
      riskPotential: {
        value: confidence ?? 0,
        display: confidence != null ? `${confidence}%` : 'n/a',
        fill: '#0c57bf',
      },
      title: getReasonTitle(reason),
    },
    riskType: getRiskTypes(riskType),
    noShadow: true,
    size: '_M',
  };
  return <AuthRiskAnalysisCustom {...riskAnalysisProps} />;
};

const ProductNotes = ({ productDetails }) => {
  const { notes } = productDetails;
  return notes?.length ? (
    <ProductNotesCustom notes={notes} hasEditPermission={false} />
  ) : (
    ''
  );
};

const ProductAttachments = ({ productDetails }) => {
  const { attachments, [keys.id]: id } = productDetails;
  return attachments?.length ? (
    <ProductAttachmentsContainer
      attachments={attachments}
      hasEditPermission={false}
      hasDeletePermission={false}
      route="case"
      folder="case_files"
      id={id}
      attachmentIdKey={productKeys.attachmentId}
      urlKey={productKeys.attachmentURL}
      nameKey={productKeys.attachmentName}
    />
  ) : (
    ''
  );
};

export const Actions = ({ actions, product, index, active, isLoading }) => {
  const status = product[keys.status];
  const productActionTaken = badgeStatuses.includes(status);

  return (
    <ActionsContainer w jusity="between" gap="_XL">
      {actions.map((action, i) => (
        <ActionTooltip
          content={
            productActionTaken
              ? productActionTakenTooltipContent[status]
              : action.tooltipContent
          }
          index={index}
          // asChild={false}
          key={i}
          children={
            <Button
              {...action}
              onClick={action.onClick(product, index)}
              disabled={
                (active && isLoading) ||
                productActionTaken ||
                action.disabled ||
                action.tooltipContent
              }
            />
          }
        />
      ))}
    </ActionsContainer>
  );
};

export const RemainingCount = ({ remainingAuthenticationsCount }) => {
  return (
    <Flex gap="_2XS">
      <RemainingCountText text={`Remaining authentications`} />
      <TabCount text={remainingAuthenticationsCount} selected />
    </Flex>
  );
};

export const ProductsAuthSizer = ({ productsBeingAuthenticated: products }) => {
  <HiddenSizer
    children={
      <ProductAuth column h w>
        <ProductDisplay product={products?.[0] ?? {}} />
        <Actions actions={[]} />
      </ProductAuth>
    }
  />;
};

export const ProductsToAuth = ({
  productsBeingAuthenticated: products,
  activeSlideIndex,
  actions,
  relatedProductsProps,
  authSliderState,
  showGallery,
  isLoading,
}) => {
  return products.map((product, index) => {
    const parcelId = product[keys.parcel.id];
    const savedParcels = authSliderState.parcel_results;
    const parcelData = savedParcels[parcelId] ?? {};
    const productDetails = authSliderState?.product_details?.[parcelId] ?? {};
    const active = index === activeSlideIndex;

    return (
      <ProductAuth
        column
        h
        w
        index={index}
        activeSlideIndex={activeSlideIndex}
        relative
        key={index}
      >
        <LazyLoadComponent>
          <ProductDisplay
            product={product}
            active={active}
            relatedProductsProps={relatedProductsProps}
            parcelData={parcelData}
            showGallery={showGallery}
            productDetails={productDetails}
          />
          <Actions
            actions={actions}
            product={product}
            index={index}
            isLoading={isLoading}
            active={active}
          />
          {index !== activeSlideIndex && <DisableProduct />}
          {active && isLoading && <ProductAuthLoader />}
        </LazyLoadComponent>
      </ProductAuth>
    );
  });
};

const ProductDisplay = ({
  product,
  active,
  relatedProductsProps,
  parcelData,
  showGallery,
  productDetails,
}) => {
  const [showDetails_, toggleShowDetails] = useToggle(false);

  useEffect(() => {
    if (!active) toggleShowDetails(false);
  }, [active]);

  const showDetails = active && showDetails_;

  return (
    <ProductDisplayContainer column grow relative>
      <ProductGallery
        product={product}
        showGallery={showGallery}
        expanded={!showDetails}
      />
      <ProductSummary
        product={product}
        expanded={showDetails}
        toggleShowDetails={toggleShowDetails}
        {...relatedProductsProps}
        parcelData={parcelData}
      />
      <ProductDetails
        expanded={active && showDetails}
        relatedProductsProps={relatedProductsProps}
        parcelData={parcelData}
        productDetails={productDetails}
      />
      <Separator />
    </ProductDisplayContainer>
  );
};

export const SlideChangeButtons = ({
  handleSlideChange,
  atStart,
  atEnd,
  isLoading,
}) => {
  return (
    <>
      {!atStart && (
        <PrevButton
          variant="Tertiary"
          text={'Prev'}
          size="_S"
          leftIcon={Icons.ChevronLeft}
          onClick={handleSlideChange('prev')}
          disabled={isLoading}
          black
        />
      )}
      {!atEnd && (
        <NextButton
          variant="Tertiary"
          text={'Next'}
          size="_S"
          rightIcon={Icons.ChevronRight}
          onClick={handleSlideChange('next')}
          disabled={isLoading}
          black
        />
      )}
    </>
  );
};
