import useFetchData from '../../../../../hooks/fetchHook';

export const useGetUsersInolved = ({ ticketId }) => {
  const [{ data = [], error }] = useFetchData(
    `support_ticket/${ticketId}/people`,
    {
      formatter: formatUsersInvolved,
      failureFormatter: () => [],
    }
  );

  return { data, error };
};

const formatUsersInvolved = users => {
  return users.map(user => {
    const { usImage: image, usDisplayName: name } = user;
    return {
      image,
      name,
    };
  });
};
