import styled from '@emotion/styled';
import {
  Colors,
  Effects,
  Layout,
  TablePageContainer,
} from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';
import { offsetGrowingBorderPadding } from '../../../../../utils/styleUtils';
import { descriptionNames } from '../controllers/variables';

export const KnowledgeBaseTablePageContainer = styled(TablePageContainer)``;

export const ManualInstructionContent = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  gap: ${Layout.Spacing._S};
  > div {
    width: 0;
    flex-grow: 1;
  }
`;

export const FieldsCSS = styled.div`
  ${Styles.flexColumn};
  width: 0;
  flex-grow: 1;
  height: 100%;
  .content {
    flex-grow: 1;
  }
`;

export const InstructionContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: 40px 1fr;
`;

export const InstructionDescriptionCSS = styled.div`
  .card {
    height: ${props => (props.isEditing ? '376px' : '312px')};
  }
  form {
    height: ${props => (props.isEditing ? '73.5%' : '100%')};
  }
`;

export const InstructionDescriptionFieldsCSS = styled(UtilityComponents.Grid)`
  grid-row-gap: 0px;
  grid-template-rows: 72px 1fr;
  .${descriptionNames.ruleName}, .${descriptionNames.productCategory} {
    width: 100%;
  }
  .${descriptionNames.ruleName} {
    grid-row: 1;
    grid-column: 1 / 7;
  }
  .${descriptionNames.productCategory} {
    grid-row: 1;
    grid-column: 7 / 13;
  }
  .${descriptionNames.description} {
    grid-column: 1 / 13;
    grid-row: 2;
    height: 144px;
  }
`;

export const Major = styled.div`
  grid-column: 1 /9;
  padding-bottom: ${Layout.Spacing._M};
  gap: ${Layout.Spacing._XS};
  ${Styles.flexColumn};
`;

export const Minor = styled.div`
  grid-column: 9 /13;
  padding-bottom: ${Layout.Spacing._M};
  gap: ${Layout.Spacing._L};
  ${Styles.flexColumn};
`;

export const SectionFlex = styled.div`
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
  .header .animated-icon {
    margin-right: -2px;
  }
`;

export const InstructionLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: ${Layout.Spacing._2XS};
`;

export const SectionItem = styled.div`
  display: flex;
  width: 0;
  flex-grow: 1;
  gap: ${Layout.Spacing._2XS};
  span {
    width: 0;
    flex-grow: 1;
  }
`;

export const BottomRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
`;

export const Actions = styled.div`
  display: flex;
  gap: ${Layout.Spacing._S};
`;

export const DownloadTag = styled.a`
  text-decoration: none;
  width: 100%;
  ${Styles.flex};
`;

export const LinkUploadStyles = styled.div`
  .in-place-form {
    width: 328px;
  }
`;

export const AuthenticityBadgeCSS = styled.div`
  position: absolute;
  z-index: 1;
  top: ${Layout.Spacing._S};
  left: ${Layout.Spacing._S};
  .file-instance {
    overflow: hidden;
  }
  .menu-option {
    width: 120px;
  }
`;

export const InstructionTypesCSS = styled(UtilityComponents.Grid)`
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  grid-auto-rows: 130px;
`;

export const InstructionTypeCSS = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: ${props =>
    offsetGrowingBorderPadding({
      padding: Layout.Spacing._S,
      selected: props.selected,
    })};
  gap: ${Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S};
  border: solid
    ${props =>
      props.selected
        ? `2px ${Colors.Primary._600}`
        : `1px ${Colors.Neutral._300}`};
  ${Styles.flex};
`;

export const InstructionIconCSS = styled.div`
  min-width: 80px;
`;

export const CharacteristicCardCSS = styled.div`
  height: 536px;
  .card {
    width: 100%;
    height: 100%;
  }
`;

export const ItemDescripitionCSS = styled.div`
  .input {
    height: 80px !important;
  }
`;

export const centerStyles = {
  width: '100%',
  height: '100%',
};
