import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { OptionFrame } from '../../../../../components/organisms/OptionFrame/OptionFrame';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';

export const SetupChoiceLayout = ({ onCancel, wizardSetup, manualSetup }) => {
  return (
    <StepPopup
      title={'New knowledge base'}
      sectionTitle={'How do you want start to build a new knowledge base?'}
      hidePrimary
      onCancel={onCancel}
    >
      <Flex gap="_S" h w>
        <OptionFrame {...wizardSetup} />
        <OptionFrame {...manualSetup} />
      </Flex>
    </StepPopup>
  );
};
