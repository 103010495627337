import useFetchData from '../../../../../hooks/fetchHook';

export const useGetBrands = () => {
  const [{ data, isLoading, isError, error }] = useFetchData(`brands`, {
    formatter,
    failureFormatter: () => [],
    params: { has_no_sorter: true },
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const formatter = data => {
  return data.map(entry => ({
    value: entry.brID,
    label: entry.brName,
  }));
};
