import { Colors, StatusColors } from '../../../../../components/style';
import {
  getLinechartKeysAndLines,
  maskEntry,
} from '../../../../../utils/utils';
import { dashboardKeys, mockTimeline } from '../variables';

export const formatTimeline = data => {
  if (data == null || !data.length) return inactiveLinechart();
  try {
    let error;
    maskEntry(data, mask);
    data = data.map(entry => {
      let {
        [dashboardKeys.month]: month,
        [dashboardKeys.day]: day,
        inspections: Inspections,
        seizures: Seizures,
        stops: Stops,
      } = entry;
      if (!month || !day) error = true;

      const date = months[month] + ' ' + day;

      return {
        Inspections,
        Stops,
        Seizures,
        date,
      };
    });

    if (
      data.every(datum => !datum.Inspections && !datum.Stops && !datum.Seizures)
    )
      throw Error('No activity. Show mock linechart');

    const { keys, lines } = getLinechartKeysAndLines(data[0], [
      StatusColors.Inspection,
      StatusColors.Stopped,
      StatusColors.Seized,
    ]);
    if (error) throw Error();
    return { data, keys, lines };
  } catch {
    return inactiveLinechart();
  }
};

export const inactiveLinechart = () => {
  const _30DaysAgo = new Date();
  _30DaysAgo.setDate(_30DaysAgo.getDate() - 29);

  const data = Array.from(Array(30)).map((_, index) => {
    const date = months[_30DaysAgo.getMonth() + 1] + ' ' + _30DaysAgo.getDate();
    _30DaysAgo.setDate(_30DaysAgo.getDate() + 1);

    return {
      Inspections: mockTimeline[index].Inspections,
      Stops: mockTimeline[index].Stops,
      Seizures: mockTimeline[index].Seizures,
      date,
    };
  });

  const { keys, lines } = getLinechartKeysAndLines(data[0], [
    Colors.Neutral._200,
    Colors.Neutral._200,
    Colors.Neutral._200,
  ]);

  return { data, keys, lines };
};

const mask = {
  caDay: dashboardKeys.day,
  caMonth: dashboardKeys.month,
  caYear: dashboardKeys.year,
};

const months = [
  // start count at 1
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
