import { useToggle } from '@react-hookz/web';
import { useEffect, useRef } from 'react';
import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Avatar from '../../../../../../components/molecules/Avatar/Avatar';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import Button from '../../../../../../components/molecules/Button/Button';
import Ellipsis from '../../../../../../components/molecules/LoadingAnimation/Ellipsis';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import { defaultErrorDisplay } from '../../../../../../components/templates/TableStatus/variables';
import { howLongAgo } from '../../../../../../utils/timeUtils';
import {
  Padded,
  clickedBy,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { dateCreatedFormat, lastMessageFormat } from '../../controllers/utils';
import {
  ActivityScroll,
  AwaitingResponseCSS,
  BadgeDropdownCSS,
  BrandAvatar,
  DetailsSubsectionCSS,
  InvolvedUserAvatarCSS,
  MarkReadUnreadCSS,
  MarkReadUnreadCenteringCSS,
  MenuOptionCSS,
  MenuOptionsCSS,
  MenuOptionsHoverArea,
  MessageCSS,
  NoResultsCSS,
  NotificationCSS,
  NotificationTextCSS,
  SendMessageCSS,
  TicketOptionsCSS,
  UnreadCircle,
} from '../../style';
import {
  MessageContent,
  MessageData,
  MetadataIcon,
  MetadataText,
  NotificationText,
  SubsectionText,
  SubsectionTitle,
  TickerCreatorName,
  TicketStatusText,
} from '../atoms/atoms';

export const TicketAliasAndTime = ({
  alias,
  showDateCreated,
  dateCreated,
  showLastMessage,
  lastMessage,
  read,
}) => {
  return (
    <Flex>
      <MetadataText
        text={[
          alias,
          <Padded symbol={<MetadataText text={'•'} read={read} bold />} />,
        ]}
        read={read}
        bold
      />

      <MetadataText
        text={
          showDateCreated
            ? dateCreatedFormat(dateCreated)
            : showLastMessage
            ? lastMessageFormat(lastMessage)
            : lastMessageFormat(lastMessage)
        }
        read={read}
      />
      {!read ? <UnreadCircle /> : ''}
    </Flex>
  );
};

export const TicketActions = ({
  messageCount,
  read,
  showOptions,
  shouldShowOptions,
  markReadUnread,
  ticketActionsRef: ref,
}) => {
  return (
    <Flex gap="_XS" ref__={ref}>
      <MessageCount messageCount={messageCount} read={read} />
      <ReadUnread read={read} />
      <TicketOptions
        showOptions={showOptions}
        shouldShowOptions={shouldShowOptions}
        markReadUnread={markReadUnread}
        read={read}
      />
    </Flex>
  );
};

const MessageCount = ({ messageCount, read }) => {
  return (
    <Flex gap="_2XS">
      <MetadataIcon name={Icons.MessageCircle} read={read} />
      <MetadataText text={messageCount} read={read} bold />
    </Flex>
  );
};

const ReadUnread = ({ read }) => {
  return (
    <Flex>
      <MetadataIcon name={read ? Icons.MailOpen : Icons.Mail} read={read} />
    </Flex>
  );
};

export const TicketOptions = ({
  showOptions,
  shouldShowOptions,
  markReadUnread,
  read,
}) => {
  const text = `Mark as ${read ? 'unread' : 'read'}`;
  return (
    <TicketOptionsCSS onMouseEnter={showOptions} className="TICKET OPTIONS">
      <MetadataIcon name={Icons.MoreVertical} read={read} />
      {shouldShowOptions && (
        <MarkReadUnreadCSS
          className="mark-read-unread"
          onClick={markReadUnread}
        >
          <MarkReadUnreadCenteringCSS>
            <Text
              text={text}
              variant={FontKeys.Variant.Paragraph}
              size={FontKeys.Size._S}
              weight={FontKeys.Weight.Regular}
            />
          </MarkReadUnreadCenteringCSS>
        </MarkReadUnreadCSS>
      )}
    </TicketOptionsCSS>
  );
};

export const TicketCreator = ({ image, name }) => {
  return (
    <Flex gap="_XS" align="center">
      <Avatar image={image} username={name} size="_2XS" border />
      <TickerCreatorName text={name} />
    </Flex>
  );
};

export const Badges = ({ status, priority }) => {
  return (
    <Flex gap="_XS">
      <Badge type={BadgeType.ticket} text={status} size="_S" />
      <Badge type={BadgeType.ticket} text={priority} size="_S" />
    </Flex>
  );
};

export const DetailsSubsection = ({ title, children }) => {
  return (
    <DetailsSubsectionCSS>
      <SubsectionTitle text={title} />
      {children}
    </DetailsSubsectionCSS>
  );
};

export const CreationData = ({ dateCreated, ticketCreator }) => {
  return (
    <Flex column gap="_S">
      <>
        <DetailsSubsection title={'CREATION DATE'}>
          <SubsectionText text={dateCreated} />
        </DetailsSubsection>
      </>
      <>
        <DetailsSubsection title={'BRAND'}>
          <Flex align="center" gap="_XS">
            <BrandAvatar
              image={ticketCreator?.brandImage}
              username={ticketCreator?.brandName ?? ''}
              size="_S"
              border
            />
            <SubsectionText text={ticketCreator?.brandName} />
          </Flex>
        </DetailsSubsection>
        <DetailsSubsection title={'CREATED BY'}>
          <Flex align="center" gap="_XS">
            <Avatar
              image={ticketCreator?.image}
              username={ticketCreator?.name ?? ''}
              size="_S"
            />
            <SubsectionText text={ticketCreator?.name} />
          </Flex>
        </DetailsSubsection>
      </>
    </Flex>
  );
};

export const BadgeDropdown = ({ badge, hasEditPermission }) => {
  const [display, toggleDisplay] = useToggle(false);

  const ref = useRef();

  const { title, text, options } = badge;

  useEffect(() => {
    const close = e => {
      if (clickedBy(ref.current, e)) return;
      toggleDisplay(false);
    };
    window.addEventListener('click', close);
    return () => {
      window.removeEventListener('click', close);
    };
  }, []);

  return (
    <BadgeDropdownCSS
      hasEditPermission={hasEditPermission}
      display={display}
      onClick={toggleDisplay}
      ref={ref}
    >
      <SubsectionTitle text={title} />
      <Badge
        text={text}
        type={BadgeType.ticket}
        icon={hasEditPermission && Icons.ChevronDown}
        size="_M"
      />
      <BadgeMenu options={options} />
    </BadgeDropdownCSS>
  );
};

const BadgeMenu = ({ options }) => {
  return (
    <MenuOptionsHoverArea className="menu-options">
      <MenuOptionsCSS>
        {options.map(option => {
          return <MenuOption option={option} key={option.text} />;
        })}
      </MenuOptionsCSS>
    </MenuOptionsHoverArea>
  );
};

const MenuOption = ({ option }) => {
  const { onClick, selected, text } = option;

  return (
    <MenuOptionCSS selected={selected} onClick={onClick}>
      <TicketStatusText bold={selected} text={text} />
    </MenuOptionCSS>
  );
};

export const InvolvedUserAvatar = ({ user, index }) => {
  const { image, name } = user;

  return (
    <InvolvedUserAvatarCSS index={index}>
      <Avatar image={image} username={name} size="_S" variant="Stackable" />
    </InvolvedUserAvatarCSS>
  );
};

export const Message = ({ message: _message }) => {
  const { image, message, name, date, type, is_office } = _message;
  const fromClient = is_office == 0 && type === 'Brand';

  return (
    <MessageCSS fromClient={fromClient}>
      <Avatar image={image} username={name} size="_M" />
      <Flex
        column
        justify="center"
        align={fromClient ? 'end' : 'start'}
        className="message-background"
        gap="_XS"
      >
        <MessageContent fromClient={fromClient} text={message} />
        <Flex align="end">
          <MessageData text={name} />
          <Padded symbol={<MessageData text={'•'} bold date />} />
          <MessageData text={howLongAgo(date)} date />
        </Flex>
      </Flex>
    </MessageCSS>
  );
};

export const AwaitingResponse = ({ text }) => {
  return (
    <AwaitingResponseCSS className="awaiting-response">
      <MessageData text={text} />
    </AwaitingResponseCSS>
  );
};

export const Activity = ({
  activity,
  handleNotificationClick,
  activityRef,
  isLoading,
  isError,
  showClickable,
  moreActivityClicked,
}) => {
  return isError ? (
    <NoResultsCSS>
      <NoSearchResults {...defaultErrorDisplay} />
    </NoResultsCSS>
  ) : (
    <ActivityScroll
      column
      scroll
      gap="_XS"
      ref__={activityRef}
      className={'recent-activity'}
      moreActivityClicked={moreActivityClicked}
    >
      {activity?.map((notification, i) => {
        return (
          <Notification
            onClick={handleNotificationClick(notification, i)}
            notification={notification}
            showClickable={showClickable}
            key={i}
          />
        );
      })}
      {isLoading && <Ellipsis color={Colors.Neutral._200} size={8} />}
    </ActivityScroll>
  );
};

const Notification = ({ onClick, notification, showClickable }) => {
  const { image, name, message, time } = notification;

  const messageText = parseStringModifiers(message);
  const symbol = <Padded symbol={<NotificationText text={'•'} bold date />} />;
  const dateText = <NotificationText text={howLongAgo(time)} date />;

  return (
    <NotificationCSS
      onClick={onClick}
      className="notification"
      showClickable={showClickable}
    >
      <Avatar image={image} size="_S" username={name} />
      <Flex>
        <NotificationTextCSS>
          <NotificationText text={[...messageText, symbol, dateText]} />
        </NotificationTextCSS>
        {showClickable && (
          <Flex align="center" className="notification-click-icon">
            <Icon
              name={Icons.ChevronRight}
              color={Colors.Neutral._400}
              size={Size.Icon._M}
            />
          </Flex>
        )}
      </Flex>
    </NotificationCSS>
  );
};

export const SendMessage = ({ onClick, isLoading, disabled }) => {
  return (
    <SendMessageCSS>
      <Button
        variant="Primary"
        onClick={onClick}
        text="Send"
        size="_S"
        disabled={disabled}
        isLoading={isLoading}
      />
    </SendMessageCSS>
  );
};
