import { Route, Routes } from 'react-router-dom';
    import AdminCategories from './AdminCategories';
    
    export const AdminCategoriesRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<AdminCategories />} />
        </Routes>
      );
    };
    