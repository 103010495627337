import { Constants } from '../../../constants';

export const formatStatusShares = shares => {
  const status = [
    Constants.Status.Seized,
    Constants.Status.Released,
    Constants.Status.Stopped,
    Constants.Status['Non-counterfeit'],
    Constants.Status.Counterfeit,
    Constants.Status.Authentication,
    Constants.Status.Inspection,
  ];

  const inputShares = status.map(status => {
    return {
      status,
      percentage: Math.round((shares?.[status] ?? 0) * 100),
    };
  });

  return inputShares;
};
