import * as Yup from 'yup';

export const dropdownValidation = Yup.object().shape({
  label: Yup.mixed().notRequired(),
  value: Yup.string().required('Please select an option from the dropdown'),
});

export const dropdownValidationUnrequired = Yup.object().shape({
  label: Yup.mixed().notRequired(),
  value: Yup.string().notRequired(),
});

export const yyymmddValidation = Yup.string()
  .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format 'yyyy-mm-dd'")
  .test('is-date', 'Invalid Date', value => {
    try {
      const date = new Date(value);
      return date && value === date.toISOString().split('T')[0];
    } catch {
      return false;
    }
  });
