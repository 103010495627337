import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects, Layout, Size } from '../../style';

const constantStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: Layout.Spacing._2XS,
  selection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: Effects.Border._M,
    borderRadius: Effects.Border_Radius.Circle,
    borderStyle: 'solid',
  },
  selectionDot: {
    width: '4px',
    height: '4px',
    borderRadius: Effects.Border_Radius.Circle,
    background: Colors.Sharp.White,
  },
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: (state, { selected, size, disabled }) => {
    const { hover } = state;
    return {
      cursor: disabled ? 'auto' : 'pointer',
      selection: {
        selected,
        borderColor: selected ? Colors.Primary._600 : Colors.Neutral._300,
        background: disabled
          ? Colors.Sharp.White
          : selected
          ? Colors.Primary._600
          : hover
          ? Colors.Neutral._300
          : Colors.Sharp.White,
        ...Size.RadioButton[size],
      },
    };
  },
};
