import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { PropKeys, Utils } from '../../../../../constants';
import { flow } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { openNewTab } from '../../../../../utils/utils';
import { useGetCasesImages } from '../../../features/cases/api/getCaseImages';
import { useGetCases } from '../../../features/cases/api/getCases';
import {
  createTicket,
  useCreateTicket,
} from '../../../features/support/api/createTicket';
import {
  TicketConstants,
  createTicketFiltersConstants as filtersConstants,
  ticketInitialFormValues,
} from '../../../features/support/controllers/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { caseKeys } from '../../cases/controllers/variables';
import { useGetProducts } from '../../products/api/getProducts';
import { useGetProductsImage } from '../../products/api/getProductsImage';
import { matchImagesTo } from '../../products/controllers/utils';
import { productKeys } from '../../products/controllers/variables';
import { CreateTicketLayout } from '../components/CreateTicketLayout';
import {
  DC,
  searchComponents,
  searchGrid,
  searchHeaders,
  ticketTypes,
} from './variables';

const {
  SELF: SUPPORT,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const permissionType = {
  [ticketTypes.Parcel]: CREATE_PARCEL_SUPPORT_TICKETS,
  [ticketTypes.Product]: CREATE_PRODUCT_SUPPORT_TICKETS,
};

const CreateTicket = ({
  goBack,
  productData: preselectedProduct,
  productTitle,
  type,
  onCreateTicket = Utils.trueReturn,
  newTab = false,
}) => {
  const [search, setSearch] = useState('');
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState('Normal');
  const [details, setDetails] = useState('');

  const [customTitle, setCustomTitle] = useState('');

  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(preselectedProduct);

  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError } = useSnackbar();

  const { store } = useStore();
  const { permissions } = usePermissions();

  const [brandId, setBrandId] = useState(store[PropKeys.brandId]);

  const { doCreateTicket, isLoading, isError, error, data, body } =
    useCreateTicket();

  useEffect(() => {
    if (type !== ticketTypes.Product) return;
    const actingBrandId = getProductAccessId(selectedProduct);
    setBrandId(actingBrandId);
  }, [selectedProduct]);

  const getProductAccessId = product => {
    if (!product) return '';
    const {
      [productKeys.brand]: productBrandId,
      [productKeys.brandId]: productBrandId2,
      [productKeys.sorterId]: productSorterId,
      soBrandID,
    } = product;

    const hasBrandSupportPermission =
      permissions[productBrandId || productBrandId2]?.[SUPPORT][
        permissionType[type]
      ];

    const hasSOIDSupportPermission =
      permissions[productSorterId]?.[SUPPORT][permissionType[type]];

    const hasSOBrandIDSupportPermission =
      permissions[soBrandID]?.[SUPPORT][permissionType[type]];

    if (hasBrandSupportPermission) return productBrandId || productBrandId2;
    if (hasSOIDSupportPermission) return productSorterId;
    if (hasSOBrandIDSupportPermission) return soBrandID;
  };

  const handleCreateTicket = () => {
    createTicket({
      type,
      selectedProduct,
      title:
        selectedTitle === TicketConstants.Titles.otherTicket.value
          ? customTitle
          : selectedTitle,
      customTitle,
      selectedPriority,
      details,
      brandId,
      doCreateTicket,
    });
  };

  const getItems = type => {
    if (type === ticketTypes.Parcel) return useGetCases;
    if (type === ticketTypes.Product) return useGetProducts;
  };

  const dataKey = type => {
    if (type === ticketTypes.Parcel) return 'cases';
    if (type === ticketTypes.Product) return 'products';
  };

  const {
    data: { [dataKey(type)]: products },
  } = getItems(type)({
    searchParams: {
      [DC.SEARCH]: search,
      [DC.PAGE]: 1,
      [DC.ITEMS_PER_PAGE]: 10,
      [DC.BRAND]: [
        {
          options: [{ value: brandId, selected: ticketTypes.Parcel === type }],
        },
      ],
      include_sorter_cases: ticketTypes.Parcel === type,
    },
    ignore: (ticketTypes.Parcel === type && !brandId) || !!preselectedProduct,
  });

  const getItemsImageApi = type => {
    if (type === ticketTypes.Parcel) return useGetCasesImages;
    if (type === ticketTypes.Product) return useGetProductsImage;
  };

  const getItemsImageKey = type => {
    if (type === ticketTypes.Parcel) return 'getCasesImages';
    if (type === ticketTypes.Product) return 'getProductsImage';
  };

  const imageDataKey = type => {
    if (type === ticketTypes.Parcel) return 'cases';
    if (type === ticketTypes.Product) return 'products';
  };

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    [getItemsImageKey(type)]: getItemsImage,
  } = getItemsImageApi(type)();

  const titleInputRef = useRef(null);

  useEffect(() => {
    if (search.length) setShowResults(true);
  }, [search]);

  useDeepCompareEffect(() => {
    setResults(products);
    if (products?.length) getItemsImage({ [imageDataKey(type)]: products });
  }, [products]);

  useEffect(() => {
    if (imagesIsError) {
      showSnackbarError(imagesError);
      setResults(matchImagesTo(type)([]));
      return;
    }

    if (products?.length && images != null)
      setResults(matchImagesTo(type)(images));
  }, [images]);

  useEffect(() => {
    if (selectedTitle === 'Other') titleInputRef?.current?.focus();
  }, [selectedTitle]);

  useEffect(() => {
    const isValid =
      selectedProduct &&
      selectedPriority &&
      validSelectedTitle(selectedTitle, customTitle) &&
      details &&
      brandId &&
      !isLoading;
    setIsValid(isValid);
  }, [
    selectedProduct,
    selectedTitle,
    customTitle,
    selectedPriority,
    details,
    isLoading,
    brandId,
  ]);

  const validSelectedTitle = (selectedTitle, customTitle) =>
    customTitle.trim().length > 3 ||
    (selectedTitle !== TicketConstants.Titles.otherTicket.label &&
      selectedTitle != null);

  const updateBrand = ({ value }) => setBrandId(value);

  useEffect(() => {
    if (isLoading || data == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    createTicketSuccess({ data, body });
  }, [isLoading, isError]);

  const createTicketSuccess = ({
    data: ticketId,
    body: { reference_id: productId },
  }) => {
    const navigateAfterCreation = onCreateTicket({ ticketId, productId });
    if (!navigateAfterCreation) return;

    const to = `${NavURIs[Pages.support]}/${ticketId}`;
    newTab ? openNewTab(to) : navigate(to);
    dismissAll();
  };

  const handleOtherChange = e => {
    const { value } = e.target;
    setCustomTitle(value);
  };

  const detailsChangeHandler = e => {
    const { value } = e.target;
    setDetails(value);
  };

  // TODO: get all keys somewhere else
  const getAliasKey = type => {
    if (type === ticketTypes.Parcel) return caseKeys.alias;
    if (type === ticketTypes.Product) return productKeys.productAlias;
  };

  const selectProduct = setFieldValue => selectedProduct => {
    setFieldValue([DC.SEARCH], selectedProduct[getAliasKey(type)]);
    setSelectedProduct(selectedProduct);
    setShowResults(false);

    resetBrandIdIfUnavailable(selectedProduct, setFieldValue);
  };

  const resetBrandIdIfUnavailable = (selectedProduct, setFieldValue) => {
    if (type === ticketTypes.Product) return;

    const isInAllowedBrands =
      actingBrandFilter([{ value: brandId }], selectedProduct).length === 1;
    if (!isInAllowedBrands) setFieldValue('brand', null);
  };

  const filtersState = {
    [DC.SEARCH]: {
      filter: setSearch,
      value: search,
      name: [DC.SEARCH],
    },
  };

  const filters = preselectedProduct
    ? []
    : mergeFilterStateAndConsts({
        filtersState,
        filtersConstants: filtersConstants(type),
      });
  const selectTitle = v => _ => setSelectedTitle(v);
  const selectPriority = v => _ => setSelectedPriority(v);

  const hideResults = () => setShowResults(false);

  const handleSearchIsClicked = isClicked => setShowResults(isClicked);

  const actingBrandFilter = (brands, parcel = selectedProduct) => {
    const filterPermission = brands =>
      brands.filter(({ value }) => {
        const hasBrandSupportPermission =
          permissions[value]?.[SUPPORT][permissionType[type]];
        return hasBrandSupportPermission;
      });

    const filterByParcelProductsOrSorter = brands =>
      brands.filter(
        ({ value }) =>
          parcel[caseKeys.products].some(
            ({ [productKeys.brandId]: brandId }) => brandId === value
          ) ||
          parcel[caseKeys.sorterId] === value ||
          parcel.soBrandID === value
      );

    const filters = [filterPermission];
    if (type === ticketTypes.Parcel && parcel)
      filters.unshift(filterByParcelProductsOrSorter);

    return flow(filters)(brands);
  };

  const filterListeners = [selectedProduct];

  return (
    <Formik initialValues={ticketInitialFormValues}>
      {({ setFieldValue, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });
        return (
          <CreateTicketLayout
            actingBrandProps={{
              selectOption: props => () => {
                selectOption(props)();
                updateBrand(props);
              },
              value: values['brand'],
              filterListeners,
            }}
            onSearchIsClicked={handleSearchIsClicked}
            hideResults={hideResults}
            title={TicketConstants.Titles.createTicket}
            goBack={goBack}
            filters={filters}
            titles={TicketConstants.Titles.newTicket}
            selectTicketTitle={'How can we help you?'}
            selectTitle={selectTitle}
            selectedTitle={selectedTitle}
            productTitle={productTitle}
            handleOtherChange={handleOtherChange}
            otherMaxLength={TicketConstants.Titles.otherMaxLength}
            selectPriorityTitle={TicketConstants.Titles.selectPriority}
            priorityOptions={TicketConstants.priorityOptions}
            selectPriority={selectPriority}
            chosenPriority={selectedPriority}
            moreDetailsTitle={TicketConstants.Titles.moreDetails}
            detailsChangeHandler={detailsChangeHandler}
            moreDetailsPlaceholder={
              TicketConstants.Titles.moreDetailsPlaceholder
            }
            cancel={dismiss}
            cancelText={TicketConstants.Actions.cancel}
            isValid={isValid}
            createTicket={handleCreateTicket}
            createTicketText={TicketConstants.Actions.createTicket}
            results={results}
            showResults={showResults}
            selectProduct={selectProduct(setFieldValue)}
            selectedProduct={selectedProduct}
            isLoading={isLoading}
            searchHeaders={searchHeaders[type]}
            searchComponents={searchComponents[type]}
            searchGrid={searchGrid[type]}
            type={type}
          />
        );
      }}
    </Formik>
  );
};

export default Modal(CreateTicket);
