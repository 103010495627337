import { useEffect, useState } from 'react';
import { Constants } from '../../../constants';
import { StatusColors } from '../../style';
import { ProgressBar, Track, initialShares } from './StatusShares.style';

export const StatusShares = ({
  shares: inputShares,
  size = '_L',
  maxWidth,
}) => {
  const [shares, setShares] = useState(initialShares);

  useEffect(() => {
    if (
      inputShares == null ||
      inputShares.every(({ percentage }) => !percentage)
    ) {
      setShares([{ status: Constants.Status.Gray, percentage: 100 }]);
      return;
    }

    let remaining = 100;
    const realShares = inputShares.map(({ status, percentage }) => {
      const progress = { status, percentage: remaining };
      remaining -= percentage;
      return progress;
    });

    setShares(realShares);
  }, [inputShares]);

  if (shares == null) {
    return (
      <Track size={size} maxWidth={maxWidth}>
        <ProgressBar color={StatusColors.Gray} percentage={100} size={size} />
      </Track>
    );
  }

  return (
    <Track size={size} maxWidth={maxWidth}>
      {shares.map(({ status, percentage }, index) => (
        <ProgressBar
          color={StatusColors[status]}
          percentage={percentage}
          size={size}
          key={index}
          isLast={index === shares.length - 1}
        />
      ))}
    </Track>
  );
};
