import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { DC, whiteLabelKeys } from '../controllers/variables';

export const useSearchWhiteLabel = ({ searchParams }) => {
  const [{ data = [], isLoading, isError, error }, _, findParcel, refresh] =
    useFetchData('parcels/whitelabel_search', {
      formatter: data => maskEntry(data, mask),
      failureFormatter: () => [],

      params: formatParams(searchParams),
      ignore: !searchParams[DC.SEARCH],
    });

  return {
    data: { parcels: data, parcelCount: data.length },
    isLoading,
    isError,
    error,
    findParcel: params => findParcel({ params: formatParams(params) }),
    refresh,
  };
};

export const formatParams = params => {
  const { SEARCH: search, PAGE, ITEMS_PER_PAGE } = params;

  const formattedParams = {
    barcode: search,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

const mask = {
  barcodes: whiteLabelKeys.barcodes,
  paSideloadDate: whiteLabelKeys.sideloadDate,
  paID: whiteLabelKeys.id,
  photos: whiteLabelKeys.images,
};
