import styled from '@emotion/styled';
import { Effects, Layout } from '../../style';

export const AddIconCSS = styled.div`
  position: absolute;
  top: ${({ top }) => top ?? Layout.Spacing._S};
  right: ${({ right }) => right ?? Layout.Spacing._S};
  transform: rotate(${props => (props.active ? '-45deg' : 0)});
  transition: ${Effects.Transition.EaseInOut()};
`;
