import { useUI } from '../../../../../providers/UIProvider';
import { AuthenticationGalleryLayout } from '../components/AuthenticationGalleryLayout';

const AuthenticationGallery = props => {
  const {
    authSliderState: { productsBeingAuthenticated: products, isLoading },
  } = props;

  const { sidebarWidth } = useUI().UI;

  const productsAreSet = products != null && products.length;
  if (!isLoading && !productsAreSet) return null;
  return <AuthenticationGalleryLayout {...props} sidebarWidth={sidebarWidth} />;
};

export default AuthenticationGallery;
