import Button from '../../../../components/molecules/Button/Button';
import { EditOptionsCentering, EditOptionsCSS } from '../style';

export const EditAvatarOptions = ({ options }) => {
  return (
    <EditOptionsCSS className="edit-options">
      <EditOptionsCentering>
        {options.map(option => (
          <EditOption {...option} key={option.text} />
        ))}
      </EditOptionsCentering>
    </EditOptionsCSS>
  );
};

const EditOption = ({ icon, text, onClick, disabled }) => {
  return (
    <Button
      variant="Tertiary"
      black
      onClick={onClick}
      size="_S"
      leftIcon={icon}
      text={text}
      disabled={disabled}
    />
  );
};
