import { howLongAgo } from '../../../utils/timeUtils';
import { maskEntry } from '../../../utils/utils';
import { notificationKeys } from '../controllers/variables';

export const formatNotifications = data => {
  maskEntry(data, mask);
  data.forEach(datum => {
    datum[notificationKeys.date] =
      howLongAgo(datum[notificationKeys.date]) || datum[notificationKeys.date];
  });
  return data;
};

const mask = {
  noTitle: notificationKeys.title,
  noContent: notificationKeys.description,
  createdAt: notificationKeys.date,
  nodStatus: notificationKeys.read,
  brImage: notificationKeys.userImage,
  brID: notificationKeys.brandId,
  nodLink: notificationKeys.link,
};

export const markAllNotificationsReadUI = data => {
  return data.map(notification => {
    return { ...notification, [notificationKeys.read]: 'Read' };
  });
};
