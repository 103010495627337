import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import {
  Container,
  FillCardContainer,
  RowGapContainer,
  Title,
} from './Card.style';

export const Card = ({
  type = 'card',
  title,
  color,
  background,
  padding,
  lineWidth,
  columns,
  children,
  noShadow,
  className,
  icon,
  onClick = () => {},
  // would we rather allow for custom style override,
  // or should we constrain customizable properties
  //    see: atoms/Text.jss
  style: customStyle = {},
  comingSoon,
  separator,
}) => {
  return (
    <Container
      color={color}
      background={background}
      padding={padding}
      lineWidth={lineWidth}
      columns={columns}
      noShadow={noShadow}
      className={`card ${className ? className : ''}`}
      hasTitle={!(title == null || title === '')}
      onClick={onClick}
      style={customStyle}
      type={type}
    >
      {title ? (
        <Title
          type={type}
          text={title}
          columns={columns}
          comingSoon={comingSoon}
          separator={separator}
          icon={comingSoon ? <ComingSoon /> : icon}
        />
      ) : (
        ''
      )}

      {children}
    </Container>
  );
};

export const FillCard = ({ columns = 12, children }) => {
  return (
    <FillCardContainer className="fill-card" columns={columns}>
      {children}
    </FillCardContainer>
  );
};

export const RowGap = ({ columns = 12, height }) => (
  <RowGapContainer columns={columns} height={height} />
);
