import { Form, Formik } from 'formik';
import { uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { yyymmddValidation } from '../../../../../utils/validationUtils';
import { useAddPlan } from '../api/addPlan';
import { useGetPlans } from '../api/getPlans';
import { AddPlanLayout } from '../components/AddPlanLayout';
import { brandManagementKeys as keys } from './variables';

const fieldNames = {
  start: 'start_date',
  end: 'end_date',
  note: 'note',
};

const fields = [
  {
    key: uniqueId(),
    name: fieldNames.start,
    label: 'Start',
    placeholder: 'yyyy-mm-dd',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.end,
    label: 'End',
    placeholder: 'yyyy-mm-dd',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.note,
    label: 'Note',
    placeholder: 'Add a short note',
    size: '_M',
  },
];

const addPlanInitialValues = {};
fields.forEach(field => (addPlanInitialValues[field.name] = ''));

const addPlanValidationSchema = Yup.object().shape({
  [fieldNames.start]: yyymmddValidation.required('Required'),
  [fieldNames.end]: yyymmddValidation.required('Required'),
  [fieldNames.note]: Yup.string()
    .max(300, 'Description is too long')
    .notRequired(''),
});

const mapFieldsToResponse = {
  [fieldNames.start]: keys.plan.start,
  [fieldNames.end]: keys.plan.end,
  [fieldNames.note]: keys.plan.note,
};

const AddPlan = ({ onSuccess, mode = 'add', plan }) => {
  const isEditing = mode === 'edit';
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(-1);

  const { brandId } = useParams();

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const {
    data: plans,
    isLoading: plansIsLoading,
    isError: plansIsError,
  } = useGetPlans();

  const { data, body, isLoading, isError, error, doAddPlan } = useAddPlan({
    isEditing,
  });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess(isEditing ? 'User saved' : 'User added');
    onSuccess();
  }, [body, isError, isLoading]);

  useEffect(() => {
    if (plansIsLoading || !(isEditing && plan != null)) return;
    setSelectedPlanIndex(
      plans.findIndex(p => p.id === plan[keys.plan.planTypeId])
    );
  }, [plans]);

  const onSubmit = values => {
    const url = isEditing
      ? `admin_brand/${brandId}/subscription_plan/${plan[keys.plan.rowId]}`
      : `admin_brand/${brandId}/subscription_plans`;
    doAddPlan({ ...values, plan_id: plans[selectedPlanIndex].id }, url);
  };
  const selectPlan = index => () => setSelectedPlanIndex(index);
  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: plan,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const nonFormikDirty = () => {
    if (isEditing) {
      const oldPlanIndex = plans.findIndex(
        p => p.name === plan[keys.plan.name]
      );
      if (oldPlanIndex !== selectedPlanIndex) return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={withPrevData(addPlanInitialValues)}
      validationSchema={addPlanValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <AddPlanLayout
              isEditing={isEditing}
              planDetailsProps={{
                fields,
              }}
              planListProps={{
                plans,
                selected: selectedPlanIndex,
                selectPlan,
              }}
              actionsProps={{
                disabled:
                  !isValid ||
                  isLoading ||
                  selectedPlanIndex < 0 ||
                  !(dirty || nonFormikDirty()),
                primaryText: isEditing ? 'Save' : 'Add',
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddPlan);
