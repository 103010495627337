import { useEffect, useState } from 'react';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { GalleryPages, GalleryProps } from '../../../../admin/constants';
import { useConfirmationAction } from '../api/confirmationAction';
import { ProcessingInstructionLayout } from '../components/ProcessingInstructionLayout';
import ParcelInspection from './ParcelInspection';
import { processingKeys } from './variables';

const {
  OFFICE_PARCEL_PROCESSING: { SELF, VIEW_PARCELS },
} = PermissionKeys.Facilitator;

const ProcessingInstruction = ({
  isLoading,
  parcelIsLoading,
  parcelIsError,
  parcelError,
  details,
  parcel,
  getParcel,
  setAtInspection,
  atInspection,
  onCompleteInspectionSuccess,
}) => {
  const [updatedConfirm, setUpdatedConfirm] = useState({});
  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { hasPermission } = usePermissions();

  const hasViewParcelPermission = hasPermission(SELF, VIEW_PARCELS);

  const {
    data,
    body,
    isLoading: confirmIsLoading,
    isError,
    error,
    doConfirm,
  } = useConfirmationAction({ id: parcel?.[processingKeys.id] });

  useEffect(() => {
    if (!hasViewParcelPermission) dismiss();
  }, []);

  useEffect(() => {
    if (body == null || confirmIsLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess('Confirmation successful');
    setUpdatedConfirm(x => ({ ...x, [body.index]: data }));
  }, [body, confirmIsLoading, isError]);

  const showParcelInspection = _ => {
    if (parcelIsError) {
      showSnackbarError(parcelError);
      dismiss();
      return;
    }
    showModal(
      ParcelInspection,
      {
        parcel,
        getParcel,
        parcelIsLoading,
        onCompleteInspectionSuccess,
        setAtInspection,
      },
      { xDismiss: true, ignoreOverlayDismiss: true, avoidScale: true }
    );
    // atInspection gets passed in a closure. if true, show inspection and allow parent to reset the state.
    // otherwise we will always change state to true and always go directly to inspection view
    if (!atInspection) setAtInspection(true);
  };

  useEffect(() => {
    if (atInspection) showParcelInspection();
  }, []);

  const showGallery = id => () => {
    showModal(
      Gallery,
      {
        ...GalleryProps[GalleryPages.parcels],
        id,
        title: `Parcel ${parcel[processingKeys.alias]}`,
      },
      { keepPrev: true }
    );
  };

  // instructionAssets in variables.js selects which function to use by declaring any key allowed here
  const functions = {
    dismiss: () => dismiss(),
    details: () => showParcelInspection(),
  };

  const confirmationAction =
    (action_key, { index }) =>
    () => {
      const body = {
        action_key,
        index,
      };
      doConfirm(body);
    };

  return (
    <ProcessingInstructionLayout
      isLoading={isLoading}
      details={details}
      functions={functions}
      confirmationAction={confirmationAction}
      parcel={parcel}
      showGallery={showGallery}
      hasViewParcelPermission={hasViewParcelPermission}
      updatedConfirm={updatedConfirm}
    />
  );
};

export default Modal(ProcessingInstruction);
