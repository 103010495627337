import { useLayoutEffect } from 'react';
import Modal from '../../../components/templates/Modal/Modal';
import { Constants, Utils } from '../../../constants';
import { useFileUpload } from '../../../providers/FileUploadProvider';
import { useModal } from '../../../providers/ModalProvider';
import { AttachmentUploadLayout } from './AttachmentUploadLayout';
import { attachmentUploadDescription, uploadStatusContent } from './variables';

const AttachmentUpload = ({
  route,
  folder,
  id,
  onUploadSuccess = Utils.emptyFunction,
}) => {
  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();
  const { dismiss, dismissAll } = useModal();

  useLayoutEffect(() => {
    resetFileUpload();
    return () => resetFileUpload();
  }, []);

  const onPrimaryClick = () => {
    if (fileState.uploadSuccess) resetFileUpload();
    else
      handleImport({
        folder,
        url: `${route}/${id}/attachments`,
        parameterName: 'names',
        successCallback: onUploadSuccess,
      });
  };

  const backToAdd = () => {
    resetFileUpload();
    dismiss();
  };

  const handleDismiss = () => dismissAll();

  return (
    <AttachmentUploadLayout
      backToAdd={backToAdd}
      disabled={
        !fileState.files || fileState.errorType || fileState.isUploading
      }
      fileState={fileState}
      handleDrop={handleDrop(Constants.FileUpload.uploadType.attachments)}
      infoIcon={attachmentUploadDescription}
      onCancel={handleDismiss}
      primaryClick={onPrimaryClick}
      percentageUploaded={percentage}
      saveText={'Upload'}
      title={'Add attachments'}
      uploadStatusContent={uploadStatusContent({
        fileState,
        resetFileUpload,
      })}
    />
  );
};

export default Modal(AttachmentUpload);
