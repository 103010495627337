import styled from '@emotion/styled';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors } from '../../style';

const constantStyle = {};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const colorThemes = {
  main: {
    activeColor: Colors.Primary._800,
    hoverColor: Colors.Primary._700,
    color: Colors.Neutral._900,
  },
  neutral: {
    activeColor: Colors.Neutral._600,
    hoverColor: Colors.Neutral._700,
    color: Colors.Neutral._900,
  },
  hidden_neutral: {
    activeColor: Colors.Neutral._900,
    hoverColor: Colors.Neutral._900,
    color: 'transparent',
  },
  light: {
    activeColor: Colors.Neutral._300,
    hoverColor: Colors.Neutral._200,
    color: Colors.Neutral._400,
  },
  hidden_light: {
    activeColor: Colors.Neutral._400,
    hoverColor: Colors.Neutral._400,
    color: 'transparent',
  },
  header_icon: {
    activeColor: Colors.Primary._600,
    hoverColor: Colors.Neutral._900,
    color: Colors.Neutral._900,
  },
  dashboard_icon: {
    activeColor: Colors.Neutral._300,
    hoverColor: Colors.Neutral._900,
    color: Colors.Neutral._400,
  },
  popup: {
    activeColor: Colors.Primary._800,
    hoverColor: Colors.Primary._700,
    color: Colors.Primary._600,
  },
};

export const ActiveIconThemes = {};
Object.keys(colorThemes).forEach(key => (ActiveIconThemes[key] = key));

const Recipes = {
  default: ({ hover }, { active, theme = 'main', disabled }) => {
    const { activeColor, hoverColor, color } = colorThemes[theme];
    return {
      color: active || disabled ? activeColor : hover ? hoverColor : color,
    };
  },
};

export const Container = styled.div`
  position: relative;
  cursor: ${props =>
    props.isClickable && !props.disabled ? 'pointer' : 'inherit'};
`;

export const ClickableArea = styled.div`
  position: absolute;
  inset: 0;
  width: 200%;
  height: 200%;
  margin: auto;
  left: -50%;
`;
