import styled from '@emotion/styled';
import SlideDots from '../../molecules/SlideDots/SlideDots';
import { Layout } from '../../style';

export const Dots = styled(SlideDots)`
  position: absolute;
  bottom: ${Layout.Spacing._M};
  left: 0;
  right: 0;
  height: unset !important;
`;
