import { Flex } from '../../atoms/Flex/Flex';
import Actions from '../../organisms/Actions/Actions';
import { Popup } from '../Popup';
import { ModalSectionTitle, StepContent } from './style';

export const StepPopup = ({
  title,
  sectionTitle,
  onPrimary,
  onCancel,
  onBack,
  onDelete,
  primaryText,
  disabled,
  hidePrimary,
  isLoading,
  children,
}) => {
  return (
    <Popup title={title} size="_M">
      <StepContent>
        <ModalSectionTitle text={sectionTitle} />
        <Flex w grow column gap="_M">
          {children}
          <Actions
            onPrimary={onPrimary}
            primaryText={primaryText}
            onCancel={onCancel}
            onBack={onBack}
            onDelete={onDelete}
            disabled={disabled}
            hidePrimary={hidePrimary}
            isLoading={isLoading}
          />
        </Flex>
      </StepContent>
    </Popup>
  );
};
