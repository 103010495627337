import { Popup } from '../../../../../components/templates/Popup';
import { AddServiceActions, ServicesList } from './organisms';
import { ServiceContent } from './style';

export const AddServiceLayout = ({ listProps, actionsProps }) => {
  return (
    <Popup size={'_M'} title={'Add service'}>
      <ServiceContent>
        <ServicesList {...listProps} />
        <AddServiceActions actionsProps={actionsProps} />
      </ServiceContent>
    </Popup>
  );
};
