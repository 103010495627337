import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Separator } from '../../atoms/Separator/Separator';
import Text from '../../atoms/Text/Text';
import { Colors, Effects, FontKeys, Layout } from '../../style';

export const Container = styled.div`
  position: relative;
  flex-direction: column;
  display: ${props => (props.columns ? 'grid' : 'flex')};
  grid-template-columns: repeat(
    ${props => props.columns},
    minmax(min(1px, 100%), 1fr)
  );
  ${props => (props.hasTitle ? 'grid-template-rows: 24px;' : '')}
  gap: ${props =>
    props.type === 'form' ? Layout.Spacing._M : Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${props => (props.noShadow ? '' : Effects.Box_Shadow._M)};
  padding: ${props =>
    props.noShadow ? 0 : props.padding ?? Layout.Spacing._S};
  ${props => withLeftbarColor(props)};
  ${({ style }) => style};
`;

export const FillCardContainer = styled.div`
  grid-column: 1 / ${props => props.columns + 1};
`;

export const RowGapContainer = styled.div`
  height: ${props => props.height ?? Layout.Spacing._S};
  grid-column: 1 / ${props => props.columns + 1};
`;

const textStyles = {
  card: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._L,
    weight: FontKeys.Weight.Semibold,
  },
  interactive: {
    variant: FontKeys.Variant.Heading,
    size: FontKeys.Size.H5,
    weight: FontKeys.Weight.Semibold,
  },
  form: {
    variant: FontKeys.Variant.Heading,
    size: FontKeys.Size.H4,
    weight: FontKeys.Weight.Semibold,
    alignment: 'center',
  },
};

const TitleCSS = styled.div`
  ${props => (props.columns ? `grid-column: 1 / ${props.columns + 1};` : '')}
  justify-content: ${props =>
    props.type === 'form' ? 'center' : 'space-between'};
  position: relative;
  padding-bottom: ${props => (props.separator ? Layout.Spacing._S : '')};
  ${Styles.flex};
`;

const Icon = styled.div`
  grid-column: ${props => props.columns};
  justify-self: flex-end;
`;

export const Title = ({ text, type, columns, icon, comingSoon, separator }) => {
  if (text == null || text === '') return;

  return (
    <TitleCSS
      columns={columns}
      type={type}
      separator={separator}
      className="card-title"
    >
      <Text
        text={text}
        {...textStyles[type]}
        color={
          comingSoon
            ? Colors.Neutral._500
            : type === 'form' || type === 'interactive'
            ? Colors.Primary._600
            : Colors.Primary._800
        }
      />
      <Icon columns={columns}>{icon}</Icon>
      {separator && <Separator />}
    </TitleCSS>
  );
};

// TODO: add safeValue for colors, imo with a StatusColors object given that
// cards in our design will only use those colors
export const withLeftbarColor = ({
  color = Colors.Sharp.White,
  background = Colors.Sharp.White,
  lineWidth = Layout.Spacing._2XS,
}) => {
  return {
    background: `linear-gradient(to right, ${color} 0px ${lineWidth}, 
        ${background} ${lineWidth})`,
  };
};
