import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { useAddCategory } from '../api/addCategory';
import { AddCategoryLayout } from '../components/organisms/AddCategoryLayout';
import { adminCategoriesKeys as keys } from './variables';

const fieldNames = {
  category: 'name',
};

const fields = [
  {
    name: fieldNames.category,
    label: 'Category',
    placeholder: 'Enter a category name',
    size: '_M',
  },
];

const initialValues = {};
fields.forEach(field => (initialValues[field.name] = ''));

const validationSchema = Yup.object().shape({
  [fieldNames.category]: Yup.string().required('Required'),
});

const mapFieldsToResponse = {
  [fieldNames.category]: keys.category.name,
};

const AddCategory = ({ mode = 'add', prevData, onSuccess }) => {
  const isEditing = mode === 'edit';

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { body, isLoading, isError, error, doAddCategory } = useAddCategory({
    mode,
  });

  const onSubmit = values => {
    const url = isEditing
      ? `admin_category/${prevData[keys.category.id]}`
      : `admin_categories/create`;

    doAddCategory(values, url);
  };

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    dismissAll();
    onSuccess();
  }, [isLoading, body, isError]);

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };
  return (
    <Formik
      initialValues={withPrevData(initialValues)}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <AddCategoryLayout
              isEditing={isEditing}
              fieldProps={{
                fields,
              }}
              actionsProps={{
                onBack: !isEditing && dismiss,
                onCancel: () => dismissAll(),
                primaryText: isEditing ? 'Save' : 'Add',
                disabled: !isValid || !dirty,
                isLoading,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(AddCategory);
