import { Card } from '../../../../../components/atoms';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { casesTableComponentsSmall } from '../../../../admin/features_public/products/components/molecules';
import { CasesListSmall } from '../../../features_public/products/components/molecules/molecules';
import {
  components,
  componentTitle,
  infoDescription,
  referenceCasesGrid,
  referenceCasesHeaders,
} from '../controllers/variables';
import { ReferenceCasesContainer } from './style';

export const ReferenceCasesLayout = ({
  cases,
  scrollRef,
  isLoading,
  isError,
  error,
  tableStatusProps,
  rowAction,
  rowHoverIn,
  rowHoverOut,
  hoveredRow,
}) => {
  return (
    <ReferenceCasesContainer>
      <Card
        title={componentTitle[components.cases]}
        icon={<InfoIcon description={infoDescription[components.cases]} />}
        className="reference-cases"
      >
        <TableStatus
          className={'table-status'}
          isLoading={isLoading}
          isError={isError}
          error={error}
          {...tableStatusProps}
          table={
            <CasesListSmall
              showResults={!!cases?.length}
              headers={referenceCasesHeaders}
              results={cases}
              components={casesTableComponentsSmall({ hoveredRow })}
              grid={referenceCasesGrid}
              scrollRef={scrollRef}
              rowAction={rowAction}
              rowHoverIn={rowHoverIn}
              rowHoverOut={rowHoverOut}
              search={false}
            />
          }
          isInCard
        />
      </Card>
    </ReferenceCasesContainer>
  );
};
