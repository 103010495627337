import { parseStringModifiers } from '../../../utils/utils';
import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import Button from '../../molecules/Button/Button';
import { Colors, FontKeys } from '../../style';
import { ActionsCSS, DescriptionCSS, Dot, Dots } from './style';

export const Description = ({ title, content }) => {
  return (
    <DescriptionCSS w grow column gap={'_S'}>
      <Title text={parseStringModifiers(title)} />
      <DescriptionText text={parseStringModifiers(content)} />
    </DescriptionCSS>
  );
};

const Title = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Heading}
    size={FontKeys.Size.H4}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Primary._600}
  />
);

const DescriptionText = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._L}
    weight={FontKeys.Weight.Regular}
  />
);

export const Actions = ({
  step,
  steps,
  stepTo,
  skip,
  isFirst,
  isLast,
  CTATitle,
  handleFinish,
}) => {
  return (
    <ActionsCSS justify={'between'}>
      <div>
        {!isFirst && (
          <Button
            text="Back"
            variant="Tertiary"
            size="_M"
            onClick={stepTo(-1)}
            leftIcon={Icons.ChevronLeft}
          />
        )}
      </div>
      {steps > 1 ? (
        <Dots
          stepTo={stepTo}
          activeIndex={step}
          amount={steps}
          CustomDot={Dot}
        />
      ) : (
        ''
      )}
      <Flex gap="_S">
        {isLast ? null : (
          <Button size="_M" text="Skip" variant="Tertiary" onClick={skip} />
        )}
        <Button
          size="_M"
          text={isLast ? CTATitle ?? 'Got it' : 'Next'}
          variant="Primary"
          onClick={isLast ? handleFinish : stepTo(1)}
          rightIcon={isLast ? null : Icons.ChevronRight}
        />
      </Flex>
    </ActionsCSS>
  );
};
