import { Form, Formik } from 'formik';
import { useEffect, useReducer, useRef } from 'react';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Input, RadioButton } from '../../../../../components/molecules';
import { FeedbackModalText } from '../components';
import { FeedbackModalStyles } from '../components/style';

export default function FeedbackModal({
  values: valuesRef,
  options,
  reducer,
  initialState,
  ...props
}) {
  const [selected, dispatch] = useReducer(reducer, initialState);
  valuesRef.current = selected;

  useEffect(() => {
    changedSelectionRef.current = true;
  }, [selected]);

  const changedSelectionRef = useRef(false);

  const setSelected = id => () => dispatch({ type: 'select', payload: id });

  const content = (
    <Formik initialValues={{ note: '' }}>
      {({ values, resetForm }) => {
        setTimeout(() => {
          if (changedSelectionRef.current) {
            resetForm();
            changedSelectionRef.current = false;
          }
        }, 0);
        valuesRef.current = { ...values, ...selected };
        return (
          <Form>
            <Flex column w h gap="_S">
              <FeedbackModalText text={initialState.subtitle} />
              {options.map((option, i) => {
                const isSelected = selected.value === option.id;
                const { hasNote, ...warningSafeOption } = option;
                return (
                  <Flex w gap="_XS" column>
                    <RadioButton
                      {...warningSafeOption}
                      size="_L"
                      key={i}
                      bold={isSelected}
                      selected={isSelected}
                      setSelected={setSelected}
                    />
                    {isSelected && option.hasNote && (
                      <Input
                        name={'note'}
                        placeholder={'Add a note about this choice'}
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
  return <FeedbackModalStyles {...props} content={content} />;
}
