import { Text } from '../../../../../../components/atoms';
import { Colors, FontKeys } from '../../../../../../components/style';

export const FieldLabel = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const FieldContent = ({ text, color }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      color={color}
    />
  );
};
