import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Popup } from '../../../../../components/templates/Popup';
import { UserServicesPermissions } from '../../../../brand/features/brand_settings/components';
import { AddServiceActions, PersonalDetails } from './organisms';
import { UserContent } from './style';

export const AddUserLayout = ({
  isEditing,
  onNotifyUserToggle,
  shouldNotifyUser,
  personalDetailsProps,
  actionsProps,
  userServicePermissionsProps,
}) => {
  return (
    <Popup size={'_M'} title={isEditing ? 'Edit user' : 'Add user'}>
      <UserContent>
        <Flex scroll w column>
          <Spacer height={'_M'} />
          <PersonalDetails
            isEditing={isEditing}
            personalDetailsProps={personalDetailsProps}
            onNotifyUserToggle={onNotifyUserToggle}
            shouldNotifyUser={shouldNotifyUser}
          />
          <Spacer height={'_M'} />
          <UserServicesPermissions {...userServicePermissionsProps} />
        </Flex>
        <AddServiceActions actionsProps={actionsProps} />
      </UserContent>
    </Popup>
  );
};
