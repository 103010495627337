import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { offsetGrowingBorderPadding } from '../../../utils/styleUtils';
import { Colors, Effects, FontKeys, Layout } from '../../style';

const labelColor = Colors.Primary._800;

export const Container = styled.div`
  cursor: ${props => (props.isClickable ? 'pointer' : 'auto')};
  background: ${({ background }) => background ?? Colors.Sharp.White};
  padding: 0
    ${props =>
      props.noPadding
        ? 0
        : offsetGrowingBorderPadding({
            padding: paddingSize[props.size],
            selected: props.selected,
          })};
  border-radius: ${Effects.Border_Radius._2XL};
  gap: ${Layout.Spacing._2XS};
  height: ${props =>
    `calc(${containerHeight[props.size]} - ${
      props.noPadding ? containerReduction[props.size] : '0px'
    })`};
  border: ${props =>
    props.hasBorder
      ? `${props.selected ? '2px' : '1px'} solid ${
          props.borderColor ?? labelColor
        }`
      : ''};
  ${Styles.flexCenter};
`;

export const styles = ({ size, textColor = labelColor }) => {
  if (!size) throw Error('Missing  size property');
  return {
    text: {
      color: textColor,
      ...fontProperties[size],
    },
    icon: {
      color: textColor,
      size: iconSize[size],
    },
  };
};

const containerHeight = {
  _L: '40px',
  _M: '36px',
  _S: '32px',
  _XS: '24px',
};

const containerReduction = {
  // haven't used. can change
  _L: '12px',
  _M: '12px',
  // haven't used. can change
  _S: '12px',
  // haven't used. can change
  _XS: '8px',
};

const iconSize = { _L: 24, _M: 20, _S: 16, _XS: 12 };

const paddingSize = {
  _L: Layout.Spacing._S,
  _M: Layout.Spacing._S,
  _S: Layout.Spacing._S,
  _XS: Layout.Spacing._XS,
};

const fontProperties = {
  _L: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size['_L'],
    weight: FontKeys.Weight.Semibold,
  },
  _M: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size['_M'],
    weight: FontKeys.Weight.Semibold,
  },
  _S: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size['_S'],
    weight: FontKeys.Weight.Semibold,
  },
  _XS: {
    variant: FontKeys.Variant.Caption,
    size: FontKeys.Size['_M'],
    weight: FontKeys.Weight.Semibold,
  },
};
