import { FieldArray } from 'formik';
import { uniqueId } from 'lodash';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import { Input } from '../../../../../../components/molecules';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { ActiveIconThemes } from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon.style';
import Button from '../../../../../../components/molecules/Button/Button';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  ExpansionArrow,
  RowDateHorizontal,
  RowOptionsMenu,
  RowSimpleText,
  TableCheckBox,
  User,
  runIfHasProducts,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import {
  fieldNames,
  adminServicesKeys as keys,
  permissionInit,
  roleTypes,
} from '../../controllers/variables';
import {
  AddPermisionButtonCSS,
  AddPermisionButtonCustom,
  PermissionCheckBox,
  PermissionGridHeaderCSS,
  PermissionRowCSS,
  PermissionSubtableCSS,
  PermissionTableHeaderText,
  PermissionsGridCSS,
  PermissionsGridHeadersCSS,
} from '../style';

const ServiceName = item => {
  const { text, icon } = item;

  return <Button variant="Tertiary" readOnly text={text} leftIcon={icon} />;
};

export const tableComponents = ({
  expansionGrid,
  expansionHeaders,
  expandCountKey,
  options,
  optionClick,
}) => ({
  expansion: {
    display: ExpansionArrow({ countKey: expandCountKey }),
    action: runIfHasProducts({
      action: Utils.expandRow,
      countKey: expandCountKey,
    }),
  },
  [keys.name]: {
    display: ServiceName,
  },
  [keys.description]: {
    display: RowSimpleText(keys.description),
  },
  [keys.addedAt]: {
    display: RowDateHorizontal(keys.addedAt, { time: false }),
  },
  [keys.addedBy.name]: {
    display: User(keys.addedBy.name, keys.addedBy.image),
  },
  [keys.brands.havingServiceCount]: {
    display: RowSimpleText(keys.brands.havingServiceCount, 'bold'),
  },
  options: RowOptionsMenu({ options, optionClick }),
  expand: PermissionsSubtable({
    headers: expansionHeaders,
    grid: expansionGrid,
    components: permissionSubtableComponents,
  }),
});

const permissionSubtableComponents = {
  [fieldNames.permissions.name]: {
    display: RowSimpleText(fieldNames.permissions.name, 'bold'),
  },
  [fieldNames.permissions.admin]: {
    display: TableCheckBox(fieldNames.permissions.admin, {
      color: Colors.Primary._600,
    }),
  },
  [fieldNames.permissions.editor]: {
    display: TableCheckBox(fieldNames.permissions.editor, {
      color: Colors.Primary._600,
    }),
  },
  [fieldNames.permissions.viewer]: {
    display: TableCheckBox(fieldNames.permissions.viewer, {
      color: Colors.Primary._600,
    }),
  },
  space: {
    display: () => <></>,
  },
};

const PermissionsSubtable =
  ({ headers, grid, components }) =>
  item => {
    if (item == null) return <></>;
    return (
      <PermissionSubtableCSS>
        <Table
          grid={grid}
          headers={headers}
          components={components}
          data={item.permissions}
        />
      </PermissionSubtableCSS>
    );
  };

export const PermissionsGrid = ({ headers, values, isEditing }) => {
  return (
    <Flex w column>
      <PermissionsGridHeadersCSS>
        {headers.map(header => (
          <PermissionGridHeader {...header} />
        ))}
      </PermissionsGridHeadersCSS>
      <PermissionsGridCSS column w>
        <FieldArray
          name={fieldNames.permissions.row}
          render={arrayHelpers => (
            <>
              {values[fieldNames.permissions.row].map((task, index) => (
                <PermissionRow
                  key={uniqueId(index)}
                  index={index}
                  remove={arrayHelpers.remove}
                  isEditing={!task.isNew && isEditing}
                />
              ))}
              <PermissionRowCSS>
                <AddPermisionButton
                  onClick={() => arrayHelpers.push(permissionInit(true))}
                />
              </PermissionRowCSS>
            </>
          )}
        />
      </PermissionsGridCSS>
    </Flex>
  );
};

const PermissionGridHeader = ({ text, center }) => {
  return (
    <PermissionGridHeaderCSS center={center}>
      <PermissionTableHeaderText
        text={text}
        center={center}
        color={Colors.Sharp.White}
      />
    </PermissionGridHeaderCSS>
  );
};

const PermissionRow = ({ index, remove, isEditing }) => {
  const inputs = [
    {
      name: fieldNames.permissions.name,
      placeholder: 'Enter a permission name',
    },
    {
      name: fieldNames.permissions.systemName,
      placeholder: 'Enter a permission ID',
      readOnly: isEditing,
    },
  ];

  return (
    <PermissionRowCSS>
      <Separator />
      <div />
      {inputs.map(input => (
        <Input
          key={uniqueId(index)}
          {...input}
          name={`${fieldNames.permissions.row}[${index}].${input.name}`}
          size="_M"
        />
      ))}
      {roleTypes.map(checkbox => (
        <PermissionCheckBox
          name={`${fieldNames.permissions.row}[${index}].${checkbox}`}
        />
      ))}
      <Flex align={'center'} justify={'center'}>
        <AnimatedIcon
          theme={ActiveIconThemes.dashboard_icon}
          name={Icons.XCircle}
          size={Size.Icon._M}
          onClick={() => remove(index)}
        />
      </Flex>
    </PermissionRowCSS>
  );
};

const AddPermisionButton = ({ onClick }) => {
  return (
    <AddPermisionButtonCSS>
      <AddPermisionButtonCustom
        fullWidth
        text="Add permission"
        leftIcon={Icons.PlusCircle}
        onClick={onClick}
        variant="Secondary"
      />
    </AddPermisionButtonCSS>
  );
};
