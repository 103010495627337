import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { NumberDisplay, NumberPad } from '../organisms';

export const ItemQuantityInput = ({
  values: value,
  typeNumber,
  deleteNumber,
  toggleNumbersSelected,
  numbersSelected,
}) => {
  return (
    <Flex column gap="_XS">
      <NumberDisplay
        value={value}
        deleteNumber={deleteNumber}
        toggleNumbersSelected={toggleNumbersSelected}
        numbersSelected={numbersSelected}
      />
      <NumberPad typeNumber={typeNumber} />
    </Flex>
  );
};
