import { Popup } from '../../../../../components/templates/Popup';
import { EditActions, ReceiverEdit, SenderEdit } from './organisms/organisms';
import { EditWhiteLabelContainer } from './style';

export const EditWhiteLabelLayout = ({
  values,
  title = 'Edit label',
  toggleOnlySenders,
  onlySenders,
  selectOption,
  senderFields,
  receiverFields,
  placeholder,
  fieldProps,
  setTouched,
  cancelButton,
  saveButton,
  printButton,
  disablePrint,
  customerDataOptions,
  isLoading,
}) => {
  return (
    <Popup size="_M" title={title}>
      <EditWhiteLabelContainer>
        <SenderEdit
          field={senderFields}
          values={values}
          toggleOnlySenders={toggleOnlySenders}
          onlySenders={onlySenders}
          selectOption={selectOption}
          placeholder={placeholder}
          fieldProps={fieldProps}
          setTouched={setTouched}
          customerDataOptions={customerDataOptions}
        />
        <ReceiverEdit
          fields={receiverFields}
          selectOption={selectOption}
          placeholder={placeholder}
          fieldProps={fieldProps}
          disabled={onlySenders}
          setTouched={setTouched}
        />
        <EditActions
          disabled={disablePrint}
          cancelButton={cancelButton}
          saveButton={saveButton}
          printButton={printButton}
          isLoading={isLoading}
        />
      </EditWhiteLabelContainer>
    </Popup>
  );
};
