import { API } from '../../lib/api';

export const checkTokenIsValid = async ({ token }) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { data, status, error } = await API.GET('check_password_reset', config);

  if (error || status !== 200) return false;
  return true;
};
