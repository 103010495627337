export const PermissionConsts = {
  Show: 'SHOW',
  allow: 'allow',
};

export const PermissionKeys = {
  Facilitator: {
    OFFICE_PARCEL_PROCESSING: {
      SELF: 'OFFICE_PARCEL_PROCESSING',
      VIEW_PARCELS: 'VIEW_PARCELS',
      EDIT_PARCEL_PRODUCTS: 'EDIT_PARCEL_PRODUCTS',
      EDIT_PARCELS: 'EDIT_PARCELS',
    },
  },
  Brand: {
    BRAND_MANAGEMENT: {
      SELF: 'BRAND_MANAGEMENT',
      VIEW_BRAND_USERS: 'VIEW_BRAND_USERS',
      CREATE_BRAND_USERS: 'CREATE_BRAND_USERS',
      DELETE_BRAND_USERS: 'DELETE_BRAND_USERS',
      EDIT_BRAND_USERS: 'EDIT_BRAND_USERS',
      VIEW_BRAND_CONTRACTS: 'VIEW_BRAND_CONTRACTS',
      VIEW_BRAND_BILLING: 'VIEW_BRAND_BILLING',
      VIEW_BRAND_DETAILS: 'VIEW_BRAND_DETAILS',
      EDIT_BRAND_DETAILS: 'EDIT_BRAND_DETAILS',
    },
    DASHBOARD: {
      SELF: 'DASHBOARD',
      VIEW_DASHBOARD_PRODUCTS: 'VIEW_DASHBOARD_PRODUCTS',
      VIEW_DASHBOARD_REFERENCES: 'VIEW_DASHBOARD_REFERENCES',
    },
    KNOWLEDGE_BASE: {
      SELF: 'KNOWLEDGE_BASE',
      CREATE_KNOWLEDGE_BASE: 'CREATE_KNOWLEDGE_BASE',
      VIEW_KNOWLEDGE_BASE: 'VIEW_KNOWLEDGE_BASE',
      EDIT_KNOWLEDGE_BASE: 'EDIT_KNOWLEDGE_BASE',
      EDIT_KNOWLEDGE_BASE_CHARACTERISTICS:
        'EDIT_KNOWLEDGE_BASE_CHARACTERISTICS',
      DELETE_KNOWLEDGE_BASE_CHARACTERISTICS:
        'DELETE_KNOWLEDGE_BASE_CHARACTERISTICS',
      EDIT_KNOWLEDGE_BASE_ATTACHMENTS: 'EDIT_KNOWLEDGE_BASE_ATTACHMENTS',
      DELETE_KNOWLEDGE_BASE_ATTACHMENTS: 'DELETE_KNOWLEDGE_BASE_ATTACHMENTS',
      EDIT_KNOWLEDGE_BASE_LINKS: 'EDIT_KNOWLEDGE_BASE_LINKS',
      DELETE_KNOWLEDGE_BASE_LINKS: 'DELETE_KNOWLEDGE_BASE_LINKS',
    },
    REFERENCES: {
      SELF: 'REFERENCES',
      VIEW_REFERENCES: 'VIEW_REFERENCES',
      CREATE_REFERENCES: 'CREATE_REFERENCES',
      DELETE_REFERENCES: 'DELETE_REFERENCES',
      EDIT_REFERENCE_INFO: 'EDIT_REFERENCE_INFO',
      EDIT_REFERENCE_NOTES: 'EDIT_REFERENCE_NOTES',
      VIEW_DASHBOARD_REFERENCES: 'VIEW_DASHBOARD_REFERENCES',
      VIEW_REFERENCE_DETAILS: 'VIEW_REFERENCE_DETAILS',
    },
    PRODUCTS: {
      SELF: 'PRODUCTS',
      VIEW_PRODUCTS: 'VIEW_PRODUCTS',
      VIEW_PRODUCTS_PHOTOS: 'VIEW_PRODUCTS_PHOTOS',
      VIEW_PRODUCTS_SUMMARY: 'VIEW_PRODUCTS_SUMMARY',
      VIEW_PRODUCTS_DETAILS: 'VIEW_PRODUCTS_DETAILS',
      EDIT_PRODUCT_NOTES: 'EDIT_PRODUCT_NOTES',
      EDIT_PRODUCT_ATTACHMENTS: 'EDIT_PRODUCT_ATTACHMENTS',
      DELETE_PRODUCT_ATTACHMENTS: 'DELETE_PRODUCT_ATTACHMENTS',
      EDIT_PRODUCT_STATUS: 'EDIT_PRODUCT_STATUS',
      VIEW_DASHBOARD_PRODUCTS: 'VIEW_DASHBOARD_PRODUCTS',
    },
    PARCELS: {
      SELF: 'PARCELS',
      VIEW_PARCELS: 'VIEW_PARCELS',
      VIEW_PARCEL_PHOTOS: 'VIEW_PARCEL_PHOTOS',
    },
    REPORTS: {
      SELF: 'REPORTS',
      VIEW_REPORTS: 'VIEW_REPORTS',
      CREATE_REPORTS: 'CREATE_REPORTS',
    },
    WHITE_LABEL: {
      SELF: 'WHITE_LABEL',
      VIEW_WHITE_LABELS: 'VIEW_WHITE_LABELS',
      CREATE_WHITE_LABELS: 'CREATE_WHITE_LABELS',
      EDIT_WHITE_LABELS: 'EDIT_WHITE_LABELS',
      PRINT_WHITE_LABELS: 'PRINT_WHITE_LABELS',
    },
    ADR: {
      SELF: 'ADR',
      VIEW_ANALYTICS_DANGEROUS_GOODS: 'VIEW_ANALYTICS_DANGEROUS_GOODS',
    },
    LQ: {
      SELF: 'LQ',
      VIEW_ANALYTICS_LQ_DEPOT_AREA: 'VIEW_ANALYTICS_LQ_DEPOT_AREA',
    },
    SORTER_INSIGHTS: {
      SELF: 'SORTER_INSIGHTS',
      VIEW_DETECTION_DETAILS_STATS: 'VIEW_DETECTION_DETAILS_STATS',
      VIEW_PARCEL_COUNT_STATS: 'VIEW_PARCEL_COUNT_STATS',
      VIEW_OVER345_SECONDS_STATS: 'VIEW_OVER345_SECONDS_STATS',
      VIEW_PROCESSING_TIME_STATS: 'VIEW_PROCESSING_TIME_STATS',
      VIEW_COUNTERFEIT_STATS: 'VIEW_COUNTERFEIT_STATS',
      VIEW_RETURN_STATS: 'VIEW_RETURN_STATS',
      VIEW_SHIPPING_LABEL_STATS: 'VIEW_SHIPPING_LABEL_STATS',
    },
    SUPPORT: {
      SELF: 'SUPPORT',
      CREATE_PARCEL_SUPPORT_TICKETS: 'CREATE_PARCEL_SUPPORT_TICKETS',
      VIEW_PARCEL_SUPPORT_TICKETS: 'VIEW_PARCEL_SUPPORT_TICKETS',
      VIEW_OWN_PARCEL_SUPPORT_TICKETS: 'VIEW_OWN_PARCEL_SUPPORT_TICKETS',
      EDIT_PARCEL_SUPPORT_TICKETS: 'EDIT_PARCEL_SUPPORT_TICKETS',
      EDIT_OWN_PARCEL_SUPPORT_TICKETS: 'EDIT_OWN_PARCEL_SUPPORT_TICKETS',

      CREATE_PRODUCT_SUPPORT_TICKETS: 'CREATE_PRODUCT_SUPPORT_TICKETS',
      VIEW_PRODUCT_SUPPORT_TICKETS: 'VIEW_PRODUCT_SUPPORT_TICKETS',
      VIEW_OWN_PRODUCT_SUPPORT_TICKETS: 'VIEW_OWN_PRODUCT_SUPPORT_TICKETS',
      EDIT_PRODUCT_SUPPORT_TICKETS: 'EDIT_PRODUCT_SUPPORT_TICKETS',
      EDIT_OWN_PRODUCT_SUPPORT_TICKETS: 'EDIT_OWN_PRODUCT_SUPPORT_TICKETS',

      CREATE_GENERAL_SUPPORT_TICKETS: 'CREATE_GENERAL_SUPPORT_TICKETS',
      VIEW_GENERAL_SUPPORT_TICKETS: 'VIEW_GENERAL_SUPPORT_TICKETS',
      VIEW_OWN_GENERAL_SUPPORT_TICKETS: 'VIEW_OWN_GENERAL_SUPPORT_TICKETS',
      EDIT_GENERAL_SUPPORT_TICKETS: 'EDIT_GENERAL_SUPPORT_TICKETS',
      EDIT_OWN_GENERAL_SUPPORT_TICKETS: 'EDIT_OWN_GENERAL_SUPPORT_TICKETS',
    },
  },
  Admin: {
    ADMIN_BRANDS: {
      SELF: 'ADMIN_BRANDS',
      VIEW_BRANDS: 'VIEW_BRANDS',
    },
    ADMIN_CATEGORIES: {
      SELF: 'ADMIN_CATEGORIES',
      VIEW_CATEGORIES: 'VIEW_CATEGORIES',
    },
    ADMIN_SERVICES: {
      SELF: 'ADMIN_SERVICES',
      VIEW_SERVICES: 'VIEW_SERVICES',
    },
    ADMIN_SORTERS: {
      SELF: 'ADMIN_SORTERS',
      VIEW_SORTERS: 'VIEW_SORTERS',
    },
    ADMIN_REFERENCES: {
      SELF: 'ADMIN_REFERENCES',
      VIEW_REFERENCES: 'VIEW_REFERENCES',
    },
    OFFICE_DASHBOARD: {
      SELF: 'OFFICE_DASHBOARD',
    },
    OFFICE_REVIEW: {
      SELF: 'OFFICE_REVIEW',
      VIEW_REVIEWS: 'VIEW_REVIEWS',
    },
    OFFICE_PARCELS: {
      SELF: 'OFFICE_PARCELS',
      SHOW: 'SHOW',
      VIEW_PARCELS: 'VIEW_PARCELS',
      EDIT_PARCEL_STATUS_SIDELOAD: 'EDIT_PARCEL_STATUS_SIDELOAD',
      EDIT_PARCEL_STATUS_INSPECTION: 'EDIT_PARCEL_STATUS_INSPECTION',
      EDIT_PARCEL_STATUS_AUTHENTICATION: 'EDIT_PARCEL_STATUS_AUTHENTICATION',
      EDIT_PARCEL_STATUS_STOPPED: 'EDIT_PARCEL_STATUS_STOPPED',
      EDIT_PARCEL_STATUS_SEIZED: 'EDIT_PARCEL_STATUS_SEIZED',
      EDIT_PARCEL_STATUS_RELEASED: 'EDIT_PARCEL_STATUS_RELEASED',
    },
    OFFICE_PRODUCTS: {
      SELF: 'OFFICE_PRODUCTS',
      SHOW: 'SHOW',
      VIEW_PRODUCTS: 'VIEW_PRODUCTS',
      EDIT_PRODUCT_STATUS_INSPECTION: 'EDIT_PRODUCT_STATUS_INSPECTION',
      EDIT_PRODUCT_STATUS_AUTHENTICATION: 'EDIT_PRODUCT_STATUS_AUTHENTICATION',
      EDIT_PRODUCT_STATUS_OFFICE_STOP: 'EDIT_PRODUCT_STATUS_OFFICE_STOP',
      EDIT_PRODUCT_STATUS_OFFICE_RELEASE: 'EDIT_PRODUCT_STATUS_OFFICE_RELEASE',
      EDIT_PRODUCT_STATUS_STOPPED: 'EDIT_PRODUCT_STATUS_STOPPED',
      EDIT_PRODUCT_STATUS_SEIZED: 'EDIT_PRODUCT_STATUS_SEIZED',
      EDIT_PRODUCT_STATUS_RELEASED: 'EDIT_PRODUCT_STATUS_RELEASED',
    },
    OFFICE_SUPPORT: {
      SELF: 'OFFICE_SUPPORT',
      SHOW: 'SHOW',
      EDIT_GENERAL_SUPPORT_TICKETS: 'EDIT_GENERAL_SUPPORT_TICKETS',
      VIEW_GENERAL_SUPPORT_TICKETS: 'VIEW_GENERAL_SUPPORT_TICKETS',

      EDIT_PARCEL_SUPPORT_TICKETS: 'EDIT_PARCEL_SUPPORT_TICKETS',
      VIEW_PARCEL_SUPPORT_TICKETS: 'VIEW_PARCEL_SUPPORT_TICKETS',

      EDIT_PRODUCT_SUPPORT_TICKETS: 'EDIT_PRODUCT_SUPPORT_TICKETS',
      VIEW_PRODUCT_SUPPORT_TICKETS: 'VIEW_PRODUCT_SUPPORT_TICKETS',
    },
    OFFICE_KNOWLEDGE_BASE: {
      SELF: 'OFFICE_KNOWLEDGE_BASE',
      SHOW: 'SHOW',
      VIEW_KNOWLEDGE_BASE: 'VIEW_KNOWLEDGE_BASE',
    },
    OFFICE_SORTER_DATA: {
      SELF: 'OFFICE_SORTER_DATA',
      VIEW_DATA: 'VIEW_DATA',
    },
  },
};
