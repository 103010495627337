import { Constants } from '../../../constants';
import { Icon, Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import Button from '../../molecules/Button/Button';
import { Colors, FontKeys, Size } from '../../style';
import { DescriptionCSS } from './style';

export const IconTextButton = ({ icon, title, text, button }) => {
  return (
    <Flex column gap="_M" align="center" w>
      <Icon name={icon} size={Size.Icon._XL} />
      <Title text={title} />
      <DescriptionCSS>
        <Description text={text} />
      </DescriptionCSS>
      <Button {...button} size="_M" variant="Primary" />
    </Flex>
  );
};

export const Title = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._L}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Primary._600}
  />
);

export const Description = ({ text }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={FontKeys.Weight.Regular}
  />
);
