import { EmailInput } from './molecules/Molecules';
import { InfoCard } from './organisms/Organisms';

const SSOLoginLayout = ({
  title,
  emailPlaceholder,
  emailName,
  minorButtonClick,
  minorButtonText,
  mainButtonText,
  minorLeftIcon,
  validationSchema,
  initialValues,
  onSubmit,
  isLoading,
  formRef,
  formAction,
  emailValue,
  onEmailChange,
}) => {
  return (
    <>
      <InfoCard
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        title={title}
        minorButtonClick={minorButtonClick}
        minorButtonText={minorButtonText}
        mainButtonText={mainButtonText}
        minorLeftIcon={minorLeftIcon}
        isLoading={isLoading}
        formRef={formRef}
        formAction={formAction}
        formMethod={'post'}
      >
        <EmailInput
          placeholder={emailPlaceholder}
          name={emailName}
          value={emailValue ?? ''}
          onChange={e => onEmailChange(e.target.value)}
        />
        <input
          placeholder={''}
          name={'password'}
          value={''}
          hidden={true}
          readOnly
        />
        <input
          placeholder={''}
          name={'is_sso'}
          value={true}
          hidden={true}
          readOnly
        />
      </InfoCard>
    </>
  );
};
export default SSOLoginLayout;
