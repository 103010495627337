import useFetchData from '../../../../../hooks/fetchHook';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';
import { formatReferenceDetails } from '../formatters/formatReferenceDetails';

export const useGetReferenceDetails = ({
  [referenceIndexKeys.id]: senderId,
  isAdmin,
}) => {
  const urlBase = isAdmin ? 'admin_sender' : 'sender';
  const [{ data = {}, isLoading, isError, error }, _, refetchReference] =
    useFetchData(`${urlBase}/${senderId}`, {
      formatter: formatReferenceDetails,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchReference,
  };
};
