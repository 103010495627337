import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Input } from '../../../../../../components/molecules';
import { tableComponents } from '../molecules';
import { AdminSortersTableContainer } from '../style';

export const AdminSortersTable = ({ tableProps }) => {
  return (
    <AdminSortersTableContainer
      {...tableProps}
      components={tableComponents(tableProps.tableComponentProps)}
    />
  );
};

export const Fields = ({ fields, selectOption }) => {
  return (
    <Flex w column>
      {fields.map(field => (
        <Input
          {...field}
          selectOption={selectOption}
          key={field.name}
          size="_M"
          hasError
        />
      ))}
    </Flex>
  );
};
