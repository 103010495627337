import { Icons } from '../../../../atoms/Icon/Icon.options';
import {
  Swipe,
  Thumbnails,
  TransformAction,
  TransformationSeparator,
} from '../molecules/molecules';
import { ThumbnailGalleryContainer, TransformationsS } from '../style';

export const Transformations = ({ rotate, zoom, download, dismiss }) => {
  return (
    <TransformationsS>
      <TransformAction icon={Icons.RotateCcw} onClick={rotate(-90)} />
      <TransformAction icon={Icons.RotateCw} onClick={rotate(90)} />
      <TransformationSeparator />
      <TransformAction icon={Icons.ZoomIn} onClick={zoom('in')} />
      <TransformAction icon={Icons.ZoomOut} onClick={zoom('out')} />
      <TransformationSeparator />
      <TransformAction icon={Icons.Download} onClick={download} />
      <TransformationSeparator />
      <TransformAction icon={Icons.X} onClick={dismiss} />
    </TransformationsS>
  );
};

export default function ThumbnailGallery({
  images = ['placeholder'],
  selected = -1,
  handleSelect,
  type,
  isLoading,
  amount,
}) {
  images = images && images.length ? images : ['placeholder'];

  const swipe = direction => () => handleSelect(direction);
  const select = index => handleSelect(index);

  return (
    <ThumbnailGalleryContainer type={type}>
      <Swipe swipe={swipe} direction={'prev'} type={type} />
      <Thumbnails
        type={type}
        images={images}
        selected={selected}
        select={select}
        isLoading={isLoading}
        amount={amount}
      />
      <Swipe swipe={swipe} direction={'next'} type={type} />
    </ThumbnailGalleryContainer>
  );
}
