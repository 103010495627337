import { authorityFields } from '../controllers/variables';

const { default: useFetchData } = require('../../../../../hooks/fetchHook');

export const useGetAuthority = () => {
  const [{ data = [], isLoading, isError, error }, getAuthority] = useFetchData(
    'authorities',
    {
      formatter,
    }
  );

  return { data, isLoading, isError, error, getAuthority };
};

const formatter = data => {
  return data.map(entry => ({
    label: entry.auName,
    value: entry.auID,
    [authorityFields.name]: entry.auName,
    [authorityFields.email]: entry.auEmail,
    [authorityFields.phone]: entry.auPhone,
  }));
};
