import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors } from '../../style';

const sizeMap = new Map([
  ['_S', 6],
  ['_M', 8],
  ['_L', 10],
]);

export const Dot = styled.div`
  position: absolute;
  z-index: 1;
  height: ${props => sizeMap[props.size] ?? 8}px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${props => props.color ?? Colors.Sharp.White};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  ${props => dots[props.number]}
`;

const dots = {
  _1: {
    left: '0px',
    animation: 'ellipsisDot1 0.6s infinite',
  },
  _2: {
    left: '0px',
    animation: 'ellipsisDot2 0.6s infinite',
  },
  _3: {
    left: '24px',
    animation: 'ellipsisDot2 0.6s infinite',
  },
  _4: {
    left: '48px',
    animation: 'ellipsisDot3 0.6s infinite',
  },
};

export const LoadingEllipsis = styled.div`
  position: relative;
  width: 64px;
  transform: translateX(2px);
  margin: auto;
  ${Styles.flex};
`;
