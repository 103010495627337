import styled from '@emotion/styled';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../style';

export const Container = styled(Flex)`
  gap: ${Layout.Spacing._2XS};
  padding: ${({ padding = '_S' }) => Layout.Spacing[padding]};
  background: ${Colors.Neutral._100};
  border-radius: ${Effects.Border_Radius._S};
`;
