import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { ReferenceIndexTable } from './organisms/organisms';

const { SELF, VIEW_REFERENCES } = PermissionKeys.Brand.REFERENCES;

const ReferenceIndexLayout = ({
  headers,
  data,
  grid,
  rowAction,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        hasPermission(VIEW_REFERENCES) && (
          <ReferenceIndexTable
            headers={headers}
            data={data}
            rowAction={rowAction}
            grid={grid}
          />
        )
      }
    />
  );
};

export default ReferenceIndexLayout;
