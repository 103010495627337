import Button from '../../../../../components/molecules/Button/Button';
import Tooltip from '../../../../../components/molecules/Tooltip/Tooltip';
import { PropKeys, Prose } from '../../../../../constants';
import { useStore } from '../../../../../providers/StoreProvider';
import { BrandManagementContainer } from './style';

export const BrandManagement = ({ options }) => {
  const { store } = useStore();
  const isAllBrands = !store[PropKeys.brandId];
  return (
    <BrandManagementContainer>
      {options.map((option, index) => {
        return (
          <Tooltip
            content={isAllBrands ? Prose.allBrandsDisabled : ''}
            key={index}
          >
            <Button
              {...option}
              variant="Tertiary"
              size="_S"
              black
              disabled={isAllBrands}
            />
          </Tooltip>
        );
      })}
    </BrandManagementContainer>
  );
};
