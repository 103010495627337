import { useState } from 'react';
import { Icon, Text } from '../../atoms';
import { AddIcon } from '../../atoms/AddIcon/AddIcon';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import AnimatedIcon from '../../molecules/AnimatedIcon/AnimatedIcon';
import { CardLoader } from '../../molecules/LoadingAnimation/LoadingAnimation.exports';
import { NoResults } from '../../molecules/NoResults/NoResults';
import { Colors, FontKeys, Size } from '../../style';
import {
  AttachmentContainer,
  DownloadTag,
  SectionItem,
} from './Attachments.style';

export const AttachmentsContainer = ({
  attachments,
  handleDelete,
  urlKey,
  nameKey,
  isLoading,
  noResults,
  showAddAttachments,
  hasEditPermission,
  hasDeletePermission,
  scroll,
}) => {
  return (
    <Flex
      column
      scroll={!isLoading && scroll}
      gap="_S"
      w
      h
      className={'attachment-scroll'}
    >
      {isLoading ? (
        <CardLoader />
      ) : attachments && attachments.length ? (
        attachments.map(attachment => {
          return (
            <Attachment
              attachment={attachment}
              key={attachment[urlKey]}
              handleDelete={handleDelete}
              urlKey={urlKey}
              nameKey={nameKey}
              hasDeletePermission={hasDeletePermission}
            />
          );
        })
      ) : (
        <NoResults
          description={noResults.description}
          icon={<Icon name={noResults.icon} />}
        />
      )}
      {hasEditPermission && (
        <AddIcon onClick={showAddAttachments} disabled={isLoading} />
      )}
    </Flex>
  );
};

export const Attachment = ({
  attachment,
  handleDelete,
  urlKey,
  nameKey,
  hasDeletePermission,
}) => {
  const [active, setActive] = useState(false);
  const { [urlKey]: url, [nameKey]: name } = attachment;

  return (
    <AttachmentContainer
      onPointerOver={() => setActive(true)}
      onPointerOut={() => setActive(false)}
    >
      <DownloadTag href={url} target="_blank">
        <SectionItem>
          <Icon
            name={Icons.File}
            size={Size.Icon._M}
            color={Colors.Neutral._900}
          />
          <DetailsText text={name} />
        </SectionItem>
      </DownloadTag>
      {hasDeletePermission && (
        <AnimatedIcon
          name={Icons.Trash2}
          theme="hidden_neutral"
          size={Size.Icon._M}
          active={active}
          onClick={handleDelete(attachment)}
        />
      )}
    </AttachmentContainer>
  );
};

export const DetailsSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

const DetailsText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      ellipsis
    />
  );
};
