import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  extractSectionOptions,
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useDeleteSubcategory } from '../api/deleteSubcategory';
import { useGetAdminCategories } from '../api/getAdminCategories';
import { useGetFilters } from '../api/getFilters';
import AdminCategoriesLayout from '../components/AdminCategoriesLayout';
import AddCategory from './AddCategory';
import AddSubcategory from './AddSubcategory';
import { useAdminCategoriesReducer } from './AdminCategories.hook';
import SetupChoice from './SetupChoice';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  adminCategoriesKeys as keys,
  noResultsState,
  titles,
} from './variables';

const { SELF, VIEW_CATEGORIES } = PermissionKeys.Admin.ADMIN_CATEGORIES;

const AdminCategories = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { dismiss, showModal } = useModal();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_CATEGORIES);
  const hasEditPermission = hasViewPermission;
  const resetFormRef = useRef(null);

  const ownOptions = {};

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    error: filtersError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const {
    isCompleted: deleteIsCompleted,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
    doDelete,
  } = useDeleteSubcategory();

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useAdminCategoriesReducer({
    initialSearchParams,
    resetSearchParams,
  });
  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location) || true;
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { categories, categoryCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetAdminCategories({ searchParams, ignore: ignoreDataFetch });

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcel_evaluation];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError || categories == null) return;
    setData(categories);
  }, [categories, isLoading, isError]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.CATEGORY]: dropdown(DC.CATEGORY),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ``;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.admin_categories]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
          endSlot={
            <Button
              text="Add category"
              onClick={showAddCategoryModal}
              size="_S"
            />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  useEffect(() => {
    if (deleteIsLoading || !deleteIsCompleted) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);

      return;
    }
    showSnackbarSuccess('Subcategory successfully deleted');
    onSuccess();
    dismiss();
  }, [deleteIsCompleted, deleteIsLoading, deleteIsError]);

  const showAddCategoryModal = () =>
    showModal(SetupChoice, {
      categories: extractSectionOptions(options?.[DC.CATEGORY]),
      onSuccess,
    });

  const showEditCategorydModal = ({ item }) =>
    showModal(AddCategory, {
      mode: 'edit',
      prevData: item,
      onSuccess,
    });

  const showEditSubcategorydModal = ({ item }) =>
    showModal(AddSubcategory, {
      mode: 'edit',
      prevData: item,
      categories: extractSectionOptions(options?.[DC.CATEGORY]),
      onSuccess,
    });

  const showDeleteSubcategorydModal = ({ item, isLoading }) => {
    const onConfirm = async () => {
      const url = `admin_category/${item[keys.category.id]}/sub_category/${
        item[keys.subCategory.id]
      }`;
      showDeleteSubcategorydModal({ item, isLoading: true });

      const error = await doDelete({ url });
      if (error) showDeleteSubcategorydModal({ item, isLoading: false });
    };

    const onCancel = dismiss;

    showModal(ConfirmModal, {
      title: 'Delete subcategory',
      description: parseStringModifiers(
        'Are you sure you want delete this subcategory? **This cannot be undone**.'
      ),
      confirmText: 'Delete',
      dismiss,
      onCancel,
      onConfirm,
      isLoading,
    });
  };

  const onSuccess = refreshView;

  const editCategory = {
    value: uniqueId(),
    label: 'Edit category',
    id: 'edit-category',
    leftIcon: Icons.Edit3,
    onClick: showEditCategorydModal,
  };
  const deleteCategory = {
    value: uniqueId(),
    label: 'Edit subcategory',
    id: 'edit-subcategory',
    leftIcon: Icons.Edit3,
    onClick: showEditSubcategorydModal,
  };
  const deleteSubcategory = {
    value: uniqueId(),
    label: 'Delete subcategory',
    id: 'delete-subcategory',
    leftIcon: Icons.Trash,
    onClick: showDeleteSubcategorydModal,
  };

  const floatingMenuOptions = [editCategory, deleteCategory, deleteSubcategory];
  const optionMenuOptionClick = item => (option, e) => {
    floatingMenuOptions
      .find(o => o.id === option.id)
      ?.onClick({ item, option, e });
  };

  const rowAction = undefined;
  const noDataAction = undefined;

  return (
    <AdminCategoriesLayout
      tableProps={{
        data,
        headers,
        grid,
        rowAction,
        tableComponentProps: {
          options: floatingMenuOptions,
          optionMenuOptionClick,
        },
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default AdminCategories;
