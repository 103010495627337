import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Button from '../../../../../../components/molecules/Button/Button';
import { Card } from '../../../../../../components/molecules/Card/Card';
import { Delta } from '../../../../../../components/molecules/Delta/Delta';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import { disabledText } from '../../../../../../components/molecules/Disabled/variables';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { Label } from '../../../../../../components/molecules/Label/Label';
import BarChart from '../../../../../../components/organisms/BarChart/BarChart';
import { Colors, Layout } from '../../../../../../components/style';
import Modal from '../../../../../../components/templates/Modal/Modal';
import { Popup } from '../../../../../../components/templates/Popup';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import {
  DescriptiveText,
  LoadingAnimation,
  PopupDescription,
} from '../atoms/atoms';
import {
  ErrorDisplay,
  OverviewStat,
  ParcelListScroll,
  ParcelStatus,
  StatusDetailsContent,
} from '../molecules/molecules';
import {
  BarchartResultsCSS,
  OverviewCSS,
  ParcelStatusesCSS,
  StatusDetailCSS,
} from '../style';

export const Overview = ({
  overviewStats = [],
  isLoading,
  isError,
  endSlot,
  debug,
}) => {
  return overviewStats.map((stat, index) => (
    <OverviewCard
      {...stat}
      isLoading={isLoading}
      isError={isError}
      index={index}
      endSlot={endSlot}
      key={index}
    />
  ));
};

const OverviewCard = ({
  title,
  titleIcon,
  description,
  totals,
  isLoading,
  isError,
  className,
  index,
  endSlot,
  disabled,
}) => {
  return (
    <OverviewCSS index={index} disabled={disabled} className={className}>
      <Card
        title={
          <Label text={title} leftIcon={titleIcon} size={'_M'} noPadding />
        }
        icon={<InfoIcon description={description} />}
      >
        {isError || !totals?.length ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex w padding={`0 ${Layout.Spacing._2XS} 0 0`}>
            {totals.map((total, i) => (
              <OverviewStat total={total} key={i} index={index} />
            ))}
          </Flex>
        )}
        {endSlot}
      </Card>
      {disabled && <Disabled text={disabledText._2Row} />}
    </OverviewCSS>
  );
};

export const StatusDetails = ({
  status,
  button,
  messageAndDelta,
  details,
  isLoading,
  isError,
  disabled,
}) => {
  return (
    <StatusDetailCSS disabled={disabled}>
      <Card>
        {isLoading ? (
          <LoadingAnimation />
        ) : isError || !details?.length ? (
          <ErrorDisplay />
        ) : (
          <StatusDetailsContent
            status={status}
            button={button}
            details={details}
            messageAndDelta={messageAndDelta}
            disabled={disabled}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText._1Row} />}
    </StatusDetailCSS>
  );
};

export const BarchartResults = ({
  title,
  description,
  isError,
  isLoading,
  data,
  bars,
  colors,
  inactive,
  disabled,
  className,
  onBarClick,
}) => {
  return (
    <BarchartResultsCSS disabled={disabled} className={className}>
      <Card title={title} icon={<InfoIcon description={description} />}>
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <BarChart
            data={data}
            bars={bars}
            inactive={inactive}
            colors={colors}
            onBarClick={onBarClick}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText._2Row} />}
    </BarchartResultsCSS>
  );
};

export const ParcelStatuses = ({
  parcelStatuses,
  isLoading,
  isError,
  onClick,
  disabled,
}) => {
  return (
    <ParcelStatusesCSS disabled={disabled}>
      {(parcelStatuses ?? [{}, {}, {}, {}, {}]).map((item, index) => {
        const { status } = item;

        return (
          <ParcelStatus
            key={index}
            {...item}
            {...status}
            onClick={onClick(item)}
            isLoading={isLoading}
            isError={isError}
            disabled={disabled}
          />
        );
      })}
    </ParcelStatusesCSS>
  );
};

export const BrandDetails = ({
  newBrandCount = 3,
  brandListString = 'Balenciaga, Gucci, Yves Saint Laurent, Balenciaga, Gucci, Yves Saint Laurent',
  delta,
  period,
}) => {
  return (
    <Flex gap="_2XS" column w grow>
      <Flex gap="_2XS" align={'center'}>
        <Button text={'New brands'} variant={'Tertiary'} size={'_M'} />
        <Label
          text={newBrandCount}
          background={Colors.Primary._600}
          textColor={Colors.Sharp.White}
          size={'_XS'}
        />
      </Flex>
      <Flex w>
        <DescriptiveText text={brandListString} />
      </Flex>
      <Delta delta={delta} period={period} />
    </Flex>
  );
};

export const SupportDetails = ({ avgResolvedTime = 3, delta, period }) => {
  return (
    <Flex gap="_2XS" column w margin={'auto 0 0 0 '}>
      <Flex w>
        <DescriptiveText text={avgResolvedTime} bold />
      </Flex>
      <Delta delta={delta} period={period} />
    </Flex>
  );
};

export const ParcelList = Modal(({ title, countType, parcels, dismiss }) => {
  const header = [
    title,
    countType ? ` - ` : '',
    countType ? <PopupDescription text={countType} /> : '',
  ];
  return (
    <Popup
      title={header}
      size={'_M'}
      element={<AnimatedIcon name={Icons.X} onClick={dismiss} />}
    >
      <Content>
        <Flex column w h>
          <Spacer height={'_M'} />
          <Flex h0 grow>
            <ParcelListScroll parcels={parcels} />
          </Flex>
        </Flex>
      </Content>
    </Popup>
  );
});
