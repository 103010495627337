import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants } from '../../../../../constants';
import { extractImageName } from '../../../../../features/file_upload/controllers/utils';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { dropdownValidation } from '../../../../../utils/validationUtils';
import { useAddInvoice } from '../api/addInvoice';
import { useGetInvoiceStatusOptions } from '../api/getInvoiceStatusOptions';
import { AddInvoiceLayout } from '../components/AddInvoiceLayout';
import { brandManagementKeys as keys, uploadStatusContent } from './variables';

const folder = 'invoices';

export const fieldNames = {
  date: 'date',
  amount: 'amount',
  status: 'status',
};

const fields = [
  {
    key: uniqueId(),
    name: fieldNames.date,
    label: 'Date',
    placeholder: 'yyyy-mm-dd',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.amount,
    label: 'Amount',
    placeholder: '999,99€',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.status,
    label: 'Status',
    placeholder: 'Select a status',
    size: '_M',
    type: 'select',
    options: [],
  },
];

const addInvoiceInitialValues = {};
fields.forEach(field => (addInvoiceInitialValues[field.name] = ''));

const addInvoiceValidationSchema = Yup.object().shape({
  [fieldNames.date]: Yup.string()
    // .matches(re.yyyymmdd, 'Invalid date format. Must be yyyy-mm-dd')
    .max(100, 'Too long')
    .required('Required'),
  [fieldNames.amount]: Yup.string().required('Required'),
  [fieldNames.status]: dropdownValidation,
});

const mapFieldsToResponse = {
  [fieldNames.date]: keys.invoice.date,
  [fieldNames.amount]: keys.invoice.amount,
  [fieldNames.status]: keys.invoice.status.value,
};

const AddInvoice = ({ onSuccess, mode = 'add', invoice }) => {
  const isEditing = mode === 'edit';
  const preexistingFile = isEditing ? invoice[keys.invoice.file] : null;

  const [enableUpload, toggleEnableUpload] = useToggle(
    !isEditing || !preexistingFile
  );
  const [_, toggle] = useToggle(false);

  const { brandId } = useParams();

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();

  const {
    data: statusOptions,
    isLoading: statusOptionsIsLoading,
    isError: statusOptionsIsError,
  } = useGetInvoiceStatusOptions();

  const { body, isLoading, isError, error, doAddInvoice } = useAddInvoice({
    isEditing,
  });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess(isEditing ? 'Invoice updated' : 'Invoice added');
    onSuccess();
  }, [body, isError, isLoading]);

  useEffect(() => {
    if (statusOptions == null || statusOptionsIsError) return;
    const options = statusOptions.map(option => ({
      value: option,
      label: option,
    }));

    fields.find(field => field.name === fieldNames.status).options = options;
    toggle();
  }, [statusOptions]);

  useEffect(() => {
    return () => resetFileUpload();
  }, []);

  const onSubmit = values => {
    if (enableUpload && fileState.files?.length === 1)
      handleImport({
        folder,
        parameterName: 'names',
        successCallback: ({ uploadNames }) => {
          showSnackbarSuccess('Attachments successfully uploaded');
          handleAddInvoice(values, uploadNames);
          resetFileUpload();
        },
        errorCallback: () => {
          showSnackbarError('Error uploading attachments');
          resetFileUpload();
        },
      });
    else handleAddInvoice(values);
  };

  const handleAddInvoice = (values, uploadNames) => {
    const url = isEditing
      ? `admin_brand/${brandId}/invoice/${invoice[keys.invoice.id]}`
      : `admin_brand/${brandId}/invoices`;
    const body = {
      ...values,
      status: values.status.value,
    };

    const file = uploadNames?.[0] || invoice?.[keys.invoice.file];

    body.invoice = file.includes('http')
      ? extractImageName(file, folder)
      : file;

    doAddInvoice(body, url);
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: invoice,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const disabled =
    fileState.isUploading ||
    isLoading ||
    fileState.errorType !== null ||
    (fileState.files?.length !== 1 && !preexistingFile);

  const nonFormikDirty = () => {
    if (isEditing) {
      if (enableUpload && fileState?.files?.length) return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={withPrevData(addInvoiceInitialValues)}
      validationSchema={addInvoiceValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, dirty }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });
        return (
          <Form>
            <AddInvoiceLayout
              isEditing={isEditing}
              invoiceFieldsProps={{
                fields,
                selectOption,
              }}
              invoiceDropzoneProps={{
                handleDrop: handleDrop(
                  Constants.FileUpload.uploadType.brand_invoice
                ),
                fileState,
                percentageUploaded: percentage,
                uploadStatusContent: uploadStatusContent({
                  fileState,
                  resetFileUpload,
                  preexistingFile,
                  enableUpload,
                  isNotImage: {
                    folder: 'invoices',
                  },
                }),
                multiple: false,
                enableUpload,
                disabled: !enableUpload,
                toggleEnableUpload:
                  isEditing && preexistingFile && toggleEnableUpload,
              }}
              actionsProps={{
                disabled: !isValid || disabled || !(dirty || nonFormikDirty()),
                isLoading,
                primaryText: isEditing ? 'Save' : 'Add',
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddInvoice);
