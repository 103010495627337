import {
  RowDateHorizontal,
  RowSimpleText,
  Space,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { referenceIndexKeys as keys } from '../../controllers/variables';

export const tableComponents = {
  [keys.leftPadding]: { display: Space },
  [keys.name]: {
    display: RowSimpleText(keys.name, 'bold', true),
  },
  [keys.alias]: {
    display: RowSimpleText(keys.alias, 'bold', false, {
      copyable: true,
    }),
  },
  [keys.brand.self]: {
    display: User(keys.brand.name, keys.brand.image),
  },
  [keys.company]: {
    display: RowSimpleText(keys.company),
  },
  [keys.country]: {
    display: RowSimpleText(keys.country),
  },
  [keys.casesSeized]: {
    display: RowSimpleText('seized_case_count', 'bold'),
  },
  [keys.dateCreated]: {
    display: RowDateHorizontal(keys.dateCreated, {
      time: false,
      boldDate: false,
    }),
  },
  [keys.addedBy]: {
    display: User('usDisplayName', 'usImage', { bold: true, border: false }),
  },
};
