import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '../components/molecules/Snackbar/Snackbar';
import { Constants } from '../constants';

const SnackbarContext = React.createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {
  const [snackbars, setSnackbars] = useState({});

  const removeSnackbar = removed => () => {
    const functionalUpdate = snackbars => {
      const newSnacks = { ...snackbars };
      delete newSnacks[removed];
      return newSnacks;
    };
    setSnackbars(functionalUpdate);
  };

  const showSnackbarError = (content = 'Please try again.', size, timeout) =>
    showSnackbar({ content, size, timeout, type: Constants.Snackbar.error });

  const showSnackbarWarning = (
    content = 'Something unexpected ocurred.',
    size,
    timeout
  ) =>
    showSnackbar({
      content,
      size,
      timeout,
      type: Constants.Snackbar.warning,
    });

  const showSnackbarSuccess = (
    content = 'Update completed successfully.',
    size,
    timeout
  ) =>
    showSnackbar({
      content,
      size,
      timeout,
      type: Constants.Snackbar.success,
    });

  const showSnackbar = ({ content, size, timeout, type }) => {
    if (!content) return;
    const key = uuidv4();
    const functionalUpdate = snackbars => {
      const newSnacks = { ...snackbars };

      newSnacks[key] = {
        content,
        snackbarDismiss: removeSnackbar(key),
        size,
        type,
        timeout,
        key,
      };
      return newSnacks;
    };
    setSnackbars(functionalUpdate);
  };

  return (
    <SnackbarContext.Provider
      value={{ showSnackbarError, showSnackbarWarning, showSnackbarSuccess }}
    >
      {children}
      <div>
        {Object.values(snackbars).map(
          ({ content, snackbarDismiss, key, type, size }, index) => {
            return (
              <Snackbar
                key={key}
                content={content}
                snackbarDismiss={snackbarDismiss}
                type={type}
                size={size}
                index={index}
              />
            );
          }
        )}
      </div>
    </SnackbarContext.Provider>
  );
}
