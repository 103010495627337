import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { cloneDeep } from '../../../../../lib/js';
import { DC } from '../../../features_public/support/controllers/variables';
import { staticOptions } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  // no filters endpoint yet. just use the same formatting structure as admin

  const [
    { data = staticOptions, isLoading, isError, error },
    _,
    refetchFilters,
  ] = useFetchData('support_tickets/filters', {
    formatter,
    failureFormatter: () => staticOptions,
    ignore,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { brands } = data;

  const newOps = cloneDeep(staticOptions);

  newOps[DC.BRANDS] = prepareDataForDropdown(brands, 'brName', 'brID', {
    sectionTitle: 'Others',
  });

  return newOps;
};
