import { ViewLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import { SupportContainer, SupportContent } from '../style';
import { RecentActivity, Tickets } from './organisms/organisms';

const SupportLayout = ({
  tickets,
  activity,
  handleNotificationClick,
  activityRef,
  ticketsRef,
  ticketLeft,
  loadMoreActivity,
  moreActivityClicked,
  isLoading,
  isError,
  error,
  tableStatusProps,
  activityIsLoading,
  activityIsError,
  hasViewPermission,
}) => {
  const { firstLoad, noData, noDataDisplay = {} } = tableStatusProps ?? {};
  return firstLoad && isLoading ? (
    <ViewLoader />
  ) : firstLoad && !isLoading && noData ? (
    <NoSearchResults show={noData} {...noDataDisplay} />
  ) : (
    <SupportContainer>
      <SupportContent>
        {hasViewPermission && (
          <Tickets
            tickets={tickets}
            ticketsRef={ticketsRef}
            sort="sort"
            isLoading={isLoading}
            isError={isError}
            error={error}
            tableStatusProps={tableStatusProps}
          />
        )}
        {hasViewPermission && (
          <RecentActivity
            activity={activity}
            handleNotificationClick={handleNotificationClick}
            activityRef={activityRef}
            loadMoreActivity={loadMoreActivity}
            moreActivityClicked={moreActivityClicked}
            isLoading={activityIsLoading}
            isError={activityIsError}
            ticketLeft={ticketLeft}
          />
        )}
      </SupportContent>
    </SupportContainer>
  );
};
export default SupportLayout;
