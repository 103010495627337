import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

export const adminCategoriesKeys = {
  addedBy: {
    name: 'addedBy.name',
    image: 'addedBy.image',
  },
  addedAt: 'addedAt',
  id: 'id',
  name: 'name',
  category: {
    id: 'category.id',
    name: 'category.name',
  },
  subCategory: {
    id: 'subCategory.id',
    name: 'subCategory.name',
  },
};

const keys = adminCategoriesKeys;

export const DC = {
  ...DISPATCH_CONSTANTS,
  CATEGORY: 'CATEGORY',
  BRAND_GROUP: 'BRAND_GROUP',
  INDUSTRY: 'INDUSTRY',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
    param: '',
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const headers = {
  [keys.category.name]: 'Product category',
  [keys.subCategory.name]: 'Product subcategory',
  [keys.addedAt]: 'Added at',
  [keys.addedBy.name]: 'Added by',
  options: '',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
  // category //
  minmax(140px, 1fr)
  // subcategory //
  minmax(140px, 1fr)
  // added at //
  minmax(80px, 0.35fr)
  // added by //
  minmax(140px, 0.65fr)
  // options //
    48px
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const initialFilterValues = {
  [DC.CATEGORY]: '',
};

export const titles = {
  [DC.CATEGORY]: 'Category',
};

export const allOptions = {
  [DC.CATEGORY]: { label: 'All', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No categories',
    description: 'Start by adding a new category',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all brands',
  },
};

export const setupChoice = {
  category: {
    icon: Icons.FolderPlus,
    title: 'Create a new category',
    text: `Please choose this option to create
    a new product category.`,
    buttonText: 'Create',
  },
  subCategory: {
    icon: Icons.FolderTree,
    title: 'Create a subcategory',
    text: `Please choose this option if you want to
    add a subcategory to an existing category.`,
    buttonText: 'Create',
  },
};
