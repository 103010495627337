import { useEffect, useState } from 'react';
import { re } from '../../../constants';
import { cloneDeep } from '../../../lib/js';
import { useModal } from '../../../providers/ModalProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../utils/utils';
import ConfirmModal from '../../molecules/ConfirmModal/ConfirmModal';
import AttachmentUpload from '../AttachmentUpload/AttachmentUpload';
import { AttachmentsLayout } from './AttachmentsLayout';
import { useRemoveAttachment } from './api/removeAttachment';
import { displayType as displayType_, noResults } from './variables';

const Attachments = ({
  route,
  folder,
  id,
  attachmentIdKey,
  urlKey,
  nameKey,
  attachments,
  isLoading: parentIsLoading,
  displayType = displayType_.card,
  hasEditPermission,
  hasDeletePermission,
  ...props
}) => {
  const [data, setData] = useState(attachments ?? []);

  const { showModal, dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess, showSnackbarWarning } =
    useSnackbar();

  const {
    params: deleteAttachmentData,
    isLoading: deleteAttachmentIsLoading,
    isError: deleteAttachmentIsError,
    error,
    doRemoveAttachment,
  } = useRemoveAttachment();

  useEffect(() => {
    if (deleteAttachmentIsLoading || deleteAttachmentData == null) return;
    if (deleteAttachmentIsError) {
      showSnackbarError(error);
      return;
    }

    cleanupRemoveAttachment(deleteAttachmentData[attachmentIdKey]);
  }, [
    deleteAttachmentData,
    deleteAttachmentIsLoading,
    deleteAttachmentIsError,
  ]);

  const deleteAttachment = attachment => {
    const attachmentId = attachment[attachmentIdKey];
    const url = `${route}/${id}/attachment/${attachmentId}`;
    const params = { [attachmentIdKey]: attachmentId };
    doRemoveAttachment({ url, params });
  };

  const cleanupRemoveAttachment = attachmentId => {
    const functonalUpdate = prevAttachments => {
      const attachments = cloneDeep(prevAttachments);
      const attachmentIndex = attachments.findIndex(
        att => att[attachmentIdKey] === attachmentId
      );

      if (attachmentIndex === -1) return attachments;

      const attachment = attachments.splice(attachmentIndex, 1)[0];
      return attachments;
    };

    setData(functonalUpdate);
  };

  const onAttachmentUploadSuccess = ({ data, fileState: { storageNames } }) => {
    const newAttachments = data.map(entry => {
      const { file } =
        storageNames.find(file => file.storageName.includes(entry.name)) ?? {};

      if (file == null) {
        showSnackbarWarning('Something wrent wrong. Please, refresh the page');
        return [];
      }

      const { name, attachment_id } = entry;
      const url = URL.createObjectURL(file);

      return {
        [nameKey]: name.replaceAll(re.uuid, '').replace('_.', '.'),
        [urlKey]: url,
        [attachmentIdKey]: attachment_id,
      };
    });

    setData(x => [...newAttachments, ...x]);
    showSnackbarSuccess('Attachment succesfully saved');
    dismiss();
  };

  const showAddAttachments = () => {
    showModal(
      AttachmentUpload,
      {
        route,
        folder,
        id,
        onUploadSuccess: onAttachmentUploadSuccess,
      },
      { ignoreOverlayDismiss: true }
    );
  };

  const showConfirmDeleteAttachment = attachment => () => {
    const onConfirm = _ => {
      deleteAttachment(attachment);
      dismiss();
    };

    showModal(ConfirmModal, {
      title: 'Delete attachment',
      description:
        parseStringModifiers(`Are you sure you want to delete this attachment?
          **This cannot be undone.**`),
      confirm: 'Delete',
      onConfirm,
      onCancel: dismiss,
    });
  };

  return (
    <AttachmentsLayout
      attachments={data}
      handleDelete={showConfirmDeleteAttachment}
      urlKey={urlKey}
      nameKey={nameKey}
      isLoading={parentIsLoading}
      cardResults={noResults}
      unboxedResults={{ description: '', icon: '' }}
      showAddAttachments={showAddAttachments}
      displayType={displayType}
      hasEditPermission={hasEditPermission}
      hasDeletePermission={hasDeletePermission}
      props={props}
    />
  );
};
export default Attachments;
