import { useState } from 'react';
import { API } from '../../../../lib/api';
import { useAuth } from '../../../../providers/AuthProvider';

export const useDownloadTableData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [{ token }] = useAuth();

  const downloadTableData = async ({ params, type, url, large }) => {
    setIsLoading(true);
    setIsError(false);
    await (large ? API.GETLARGE : API.GET)(url ?? `${type}/export`, {
      params,
      responseType: 'arraybuffer',
      headers: {
        Authorization: token,
      },
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]), {
          type: 'application/octet-stream',
        });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          res.headers?.['content-disposition']?.split('"')?.[1] ||
            `Report-${Date.now()}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsLoading(false);
      })
      .catch(err => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isError,
    downloadTableData,
  };
};
