import styled from '@emotion/styled';
import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Delta } from '../../../../../components/molecules/Delta/Delta';
import { Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';
import { dashboardKeys } from '../variables';

export const DashboardContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: minmax(236px, min-content) minmax(214px, min-content);
  grid-auto-rows: auto;
  .card {
    height: 100%;
  }
  .top-products-barchart,
  .top-items-barchart {
    grid-column: 1 / 9;
  }
  .${dashboardKeys.brandCount} {
    grid-column: 9 / 13;
    grid-row: 5;
  }
  .${dashboardKeys.supportCount} {
    grid-column: 9 / 13;
    grid-row: 6;
  }
`;

export const AnimationCSS = styled.div`
  width: 100%;
  height: 100%;
  ${Styles.flexColumnCenter};
`;

export const DashboardCard = styled.div`
  height: 100%;
  position: relative;
  border-radius: ${Effects.Border_Radius._S};
  .card {
    overflow: hidden;
    height: 100%;
    filter: ${({ disabled }) => (disabled ? 'blur(1px)' : '')};
  }
`;

export const OverviewCSS = styled(DashboardCard)`
  &.parcelCount {
    grid-row: 1;
    grid-column: 1 / 13;
  }
  &.productCount {
    grid-row: 2;
    grid-column: 1 / 7;
  }
  &.itemCount {
    grid-row: 2;
    grid-column: 7 / 13;
  }
  .delta-content {
    flex-grow: 0;
    width: unset;
  }
`;

export const StatusDetailCSS = styled(DashboardCard)`
  grid-column: span 6;
  .delta-content.horizontal {
    width: 0;
    flex-grow: 1;
  }
`;

export const ParcelStatusesCSS = styled(DashboardCard)`
  grid-row: 3;
  grid-column: 1 / 13;
  gap: ${Layout.Spacing._XS};
  ${Styles.flexBetween};
  .card {
    padding: ${Layout.Spacing._XS};
    padding-left: ${Layout.Spacing._S};
    gap: ${Layout.Spacing._XS};
  }
`;

export const ParcelStatusCSS = styled(DashboardCard)`
  width: 0;
  flex-grow: 1;
`;
export const DashboardClickIconCSS = styled.div`
  display: none;
`;

export const BarchartResultsCSS = styled(DashboardCard)`
  height: 356px;
`;

export const CategoryLabelCSS = styled.div`
  width: 112px;
  max-width: 112px;
  min-width: 112px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: ${Effects.Border_Radius.Circle};
  background: ${({ color }) => color};
`;

export const DeltaFlexStart = styled(Delta)`
  justify-content: flex-start;
`;

export const ParcelListScrollContainer = styled(Card)`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  gap: 0;
`;

export const ParcelAliasContainer = styled(Flex)`
  width: 184px;
`;

export const AliasRowContainer = styled(Flex)`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  gap: ${Layout.Spacing._2XS};
`;

export const StyledDelta = styled(Delta)`
  margin-left: auto;
  flex-grow: 0;
  width: unset;
`;
