import { useReducer } from 'react';
import { timeFrameIsSet } from '../../../../../utils/timeUtils';
import { DC } from '../variables';

export const useDashboardReducer = ({
  initialSearchParams,
  resetSearchParams,
}) => {
  const [searchParams, dispatch] = useReducer(
    dashboardFetchReducer({ resetSearchParams }),
    initialSearchParams
  );
  return [searchParams, dispatch];
};

const dashboardFetchReducer =
  ({ resetSearchParams }) =>
  (state, { type, payload }) => {
    switch (type) {
      case DC.TIME_FRAME:
        return {
          ...state,
          [type]: payload,
          [DC.TIME_FRAME_SET]: !timeFrameIsSet(payload.value),
        };
      case DC.RESET:
        return {
          ...resetSearchParams,
        };
      case DC.SET:
        return payload;
      default:
        throw new Error();
    }
  };

export const dispatchAction = dispatch => (type, props) => {
  switch (type) {
    case DC.TIME_FRAME:
      return payload => () =>
        dispatch({ type, payload: { value: payload, label: '' } });
    case DC.RESET:
      return payload => {
        props.resetForm();
        dispatch({ type, payload });
      };
    default:
      throw Error(`No function assigned to type: ${type}`);
  }
};
