import styled from '@emotion/styled';
import { Styles, UtilityComponents } from '../../../constants';
import { Flex } from '../../atoms/Flex/Flex';
import Button from '../../molecules/Button/Button';
import { Colors, Effects, Layout, Size, UtilityEffects } from '../../style';

const containerWidth = {
  _S: '512px',
  _M: '1024px',
  _L: '1272px',
};

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(min(10px, 100%), 1fr));
  grid-template-rows: 72px 1fr;
  grid-auto-rows: 40px;
  cursor: auto;
  margin: auto;
  padding: ${Layout.Spacing._M};
  padding-top: 0;
  grid-column-gap: ${Styles.gridColumnGap};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  max-height: ${props => (props.iPad ? Size.iPad.height : '720px')};
  min-height: ${props => (props.iPad ? Size.iPad.height : '720px')};
  height: 100%;
  width: ${props =>
    props.iPad ? Size.iPad.width : containerWidth[props.size]};
  ${props => props.fullWidth && fullWidthPopupStyles({ ...props })}
`;

export const fullWidthPopupStyles = ({ sidebarWidth = 0 }) => ({
  position: 'fixed',
  right: 0,
  bottom: 0,
  // comment this back on if .popup transform is removed
  width: `calc(100% - ${sidebarWidth})`,
  // width: '100%',
  height: `calc(100% - ${Layout.Spacing._2XL})`,
  transition: UtilityEffects.SidebarTransition('width'),
});

export const Content = styled.div`
  grid-row-start: 2;
  grid-column: 1 / 13;
`;

export const ScrollPopupContent = styled(UtilityComponents.Grid)`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  grid-column: 1 /13;
`;

export const PopupActions = ({
  onCancel,
  onPrimary,
  primaryText,
  disabled,
  isLoading,
}) => {
  return (
    <Flex margin="0 0 0 auto" gap="_S">
      <Button text="Cancel" variant="Secondary" size="_M" onClick={onCancel} />
      <Button
        text={primaryText}
        variant="Primary"
        size="_M"
        onClick={onPrimary}
        isLoading={isLoading}
        disabled={disabled}
      />
    </Flex>
  );
};
