import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { NavURIs, Pages } from '../../routes/variables';
import SSOLoginLayout from '../components/SSOLoginLayout';
import { Fields, FormValues, Texts } from './variables';

const { login, ssoLogin } = Pages;
const { [login]: loginRoute } = NavURIs;
const {
  title,
  subtitle,
  minorButtonText,
  mainButtonText,
  minorLeftIcon,
  emailPlaceholder,
} = Texts[ssoLogin];

const { initialValues, validationSchema } = FormValues[ssoLogin];

const { emailField } = Fields[ssoLogin];

const SSOLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get('email'));

  const formRef = useRef(null);

  const navigate = useNavigate();
  const [{ initVerifiedUser }] = useAuth();

  const minorButtonClick = () => {
    const to = loginRoute;
    navigate(to);
  };

  const onSubmit = async ({ email }) => {
    // const response = await doLogin({ email, password: '', is_sso: true });
    // initVerifiedUser({ response });
    formRef.current.submit();
  };

  useEffect(() => {
    if (email) {
      onSubmit({ email });
    }
  }, []);

  const onEmailChange = value => {
    setEmail(value);
  };
  return (
    <SSOLoginLayout
      title={title}
      subtitle={subtitle}
      emailPlaceholder={emailPlaceholder}
      emailName={emailField}
      minorButtonClick={minorButtonClick}
      minorButtonText={minorButtonText}
      mainButtonText={mainButtonText}
      minorLeftIcon={minorLeftIcon}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formRef={formRef}
      formAction={`${process.env.REACT_APP_SERVER}/login`}
      emailValue={email}
      onEmailChange={onEmailChange}
    />
  );
};
export default SSOLogin;
