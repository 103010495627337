import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import Button from '../../../../../../components/molecules/Button/Button';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import PreviewGallery from '../../../../../../components/templates/Gallery/controllers/PreviewGallery';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../../providers/PermissionProvider/PermissionsProvider';
import {
  getStatusColor,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { GalleryPages, GalleryProps } from '../../../../constants';
import {
  componentTitle,
  components,
  infoDescription,
} from '../../controllers/variables';
import {
  RiskAnalysisCircleProgressBar,
  RiskContent,
  RiskType,
  StatusStepsContainer,
} from '../atoms';
import {
  Detail,
  ReasonDescription,
  RecentActivityContainer,
  StatusDescription,
  StepIcon,
  Steps,
  TicketCountContainer,
} from '../molecules';
import { Details, DisableContainer, RiskTypeCSS } from '../style';

const disabledText2Rows = parseStringModifiers(
  'To visualize this data, you need to\nhave access to this service'
);

export const ProductDetailsSummary = ({
  productDetails,
  status,
  photos,
  productId,
  productAlias,
  noShadow,
}) => {
  return (
    <Card
      title={componentTitle[components.productDetails]}
      noShadow={noShadow}
      className="product-details-summary"
    >
      <Flex justify="between">
        <Details>
          {(productDetails ?? []).map(detail => {
            const { title, text } = detail;
            return <Detail title={title} text={text} key={detail.title} />;
          })}
          <Badge type={BadgeType.status} text={status} size="_M" />
        </Details>
        <PreviewGallery
          galleryProps={GalleryProps[GalleryPages.cases]}
          images={photos?.map(photo => photo.ppThumb)}
          id={productId}
          galleryTitle={`Product ${productAlias}`}
        />
      </Flex>
    </Card>
  );
};

export const StatusSteps = ({
  steps,
  statusInstructions,
  status,
  actions,
  size,
  isLoading,
}) => {
  const { hasPermission } = usePermissions();

  const leftbarColor = getStatusColor(status);
  return (
    <StatusStepsContainer leftbarColor={leftbarColor} className="status-steps">
      <Steps steps={steps} />
      <StepIcon status={status} size={size} />
      <StatusDescription
        status={status}
        statusInstructions={statusInstructions}
        actions={actions}
        isLoading={isLoading}
        hasEditPermission={hasPermission(
          PermissionKeys.Brand.PRODUCTS.SELF,
          PermissionKeys.Brand.PRODUCTS.EDIT_PRODUCT_STATUS
        )}
      />
    </StatusStepsContainer>
  );
};

export const RiskAnalysis = ({
  riskType,
  reason,
  noShadow,
  size,
  icon,
  isLoading,
  disabled,
  ...props
}) => {
  return (
    <DisableContainer
      disabled={disabled}
      className={`${props.className ?? ''} risk-analysis`}
    >
      <Card
        title={componentTitle[components.riskAnalysis]}
        noShadow={noShadow}
        icon={
          icon && (
            <InfoIcon description={infoDescription[components.riskAnalysis]} />
          )
        }
      >
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            <RiskContent size={size}>
              <RiskTypeCSS>
                <RiskType text={riskType} size={size} />
              </RiskTypeCSS>
              <ReasonDescription
                reasonTitle="REASON"
                title={reason?.title}
                description={reason?.description}
              />
            </RiskContent>
            <RiskAnalysisCircleProgressBar
              data={reason?.riskPotential}
              size={size}
            />
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </DisableContainer>
  );
};

export const TicketCount = ({ text, goToTickets, display }) => {
  return (
    <TicketCountContainer text={text} onClick={goToTickets} display={display} />
  );
};

export const RecentActivity = ({
  activity,
  showThisMany = undefined,
  noShadow,
  icon,
}) => {
  return (
    <Card
      title={componentTitle[components.recentActivity]}
      className="recent-activity"
      noShadow={noShadow}
      icon={
        icon && (
          <InfoIcon description={infoDescription[components.recentActivity]} />
        )
      }
    >
      <RecentActivityContainer
        activity={activity}
        showThisMany={showThisMany}
      />
    </Card>
  );
};

export const CaseDetailsButton = ({ text, onClick }) => {
  return (
    <div style={{ marginTop: 'auto' }}>
      <Button
        text={text}
        size="_M"
        variant={'Secondary'}
        fullWidth
        rightIcon={Icons.ExternalLink}
        onClick={onClick}
      />
    </div>
  );
};
