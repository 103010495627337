import { useReducer } from 'react';
import { API } from '../lib/api';
import { useAuth, useReauth } from '../providers/AuthProvider';
import { sanitizeStrings } from '../utils/stringUtils';

const removeRequest = (requests, id) => requests.filter(req => req != id);

const dataPostReducer = (state, { payload, requestId, type, body, error }) => {
  const remainingRequests = removeRequest(state.requests, requestId);
  switch (type) {
    case 'POST_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        requests: [...state.requests, requestId],
      };
    case 'POST_SUCCESS':
      return {
        ...state,
        isLoading: !!remainingRequests.length,
        isError: false,
        data: payload,
        requests: remainingRequests,
        body,
      };
    case 'POST_FAILURE':
      let error_msg;
      try {
        error_msg = JSON.parse(error.message).message;
      } catch (_) {
        error_msg = error?.message ?? 'Error';
      }
      return {
        ...state,
        isLoading: !!remainingRequests.length,
        isError: true,
        error: error_msg,
        data: payload,
        requests: remainingRequests,
        body,
      };
    case 'REAUTH':
      return {
        ...state,
        isLoading: true,
        requests: remainingRequests,
      };
    default:
      throw new Error();
  }
};

const usePostData = (
  url,
  {
    params: postParams = {},
    type = 'post',
    formatter = data => data,
    errorFormatter = error => error,
    errorCallback,
  } = {}
) => {
  const [state, dispatch] = useReducer(dataPostReducer, {
    isLoading: false,
    error: { message: undefined },
    isError: false,
    data: null,
    requests: [],
  });

  const [{ token }] = useAuth();

  const { withReauth, handleApiError, handleResponseError } = useReauth();

  const post = (postBody, url_) =>
    withReauth(postData, token, [postBody, url_]);

  const postData = async (token, body, url_ = url) => {
    const requestId = String(Math.random());
    dispatch({ type: 'POST_INIT', requestId });

    const oldParams = postParams || {};

    const params = {
      ...oldParams,
    };

    const config = {
      params,
      headers: {
        Authorization: token,
      },
    };

    body = sanitizeStrings(body);

    try {
      const { data, status, error } =
        type === 'post'
          ? await API.POST(url_, body, config)
          : type === 'put'
          ? await API.PUT(url_, body, config)
          : '';

      handleResponseError({ status, error });

      dispatch({
        type: 'POST_SUCCESS',
        payload: formatter(data),
        requestId,
        body,
      });
      return {
        data: formatter(data),
        status,
        error,
        body,
      };
    } catch (error) {
      // REAUTH keeps isLoading === true (and removes requestId)
      // if handleApiError() doesn't throw, POST_FAILURE takes over
      dispatch({ type: 'REAUTH', requestId });
      handleApiError(error);
      if (typeof errorCallback === 'function') errorCallback();
      dispatch({
        type: 'POST_FAILURE',
        payload: errorFormatter(error),
        body,
        requestId,
        error,
      });
      return {
        error,
      };
    }
  };

  return [state, post];
};

export default usePostData;
