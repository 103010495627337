import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { KnowledgeBaseTable } from './organisms/organisms';

const KnowledgeBaseLayout = ({
  headers,
  data,
  grid,
  rowAction,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        <KnowledgeBaseTable
          headers={headers}
          data={data}
          rowAction={rowAction}
          grid={grid}
        />
      }
    />
  );
};
export default KnowledgeBaseLayout;
