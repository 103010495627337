import {
  NoData,
  NoResults,
} from '../../../../components/atoms/Icon/Icon.options';
import { Constants } from '../../../../constants';

// TODO: moves these to Icon.options.js

import { ReactComponent as EmptyAuthorityIcon } from '../../../../assets/svg/no_results/brand/authority.svg';
import { ReactComponent as EmptyNotesIcon } from '../../../../assets/svg/no_results/brand/notes.svg';
import { ReactComponent as EmptyTicketsIcon } from '../../../../assets/svg/no_results/brand/support_tickets.svg';
import {
  components,
  componentTitle,
} from '../../features_public/products/controllers/variables';

const { Status } = Constants;

export const statusSteps = {
  Sideload: Status.Sideload,
  Inspection: Status.Inspection,
  Authentication: Status.Authentication,
  Count_NonCount: Status.Counterfeit + '/' + Status['Non-counterfeit'],
  Counterfeit: Status.Counterfeit,
  'Non-counterfeit': Status['Non-counterfeit'],
  Released: Status.Released,
  Stopped: Status.Stopped,
  Seized: Status.Seized,
};

export const stepNumber = {
  [statusSteps.Sideload]: 1,
  [statusSteps.Inspection]: 2,
  [statusSteps.Authentication]: 3,
  [statusSteps.Count_NonCount]: 4,
  [statusSteps.Counterfeit]: 4,
  [statusSteps['Non-counterfeit']]: 4,
  [statusSteps.Released]: 5,
  [statusSteps.Stopped]: 5,
  [statusSteps.Seized]: 6,
};

export const statusStepsHistory = {
  [Status.Inspection]: [
    statusSteps.Sideload,
    statusSteps.Inspection,
    statusSteps.Authentication,
  ],
  [Status.Authentication]: [
    statusSteps.Inspection,
    statusSteps.Authentication,
    statusSteps.Count_NonCount,
  ],
  [Status.Counterfeit]: [
    statusSteps.Authentication,
    statusSteps.Counterfeit,
    statusSteps.Stopped,
  ],
  [Status['Non-counterfeit']]: [
    statusSteps.Authentication,
    statusSteps['Non-counterfeit'],
    statusSteps.Released,
  ],
  [Status.Stopped]: [
    statusSteps.Counterfeit,
    statusSteps.Stopped,
    statusSteps.Seized,
  ],
  [Status.Seized]: [statusSteps.Stopped, statusSteps.Seized],
  [Status.Released]: [statusSteps['Non-counterfeit'], statusSteps.Released],
};

const authenticationRequiredText = `**Your authentication is required** to determine if this item is counterfeit. 

##Counterfeit items will be handed to the relevant authority##
##"Non-counterfeit" items will be released to the receiver##`;

const authenticationRequiredTextNonOwner = `**Brand authentication is required** to determine if this item is counterfeit. 

##Counterfeit items will be handed to the relevant authority##
##"Non-counterfeit" items will be released to the receiver##`;

export const statusStepsText = {
  [Status.Inspection]: `**A Countercheck Facilitator is looking into this item.**
  
        ##Counterfeit items will be handed to the relevant authority##
        ##"Non-counterfeit" items will be relased to the receiver##`,
  [Status.Authentication]: authenticationRequiredText,
  [Status.Counterfeit]: `**You have completed the authentication and declared this product as counterfeit.**
  
          This item will be handed over to the relevant authority shortly.`,
  [Status[
    'Non-counterfeit'
  ]]: `You have completed the authentication and declared this product as non-counterfeit
  
    This item will be released and proceed to its destination shortly.`,
  [Status.Stopped]: `**This product has been identified as counterfeit and is waiting for handover to a legal authority.**

  It is expected that the item will be seized soon. `,
  [Status.Seized]: `**Item seized by the authorities.**

  You can find the details about the seizure in the authority section on the product details page. `,
  [Status.Released]: `**Item has been inspected and no risk identified.**

  Item has been released to the original receiver.`,
};

export const statusStepsTextNonOwner = {
  [Status.Inspection]: `**A Countercheck Facilitator is looking into this item.**
  
  ##Counterfeit items will be handed to the relevant authority##
  ##"Non-counterfeit" items will be relased to the receiver##`,
  [Status.Authentication]: authenticationRequiredTextNonOwner,
  [Status.Counterfeit]: `**Brand has completed the authentication and declared this product as counterfeit.**

  This item will be handed over to the relevant authority shortly.`,
  [Status[
    'Non-counterfeit'
  ]]: `**Brand has completed the authentication and declared this product as non-counterfeit.**

  This item will be released and proceed to its destination shortly.`,
  [Status.Stopped]: `**This product has been identified as counterfeit and is waiting for handover to a legal authority.**

  It is expected that the item will be seized soon. `,
  [Status.Seized]: `**Item seized by the authorities.**

  You can find the details about the seizure in the authority section on the product details page.`,
  [Status.Released]: `**Item has been inspected and no risk identified.**

  Item has been released to the original receiver.`,
};

export const goToProductDetailsText = 'Show product details';

export const parcelDetailTypes = {
  senderType: 'Sender',
  receiverType: 'Receiver',
  volumeType: 'volume',
  weightType: 'weight',
  trackingType: 'tracking',
  locationType: 'location',
  catchDateType: 'catchDate',
  totalItemsType: 'totalItems',
};

export const parcelDetailTitles = {
  [parcelDetailTypes.senderType]: 'SENDER',
  [parcelDetailTypes.receiverType]: 'RECEIVER',
  [parcelDetailTypes.volumeType]: 'VOLUME',
  [parcelDetailTypes.weightType]: 'WEIGHT',
  [parcelDetailTypes.trackingType]: 'TRACKING',
  [parcelDetailTypes.locationType]: 'LOCATION',
  [parcelDetailTypes.catchDateType]: 'CATCH DATE',
  [parcelDetailTypes.totalItemsType]: 'OWN / TOTAL ITEMS',
};

export const parcelDetailMockText = `**Actor Name**\n\na fake address\n010101\nnot a real country\nunexistent number`;

export const noResults = {
  [components.productNotes]: {
    description: 'You can use this space to add\nnotes about the product',
    icon: <EmptyNotesIcon />,
  },
  [components.authorityInfo]: {
    description:
      'Once the legal authority receives the items,\nyou will find their information here',
    icon: <EmptyAuthorityIcon />,
  },
  [components.productTickets]: {
    description: 'Create a new ticket to ask for help from our support team!',
    icon: <EmptyTicketsIcon />,
  },
};

export const caseNotesConstants = {
  charLimit: 450,
  placeholder: 'Write a note for this product',
  title: componentTitle[components.productNotes],
  noResults: noResults[components.productNotes],
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No products',
    description: 'As soon as we catch a new product, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all products',
  },
};
