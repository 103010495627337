import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Major, Minor } from '../../../../../components/style';
import PreviewGallery from '../../../../../components/templates/Gallery/controllers/PreviewGallery';
import { UtilityComponents } from '../../../../../constants';
import {
  RecentActivity,
  RiskAnalysis,
} from '../../products/components/organisms';
import { Details, StatusDescription } from './organisms';
import { OverviewLayoutCSS } from './style';

export const OverviewLayout = ({
  detailsProps,
  previewGalleryProps,
  descriptionProps,
  riskAnalysisProps,
  recentActivityProps,
  isLoading,
}) => {
  return (
    <OverviewLayoutCSS>
      {isLoading ? (
        <Flex w h center>
          <TableLoader />
        </Flex>
      ) : (
        <UtilityComponents.Grid>
          <Major>
            <Flex justify="between">
              <Details {...detailsProps} />
              <PreviewGallery {...previewGalleryProps} />
            </Flex>
            <StatusDescription {...descriptionProps} />
          </Major>
          <Minor>
            <RiskAnalysis {...riskAnalysisProps} />
            <RecentActivity {...recentActivityProps} />
          </Minor>
        </UtilityComponents.Grid>
      )}
    </OverviewLayoutCSS>
  );
};
