import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Popup } from '../../../../../components/templates/Popup';
import { AddServiceActions, PlanDetails, PlanList } from './organisms';
import { PlanContent } from './style';

export const AddPlanLayout = ({
  isEditing,
  planDetailsProps,
  planListProps,
  actionsProps,
}) => {
  return (
    <Popup size={'_M'} title={isEditing ? 'Edit plan' : 'Add plan'}>
      <PlanContent>
        <Flex column scroll w>
          <Spacer height={'_M'} />
          <PlanDetails planDetailsProps={planDetailsProps} />
          <Spacer height={'_M'} />
          <PlanList {...planListProps} />
        </Flex>
        <AddServiceActions actionsProps={actionsProps} />
      </PlanContent>
    </Popup>
  );
};
