import { LeftSwipe, RightAction } from './atoms';
import { FilesGallery } from './organisms';
import { Container } from './style';

const FileUploadLayout = ({
  showAmount,
  files,
  isLoading,
  storageNames,
  showMultiple,
  fileState,
  handleDrop,
  // TODO: how will these show up when showMultiple = true?
  percentageShares,
  // TODO: how will these show up when showMultiple = true?
  uploadStatusContent,
  removeInstance,
  rightAction,
  swipeLeft,
  rightSwipe,
  addsMoreFiles,
  afterElement,
  beforeElement,
  parentIsLoading,
  preexistingFilesLength,
}) => {
  // TODO: make a single fileupload option
  return (
    <Container className="file-upload-new">
      {files.length > showAmount && !!rightSwipe && (
        <LeftSwipe onClick={swipeLeft} />
      )}
      <FilesGallery
        showAmount={showAmount}
        files={files}
        preexistingFilesLength={preexistingFilesLength}
        fileState={fileState}
        rightSwipe={rightSwipe}
        beforeElement={beforeElement}
        afterElement={afterElement}
        isLoading={isLoading}
        handleDrop={handleDrop}
        storageNames={storageNames}
        percentageShares={percentageShares}
        uploadStatusContent={uploadStatusContent}
        showMultiple={showMultiple}
        parentIsLoading={parentIsLoading}
        removeInstance={removeInstance}
      />
      {!isLoading && !parentIsLoading && (
        <RightAction onClick={rightAction} addsMoreFiles={addsMoreFiles} />
      )}
    </Container>
  );
};
export default FileUploadLayout;
