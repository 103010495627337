import { Card, Text } from '../../../../../../components/atoms';
import { Title } from '../../../../../../components/molecules/Card/Card.style';
import CircleProgressBar from '../../../../../../components/organisms/CircleProgressBar/CircleProgressBar';
import { Colors, FontKeys, Layout } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import {} from '../../../../../../utils/stringUtils';
import { parseStringModifiers } from '../../../../../../utils/utils';
import {
  ConnectingLineCSS,
  RiskAnalysisCircleBarPosition,
  RiskContentCSS,
  StatusActionsCSS,
  StatusStepsContainerCSS,
} from '../style';

const BaseText = ({ text, weight, color }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={weight ?? FontKeys.Weight.Medium}
    color={color}
    whiteSpace="nowrap"
  />
);

export const ProductText = ({ text }) => <BaseText text={text} />;

export const ProductBoldText = ({ text }) => (
  <BaseText text={text} weight={FontKeys.Weight.Semibold} />
);

export const ProductLightText = ({ text }) => (
  <BaseText text={text} color={Colors.Neutral._400} />
);
export const ProductTextDisabled = ({ text }) => (
  <BaseText text={text} color={Colors.Neutral._500} />
);

export const ProductBoldTextDisabled = ({ text }) => (
  <BaseText
    text={text}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Neutral._500}
  />
);

export const ProductLightTextDisabled = ({ text }) => (
  <BaseText text={text} color={Colors.Neutral._500} />
);

export const StatusStepsContainer = ({
  leftbarColor: color,
  children,
  className,
}) => {
  return (
    <StatusStepsContainerCSS className={className}>
      <Card color={color} padding={`${Layout.Spacing._M} 0`} columns={8}>
        {children}
      </Card>
    </StatusStepsContainerCSS>
  );
};

export const ConnectingLine = ({ children, index }) => {
  return <ConnectingLineCSS index={index}>{children}</ConnectingLineCSS>;
};

export const StatusTitle = ({ status }) => {
  return <Title type="card" text={status} />;
};

export const Instructions = ({ statusInstructions }) => {
  const text = parseStringModifiers(statusInstructions);
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const StatusActions = ({ actions, actionClick }) => {
  return (
    <StatusActionsCSS>
      {(actions ?? []).map(({ Component, props }) => (
        <Component {...props} onClick={actionClick(props)} />
      ))}
    </StatusActionsCSS>
  );
};

export const RiskContent = ({ children, size }) => {
  return <RiskContentCSS size={size}>{children}</RiskContentCSS>;
};

export const RiskType = ({ text, size }) => {
  return (
    <Text
      text={text}
      variant={
        size === '_L' ? FontKeys.Variant.Heading : FontKeys.Variant.Paragraph
      }
      size={size === '_L' ? FontKeys.Size.H5 : FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

const customCircleProgressBarSizes = {
  _M: {},
  _L: {
    circleSize: 128,
    innerRadius: 56,
    outerRadius: 56,
    barSize: 16,
  },
};

export const RiskAnalysisCircleProgressBar = ({ data, size }) => {
  return (
    <RiskAnalysisCircleBarPosition size={size}>
      <CircleProgressBar
        data={data}
        {...customCircleProgressBarSizes[size]}
        customLabel={CustomCircleProgresBarLabel(size)}
      />
    </RiskAnalysisCircleBarPosition>
  );
};

export const CustomCircleProgresBarLabel = size => text => {
  return (
    <Text
      text={text}
      variant={
        size === '_L' ? FontKeys.Variant.Heading : FontKeys.Variant.Paragraph
      }
      size={size === '_L' ? FontKeys.Size.H5 : FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const SectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const Date = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const LogText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
