import { useMemo, useReducer } from 'react';

export const useInlineStyle = (coreStyle, props, styleInitial = {}) => {
  const styleKeys = Object.keys(styleInitial);
  const [styleState, dispatch] = useReducer(reducer(styleKeys), {
    ...styleInitial,
  });
  const style = useMemo(() => {
    return coreStyle(styleState, props);
  }, [coreStyle, styleState, props]);
  const setStyle = (type, value) => dispatch({ type, value });

  return [style, setStyle];
};

const reducer =
  styleKeys =>
  (state, { type, value }) => {
    if (type == null) throw Error('no null action type');
    if (!styleKeys.includes(type)) return state;

    return { ...state, [type]: value };
  };
