import { Icon, Text } from '../../../../../../components/atoms';
import { FirstLoad } from '../../../../../../components/atoms/Icon/Icon.options';
import { Colors, FontKeys } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';

export const FirstBarcodeSearchTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};

export const FirstBarcodeSearchSubtitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const FirstBarcodeSearchIllustration = () => {
  return <Icon name={FirstLoad.WhiteLabel} />;
};

export const WarningText = ({ text, size = '_S' }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._700}
    />
  );
};

export const PropertyTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const EditSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};

export const TableText = ({ text, bold, nowWrap, ellipsis }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
    whiteSpace={nowWrap ? 'pre' : 'normal'}
    ellipsis={ellipsis}
  />
);
