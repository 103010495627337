import { uniqueId } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../components/molecules/Button/Button';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  extractSectionOptions,
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import { useGetAdminServices } from '../../../api/getServices';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useDeleteService } from '../api/deleteService';
import { useGetFilters } from '../api/getFilters';
import AdminServicesLayout from '../components/AdminServicesLayout';
import AddService from './AddService';
import { useAdminServicesReducer } from './AdminServices.hook';
import {
  DC,
  adminServicesKeys,
  allOptions,
  expansionGrid,
  expansionHeaders,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const { SELF, VIEW_SERVICES } = PermissionKeys.Admin.ADMIN_SERVICES;

const AdminServices = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const { dismiss, showModal } = useModal();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_SERVICES);
  const hasEditPermission = hasViewPermission;
  const resetFormRef = useRef(null);

  const ownOptions = {};

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useAdminServicesReducer({
    initialSearchParams,
    resetSearchParams,
  });
  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location) || true;
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    (urlHasExclude && filtersIsLoading) ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { services, serviceCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetAdminServices({
    searchParams,
    ignore: ignoreDataFetch,
    with_permissions: true,
  });

  const {
    isCompleted,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
    doDeleteService,
  } = useDeleteService();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcel_evaluation];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError || services == null) return;
    setData(services);
  }, [services, isLoading, isError]);

  useEffect(() => {
    if (deleteIsLoading || !isCompleted) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);
      return;
    }
    showSnackbarSuccess('Service deleted successfully');
    onSuccess();
  }, [isCompleted, deleteIsLoading, deleteIsError]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.SERVICE]: dropdown(DC.SERVICE),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = ``;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.admin_services]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
          endSlot={
            <Button
              text="Add service"
              onClick={showAddServiceModal}
              size="_S"
            />
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  const setFooterPagination = () => {
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  };

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    setFooterPagination();
  }, [isLoading, searchParams]);

  const showAddServiceModal = () =>
    showModal(AddService, {
      services: extractSectionOptions(options?.[DC.SERVICE]),
      onSuccess,
    });

  const showEditServicedModal = ({ item }) =>
    showModal(
      AddService,
      {
        mode: 'edit',
        prevData: item,
        services: extractSectionOptions(options?.[DC.SERVICE]),
        onSuccess,
      },
      {
        ignoreOverlayDismiss: true,
      }
    );

  const showConfirmDeleteService = ({ item, isLoading }) => {
    const onConfirm = async _ => {
      const url = `admin_service/${item[adminServicesKeys.id]}`;
      showConfirmDeleteService({ item, isLoading: true });
      const error = await doDeleteService({ url });
      if (error) showConfirmDeleteService({ item, isLoading: false });
    };
    showModal(ConfirmModal, {
      title: 'Delete service',
      description:
        parseStringModifiers(`Are you sure you want to delete this service?
            **This cannot be undone.**`),
      confirm: 'Delete',
      onConfirm,
      onCancel: dismiss,
      isLoading,
    });
  };

  const onSuccess = () => {
    refreshView();
    dismiss();
  };

  const editService = {
    value: uniqueId(),
    label: 'Edit service',
    id: 'edit-service',
    leftIcon: Icons.Edit3,
    onClick: showEditServicedModal,
  };

  const deleteService = {
    value: uniqueId(),
    label: 'Delete service',
    id: 'delete-service',
    leftIcon: Icons.Trash2,
    onClick: showConfirmDeleteService,
  };

  const floatingMenuOptions = [editService, deleteService];
  const optionClick = item => (option, e) => {
    floatingMenuOptions
      .find(o => o.id === option.id)
      ?.onClick({ item, option, e });
  };

  const rowAction = undefined;
  const noDataAction = undefined;

  return (
    <AdminServicesLayout
      tableProps={{
        data,
        headers,
        grid,
        rowAction,
        tableComponentProps: {
          options: floatingMenuOptions,
          optionClick,
          expansionGrid,
          expansionHeaders,
          expandCountKey: 'permissions',
        },
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default AdminServices;
