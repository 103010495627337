import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Filters } from '../../../../../components/organisms/Filters';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Constants, PropKeys, Utils } from '../../../../../constants';
import { useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import {
  getTimeFrameUrlParams,
  titleFromCalendar,
} from '../../../../../utils/timeUtils';
import ContactSupportButton from '../../../components/molecules/ContactSupportButton/ContactSupportButton';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetActivity } from '../api/getActivity';
import { useGetCategories } from '../api/getCategories';
import { useGetOrigins } from '../api/getOrigins';
import { useGetOverview } from '../api/getOverview';
import { useGetPopulars } from '../api/getPopulars';
import { useGetReferenceCount } from '../api/getReferenceCount';
import { useGetTimeline } from '../api/getTimeline';
import DashboardLayout from '../components/DashboardLayout';
import {
  DC,
  allOptions,
  authenticationDestination,
  dashboardKeys,
  filtersConstants,
  navToEvalCasesButton,
  overviewDestination,
  parcelStatusesDestination,
  titles,
} from '../variables';
import { dispatchAction, useDashboardReducer } from './Dashboard.hook';
import WelcomePopup from './WelcomePopup';

const { SELF, VIEW_DASHBOARD_REFERENCES, VIEW_DASHBOARD_PRODUCTS } =
  PermissionKeys.Brand.DASHBOARD;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { store } = useStore();
  const { showModal } = useModal();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasReferencePermission = hasPermission(VIEW_DASHBOARD_REFERENCES);
  const hasProductPermission = hasPermission(VIEW_DASHBOARD_PRODUCTS);

  const resetFormRef = useRef(null);

  const inactiveBrand =
    store[PropKeys.brandStatus] === Constants.BrandStatus.Inactive;

  let ownOptions;
  let options;
  let filtersIsError;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useDashboardReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const {
    data: referenceCount,
    isLoading: referenceCountIsLoading,
    isError: referenceCountIsError,
    refresh: refreshReferenceCount,
  } = useGetReferenceCount({
    searchParams,
    hasReferencePermission,
  });

  const {
    data: { overviewStats, authenticationStats, parcelStatuses },
    isLoading: overviewIsLoading,
    isError: overviewIsError,
    refresh: refreshOverview,
  } = useGetOverview({
    searchParams,
    hasReferencePermission,
    hasProductPermission,
  });

  const {
    data: { data: populars, bars: popularsBars },
    isLoading: popularsIsLoading,
    isError: popularsIsError,
    refresh: refreshPopulars,
  } = useGetPopulars({
    searchParams,
    inactiveBrand,
    ignore: !hasProductPermission,
  });
  const {
    data: { data: timeline, lines: timelineLines, keys: timelineKeys },
    isLoading: timelineIsLoading,
    isError: timelineIsError,
    refresh: refreshTimeline,
  } = useGetTimeline({
    searchParams,
    inactiveBrand,
    ignore: !hasProductPermission,
  });
  const {
    data: { data: origins, total, main, colors: originColors },
    isLoading: originsIsLoading,
    isError: originsIsError,
    refresh: refreshOrigins,
  } = useGetOrigins({
    searchParams,
    inactiveBrand,
    ignore: !hasReferencePermission,
  });
  const {
    data: categories,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    refresh: refreshCategories,
  } = useGetCategories({
    searchParams,
    inactiveBrand,
    ignore: !hasProductPermission,
  });
  const {
    data: activity,
    isLoading: activityIsLoading,
    isError: activityIsError,
    refetch: refetchActivity,
  } = useGetActivity({
    searchParams,
    inactiveBrand,
    ignore: !hasProductPermission,
  });

  useEffect(() => {
    const { state } = location;

    if (
      state?.from === 'login' &&
      store[PropKeys.brandStatus] === Constants.BrandStatus.Inactive
    )
      showWelcomePopup();
  }, [store[PropKeys.brandStatus]]);

  const showWelcomePopup = () => {
    showModal(WelcomePopup);
  };

  const refreshTable = () => {
    refreshOverview();
    refreshPopulars();
    refreshTimeline();
    refreshOrigins();
    refreshCategories();
  };

  const refetchFilters = Utils.emptyFunction;

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
      fullWidth: true,
    },
    [DC.REFRESH]: { onClick: refreshView },
  };

  const onOverviewClick = item => () => {
    const { status } = item ?? {};

    const statusURLParam = overviewDestination[status];
    if (status === dashboardKeys.referenceCount) {
      const to =
        statusURLParam + getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]);
      navigate(to);
      return;
    }
    doProductNav(statusURLParam);
  };

  const onAuthenticationClick = item => () => {
    const { status } = item ?? {};
    const statusURLParam = authenticationDestination[status];
    const to = `${NavURIs[Pages.authentication]}${statusURLParam}`;
    navigate(to);
  };

  const onParcelStatusClick = item => () => {
    const { status } = item ?? {};
    const statusURLParam = parcelStatusesDestination[status];
    doProductNav(statusURLParam);
  };

  const onOriginClick = item => () => {
    const { [dashboardKeys.referenceCountry]: name } = item;
    let to = `${NavURIs[Pages.reference_index]}`;
    to += getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]);
    if (name !== 'Other') to += `?country=${name}`;
    if (name === 'Other') {
      const excludedCountries = origins
        .map(origin => origin[dashboardKeys.referenceCountry])
        .filter(origin => origin !== 'Other');

      to += `?country=${Constants.excludeFilters}+${excludedCountries.join(
        '+'
      )}`;
    }

    navigate(to);
  };

  const onCategoryClick = item => () => {
    const { productId } = item.category ?? {};
    if (!productId) return;

    const categoryIdParam = `?category=${productId}`;
    const statusURLParam = `?status=${Constants.Status.Stopped}+${Constants.Status.Seized}`;

    doProductNav(categoryIdParam, statusURLParam);
  };

  const onActivityClick = item => () => {
    const to = `${NavURIs[Pages.products]}?search=${
      item[dashboardKeys.caseAlias]
    }`;

    navigate(to);
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  useEffect(() => {
    const FilterStyles = styled.div`
      max-width: 416px;
      min-width: 416px;
    `;
    const headerChildren = (
      <Flex justify="between" w align="center">
        <ViewTitle text={Labels[Pages.dashboard]} noNav />
        <Flex gap="_XS">
          <FilterStyles children={<Filters filters={filters} />} />
          <ContactSupportButton />
        </Flex>
      </Flex>
    );

    setTimeout(() => setHeaderChildren(headerChildren), 0);
  }, [searchParams]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  const popularsTitle = 'Top detections';

  const evalNavigateButton = {
    ...navToEvalCasesButton,
    onClick: () => {
      const statusURLParam = `?status=${[
        Constants.Status.Authentication,
        Constants.Status.Counterfeit,
        Constants.Status['Non-counterfeit'],
      ].join('+')}`;

      doProductNav(statusURLParam);
    },
  };

  const doProductNav = (...urlParams) => {
    const brandsURLParam = `?ownBrandId=${
      store[PropKeys.brandId]
    }?=ownBrandName=${store[PropKeys.brandName]}`;
    const to =
      NavURIs[Pages.products] +
      getTimeFrameUrlParams(searchParams[DC.TIME_FRAME]) +
      brandsURLParam +
      urlParams.join('');

    navigate(to);
  };

  return (
    <DashboardLayout
      overviewStats={[...referenceCount, ...overviewStats]}
      authenticationStats={authenticationStats}
      evalNavigateButton={evalNavigateButton}
      parcelStatuses={parcelStatuses}
      populars={populars}
      popularsBars={popularsBars}
      popularsTitle={popularsTitle}
      timeline={timeline}
      timelineLines={timelineLines}
      timelineKeys={timelineKeys}
      origins={origins}
      total={total}
      main={main}
      categories={categories}
      activity={activity}
      overviewIsLoading={overviewIsLoading}
      popularsIsLoading={popularsIsLoading}
      timelineIsLoading={timelineIsLoading}
      originsIsLoading={originsIsLoading}
      categoriesIsLoading={categoriesIsLoading}
      activityIsLoading={activityIsLoading}
      overviewIsError={overviewIsError}
      popularsIsError={popularsIsError}
      timelineIsError={timelineIsError}
      originsIsError={originsIsError}
      categoriesIsError={categoriesIsError}
      activityIsError={activityIsError}
      inactiveBrand={inactiveBrand}
      originColors={originColors}
      onOverviewClick={onOverviewClick}
      onAuthenticationClick={onAuthenticationClick}
      onParcelStatusClick={onParcelStatusClick}
      onOriginClick={onOriginClick}
      onCategoryClick={onCategoryClick}
      onActivityClick={onActivityClick}
      hasProductPermission={hasProductPermission}
      hasReferencePermission={hasReferencePermission}
    />
  );
};

export default Dashboard;
