import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { includeConstants } from '../../../utils/styleUtils';
import { Text } from '../../atoms';
import {
  Colors,
  Effects,
  FontKeys,
  Font_Properties,
  Layout,
  Size,
  UtilityColors,
} from '../../style';

export const Container = styled.div`
  width: 100%;
  position: relative;
  ${Styles.flexColumn};
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: ${Effects.Border._S};
  border-radius: ${Effects.Border_Radius._S};
  padding: 0 ${Layout.Spacing._XS};
  gap: ${Layout.Spacing._XS};
  ${props => ({ ...props.style })}
  input::placeholder, textarea::placeholder {
    color: ${Colors.Neutral._400};
    opacity: 1; /* Firefox */
  }
  input:focus::placeholder,
  textarea:focus::placeholder {
    color: ${Colors.Neutral._200};
  }
`;

export const Error = styled.div`
  position: ${props =>
    props.showError || props.preserveSpace ? 'static' : 'absolute'};
  padding-left: ${Layout.Spacing._XS};
  visibility: ${props => (props.showError ? 'visible' : 'hidden')};
`;

const constantStyle = {
  input: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  inputElement: {
    fontWeight: Font_Properties.Weight[FontKeys.Weight.Semibold],
  },
  errorStyle: {
    minHeight: Layout.Spacing._M,
    color: Colors.Error._500,
    display: 'flex',
    alignItems: 'center',
    gap: Layout.Spacing._2XS,
    width: '100%',
  },
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: (state, { size, error, disabled, readOnly }) => {
    const { active, hover, focus } = state;
    return {
      ...dimensions({ size }),
      // NON-CRITICAL: active/hover/focus might be possible with css inside a styled component
      borderColor: error
        ? Colors.Error._500
        : (active || hover || focus) && !disabled && !readOnly
        ? Colors.Primary._600
        : Colors.Neutral._300,
      inputElement: {
        color: disabled & !readOnly ? UtilityColors.Disabled : 'inherit',
      },
      leftIcon: {
        color: error ? Colors.Error._500 : undefined,
        size: Size.Icon[size],
      },
    };
  },
  filter: (_, { size }) => {
    return {
      ...dimensions({ size }),
      border: 'unset',
      leftIcon: {
        color: Colors.Neutral._500,
        size: Size.Icon[size],
      },
    };
  },
  textarea: (state, { size, error, disabled, readOnly }) => {
    const { active, hover, focus } = state;
    return {
      ...dimensions({ size }),
      borderColor: error
        ? Colors.Error._500
        : (active || hover || focus) && !disabled && !readOnly
        ? Colors.Primary._600
        : Colors.Neutral._300,
      width: '100%',
      height: '100%',
      padding: Layout.Spacing._XS,
      inputElement: {
        border: 'none',
      },
    };
  },
};

const dimensions = ({ size }) => {
  return {
    width: Size.Input[size].width,
    height: Size.Input[size].height,
    fontSize: Font_Properties.Size[size],
    lineHeight: Font_Properties.Height[size],
  };
};

export const InputLabel = ({ text }) => {
  return (
    <div style={{ marginBottom: Layout.Spacing._2XS }}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Semibold}
      />
    </div>
  );
};
