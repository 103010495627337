import styled from '@emotion/styled';
import { Styles } from '../../../constants';

export const BaseTimer = styled.div`
  position: relative;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

export const TimerLabel = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  ${Styles.flexCenter};
`;

export const SVG = styled.svg`
  transform: scaleX(-1);
`;

export const G = styled.g`
  fill: none;
  stroke: none;
`;

export const Circle = styled.circle`
  stroke-width: ${({ width }) => width}px;
  stroke: ${({ trackColor }) => trackColor};
`;

export const Path = styled.path`
  stroke-linecap: round;
  transition: 1s linear all;
  color: ${({ color }) => color};
  stroke-dasharray: ${({ strokeDasharray }) => strokeDasharray};
  stroke-width: ${({ width }) => width}px;

  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg);
  transform-origin: center;

  /* Allows the ring to change color when the color value updates */
  // will be useful if we implement different colors at different times
  stroke: currentcolor;
`;
