import {
  Icons,
  Illustrations,
} from '../../../../../components/atoms/Icon/Icon.options';
import { Constants, PropKeys } from '../../../../../constants';

const { Status } = Constants;

export const productKeys = {
  actions: 'actions',
  activity: 'activity',
  attachments: 'attachments',
  attachmentId: 'attachmentId',
  attachmentName: 'attachmentName',
  attachmentURL: 'attachmentURL',
  authorityDetails: 'authorityDetails',
  authorityEmail: 'authorityEmail',
  authorityName: 'authorityName',
  authorityPhone: 'authorityPhone',
  brand: 'brand',
  brandId: 'brandId',
  parcelDetails: 'parcelDetails',
  productAlias: 'productAlias',
  productActions: 'productActions',
  productId: 'productId',
  productDetails: 'productDetails',
  productImage: 'productImage',
  productStatus: 'productStatus',
  category: 'category',
  categoryName: 'categoryName',
  categoryId: 'categoryId',
  createdAt: 'createdAt',
  confidence: 'confidence',
  expansionArrow: 'expansionArrow',
  inspectionDate: 'inspectionDate',
  isContracted: 'isContracted',
  isProductOwner: 'isProductOwner',
  itemQuantity: 'itemQuantity',
  itemQuantityOwn: 'itemQuantityOwn',
  lastActivity: 'lastActivity',
  leftPadding: 'leftPadding',
  notes: 'notes',
  parcelId: 'parcelId',
  parcelDetails: 'parcelDetails',
  parcelActivity: 'parcelActivity',
  photos: 'photos',
  productName: 'productName',
  productQuantity: 'productQuantity',
  reason: 'reason',
  riskType: 'riskType',
  selected: 'selected',
  senderCompany: 'senderCompany',
  senderId: 'senderId',
  senderBrandName: 'senderBrandName',
  senderName: 'senderName',
  shouldBlurActors: 'shouldBlurActors',
  statusIndicator: 'statusIndicator',
  steps: 'steps',
  sorter: 'sorter',
  subCategory: 'subCategory',
  tickets: 'tickets',
  // case details
  productSideloadDate: 'sideloadDate',
  parcelTrackingNumber: 'parcelTrackingNumber',
  parcelVolume: 'parcelVolume',
  parcelWeight: 'parcelWeight',
  sorterCity: 'sorterCity',
  sorterCountry: 'sorterCountry',
  sorterId: PropKeys.sorterId,
  sorterName: 'sorterName',
  parcel: {
    alias: 'alias',
    barcodes: 'barcodes',
    destination: 'has_receiver',
    itemQuantity: 'itemQuantity',
    origin: 'has_sender',
    trackingNumber: 'trackingNumber',
    totalItems: 'totalItems',
    summary: 'summary',
  },
  brandObj: {
    id: 'id',
    isContracted: 'isContracted',
    image: 'image',
    name: 'name',
  },
};

export const statusStepsIcons = {
  [Status.Inspection]: Illustrations.Inspection,
  [Status.Authentication]: Illustrations.AuthenticationRequired,
  [Status.Counterfeit]: Illustrations.AuthenticationCompleted,
  [Status['Non-counterfeit']]: Illustrations.AuthenticationCompleted,
  [Status.Stopped]: Illustrations.Stopped,
  [Status.Seized]: Illustrations.Seized,
  [Status.Released]: Illustrations.Released,
};

export const components = {
  productNotes: 'productNotes',
  productTickets: 'productTickets',
  parcelDetails: 'parcelDetails',
  productDetails: 'productDetails',
  parcelProducts: 'parcelProducts',
  authorityInfo: 'authorityInfo',
  recentActivity: 'recentActivity',
};

export const componentTitle = {
  [components.productNotes]: 'Notes',
  [components.productTickets]: 'Support tickets',
  [components.parcelDetails]: 'Parcel details',
  [components.productDetails]: 'Product details',
  [components.parcelProducts]: 'Parcel products',
  [components.authorityInfo]: 'Authority info',
  [components.recentActivity]: 'Activity',
  [components.riskAnalysis]: 'Risk type',
};

export const infoDescription = {
  [components.parcelDetails]:
    'This section shows all information about the parcel containing the item(s) of this product. Sender and receiver details will be available when the item is handed over to the legal authority.',
  [components.authorityInfo]:
    'This section shows the details about the legal authority handling the parcel if it is seized.',
  [components.recentActivity]:
    'This section shows the changes in the processing status of this product.',
};

export const activityToggleOptions = [
  {
    leftIcon: Icons.Package,
    text: 'Parcel',
    value: 'parcel',
  },
  {
    leftIcon: Icons.Tag,
    text: 'Product',
    value: 'product',
  },
];
