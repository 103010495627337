import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { openNewTab } from '../../../../../utils/utils';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import ProductSummary from '../../../features_public/products/controllers/ProductSummary';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { ticketTypes } from '../../../features_public/support/controllers/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { useGetCaseDetails } from '../../products/api/getCaseDetails';
import { useGetProductDetails } from '../../products/api/getProductDetails';
import { formatMessage, useGetMessages } from '../api/getMessages';
import { useGetTicket } from '../api/getTicket';
import { useToggleRead } from '../api/toggleRead';
import { TicketConversationLayout } from '../components/TicketConversationLayout';
import { getAwaitingResponseMessage } from './utils';

const {
  SELF,
  EDIT_GENERAL_SUPPORT_TICKETS,
  EDIT_OWN_GENERAL_SUPPORT_TICKETS,
  EDIT_PARCEL_SUPPORT_TICKETS,
  EDIT_OWN_PARCEL_SUPPORT_TICKETS,
  EDIT_PRODUCT_SUPPORT_TICKETS,
  EDIT_OWN_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

export default function TicketConversation() {
  const [awaitingResponseMessage, setAwaitingResponseMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [hasProduct, setHasProduct] = useState(false);

  const { ticketId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { showModal } = useModal();
  const { showSnackbarError } = useSnackbar();
  const { store } = useStore();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const conversationRef = useRef(null);
  const caseImageRef = useRef(null);

  const {
    data: ticket = {},
    isLoading: ticketIsLoading,
    isError: ticketIsError,
    error: ticketError,
  } = useGetTicket({
    ticketId,
  });

  useEffect(() => {
    const headerChildren = (
      <ViewTitle text={ticket?.mainTopic} backPage={NavURIs[Pages.support]} />
    );

    setHeaderChildren(headerChildren);
  }, [ticket.mainTopic]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  const getItems = type => {
    if (type === ticketTypes.Parcel) return useGetCaseDetails;
    if (type === ticketTypes.Product) return useGetProductDetails;
    return useGetProductDetails;
  };

  const manualGet = type => {
    if (type === ticketTypes.Parcel) return 'getCaseDetails';
    if (type === ticketTypes.Product) return 'getProductDetails';
    return 'getProductDetails';
  };

  const [productData, setProductData] = useState([]);

  const {
    data: productDataResult = { images: [], data: { images: [] } },
    isLoading: productDataIsLoading,
    isError: productDataIsError,
    error: productDataError,
    [manualGet(ticket.type)]: getItemDetails,
  } = getItems(ticket.type)({});

  useDeepCompareEffect(() => {
    if (ticket.type == null) return;
    setProductData(productDataResult);
  }, [productDataResult]);

  const {
    data: messagesResponse,
    isLoading: messagesIsLoading,
    isError: messagesIsError,
    error: messagesError,
  } = useGetMessages({
    ticketId,
  });

  const { doToggleRead } = useToggleRead({
    ticketId,
  });

  useEffect(() => {
    if (
      ticket.type === ticketTypes.Parcel ||
      ticket.type === ticketTypes.Product ||
      // remove once db is updated
      ticket.type === 'Case'
    ) {
      setHasProduct(true);
      getItemDetails(ticket.reference);
    }
  }, [ticket]);

  useDeepCompareEffect(() => {
    if (messagesIsError) return;
    setMessages(messagesResponse);
  }, [messagesResponse]);

  useEffect(() => {
    if (conversationRef.current)
      conversationRef.current.scrollTo(0, conversationRef.current.scrollHeight);

    setAwaitingResponseMessage(
      getAwaitingResponseMessage(messages, ticket.status === 'Resolved')
    );
  }, [messages, ticket.status]);

  useEffect(() => {
    const noError = !productDataIsError && !ticketIsError && !messagesIsError;
    if (noError) return;
    const error_msg = productDataIsError
      ? productDataError
      : ticketIsError
      ? ticketError
      : messagesIsError
      ? messagesError
      : null;
    showSnackbarError(error_msg);
    onErrorGoBack();
  }, [productDataIsError, ticketIsError, messagesIsError]);

  useEffect(() => {
    const body = {
      read: 1,
    };
    doToggleRead(body);
  }, []);

  const onErrorGoBack = () => {
    const { searchParams, from } = location.state ?? {};
    const hasPreviousState = location.key !== 'default';

    const to = from ? from : hasPreviousState ? -1 : NavURIs[Pages.support];
    const state = { searchParams };
    navigate(to, { state });
  };

  const showProductSummary = _ => {
    showModal(ProductSummary, {
      productId: productData[productKeys.productId],
      productAlias: productData[getAliasKey(ticket.type)],
      navigate,
      newTab: true,
    });
  };

  const goToCasesTable = _ => {
    const to = `${NavURIs[Pages.cases]}?search=${
      productData[getAliasKey(ticket.type)]
    }`;

    openNewTab(to);
  };

  const appendSuccessfulMessage = ({ data }) => {
    const { message } = data;
    const newMessage = { ...formatMessage(message), type: 'Brand' };
    setMessages(messages => [...messages, newMessage]);
  };

  const showGallery = e => {
    // we're avoiding showing Gallery, and instead show
    // either product summary or go to parcels page
  };

  const getAliasKey = type => {
    if (type === ticketTypes.Parcel) return parcelKeys.alias;
    if (type === ticketTypes.Product) return productKeys.productAlias;
    return productKeys.productAlias;
  };

  const checkHasEditPermission = ({ type, own_ticket: isParcelOwner }) => {
    if (type == null) return;

    const { Parcel, Product } = ticketTypes;

    switch (type) {
      case Parcel:
        return (
          hasPermission(EDIT_PARCEL_SUPPORT_TICKETS) ||
          (isParcelOwner && hasPermission(EDIT_OWN_PARCEL_SUPPORT_TICKETS))
        );
      case Product:
        return (
          hasPermission(EDIT_PRODUCT_SUPPORT_TICKETS) ||
          (isParcelOwner && hasPermission(EDIT_OWN_PRODUCT_SUPPORT_TICKETS))
        );
      default:
        return (
          hasPermission(EDIT_GENERAL_SUPPORT_TICKETS) ||
          (isParcelOwner && hasPermission(EDIT_OWN_GENERAL_SUPPORT_TICKETS))
        );
    }
  };

  return (
    <TicketConversationLayout
      hasProduct={hasProduct}
      header={ticket.mainTopic}
      showProductSummary={showProductSummary}
      goToCasesTable={goToCasesTable}
      showGallery={showGallery}
      productData={productData}
      title={productData[getAliasKey(ticket.type)]}
      ticket={ticket}
      ticketId={ticketId}
      messages={messages}
      awaitingResponseMessage={awaitingResponseMessage}
      conversationRef={conversationRef}
      facilitatorName={ticket.facilitatorName ?? 'the facilitator'}
      facilitatorImage={ticket.facilitatorImage}
      onSendMessageSuccess={appendSuccessfulMessage}
      caseImageRef={caseImageRef}
      productDataIsLoading={productDataIsLoading || ticketIsLoading}
      messagesIsLoading={messagesIsLoading}
      type={ticket.type}
      hasEditPermission={checkHasEditPermission(ticket)}
    />
  );
}
