import { Input } from '../../../../../components/molecules';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import FileUpload from '../../../../../features/file_upload/controllers/FileUpload';
import { AuthenticityBadge } from '../components/organisms';
import { ItemDescripitionCSS } from '../components/style';

export const CharacteristicUploadLayout = ({
  onPrimary,
  assignHandleImport,
  onRemoveItem,
  onFileStateUpdate,
  onDetailChange,
  disabled,
  isLoading,
  filesUploading,
  // Authenticity dropdown
  authenticityBadgeMenuOptions,
  authenticityBadgeColors,
  selectOption,
  getSelectedOption,
  characteristicType,
  onCancel,
  onBack,
  authenticityBadgeIcons,
}) => {
  return (
    <StepPopup
      title={`New knowledge base - ${characteristicType}`}
      sectionTitle="Add your images and choose whether they refer to an non-counterfeit or a counterfeit reference"
      onPrimary={onPrimary}
      disabled={disabled}
      isLoading={isLoading}
      onCancel={onCancel}
      onBack={onBack}
    >
      <FileUpload
        showMultiple
        assignHandleImport={assignHandleImport}
        onRemoveItem={onRemoveItem}
        onFileStateUpdate={onFileStateUpdate}
        parentIsLoading={isLoading}
        beforeElement={
          filesUploading
            ? undefined
            : (file, index) => (
                <AuthenticityBadge
                  file={file}
                  options={authenticityBadgeMenuOptions}
                  color={
                    authenticityBadgeColors[getSelectedOption(index)?.value]
                  }
                  icon={authenticityBadgeIcons[getSelectedOption(index)?.value]}
                  selectOption={selectOption(index)}
                  selectedOption={getSelectedOption(index)}
                />
              )
        }
        afterElement={(file, index) => (
          <ItemDescripitionCSS>
            <Input
              onChange={onDetailChange(index)}
              placeholder={'Provide a description for this image'}
              type="textarea"
              name={String(index)}
              hasError
              readOnly={isLoading}
            />
          </ItemDescripitionCSS>
        )}
      />
    </StepPopup>
  );
};
