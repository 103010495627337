import { StatusColors } from '../../../../../components/style';
import { Constants } from '../../../../../constants';
import { magnitudeFormatter } from '../../../../../utils/stringUtils';
import { maskEntry } from '../../../../../utils/utils';
import {
  authenticationStats,
  authenticationTitles,
  dashboardKeys,
  overviewProductStats,
  parcelStatuses,
} from '../variables';
import { formatOverviewStat } from './utils';

export const formatOverview =
  ({ hasProductPermission }) =>
  data => {
    data = maskEntry(data, mask);

    const parcelStatuses = getParcelStatuses(data, { hasProductPermission });
    const overviewStats = formatOverviewStat(overviewProductStats)(
      data[dashboardKeys.allCases],
      { hasPermission: hasProductPermission }
    );
    const authenticationStats = getAuthenticationStats(
      data[dashboardKeys.allAuthentications],
      data[dashboardKeys.allCases]?.[dashboardKeys.casesTotal]
    );

    return { parcelStatuses, overviewStats, authenticationStats };
  };

const getAuthenticationStats = (data, casesTotal) => {
  return authenticationStats.map(stat => ({
    title: authenticationTitles[stat],
    status: stat,
    count: casesTotal
      ? magnitudeFormatter(data?.[stat]) ?? Constants.fallback
      : Constants.fallback,
    navigate: true,
  }));
};

const getParcelStatuses = (data, { hasProductPermission }) => {
  return parcelStatuses.map(status => {
    const count = data[dashboardKeys.allCases]?.[status] || Constants.fallback;

    const itemCount__ = data[dashboardKeys.productCount]?.[status];

    const itemCount = `${
      magnitudeFormatter(itemCount__) ?? Constants.fallback
    } item${itemCount__ === 1 ? '' : 's'} `;

    const parcelCount__ = data[dashboardKeys.parcelCount]?.[status];
    const parcelCount = `${
      magnitudeFormatter(parcelCount__) ?? Constants.fallback
    } parcel${parcelCount__ === 1 ? '' : 's'} `;

    const countDetail = {
      itemCount,
      parcelCount,
    };

    const color = StatusColors[status];

    return {
      count,
      color,
      status,
      countDetail,
      navigate: !!hasProductPermission,
    };
  });
};

const mask = {
  sender_count: dashboardKeys.referenceCount,
  cases_parcels_count: dashboardKeys.parcelCount,
  authentication_counts: dashboardKeys.allAuthentications,
  product_quantity_count: dashboardKeys.productCount,
  inspection: dashboardKeys.inspectionCount,
  authentication: dashboardKeys.authenticationCount,
  counterfeit: dashboardKeys.counterfeitCount,
  non_counterfeit: dashboardKeys.authenticCount,
  stopped: dashboardKeys.stoppedCount,
  seized: dashboardKeys.seizedCount,
  released: dashboardKeys.releasedCount,
  cases: dashboardKeys.casesTotal,
  case_count: dashboardKeys.allCases,
  authentication_pending: dashboardKeys.pendingCount,
  authentication_complete: dashboardKeys.completeCount,
};
