import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Text from '../../../../../../components/atoms/Text/Text';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { parseStringModifiers } from '../../../../../../utils/utils';
import { SectionTitle } from '../../../../features_public/products/components/atoms';
import {
  BlurContainer,
  ParcelDetailS,
  ProductDetailsContainerCSS,
} from '../style';

export const CaseDetailsContainer = ({ children }) => {
  return <ProductDetailsContainerCSS>{children}</ProductDetailsContainerCSS>;
};

export const ParcelDetailTitle = ({ text }) => {
  return <SectionTitle text={text} />;
};

export const ParcelDetail = ({ text, mock }) => {
  return (
    <ParcelDetailS mock={mock}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
      />
    </ParcelDetailS>
  );
};

const text = parseStringModifiers(
  `This information will be available when the item\nis handed over to the legal authority.`
);

export const Blur = ({ blurColumns }) => {
  return (
    <BlurContainer blurColumns={blurColumns}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
        alignment="center"
      />
    </BlurContainer>
  );
};

export const PriorityBadge = ({ priority }) => {
  return <Badge type={BadgeType.ticket} text={priority} size="_S" />;
};

export const StatusBadge = ({ status }) => {
  return <Badge type={BadgeType.ticket} text={status} size="_S" />;
};

export const EntryTitle = ({ text }) => <SectionTitle text={text} />;

export const EntryInfo = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={FontKeys.Weight.Regular}
  />
);

export const CaseNavigate = ({ onClick }) => {
  return (
    <AnimatedIcon
      name={Icons.ExternalLink}
      theme="dashboard_icon"
      onClick={onClick}
      size={Size.Icon._L}
    />
  );
};

export const ErrorText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
    />
  );
};

export const ParcelAlias = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};
