import styled from '@emotion/styled';
import {
  menuXAxisPadding,
  menuYAxisPadding,
} from '../../../../../components/organisms/Sidebar/Sidebar.style';
import { Layout } from '../../../../../components/style';
import { Styles } from '../../../../../constants';

export const Container = styled.div`
  height: calc(100vh - calc(${menuYAxisPadding} * 2));
  width: calc(280px - calc(${menuXAxisPadding} * 2));
  ${Styles.flexColumn};
`;

export const BrandManagementContainer = styled.div`
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;
