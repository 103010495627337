import { useDeepCompareEffect } from '@react-hookz/web';
import { useEffect, useRef, useState } from 'react';
import { Utils } from '../constants';
import { isEmpty, throttle } from '../lib/js';
import { useSnackbar } from '../providers/SnackbarProvider';

export const useScrollLoad = ({
  hook,
  getMore,
  moreActivityClicked,
  hookSearchParams = {},
  formatParams = x => x,
  scrollRef,
  formatter = Utils.unary,
  search,
  initialLimit = 10,
  limit = 10,
  throttleMS = 50,
  triggerThreshold = 80,
  reassign,
  keepFetching = Utils.emptyFunction,
  ignore,
}) => {
  const [pagination, setPagination] = useState(1);
  const [allLoadedData, setAllLoadedData] = useState([]);
  const [hasDataLeft, setHasDataLeft] = useState(true);
  const [data, setData] = useState([]);

  const { showSnackbarError } = useSnackbar();

  const canGetMore = useRef(false);
  const lastScrollTop = useRef(Infinity);

  const initialParams =
    hookSearchParams && !isEmpty(hookSearchParams)
      ? hookSearchParams
      : { limit: initialLimit, offset: 0 };

  const {
    data: unformattedData,
    isLoading,
    isError,
    error,
    [getMore]: fetchMore,
  } = hook(initialParams);

  const refetch = () => {
    setPagination(1);
    setAllLoadedData([]);
    setData([]);
    setHasDataLeft(true);
    fetchMore({ params: initialParams, reset: true });
    canGetMore.current = false;
    lastScrollTop.current = Infinity;
  };

  useEffect(() => {
    if (ignore) return;
    const definitelyCanGetMore = !isLoading && hasDataLeft;
    canGetMore.current = definitelyCanGetMore;
    if (keepFetching(data) && definitelyCanGetMore) runFetchMore();
  }, [hasDataLeft, isLoading]);

  useDeepCompareEffect(() => {
    if (!unformattedData || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    setData(formatter(unformattedData));
  }, [unformattedData]);

  useDeepCompareEffect(() => {
    setAllLoadedData(x => [...x, ...data]);
  }, [data]);

  useEffect(() => {
    const initialDataLength = initialLimit;
    const subsequentDataLength = (pagination - 1) * limit;
    if (initialDataLength + subsequentDataLength > allLoadedData.length)
      setHasDataLeft(false);
    else setHasDataLeft(true);
  }, [allLoadedData]);

  useDeepCompareEffect(() => {
    const content = scrollRef.current;
    if (!content) return;
    content.addEventListener('scroll', throttleAttemptLoad);
    return () => content.removeEventListener('scroll', throttleAttemptLoad);
  }, [unformattedData, reassign]);

  const runFetchMore = () => {
    try {
      const update = pagination => {
        let params = { ...formatParams(hookSearchParams), search, limit };

        if (pagination === 0) {
          params.limit = initialLimit;
          params.offset = 0;
        } else {
          params.offset = initialLimit + (pagination - 1) * limit;
        }

        fetchMore({ params });
        return pagination + 1;
      };

      setPagination(update);
    } catch {}
  };

  const attemptLoad = () => {
    if (!canGetMore.current || !scrollRef.current || !moreActivityClicked)
      return;
    const { scrollHeight, scrollTop, offsetHeight } = scrollRef.current;
    const scrolledFromTop = scrollHeight - scrollTop;
    const bottomThreshold = offsetHeight + triggerThreshold;

    if (lastScrollTop.current <= scrolledFromTop) {
      lastScrollTop.current = Infinity;
      return;
    }
    if (scrolledFromTop > bottomThreshold) return;
    canGetMore.current = false;
    lastScrollTop.current = scrolledFromTop;
    runFetchMore();
  };

  const throttleAttemptLoad = throttle(attemptLoad, throttleMS);

  return {
    data: allLoadedData,
    isLoading,
    isError,
    fetchMore: runFetchMore,
    refetch,
  };
};
