import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';

export const useGetCases = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { parcels: cases = [], parcel_count: caseCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('parcels', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { cases: formatCases(cases), caseCount },
    isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
  };
};

const formatCases = data => {
  data = maskEntry(data, mask);
  const formattedData = data.map(row => ({
    ...row,
    [caseKeys.statusShares]: formatStatusShares(row[caseKeys.statusShares]),
  }));

  return formattedData;
};

export const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PARCEL_STATUS,
    LOCATION,
    BRAND,
    PAGE,
    ITEMS_PER_PAGE,
    RISK_TYPE,
    include_sorter_cases,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const status = extractSelectedValues(PARCEL_STATUS);
  const sorter_id = extractSelectedValues(LOCATION);
  const brand_id = extractSelectedValues(BRAND);
  const risk_type = extractSelectedValues(RISK_TYPE);

  const formattedParams = {
    search,
    status,
    sorter_id,
    brand_id,
    risk_type,
    include_sorter_cases,
    from_date,
    to_date,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

export const mask = {
  cretaedAt: caseKeys.createdDate,
  case_count: caseKeys.productQuantity,
  last_activity: caseKeys.lastActivity,
  own_quantity: caseKeys.ownQuantity,
  paAlias: caseKeys.alias,
  paID: caseKeys.id,
  paStatus: caseKeys.status,
  paSideloadDate: caseKeys.sideloadDate,
  rest_quantity: caseKeys.totalQuantity,
  soName: caseKeys.sorter,
  soCity: caseKeys.city,
  status_ratios: caseKeys.statusShares,
  cases: caseKeys.products,
  soID: caseKeys.sorterId,
  paRiskType: caseKeys.riskType,
  paTracking: caseKeys.trackingNumber,
  // products
  bcName: productKeys.category,
  brID: productKeys.brandId,
  brName: productKeys.brand,
  brImage: productKeys.brandImage,
  caAlias: productKeys.productAlias,
  caID: productKeys.productId,
  caStatusUpdatedAt: productKeys.lastActivity,
  cpName: productKeys.subCategory,
  cspQuantity: productKeys.itemQuantity,
  is_case_owner: productKeys.isProductOwner,
  is_sorter_case: productKeys.isSorterCase,
  stName: productKeys.productStatus,
};
