import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import { AlphabetBar, BrandList } from '../organisms';
import { BrandListScrollContainer, BrandSelectContainer } from '../style';

export const BrandSelect = ({
  values: chosenBrand,
  options,
  getRef,
  setRef,
  selectBrand,
  disabled,
}) => {
  return (
    <BrandSelectContainer disabled={disabled}>
      <AlphabetBar getRef={getRef} options={options} />
      <BrandListScrollContainer>
        <BrandList
          setRef={setRef}
          brands={options}
          selectBrand={selectBrand}
          chosenBrand={chosenBrand}
        />
      </BrandListScrollContainer>
      {disabled && <Disabled />}
    </BrandSelectContainer>
  );
};
