import usePostData from '../../../../../hooks/postHook';

export const useCreateMessage = ({ ticketId }) => {
  const [{ data, body, isLoading, isError, error }, doSendMessage] =
    usePostData(`support_ticket/${ticketId}/messages`);

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doSendMessage,
  };
};
