import usePostData from '../../../hooks/postHook';
import { userSettingsKeys } from '../controllers/variables';

export const useUpdateUserDetails = () => {
  const [{ body, isLoading, isError, error }, doUpdate] = usePostData('');

  const doUpdateDetails = (values, initialValues) => {
    const updates = [
      {
        url: 'username',
        body: {
          values,
          first_name: values[userSettingsKeys.firstName],
          last_name: values[userSettingsKeys.lastName],
        },
        changed:
          values[userSettingsKeys.firstName] !==
            initialValues[userSettingsKeys.firstName] ||
          values[userSettingsKeys.lastName] !==
            initialValues[userSettingsKeys.lastName],
      },
      {
        url: 'email',
        body: {
          values,
          email: values[userSettingsKeys.email],
          old_email: initialValues[userSettingsKeys.email],
        },
        changed:
          values[userSettingsKeys.email] !==
          initialValues[userSettingsKeys.email],
      },
      {
        url: 'phone',
        body: {
          values,
          phone: values[userSettingsKeys.phone],
        },
        changed:
          values[userSettingsKeys.phone] !==
          initialValues[userSettingsKeys.phone],
      },
    ];

    updates
      .filter(field => field.changed)
      .forEach(update => {
        const { body, url } = update;
        doUpdate(body, `user_profile/${url}`);
      });
  };

  return { body, isLoading, isError, error, doUpdateDetails };
};
