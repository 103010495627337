import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { formatStatusShares } from '../../../../../components/molecules/StatusShares/utils';
import { Colors, Size } from '../../../../../components/style';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../controllers/variables';
import {
  parcelDetailMockText,
  parcelDetailTitles,
  parcelDetailTypes,
} from '../variables';

const { fallback } = Constants;

export const useGetCaseDetails = ({ id, ignore, copyText }) => {
  // parcelId may be missing before manual fetch by getCaseDetails.
  // keep loading until manually fetched unless ignored
  const [
    { data = {}, isLoading = !ignore, isError, error },
    _,
    getCaseDetails_,
  ] = useFetchData('', {
    formatter: formatter({ copyText }),
    ignore: id == null || ignore,
  });

  const getCaseDetails = id => {
    const url = `office_parcel/${id}`;
    getCaseDetails_({ url });
  };

  return {
    data: ignore ? formatter({})({}) : data,
    isLoading,
    isError,
    error,
    getCaseDetails,
  };
};

const formatter =
  ({ copyText }) =>
  data => {
    data = maskEntry(data, mask);
    const shouldBlurActors = false;
    data[caseKeys.statusShares] = formatStatusShares(
      data[caseKeys.statusShares]
    );

    const details = formatSnapshotDetails(data.actors, {
      barcodes: data[productKeys.parcel.barcodes],
      trackingNumber: data[productKeys.parcel.trackingNumber],
      shouldBlurActors,
      catchDate: joinStrings(
        formatDate(data[productKeys.productSideloadDate], DateTypes.ddmmyy),
        formatDate(data[productKeys.productSideloadDate], DateTypes.time12),
        ' - '
      ),
      totalItems: data[productKeys.parcel.totalItems],
      copyText,
    });

    const riskAnalysis = {
      title: getReasonTitle(data[parcelKeys.reason]),
      description: getSideloadExplanation(
        data[parcelKeys.reason],
        data[parcelKeys.riskType]
      ),
      riskPotential: {
        value: data[parcelKeys.confidence] ?? 0,
        display: `${
          data[parcelKeys.confidence] == null
            ? 'n/a'
            : `${data[parcelKeys.confidence]}%`
        }`,
        fill: Colors.Primary._600,
      },
    };

    return {
      ...data,
      [productKeys.parcelDetails]: details,
      [productKeys.shouldBlurActors]: shouldBlurActors,
      [productKeys.reason]: riskAnalysis,
      [productKeys.riskType]: getRiskTypes(data[productKeys.riskType]),
      [parcelKeys.authorityId]: data?.authority?.[parcelKeys.authorityId],
      [parcelKeys.authorityDetails]: getAuthorityDetails(data),
    };
  };

const formatSnapshotDetails = (
  actors,
  {
    barcodes,
    trackingNumber,
    catchDate,
    totalItems,
    shouldBlurActors,
    copyText,
  }
) => {
  const copyableBarcodeElement = barcode => {
    return (
      <AnimatedIcon
        name={Icons.Copy}
        size={Size.Icon._XS}
        onClick={copyText(barcode)}
        theme={'light'}
      />
    );
  };

  const mock = shouldBlurActors;
  const details = [
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.catchDateType],
          info: catchDate ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.totalItemsType],
          info: totalItems ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.trackingType],
          info: trackingNumber ?? fallback,
        },
      ],
    },
    {
      className: 'barcodes',
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.barcodesType],
          info: barcodes?.[0] ?? fallback,
          element: barcodes?.[0] ? copyableBarcodeElement(barcodes[0]) : null,
        },
        ...(barcodes ?? []).slice(1).map(barcode => ({
          info: barcode,
          element: copyableBarcodeElement(barcode),
        })),
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.senderType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.senderType),
        },
      ],
      mock,
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.receiverType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.receiverType),
        },
      ],
      mock,
    },
  ];

  return details;
};

const getActorInfo = (actors, actorType) => {
  const actor = (actors ?? []).find(actor => actor.pacType === actorType) ?? {};
  const formattedInfo =
    '**' +
    (joinStrings(actor?.seName, actor?.seCompany) || fallback) +
    '**' +
    '\n\n' +
    (actor?.seAddress || fallback) +
    '\n' +
    (actor?.sePostcode || fallback) +
    '\n' +
    (actor?.seCountry || fallback) +
    '\n' +
    (actor?.sePhone || fallback);

  return formattedInfo;
};

const getAuthorityDetails = data => {
  if (
    data?.authority == null ||
    data?.authority[parcelKeys.authorityId] == null
  )
    return;
  const details = [
    {
      title: 'Name',
      info: data?.authority?.auName ?? fallback,
    },
    {
      title: 'Email',
      info: data?.authority?.auEmail ?? fallback,
    },
    {
      title: 'Phone',
      info: data?.authority?.auPhone ?? fallback,
    },
    {
      title: 'Fax',
      info: data?.authority?.auFax ?? fallback,
    },
  ];
  return details;
};

const mask = {
  auID: parcelKeys.authorityId,
  paTracking: productKeys.parcel.trackingNumber,
  paVolume: productKeys.parcelVolume,
  paWeight: productKeys.parcelWeight,
  // should_show_actors,
  soCountry: productKeys.sorterCountry,
  soCity: productKeys.sorterCity,
  // own_quantity,
  // rest_quantity,
  paSideloadDate: productKeys.productSideloadDate,
  case_count: parcelKeys.caseCount,
  paAlias: parcelKeys.alias,
  paID: parcelKeys.id,
  paRiskType: parcelKeys.riskType,
  paStatus: parcelKeys.status,
  pdConfidence: parcelKeys.confidence,
  sender_brand_name: parcelKeys.reason,
  // case keys
  case_count: caseKeys.productQuantity,
  last_activity: caseKeys.lastActivity,
  own_quantity: caseKeys.ownQuantity,
  rest_quantity: productKeys.parcel.totalItems,
  soName: caseKeys.sorter,
  status_ratios: caseKeys.statusShares,
};
