import InPlaceForm from '../../../../../components/organisms/InPlaceForm/InPlaceForm';
import { LinkUploadStyles } from '../components/style';

export const LinkUploadLayout = ({
  title,
  element,
  initialValues,
  validationSchema,
  onSubmit,
  fields,
  e,
  offsetX,
  offsetY,
  isLoading,
}) => {
  return (
    <LinkUploadStyles>
      <InPlaceForm
        title={title}
        element={element}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        fields={fields}
        e={e}
        offsetX={offsetX}
        offsetY={offsetY}
        isLoading={isLoading}
      />
    </LinkUploadStyles>
  );
};
