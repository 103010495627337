import { Icon } from '../../atoms';
import { Icons } from '../../atoms/Icon/Icon.options';
import {} from '../../molecules/Badge/Badge';
import { Colors, Size } from '../../style';
import {
  Badge,
  BadgeMenu,
  BadgeText,
  Container,
  iconSizeTransform,
} from './BadgeDropdown.style';

const BadgeDropdown = ({
  size,
  prevIcon,
  text,
  options,
  color,
  disabled,
  selectOption,
  selectedOption,
  readOnly,
}) => {
  return (
    <Container disabled={disabled} readOnly={readOnly}>
      <Badge size={size} color={color} disabled={disabled}>
        {prevIcon && (
          <Icon
            name={prevIcon}
            size={Size.Icon[iconSizeTransform[size]]}
            color={Colors.Sharp.White}
          />
        )}
        <BadgeText text={text} size={size} />
        {!readOnly && (
          <Icon
            name={Icons.ChevronDown}
            size={Size.Icon[iconSizeTransform[size]]}
            color={Colors.Sharp.White}
          />
        )}
      </Badge>
      {/* TODO: make this a molecule, reuse when changing user avatar */}
      {!readOnly && (
        <BadgeMenu
          options={options}
          selectOption={selectOption}
          selectedOption={selectedOption}
        />
      )}
    </Container>
  );
};
export default BadgeDropdown;
