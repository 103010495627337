import styled from '@emotion/styled';
import { Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';

export const AnalyticsContainer = styled(UtilityComponents.Grid)`
  grid-template-rows: 272px 376px 376px 376px 320px;
  .calendar {
    min-width: 268px !important;
    z-index: 200 !important;
  }
  .filters-bar {
    grid-column: 1 / 13;
  }
`;

export const DashboardCard = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
  .card {
    height: 100%;
    filter: ${({ disabled }) => (disabled ? 'blur(1px)' : '')};
  }
  .recharts-responsive-container {
    height: 0 !important;
    flex-grow: 1;
  }
`;

export const AnimationCSS = styled.div`
  width: 100%;
  height: 100%;
  ${Styles.flexColumnCenter};
`;

export const OverviewCSS = styled(DashboardCard)`
  grid-row: 1;
  grid-column: 1 / 5;
`;

export const DetectionDetailsCSS = styled(DashboardCard)`
  grid-row: 1;
  grid-column: 5 / 13;
`;

export const ParcelCountCSS = styled(DashboardCard)`
  grid-row: 2;
  grid-column: 1 / 5;
`;

export const RequestsSecondsCSS = styled(DashboardCard)`
  grid-row: 2;
  grid-column: 5 / 9;
`;

export const ProcessingTimeCSS = styled(DashboardCard)`
  grid-row: 2;
  grid-column: 9 / 13;
`;

export const CounterfeitsCSS = styled(DashboardCard)`
  grid-row: 3;
  grid-column: 1 / 5;
`;

export const ReturnCSS = styled(DashboardCard)`
  grid-row: 3;
  grid-column: 5 / 9;
`;

export const LQCSS = styled(DashboardCard)`
  grid-row: 3;
  grid-column: 9 / 13;
`;

export const ShippingLabelsCSS = styled(DashboardCard)`
  grid-row: 5;
  grid-column: 1 / 13;
`;

export const DepotAreaCSS = styled(DashboardCard)`
  grid-row: 4;
  grid-column: 1 / 7;
`;

export const DangerousGoodsCSS = styled(DashboardCard)`
  grid-row: 4;
  grid-column: 7 / 13;
  .piechart-flex {
    gap: unset;
    margin-top: ${Layout.Spacing._S};
  }
  .piechart-labels {
    > div {
      margin-left: 30%;
    }
  }
`;

export const OverviewDataCSS = styled.div`
  display: grid;
  grid-template-columns: minmax(min(10px, 100%), 1fr) minmax(
      min(10px, 100%),
      1fr
    );
  // grid-row-gap: ${0};
  grid-row-gap: ${Layout.Spacing._M};
  grid-column-gap: ${0};
  // grid-column-gap: ${Layout.Spacing._M};
`;

export const DetectionDataCSS = styled.div`
  display: grid;
  grid-template-columns: minmax(min(10px, 100%), 1fr) minmax(
      min(10px, 100%),
      1fr
    );
  grid-row-gap: ${Layout.Spacing._XS};
  grid-column-gap: ${Layout.Spacing._S};
`;
