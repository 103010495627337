import { Route, Routes } from 'react-router-dom';
import Processing from './Processing';

export const ProcessingRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Processing />} />
    </Routes>
  );
};
