import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Styles, UtilityComponents } from '../../../../../constants';

export const FirstBarcodeSearchCSS = styled.div`
  width: 100%;
  max-width: 624px;
  margin: auto;
  .filters {
    width: 100%;
  }
  gap: ${Layout.Spacing._2XL};
  ${Styles.flexColumnCenter}
`;

export const WhiteLabelLayoutCSS = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const WhiteLabelTableCSS = styled.div`
  display: flex;
  height: 100%;
  #table .label {
    padding: 0;
  }
`;

export const WhiteLabelDetailsCSS = styled.div`
  width: 0;
  flex-grow: 1;
  min-height: 576px;
  display: flex;
  gap: ${Layout.Spacing._L};
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S};
  background: ${Colors.Sharp.White};
  .label {
    padding: 0;
    height: unset;
  }
`;

export const WhitelabelHeaders = styled(Flex)`
  .filters {
    > div {
      max-width: unset !important;
    }
  }
`;

export const WhiteLabelDetailsContainer = styled.div`
  height: 100%;
  width: 0;
  flex-grow: 1;
  justify-content: space-between;
  gap: ${Layout.Spacing._XL};
  ${Styles.flexColumn};
`;

const indent = '12px';
export const BarcodesContainer = styled.div`
  padding-left: ${indent};
  gap: ${Layout.Spacing._XS};
  ${Styles.flexColumn};
  span {
    text-indent: -${indent};
  }
`;

export const BarcodesTableCSS = styled.div`
  max-height: 240px;
  min-height: 240px;
  height: 100%;
  ${Styles.flexColumn};
`;

// ******************************
// *********  START   ***********
// *** WHITE LABEL COMPONENTS ***
// ******************************

export const Hidden = styled.div`
  ${props => (props.isHidden ? 'display: none' : '')}
`;

export const CompanyLabelCSS = styled.div`
  padding: 3mm;
  background: white;
  box-shadow: ${Effects.Box_Shadow._M};
  border-radius: ${Effects.Border_Radius._S};
`;

export const CompanyLabelFields = styled.div`
  ${props => (props.willPrint ? '' : 'transform: scale(0.93259);')}
  display: grid;
  width: 95mm;
  * {
    background: transparent !important;
  }
  border-left: 0.5px solid black;
  border-top: 0.5px solid black;
  border-bottom: 0.5px solid black;
  > * {
    border-right: 0.5px solid black;
    border-bottom: 0.5px solid black;
  }
  grid-template-rows: 10mm 41mm 17mm 17mm 35mm 25mm;
  grid-template-columns: 40mm 18mm 25mm 12mm;
  .entry {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // title
  .grid-item-0 {
    grid-area: 1 / 1 / 2 / 5;
  }
  // receiver
  .grid-item-1 {
    grid-area: 2 / 1 / 3 / 3;
  }
  // sender
  .grid-item-2 {
    grid-area: 2 / 3 / 3 / 4;
  }
  // origin depot
  .grid-item-3 {
    grid-area: 2 / 4 / 3 / 5;
  }
  // delivery details
  .grid-item-4 {
    grid-area: 3 / 1 / 4 / 2;
  }
  // parcel details
  .grid-item-5 {
    grid-area: 3 / 2 / 4 / 3;
  }
  // additional information
  .grid-item-6 {
    grid-area: 4 / 1 / 5 / 2;
  }
  // QR code
  .grit-item-7 {
    grid-area: 4 / 2 / 5 / 3;
  }
  // Aztec
  .grid-item-8 {
    grid-area: 3 / 3 / 5 / 5;
  }
  // Routing
  .grid-item-9 {
    grid-area: 5 / 1 / 6 / 5;
    border-bottom: none;
  }
  // Barcode
  .grid-item-10 {
    grid-area: 6 / 1 / 7 / 5;
    svg {
      max-height: 100%;
    }
    text {
      letter-spacing: 4px;
    }
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  ${Styles.flex};
  > div {
    height: 100%;
  }
`;

export const TitleText = styled.div`
  width: 64mm;
  font-size: 12px;
  ${Styles.flexCenter};
`;

export const CountercheckLogo = styled.div`
  width: 25mm;
  padding: 4px;
  box-sizing: border-box;
  ${Styles.flexCenter};
`;

export const CEPLogo = styled.div`
  width: 13mm;
  padding: 4px;
  box-sizing: border-box;
  ${Styles.flexCenter};
`;

export const Logo = styled.img`
  width: 100%;
`;

const FieldContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px;
`;

export const ReceiverContainer = styled(FieldContainer)`
  padding-top: 0;
`;

export const ReceiverData = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin-top: 1px;
  &:first-of-type {
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 4px;
  }
`;

export const SenderContainer = styled(FieldContainer)`
  writing-mode: vertical-rl;
  transform: rotate(0deg);
  padding-right: 0;
`;

export const SenderData = styled.p`
  font-size: 11px;
  &:first-of-type {
    text-decoration: underline;
    font-weight: bold;
    margin-left: 4px;
  }
`;

export const OriginDepotContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px;
  writing-mode: vertical-rl;
  transform: rotate(0deg);
`;

export const OriginDepotData = styled.p`
  font-size: 10px;
`;

export const DeliveryDetailsContainer = styled(FieldContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const DeliveryDetailsData = styled.p`
  font-size: 10px;
  span {
    font-weight: bold;
  }
`;

export const ParcelDetailsContainer = styled(FieldContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Center = styled.div`
  margin: auto;
`;

export const ParcelDetailsData = styled.p`
  font-size: 12px;
  text-align: center;
  span {
    font-weight: bold;
  }
`;

export const AdditionalInformationContainer = styled(FieldContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const RoutingContainer = styled.div`
  display: grid;
  width: 100%;
  z-index: 1;
  grid-template-rows: 10mm 17mm 10mm;
  grid-template-columns: 27mm 20mm 20mm 27mm;
  .routing-grid-item-0 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .routing-grid-item-1 {
    justify-content: flex-end;
    grid-area: 1 / 3 / 2 / 5;
  }
  .routing-grid-item-2 {
    justify-content: center;
    font-size: 36px;
    font-weight: bold;
    grid-area: 2 / 1 / 3 / 5;
  }
  .routing-grid-item-3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .routing-grid-item-date {
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    grid-area: 3 / 2 / 4 / 4;
  }
  .routing-grid-item-4 {
    justify-content: flex-end;
    grid-area: 3 / 4 / 4 / 5;
  }
`;

export const RoutingData = styled(FieldContainer)`
  display: flex;
  align-items: center;
  border: none;
  font-size: 21px;
  font-weight: 600;
  white-space: nowrap;
`;

// ******************************
// *** WHITE LABEL COMPONENTS ***
// **********  END  *************
// ******************************

export const EditFields = styled(UtilityComponents.Grid)`
  grid-auto-rows: 80px;
  grid-row-gap: 0;
`;

export const EditWhiteLabelContainer = styled.div`
  grid-column: 1 / 13;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
  padding-top: ${Layout.Spacing._S};
  .edit-whitelabel-actions {
    margin-top: auto;
    align-self: flex-end;
  }
`;

export const SenderReturnActionCSS = styled(Flex)`
  margin-top: ${({ action }) => (action ? Layout.Spacing._2XS : '0')};
  .checkbox {
    transform: translateY(
      ${({ action }) => (action ? Layout.Spacing._S : '0')}
    );
  }
`;

export const SingleImagesCSS = styled(Flex)`
  flex-wrap: wrap;
  .item-preview-image {
    min-height: 72px;
    min-width: 72px;
  }
`;
