import { AvatarColors, Colors } from '../../style';

export const getTextAndBackground = ({ username, count, customText }) => {
  if (count == null) count = 0;
  if (count) {
    const text = `+${count}`;
    const background = Colors.Primary._600;
    return { text, background };
  }

  const initial = username?.[0];
  const colorInitial = username?.[0] ?? 'a';

  const lastInitial = username?.split(' ')?.[1]?.[0];
  const colorLastInitial = username?.split(' ')?.[1]?.[0];

  const colorIndex =
    0 +
    (colorInitial?.charCodeAt(0) ?? 0) +
    (colorLastInitial?.charCodeAt(0) ?? 0);

  const moduloIndex = colorIndex % AvatarColors.length;

  const { background } = AvatarColors[moduloIndex];

  const text =
    customText ??
    `${initial?.toUpperCase() ?? ''}${lastInitial?.toUpperCase() ?? ''} `;

  return { text, background };
};
