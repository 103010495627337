import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';

import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Constants } from '../constants';
import usePostData from '../hooks/postHook';
import { isEmpty } from '../lib/js';
import { useReauth } from './AuthProvider';
import {
  DC,
  areValidFileSizes,
  areValidFileType,
  createUploadName,
  extractImageName,
  fileUploadReducer,
  initialState,
} from './FileUploadProvider.utils';
import { useFirebase } from './FirebaseProvider';
import { useSnackbar } from './SnackbarProvider';

const FileUploadContext = React.createContext();

export function useFileUpload() {
  return useContext(FileUploadContext);
}

export function FileUploadProvider({ children }) {
  const [percentage, setPercentage] = useState(0);
  const [percentageShares, setPercentageShares] = useState({});

  const uploadNameRef = useRef(null);
  const uploadNamesRef = useRef([]);

  const { doReauth } = useReauth();
  const { firebaseStorage, publicFirebaseStorage } = useFirebase();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const [fileState, dispatch] = useReducer(fileUploadReducer, initialState);

  const [{ data, body, isLoading, isError }, doUpload] = usePostData('');

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      let errorType = Constants.FileUpload.uploadError;
      if (data?.message?.includes('permission'))
        errorType = Constants.FileUpload.permissionError;

      const { errorCallback } = body;
      if (typeof errorCallback === 'function') errorCallback();

      dispatch({ type: DC.ERROR, payload: errorType });
      return;
    }
    const { successCallback } = body;
    setPercentage(0);
    setPercentageShares({});
    dispatch({ type: DC.SUCCESS });
    if (typeof successCallback === 'function') {
      successCallback({ data, body, fileState });
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (isEmpty(percentageShares) || !fileState?.files) return;
    const total = Object.values(percentageShares).reduce(
      (total, next) => (total += next),
      0
    );
    const averaged = total / fileState.files.length;
    setPercentage(averaged);
  }, [percentageShares]);

  const handleDrop =
    (uploadType, singleFile = false) =>
    userUpload => {
      if (!userUpload) {
        showSnackbarError();
        return;
      }
      // unnecessary if we're succesfully limiting on FileDropzone.js
      if (singleFile) userUpload = userUpload.slice(0, 1);

      const isValidFileType = areValidFileType(userUpload, uploadType);
      const isValidFileSizes = areValidFileSizes(userUpload, uploadType);
      if (!isValidFileType)
        dispatch({
          type: DC.ERROR,
          payload: Constants.FileUpload.extensionError,
        });
      if (!isValidFileSizes)
        dispatch({
          type: DC.ERROR,
          payload: Constants.FileUpload.fileSizeError,
        });

      dispatch({ type: DC.FILES, payload: userUpload });
    };

  const handleImport = async ({
    folder,
    url,
    body: extraBody = {},
    isPublic,
    parameterName = 'name',
    nameMustBeString,
    successCallback,
    errorCallback,
    verbose = false,
  }) => {
    try {
      await doReauth();
      const { files } = fileState;
      const numFilesToUpload = files.length;

      dispatch({ type: DC.UPLOAD });

      uploadNamesRef.current = [];
      const uploadPromises = [];
      files.forEach(file =>
        uploadPromises.push(uploadFile(file, folder, isPublic))
      );

      const filesResponses = await Promise.all(uploadPromises)
        .then(val => val)
        .catch(err => {
          throw err;
        });

      const body = {
        [parameterName]:
          numFilesToUpload.length === 1
            ? files[0].name
            : nameMustBeString
            ? 'multiple files'
            : uploadNamesRef.current.map(name =>
                name?.replace(`${folder}/`, '')
              ),
        folder,
        // if endpoint accepts image_url, there should be only one image uploaded
        image_url: uploadNameRef.current,
        // TODO: keep a storageRef reference and delete the uploaded image if there's an error
      };

      if (url)
        doUpload(
          { ...body, ...extraBody, successCallback, errorCallback },
          url
        );
      else {
        dispatch({ type: DC.SUCCESS });
        if (typeof successCallback === 'function') {
          const successText = `Successfully uploaded ${
            filesResponses.length
          } file${filesResponses.length > 1 ? 's' : ''}`;
          if (verbose) showSnackbarSuccess(successText);

          const willUploadText = `Will upload ${uploadNameRef.current}`;
          if (verbose) showSnackbarSuccess(willUploadText);
          // out of sync, thus uploadNames needs to be sent separately. likely not an issue in the end
          // but better if we can guarantee the fileState sent is up to date
          successCallback({
            body,
            fileState,
            uploadNames: uploadNamesRef.current,
          });
        }
      }
    } catch (error) {
      if (typeof errorCallback === 'function') errorCallback(error);
      dispatch({ type: DC.ERROR, payload: Constants.FileUpload.uploadError });
    }
  };

  const uploadFile = (file, folder, isPublic) => {
    return new Promise((resolve, reject) => {
      const uploadName = createUploadName(file.name);
      const storageRef = ref(
        isPublic ? publicFirebaseStorage : firebaseStorage,
        `${folder}/${uploadName}`
      );
      // if endpoint accepts image_url, there should be only one image uploaded
      uploadNameRef.current = uploadName;
      uploadNamesRef.current = [...uploadNamesRef.current, uploadName];
      // TODO: better name for this, or switch over to using files for this
      // maybe name STORED_FILES or FILES_WITH_STORAGE_NAMES
      dispatch({
        type: DC.STORAGE_NAME,
        payload: { file, storageName: `${folder}/${uploadName}` },
      });

      const task = uploadBytesResumable(storageRef, file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          const increasePercentageShare = share => {
            const newShare = { ...share };
            newShare[uploadName] = percentage;
            return newShare;
          };

          setPercentageShares(increasePercentageShare);
        },
        function error() {
          reject();
        },

        async function complete() {
          resolve();
        }
      );
    });
  };

  const resetFileUpload = () => {
    uploadNameRef.current = null;
    uploadNamesRef.current = [];
    setPercentage(0);
    setPercentageShares({});
    dispatch({ type: DC.RESET });
  };

  const deleteFile = async ({ url, folder, isPublic }) => {
    const urls = typeof url === 'string' ? [url] : url;

    const deletePromises = [];
    urls.forEach(url => deletePromises.push(doDelete(url, folder, isPublic)));

    const success = await Promise.all(deletePromises)
      .then(res => true)
      .catch(err => false);

    return success;
  };

  const doDelete = (fileUrl, folder, isPublic) => {
    const fileName = decodeURIComponent(extractImageName(fileUrl, folder));

    const fileRef = ref(
      isPublic ? publicFirebaseStorage : firebaseStorage,
      `${folder}/${fileName}`
    );

    return deleteObject(fileRef);
  };

  return (
    <FileUploadContext.Provider
      value={{
        handleDrop,
        handleImport,
        deleteFile,
        fileState,
        percentage,
        resetFileUpload,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
}
