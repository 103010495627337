import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { mask as productMask } from '../../../features/cases/api/getCases';
import { parcelKeys } from '../controllers/variables';

export const useGetParcelDetails = ({ parcelId }) => {
  const [{ data, isLoading = true, isError, error }, _, getParcelDetails_] =
    useFetchData(`parcel/${parcelId}`, { formatter, ignore: parcelId == null });

  const getParcelDetails = parcelId => {
    const url = `parcel/${parcelId}`;
    getParcelDetails_({ url });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    getParcelDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);

  return data;
};

const mask = {
  ...productMask,
  brName: parcelKeys.reason,
  case_count: parcelKeys.caseCount,
  paAlias: parcelKeys.alias,
  paID: parcelKeys.id,
  paRiskType: parcelKeys.riskType,
  paSideloadDate: parcelKeys.sideloadDate,
  paStatus: parcelKeys.status,
  paVolume: parcelKeys.parcelVolume,
  paWeight: parcelKeys.parcelWeight,
  cases: parcelKeys.products,
  pdConfidence: parcelKeys.confidence,
  // should_show_actors,
  soCountry: parcelKeys.sorterCountry,
  soCity: parcelKeys.sorterCity,
  // own_quantity,
  // rest_quantity,
  // case keys
  last_activity: parcelKeys.lastActivity,
  // own_quantity: parcelKeys.ownQuantity,
  // rest_quantity: parcelKeys.totalQuantity,
  soName: parcelKeys.sorterName,
  status_ratios: parcelKeys.statusShares,
  paTracking: parcelKeys.trackingNumber,
  caParcel: parcelKeys.id,
  sender_brand_name: parcelKeys.reason,
  // case keys
  own_quantity: parcelKeys.ownQuantity,
  rest_quantity: parcelKeys.totalQuantity,
};
