import { Constants } from '../../../constants';
import { Text } from '../../atoms';
import { FontKeys, Size } from '../../style';

export const BrandName = ({ text, size: _size, bold }) => {
  const { variant, size } = sizeProps[_size];
  return (
    <Text
      text={text ?? Constants.fallback}
      variant={variant}
      size={size}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      ellipsis
    />
  );
};

export const sizeProps = {
  _L: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._L,
    iconSize: Size.Icon._S,
  },
  _M: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._M,
    iconSize: Size.Icon._XS,
  },
  _S: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._S,
    iconSize: Size.Icon._XS,
  },
};
