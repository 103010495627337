import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import {
  CompanyLabel,
  FirstBarcodeSearch,
  WhiteLabelDetails,
  WhiteLabelTable,
} from './organisms/organisms';
import { WhiteLabelLayoutCSS } from './style';

const { SELF, VIEW_WHITE_LABELS, EDIT_WHITE_LABELS, PRINT_WHITE_LABELS } =
  PermissionKeys.Brand.WHITE_LABEL;

const WhiteLabelLayout = ({
  headers,
  data,
  autoFocusSearch,
  focusSearchBar,
  firstPageFilters,
  searchValue,
  printStatusText,
  isLoading,
  isError,
  error,
  firstSearch,
  tableStatusProps,
  printRef,
  printData,
  printLoading,
  barcodeHeaders,
  barcodeGrid,
  barcodeRowAction,
  showGallery,
  edit,
  print,
  isAllBrands,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasEditPermission = hasPermission(EDIT_WHITE_LABELS);
  const hasPrintPermission = hasPermission(PRINT_WHITE_LABELS);

  if (firstSearch && hasPermission(VIEW_WHITE_LABELS))
    return (
      <FirstBarcodeSearch
        filters={firstPageFilters}
        searchValue={searchValue}
        autoFocusSearch={autoFocusSearch}
        focusSearchBar={focusSearchBar}
      />
    );

  return (
    <WhiteLabelLayoutCSS onClick={focusSearchBar}>
      <TableStatus
        isLoading={isLoading}
        isError={isError}
        error={error}
        {...tableStatusProps}
        table={
          !hasPermission(VIEW_WHITE_LABELS) ? (
            <></>
          ) : data.length > 1 ? (
            <WhiteLabelTable
              headers={headers}
              data={data}
              showGallery={showGallery}
              edit={edit}
              print={print}
              hasEditPermission={hasEditPermission}
              hasPrintPermission={hasPrintPermission}
              isAllBrands={isAllBrands}
            />
          ) : (
            <WhiteLabelDetails
              data={data[0]}
              printStatusText={printStatusText}
              isLoading={printLoading}
              barcodeHeaders={barcodeHeaders}
              barcodeGrid={barcodeGrid}
              barcodeRowAction={barcodeRowAction}
              edit={edit}
              print={print}
              showGallery={showGallery}
              hasEditPermission={hasEditPermission}
              hasPrintPermission={hasPrintPermission}
              isAllBrands={isAllBrands}
            />
          )
        }
      />
      <CompanyLabel data={printData} printRef={printRef} willPrint />
    </WhiteLabelLayoutCSS>
  );
};
export default WhiteLabelLayout;
