import { Card } from '../../../../../../components/atoms';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import {
  components,
  componentTitle,
  infoDescription,
} from '../../controllers/variables';
import {
  Metrics,
  ReferenceActivity,
  tableComponents,
} from '../molecules/molecules';
import { ReferenceIndexTableCSS } from '../style';

export const ReferenceIndexTable = ({ headers, data, rowAction, grid }) => {
  return (
    <ReferenceIndexTableCSS
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      components={tableComponents}
    />
  );
};

export const ReferenceSummary = ({ metrics, date, active }) => {
  return (
    <Card
      title={componentTitle[components.referenceSummary]}
      icon={
        <InfoIcon description={infoDescription[components.referenceSummary]} />
      }
      className="reference-summary"
    >
      <Metrics metrics={metrics} />
      <ReferenceActivity date={date} active={active} />
    </Card>
  );
};
