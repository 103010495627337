import useFetchData from '../../../../../hooks/fetchHook';

export const useGetMessages = ({ ticketId, ignore }) => {
  ignore = ignore || ticketId == null;
  const [{ data = [], isLoading, isError, error }, getMessages] = useFetchData(
    `support_ticket/${ticketId}/messages`,
    {
      formatter: formatMessages,
      ignore,
    }
  );

  const doGetMessages = ({ id, ...params }) => {
    const url = `support_ticket/${id}/messages`;
    getMessages({
      url,
      params,
    });
  };

  return {
    data,
    isLoading,
    isError,
    error,
    getMessages: doGetMessages,
  };
};

const formatMessages = data => {
  if (data == null) return [];
  return data.map(message => formatMessage(message));
};

export const formatMessage = message_ => {
  const {
    client_type: type,
    usImage: image,
    message,
    usDisplayName: name,
    createdAt: date,
  } = message_;
  return {
    ...message_,
    type,
    image,
    message,
    name,
    date,
  };
};
