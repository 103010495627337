import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { Icons } from '../../atoms/Icon/Icon.options';
import { FontKeys, Size } from '../../style';
import { FilterTitle } from '../FilterTitle/FilterTitle';
import { OptionsContainer } from './Dropdown.components';
import { Container, SelectedCount } from './Dropdown.style';

export const Dropdown = ({
  open,
  title,
  fullWidth,
  onClick,
  maxDropdownHeight,
  icon,
  isError,
  sections,
  filter,
  onOptionsFilter = Utils.emptyFunction,
  onDropdownClose = Utils.emptyFunction,
  setFieldValue,
  placeholder,
  name,
  highlight,
  showCheckbox = true,
  disabled,
  isFilterApplied,
  someOptionSelected,
  noOfOptionsSelected,
  hideSearch,
  hideCount,
}) => {
  const [inputFocus, setInputFocus] = useState(0);
  const fieldRef = useRef(null);

  useEffect(() => {
    if (open) return;
    onDropdownClose();
    if (fieldRef?.current) setFieldValue(fieldRef.current?.name, '');
  }, [open]);

  const handleBlur = (e, field) => {
    e.stopPropagation();
    fieldRef.current = field;
  };

  const inputProps = {
    name: name,
    placeholder: placeholder ?? `Find a ${String(title ?? '').toLowerCase()}`,
    variant: 'filter',
    size: FontKeys.Size._S,
    onChange: onOptionsFilter,
    onClick,
    readOnly: onOptionsFilter == null,
    autoFocus: inputFocus || open,
    onBlur: handleBlur,
    hideSearch,
    leftIcon: Icons.Search,
  };

  const sectionProps = (section, index) => ({
    key: `section-${index}`,
    className: 'section',
    isError,
    section,
    index,
    filterAction: filter,
    highlight,
    showCheckbox,
    setInputFocus,
    inputProps,
  });

  return (
    <Container
      onClick={onClick}
      disabled={disabled}
      className={'dropdown ' + name ?? ''}
      isFilterApplied={isFilterApplied}
      someOptionSelected={someOptionSelected}
      fullWidth={fullWidth}
      open={open}
    >
      {/* hide when closed, but keep SelectedCount always visible */}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          overflow: open ? 'visible' : 'hidden',
        }}
      >
        <FilterTitle
          title={title}
          iconStart={icon}
          iconEnd={Icons.ChevronDown}
          iconSize={Size.Icon._S}
          open={open}
        />
        <OptionsContainer
          open={open}
          maxHeight={maxDropdownHeight}
          isError={isError}
          sections={sections}
          sectionProps={sectionProps}
        />
      </div>
      {!hideCount ? (
        <SelectedCount
          isFilterApplied={isFilterApplied}
          someOptionSelected={someOptionSelected}
        >
          {noOfOptionsSelected}
        </SelectedCount>
      ) : null}
    </Container>
  );
};
