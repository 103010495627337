const { default: usePostData } = require('../../../../../hooks/postHook');

export const useAddAuthority = ({ id }) => {
  const [{ body, isLoading, isError, error }, doAuthorityAdd] = usePostData(
    `office_parcel/${id}/authority`
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doAuthorityAdd,
  };
};
