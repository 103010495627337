import useFetchData from '../../../../../hooks/fetchHook';

export const useGetTicket = ({ ticketId }) => {
  const [{ data, isLoading, isError, error }] = useFetchData(
    `support_ticket/${ticketId}`,
    { formatter: formatTicket }
  );

  return { data, isLoading, isError, error };
};

export const formatTicket = data => {
  const {
    createdAt: dateCreated,
    first_message: description,
    last_message_date: lastMessage,
    message_count: messageCount,
    own_ticket,
    rep_image: facilitatorImage,
    rep_name: facilitatorName,
    stvIsRead: read,
    supAlias: alias,
    supPriority: priority,
    supReference: reference,
    supStatus: status,
    supTitle: mainTopic,
    supType: type,
    supID: ticketId,
    usImage: image,
    usDisplayName: name,
    brImage: brandImage,
    brName: brandName,
  } = data;

  return {
    alias,
    dateCreated,
    description,
    facilitatorName,
    facilitatorImage,
    lastMessage,
    mainTopic,
    messageCount,
    own_ticket,
    ticketCreator: {
      name,
      image,
      brandName,
      brandImage,
    },
    priority,
    reference,
    read,
    status,
    ticketId,
    type,
  };
};
