import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { fullWidthPopupStyles } from '../../../../../components/templates/Popup/Popup.style';
import { Styles } from '../../../../../constants';

export const ProcessingContainer = styled.div`
  ${Styles.flexColumnCenter};
  .filters {
    width: 634px;
    > div {
      max-width: unset !important;
    }
  }
`;

export const ProcessingToggleCSS = styled.div`
  margin: 0 auto;
  margin-top: 104px;
  width: 471px;
`;

export const ParcelInspectionCSS = styled.div`
  .card.parcel-inspection {
    padding: 0;
    ${props => props && fullWidthPopupStyles({ ...props })};
  }
`;

export const ParcelInspectionContainer = styled.div``;

export const CaseInfoContainer = styled.div`
  flex-grow: 1;
  ${Styles.flexColumn};
  #table {
    flex-grow: unset;
  }
  #tableContainer {
    height: unset;
  }
`;

export const CaseInfoSubtableContainer = styled.div`
  height: 0;
  flex-grow: 1;
  background: ${Colors.Neutral._200};
  padding: 0 ${Layout.Spacing._S};
  ${Styles.flexColumn};
  .add-product-button {
    margin: auto;
  }
`;

export const CaseProductsCSS = styled.div`
  overflow: auto;
  #tableContainer {
    height: unset;
    border-radius: 0 0 ${Effects.Border_Radius._XS} ${Effects.Border_Radius._XS};
  }
  #tableShadow {
    display: none;
  }
  .header-row {
    display: none;
    ._1 span {
      overflow: unset !important;
    }
  }
  ${Styles.flexColumn};
`;

// share across apps to avoid 2 changes if style changes
// (SubtableHeaderContainer)
export const CaseProductsTableHeaderContainer = styled.div`
  z-index: 10;
  position: sticky;
  top: 0px;
  width: 100%;
  padding-top: ${Layout.Spacing._S};
  background: ${Colors.Neutral._200};
  ${Styles.flexColumn};
  .header-row {
    display: grid;
  }
`;

export const CaseProductsTableHeaderCSS = styled.div`
  height: ${Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S} ${Effects.Border_Radius._S} 0 0;
  background: ${({ own }) => (own ? Colors.Primary._600 : Colors.Neutral._100)};
  padding-left: ${Layout.Spacing._M};
  ${Styles.flex};
`;

export const ProductStepNumberCSS = styled.div`
  z-index: 1;
  border-radius: ${Effects.Border_Radius.Circle};
  ${({ selected }) => {
    const size = `calc(${Layout.Spacing._S} - ${
      !selected ? Layout.Spacing._3XS : '0px'
    })`;
    return `
      min-width: ${size};
      max-width: ${size};
      min-height: ${size};
      max-height: ${size};
    `;
  }}
  border: 1px solid
    ${({ selected, beforeCurrentStep }) =>
    selected || beforeCurrentStep ? Colors.Primary._600 : Colors.Neutral._400};
  background: ${({ selected }) =>
    selected ? Colors.Primary._600 : Colors.Sharp.White};
  ${Styles.flexCenter};
`;

export const Line = styled.div`
  width: 1px;
  position: absolute;
  margin: auto;
  height: 100%;
  background: ${({ beforeCurrentStep }) =>
    beforeCurrentStep ? Colors.Primary._600 : Colors.Neutral._400};
  top: 0%;
`;

export const AddProductWizardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${Layout.Spacing._M};
  .add-product-step-indicator {
    min-width: 200px;
    max-width: 200px;
    * {
      transition: unset !important;
    }
  }
  .add-product-step {
    &:last-of-type {
      height: 40px;
    }
  }
  .add-product-step-connection {
    min-width: 24px;
  }
  .display-container {
    cursor: auto;
  }
  .step-value-label {
    width: 0;
    flex-grow: 1;
  }
`;

const AlphabetScrollContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  box-shadow: ${Effects.Box_Shadow._S};
  border-radius: ${Effects.Border_Radius._S};
  filter: ${({ disabled }) =>
    disabled ? 'grayscale(60%) blur(1px)' : 'unset'};
  ${Styles.flexColumn};
  .alphabet-bar {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 0 ${Layout.Spacing._2XS};
    height: ${Layout.Spacing._M};
    background: ${Colors.Neutral._100};
    ${Styles.flex};
  }
  *::-webkit-scrollbar {
    width: 8px;
    display: unset;
  }
`;

const ListScrollContainer = styled.div`
  height: 0;
  flex-grow: 1;
  overflow: auto;
  border-radius: 0 ${Effects.Border_Radius._2XS};
  width: calc(100% - ${Layout.Spacing._2XS});
  ${Styles.flexColumn};
`;

const ScrollableList = styled.div`
  width: 100%;
  height: 0;
  flex-grow: 1;
  padding: ${Layout.Spacing._XS} ${Layout.Spacing._2XS} ${Layout.Spacing._S}
    ${Layout.Spacing._XS};
  gap: ${Layout.Spacing};
  ${Styles.flexColumn};
`;

const ListSection = styled.div`
  width: 100%;
  position: relative;
  gap: ${Layout.Spacing._XS};
  height: ${({ hasEntries }) => (hasEntries ? '' : '0px')};
  visibility: ${({ hasEntries }) => (hasEntries ? 'visible' : 'hidden')};
  margin-bottom: ${({ hasEntries }) =>
    hasEntries ? Layout.Spacing._XS : '0px'};
  ${Styles.flexColumn};
`;

export const BrandSelectContainer = styled(AlphabetScrollContainer)`
  overflow: hidden;
`;

export const BrandListScrollContainer = styled(ListScrollContainer)``;

export const BrandListCSS = styled(ScrollableList)``;

export const BrandAlphabetSectionCSS = styled(ListSection)``;

export const BrandCharSectionCSS = styled.div``;

export const BrandGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(10px, 1fr));
  grid-auto-rows: minmax(${Layout.Spacing._4XL}, min-content);
  grid-gap: ${Layout.Spacing._XS};
`;

export const BrandGridItemCSS = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._2XS};
  border: 1px solid
    ${({ selected }) => (selected ? Colors.Primary._600 : Colors.Neutral._100)};
  ${Styles.flexColumn};
`;

export const BrandNameCSS = styled.div`
  padding: 0 ${Layout.Spacing._3XS};
  height: ${Layout.Spacing._S};
  background: ${({ selected }) =>
    selected ? Colors.Primary._600 : Colors.Neutral._100};
  ${Styles.flexCenter};
`;

export const CategorySelectContainer = styled(AlphabetScrollContainer)``;

export const CategoryListScrollContainer = styled(ListScrollContainer)``;

export const CategoryListCSS = styled(ScrollableList)``;

export const CategoryAlphabetSectionCSS = styled(ListSection)``;

export const NumberDisplayContainer = styled.div`
  width: 100%;
  height: 85px;
  border: 1px solid ${Colors.Neutral._200};
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S} ${Layout.Spacing._XS};
  ${Styles.flex};
  button {
    margin-left: auto;
    margin-right: -${Layout.Spacing._S};
    padding: ${Layout.Spacing._S};
    svg {
      fill: transparent;
    }
  }
`;

export const NumberDisplaySelectedBG = styled.div`
  background: ${({ numbersSelected }) =>
    numbersSelected ? Colors.Primary._050 : 'transparent'};
`;

const blink = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const BlinkingCursor = styled.div`
  width: 0.5px;
  height: 100%;
  background: ${Colors.Neutral._900};
  animation: 1s ${blink} infinite;
`;

export const NumberPadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(10px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: ${Layout.Spacing._XS};
  button {
    height: 56px !important;
  }
`;

export const ZeroButton = styled.div`
  grid-column: 1 / 4;
`;

export const ProductPhotosSelectContainer = styled(AlphabetScrollContainer)``;

export const ProductPhotosListScrollContainer = styled(ListScrollContainer)`
  padding: ${Layout.Spacing._2XS};
`;

export const ProductPhotosListCSS = styled(ScrollableList)``;

export const PhotoQuantityValidatorContainer = styled.div`
  width: 100%;
  background: ${Colors.Neutral._100};
  padding: 0 ${Layout.Spacing._S};
  ${Styles.flex};
  .label {
    padding-left ${Layout.Spacing._XS} 
  }
`;

export const ProductPhotosGalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(10px, 1fr));
  grid-gap: ${Layout.Spacing._XS};
`;

export const ProductPhotoGridSquare = styled.div`
  position: relative;
  aspect-ratio: 1 / 1;
`;

export const AddImageContainer = styled(ProductPhotoGridSquare)`
  background: ${Colors.Neutral._100};
  border-radius: ${Effects.Border_Radius._S};
  ${Styles.flexCenter};
  input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
`;

export const ProductPhotoImageCSS = styled(ProductPhotoGridSquare)`
  overflow: hidden;
  border-radius: ${Effects.Border_Radius._S};
`;

const actionHeight = Layout.Spacing._XS;

export const DeletPhotoButtonClickarea = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: ${Layout.Spacing._2XS};
`;

export const DeletPhotoButtonContainer = styled.div`
  background: ${Colors.Sharp.White};
  height: ${actionHeight};
  width: ${actionHeight};
  box-shadow: ${Effects.Box_Shadow._M};
  border-radius: ${Effects.Border_Radius.Circle};
  ${Styles.flexCenter};
`;

export const ProductSummaryContainer = styled.div`
  height: 100%;
  .card {
    width: 100%;
    height: 100%;
    padding: ${Layout.Spacing._S};
  }
  .preview-gallery {
    margin-top: -20px;
  }
`;

export const BrandIsContractedIconCSS = styled.div`
  position: absolute;
  top: ${Layout.Spacing._2XS};
  right: ${Layout.Spacing._2XS};
  ${Styles.flexCenter};
`;
