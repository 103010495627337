import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import { ProductsTable } from '../organisms';

const ProductsLayout = ({
  headers,
  data,
  grid,
  rowAction,
  checkedRows,
  isLoading,
  isError,
  error,
  tableStatusProps,
  tableComponentsProps,
}) => {
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        <ProductsTable
          headers={headers}
          data={data}
          grid={grid}
          rowAction={rowAction}
          checkedRows={checkedRows}
          tableComponentsProps={tableComponentsProps}
        />
      }
    />
  );
};
export default ProductsLayout;
