import { Formik } from 'formik';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Filters } from '../../../../../components/organisms/Filters';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { PropKeys, Prose, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { isEmpty } from '../../../../../lib/js';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareLayoutEffect from '../../../../../utils/useDeepCompareLayoutEffect';
import ContactSupportButton from '../../../components/molecules/ContactSupportButton/ContactSupportButton';
import { GalleryProps } from '../../../constants';
import { useGetWhitelabelCustomers } from '../api/getWhitelabelCustomers';
import {
  formatPrintData,
  useGetWhitelabelPrint,
} from '../api/getWhitelabelPrint';
import { useSearchWhiteLabel } from '../api/searchWhitelabel';
import { MultipleParcelsWarning } from '../components';
import WhiteLabelLayout from '../components/WhiteLabelLayout';
import { WhitelabelHeaders } from '../components/style';
import EditWhiteLabel from './EditWhiteLabel';
import { useWhiteLabelReducer } from './WhiteLabel.hook';
import {
  DC,
  allOptions,
  barcodeGrid,
  barcodeHeaders,
  filtersConstants,
  firstPageFiltersConstants,
  headers,
  multipleParcelsWarning,
  noResultsState,
  printStatusText,
  whiteLabelKeys,
} from './variables';

const { SELF, VIEW_WHITE_LABELS, CREATE_WHITE_LABELS, EDIT_WHITE_LABELS } =
  PermissionKeys.Brand.WHITE_LABEL;

const initialData = [];

const WhiteLabel = () => {
  const [data, setData] = useState(initialData);
  const [printData, setPrintData] = useState({});

  const [autoFocusSearch, setAutoFocusSearch] = useState(Math.random());

  const { showModal } = useModal();
  const { showSnackbarError, showSnackbarWarning, showSnackbarSuccess } =
    useSnackbar();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { store } = useStore();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const isAllBrands = !store[PropKeys.brandId];

  const hasViewPermission = hasPermission(VIEW_WHITE_LABELS);
  const hasCreatePermission = hasPermission([
    CREATE_WHITE_LABELS,
    EDIT_WHITE_LABELS,
  ]);

  const resetFormRef = useRef(null);

  const firstSearch = useRef(true);
  const printRef = useRef(null);

  let options;
  let ownOptions;
  let filtersIsError;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useWhiteLabelReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const {
    data: { parcels, parcelCount: dataCount },
    isLoading,
    isError,
    error,
    findParcel,
    refresh: refreshTable,
  } = useSearchWhiteLabel({
    searchParams,
    ignore: !searchParams[DC.FILTERS_SET],
  });

  const { getPrintData, isLoading: printLoading } = useGetWhitelabelPrint();
  const { data: customerData } = useGetWhitelabelCustomers();

  const doPrint = useReactToPrint({
    content: () => printRef.current,
  });

  const triggerSearch = _ => {
    firstSearch.current = false;
    _dispatch({ type: DC.ENTER_SEARCH });
  };

  useDeepCompareLayoutEffect(() => {
    setData(parcels);
    prepareParcelStatuses(parcels);
  }, [parcels]);

  const prepareParcelStatuses = async parcels => {
    const promises = [];
    parcels.forEach(parcel => {
      promises.push(
        getPrintData(parcel).then(printData => {
          return { ...parcel, printData };
        })
      );
    });

    const data = (await Promise.allSettled(promises)).map(it => it.value);
    setData(data);
  };

  const edit = parcelData => () => showEditLabel(parcelData);

  const print = printData => () => setPrintData(printData);

  useEffect(() => {
    if (isEmpty(printData)) return;
    doPrint();
    setPrintData({});
  }, [printData]);

  const showGallery =
    ({ item, initialSelected }) =>
    () => {
      const { [whiteLabelKeys.id]: id, [whiteLabelKeys.barcodes]: barcodes } =
        item;
      showModal(Gallery, {
        id,
        initialSelected,
        ...GalleryProps.parcels,
        title: `Barcode ${barcodes[0]}`,
      });
    };

  const refetchFilters = Utils.emptyFunction;

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
      handleSearchEnter: triggerSearch,
    },
    [DC.REFRESH]: { onClick: refreshView },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  useLayoutEffect(() => {
    if (isLoading) return;
    if (firstSearch.current) {
      setShowHeader(false);
      setShowFooter(false);
    } else {
      const headerChildren = (
        <Formik initialValues={searchParams} enableReinitialize>
          {() => {
            return (
              <WhitelabelHeaders gap="_XS" w>
                {true && (
                  <Filters
                    filters={filters}
                    searchValue={searchParams[DC.SEARCH]}
                    disabled={isLoading}
                    autoFocusSearch={autoFocusSearch}
                    searchTheme="popup"
                  />
                )}
                {data.length > 1 && (
                  <MultipleParcelsWarning text={multipleParcelsWarning} />
                )}
                <ContactSupportButton />
              </WhitelabelHeaders>
            );
          }}
        </Formik>
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (firstSearch.current) return;
    if (data.length <= 1 || !hasViewPermission) setShowFooter(false);
    else {
      const dataCount = data.length;

      const props = {
        isLoading,
        ...tableStatusProps({
          searchParams,
          dispatch,
          dataCount,
          noResultsState,
        }),
      };
      const footerChildren = <Pagination {...props} />;

      setFooterChildren(footerChildren);
    }
  }, [isLoading, searchParams, data]);

  const firstPageFilters = mergeFilterStateAndConsts({
    filtersState,
    filtersConstants: firstPageFiltersConstants,
  });

  const showEditLabel = async item => {
    if (item == null || item.barcodes?.length == 0) {
      showSnackbarError();
      return;
    }
    if (item.barcodes[0].length < 3) {
      showSnackbarWarning('Barcode must be at least 3 characters');
      return;
    }
    const printIt = newPrintData => {
      const formattedPrintData = formatPrintData(newPrintData);

      if (item.isNewLabel) findParcel(searchParams);
      else
        setData(data => {
          const singleLabel = data?.[0] ?? {};
          singleLabel.printData = { ...formattedPrintData };
          return [singleLabel];
        });
      if (newPrintData.shouldPrint) print(formattedPrintData)();
    };

    showModal(
      EditWhiteLabel,
      {
        customerDataOptions: customerData,
        onSuccess: printIt,
        data: item.printData,
        parcelId: item[whiteLabelKeys.id],
        title: item.cardTitle,
        focusSearchBar,
      },
      {
        ignoreOverlayDismiss: true,
      }
    );
  };

  const copyBarcodeToClipboard = barcode => {
    if (barcode == null) return;
    navigator.clipboard.writeText(barcode).then(
      () => showSnackbarSuccess('Copied to clipboard'),
      () => showSnackbarError('Please try again')
    );
  };

  const focusSearchBar = () => setAutoFocusSearch(Math.random());

  const createWhitelabelButton = {
    text: 'Create whitelabel',
    variant: 'Primary',
    size: '_M',
    onClick: () =>
      showEditLabel({
        printData: { barcode: searchParams[DC.SEARCH] },
        barcodes: [searchParams[DC.SEARCH]],
        cardTitle: 'New label',
        isNewLabel: true,
      }),
    tooltipContent: isAllBrands ? Prose.allBrandsDisabled : '',
    disabled: isAllBrands,
  };

  // there is no automatic search on load. only a filtered search triggets .get()
  const noDataAction = undefined;

  return (
    <Formik initialValues={formInitialValues} enableReinitialize>
      {() => {
        return (
          <WhiteLabelLayout
            headers={headers}
            data={data}
            autoFocusSearch={autoFocusSearch}
            focusSearchBar={focusSearchBar}
            isLoading={isLoading}
            isError={isError}
            error={error}
            firstPageFilters={firstPageFilters}
            printStatusText={printStatusText}
            firstSearch={firstSearch.current}
            printRef={printRef}
            printData={printData}
            printLoading={printLoading}
            barcodeHeaders={barcodeHeaders}
            barcodeGrid={barcodeGrid}
            barcodeRowAction={copyBarcodeToClipboard}
            showGallery={showGallery}
            edit={edit}
            print={print}
            isAllBrands={isAllBrands}
            tableStatusProps={{
              ...tableStatusProps({
                searchParams,
                dispatch,
                dataCount,
                noResultsState,
                noDataAction,
                hasViewPermission,
              }), // override no searchresults display from tableStatusProps
              noSearchResultsDisplay: {
                ...noResultsState.noSearchResults,
                button: hasCreatePermission && createWhitelabelButton,
              },
            }}
          />
        );
      }}
    </Formik>
  );
};

export default WhiteLabel;
