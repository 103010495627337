import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('office_dashboard/sorter_data_filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { categories, tags } = data;

  const newOps = {};

  newOps[DC.CATEGORY] = prepareDataForDropdown(
    categories,
    'category',
    'category',
    { selected: false }
  );

  newOps[DC.SUBCATEGORY] = prepareDataForDropdown(tags, 'dsTag', 'dsTag', {
    selected: false,
  });

  return newOps;
};
