import { Formik } from 'formik';
import { openNewTab } from '../../../../../utils/utils';
import { useGetBilling } from '../api/getBilling';
import { BillingLayout } from '../components/BillingLayout';
import {
  invoiceGrid,
  invoiceHeaders,
  invoiceKeys,
  noResultsState,
} from './variables';

const Billing = () => {
  const { invoices, isLoading, isError, error, subscriptionPlan } =
    useGetBilling();

  const downloadInvoice = ({ [invoiceKeys.link]: link }) => openNewTab(link);

  return (
    <Formik>
      <BillingLayout
        noPlan={subscriptionPlan.title == null}
        planTitle={subscriptionPlan.title}
        planPrice={subscriptionPlan.monthlyPrice}
        planDetails={subscriptionPlan.details}
        invoices={invoices}
        headers={invoiceHeaders}
        grid={invoiceGrid}
        rowAction={downloadInvoice}
        isLoading={isLoading}
        isError={isError}
        error={error}
        noData={invoices.length === 0}
        noDataDisplay={noResultsState.invoices.noData}
      />
    </Formik>
  );
};
export default Billing;
