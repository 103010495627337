import styled from '@emotion/styled';
import { Constants, Styles } from '../../../../constants';
import { Text } from '../../../atoms';
import { Colors, FontKeys, Layout } from '../../../style';

export const Container = styled.div`
  justify-content: space-between;
  height: 464px;
  gap: ${Layout.Spacing._S};
  padding: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const CalendarStyles = styled.div`
  position: relative;
  margin: 0;
`;

const PresetOptionCSS = styled.div`
  cursor: pointer;
`;

export const PresetOptions = styled.div`
  border-right: 1px solid ${Colors.Neutral._200};
  padding-right: ${Layout.Spacing._XS};
  width: 144px;
`;

export const PresetOption = ({ text, onClick }) => {
  return (
    <PresetOptionCSS onClick={onClick}>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._S}
        weight={FontKeys.Weight.Regular}
      />
    </PresetOptionCSS>
  );
};

export const Range = ({ text }) => {
  return (
    <Text
      text={`Range: ${
        text != null
          ? `${text} day${text == 0 || text >= 2 ? 's' : ''}`
          : Constants.fallback
      }`}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral._600}
    />
  );
};
