import {
  cloneDeep as _cloneDeep,
  debounce as _debounce,
  difference as _difference,
  flow as _flow,
  get as _get,
  intersection as _intersection,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  merge as _merge,
  omitBy as _omitBy,
  throttle as _throttle,
  uniq as _uniq,
  uniqBy as _uniqBy,
} from 'lodash';
import { Utils } from '../constants';

export const cloneDeep = _cloneDeep;

export const debounce = _debounce;

export const flow = _flow;

export const get = _get;

export const getDifferentProperties = (a, b) => {
  const differentProperties = {};

  Object.keys(a).forEach(key => {
    if (!isEqual(a[key], b[key])) {
      differentProperties[key] = b[key];
      differentProperties['_' + key] = a[key];
    }
  });

  return differentProperties;
};

export const intersection = _intersection;

export const isValidDate = date => date != null && !!date.getTime();

export const isEmpty = _isEmpty;

export const isEqual = _isEqual;

export const isObject = x =>
  typeof x === 'object' && !Array.isArray(x) && x != null;

export const isArray = x => Array.isArray(x) && x != null;

export const isOnlyEmptyValues = object => Object.values(object).every(x => !x);

export const haveSameValues = (a, b) => _difference(a, b).length === 0;

export const moveItemToStart = index => array => {
  return index < array.length
    ? [array[index], ...array.slice(0, index), ...array.slice(index + 1)]
    : array;
};

export const preserveObject = x => JSON.parse(JSON.stringify(x));

export const pick = (object, keys) => {
  const picks = {};
  keys.forEach(key => {
    let value = object[key];
    if (isObject(value)) value = cloneDeep(object[key]);
    // if isArray, handle. create cloneDeepArray
    if (value) picks[key] = value;
  });
  return picks;
};

export const objectify = (array, transform = Utils.emptyFunction) =>
  array.reduce((o, k) => ((o[k] = transform(k)), o), {});

export const mergeDeep = (object, source) =>
  _merge(_cloneDeep(object), _cloneDeep(source));

export const range = (start, end, step = 1) => {
  const output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const unique = _uniq;

export const uniqueBy = _uniqBy;

export const strip = object => _omitBy(object, x => x === undefined);

export const throttle = (
  callback,
  interval,
  { leading = true, trailing = true } = { leading: true, trailing: true }
) => _throttle(callback, interval, { leading, trailing });

export const trimmed = x => (typeof x !== 'string' ? x : x.trim());

export const trimObject = object => {
  const sanitized = {};
  Object.keys(object).forEach(key => {
    sanitized[key] = trimmed(sanitized[key]);
  });
  return sanitized;
};

export const wait = n => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < n * 1000);
};

export const replaceCharAt = (str, index, replacement) =>
  str.substring(0, index) + replacement + str.substring(index + 1);

export const isJsonNull = item => {
  return item == null || item == 'null' || item == 'undefined';
};
