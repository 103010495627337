import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  mapToLightweightState,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Constants, PropKeys, Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useStore } from '../../../../../providers/StoreProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetFilters } from '../api/getFilters';
import { useGetProductCategories } from '../api/getProductCategories';
import { useGetRules } from '../api/getRules';
import { AddInstructionButton } from '../components';
import KnowledgeBaseLayout from '../components/KnowledgeBaseLayout';
import { useKnowledgeBaseReducer } from './KnowledgeBase.hook';
import SetupChoice from './SetupChoice';
import {
  DC,
  allOptions,
  filtersConstants,
  grid,
  headers,
  initialFilterValues,
  knowledgeBaseKeys,
  noResultsState,
  titles,
} from './variables';

const { SELF, VIEW_KNOWLEDGE_BASE, CREATE_KNOWLEDGE_BASE } =
  PermissionKeys.Brand.KNOWLEDGE_BASE;

const KnowledgeBase = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [categoriesData, setCategoriesData] = useState([]);
  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { store } = useStore();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_KNOWLEDGE_BASE);
  const hasCreatePermission = hasPermission(CREATE_KNOWLEDGE_BASE);

  const resetFormRef = useRef(null);

  const ownOptions = {
    [DC.ADDED_BY]: {
      sectionTitle: 'You',
      options: [
        {
          value: Constants.isSelf,
          label: store[PropKeys.username],
        },
      ],
    },
  };

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useKnowledgeBaseReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]) ||
    // avoid searching again on normal navigation, unless a filter is set
    (!urlHasSearch && searchParams[DC.SET] && !searchParams[DC.FILTERS_SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { rules, ruleCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetRules({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const {
    data: { categories },
    isLoading: isCategoriesLoading,
  } = useGetProductCategories({
    ignore: !hasViewPermission,
  });

  useEffect(() => {
    if (categories == null || isCategoriesLoading) return;
    setCategoriesData(categories);
  }, [categories]);

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useDeepCompareEffect(() => {
    if (rules == null || isLoading) return;
    setData(rules);
  }, [rules]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRANDS]: dropdown(DC.BRANDS),
    [DC.PRODUCT_CATEGORY]: dropdown(DC.PRODUCT_CATEGORY),
    [DC.ADDED_BY]: dropdown(DC.ADDED_BY),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.knowledge_base]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          endSlot={
            hasCreatePermission ? (
              <AddInstructionButton onClick={showAddInstruction} />
            ) : null
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues, categoriesData]);

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  }, [isLoading, searchParams]);

  // TODO: DRY: these are very simliar across table views, not 100% sure if this one's possible
  const goToDetails = item => {
    const to = `${NavURIs[Pages.knowledge_base]}/${item[knowledgeBaseKeys.id]}`;
    const from = location.pathname;
    const state = {
      searchParams: mapToLightweightState(searchParams),
      from,
    };

    navigate(to, { state });
  };

  const showAddInstruction = () => {
    if (!hasCreatePermission) return;
    showModal(SetupChoice, {
      categories: categoriesData,
      appendNewRule: onAppendNewRule,
    });
  };

  const noDataAction = hasCreatePermission ? showAddInstruction : undefined;

  const onAppendNewRule = () => {
    refreshTable();
    refetchFilters();
  };

  return (
    <KnowledgeBaseLayout
      headers={headers}
      data={data}
      grid={grid}
      rowAction={goToDetails}
      isError={isError}
      error={error}
      isLoading={isLoading}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default KnowledgeBase;
