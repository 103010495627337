import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Layout, UtilityEffects } from '../../style';

export const Container = styled.div`
  width: 100%;
  // Pagination.style.js height is 56px;
  min-height: 56px;
  padding: 0 ${Layout.Spacing._M};
  background: ${Colors.Sharp.White};
  transition: ${UtilityEffects.SidebarTransition('width')};
  ${Styles.flexCenter}
`;

export const Centering = styled.div`
  margin: 0 auto;
  width: 100%;
`;
