import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart as ModuleLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartTooltip } from '../../molecules/ChartTooltip/ChartTooltip';
import { Colors, Font_Properties, StatusColors } from '../../style';

import {
  countFormatter,
  countThird,
  countTooltipFormatter,
  leftMargin,
  percentageTickFormatter,
} from './utils';

const LineChart = ({
  data = [],
  lines = [],
  xDataKey = 'date',
  type = 'count',
  decimals = 2,
  interval = 'preserveEnd',
  inactive,
  ...props
}) => {
  let third;
  let tickFormatter;
  let tooltipFormatter;
  switch (type) {
    case 'count':
      third = countThird(data);
      tickFormatter = countFormatter(third * 4, decimals);
      tooltipFormatter = countTooltipFormatter;
      break;
    case 'percent':
      third = 25;
      tickFormatter = percentageTickFormatter;
      tooltipFormatter = value => value + '%';
      break;

    default:
      third = countThird;
      tickFormatter = countFormatter(third * 4, decimals);
      tooltipFormatter = value => value;
      break;
  }

  return (
    <ResponsiveContainer>
      <ModuleLineChart
        data={data}
        margin={{
          top: 8,
          left: leftMargin({ third, tickFormatter }),
        }}
        syncId={props.syncId}
      >
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke={Colors.Neutral._300}
        />
        {(data ?? []).length > 3 && interval === 0 ? (
          <XAxis
            dataKey={xDataKey}
            fontSize={Font_Properties.Size._S}
            stroke={Colors.Neutral._500}
            axisLine={false}
            tickLine={false}
            height={32}
            ticks={[
              data[0].date,
              data[Math.floor(data.length / 2)].date,
              data[data.length - 1].date,
            ]}
            domain={['dataMin', 'dataMax']}
            tickCount={3}
            interval={interval}
            padding={{
              left: 10,
              right: 10,
            }}
          />
        ) : (
          <XAxis
            dataKey={xDataKey}
            fontSize={Font_Properties.Size._S}
            stroke={Colors.Neutral._500}
            axisLine={false}
            tickLine={false}
            tickMargin={12}
            height={32}
            interval={interval}
            padding={{
              left: 10,
              right: 10,
            }}
          />
        )}
        <YAxis
          orientation="left"
          domain={[0, third * 4]}
          tickFormatter={tickFormatter}
          ticks={[0, third, third * 2, third * 3, third * 4]}
          fontSize={Font_Properties.Size._S}
          stroke={Colors.Neutral._500}
          axisLine={false}
          tickLine={false}
        />
        {!inactive && (
          <Tooltip
            content={<ChartTooltip />}
            formatter={tooltipFormatter}
            wrapperStyle={{ outline: 'none' }}
          />
        )}
        {(lines || []).map(line => {
          const {
            dataKey,
            stroke = StatusColors.Seized,
            dot = false,
            type = 'monotone',
            strokeWidth = 2,
          } = line;
          if (!dataKey) return <></>;
          return (
            <Line
              key={dataKey}
              dataKey={dataKey}
              stroke={stroke}
              dot={dot}
              type={type}
              strokeWidth={strokeWidth}
            />
          );
        })}
      </ModuleLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
