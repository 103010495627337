import usePostData from '../../../../../hooks/postHook';
import { caseKeys } from '../../../../admin/features_public/cases/controllers/variables';

export const useGetCasesImages = () => {
  const [{ data, isLoading, isError, error }, getCasesImages] =
    usePostData('parcels/images');

  const doGetCasesImage = ({ cases }) => {
    const parcel_ids = cases.map(parcel => parcel[caseKeys.id]);
    const body = {
      parcel_ids,
      only_thumbs: true,
      limit: 1,
    };
    getCasesImages(body);
  };

  return {
    data,
    isError,
    error,
    isLoading,
    getCasesImages: doGetCasesImage,
  };
};
