import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { ActingBrandSelection } from '../../../../../components/organisms/ActingBrandSelection/ActingBrandSelection';
import Actions from '../../../../../components/organisms/Actions/Actions';
import CaseSnapshot from '../controllers/CaseSnapshot';
import ProductSnapshot from '../controllers/ProductSnapshot';
import { ticketTypes } from '../controllers/variables';
import { MoreDetails, PriorityOptions, Search, TicketTitle } from './organisms';
import { CreateTicketContent, CreateTicketPopup } from './style';

export const CreateTicketLayout = ({
  actingBrandProps,
  title,
  goBack,
  filters,
  onSearchIsClicked,
  results,
  showResults,
  productTitle,
  selectProduct,
  selectedProduct,
  selectTicketTitle,
  titles,
  selectTitle,
  selectedTitle,
  handleOtherChange,
  otherMaxLength,
  titleInputRef,
  selectPriorityTitle,
  priorityOptions,
  selectPriority,
  chosenPriority,
  moreDetailsTitle,
  detailsChangeHandler,
  moreDetailsPlaceholder,
  cancel,
  cancelText,
  createTicket,
  createTicketText,
  isValid,
  hideResults,
  isLoading,
  caseDetailsIsLoading,
  searchHeaders,
  searchComponents,
  searchGrid,
  type,
}) => {
  return (
    <CreateTicketPopup
      size="_M"
      title={title}
      onClick={hideResults}
      goBack={goBack}
    >
      <CreateTicketContent className="create-ticket-content">
        <Flex column gap="_XS">
          <div />
          {type === ticketTypes.Parcel ? (
            <ActingBrandSelection {...actingBrandProps} />
          ) : null}
          <Search
            filters={filters}
            results={results}
            showResults={showResults}
            selectProduct={selectProduct}
            onSearchIsClicked={onSearchIsClicked}
            headers={searchHeaders}
            components={searchComponents}
            grid={searchGrid}
          />
          {/* TODO: pretty sure we can get rid of caseDetailsIsLoading */}
          {/* TODO: merge all snapshots into one with keys */}
          {!selectedProduct && !caseDetailsIsLoading ? (
            <></>
          ) : type === ticketTypes.Product ? (
            <>
              <ProductSnapshot
                productData={selectedProduct ?? {}}
                isLoading={caseDetailsIsLoading}
                title={productTitle}
              />
              <Spacer />
            </>
          ) : ticketTypes.Parcel ? (
            <CaseSnapshot
              caseData={selectedProduct ?? {}}
              isLoading={caseDetailsIsLoading}
              title={productTitle}
            />
          ) : (
            <></>
          )}
          <TicketTitle
            title={selectTicketTitle}
            titles={titles}
            selectTitle={selectTitle}
            chosenTitle={selectedTitle}
            handleOtherChange={handleOtherChange}
            otherMaxLength={otherMaxLength}
            titleInputRef={titleInputRef}
          />
          <PriorityOptions
            title={selectPriorityTitle}
            options={priorityOptions}
            selectPriority={selectPriority}
            chosenPriority={chosenPriority}
          />
          <MoreDetails
            title={moreDetailsTitle}
            detailsChangeHandler={detailsChangeHandler}
            placeholder={moreDetailsPlaceholder}
          />
          <Actions
            // onBack={goBack}
            onCancel={cancel}
            cancelText={cancelText}
            onPrimary={createTicket}
            primaryText={createTicketText}
            disabled={!isValid}
            isLoading={isLoading}
          />
        </Flex>
      </CreateTicketContent>
    </CreateTicketPopup>
  );
};
