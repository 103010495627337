import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionConsts,
  PermissionKeys,
  usePermissions,
} from '../../../../../../providers/PermissionProvider/PermissionsProvider';
import { CasesTable } from '../organisms';

const {
  SELF: PRODUCTS,
  VIEW_PRODUCTS,
  VIEW_PRODUCTS_SUMMARY,
} = PermissionKeys.Brand.PRODUCTS;

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Brand.PARCELS;

const CasesLayout = ({
  headers,
  data,
  grid,
  showGallery,
  expansionHeaders,
  expansionGrid,
  expansionRowAction,
  onRowExpand,
  isLoading,
  isError,
  error,
  tableStatusProps,
  onSupportClick,
  hasSupportPermission,
  expandCountKey,
}) => {
  const { hasPermission } = usePermissions();

  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        hasPermission(PARCELS, VIEW_PARCELS) && (
          <CasesTable
            headers={headers}
            data={data}
            expansionRowAction={
              (hasPermission(PRODUCTS, VIEW_PRODUCTS_SUMMARY) ||
                hasPermission(PRODUCTS, VIEW_PRODUCTS)) &&
              expansionRowAction
            }
            showGallery={showGallery}
            onRowExpand={onRowExpand}
            grid={grid}
            expansionHeaders={expansionHeaders}
            expansionGrid={expansionGrid}
            onSupportClick={onSupportClick}
            hasSupportPermission={hasSupportPermission}
            expandCountKey={expandCountKey}
            hasProductPermission={
              hasPermission(PRODUCTS, PermissionConsts.Show) &&
              hasPermission(PRODUCTS, VIEW_PRODUCTS)
            }
            VIEW_PARCEL_PHOTOS
          />
        )
      }
    />
  );
};
export default CasesLayout;
