import styled from '@emotion/styled';
import { includeConstants } from '../../../utils/styleUtils';
import { Colors, Effects, UtilityEffects } from '../../style';

export const Indicator = styled.div`
  height: 36px;
  width: 4px;
  position: absolute;
  right: 0;
  top: ${props => props.top}px;
  background: ${props => props.color ?? Colors.Sharp.White};
  transition: ${props =>
    props.transition ?? UtilityEffects.SidebarTransition()};
  border-radius: ${Effects.Border_Radius._2XS} 0px 0px
    ${Effects.Border_Radius._2XS};
`;

const constantStyle = {
  height: '36px',
  width: '4px',
  position: 'absolute',
  right: 0,
  background: Colors.Sharp.White,
  borderRadius: `${Effects.Border_Radius._2XS} 0px 0px ${Effects.Border_Radius._2XS}`,
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: ({ top, transition = UtilityEffects.SidebarTransition() }) => {
    return { top, transition, opacity: top ? 1 : 0 };
  },
};
