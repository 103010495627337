import useFetchData from '../../../../../hooks/fetchHook';

export const useGetInvoiceStatusOptions = () => {
  const [{ data, isLoading, isError, error }] = useFetchData(
    'admin_brands/invoices/status_options'
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};
