import { Text } from '../../../../../../components/atoms';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Colors, FontKeys } from '../../../../../../components/style';

export const ItemTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      whiteSpace="nowrap"
      ellipsis
    />
  );
};

export const ItemData = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H4}
      weight={FontKeys.Weight.Bold}
      color={Colors.Primary._600}
    />
  );
};

export const AnalyticsItemLabel = ({ text, bold }) => {
  return (
    <Text
      className="analytics-label"
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      whiteSpace="nowrap"
    />
  );
};

export const AnalyticsItemSublabel = ({ text, bold }) => {
  return (
    <Text
      className="analytics-label"
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={Colors.Neutral._500}
      whiteSpace="nowrap"
    />
  );
};

export const GraphTotalLabel = ({ text }) => {
  return (
    <Text
      className="analytics-label"
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Regular}
      color={Colors.Primary._600}
      whiteSpace="nowrap"
    />
  );
};

export const GraphTotalValue = ({ text }) => {
  return (
    <Text
      className="analytics-label"
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
      whiteSpace="nowrap"
    />
  );
};

export const LoadingAnimation = () => <CardLoader />;

export const ErrorText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
