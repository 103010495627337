import { useLayoutEffect } from 'react';
import ReactDom from 'react-dom';
import { matchRoutes, useLocation } from 'react-router-dom';
import { Constants } from '../../../constants';
import { useUI } from '../../../providers/UIProvider';
import { publicRURIs } from '../../../routes/variables';
import { withoutPx } from '../../../utils/utils';
import { Icon } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, Layout, Size } from '../../style';
import { Card } from '../Card/Card';
import { Container, Content, Type } from './Snackbar.style';

const defaultTimeout = 4000;

const Snackbar = ({
  content,
  snackbarDismiss,
  index = 0,
  type,
  size = '_M',
  timeout = defaultTimeout,
}) => {
  timeout = Math.min(Math.max(timeout, 2000), 6000);

  const { sidebarWidth } = useUI().UI;
  const location = useLocation();
  const [{ pathnameBase: noSidebar }] = matchRoutes(publicRURIs, location) ?? [
    {},
  ];

  useLayoutEffect(() => {
    setTimeout(() => {
      snackbarDismiss();
    }, timeout);
  }, []);

  const color = Colors[type]._500;

  const icon = {
    [Constants.Snackbar.error]: Icons.CC_AlertCircle,
    [Constants.Snackbar.warning]: Icons.CC_AlertCircle,
    [Constants.Snackbar.success]: Icons.CC_CheckCircle,
  };

  return ReactDom.createPortal(
    <Container
      index={index}
      timeout={timeout}
      sidebarWidth={noSidebar ? 0 : withoutPx(sidebarWidth) ?? 104}
      onClick={snackbarDismiss}
      type
    >
      <Card
        lineWidth={Layout.Spacing._3XS}
        color={color}
        padding={`${Layout.Spacing._XS} ${Layout.Spacing._S}`}
      >
        <Flex gap="_XS" align="center">
          <Icon name={icon[type]} color={color} size={Size.Icon[size]} />
          <Flex column gap="">
            <Type text={type} size={size} />
            <Content text={content} size={size} />
          </Flex>
        </Flex>
      </Card>
    </Container>,
    document.getElementById('snackbar-portal')
  );
};

export default Snackbar;
