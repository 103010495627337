import { useToggle } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useUI } from '../../../../../providers/UIProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { useAddPhotos } from '../api/addPhotos';
import { useCompleteInspection } from '../api/completeInspection';
import { useCreateProduct } from '../api/createProduct';
import { useRemovePhotos } from '../api/removePhotos';
import { useUpdateBrand } from '../api/updateBrand';
import { useUpdateProduct } from '../api/updateProduct';
import { ParcelInspectionLayout } from '../components/ParcelInspectionLayout';
import { prefillValues } from './utils';
import {
  inspectionStep,
  processingKeys,
  productEditSteps,
  stepKeys,
} from './variables';

const baseUrl = 'office_parcel';

const {
  OFFICE_PARCEL_PROCESSING: { SELF, EDIT_PARCEL_PRODUCTS },
} = PermissionKeys.Facilitator;

const ParcelInspection = ({
  parcel,
  getParcel,
  parcelIsLoading,
  onCompleteInspectionSuccess,
  setAtInspection,
}) => {
  const [step, setStep] = useState(stepKeys.details);

  const [productValues, setProductValues] = useState();
  const [productInModification, setProductInModification] = useState();
  const isUpdatingProduct = productInModification != null;

  const [saveValues, setSaveValues] = useState({});
  const [validation, setValidation] = useState({});

  const [isAdding, toggleIsAdding] = useToggle(false);

  const [summaryActionIsLoading, setSummaryActionIsLoading] = useState(false);

  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { hasPermission } = usePermissions();
  const { sidebarWidth } = useUI().UI;

  const hasEditProductPermission = hasPermission(SELF, EDIT_PARCEL_PRODUCTS);

  useEffect(() => {
    if (!hasEditProductPermission) dismiss();
  }, []);

  useEffect(() => {
    const atEditStep = productEditSteps.some(s => s === step);
    if (atEditStep && !hasEditProductPermission) stepTo(stepKeys.details)();
  }, [step]);

  // Creating product
  const { data, isLoading, isError, error, createProduct } = useCreateProduct({
    id: parcel?.[processingKeys.id],
  });

  const {
    body: completeBody,
    isLoading: completeIsLoading,
    isError: completeIsError,
    error: completeError,
    completeInspection,
  } = useCompleteInspection({
    id: parcel?.[processingKeys.id],
  });

  // Modifying product
  const {
    body: brand,
    isLoading: brandIsLoading,
    isError: brandIsError,
    error: brandError,
    updateBrand,
  } = useUpdateBrand({
    id: productInModification?.[processingKeys.product.id],
  });

  const {
    body: product,
    isLoading: productIsLoading,
    isError: productIsError,
    error: productError,
    updateProduct: doUpdateProduct,
  } = useUpdateProduct({
    id: productInModification?.[processingKeys.product.id],
  });

  const {
    body: photos,
    isLoading: photosIsLoading,
    isError: photosIsError,
    addPhotos,
  } = useAddPhotos({
    id: productInModification?.[processingKeys.product.id],
  });

  const {
    body: photosRemove,
    isLoading: photosRemoveIsLoading,
    isError: photosRemoveIsError,
    error: photosRemoveError,
    removePhotos,
  } = useRemovePhotos({
    id: productInModification?.[processingKeys.product.id],
  });

  const { handleImport, resetFileUpload, fileState, deleteFile } =
    useFileUpload();

  useEffect(() => {
    if (data == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const url = `${baseUrl}/${parcel?.[processingKeys.id]}`;
    setAtInspection(true);
    getParcel({ url });
    resetFileUpload();
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (completeBody == null || completeIsLoading) return;
    if (completeIsError) {
      showSnackbarError(completeError);
      return;
    }
    onCompleteInspectionSuccess();
  }, [completeBody, completeIsLoading, completeIsError]);

  const updates = [brand, product, photos];
  const updatesLoading = [
    brandIsLoading,
    productIsLoading,
    photosIsLoading,
    photosRemoveIsLoading,
  ];
  const updatesError = [
    { isError: brandIsError, error: 'Could not change brand' },
    {
      isError: productIsError,
      error: 'Could not change quantity and category',
    },
    { isError: photosIsError, error: 'Could not add photos' },
    { isError: photosRemoveIsError, error: 'Could not remove photos' },
  ];

  useEffect(() => {
    if (updates.every(u => u == null) || updatesLoading.some(l => l)) return;

    let stayOn = false;
    updatesError.forEach(({ isError, error }) => {
      if (!isError) return;
      showSnackbarError(error);
      stayOn = true;
    });

    if (stayOn) return;

    const url = `${baseUrl}/${parcel?.[processingKeys.id]}`;
    getParcel({ url });
    setProductInModification();
    resetFileUpload();
  }, [
    ...updates,
    ...updatesLoading,

    brandIsError,
    productIsError,
    photosIsError,
  ]);

  useEffect(() => {
    const somethingIsLoading =
      fileState.isUploading || isLoading || updatesLoading.some(l => l);

    setSummaryActionIsLoading(somethingIsLoading);
  }, [fileState, updatesLoading, isLoading]);

  const stepTo = to => () => setStep(to);

  const confirmBackToParcel = () => {
    const title = 'Return to parcel inspection';
    const description = parseStringModifiers(
      `Are you sure you want to cancel the adding process and return to the parcel inspection?

      **This action cannot be undone.**`
    );
    const confirm = 'Return';
    const onCancel = dismiss;
    const onConfirm = () => {
      dismiss();
      stepTo(stepKeys.details)();
      setProductValues();
    };

    showModal(
      ConfirmModal,
      { title, description, confirm, onCancel, onConfirm },
      { stack: true, keepPrev: true }
    );
  };

  const cancelButton = {
    text: 'Cancel',
    onClick: confirmBackToParcel,
    variant: 'Secondary',
  };

  const addProduct = () => {
    const title = 'Add product';
    const confirm = 'Save';
    const description = parseStringModifiers(
      `Are you sure you want to add this product?
      
      **You can always modify the product.**`
    );
    const onCancel = dismiss;
    const onConfirm = () => {
      handleImport({
        verbose: true,
        folder: 'case_photos/uploaded',
        // no url because I want to upload through another function
        parameterName: 'names',
        successCallback: onUploadSuccessCreate,
        errorCallback: error =>
          showSnackbarError('Something went wrong. Refresh and try again.'),
      });
      dismiss();
    };

    showModal(
      ConfirmModal,
      { title, description, confirm, onCancel, onConfirm },
      { stack: true, keepPrev: true }
    );
  };

  const updateProduct = () => {
    if (fileState.files)
      handleImport({
        folder: 'case_photos/uploaded',
        // no url because I want to upload through another function
        parameterName: 'names',
        successCallback: onUploadSuccessEdit,
      });
    else onUploadSuccessEdit({ uploadNames: [] });
  };

  const onUploadSuccessCreate = ({ uploadNames }) => {
    const {
      category: { value: product_id },
      itemQuantity: product_quantity,
      selectBrand: { [processingKeys.product.brand.id]: brand_id },
    } = saveValues;

    const photos = uploadNames.map(name => ({ name }));
    const successText = `Will upload ${photos.length} photo${
      photos.length > 1 ? 's' : ''
    }`;
    showSnackbarSuccess(successText);

    const body = {
      brand_id,
      photos,
      product_id,
      product_quantity,
    };

    createProduct(body);
  };

  const onUploadSuccessEdit = ({ uploadNames }) => {
    const {
      category: { value: category_id },
      itemQuantity: quantity,
      selectBrand: { [processingKeys.product.brand.id]: brand_id },
      filesStagedForDeletion,
    } = saveValues;

    const {
      [processingKeys.product.categoryId]: _category_id,
      [processingKeys.product.quantity]: _quantity,
      [processingKeys.product.brand.id]: _brand_id,
    } = productValues.item;

    const photos = uploadNames.map(name => ({ name }));
    const photosToRemove = filesStagedForDeletion.map(({ photoID }) => photoID);

    if (brand_id !== _brand_id) updateBrand({ brand_id });
    if (category_id !== _category_id && quantity !== _quantity)
      doUpdateProduct({ category_id, quantity });
    if (photos?.length) addPhotos({ photos });
    if (photosToRemove?.length) removePhotos({ photos: photosToRemove });
  };
  const toFirstStepProductWizard = () => {
    setAtInspection(true);
    stepTo(stepKeys.selectBrand)();
  };

  const backButtonTo = prevStep => ({
    text: 'Back',
    leftIcon: Icons.ChevronLeft,
    onClick: stepTo(prevStep),
    variant: 'Tertiary',
  });

  const parcelIsInspection =
    parcel?.[processingKeys.status] === Constants.Status.Inspection ||
    parcel?.[processingKeys.status] === 'Investigation';
  const parcelIsSideload =
    parcel?.[processingKeys.status] === Constants.Status.Sideload;

  const productIsInspection =
    productValues?.item?.[processingKeys.product.status] ===
    Constants.Status.Inspection;
  const productIsReleased =
    productValues?.item?.[processingKeys.product.status] ===
    Constants.Status.Released;

  const allowProductChanges =
    hasEditProductPermission &&
    (parcelIsInspection || parcelIsSideload) &&
    !completeIsLoading;

  const allowSelectBrand = productIsInspection || productIsReleased || isAdding;

  const disabled = {
    [stepKeys.details]: !allowProductChanges,
    [stepKeys.selectBrand]: !allowSelectBrand,
    [stepKeys.category]: false,
    [stepKeys.itemQuantity]: false,
    [stepKeys.photos]: false,
    [stepKeys.summary]: false,
  };

  const editProduct = item => () => {
    const { isNewProduct } = item;
    toggleIsAdding(!!isNewProduct);

    if (!isNewProduct) setProductInModification(item);
    if (!isNewProduct) setProductValues(prefillValues(item));
    toFirstStepProductWizard();
  };

  const startAddingProduct = editProduct({ isNewProduct: true });

  const buttons = {
    [stepKeys.details]: [
      // {
      //   text: 'Dismiss',
      //   variant: 'Tertiary',
      //   onClick: dismiss,
      //   disabled: completeIsLoading,
      // },
      {
        text: 'Complete inspection',
        disabled:
          !allowProductChanges ||
          parcelIsSideload ||
          parcel?.[processingKeys.productCount] === 0,
        variant: 'Secondary',
        onClick: () => completeInspection({}),
      },
      {
        text: 'Add product',
        onClick: startAddingProduct,
        variant: 'Primary',
        disabled: !allowProductChanges,
      },
    ],
    [stepKeys.selectBrand]: [
      cancelButton,
      {
        text: 'Next',
        onClick: stepTo(stepKeys.category),
        variant: 'Primary',
        disabled: !validation[stepKeys.category],
      },
    ],
    [stepKeys.category]: [
      backButtonTo(stepKeys.selectBrand),
      cancelButton,
      {
        text: 'Next',
        onClick: stepTo(stepKeys.itemQuantity),
        variant: 'Primary',
        disabled: !validation[stepKeys.itemQuantity],
      },
    ],
    [stepKeys.itemQuantity]: [
      backButtonTo(stepKeys.category),
      cancelButton,
      {
        text: 'Next',
        onClick: stepTo(stepKeys.photos),
        variant: 'Primary',
        disabled: !validation[stepKeys.photos],
      },
    ],
    [stepKeys.photos]: [
      backButtonTo(stepKeys.itemQuantity),
      cancelButton,
      {
        text: 'Next',
        onClick: stepTo(stepKeys.summary),
        variant: 'Primary',
        disabled: !validation[stepKeys.summary],
      },
    ],
    [stepKeys.summary]: [
      { ...backButtonTo(stepKeys.photos), disabled: summaryActionIsLoading },
      {
        text: 'Modify',
        onClick: toFirstStepProductWizard,
        variant: 'Secondary',
        disabled: summaryActionIsLoading,
      },
      {
        text: 'Save',
        onClick: isUpdatingProduct ? updateProduct : addProduct,
        variant: 'Primary',
        isLoading: summaryActionIsLoading,
        disabled: summaryActionIsLoading || !validation[stepKeys.summary],
      },
    ],
  };

  return (
    <ParcelInspectionLayout
      stepTitle={inspectionStep[step].title(isUpdatingProduct)}
      buttons={buttons[step]}
      step={step}
      stepTo={stepTo}
      parcel={parcel}
      editProduct={editProduct}
      productInitialValues={productValues}
      setSaveValues={setSaveValues}
      validation={validation}
      setValidation={setValidation}
      goToAddProduct={startAddingProduct}
      parcelIsLoading={parcelIsLoading}
      sidebarWidth={sidebarWidth}
      disabled={disabled[step]}
    />
  );
};

export default Modal(ParcelInspection);
