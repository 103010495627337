import styled from '@emotion/styled';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors } from '../../style';
import StaticSpinner from './StaticSpinner';

export const TableLoader = ({ ...props }) => (
  <Flex w h justify="center" align="center" className={props.className ?? ''}>
    <StaticSpinner dotSize={6} size={80} color={Colors.Neutral._300} />
  </Flex>
);

export const CardLoader = () => (
  <Flex w h justify="center" align="center">
    <StaticSpinner dotSize={4} size={60} color={Colors.Neutral._200} />
  </Flex>
);

export const ThumbnailLoader = () => (
  <Flex w h justify="center" align="center">
    <StaticSpinner dotSize={4} size={44} color={Colors.Neutral._200} />
  </Flex>
);

const ViewLoaderCSS = styled.div`
  width: 100%;
  height: 100%;
`;

export const ViewLoader = () => {
  return (
    <ViewLoaderCSS className="view-loader">
      <Flex w h justify="center" align="center">
        <StaticSpinner dotSize={6} size={80} color={Colors.Neutral._300} />
      </Flex>
    </ViewLoaderCSS>
  );
};
