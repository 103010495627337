import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useReducer, useRef, useState } from 'react';
import { Constants } from '../../../constants';
import { useFirebase } from '../../../providers/FirebaseProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import FileUploadLayout from '../components/FileUploadLayout';
import { fileUploadReducer } from './FileUpload.hook';
import {
  areValidFileSizes,
  areValidFileType,
  createUploadName,
  extractImageName,
} from './utils';
import { DC, initialState, uploadStatusContent } from './variables';

const FileUpload = ({
  showAmount = 3,
  showMultiple,
  assignHandleImport,
  onRemoveItem,
  onFileStateUpdate,
  beforeElement,
  afterElement,
  parentIsLoading,
  preexistingFiles = [],
}) => {
  const [percentageShares, setPercentageShares] = useState({});
  const [rightSwipe, setRightSwipe] = useState(0);

  const uploadNameRef = useRef(null);
  const uploadNamesRef = useRef([]);

  const { firebaseStorage, publicFirebaseStorage } = useFirebase();
  const { showSnackbarError, showSnackbarWarning } = useSnackbar();

  const [fileState, dispatch] = useReducer(
    fileUploadReducer,
    initialState(preexistingFiles)
  );

  const handleDrop =
    (uploadType, singleFile = false) =>
    index =>
    userUpload => {
      if (!userUpload || !userUpload.length) {
        showSnackbarError();
        return;
      }

      const isValidFileType = areValidFileType(userUpload, uploadType);
      const isValidFileSizes = areValidFileSizes(userUpload, uploadType);
      if (!isValidFileType)
        showSnackbarWarning(
          Constants.FileUpload.uploadTypeUtils[uploadType].extensionError
        );
      if (!isValidFileSizes)
        showSnackbarWarning(
          Constants.FileUpload.uploadTypeUtils[uploadType].fileSizeError
        );
      if (!isValidFileType || !isValidFileSizes) return;
      dispatch({
        type: DC.FILES,
        payload: { userUpload, index: index - preexistingFiles.length },
      });
    };

  const handleImport = async ({
    folder,
    url,
    isPublic,
    parameterName = 'name',
    nameMustBeString,
    successCallback,
    errorCallback,
  }) => {
    try {
      const { files } = fileState;

      dispatch({ type: DC.UPLOAD });
      const uploadPromises = [];
      files.forEach(file =>
        uploadPromises.push(uploadFile(file, folder, isPublic))
      );

      const filesResponses = await Promise.all(uploadPromises)
        .then(val => val)
        .catch(err => {
          throw err;
        });

      successCallback(filesResponses);
      dispatch({ type: DC.SUCCESS });
    } catch (error) {
      if (typeof errorCallback === 'function') errorCallback(error);
      dispatch({ type: DC.ERROR, payload: Constants.FileUpload.uploadError });
    }
  };

  useEffect(() => {
    assignHandleImport(() => handleImport);
    onFileStateUpdate(fileState);
  }, [fileState]);

  const uploadFile = (file, folder, isPublic) => {
    const uploadName = createUploadName(file.name);
    return new Promise((resolve, reject) => {
      const storageRef = ref(
        isPublic ? publicFirebaseStorage : firebaseStorage,
        `${folder}/${uploadName}`
      );
      // if endpoint accepts image_url, there should be only one image uploaded
      uploadNameRef.current = uploadName;
      uploadNamesRef.current = [...uploadNamesRef.current, uploadName];
      // TODO: better name for this, or switch over to using files for this
      // maybe name STORED_FILES or FILES_WITH_STORAGE_NAMES
      dispatch({
        type: DC.STORAGE_NAME,
        payload: { file, storageName: uploadName },
      });

      const task = uploadBytesResumable(storageRef, file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          const increasePercentageShare = share => {
            const newShare = { ...share };
            newShare[uploadName] = percentage;
            return newShare;
          };

          setPercentageShares(increasePercentageShare);
        },
        function error() {
          reject();
        },

        async function complete() {
          resolve({ file, uploadName });
        }
      );
    });
  };

  const deleteFile = async ({ url, folder, isPublic }) => {
    const urls = typeof url === 'string' ? [url] : url;

    const deletePromises = [];
    urls.forEach(url => deletePromises.push(doDelete(url, folder, isPublic)));

    const success = await Promise.all(deletePromises)
      .then(res => true)
      .catch(err => false);

    return success;
  };

  const doDelete = (fileUrl, folder, isPublic) => {
    const fileName = decodeURIComponent(extractImageName(fileUrl, folder));

    const fileRef = ref(
      isPublic ? publicFirebaseStorage : firebaseStorage,
      `${folder}/${fileName}`
    );

    return deleteObject(fileRef);
  };

  const resetFileUpload = () => console.info('reset file upload');

  const rightAction = () => {
    const fileNumber = fileState.files.length + preexistingFiles.length;

    if (rightSwipe >= fileNumber - showAmount)
      dispatch({ type: DC.MORE_FILES });
    if (fileNumber >= showAmount) swipeRight();
  };

  const removeInstance = index => () => {
    if (rightSwipe > 0) swipeLeft();
    const preexistingFilesLength = preexistingFiles.length;

    onRemoveItem(index);
    if (index >= preexistingFilesLength)
      dispatch({
        type: DC.LESS_FILES,
        payload: index - preexistingFilesLength,
      });
  };

  const swipeRight = () => setRightSwipe(x => x + 1);
  const swipeLeft = () => setRightSwipe(x => x - 1);

  return (
    <FileUploadLayout
      showAmount={showAmount}
      files={[...preexistingFiles, ...fileState.files]}
      preexistingFilesLength={preexistingFiles.length}
      isLoading={fileState.isUploading}
      parentIsLoading={parentIsLoading}
      completed={fileState.completedUpload}
      success={fileState.uploadSuccess}
      errorType={fileState.errorType}
      storageNames={fileState.storageNames.map(f => f.storageName)}
      showMultiple={showMultiple}
      // TODO: accept dynamic upload types ( not just image )
      handleDrop={handleDrop(
        Constants.FileUpload.uploadType.image,
        !showMultiple || fileState.files.length + preexistingFiles.length > 1
      )}
      fileState={fileState}
      uploadStatusContent={uploadStatusContent({
        fileState,
        resetFileUpload,
        acceptsMultiple: fileState.files.length + preexistingFiles.length === 1,
      })}
      percentageShares={percentageShares}
      removeInstance={removeInstance}
      rightAction={rightAction}
      swipeLeft={swipeLeft}
      rightSwipe={rightSwipe}
      addsMoreFiles={
        rightSwipe >=
        fileState.files.length + preexistingFiles.length - showAmount
      }
      beforeElement={beforeElement}
      afterElement={afterElement}
    />
  );
};

export default FileUpload;
