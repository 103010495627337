import { Route, Routes } from 'react-router-dom';
import ProductDetails from './ProductDetails';
import Products from './Products';

export const ProductsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Products />} />
    </Routes>
  );
};

export const ProductDetailsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ProductDetails />} />
    </Routes>
  );
};
