import { useFormikContext } from 'formik';
import { get } from '../../../lib/js';
import { CheckBox } from './CheckBox';

const FormCheckBox = ({ name, label, ...props }) => {
  const { setFieldValue, values } = useFormikContext();
  const isSelected = get(values, name);

  const handleSelected = () => () => {
    setFieldValue(name, !isSelected);
  };
  return (
    <CheckBox
      id={name}
      label={label}
      selected={isSelected}
      setSelected={handleSelected}
      disabled={false}
      {...props}
    />
  );
};

export default FormCheckBox;
