import { TicketContainer } from './atoms/atoms';
import {
  TicketLabel,
  TicketMetadata,
  TicketOtherDetails,
} from './organisms/organisms';

const TicketLayout = ({
  handleClick,
  read,
  alias,
  showDateCreated,
  dateCreated,
  showLastMessage,
  lastMessage,
  messageCount,
  showOptions,
  shouldShowOptions,
  markReadUnread,
  ticketActionsRef,
  mainTopic,
  description,
  image,
  name,
  priority,
  status,
  type,
}) => {
  return (
    <TicketContainer onClick={handleClick} read={read ?? 1}>
      <TicketMetadata
        alias={alias}
        showDateCreated={showDateCreated}
        dateCreated={dateCreated}
        showLastMessage={showLastMessage}
        lastMessage={lastMessage}
        messageCount={messageCount}
        read={read}
        showOptions={showOptions}
        shouldShowOptions={shouldShowOptions}
        markReadUnread={markReadUnread}
        ticketActionsRef={ticketActionsRef}
        type={type}
      />
      <TicketLabel mainTopic={mainTopic} description={description} />
      <TicketOtherDetails
        image={image}
        name={name}
        priority={priority}
        status={status}
      />
    </TicketContainer>
  );
};
export default TicketLayout;
