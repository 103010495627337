import { Colors } from '../../../../../components/style';
import { Constants } from '../../../../../constants';
import useFetchData from '../../../../../hooks/fetchHook';
import { isArray } from '../../../../../lib/js';
import { joinStrings } from '../../../../../utils/stringUtils';
import { DateTypes, formatDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { caseKeys } from '../../../features_public/cases/controllers/variables';
import { parcelKeys } from '../../../features_public/parcels/controllers/variables';
import { productKeys } from '../../../features_public/products/controllers/variables';
import {
  getReasonTitle,
  getRiskTypes,
  getSideloadExplanation,
} from '../controllers/variables';
import {
  parcelDetailMockText,
  parcelDetailTitles,
  parcelDetailTypes,
} from '../variables';

const { fallback } = Constants;

export const useGetCaseDetails = ({ id, ignore }) => {
  // parcelId may be missing before manual fetch by getCaseDetails.
  // keep loading until manually fetched unless ignored
  const [
    { data = {}, isLoading = !ignore, isError, error },
    _,
    getCaseDetails_,
  ] = useFetchData(`parcel/${id}`, {
    formatter,
    ignore: id == null || ignore,
  });

  const getCaseDetails = id => {
    const url = `parcel/${id}`;
    getCaseDetails_({ url });
  };

  return {
    data: ignore ? formatter({}) : data,
    isLoading,
    isError,
    error,
    getCaseDetails,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  const shouldBlurActors = data.actors == null || !data.should_show_actors;

  data[caseKeys.statusShares] = formatStatusShares(data[caseKeys.statusShares]);

  const details = formatSnapshotDetails(data.actors, {
    volume: data[productKeys.parcelVolume],
    weight: data[productKeys.parcelWeight],
    trackingNumber: data[productKeys.parcelTrackingNumber],
    shouldBlurActors,
    location: joinStrings(
      data[productKeys.sorterCountry],
      data[productKeys.sorterCity],
      ' - '
    ),
    catchDate: joinStrings(
      formatDate(data[productKeys.productSideloadDate], DateTypes.ddmmyy),
      formatDate(data[productKeys.productSideloadDate], DateTypes.time12),
      ' - '
    ),
    totalItems: `${data[caseKeys.ownQuantity] ?? Constants.fallback} / ${
      data[caseKeys.totalQuantity] ?? Constants.fallback
    }`,
  });

  const riskAnalysis = {
    title: getReasonTitle(data[parcelKeys.reason]),
    description: getSideloadExplanation(
      data[parcelKeys.reason],
      data[parcelKeys.riskType]
    ),
    riskPotential: {
      value: data[parcelKeys.confidence] ?? 0,
      display: `${
        data[parcelKeys.confidence] == null
          ? 'n/a'
          : `${data[parcelKeys.confidence]}%`
      }`,
      fill: Colors.Primary._600,
    },
  };

  return {
    ...data,
    [productKeys.parcelDetails]: details,
    [productKeys.shouldBlurActors]: shouldBlurActors,
    [productKeys.reason]: riskAnalysis,
    [productKeys.riskType]: getRiskTypes(data[productKeys.riskType]),
  };
};

const formatStatusShares = shares => {
  if (isArray(shares)) return shares;
  const status = [
    Constants.Status.Seized,
    Constants.Status.Released,
    Constants.Status.Stopped,
    Constants.Status['Non-counterfeit'],
    Constants.Status.Counterfeit,
    Constants.Status.Authentication,
    Constants.Status.Inspection,
  ];

  const inputShares = status.map(status => {
    return {
      status,
      percentage: Math.round((shares?.[status] ?? 0) * 100),
    };
  });

  return inputShares;
};

const formatSnapshotDetails = (
  actors,
  {
    volume,
    weight,
    trackingNumber,
    shouldBlurActors,
    location,
    catchDate,
    totalItems,
  }
) => {
  const mock = shouldBlurActors;
  const details = [
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.locationType],
          info: location ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.catchDateType],
          info: catchDate ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.totalItemsType],
          info: totalItems ?? fallback,
        },
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.volumeType],
          info: volume ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.weightType],
          info: weight ?? fallback,
        },
        {
          title: parcelDetailTitles[parcelDetailTypes.trackingType],
          info: trackingNumber ?? fallback,
        },
      ],
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.senderType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.senderType),
        },
      ],
      mock,
    },
    {
      data: [
        {
          title: parcelDetailTitles[parcelDetailTypes.receiverType],
          info: mock
            ? parcelDetailMockText
            : getActorInfo(actors, parcelDetailTypes.receiverType),
        },
      ],
      mock,
    },
  ];

  return details;
};

const getActorInfo = (actors, actorType) => {
  const actor = actors.find(actor => actor.pacType === actorType) ?? {};
  const formattedInfo =
    '**' +
    (joinStrings(actor?.seName, actor?.seCompany) || fallback) +
    '**' +
    '\n\n' +
    (actor?.seAddress || fallback) +
    '\n' +
    (actor?.sePostcode || fallback) +
    '\n' +
    (actor?.seCountry || fallback) +
    '\n' +
    (actor?.sePhone || fallback);

  return formattedInfo;
};

const mask = {
  paTracking: productKeys.parcelTrackingNumber,
  paVolume: productKeys.parcelVolume,
  paWeight: productKeys.parcelWeight,
  // should_show_actors,
  soCountry: productKeys.sorterCountry,
  soCity: productKeys.sorterCity,
  // own_quantity,
  // rest_quantity,
  paSideloadDate: productKeys.productSideloadDate,
  case_count: parcelKeys.caseCount,
  paAlias: parcelKeys.alias,
  caParcel: parcelKeys.id,
  paRiskType: parcelKeys.riskType,
  paStatus: parcelKeys.status,
  pdConfidence: parcelKeys.confidence,
  sender_brand_name: parcelKeys.reason,
  // case keys
  case_count: caseKeys.productQuantity,
  last_activity: caseKeys.lastActivity,
  own_quantity: caseKeys.ownQuantity,
  rest_quantity: caseKeys.totalQuantity,
  soName: caseKeys.sorter,
  status_ratios: caseKeys.statusShares,
};
