import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Input } from '../../../../../../components/molecules';
import { Filters } from '../../../../../../components/organisms/Filters';
import { TicketConstants } from '../../../../features/support/controllers/variables';
import { CasesListSmall } from '../../../products/components/molecules';
import { SectionTitle } from '../atoms';
import { OtherTitle, SectionOptions } from '../molecules';
import { MoreDetailsInputCSS } from '../style';

export const Search = ({
  filters,
  results,
  showResults,
  selectProduct,
  isLoading,
  onSearchIsClicked,
  headers,
  components,
  grid,
}) => {
  return (
    <Flex column relative gap="_2XS">
      <Filters
        filters={filters}
        disabled={isLoading}
        onSearchIsClicked={onSearchIsClicked}
        searchTheme="popup"
      />
      <CasesListSmall
        headers={headers}
        results={results}
        components={components}
        grid={grid}
        rowAction={selectProduct}
        showResults={showResults}
        search
      />
    </Flex>
  );
};

export const TicketTitle = ({
  title,
  titles,
  selectTitle,
  chosenTitle,
  handleOtherChange,
  otherMaxLength,
  titleInputRef,
}) => {
  return (
    <Flex column gap="_XS" align="start">
      <SectionTitle text={title} />
      <SectionOptions
        options={titles}
        selectOption={selectTitle}
        chosenOption={chosenTitle}
        customOptions={[
          <OtherTitle
            handleOtherChange={handleOtherChange}
            otherMaxLength={otherMaxLength}
            titleInputRef={titleInputRef}
            // TODO: fix this import path
            selected={chosenTitle === TicketConstants.Titles.otherTicket.value}
            selectTitle={selectTitle}
            key="other"
          />,
        ]}
      />
    </Flex>
  );
};

export const PriorityOptions = ({
  title,
  options,
  selectPriority,
  chosenPriority,
}) => {
  return (
    <Flex column gap="_XS" align="start">
      <SectionTitle text={title} />
      <SectionOptions
        options={options}
        selectOption={selectPriority}
        chosenOption={chosenPriority}
      />
    </Flex>
  );
};

export const MoreDetails = ({ title, detailsChangeHandler, placeholder }) => {
  return (
    <Flex column gap="_XS" align="start" grow relative>
      <SectionTitle text={title} />
      <MoreDetailsInputCSS>
        <Input
          type="textarea"
          name="moreDetails"
          placeholder={placeholder}
          onChange={detailsChangeHandler}
          hasError
        />
      </MoreDetailsInputCSS>
    </Flex>
  );
};
