import { FileDropzone } from '../../../../../components/organisms/FileDropzone/FileDropzone';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import { Fields } from '../components/organisms/organisms';
import { ManualInstructionContent } from '../components/style';

export const ManualInstructionLayout = ({
  fields,
  actingBrandProps,
  handleDrop,
  fileState,
  percentageUploaded,
  uploadStatusContent,
  onCancel,
  onBack,
  addRule,
  disabled,
  isLoading,
  selectOption,
  setTouched,
}) => {
  return (
    <StepPopup
      title="New knowledge base"
      sectionTitle="Add the requested information to create a new knowledge base"
      onCancel={onCancel}
      onBack={onBack}
      onPrimary={addRule}
      primaryText={'Add'}
      disabled={disabled}
      isLoading={isLoading}
    >
      <ManualInstructionContent>
        <Fields
          fields={fields}
          selectOption={selectOption}
          setTouched={setTouched}
          actingBrandProps={actingBrandProps}
        />
        <FileDropzone
          handleDrop={handleDrop}
          fileState={fileState}
          percentageUploaded={percentageUploaded}
          uploadStatusContent={uploadStatusContent}
        />
      </ManualInstructionContent>
    </StepPopup>
  );
};
