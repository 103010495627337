export const toggleProps = ({ searchParams, dispatch, type }) => {
  return {
    toggleActive: () =>
      dispatch({
        type,
        payload: !searchParams[type],
      }),
    active: searchParams[type],
  };
};
