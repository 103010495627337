import styled from '@emotion/styled';
import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { FontKeys } from '../../style';
import { Content } from '../Popup/Popup.style';

export const StepContent = styled(Content)`
  display: flex;
  flex-direction: column;
`;

export const ModalSectionTitle = ({ text }) => (
  <Flex w column align="center" justify="center" size="_L" gap="_M">
    <div />
    <ModalSectionTitleText text={text} />
    <div />
  </Flex>
);

const ModalSectionTitleText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
