import { useDeepCompareEffect } from '@react-hookz/web';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFilterParams } from '../components/organisms/Filters/utils';

export const useTableParams = ({ ...initializerOptions }) => {
  const location = useLocation();

  const {
    initialSearchParams: initialSearchParams_,
    resetSearchParams: resetSearchParams_,
    formInitialValues: formInitialValues_,
  } = useCallback(getFilterParams({ location, ...initializerOptions }), []);

  const [formInitialValues, setFormInitialValues] =
    useState(formInitialValues_);
  const [resetSearchParams, setResetSearchParams] =
    useState(resetSearchParams_);
  const [initialSearchParams, setInitialSearchParams] =
    useState(initialSearchParams_);

  useDeepCompareEffect(() => {
    const { initialSearchParams, resetSearchParams, formInitialValues } =
      getFilterParams({ location, ...initializerOptions });
    setResetSearchParams(resetSearchParams);
    setInitialSearchParams(initialSearchParams);
    setFormInitialValues(formInitialValues);
  }, [initializerOptions]);

  return {
    initialSearchParams,
    resetSearchParams,
    formInitialValues,
  };
};
