import { AtomText, Container } from './ComingSoon.style';

const ComingSoon = ({ short }) => {
  return (
    <Container className="coming-soon">
      <AtomText text={`${short ? '' : 'COMING'} SOON`} />
    </Container>
  );
};

export default ComingSoon;
