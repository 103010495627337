import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data = {}, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_brands/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { brand_groups, industries, contracts } = data;

  const newOps = {};

  newOps[DC.CONTRACTED] = prepareDataForDropdown(
    contracts,
    'label',
    'is_contracted'
  );

  newOps[DC.BRAND_GROUP] = prepareDataForDropdown(
    brand_groups,
    'bgName',
    'bgName'
  );

  newOps[DC.INDUSTRY] = prepareDataForDropdown(
    industries,
    'brIndustry',
    'brIndustry'
  );

  return newOps;
};
