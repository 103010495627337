import { useReducer } from 'react';
import { customTableReducer } from '../../../../../hooks/tableHook';
import { DC } from './variables';

export const useParcelsReducer = ({
  initialSearchParams,
  resetSearchParams,
}) => {
  const [searchParams, dispatch] = useReducer(
    customTableReducer({ resetSearchParams, customReducer }),
    initialSearchParams
  );
  return [searchParams, dispatch];
};

const customReducer = (state, { type, payload }) => {
  switch (type) {
    case DC.RISK_TYPE:
    case DC.PARCEL_STATUS:
    case DC.SPECIAL_FILTER:
    case DC.BRAND:
    case DC.LOCATION:
    case DC.ACTOR_DATA:
      return {
        ...state,
        [type]: [...payload],
        [DC.FILTERS_SET]: true,
      };
    case DC.CONTRACTED:
      return {
        ...state,
        [type]: payload,
        [DC.FILTERS_SET]: true,
      };
    default:
      return state;
  }
};
