import { colors, Container, TypeText } from './style';

const TicketTypeBadge = ({ type, size }) => {
  const { background: bgColor, text: textColor } = colors(type);

  return (
    <Container color={bgColor} size={size}>
      <TypeText color={textColor} size={size} text={type} />
    </Container>
  );
};

export default TicketTypeBadge;
