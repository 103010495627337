import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../../components/molecules/Button/Button';
import { CheckBox } from '../../../../../../components/molecules/CheckBox/CheckBox';
import { Input } from '../../../../../../components/molecules/Input/Input';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { Filters } from '../../../../../../components/organisms/Filters/Filters';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { Colors, Size } from '../../../../../../components/style';
import { grid, whiteLabelKeys } from '../../controllers/variables';
import {
  EditSectionTitle,
  FirstBarcodeSearchIllustration,
  WarningText,
} from '../atoms/atoms';
import {
  Actions,
  FirstSearchHeader,
  WhiteLabelData,
  companyLabelComponents,
  tableComponents,
} from '../molecules/molecules';
import {
  CompanyLabelCSS,
  CompanyLabelFields,
  EditFields,
  FirstBarcodeSearchCSS,
  Hidden,
  SenderReturnActionCSS,
  WhiteLabelDetailsCSS,
  WhiteLabelDetailsContainer,
  WhiteLabelTableCSS,
} from '../style';

export const FirstBarcodeSearch = ({
  filters,
  searchValue,
  autoFocusSearch,
  focusSearchBar,
}) => {
  return (
    <FirstBarcodeSearchCSS onClick={focusSearchBar}>
      <FirstSearchHeader />
      <Filters
        filters={filters}
        searchValue={searchValue}
        autoFocusSearch={autoFocusSearch}
        searchTheme="full-page"
      />
      <FirstBarcodeSearchIllustration />
    </FirstBarcodeSearchCSS>
  );
};

export const MultipleParcelsWarning = ({ text }) => {
  return (
    <Flex gap="_S" className="multiple-parcels-warning" align="center">
      <Icon name={Icons.AlertCircle} color={Colors.Primary._700} />
      <WarningText text={text} />
    </Flex>
  );
};

const SenderAction = ({ text }) => {
  return (
    <Flex gap="_2XS" align="center">
      <Icon
        name={Icons.AlertCircle}
        color={Colors.Primary._700}
        size={Size.Icon._S}
      />
      <WarningText text={text} size="_M" />
    </Flex>
  );
};

export const SupportButton = ({ button }) => {
  return (
    <div className="support-button">
      <Button {...button} />
    </div>
  );
};

export const WhiteLabelTable = ({
  headers,
  data,
  showGallery,
  edit,
  print,
  hasEditPermission,
  hasPrintPermission,
  isAllBrands,
}) => {
  return (
    <WhiteLabelTableCSS>
      <Table
        headers={headers}
        data={data}
        grid={grid}
        components={tableComponents({
          showGallery,
          edit,
          print,
          hasEditPermission,
          hasPrintPermission,
          isAllBrands,
        })}
      />
    </WhiteLabelTableCSS>
  );
};

export const WhiteLabelDetails = ({
  data = {},
  printStatusText,
  isLoading,
  barcodeHeaders,
  barcodeGrid,
  barcodeRowAction,
  edit,
  print,
  showGallery,
  hasEditPermission,
  hasPrintPermission,
  isAllBrands,
}) => {
  return (
    <Flex w>
      <WhiteLabelDetailsCSS>
        {isLoading ? (
          <CardLoader />
        ) : (
          <>
            <CompanyLabel data={data?.printData} />
            <WhiteLabelDetailsContainer>
              <WhiteLabelData
                data={data}
                barcodeHeaders={barcodeHeaders}
                barcodeGrid={barcodeGrid}
                barcodeRowAction={barcodeRowAction}
                showGallery={showGallery}
              />
              <Actions
                data={data}
                printStatusText={printStatusText}
                edit={edit}
                print={print}
                hasEditPermission={hasEditPermission}
                hasPrintPermission={hasPrintPermission}
                isAllBrands={isAllBrands}
              />
            </WhiteLabelDetailsContainer>
          </>
        )}
      </WhiteLabelDetailsCSS>
    </Flex>
  );
};

const companyLabelFields = Object.values(whiteLabelKeys.companyLabel);

export const CompanyLabel = ({ data, printRef, willPrint }) => {
  if (!data) return;
  return (
    <Hidden isHidden={willPrint}>
      <CompanyLabelCSS ref={printRef}>
        <CompanyLabelFields willPrint={willPrint}>
          {companyLabelFields.map((field, index) => {
            const Field = companyLabelComponents[field];
            const props = data[field];
            return (
              <div className={`entry grid-item-${index}`} key={field}>
                {typeof props === 'string' ? (
                  <Field data={props} />
                ) : (
                  <Field {...(props ?? {})} />
                )}
              </div>
            );
          })}
        </CompanyLabelFields>
      </CompanyLabelCSS>
    </Hidden>
  );
};

export const SenderEdit = ({
  field,
  values,
  toggleOnlySenders,
  fieldProps,
  placeholder,
  selectOption,
  onlySenders,
  setTouched,
  customerDataOptions: options,
}) => {
  const name = whiteLabelKeys.customerIdField;
  const action = options.find(
    customer => customer.value === values[whiteLabelKeys.customerIdField]
  )?.action;

  return (
    <Flex column w gap="_S">
      <EditSectionTitle text="Sender" />
      <EditFields columns={2}>
        <Input
          hasError
          name={name}
          selectOption={selectOption}
          placeholder={placeholder[name]}
          key={name}
          size="_M"
          {...fieldProps[name]}
          {...field}
          options={options}
          setTouched={setTouched}
        />
        <SenderReturnActionCSS
          h
          gap="_2XS"
          align="start"
          column
          justify={'between'}
          action={action}
        >
          {action ? <SenderAction text={action} /> : <></>}
          <CheckBox
            setSelected={toggleOnlySenders}
            selected={onlySenders}
            label={'Return to sender'}
          />
        </SenderReturnActionCSS>
      </EditFields>
    </Flex>
  );
};

export const ReceiverEdit = ({
  fields,
  fieldProps,
  selectOption,
  placeholder,
  disabled,
  setTouched,
}) => {
  return (
    <Flex column w gap="_S">
      <EditSectionTitle text="Receiver" />
      <EditFields columns={2}>
        {Object.entries(fields).map(([name, field]) => {
          return (
            <Input
              hasError
              name={name}
              selectOption={selectOption}
              placeholder={placeholder[name]}
              key={name}
              size="_M"
              disabled={disabled}
              {...fieldProps[name]}
              setTouched={setTouched}
            />
          );
        })}
      </EditFields>
    </Flex>
  );
};

export const EditActions = ({
  cancelButton,
  saveButton,
  printButton,
  disabled,
  isLoading,
}) => {
  return (
    <Flex gap="_S" className="edit-whitelabel-actions">
      <Button {...cancelButton} />
      <Button {...saveButton} disabled={disabled} isLoading={isLoading} />
      {printButton && (
        <Button {...printButton} disabled={disabled} isLoading={isLoading} />
      )}
    </Flex>
  );
};
