import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect } from 'react';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import { actingBrandFilter } from '../../../../../components/organisms/ActingBrandSelection/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Utils } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { ReferenceUploadLayout } from '../components/ReferenceUploadLayout';
import {
  fileType,
  infoDescription,
  saveText,
  title,
  uploadStatusContent,
} from './variables';
const { SELF: REFERENCES, CREATE_REFERENCES } = PermissionKeys.Brand.REFERENCES;

const ReferenceUpload = ({ view, onUploadSuccess = Utils.emptyFunction }) => {
  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();
  const { dismiss, dismissAll } = useModal();
  const { permissions } = usePermissions();

  useLayoutEffect(() => {
    resetFileUpload();
    return () => resetFileUpload();
  }, []);

  useEffect(() => {
    if (fileState.uploadSuccess) onUploadSuccess();
  }, [fileState]);

  const onPrimaryClick = values => () => {
    if (fileState.uploadSuccess) resetFileUpload();
    else
      handleImport({
        folder: 'actor_uploads',
        url: 'senders/upload',
        body: {
          brand_id: values['brand'].value,
        },
        nameMustBeString: true,
      });
  };

  const backToAdd = () => {
    resetFileUpload();
    dismiss();
  };

  const handleDismiss = () => dismissAll();

  return (
    <Formik initialValues={{}}>
      {({ setFieldValue, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });
        return (
          <Form>
            <ReferenceUploadLayout
              actingBrandProps={{
                selectOption,
                value: values['brand'],
                label: 'Brand',
                brandsFilter: actingBrandFilter(
                  permissions,
                  REFERENCES,
                  CREATE_REFERENCES
                ),
              }}
              backToAdd={backToAdd}
              disabled={
                !fileState.files ||
                fileState.errorType ||
                fileState.isUploading ||
                !values['brand']
              }
              fileState={fileState}
              handleDrop={handleDrop(fileType[view])}
              infoIcon={<InfoIcon description={infoDescription[view]} />}
              onCancel={handleDismiss}
              primaryClick={onPrimaryClick(values)}
              percentageUploaded={percentage}
              saveText={saveText({
                view,
                successfulUpload:
                  fileState.completedUpload && fileState.uploadSuccess,
              })}
              title={title[view]}
              uploadStatusContent={uploadStatusContent({
                fileState,
                resetFileUpload,
                view,
              })}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default Modal(ReferenceUpload);
