import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { usersKeys } from '../controllers/variables';

export const useGetUsers = () => {
  const [{ data = [], isLoading = true, isError, error }, _, refetchUsers] =
    useFetchData('clients', {
      formatter,
    });

  return { data, isLoading, isError, error, refetchUsers };
};

const formatter = data => {
  data = maskEntry(data, mask);

  return data;
};

const mask = {
  bguaID: [usersKeys.id],
  usActive: [usersKeys.active],
  usDisplayName: [usersKeys.name],
  usFirstName: [usersKeys.firstName],
  usLastName: [usersKeys.lastName],
  usEmail: [usersKeys.email],
  usID: [usersKeys.userId],
  usImage: [usersKeys.image],
  name: [usersKeys.service.role],
  added_by_image: [usersKeys.service.addedBy.image],
  added_by_name: [usersKeys.service.addedBy.name],
  added_date: [usersKeys.service.dateAdded],
};
