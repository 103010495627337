import { isEqual } from 'lodash';
import { useEffect, useReducer, useRef, useState } from 'react';
import { specialCaseIgnore } from '../components/organisms/Filters/utils';
import { someDropdownIsEmpty } from '../components/organisms/Table/utils';
import { API } from '../lib/api';
import { cloneDeep } from '../lib/js';
import { useAuth, useReauth } from '../providers/AuthProvider';
import { sanitizeStrings } from '../utils/stringUtils';
import useDeepCompareEffect from '../utils/useDeepCompareEffect';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      let error_msg;
      try {
        error_msg = JSON.parse(action.error.message).message;
      } catch (_) {
        error_msg = action.error?.message ?? 'Error';
      }
      return {
        ...state,
        error: error_msg,
        data: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

const useFetchData = (
  initialUrl,
  {
    initialData = undefined,
    ignore: ignore_ = false,
    params: initialParams = {},
    formatter = data => data,
    failureFormatter = error => error,
    large = false,
  } = {}
) => {
  const [ignore, setIgnore] = useState(ignore_);
  const [propsUrl, setPropsUrl] = useState(initialUrl);
  const [propsParams, setPropsParams] = useState(initialParams);

  const prevParams = useRef('Something different from any initial params');
  const prevUrl = useRef('Something different from any initial url');

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: undefined,
    error: { message: undefined },
    isError: false,
    data: initialData,
  });

  const [{ token }] = useAuth();

  const { withReauth, handleApiError, handleResponseError } = useReauth();

  useEffect(() => {
    setIgnore(ignore_);
  }, [ignore_]);

  const newRequest = ({ url = propsUrl, params = propsParams } = {}) => {
    setPropsUrl(url);
    setPropsParams(params);
    setIgnore(false);
  };

  const refetchRequest = () => {
    setPropsParams(params_ => {
      setPropsUrl(url_ => {
        const url = cloneDeep(url_);
        const params = cloneDeep(params_);

        manualFetch({ url, params });

        return url_;
      });
      return params_;
    });
  };

  // some requests require/are much simpler if a value is returned directly
  // (e.g. loadOptions for CustomSearch)
  const manualFetch = ({ url = propsUrl, params = propsParams } = {}) =>
    withReauth(fetchData, token, [{ url, params_: params }]);

  const getConfigAndParams = (token, params_) => {
    const oldParams = params_ || {};

    const params = {
      ...oldParams,
    };

    const config = {
      params: sanitizeStrings(params),
      headers: {
        Authorization: token,
      },
    };
    return { config, params };
  };

  const runRequest = async ({ url, config }) => {
    dispatch({ type: 'FETCH_INIT' });

    const fetch = large ? API.GETLARGE : API.GET;

    const { data, status, error } = await fetch(url, config);

    handleResponseError({ status, error });

    return data;
  };

  const catchRequest = ({ error, params }) => {
    handleApiError(error);
    dispatch({
      type: 'FETCH_FAILURE',
      payload: failureFormatter(error),
      error,
    });
    return failureFormatter(error, params);
  };

  const shouldIgnore = () => {
    let shouldIt = false;
    const ignoreIf = [
      specialCaseIgnore(propsParams),
      someDropdownIsEmpty(propsParams),
      isEqual(propsParams, prevParams.current) &&
        isEqual(propsUrl, prevUrl.current),
    ];

    if (ignoreIf.some(condition => condition)) shouldIt = true;
    if (!shouldIt) {
      prevParams.current = propsParams;
      prevUrl.current = propsUrl;
    }

    return shouldIt;
  };

  async function fetchData(
    token,
    { url = propsUrl, params_ = propsParams } = {
      url: propsUrl,
      params_: propsParams,
    }
  ) {
    const { config, params } = getConfigAndParams(token, params_);

    try {
      const data = await runRequest({ url, config });
      dispatch({ type: 'FETCH_SUCCESS', payload: formatter(data) });
      return formatter(data, params);
    } catch (error) {
      return catchRequest({ error, params });
    }
  }

  useDeepCompareEffect(() => {
    setPropsParams(initialParams);
  }, [initialParams]);

  useDeepCompareEffect(() => {
    const dontFetch =
      propsUrl == null || !propsUrl.length || ignore || shouldIgnore();
    if (dontFetch) return;
    let didCancel = false;

    withReauth(fetchData, token);

    async function fetchData(
      token,
      { url = propsUrl, params_ = propsParams } = {
        url: propsUrl,
        params_: propsParams,
      }
    ) {
      const { config, params } = getConfigAndParams(token, params_);

      try {
        if (didCancel) return;
        const data = await runRequest({ url, config });

        // if (didCancel) return;
        dispatch({ type: 'FETCH_SUCCESS', payload: formatter(data) });
        return formatter(data, params);
      } catch (error) {
        // if (didCancel) return;

        return catchRequest({ error, params });
      } finally {
        if (!didCancel) setIgnore(ignore_);
      }
    }

    return () => {
      didCancel = true;
    };
  }, [propsUrl, propsParams, ignore]);

  return [state, newRequest, manualFetch, refetchRequest];
};

export default useFetchData;
