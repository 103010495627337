import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { passwordTests } from '../../../components/organisms/PasswordRules/variables';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useUpdatePassword } from '../api/updatePassword';
import { SecurityLayout } from '../components/SecurityLayout';
import {
  securityFields,
  securityInitialValues,
  securityValidationSchema,
} from './variables';

const Security = () => {
  const resetFormRef = useRef(null);

  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const [passwordRules, setPasswordRules] = useState(passwordTests);

  const { body, isLoading, isError, error, updatePassword } =
    useUpdatePassword();

  useEffect(() => {
    if (body == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }

    showSnackbarSuccess('Password succesfully updated');
    resetFormRef.current();
    setPasswordRules(passwordTests);
  }, [body, isLoading, isError]);

  const handlePasswordChange = e => {
    const { value: password } = e?.target;

    const passwordRules = passwordTests.map(test => {
      return { ...test, valid: test.validator(password) };
    });

    setPasswordRules(passwordRules);
  };

  return (
    <Formik
      initialValues={securityInitialValues}
      validationSchema={securityValidationSchema}
      onSubmit={updatePassword}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, resetForm, dirty }) => {
        resetFormRef.current = resetForm;
        return (
          <Form>
            <SecurityLayout
              passwordRules={passwordRules}
              fields={securityFields(handlePasswordChange)}
              onCancel={resetForm}
              cancelDisabled={!dirty || isLoading}
              submitDisabled={
                !isValid ||
                !dirty ||
                isLoading ||
                passwordRules.some(rule => !rule.valid)
              }
              isLoading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Security;
