import { Constants } from '../../../constants';
import {
  includeConstants,
  offsetGrowingBorderPadding,
} from '../../../utils/styleUtils';
import { Colors, Effects, Layout, Size, StatusColors } from '../../style';

const {
  Authentication,
  Inspection,
  Counterfeit,
  ['Non-counterfeit']: _,
  Seized,
  Released,
  Stopped,
  OfficeStop,
  OfficeRelease,
} = Constants.Status;

const constantStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: Layout.Spacing._2XS,
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

export const Recipes = {
  Primary: (
    state,
    { size, shadow, disabled, fullWidth, leftIcon, rightIcon }
  ) => {
    if (disabled && shadow) return;
    return {
      backgroundColor: disabled
        ? Colors.Neutral._300
        : state.focus
        ? Colors.Primary._800
        : state.hover
        ? Colors.Primary._700
        : Colors.Primary._600,
      boxShadow: Effects.Box_Shadow[shadow],
      borderRadius: Effects.Border_Radius._S,
      border: '1px solid transparent',
      color: disabled ? Colors.Neutral._500 : Colors.Sharp.White,
      loadingColor: Colors.Sharp.White,
      cursor: disabled ? 'auto' : 'pointer',
      ...sizeProperties({ size, fullWidth, leftIcon, rightIcon }),
    };
  },
  Secondary: (
    state,
    { size, shadow, disabled, fullWidth, leftIcon, rightIcon }
  ) => {
    if (disabled && shadow) return;
    return {
      backgroundColor: Colors.Sharp.White,
      boxShadow: Effects.Box_Shadow[shadow],
      borderStyle: 'solid',
      borderRadius: Effects.Border_Radius._S,
      cursor: disabled ? 'auto' : 'pointer',
      borderWidth: disabled
        ? Effects.Border._S
        : state.focus || state.hover
        ? Effects.Border._M
        : Effects.Border._S,
      borderColor: disabled
        ? Colors.Neutral._500
        : state.focus
        ? Colors.Primary._800
        : state.hover
        ? Colors.Primary._700
        : Colors.Primary._600,
      color: disabled
        ? Colors.Neutral._500
        : state.focus
        ? Colors.Primary._800
        : Colors.Primary._700,
      loadingColor: Colors.Neutral._400,
      ...sizeProperties({
        size,
        fullWidth,
        leftIcon,
        rightIcon,
        growBorder: (state.focus || state.hover) && !disabled,
      }),
    };
  },
  Tertiary: (
    state,
    {
      size,
      shadow,
      disabled,
      fullWidth,
      leftIcon,
      rightIcon,
      black,
      gray,
      isClickable,
    }
  ) => {
    if (shadow) return;
    return {
      backgroundColor: 'transparent',
      boxShadow: Effects.Box_Shadow[shadow],
      borderRadius: Effects.Border_Radius._S,
      border: 'none',
      cursor: disabled || !isClickable ? 'auto' : 'pointer',
      color: !isClickable
        ? Colors.Primary._600
        : disabled
        ? Colors.Neutral._500
        : state.focus
        ? Colors.Primary._800
        : state.hover
        ? Colors.Primary._700
        : black
        ? Colors.Neutral._900
        : gray
        ? Colors.Neutral._500
        : Colors.Primary._600,
      loadingColor: Colors.Neutral._400,
      ...sizeProperties({
        size,
        fullWidth,
        leftIcon,
        rightIcon,
        padding: false,
      }),
    };
  },
  Status: (state, { size, disabled, fullWidth, status, isClickable }) => {
    disabled = disabled || !isClickable;
    const { background, backgroundHover, backgroundFocus } =
      statusButtonColors[status];

    const { border, borderHover, borderFocus } = statusButtonColors[status];

    const { color } = statusButtonColors[status];

    return {
      backgroundColor: disabled
        ? background
        : state.focus
        ? backgroundFocus
        : state.hover
        ? backgroundHover
        : background,
      borderStyle: 'solid',
      borderColor: disabled
        ? border
        : state.focus
        ? borderFocus
        : state.hover
        ? borderHover
        : border,
      borderWidth: disabled
        ? Effects.Border._S
        : state.focus || state.hover
        ? Effects.Border._M
        : Effects.Border._S,
      borderRadius: Effects.Border_Radius._S,
      cursor: disabled ? 'auto' : 'pointer',
      color,
      opacity: disabled && isClickable ? '0.5' : '1',
      ...sizeProperties({
        size,
        fullWidth,
        growBorder: (state.focus || state.hover) && !disabled,
      }),
    };
  },
};

const sizeProperties = ({
  size,
  fullWidth,
  padding = true,
  leftIcon,
  rightIcon,
  growBorder,
}) => {
  return {
    width: fullWidth ? '100%' : Size.Button[size].width,
    height: Size.Button[size].height,
    iconSize: Size.Icon[size],
    paddingLeft: offsetGrowingBorderPadding({
      padding: padding && Layout.Padding[leftIcon ? '_M' : '_L'],
      selected: growBorder,
    }),
    paddingRight: offsetGrowingBorderPadding({
      padding: padding && Layout.Padding[rightIcon ? '_M' : '_L'],
      selected: growBorder,
    }),
  };
};

const statusButtonColors = {
  [Inspection]: {
    color: Colors.Sharp.White,
    background: StatusColors[Inspection],
    backgroundHover: Colors.Primary._500,
    backgroundFocus: Colors.Primary._600,
    border: StatusColors[Inspection],
    borderHover: Colors.Primary._500,
    borderFocus: Colors.Primary._600,
  },
  [Authentication]: {
    color: Colors.Sharp.White,
    background: StatusColors[Authentication],
    backgroundHover: Colors.Error._700,
    backgroundFocus: Colors.Error._900,
    border: StatusColors[Authentication],
    borderHover: Colors.Error._700,
    borderFocus: Colors.Error._900,
  },
  [Counterfeit]: {
    color: Colors.Sharp.White,
    background: StatusColors[Counterfeit],
    backgroundHover: Colors.CherryRed._700,
    backgroundFocus: Colors.CherryRed._900,
    border: StatusColors[Counterfeit],
    borderHover: Colors.CherryRed._700,
    borderFocus: Colors.CherryRed._900,
  },
  ['Non-counterfeit']: {
    color: Colors.Sharp.White,
    background: StatusColors['Non-counterfeit'],
    backgroundHover: Colors.Primary._700,
    backgroundFocus: Colors.Primary._900,
    border: StatusColors['Non-counterfeit'],
    borderHover: Colors.Primary._700,
    borderFocus: Colors.Primary._900,
  },
  [Seized]: {
    color: Colors.Sharp.White,
    background: StatusColors[Seized],
    backgroundHover: Colors.Primary._700,
    backgroundFocus: Colors.Primary._900,
    border: StatusColors[Seized],
    borderHover: Colors.Primary._700,
    borderFocus: Colors.Primary._900,
  },
  [Released]: {
    color: Colors.Sharp.White,
    background: StatusColors[Released],
    backgroundHover: Colors.Success._700,
    backgroundFocus: Colors.Success._900,
    border: StatusColors[Released],
    borderHover: Colors.Success._700,
    borderFocus: Colors.Success._900,
  },
  [Stopped]: {
    color: Colors.Sharp.White,
    background: StatusColors[Stopped],
    backgroundHover: Colors.Warning._700,
    backgroundFocus: Colors.Warning._900,
    border: StatusColors[Stopped],
    borderHover: Colors.Warning._700,
    borderFocus: Colors.Warning._900,
  },
  [OfficeRelease]: {
    // these might clash with the above bc their values are repeated in Constants.Status
    color: Colors.Sharp.White,
    background: Colors.Primary._500,
    backgroundHover: Colors.Primary._700,
    backgroundFocus: Colors.Primary._900,
    border: Colors.Primary._500,
    borderHover: Colors.Primary._700,
    borderFocus: Colors.Primary._900,
  },
  [OfficeStop]: {
    // these might clash with the above bc their values are repeated in Constants.Status
    color: Colors.Sharp.White,
    background: Colors.CherryRed._500,
    backgroundHover: Colors.CherryRed._700,
    backgroundFocus: Colors.CherryRed._900,
    border: Colors.CherryRed._500,
    borderHover: Colors.CherryRed._700,
    borderFocus: Colors.CherryRed._900,
  },
};
