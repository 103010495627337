import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import { InstructionTypes } from '../components/organisms';

export const CharacteristicTypesLayout = ({
  instructionTypes,
  selectType,
  typeSelected,
  onPrimary,
  onBack,
  onCancel,
  disabled,
}) => {
  return (
    <StepPopup
      title={'New knowledge base - Select a characteristic'}
      sectionTitle="Select a characteristic for the new knowledge base"
      onPrimary={onPrimary}
      primaryText="Next"
      onCancel={onCancel}
      onBack={onBack}
      disabled={disabled}
    >
      <InstructionTypes
        types={instructionTypes}
        selectType={selectType}
        typeSelected={typeSelected}
      />
    </StepPopup>
  );
};
