import { PasswordRule } from './PasswordRules.style';

const PasswordRules = ({ rules }) => {
  return (
    <div>
      {rules.map(rule => (
        <PasswordRule valid={rule.valid} text={rule.text} key={rule.text} />
      ))}
    </div>
  );
};

export default PasswordRules;
