import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import Actions from '../../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../../components/templates/Popup';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import { ProductPhotos } from '../../../../../facilitator/features/processing/components/page/ProductPhotos';
import { FormSectionTitle } from '../atoms';
import { ProductDetailsEditFields } from '../organisms';

export const EditProductLayout = ({
  title,
  options,
  selectOption,
  disabled,
  onCancel,
  isValid,
  isLoading,
  photosProps,
}) => {
  return (
    <Popup size="_M" title={title}>
      <Content>
        <Flex w h column gap="_S">
          <Spacer />
          <Flex w h gap="_S">
            <Flex w h column gap="_S">
              <FormSectionTitle text={'Product details'} />
              <ProductDetailsEditFields
                options={options}
                selectOption={selectOption}
                disabled={disabled}
              />
            </Flex>
            <ProductPhotos {...photosProps} />
          </Flex>
          <Actions
            onCancel={onCancel}
            disabled={!isValid || isLoading}
            isLoading={isLoading}
          />
        </Flex>
      </Content>
    </Popup>
  );
};
