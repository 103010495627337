import { magnitudeFormatter } from '../../../utils/stringUtils';
import { calculateThird, ignoreNaNs } from '../../../utils/utils';

export const formatTick = domainMax => number =>
  magnitudeFormatter(number, domainMax);

export const getChartTicks = data => {
  let max = 4;

  data.forEach(entry => {
    max = Math.max(max, ...ignoreNaNs(entry));
  });

  const third = calculateThird(max);

  return { third, domainMax: Math.floor(max * 1.1) };
};
