import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Table } from '../../../../../components/organisms/Table/Table';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { snapshotTableComponents } from './molecules';
import { SnapshotHeader } from './organisms';

export const SnapshotListLayout = ({
  headerProps,
  tableProps,
  tableComponentProps,
  tab,
  tableStatusProps,
  isLoading,
  isError,
  error,
}) => {
  return (
    <Flex w h column gap="_S">
      <SnapshotHeader {...headerProps} />
      <TableStatus
        isLoading={isLoading}
        isError={isError}
        error={error}
        {...tableStatusProps}
        table={
          <Table
            {...tableProps}
            components={snapshotTableComponents(tableComponentProps)[tab]}
          />
        }
      />
    </Flex>
  );
};
