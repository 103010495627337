import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PropKeys } from '../../../../../constants';
import { useScrollLoad } from '../../../../../hooks/scrollLoadHook';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import {
  formatParams,
  useGetProducts,
} from '../../../features_public/products/api/getProducts';
import { useGetProductsImage } from '../../../features_public/products/api/getProductsImage';
import ProductSummary from '../../../features_public/products/controllers/ProductSummary';
import { matchImagesToProduct } from '../../../features_public/products/controllers/utils';
import { productKeys } from '../../../features_public/products/controllers/variables';
import { ReferenceCasesLayout } from '../components/ReferenceCasesLayout';
import {
  DC,
  referenceCasesNoResultsState,
  referenceScrollLimit,
} from './variables';

const ReferenceCases = ({ referenceId }) => {
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [results, setResults] = useState([]);
  const { store } = useStore();

  const searchParams = {
    [DC.SEARCH]: referenceId,
    [DC.PAGE]: 1,
    [DC.ITEMS_PER_PAGE]: referenceScrollLimit,
  };

  if (store[PropKeys.brandId])
    searchParams[DC.BRANDS] = [
      { options: [{ value: store[PropKeys.brandId], selected: true }] },
    ];

  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { showSnackbarError } = useSnackbar();

  const scrollRef = useRef(null);

  const {
    data: products,
    isLoading,
    isError,
    error,
  } = useScrollLoad({
    hook: useGetProducts,
    getMore: 'getProducts',
    hookSearchParams: { searchParams },
    formatParams,
    search: referenceId,
    scrollRef,
    formatter: data => data?.products ?? [],
    initialLimit: referenceScrollLimit,
    limit: referenceScrollLimit,
    moreActivityClicked: true,
  });

  const {
    data: images,
    isLoading: imagesIsLoading,
    isError: imagesIsError,
    error: imagesError,
    getProductsImage,
  } = useGetProductsImage();

  useDeepCompareEffect(() => {
    setResults(products);
    if (products?.length) getProductsImage({ products });
  }, [products]);

  useEffect(() => {
    if (imagesIsError) {
      showSnackbarError(imagesError);
      setResults(matchImagesToProduct([]));
      return;
    }

    if (products?.length && images != null)
      setResults(matchImagesToProduct(images));
  }, [images]);

  const rowHoverIn = (_, index) => setHoveredRow(index);
  const rowHoverOut = _ => setHoveredRow(-1);

  // TODO: DRY this to public cases
  const showProductSummary = item => {
    let {
      [productKeys.productId]: productId,
      [productKeys.productAlias]: productAlias,
    } = item;

    showModal(ProductSummary, {
      productId,
      productAlias,
      navigate,
      from: location.pathname,
    });
  };

  return (
    <ReferenceCasesLayout
      cases={results}
      scrollRef={scrollRef}
      isLoading={isLoading}
      isError={isError}
      error={error}
      rowAction={showProductSummary}
      rowHoverIn={rowHoverIn}
      rowHoverOut={rowHoverOut}
      hoveredRow={hoveredRow}
      tableStatusProps={{
        noData: !results.length,
        noDataDisplay: referenceCasesNoResultsState.noData,
        noSearchResultsDisplay: referenceCasesNoResultsState.noData,
      }}
    />
  );
};
export default ReferenceCases;
