import styled from '@emotion/styled';
import { Flex } from '../../components/atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../components/style';
import { Styles } from '../../constants';
// inline media queries
// https://medium.com/@ttennant/react-inline-styles-and-media-queries-using-a-custom-react-hook-e76fa9ec89f6
export const styles = {
  logo: {
    height: '48px',
    position: 'absolute',
    cursor: 'pointer',
    top: Layout.Spacing._M,
    left: Layout.Spacing._M,
  },
  passwordRequestSuccess: {
    display: 'flex',
    flexDirection: 'column',
    gap: Layout.Spacing._L,
    image: {
      height: 'auto',
      width: '134px',
      margin: '0 auto',
    },
  },
  emptySquares: {
    position: 'absolute',
    left: '-53px',
    top: ' 74px',
    height: '53px',
  },
  fullSquares: {
    position: 'absolute',
    bottom: '-28px',
    right: '4px',
    height: '19px',
    width: '44px',
  },
  layout: {
    width: '100vw',
    minHeight: '100vh',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.Neutral._100,
    padding: Layout.Spacing._M,
  },
  infoCard: {
    position: 'relative',
    // height: '388px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gridColumnStart: 1,
    gridColumnEnd: 13,
    width: '378px',
  },
  cardConstants: {
    display: 'flex',
    flexDirection: 'column',
    gap: Layout.Spacing._M,
  },
  flexibleHeightCard: {
    gap: 'unset',
    justifyContent: 'space-between',
    width: '442px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  options2FA: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: Layout.Spacing._M,
    width: '100%',
    height: '36px',
    padding: `0 ${Layout.Spacing._2XS}`,
    marginBottom: '4px',
  },
};

export const TwoFAContainer = styled.div`
  .label {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
`;

export const Setup2FAContainer = styled(TwoFAContainer)``;
export const Verify2FAContainer = styled(TwoFAContainer)``;

export const SuccessCardCSS = styled.div`
  position: relative;
  height: 388px;
  width: 442px;
  .card {
    width: 100%;
    height: 100%;
    gap: ${Layout.Spacing._L};
    button {
      margin-top: auto;
    }
  }
`;

const clickareaPadding = Layout.Spacing._2XS;
export const FavoriteIconCSS = styled.div`
  cursor: pointer;
  position: absolute;
  right: -${clickareaPadding};
  padding: ${clickareaPadding};
  ${Styles.flexCenter};
`;

export const SidebarBrandsCountCSS = styled(Flex)`
  min-width: 20px;
  height: 20px;
  padding: ${Layout.Spacing._3XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._2XL};
  ${Styles.flexCenter};
`;

export const BrandsCountCSS = styled(Flex)`
  min-width: 16px;
  height: 16px;
  padding: ${Layout.Spacing._3XS};
  background: ${Colors.Neutral._200};
  border-radius: ${Effects.Border_Radius._2XL};
  ${Styles.flexCenter};
`;
