import { styles } from '../components/style';
import UserSettingsLayout from '../components/UserSettingsLayout';
import Security from './Security';
import UserInfo from './UserInfo';
import { options, optionViews } from './variables';

const UserSettings = () => {
  const components = {
    [optionViews.userInfo]: <UserInfo />,
    [optionViews.security]: <Security />,
  };

  return (
    <UserSettingsLayout
      options={options}
      components={components}
      styles={styles}
    />
  );
};

export default UserSettings;
