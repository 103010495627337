import { HorizontalDelta, VerticalDelta } from './Delta.exports';

export const Delta = ({
  delta,
  period,
  horizontal = false,
  deltaExtraText = '',
  deltaLabelExtraElement,
  size = '_L',
  positiveIsGood,
  className,
  tooltipSide,
}) => {
  const Component = horizontal ? HorizontalDelta : VerticalDelta;
  return (
    <Component
      delta={delta}
      period={period}
      deltaExtraText={deltaExtraText}
      deltaLabelExtraElement={deltaLabelExtraElement}
      size={size}
      positiveIsGood={positiveIsGood}
      className={className}
      tooltipSide={tooltipSide}
    />
  );
};
