import { PropKeys } from '../../constants';
import usePostData from '../../hooks/postHook';

export const useUpdateFavoriteBrand = () => {
  const [{ body, isLoading, isError, error }, doUpdate] = usePostData('', {
    type: 'put',
  });

  const doFavoriteToggle = brand => {
    const brandId = brand[PropKeys.brandId];
    const isFavorite = brand[PropKeys.favoritedBrand] ? 1 : 0;

    const url = `user_profile/brand/${brandId}/favor`;
    const body = {
      status: isFavorite ? 0 : 1,
      brandId,
    };
    doUpdate(body, url);
  };

  return { body, isLoading, isError, error, doFavoriteToggle };
};
