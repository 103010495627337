import { useEffect, useState } from 'react';
import { PropKeys } from '../../constants';
import { cloneDeep } from '../../lib/js';
import { useReauth } from '../../providers/AuthProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useStore } from '../../providers/StoreProvider';
import { useUpdateFavoriteBrand } from '../api/updateFavoriteBrand';
import { BrandSelectionLayout } from '../components/BrandSelectionLayout';
import { markCurrentClient } from './utils';

const BrandSelection = () => {
  const [clients, setClients] = useState([]);
  const { store, updateStore } = useStore();
  const { switchBrands } = useReauth();
  const { showSnackbarError } = useSnackbar();

  const { body, isLoading, isError, error, doFavoriteToggle } =
    useUpdateFavoriteBrand();

  useEffect(() => {
    if (body == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }

    updateUIFavorites(body);
  }, [body, isLoading, isError]);

  useEffect(() => {
    const clients = store[PropKeys.clients] ?? [];
    const currentCliendId = store[PropKeys.brandId] ?? '';

    const displayClients = markCurrentClient(clients, currentCliendId);
    setClients(displayClients);
  }, [store[PropKeys.clients], store[PropKeys.brandId]]);

  useEffect(() => {
    const currentCliendId = store[PropKeys.brandId] ?? '';
    const clientImage = store[PropKeys.brandImage];

    const updateBrandImage = clients =>
      clients.map(brand => {
        if (brand[PropKeys.brandId] === currentCliendId)
          brand[PropKeys.brandImage] = clientImage;
        return brand;
      });

    setClients(updateBrandImage);
  }, [store[PropKeys.brandImage]]);

  const updateUIFavorites = ({ status, brandId }) => {
    const clients = store[PropKeys.clients];
    const newClients = clients.map(c => {
      let client = cloneDeep(c);
      if (client[PropKeys.brandId] == brandId)
        client[PropKeys.favoritedBrand] = status;
      return client;
    });
    updateStore({ [PropKeys.clients]: newClients });
  };

  const handleBrandSwitch =
    ({ [PropKeys.brandId]: brandId }) =>
    async () => {
      if (brandId === store[PropKeys.brandId]) return;

      await switchBrands(brandId);
      const to = '/';

      window.location = to;
    };

  const toggleFavorite = brand => e => {
    e?.stopPropagation();
    doFavoriteToggle(brand);
  };

  return (
    <BrandSelectionLayout
      clients={clients}
      handleBrandSwitch={handleBrandSwitch}
      toggleFavorite={toggleFavorite}
      isAllBrands={!store[PropKeys.brandId]}
    />
  );
};

export default BrandSelection;
