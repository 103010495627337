import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { AdminCategoriesTable } from './organisms';

const AdminCategoriesLayout = ({
  tableProps,
  tableStatusProps,
  isLoading,
  isError,
  error,
}) => {
  return (
    <TableStatus
      {...tableStatusProps}
      isLoading={isLoading}
      isError={isError}
      error={error}
      table={<AdminCategoriesTable tableProps={tableProps} />}
    />
  );
};
export default AdminCategoriesLayout;
