import { useEffect, useState } from 'react';
import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { TabHighlight } from '../../../../../../components/atoms/TabHighlight/TabHighlight';
import { Input } from '../../../../../../components/molecules';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import { Filters } from '../../../../../../components/organisms/Filters';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { Layout, StatusColors } from '../../../../../../components/style';
import useDynamicRefs from '../../../../../../utils/dynamicRefs';
import {
  elementDimensions,
  parentPosition,
} from '../../../../../../utils/utils';
import { ReferenceEntryForm } from '../../../../../brand/features_public/reference_index/components/organisms';
import {
  ReferenceSearchDescription,
  SectionTitle,
  StatusDescriptionText,
  Tab,
} from '../atoms';
import {
  AddBarcode,
  Alerts,
  Detail,
  ReferenceSearchResults,
  filesTableComponents,
} from '../molecules';
import {
  DetailsCSS,
  FilesTableContainer,
  StatusDescriptionCSS,
  TabsContainer,
} from '../style';

export const Tabs = ({ tabs, openTab, setOpenTab }) => {
  const [getRef, setRef] = useDynamicRefs();
  const [width, setWidth] = useState();
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex(tab => tab === openTab);
    const button = getRef(`tab${index}`).current;

    const buttonWidth = elementDimensions(button).width ?? 0;
    setWidth(buttonWidth);

    const buttonLeft = parentPosition(button).x ?? 0;
    setLeft(buttonLeft);
  }, [openTab]);

  return (
    <TabsContainer w column relative>
      <Flex gap="_L">
        {tabs.map((tab, index) => (
          <Tab
            tab={tab}
            selected={tab === openTab}
            onClick={() => setOpenTab(tab)}
            ref={setRef(`tab${index}`)}
            key={index}
          />
        ))}
      </Flex>
      <Spacer height="_XS" />
      <Spacer height="_3XS" />
      <Separator />
      <TabHighlight left={left} width={width} />
    </TabsContainer>
  );
};

export const Details = ({ details, status }) => {
  return (
    <DetailsCSS>
      {(details ?? []).map(detail => {
        const { title, text } = detail;
        return <Detail title={title} text={text} key={detail.title} />;
      })}
      <Badge type={BadgeType.status} text={status} size="_M" />
    </DetailsCSS>
  );
};

export const StatusDescription = ({ status, title, text, alerts }) => {
  return (
    <StatusDescriptionCSS>
      <Card title={title} color={StatusColors[status]}>
        <Flex gap="_XS" column w grow>
          <StatusDescriptionText text={text} />
          <Alerts alerts={alerts} />
        </Flex>
      </Card>
    </StatusDescriptionCSS>
  );
};

export const TrackingNumber = ({ title, inputProps }) => {
  return (
    <Flex column gap="_XS">
      <SectionTitle text={title} />
      <Input {...inputProps} />
    </Flex>
  );
};

export const Barcodes = ({ title, tableProps, addBarcodeProps }) => {
  return (
    <Flex column grow gap="_XS" className="barcodes">
      <Flex w justify="between">
        <SectionTitle text={title} />
        <AddBarcode {...addBarcodeProps} />
      </Flex>
      {!!tableProps?.data?.length && <Table {...tableProps} />}
    </Flex>
  );
};

export const ReferenceInfoSearch = ({ searchProps }) => {
  return (
    <Flex column w gap="_M" padding={`${Layout.Spacing._L} 0 0 0`}>
      <ReferenceSearchDescription text={searchProps.description} />
      <Search {...searchProps} />
    </Flex>
  );
};

export const ReferenceInfo = ({ referenceInfoProps }) => (
  <ReferenceEntryForm {...referenceInfoProps} />
);

export const Search = ({
  filters,
  results,
  showResults,
  selectReference,
  isLoading,
  handleSearchClick,
  headers,
  components,
  grid,
  disabled,
}) => {
  return (
    <Flex column relative gap="_2XS">
      <Filters
        filters={filters}
        disabled={isLoading || disabled}
        handleSearchClick={handleSearchClick}
        searchTheme={'popup'}
      />
      <ReferenceSearchResults
        headers={headers}
        results={results}
        components={components}
        grid={grid}
        rowAction={selectReference}
        showResults={showResults}
      />
    </Flex>
  );
};

export const FilesTable = ({ headers, data, grid, downloadFile }) => {
  return (
    <FilesTableContainer
      headers={headers}
      data={data}
      grid={grid}
      components={filesTableComponents({ downloadFile })}
    />
  );
};
