import { useEffect, useRef } from 'react';
import { mapToLightweightState } from '../../../../../components/organisms/Filters/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants, Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { openNewTab } from '../../../../../utils/utils';
import ContactSupportButton from '../../../components/molecules/ContactSupportButton/ContactSupportButton';
import { useGetCaseDetails } from '../../../features/products/api/getCaseDetails';
import { useGetProductDetails } from '../../../features/products/api/getProductDetails';
import {
  goToTicketsForProduct,
  ticketCountText,
} from '../../../features/products/controllers/utils';
import { actions } from '../../../features/products/controllers/variables';
import {
  goToProductDetailsText,
  statusStepsHistory,
  statusStepsText,
  statusStepsTextNonOwner,
} from '../../../features/products/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { caseKeys } from '../../cases/controllers/variables';
import { ticketTypes } from '../../support/controllers/variables';
import { ProductSummaryLayout } from '../templates/ProductSummaryLayout';
import { productKeys } from './variables';

const { Status } = Constants;

const {
  SELF: SUPPORT,
  VIEW_GENERAL_SUPPORT_TICKETS,
  VIEW_OWN_GENERAL_SUPPORT_TICKETS,
  VIEW_PARCEL_SUPPORT_TICKETS,
  VIEW_OWN_PARCEL_SUPPORT_TICKETS,
  VIEW_PRODUCT_SUPPORT_TICKETS,
  VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const {
  SELF: PRODUCTS,
  VIEW_PRODUCTS_DETAILS,
  VIEW_PRODUCTS,
} = PermissionKeys.Brand.PRODUCTS;

const { SELF: PARCELS, VIEW_PARCELS } = PermissionKeys.Brand.PARCELS;

const ProductSummary = ({
  productId,
  productAlias,
  updateUI = Utils.emptyFunction,
  searchParams = {},
  navigate,
  newTab,
  from,
}) => {
  const title = `Product ${productAlias ?? ''}`;

  const { dismiss } = useModal();
  const { hasPermission } = usePermissions();

  const ticketsRef = useRef([]);
  const dataRef = useRef({});

  const goToTickets = goToTicketsForProduct(productId);
  const onViewTicketClick = () => goToTickets(ticketsRef.current);

  const goToAuthentication = () => {
    const to = `${NavURIs[Pages.authentication]}`;
    navigate(to);
  };

  const actionFunctions = {
    [Status.Authentication]: {
      [actions.authentication]: goToAuthentication,
    },
  };

  const hasTicketCreatePermission = hasPermission(
    SUPPORT,
    CREATE_PRODUCT_SUPPORT_TICKETS
  );

  const hasDetailsPermission = hasPermission(PRODUCTS, [
    VIEW_PRODUCTS,
    VIEW_PRODUCTS_DETAILS,
  ]);

  const hasParcelViewPermission = hasPermission(PARCELS, VIEW_PARCELS);

  const hasCaseViewPermission = hasParcelViewPermission;

  const { data, isLoading, isError, refetchData } = useGetProductDetails({
    productId,
    actionFunctions,
    hasTicketCreatePermission,
  });

  const {
    data: caseData,
    isLoading: caseIsLoading = true,
    isError: caseIError,
    getCaseDetails,
  } = useGetCaseDetails({
    ignore: !hasCaseViewPermission,
  });

  useEffect(() => {
    if (data == null || data[caseKeys.id] == null || isLoading) return;
    if (isError) return;

    if (hasCaseViewPermission) getCaseDetails(data[caseKeys.id]);
  }, [data]);

  useEffect(() => {
    ticketsRef.current = data.tickets;
    dataRef.current = data;
  }, [data]);

  const contactSupportButton = (
    <ContactSupportButton
      productData={data}
      goBack={dismiss}
      disabled={isLoading}
      productTitle={productAlias}
      type={ticketTypes.Product}
      newTab={newTab}
    />
  );

  const goToProductDetails = () => {
    const to = `${NavURIs[Pages.products]}/${productId}`;
    const state = {
      searchParams: mapToLightweightState(searchParams),
      from,
    };
    newTab ? openNewTab(to, { state }) : navigate(to, { state });
    dismiss();
  };

  const canViewSomeTicket = [
    VIEW_GENERAL_SUPPORT_TICKETS,
    VIEW_OWN_GENERAL_SUPPORT_TICKETS,
    VIEW_PARCEL_SUPPORT_TICKETS,
    VIEW_OWN_PARCEL_SUPPORT_TICKETS,
    VIEW_PRODUCT_SUPPORT_TICKETS,
    VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  ].some(permission => hasPermission(permission));

  return (
    <ProductSummaryLayout
      title={title}
      goBack={dismiss}
      contactSupportButton={contactSupportButton}
      productId={productId}
      productAlias={productAlias}
      productDetails={data[productKeys.productDetails]}
      status={data[productKeys.productStatus]}
      photos={data[productKeys.photos]}
      steps={statusStepsHistory[data.productStatus]}
      statusInstructions={
        data[productKeys.isProductOwner]
          ? statusStepsText[data.productStatus]
          : statusStepsTextNonOwner[data.productStatus]
      }
      actions={data.actions}
      actionIsLoading={false}
      riskType={caseData?.[productKeys.riskType]}
      reason={caseData?.[productKeys.reason]}
      parcelIsLoading={caseIsLoading}
      ticketCountText={ticketCountText(data[productKeys.tickets])}
      goToTickets={onViewTicketClick}
      displayTicketCount={data?.[productKeys.tickets]?.length}
      activity={data[productKeys.activity]}
      activityShown={3}
      productDetailsButtonText={goToProductDetailsText}
      goToProductDetails={goToProductDetails}
      componentSize="_M"
      isLoading={isLoading}
      isError={isError}
      hasDetailsPermission={hasDetailsPermission}
      hasViewPermission={canViewSomeTicket}
      hasTicketCreatePermission={hasTicketCreatePermission}
      hasCaseViewPermission={hasCaseViewPermission}
    />
  );
};

export default Modal(ProductSummary);
