import { Colors } from '../../style';

export const containerHeight = {
  _L: '40px',
  _M: '32px',
  _S: '24px',
  _XS: '20px',
};

export const statusWidth = {
  _L: '176x',
  _M: '152px',
  _S: '128px',
  _XS: '104px',
};

export const ticketWidth = {
  _L: '120px',
  _M: '104px',
  _S: '80px',
};

export const iconSize = {
  _L: 20,
  _M: 16,
  _S: 12,
  _XS: 12,
};

export const ticketStyle = {
  Open: {
    background: Colors.Primary._400,
    color: Colors.Sharp.White,
    borderColor: Colors.Primary._400,
  },
  Resolved: {
    background: Colors.Success._500,
    color: Colors.Sharp.White,
    borderColor: Colors.Success._500,
  },
  Normal: {
    background: 'transparent',
    color: Colors.Primary._400,
    borderColor: Colors.Primary._400,
  },
  Priority: {
    background: 'transparent',
    color: Colors.Primary._600,
    borderColor: Colors.Primary._600,
  },
};
