import usePostData from '../../../../../hooks/postHook';

export const useUpdateMainContact = () => {
  const [{ data, body, isLoading, isError, error }, doUpdateMainContact] =
    usePostData(``, {
      type: 'put',
    });

  return { data, body, isLoading, isError, error, doUpdateMainContact };
};
