import { IconTextButton } from './components';
import { Container } from './style';

export const OptionFrame = ({ icon, title, text, buttonText, onClick }) => {
  return (
    <Container>
      <IconTextButton
        icon={icon}
        title={title}
        text={text}
        button={{
          text: buttonText,
          onClick,
        }}
      />
    </Container>
  );
};
