import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data = {}, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_services/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { types } = data;

  const newOps = {};

  newOps[DC.SERVICE] = prepareDataForDropdown(
    types.map(type => ({ type })),
    'type',
    'type'
  );

  return newOps;
};
