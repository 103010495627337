import { matchRoutes, Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthRoutes } from '../auth';
import { TableLoader } from '../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { PublicLayout } from '../components/templates/PublicLayout/PublicLayout';
import { initialToken, useAuth } from '../providers/AuthProvider';
import { NavURIs, nonRedirects, Pages } from './variables';

const Auth = () => {
  const [{ token }] = useAuth();

  const location = useLocation();

  const [{ pathnameBase: tokenNotNeeded }] = matchRoutes(
    nonRedirects,
    location
  ) ?? [{}];

  return (
    <PublicLayout>
      {(token === initialToken || token != null) && !tokenNotNeeded ? (
        <TableLoader />
      ) : (
        <Outlet />
      )}
    </PublicLayout>
  );
};

export const publicRoutes = [
  {
    path: '/*',
    element: <Auth />,
    children: [
      {
        path: '*',
        element: <AuthRoutes />,
      },
      {
        path: '*',
        element: <Navigate to={NavURIs[Pages.login]} replace />,
      },
    ],
  },
];
