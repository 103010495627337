import { Icon } from '../../../../../../components/atoms';
import BrandImage from '../../../../../../components/atoms/BrandImage/BrandImage';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import {
  LocationHorizontal,
  RowDateHorizontal,
  RowMultipleImages,
  RowSimpleText,
  RowSingleImage,
  Space,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import { setImagePlaceholder } from '../../../../../../utils/utils';
import { processingKeys } from '../../controllers/variables';
import {
  BrandName,
  ProductStepNumber,
  StepLabel,
  StepValueLabel,
  SummaryDetailSubtext,
  SummaryDetailText,
  SummaryDetailTitle,
} from '../atoms';
import { BrandGridItemCSS, BrandIsContractedIconCSS, Line } from '../style';

const isContractedIcon = (
  <Icon
    name={Icons.CC_CheckCircle}
    size={Size.Icon._S}
    color={Colors.Primary._600}
  />
);

export const tableComponents = ({ showGallery }) => ({
  [processingKeys.leftPadding]: { display: Space },
  [processingKeys.image]: {
    display: RowMultipleImages({
      showGallery,
      photosKey: processingKeys.photos,
      size: '_S',
    }),
  },
  [processingKeys.alias]: {
    display: RowSimpleText(processingKeys.alias, 'bold'),
  },
  [processingKeys.sideloadDate]: {
    display: RowDateHorizontal(processingKeys.sideloadDate),
  },
  [processingKeys.location]: {
    display: LocationHorizontal(processingKeys.sorter, processingKeys.city),
  },
  [processingKeys.itemCount]: {
    display: RowSimpleText(processingKeys.itemCount, 'bold'),
  },
});

const ProductBrand = item => {
  const { [processingKeys.product.brand.isContracted]: isContracted } = item;
  const BrandName = RowSimpleText(
    processingKeys.product.brand.name,
    'bold'
  )(item);

  return (
    <Flex gap="_2XS" align="center" w0 grow>
      {BrandName}
      {!!isContracted && isContractedIcon}
    </Flex>
  );
};

const EditProduct = onClick => item => {
  return (
    <AnimatedIcon onClick={onClick(item)} theme="neutral" name={Icons.Edit3} />
  );
};

export const caseProductsTableComponents = ({
  editProduct,
  disabled,
  showGallery,
}) => ({
  [processingKeys.leftPadding]: { display: Space },
  [processingKeys.product.image]: {
    display: RowSingleImage(processingKeys.product.photos, { showGallery })(
      '_S'
    ),
  },
  [processingKeys.product.alias]: {
    display: RowSimpleText(processingKeys.product.alias, 'bold'),
  },
  [processingKeys.product.brand.name]: {
    display: ProductBrand,
  },
  [processingKeys.product.category]: {
    display: LocationHorizontal(
      processingKeys.product.category,
      processingKeys.product.subcategory
    ),
  },
  [processingKeys.product.itemCount]: {
    display: RowSimpleText(processingKeys.product.itemCount, 'bold'),
  },
  [processingKeys.product.action]: {
    display: disabled ? () => <></> : EditProduct(editProduct),
  },
});

export const AddProductStep = ({
  value,
  stepNumber,
  stepLabel,
  selected,
  isFirst,
  isLast,
  onClick,
  beforeCurrentStep,
}) => {
  return (
    <Flex
      relative
      gap="_2XS"
      onClick={onClick}
      grow={!isLast}
      h0={!isLast}
      className="add-product-step"
    >
      <AddProductStepConnection
        stepNumber={stepNumber}
        selected={selected}
        isFirst={isFirst}
        isLast={isLast}
        beforeCurrentStep={beforeCurrentStep}
      />
      <Flex column gap="_XS" w>
        <StepLabel text={stepLabel} selected={selected} />
        <Flex w>
          <StepValueLabel text={value} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const AddProductStepConnection = ({
  stepNumber,
  selected,
  isLast,
  beforeCurrentStep,
}) => {
  return (
    <Flex justify="center" className="add-product-step-connection">
      <ProductStepNumber
        stepNumber={stepNumber}
        selected={selected}
        beforeCurrentStep={beforeCurrentStep}
      />
      {!isLast && (
        <Line className="line" beforeCurrentStep={beforeCurrentStep} />
      )}
    </Flex>
  );
};

export const SummaryDetail = ({ detail }) => {
  const { title, text, subText } = detail;
  return (
    <Flex column gap="_2XS">
      <SummaryDetailTitle text={title} />
      <Flex column>
        <SummaryDetailText text={text || Constants.fallback} />
        <SummaryDetailSubtext text={subText} />
      </Flex>
    </Flex>
  );
};

export const BrandGridItem = ({ brand, selected, selectBrand }) => {
  return (
    <BrandGridItemCSS onClick={selectBrand(brand)} selected={selected}>
      <BrandImage
        src={brand[processingKeys.brand.image]}
        onError={setImagePlaceholder('_XL')}
      />
      <BrandName name={brand[processingKeys.brand.name]} selected={selected} />
      {!!brand[processingKeys.brand.isContracted] && (
        <BrandIsContractedIconCSS>{isContractedIcon}</BrandIsContractedIconCSS>
      )}
    </BrandGridItemCSS>
  );
};
