import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore, [DC.ONLY_ACTIVE]: active_senders }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('admin_senders/filters', {
      formatter,
      ignore,
      params: { active_senders },
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

const formatter = data => {
  const { brands, countries, adding_clients } = data;

  const newOps = {};

  newOps[DC.BRANDS] = prepareDataForDropdown(brands, 'brName', 'brID');

  newOps[DC.COUNTRY] = prepareDataForDropdown(
    countries,
    'seCountry',
    'seCountry'
  );

  newOps[DC.ADDED_BY] = prepareDataForDropdown(
    adding_clients,
    'usDisplayName',
    'bguaID',
    { sectionTitle: 'Others' }
  );

  return newOps;
};
