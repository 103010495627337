import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Layout } from '../../style';

export const FlexContainer = styled.div(props => ({
  display: 'flex',
  margin: props.margin,
  padding: props.padding,
  flexDirection: props.column && 'column',
  position: props.relative && 'relative',
  justifyContent: props.center ? alignment['center'] : alignment[props.justify],
  alignItems: props.center ? alignment['center'] : alignment[props.align],
  gap: Layout.Spacing[props.gap],
  flexWrap: props.wrap__ && 'wrap',
  width: props.w
    ? '100%'
    : props.w0
    ? '0'
    : props.width
    ? Layout.Spacing[props.width]
    : '',
  height: props.h
    ? '100%'
    : props.h0
    ? '0'
    : props.height
    ? Layout.Spacing[props.height]
    : '',
  maxWidth: props.maxW ? '100%' : '',
  flexGrow: props.grow && 1,
  overflow: props.overflow ?? '',
  ...(scroll[props.scroll] ?? {}),
  [props.hideScrollbar ? 'width' : 'ignore']: '100%',
  cursor: props.isClickable ? 'pointer' : 'auto',
}));

export const alignment = {
  end: 'flex-end',
  start: 'flex-start',
  between: 'space-between',
  center: 'center',
};

const scroll = {
  true: Styles.scroll,
};
