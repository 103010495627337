import { forwardRef } from 'react';
import { Constants } from '../../../constants';
import { parseStringModifiers } from '../../../utils/utils';
import { Icon, Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import Spacer from '../../atoms/Spacer/Spacer';
import { Colors, FontKeys, Size } from '../../style';
import Tooltip from '../Tooltip/Tooltip';
import { numAsPercent } from './utils';

export const VerticalDelta = ({
  delta,
  period,
  tooltipSide,
  positiveIsGood,
  deltaExtraText,
  deltaLabelExtraElement,
  size,
  className,
}) => {
  const value = numAsPercent(delta);
  const noChange = delta == 0 || delta == null || value === Constants.fallback;

  return (
    <Flex w>
      <Flex
        column
        gap="_3XS"
        w0
        grow
        className={'delta-content vertical ' + className}
      >
        <Tooltip
          content={parseStringModifiers(period)}
          asChild={noChange}
          side={tooltipSide}
        >
          <DeltaLabel
            delta={delta}
            positiveIsGood={positiveIsGood}
            deltaExtraText={deltaExtraText}
            deltaLabelExtraElement={deltaLabelExtraElement}
            size={size}
          />
        </Tooltip>
        {/* <DeltaPeriod text={parseStringModifiers(period)} /> */}
      </Flex>
    </Flex>
  );
};

export const HorizontalDelta = ({
  delta,
  period,
  positiveIsGood,
  deltaExtraText,
  deltaLabelExtraElement,
  className,
}) => {
  const value = numAsPercent(delta);
  const noChange = delta == 0 || delta == null || value === Constants.fallback;

  return (
    <Flex
      justify="between"
      align="center"
      gap="_2XS"
      className={'delta-content horizontal ' + className}
    >
      <DeltaLabel
        delta={delta}
        size={'_M'}
        positiveIsGood={positiveIsGood}
        deltaExtraText={deltaExtraText}
        deltaLabelExtraElement={deltaLabelExtraElement}
      />
      {noChange ? '' : <DeltaPeriod text={parseStringModifiers(period)} />}
    </Flex>
  );
};

export const DeltaLabel = forwardRef(
  (
    {
      delta,
      size,
      positiveIsGood,
      deltaExtraText,
      deltaLabelExtraElement,
      ...props
    },
    ref
  ) => {
    positiveIsGood = positiveIsGood ?? true;

    const value = numAsPercent(delta);
    const noChange =
      delta == 0 || delta == null || value === Constants.fallback;
    const text = !noChange ? `${value} ${deltaExtraText}` : '';

    const isPositive = positiveIsGood ? delta > 0 : delta < 0;

    const iconName = noChange
      ? ''
      : isPositive
      ? Icons.TrendingUp
      : Icons.TrendingDown;
    const color = noChange
      ? Colors.Neutral._500
      : isPositive
      ? Colors.Success._700
      : Colors.Error._700;

    return (
      <Flex gap="_2XS" align={'center'} ref={ref} {...props}>
        <Icon name={iconName} color={color} size={Size.Icon[size]} />
        <DeltaPercentage text={text} color={color} size={size} />
        {!noChange && deltaLabelExtraElement}
      </Flex>
    );
  }
);

const sizeMap = {
  _L: {
    variant: FontKeys.Variant.Heading,
    size: FontKeys.Size.H6,
    weight: FontKeys.Weight.Semibold,
  },
  _M: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._M,
    weight: FontKeys.Weight.Semibold,
  },
};

const spacerSizeMap = {
  _L: '_M',
  _M: '_S',
};

const DeltaPercentage = ({ text, color, size = '_L' }) => {
  return text ? (
    <Text text={text} {...sizeMap[size]} color={color} ellipsis />
  ) : (
    <Spacer height={spacerSizeMap[size]} />
  );
};

export const DeltaPeriod = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral._500}
      ellipsis
    />
  );
};
