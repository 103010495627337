import { useField } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Icon, Text } from '../../../components/atoms';
import { Utils } from '../../../constants';
import { FontKeys, Size } from '../../style';
import Select from '../Select/Select';
import { useInlineStyle } from './Input.hook';
import {
  Container,
  Error,
  InputContainer,
  InputLabel,
  Recipe,
} from './Input.style';

export const Input = ({
  name,
  label,
  type = 'text',
  placeholder,
  variant = 'default',
  size = '_S',
  leftIcon,
  rightIcon,
  maxLength = 999,
  onClick,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  disabled,
  options,
  selectOption,
  hasError,
  setTouched,
  autoFocus,
  preserveErrorSpace: preserveErrorSpace_,
  readOnly,
  ...props
}) => {
  if (readOnly) disabled = true;
  const [field, meta] = useField({ name });
  const [showError, setShowError] = useState(false);

  const inputRef = useRef(null);

  const coreStyle = Recipe(type === 'textarea' ? type : variant);
  const [style, setStyle] = useInlineStyle(coreStyle, {
    size,
    disabled,
    error: showError,
    readOnly,
  });

  const setHover =
    type === 'select' ? Utils.emptyFunction : to => () => setStyle('hover', to);

  const setFocus = to => e => {
    if (disabled) return;
    setStyle('focus', to);
    if (to)
      setTimeout(() => inputRef?.current?.focus({ preventScroll: true }), 0);
  };

  useEffect(() => {
    if (autoFocus) setTimeout(setFocus(true), 0);
  }, [autoFocus]);

  useEffect(() => {
    const to = meta?.value !== meta?.initialValue;
    setStyle('active', to);
  }, [meta?.value, meta?.initialValue]);

  const isValidationError = (meta, type) => {
    const error = type === 'select' ? meta.error?.value : meta.error;
    return !!error;
  };

  useEffect(() => {
    const showError = meta.touched && isValidationError(meta, type);
    setShowError(showError);
  }, [meta?.touched, meta.error]);

  useEffect(() => {
    const removeFocus = () => setFocus(false)();

    window.addEventListener('click', removeFocus);
    return () => window.removeEventListener('click', removeFocus);
  }, []);

  const handleClick = e => {
    if (typeof onClick === 'function') onClick(e);
    setTimeout(setFocus(true), 0);
  };

  const handleChange = e => {
    if (typeof onChange === 'function') onChange(e);
    field.onChange(e);
  };

  const handleBlur = e => {
    if (typeof onBlur === 'function') onBlur(e, field);
    field.onBlur(e);
    setFocus(false)(e);
  };

  const handleKeyPress = e => {
    if (onKeyPress) onKeyPress(e);
  };

  const handleFocus = e => {
    if (typeof onFocus === 'function') onFocus(e);
    setFocus(true)(e);
  };

  const propsBag = {
    ...field,
    ...props,
    style: style.inputElement,
    type,
    placeholder,
    maxLength,
    ref: inputRef,
    disabled,
    readOnly,
    variant,
    field,
    selectOption,
    options,
    onClick: handleClick,
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onKeyPress: handleKeyPress,
    size,
  };

  const { selectOption: _s, options: _o, onClick: _c, ...inputBag } = propsBag;
  const { ref, style: _st, ...selectBag } = propsBag;

  // TODO: clean this up. feels dangerous
  const preserveErrorSpace =
    type !== 'textarea' && preserveErrorSpace_ !== false;

  return (
    <Container
      {...props}
      className={name + ` ${type}-container ${props.className ?? ''}`}
    >
      {label ? <InputLabel text={label} /> : null}
      <InputContainer
        style={style}
        onPointerOver={setHover(true)}
        onPointerLeave={setHover(false)}
        onClick={handleClick}
        className="input"
      >
        {leftIcon && <Icon name={leftIcon} {...style.leftIcon} />}
        <div style={style.input}>
          {type === 'textarea' && <textarea {...inputBag} />}
          {(type === 'text' || type === 'password') && <input {...inputBag} />}
        </div>
        {type === 'select' && <Select {...selectBag} setTouched={setTouched} />}
        {/* delegating the Icon creation responsibility to whoever instantiates Input
        allows it to control behavior (hover, onClick, etc.) */}
        {rightIcon && <div>{rightIcon}</div>}
      </InputContainer>
      {hasError && (
        <Error
          style={style.errorStyle}
          showError={showError}
          preserveSpace={preserveErrorSpace}
        >
          <Icon name="AlertTriangle" size={Size.Icon._S} />
          <Text
            variant="Paragraph"
            size="_S"
            weight={FontKeys.Weight.Semibold}
            color={style.errorStyle.color}
            text={type === 'select' ? meta.error?.value : meta.error}
          />
        </Error>
      )}
    </Container>
  );
};
