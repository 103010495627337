import { Text } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../../components/molecules/Button/Button';
import { Colors, FontKeys } from '../../../../../../components/style';

export const CoverTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
      alignment="center"
    />
  );
};

export const PromptTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
    />
  );
};

export const CoverSubtitle = ({ text }) => <StandardText text={text} />;

export const PromptText = ({ text }) => <StandardText text={text} />;

const StandardText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      alignment="center"
    />
  );
};

export const ToMainView = ({ backToAdd }) => (
  <Button
    leftIcon={Icons.ChevronLeft}
    onClick={backToAdd}
    variant="Tertiary"
    text="Back"
    size="_M"
  />
);

export const Cancel = ({ onCancel }) => (
  <Button onClick={onCancel} variant="Secondary" text="Cancel" size="_M" />
);

export const PrimaryAction = ({ onClick, text, disabled }) => (
  <Button
    onClick={onClick}
    variant="Primary"
    text={text}
    size="_M"
    disabled={disabled}
    type="submit"
  />
);

export const FieldLabel = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const FieldValue = ({ text, color }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={color}
      ellipsis
    />
  );
};
