import styled from '@emotion/styled';
import { mergeDeep } from '../lib/js';

export const includeConstants = (recipeFn, constantStyle) => {
  return (state, props) => {
    return mergeDeep({ ...constantStyle }, { ...recipeFn(state, props) });
  };
};

// Clear Grid Style
// We use a special syntax to make grid-template-column reading easier. Looks like so:
//   // 1st column name //
//   45px
//   // 2nd column name //
//   minmax(10px, 1fr)
// The following code and export cleanGridStyle() sanitizes the easy-to-read syntax into
// a value acceptable for inline styles

const clearWhiteSpace = text => text.replaceAll('\n', '');

const commentSymbol = '//';
const clearComments = text => {
  let dirtyText = text;
  let clearedText = '';
  let commentsLeft = true;
  while (commentsLeft) {
    const commentStart = dirtyText.indexOf(commentSymbol);
    if (commentStart < 0) {
      clearedText += dirtyText;
      commentsLeft = false;
      break;
    }

    clearedText += dirtyText.slice(0, commentStart);
    dirtyText = dirtyText.slice(commentStart + commentSymbol.length);

    const commentEnd = dirtyText.indexOf(commentSymbol);

    dirtyText = dirtyText.slice(commentEnd + commentSymbol.length);
    clearedText = clearedText.trim();
    clearedText += ' ';
  }

  return clearedText.trim();
};

const detergents = [clearWhiteSpace, clearComments];

export const cleanGridStyle = style => {
  return detergents.reduce((text, clean) => clean(text), style);
};
// Clear Grid Style - End
//                     //

export const hexOpacity = decimal =>
  Math.round((decimal / 100) * 256).toString(16);

export const disableComponent = disabled => {
  if (!disabled) return;
  return `
    opacity: 0.2;
    pointer-events: none;
  `;
};

export const DisableContainer = styled.div`
  ${props => disableComponent(props.disabled)};
`;

export const DisableChartContainer = styled.div`
  position: relative;
  overflow: ${({ disabled }) => (disabled ? 'hidden' : '')};
  border-radius: 8px;
  .card {
    filter: ${({ disabled }) => (disabled ? 'blur(1px)' : '')};
    height: 100%;
  }
`;

export const offsetGrowingBorderPadding = ({ padding, selected }) => {
  return selected ? `calc(${padding} - 1px)` : padding;
};

export const extendStyledComponents =
  (...components) =>
  props => {
    let css = '';
    components.forEach(component => {
      component.__emotion_styles.forEach(style => {
        if (typeof style === 'string') css += style;
        if (typeof style === 'function') css += style(props);
      });
    });
    return css;
  };
