import { API } from '../../lib/api';
import { Pages } from '../../routes/variables';
import { Fields } from '../controllers/variables';

const { setPassword } = Pages;
const { passwordField } = Fields[setPassword];

export const updatePassword = async ({
  [passwordField]: passwordValue,
  token,
}) => {
  const body = {
    [passwordField]: passwordValue,
  };

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { data, status, error } = await API.POST('set_password', body, config);

  if (error || status !== 200) return false;
  return true;
};
