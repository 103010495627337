import { forwardRef, useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { Icon, Text } from '../../atoms';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import { Flex } from '../../atoms/Flex/Flex';
import { FontKeys, Layout } from '../../style';
import Tooltip from '../Tooltip/Tooltip';
import { useInlineStyle } from './SidebarPageIcon.hook';
import { Recipe, WhiteBG } from './SidebarPageIcon.style';

const SidebarPageIcon = (
  {
    name,
    size,
    label,
    selected,
    expanded,
    transition,
    onClick,
    uri,
    moveIndicator = Utils.emptyFunction,
    comingSoon,
    customElement,
    showCustomElement,
    disabled,
    tooltipContent,
    ...rest
  },
  ref
) => {
  const [hovered, setHovered] = useState(false);
  const coreStyle = Recipe('default');
  const [style, setStyle] = useInlineStyle(coreStyle, {
    expanded,
    transition,
    active: selected,
    hovered,
    comingSoon,
  });

  const iconRef = useRef(null);

  useEffect(() => {
    if (selected) moveIndicator(iconRef.current);
  }, [selected, iconRef.current?.offsetTop]);

  const setHover = to => e => setHovered(!disabled && to);

  return (
    <Flex relative w ref__={ref} {...rest}>
      <div
        onPointerOver={setHover(true)}
        onPointerLeave={setHover(false)}
        style={style}
        onClick={comingSoon ? Utils.emptyFunction : onClick}
        id={(uri ?? '').replace('/', '')}
        ref={iconRef}
      >
        <WhiteBG expanded={expanded} selected={selected} />
        <Tooltip side="right" content={expanded ? tooltipContent : null}>
          <div style={{ gap: Layout.Spacing._XS, display: 'flex' }}>
            <Tooltip side="right" content={!expanded ? tooltipContent : null}>
              <div>
                <Icon name={name} color={style.color} size={size} />
              </div>
            </Tooltip>
            {/* </Tooltip> */}
            <Flex align="center" gap="_XS" isClickable>
              {/* <Tooltip side="right" content={tooltipContent}> */}
              <Text
                text={label}
                variant={FontKeys.Variant.Paragraph}
                size={'_S'}
                weight={FontKeys.Weight.Semibold}
                color={style.color}
                whiteSpace="nowrap"
              />

              {comingSoon && <ComingSoon short />}
              {showCustomElement &&
                typeof customElement === 'function' &&
                customElement({
                  name,
                  size,
                  label,
                  selected,
                  expanded,
                  transition,
                  onClick,
                  uri,
                  comingSoon,
                  customElement,
                  hovered,
                  ...rest,
                })}
            </Flex>
          </div>
        </Tooltip>
      </div>
    </Flex>
  );
};

export default forwardRef(SidebarPageIcon);
