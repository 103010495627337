import styled from '@emotion/styled';
import { useEffect } from 'react';
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import { logout as doLogout } from '../auth/api/logout';
import ConnectionErrorPage from '../components/templates/ConnectionErrorPage/ConnectionErrorPage';
import { PropKeys, ResponsiveSizes } from '../constants';
import { initialToken, useAuth, useReauth } from '../providers/AuthProvider';
import { useFooter } from '../providers/FooterProvider/FooterProvider';
import { useHeader } from '../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../providers/ModalProvider';
import { usePermissions } from '../providers/PermissionProvider/PermissionsProvider';
import { usePlatform } from '../providers/PlatformProvider';
import { useStore } from '../providers/StoreProvider';
import { tokenIsValid } from '../utils/routesUtils';
import { extractPropFromURL } from '../utils/utils';
import { publicRoutes } from './public';
import { nonRedirects } from './variables';

export const AppRoutes = () => {
  const [{ token, logout, networkError }] = useAuth();
  const { doReauth, switchBrands } = useReauth();
  const { clearPermissions, permissions } = usePermissions();
  const { modalComponents } = useModal();
  const { store } = useStore();
  const { setShowHeader } = useHeader();
  const { setShowFooter } = useFooter();

  const location = useLocation();
  const navigate = useNavigate();

  const [{ pathnameBase: tokenNotNeeded }] = matchRoutes(
    nonRedirects,
    location
  ) ?? [{}];

  const needsToken = !tokenNotNeeded;

  const { platformRoutes, isRoutesSet } = usePlatform();
  const { dismissAll } = useModal();

  useEffect(() => {
    if (!tokenIsValid(token) && needsToken) doReauth();
  }, [needsToken]);

  useEffect(() => {
    if (logout) {
      clearPermissions();
      doLogout();
      dismissAll();
      const to = '/login';
      navigate(to);
    }
  }, [logout]);

  useEffect(() => {
    if (!tokenIsValid(token) && needsToken) return;
    if (!store[PropKeys.clients]) return;
    switchBrandFromURL();
  }, [store[PropKeys.brandId], store[PropKeys.clients]]);

  useEffect(() => {
    if (!store[PropKeys.brandId]) switchIfOnlyOneBrand(store[PropKeys.clients]);
  }, [store[PropKeys.clients]]);

  useEffect(() => {
    if (!networkError) return;
    setShowHeader(false);
    setShowFooter(false);
  }, [networkError]);

  useEffect(() => {
    if (location.pathname.endsWith('*')) {
      const to = location.pathname.slice(0, -1);
      navigate(to, { replace: true });
    }
  }, [location.pathname]);

  const switchBrandFromURL = async () => {
    const { search } = location;
    let { state } = location;

    if (!state) state = { search };
    else {
      state.search = search;
    }
    const navedFromLink = location.key === 'default' || state.navedFromLink;
    state.navedFromLink = navedFromLink;

    const currentBrandId = store[PropKeys.brandId];
    const urlBrandId = extractPropFromURL('brand_id', location.search);
    const newBrandId = urlBrandId === 'all' ? null : urlBrandId;

    const to = location.pathname;

    const clearUrl = !!location.search;
    const missingId = !urlBrandId;
    const sameId =
      newBrandId == currentBrandId || (!newBrandId && !currentBrandId);

    if (missingId || sameId) {
      if (clearUrl) navigate(to, { state, replace: true });
      return;
    }

    if (store[PropKeys.clients]?.length === 1 && newBrandId === null) return;

    await switchBrands(newBrandId);
    if (clearUrl) navigate(to, { state, replace: true });
    window.location.href = to + location.search;
  };

  const switchIfOnlyOneBrand = async clients => {
    if (clients != null && clients.length !== 1) return;
    const { [PropKeys.brandId]: brandId } = clients?.[0] ?? {};
    if (store[PropKeys.brandId] === brandId) return;

    await switchBrands(brandId);

    const to = location.pathname + location.search;
    window.location.href = to;
  };

  // if they had any previous token (except initialToken), then allow protected routes
  // if they refresh, token === initialToken
  // if they navigate, token == some token. if still valid, it will work. if not valid, any request will doReauth()
  const hasValidToken = token && token !== initialToken && !logout;

  const showProtected = hasValidToken && isRoutesSet;

  const routes = showProtected ? platformRoutes : publicRoutes;

  const element = useRoutes(routes);

  return (
    <Style className="style" modalsActive={!!modalComponents.length}>
      {networkError ? <ConnectionErrorPage /> : element}
    </Style>
  );
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const Style = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: ${ResponsiveSizes.minWindowHeight}px;
  ${isSafari &&
  `font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`}
`;
