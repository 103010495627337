import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { contractKeys } from '../controllers/variables';

export const useGetContracts = () => {
  const [{ data = [], isLoading = true, isError, error }] = useFetchData(
    'profile/contracts',
    { formatter: data => maskData(data, mask) }
  );

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data.map(entry => ({ ...entry, id: entry[contractKeys.url] }));
};

const mask = {
  bctContract: contractKeys.url,
  bctName: contractKeys.name,
  createdAt: contractKeys.date,
  bctId: contractKeys.id,
};
