import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import CopyableText from '../../../../../../components/molecules/CopyableText/CopyableTitle';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { CardLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { NoResults } from '../../../../../../components/molecules/NoResults/NoResults';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import { DisableContainer } from '../../../../features_public/products/components/style';
import {
  components,
  componentTitle,
  infoDescription,
} from '../../../../features_public/products/controllers/variables';
import { noResults } from '../../variables';
import { Blur, CaseNavigate, ParcelAlias } from '../atoms';
import {
  AuthorityEntry,
  ErrorDisplay,
  ParcelDetailsSection,
  ProductTicketsContainer,
  tableComponents,
} from '../molecules/molecules';
import {
  AuthorityInfoCSS,
  ProductsTableContainer,
  ProductTicketsScroll,
} from '../style';

const disabledText1Row =
  'To visualize this data, you need to have access to this service';

export const ProductsTable = ({
  headers,
  data,
  rowAction,
  grid,
  showGallery,
}) => {
  return (
    <ProductsTableContainer
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      components={tableComponents({ showGallery })}
    />
  );
};

export const ParcelDetails = ({ sections }) => {
  return (
    <>
      {sections != null &&
        sections.length &&
        sections.map((section, sIndex) => {
          return (
            <ParcelDetailsSection
              section={section}
              index={sIndex}
              key={sIndex}
            />
          );
        })}
    </>
  );
};

export const AuthorityInfo = ({ authorityDetails }) => {
  return (
    <Card
      title={componentTitle[components.authorityInfo]}
      icon={
        <InfoIcon description={infoDescription[components.authorityInfo]} />
      }
      className="authority-info"
    >
      <AuthorityInfoCSS>
        {authorityDetails && authorityDetails.length ? (
          authorityDetails.map(entry => {
            const { title, info } = entry;
            return <AuthorityEntry title={title} info={info} />;
          })
        ) : (
          <NoResults {...noResults[components.authorityInfo]} />
        )}
      </AuthorityInfoCSS>
    </Card>
  );
};

const columns = 12;
const blurColumns = '7 / 13';

export const ParcelDetailsSummary = ({
  sections,
  shouldBlurActors,
  caseAlias,
  goToCase,
  isLoading,
  isError,
  disabled,
}) => {
  return (
    <DisableContainer className="parcel-details-summary" disabled={disabled}>
      <Card
        title={
          <Flex gap="_XS">
            <ParcelAlias
              text={
                <CopyableText
                  text={`Parcel ${caseAlias ?? 'details'}`}
                  copyText={caseAlias}
                />
              }
            />
          </Flex>
        }
        columns={columns}
        icon={
          <Flex align={'center'} gap="_2XS">
            {caseAlias && <CaseNavigate onClick={goToCase(caseAlias)} />}
            <InfoIcon description={infoDescription[components.parcelDetails]} />
          </Flex>
        }
      >
        {isError ? (
          <Content>
            <ErrorDisplay />
          </Content>
        ) : isLoading ? (
          <Content>
            <CardLoader />
          </Content>
        ) : (
          <>
            <ParcelDetails sections={sections} />
            {shouldBlurActors && <Blur blurColumns={blurColumns} />}
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </DisableContainer>
  );
};

export const ProductTickets = ({
  tickets,
  handleTicketClick: onTicketClick,
  addTicket,
  rightIcon,
  supportButtonTooltipContent,
}) => {
  return (
    <Card
      className="product-tickets"
      title={componentTitle[components.productTickets]}
    >
      <ProductTicketsScroll>
        <ProductTicketsContainer
          tickets={tickets}
          addTicket={addTicket}
          onTicketClick={onTicketClick}
          rightIcon={rightIcon}
          supportButtonTooltipContent={supportButtonTooltipContent}
        />
      </ProductTicketsScroll>
    </Card>
  );
};
