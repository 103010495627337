import usePostData from '../../../../../hooks/postHook';

export const useAddBrand = ({ mode }) => {
  const [{ data, body, isLoading, isError, error }, doAddBrand] = usePostData(
    ``,
    {
      type: mode === 'add' ? 'post' : 'put',
    }
  );

  return { data, body, isLoading, isError, error, doAddBrand };
};
