import usePostData from '../../../../hooks/postHook';

export const useGetImages = ({
  id = '',
  url,
  customImagesProps,
  idsKey,
  formatter = x => x,
}) => {
  const [{ data, isLoading, isError, error }, getImages] = usePostData(url, {
    formatter,
  });

  const doGetImages = () => {
    const body = {
      [idsKey]: [id],
      ...customImagesProps,
    };
    getImages(body);
  };

  return {
    data:
      !isError && data ? data : { images: undefined, thumbnails: undefined },
    isLoading,
    isError,
    error,
    getImages: doGetImages,
  };
};
