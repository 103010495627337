import { useState } from 'react';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { useDownloadCustomReports } from '../api/downloadCustomReport';
import { useGetCustomReports } from '../api/getCustomReports';
import { CustomReportsLayout } from '../components/CustomReportsLayout';
import NewReport from './NewReport';
import {
  customReportsGrid,
  customReportsHeaders,
  noResultsState,
} from './variables';

const { SELF, CREATE_REPORTS } = PermissionKeys.Brand.REPORTS;

const CustomReports = () => {
  const [data, setData] = useState([]);

  const { showModal } = useModal();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasCreatePermission = hasPermission(CREATE_REPORTS);

  const {
    data: reports,
    isLoading,
    isError,
    error,
    refresh,
  } = useGetCustomReports({});

  const {
    data: downloadData,
    isLoading: downloadIsLoading,
    isError: downloadIsError,
    downloadCustomReport,
  } = useDownloadCustomReports();

  useDeepCompareEffect(() => {
    setData(reports);
  }, [reports]);

  const handleAction = item => () => downloadCustomReport(item);

  const showNewReport = () => {
    showModal(NewReport, { onSuccess: refresh });
  };

  const generateReportButton = {
    onClick: showNewReport,
    text: 'Create new report',
    variant: 'Primary',
    size: '_S',
  };
  return (
    <CustomReportsLayout
      headers={customReportsHeaders}
      data={data}
      grid={customReportsGrid}
      handleAction={handleAction}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={{
        noData: data.length === 0,
        noDataDisplay: noResultsState.custom.noData(),
        noSearchResultsDisplay: noResultsState.custom.noSearchResults,
        // no filter setting, so no need to handle noSearchResults
      }}
      generateReportButton={generateReportButton}
      hasCreatePermission={hasCreatePermission}
    />
  );
};
export default CustomReports;
