import { uniqueId } from 'lodash';
import {} from '../../../utils/stringUtils';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { ClickIcon, Key, KeyColor, KeyText, WholeKey } from './Legend.style';

export default function Legend({
  keys = [],
  column,
  gap = '_S',
  justify = 'start',
  onClickIcon = Icons.ChevronRight,
}) {
  if (keys == null) return;
  return (
    <Flex gap={gap} column={column}>
      {keys.map(item => {
        const {
          key,
          value,
          valueLabel,
          color,
          onClick,
          column: itemColumn,
        } = item;
        return (
          <WholeKey
            column={itemColumn}
            onClick={onClick}
            isClickable={!!onClick}
            justify={justify}
            key={uniqueId()}
          >
            <Key isClickable={!!onClick} column={itemColumn} className="key">
              <KeyColor className="key-color" color={color} />
              <KeyText text={key} />
              {onClick && value == null && <ClickIcon name={onClickIcon} />}
            </Key>
            {value != null && (
              <Key
                isClickable={!!onClick}
                column={itemColumn}
                className="key-2"
              >
                {/* placeholder to align key and value. simpler than grid IMO */}
                {itemColumn && (
                  <KeyColor className="key-color" color={'transparent'} />
                )}
                <KeyText
                  className="legend-value"
                  text={valueLabel ?? value}
                  bold
                />
              </Key>
            )}
            {onClick && value != null && <ClickIcon name={onClickIcon} />}
          </WholeKey>
        );
      })}
    </Flex>
  );
}
