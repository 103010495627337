import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { passwordTests } from '../../components/organisms/PasswordRules/variables';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { NavURIs, Pages } from '../../routes/variables';
import { checkTokenIsValid } from '../api/checkTokenIsValid';
import { updatePassword } from '../api/updatePassword';
import SetPasswordLayout from '../components/SetPasswordLayout';
import { Fields, FormValues, Texts } from './variables';

const { login, setPassword } = Pages;
const { [login]: loginRoute } = NavURIs;
const {
  passwordPlaceholder,
  confirmPasswordPlaceholder,
  minorButtonText,
  minorLeftIcon,
} = Texts[setPassword];

const { initialValues, validationSchema } = FormValues[setPassword];

const { passwordField, confirmPasswordField } = Fields[setPassword];

const SetPassword = ({ page }) => {
  const [hasVerifiedTokenValidity, setHasVerifiedTokenValidity] =
    useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const [passwordRules, setPasswordRules] = useState(passwordTests);

  const [tokenIsValid, setTokenIsValid] = useState(!!token);

  const handleReset = async ({ [passwordField]: passwordValue }) => {
    const success = await updatePassword({
      [passwordField]: passwordValue,
      token,
    });
    if (!success) {
      showSnackbarError();
      return;
    }
    showSnackbarSuccess('Password successfully changed.');
    handleBackToLogin();
  };

  const handleBackToLogin = () => {
    const to = loginRoute;
    navigate(to);
  };

  const handlePasswordChange = e => {
    const { value: password } = e?.target;

    const passwordRules = passwordTests.map(test => {
      return { ...test, valid: test.validator(password) };
    });

    setPasswordRules(passwordRules);
  };

  const validateToken = async token => {
    const tokenIsValid = await checkTokenIsValid({ token });
    setTokenIsValid(tokenIsValid);
    setHasVerifiedTokenValidity(true);
  };

  useEffect(() => {
    validateToken(token);
  }, []);

  useEffect(() => {
    if (!tokenIsValid) {
      showSnackbarError('Token has expired');
      handleBackToLogin();
    }
  }, [tokenIsValid]);

  const { title, subtitle, mainButtonText } = Texts[page];

  return (
    <SetPasswordLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleReset}
      title={title}
      subtitle={subtitle}
      minorButtonClick={handleBackToLogin}
      minorButtonText={minorButtonText}
      minorLeftIcon={minorLeftIcon}
      mainButtonText={mainButtonText}
      passwordPlaceholder={passwordPlaceholder}
      passwordName={passwordField}
      confirmPasswordPlaceholder={confirmPasswordPlaceholder}
      confirmPasswordName={confirmPasswordField}
      passwordRules={passwordRules}
      customIsValid={passwordRules.every(rule => rule.valid)}
      onChange={handlePasswordChange}
      isLoading={!hasVerifiedTokenValidity}
    />
  );
};
export default SetPassword;
