import { uniqueId } from 'lodash';
import React from 'react';
import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { NoResults as NoResultsIcons } from '../../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import {
  Badge,
  BadgeType,
} from '../../../../../../components/molecules/Badge/Badge';
import Button from '../../../../../../components/molecules/Button/Button';
import { Card } from '../../../../../../components/molecules/Card/Card';
import { Disabled } from '../../../../../../components/molecules/Disabled/Disabled';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { NoResults } from '../../../../../../components/molecules/NoResults/NoResults';
import BarChart from '../../../../../../components/organisms/BarChart/BarChart';
import LineChart from '../../../../../../components/organisms/LineChart/LineChart';
import PieChart from '../../../../../../components/organisms/PieChart/PieChart';
import { Layout, StatusColors } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import {} from '../../../../../../utils/stringUtils';
import {
  colorCardPadding,
  parseStringModifiers,
} from '../../../../../../utils/utils';
import { components, infoDescription } from '../../variables';
import { ItemTitle, LoadingAnimation } from '../atoms/atoms';
import {
  ActivityLog,
  AuthenticationStat,
  ErrorDisplay,
  OverviewStat,
  ParcelStatus,
  ProgressBars,
  TimelineLegendAndInfo,
} from '../molecules/molecules';
import {
  ActivityCSS,
  AuthenticationCSS,
  CategoriesCSS,
  OriginCSS,
  OverviewCSS,
  ParcelStatusesCSS,
  PopularsCSS,
  TimelineCSS,
} from '../style';

const disabledText2Rows = parseStringModifiers(
  'To visualize this data, you need to\nhave access to this service'
);

const disabledText1Row =
  'To visualize this data, you need to have access to this service';

export const Overview = ({
  overviewStats = [],
  isLoading,
  isError,
  onClick,
}) => {
  return (
    <OverviewCSS>
      <Card
        title="Overview"
        icon={<InfoIcon description={infoDescription[components.overview]} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex justify="between">
            {overviewStats.map((status, i) => (
              <OverviewStat
                stats={status}
                onClick={onClick}
                key={uniqueId(`${i}-${Date.now()}`)}
              />
            ))}
            <div />
          </Flex>
        )}
      </Card>
    </OverviewCSS>
  );
};

export const Authentication = ({
  authenticationStats,
  evalNavigateButton,
  isLoading,
  isError,
  onClick,
  hasProductPermission,
  disabled,
}) => {
  return (
    <AuthenticationCSS disabled={disabled}>
      <Card
        title={
          <Badge
            type={BadgeType.status}
            text={Constants.Status.Authentication}
            size={'_S'}
          />
        }
        color={StatusColors.Authentication}
        padding={colorCardPadding()}
        icon={
          hasProductPermission ? (
            <Button {...evalNavigateButton} />
          ) : (
            <Spacer height="_S" />
          )
        }
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <Flex margin={`-${Layout.Spacing._2XS} 0 0 0`}>
            {authenticationStats.map((status, index) => (
              <React.Fragment key={status.title}>
                <AuthenticationStat stats={status} onClick={onClick} />
                <Flex grow />
              </React.Fragment>
            ))}
          </Flex>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </AuthenticationCSS>
  );
};

export const Populars = ({
  populars,
  popularsBars,
  title,
  isLoading,
  isError,
  inactiveBrand,
  disabled,
}) => {
  return (
    <PopularsCSS disabled={disabled}>
      <Card
        title={title}
        icon={<InfoIcon description={infoDescription[components.populars]} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <BarChart
            data={populars}
            bars={popularsBars}
            inactive={inactiveBrand}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </PopularsCSS>
  );
};

export const Timeline = ({
  timeline,
  timelineLines,
  timelineKeys,
  isLoading,
  isError,
  inactiveBrand,
  disabled,
}) => {
  return (
    <TimelineCSS disabled={disabled}>
      <Card
        title="Timeline"
        icon={<TimelineLegendAndInfo keys={timelineKeys} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <LineChart
            data={timeline}
            lines={timelineLines}
            inactive={inactiveBrand}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText1Row} />}
    </TimelineCSS>
  );
};

export const ParcelStatuses = ({
  parcelStatuses,
  isLoading,
  isError,
  onClick,
  disabled,
}) => {
  return (
    <ParcelStatusesCSS disabled={disabled}>
      {parcelStatuses.map(item => {
        const { status, count, color, countDetail, navigate } = item;
        return (
          <ParcelStatus
            key={status}
            status={status}
            count={count}
            color={color}
            countDetail={countDetail}
            navigate={navigate}
            onClick={onClick(item)}
            isLoading={isLoading}
            isError={isError}
            disabled={disabled}
          />
        );
      })}
    </ParcelStatusesCSS>
  );
};

export const Origin = ({
  origins,
  total,
  main,
  isLoading,
  isError,
  colors,
  inactiveBrand,
  onClick,
  disabled,
}) => {
  return (
    <OriginCSS inactiveBrand={inactiveBrand} disabled={disabled}>
      <Card
        title="Reference origin"
        icon={<InfoIcon description={infoDescription[components.origins]} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <ItemTitle text={'Top countries of references'} />
            <PieChart
              data={origins}
              total={total}
              main={main}
              colors={colors}
              onClick={onClick}
              customDimensions={{
                width: 116,
                height: 116,
                cx: 54,
                cy: 54,
                innerRadius: 46,
                outerRadius: 58,
              }}
            />
          </>
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </OriginCSS>
  );
};

export const Categories = ({
  categories,
  disabled,
  isLoading,
  isError,
  onClick,
}) => {
  return (
    <CategoriesCSS disabled={disabled}>
      <Card
        title="Product categories"
        icon={<InfoIcon description={infoDescription[components.categories]} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : (
          <ProgressBars bars={categories} onClick={onClick} />
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </CategoriesCSS>
  );
};

export const Activity = ({
  activity,
  disabled,
  isLoading,
  isError,
  onClick,
}) => {
  return (
    <ActivityCSS disabled={disabled}>
      <Card
        title="Activity"
        icon={<InfoIcon description={infoDescription[components.activity]} />}
      >
        {isError ? (
          <ErrorDisplay />
        ) : isLoading ? (
          <LoadingAnimation />
        ) : activity.length ? (
          <Flex column gap="_XS">
            {activity.map((log, index) => (
              <ActivityLog activity={log} key={index} onClick={onClick} />
            ))}
          </Flex>
        ) : (
          <NoResults
            icon={<Icon name={NoResultsIcons.Activity} />}
            description={'There has been no recent activity'}
          />
        )}
      </Card>
      {disabled && <Disabled text={disabledText2Rows} />}
    </ActivityCSS>
  );
};
