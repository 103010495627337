import { DISPATCH_CONSTANTS as DC } from '../../../constants';

export const tableStatusProps = ({
  searchParams,
  dispatch,
  dataCount,
  noResultsState,
  noDataAction,
  hasViewPermission,
}) => {
  const noDataDisplay = noResultsState.noData(noDataAction);
  if (!noDataAction) delete noDataDisplay.button;

  return {
    page: searchParams[DC.PAGE],
    itemsPerPage: searchParams[DC.ITEMS_PER_PAGE],
    setItemsPerPage: dispatch(DC.ITEMS_PER_PAGE),
    paginate: dispatch(DC.PAGE),
    firstLoad: !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET],
    noSearchResults: !dataCount && searchParams[DC.FILTERS_SET],
    noData: !dataCount && !searchParams[DC.FILTERS_SET],
    totalItems: dataCount,
    noDataDisplay,
    noSearchResultsDisplay: noResultsState.noSearchResults,
    hasViewPermission,
    searchParams,
  };
};
