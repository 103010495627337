import { useEffect, useRef, useState } from 'react';
import { useUI } from '../../../providers/UIProvider';
import {
  collapsedWidth,
  expandedWidth,
  togglePlacement,
  transition,
} from './Sidebar.style';
import { SidebarLayout } from './SidebarLayout';

export const Sidebar = Layout => {
  return function WithLayout(props) {
    const sidebarExpanded = JSON.parse(
      localStorage.getItem('sidebarExpanded') ?? 'true'
    );
    const [expanded, setExpanded] = useState(!!sidebarExpanded);

    const { updateSharedUI } = useUI();

    const sidebarRef = useRef(null);

    useEffect(() => {
      const sidebarWidth = expanded ? expandedWidth : collapsedWidth;
      updateSharedUI({ sidebarWidth });
    }, []);

    const toggleExpanded = () => {
      const sidebarWidth = !expanded ? expandedWidth : collapsedWidth;
      updateSharedUI({ sidebarWidth });
      setExpanded(x => {
        localStorage.setItem('sidebarExpanded', JSON.stringify(!x));
        return !x;
      });
    };

    return (
      <SidebarLayout
        sidebarRef={sidebarRef}
        toggleExpanded={toggleExpanded}
        expanded={expanded}
        transition={transition}
        togglePlacement={togglePlacement}
        Layout={Layout}
        props={props}
      />
    );
  };
};
