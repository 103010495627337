import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { invoiceKeys } from '../controllers/variables';
import { formatPlan } from '../formatters/formatBilling';

export const useGetBilling = () => {
  const [
    {
      data: {
        invoices = [],
        subscription_plan = {
          title: '',
          monthlyPrice: '',
          details: [],
        },
      } = {},
      isLoading = true,
      isError,
      error,
    },
  ] = useFetchData('profile/billing');

  return {
    invoices: maskData(invoices, invoiceMask),
    subscriptionPlan: formatPlan(subscription_plan),
    isLoading,
    isError,
    error,
  };
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data.map(entry => ({ ...entry, id: entry[invoiceKeys.link] }));
};

const invoiceMask = {
  biAlias: invoiceKeys.alias,
  biDate: invoiceKeys.date,
  biAmount: invoiceKeys.amount,
  biInvoice: invoiceKeys.link,
  biName: invoiceKeys.invoiceName,
};
