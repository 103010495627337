import { useEffect, useState } from 'react';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { useDeleteUser } from '../api/deleteUser';
import { useGetBrandServices } from '../api/getBrandServices';
import { useGetUsers } from '../api/getUsers';
import { UsersListLayout } from '../components/UsersListLayout';
import {
  noResultsState,
  userExpansionHeaders,
  usersExpansionGrid,
  usersGrid,
  usersHeaders,
  usersKeys,
} from './variables';

const { SELF, DELETE_BRAND_USERS, EDIT_BRAND_USERS } =
  PermissionKeys.Brand.BRAND_MANAGEMENT;

const UsersList = ({ showWriteUser }) => {
  const [data, setData] = useState([]);
  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const {
    data: users,
    isLoading,
    isError,
    error,
    refetchUsers,
  } = useGetUsers();

  const {
    data: { services: brandServices },
    isLoading: brandServicesIsLoading,
    isError: brandServicesIsError,
    error: brandServicesError,
  } = useGetBrandServices();

  const {
    isCompleted: deleteIsCompleted,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
    doDelete,
  } = useDeleteUser();

  useEffect(() => {
    if (deleteIsLoading || !deleteIsCompleted) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);
      return;
    }
    refetchUsers();
    showSnackbarSuccess('User successfully deleted');
  }, [deleteIsCompleted, deleteIsLoading, deleteIsError]);

  useEffect(() => {
    if (!users.length) return;
    const servicesMap = {};
    brandServices.forEach(service => {
      servicesMap[service.id] = service;
    });

    const withMatchedServices = users.map(user => {
      return {
        ...user,
        [usersKeys.services]: user.roles
          .map(role => {
            const service = servicesMap[role?.service_id] ?? {};
            return {
              ...role,
              [usersKeys.service.title]: service.title,
              [usersKeys.service.icon]: service.icon,
            };
          })
          // hide roles with no match to brandServices
          .filter(role => !!role[usersKeys.service.title]),
      };
    });

    setData(withMatchedServices);
  }, [brandServices, users]);

  const showDeleteUser = user => {
    const username = user?.[usersKeys.name] ?? 'this user';
    const title = 'Remove user';
    const description =
      parseStringModifiers(`Are you sure you want to remove **${username}?**
      
      **This action cannot be undone**`);
    const confirm = 'Remove';
    const onConfirm = () => {
      // TODO: export a closed function taking the userId
      const url = `client/${user[usersKeys.id]}`;
      doDelete({ url });
      dismiss();
    };
    const onCancel = dismiss;
    showModal(ConfirmModal, {
      title,
      description,
      confirm,
      onConfirm,
      onCancel,
    });
  };

  const handleAction = user => (option, e) => option.action({ user, e });

  const options = [
    {
      label: 'Mofidy',
      value: 'Mofidy',
      icon: Icons.Edit3,
      action: ({ user }) => showWriteUser(user),
      permission: EDIT_BRAND_USERS,
    },
    {
      label: 'Remove',
      value: 'Remove',
      icon: Icons.XCircle,
      action: ({ user }) => showDeleteUser(user),
      permission: DELETE_BRAND_USERS,
    },
  ].filter(({ permission }) => hasPermission(permission));

  return (
    <UsersListLayout
      headers={usersHeaders}
      data={data}
      grid={usersGrid}
      expansionHeaders={userExpansionHeaders}
      expansionGrid={usersExpansionGrid}
      showWriteUser={showWriteUser}
      isLoading={isLoading || brandServicesIsLoading}
      isError={isError || brandServicesIsError}
      error={isError ? error : brandServicesIsError ? brandServicesError : null}
      tableStatusProps={{
        noData: data.length === 0,
        noDataDisplay: noResultsState.users.noData,
        noSearchResultsDisplay: noResultsState.users.noSearchResults,
      }}
      showDeleteUser={showDeleteUser}
      handleAction={handleAction}
      options={options}
      addingUsers
    />
  );
};
export default UsersList;
