import { Utils } from '../../../../../../constants';
import {
  CaseProducts,
  tableComponents,
} from '../../../../features_public/cases/components';
import { CasesTableStyles } from '../style';

export const CasesTable = ({
  headers,
  data,
  expansionRowAction,
  onRowExpand,
  grid,
  showGallery,
  expansionHeaders,
  expansionGrid,
  onSupportClick,
  hasSupportPermission,
  hasProductPermission,
  expandCountKey,
}) => {
  return (
    <CasesTableStyles
      headers={headers}
      data={data}
      grid={grid}
      rowAction={hasProductPermission && Utils.expandRow}
      onRowExpand={onRowExpand}
      expandCountKey={expandCountKey}
      components={tableComponents({
        showGallery,
        expansionHeaders,
        expansionGrid,
        expansionRowAction,
        maxWidth: 160,
        CaseProducts,
        onSupportClick,
        hasProductPermission,
        hasSupportPermission,
        expandCountKey,
      })}
    />
  );
};
