import { Dot, LoadingEllipsis } from './Ellipsis.style';

const Ellipsis = ({ color, size }) => {
  return (
    <LoadingEllipsis>
      <Dot size={size} color={color} number={'_1'} />
      <Dot size={size} color={color} number={'_2'} />
      <Dot size={size} color={color} number={'_3'} />
      <Dot size={size} color={color} number={'_4'} />
    </LoadingEllipsis>
  );
};

export default Ellipsis;
