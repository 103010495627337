import usePostData from '../../../../../hooks/postHook';

export const useUpdateIsSSOUser = () => {
  const [{ data, body, isLoading, isError, error }, doUpdateIsSSOUser] =
    usePostData(``, {
      type: 'put',
    });

  return { data, body, isLoading, isError, error, doUpdateIsSSOUser };
};
