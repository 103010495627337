import { Form, Formik } from 'formik';
import { useLayoutEffect, useRef, useState } from 'react';
import { useModal } from '../../../providers/ModalProvider';
import { elementDimensions, screenPosition } from '../../../utils/utils';
import { Card } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Input } from '../../molecules/Input/Input';
import Actions from '../Actions/Actions';
import { Container } from './InPlaceForm.style';

const placeX = ({ x, width, offsetX, distanceFromEdge }) => {
  const windowWidth = window.innerWidth;
  const minX = windowWidth - width - distanceFromEdge;

  return Math.min(minX, x + offsetX);
};

const placeY = ({ y, height, offsetY, distanceFromEdge }) => {
  const windowHeight = window.innerHeight;
  const minY = windowHeight - height - distanceFromEdge;

  return Math.min(minY, y + offsetY);
};

const InPlaceForm = ({
  title,
  element,
  initialValues,
  validationSchema,
  fields,
  onSubmit,
  onCancel,
  cancelText,
  primaryText,
  offsetX,
  offsetY,
  e,
  isLoading,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef(null);
  const { dismiss } = useModal();
  const { x, y } = screenPosition(e);

  const distanceFromEdge = {
    x: 24,
    y: 24,
  };

  useLayoutEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(() => rszObserverCallback());
    resizeObserver.observe(container);
    return () => {
      resizeObserver.unobserve(container);
    };
  }, []);

  const rszObserverCallback = () => {
    const { width, height } = elementDimensions({
      currentTarget: containerRef.current,
    });

    setWidth(width);
    setHeight(height);
  };

  useLayoutEffect(rszObserverCallback, []);

  return (
    <Container
      x={placeX({ x, width, offsetX, distanceFromEdge: distanceFromEdge.x })}
      y={placeY({ y, height, offsetY, distanceFromEdge: distanceFromEdge.y })}
      ref={containerRef}
      className="in-place-form"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        validateOnMount
        enableReinitialize
      >
        {({ resetForm, values, actions, isValid }) => {
          return (
            <Form>
              <InPlaceFormContent
                title={title}
                element={element}
                fields={fields}
                onCancel={onCancel}
                dismiss={dismiss}
                cancelText={cancelText}
                primaryText={primaryText}
                isValid={isValid}
                isLoading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export const InPlaceFormContent = ({
  title,
  element,
  fields,
  onCancel,
  dismiss,
  cancelText,
  primaryText,
  isValid,
  isLoading,
}) => {
  return (
    <Card title={title} icon={element}>
      <Flex column w>
        {fields.map((field, index) => (
          <Input {...field} key={index} />
        ))}
      </Flex>
      <Actions
        gap="_XS"
        size="_S"
        onCancel={onCancel ?? dismiss}
        cancelText={cancelText}
        primaryText={primaryText}
        disabled={!isValid || isLoading}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default InPlaceForm;
