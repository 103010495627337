import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getClients } from '../auth/api/getClients';
import { getUserInfo } from '../auth/api/getUserInfo';
import { PropKeys, PropKeysMask } from '../constants';
import { API } from '../lib/api';
import { isEmpty } from '../lib/js';
import { maskEntry } from '../utils/utils';
import { useFirebase } from './FirebaseProvider';
import { usePermissions } from './PermissionProvider/PermissionsProvider';
import { useSnackbar } from './SnackbarProvider';
import { useStore } from './StoreProvider';

const AuthContext = createContext();
const ReauthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useReauth() {
  return useContext(ReauthContext);
}

export const initialToken = 'initial';

export function AuthProvider({ children }) {
  const [credentials, setCredentials] = useState({
    token: initialToken,
  });

  const { showSnackbarError } = useSnackbar();

  const { store, updateStore } = useStore();
  const { setFirebaseConfig, setPublicFirebaseConfig } = useFirebase();
  const { setPermissions } = usePermissions();

  const location = useLocation();
  const navigate = useNavigate();

  const isReauthing = useRef(false);
  const funcsQueue = useRef([]);

  // if token is expired, fetches new token and reruns the function
  async function withReauth(func, possiblyExpiredToken, args = '') {
    try {
      const success = await func(possiblyExpiredToken, ...args);
      return success;
    } catch (error) {
      try {
        if (!isUnauthorized(error)) throw new Error();

        const token = await doReauth();
        if (token == null) funcsQueue.current.push({ func, args });
        else {
          const success = await func(token, ...args);
          return success;
        }
      } catch {
        setCredentials({ logout: true });
      }
    }
  }

  function handleResponseError({ status, error }) {
    if (status == 401) throw new Error(status);
    if (status != 200) throw new Error(error);
  }

  function handleApiError(error) {
    if (isNetworkError(error)) setCredentials({ networkError: true });
    if (isUnauthorized(error)) throw error;
  }

  const networkErrorCodes = ['ETIMEDOUT', 'ERR_NETWORK'];
  const networkErrorMessages = ['ETIMEDOUT', 'Network Error'];

  function isNetworkError(error) {
    if (error?.code == null || error?.message == null) return false;
    return (
      networkErrorCodes.includes(error.code) ||
      networkErrorMessages.includes(error.message)
    );
  }

  function isUnauthorized(error) {
    return (
      error.message == 401 ||
      error.status == 401 ||
      error.response?.status == 401
    );
  }
  async function doReauth(brand_id, { isBrandSwitch = false } = {}) {
    if (isReauthing.current === true) return null;
    isReauthing.current = true;

    if (brand_id === undefined) brand_id = store?.[PropKeys.brandId];

    let brandIdOnReload = localStorage.getItem('brand_id');
    if (brandIdOnReload === 'null' || brandIdOnReload === 'undefined')
      brandIdOnReload = null;

    localStorage.removeItem('brand_id');
    if (brandIdOnReload && brand_id == null) brand_id = brandIdOnReload;

    try {
      const properties = [{ type: 'window', value: getWindowProperties(3) }];

      const body = {
        properties,
      };
      if (brand_id) body.brand_id = brand_id;

      const { data, status, error, token } = await API.POST(
        'reauthorize',
        body
      );

      if (error || status !== 200) throw Error(error);

      if (isBrandSwitch) return;

      const { firebase_config, firebase_config_public, client } = data;

      const { permissions } = client;
      setPermissions(permissions ?? {});
      // must happen before calls to getClients or getUserInfo
      if (!isEmpty(permissions)) updateStore({ usesNewPermissions: true });

      const saveClients = clients => updateStore({ clients });
      const saveUserInfo = userInfo => updateStore(userInfo);

      getClients({ token, saveClients });
      getUserInfo({ token, saveUserInfo });

      const it = maskEntry(client, PropKeysMask);
      updateStore(it);

      setCredentials(x => ({ ...x, token, networkError: false }));

      setFirebaseConfig(firebase_config);
      setPublicFirebaseConfig(firebase_config_public);

      return token;
    } catch (error) {
      if (isNetworkError(error)) {
        setCredentials({ networkError: true });
      } else setCredentials({ logout: true });
    } finally {
      isReauthing.current = false;
    }
  }

  async function switchBrands(brand_id) {
    updateStore({ [PropKeys.brandId]: brand_id });
    try {
      setCredentials(x => ({ ...x, showLoading: true }));
      await doReauth(brand_id, { isBrandSwitch: true });
    } catch (error) {
      showSnackbarError(
        'We had a problem with this brand. Please try again, or contact support@countercheck.com',
        undefined,
        6000
      );
      setCredentials(x => ({ ...x, showLoading: false }));
    }
  }

  async function initVerifiedUser({ response }) {
    if (!response) {
      showSnackbarError();
      return;
    }

    const { data, token } = response;

    const { firebase_config, firebase_config_public, client } = data;
    const { permissions } = client;
    setPermissions(permissions ?? {});
    // must happen before calls to getClients or getUserInfo
    if (!isEmpty(permissions)) updateStore({ usesNewPermissions: true });

    const saveClients = clients => updateStore({ clients });
    const saveUserInfo = userInfo => updateStore(userInfo);

    getClients({ token, saveClients });
    getUserInfo({ token, saveUserInfo });

    const it = maskEntry(client, PropKeysMask);
    updateStore(it);

    setCredentials({ token });

    setFirebaseConfig(firebase_config);
    setPublicFirebaseConfig(firebase_config_public);

    const to = '/';
    const state = { from: 'login' };
    navigate(to, { state });
  }

  // When reauth is done and a new token is received, run all functions that were queued
  // while waiting for reauth to complete
  useEffect(() => {
    // if token == null (or other wrong value), let it fail and logout on forEach below
    if (credentials.token === initialToken) return;

    funcsQueue.current.forEach(fnObj => {
      const { func, args } = fnObj;
      try {
        func(credentials.token, ...args);
      } catch (error) {
        setCredentials({ logout: true });
      }
    });
    isReauthing.current = false;
    funcsQueue.current = [];
  }, [credentials]);

  const cleanUrl = to => {
    let { search } = location;
    let { state } = location;

    if (!state) state = { search: search };
    else {
      state.search = search;
    }

    if (to == null) to = location.pathname;

    navigate(to, { state, replace: true });
  };

  const storeBrandId = () => {
    localStorage.setItem('brand_id', store?.[PropKeys.brandId]);
  };

  useEffect(() => {
    const storeBrandId_ = storeBrandId;

    const handleVisibilityChange = () => {
      if (document.hidden) storeBrandId_();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [store?.[PropKeys.brandId]]);

  useEffect(() => {
    const onOpenNewTab = e => {
      const { url } = e.detail;
      storeBrandId();
      (window.open(url, '_blank', 'noopener noreferrer') ?? {}).opener = null;
    };

    window.addEventListener('openNewTab', onOpenNewTab);

    return () => window.removeEventListener('openNewTab', onOpenNewTab);
  }, []);

  useEffect(() => {
    window.addEventListener('cleanUrl', cleanUrl);
    return () => window.removeEventListener('cleanUrl', cleanUrl);
  });

  return (
    <AuthContext.Provider
      value={[credentials, setCredentials, { initVerifiedUser }]}
    >
      <ReauthContext.Provider
        value={{
          withReauth,
          doReauth,
          switchBrands,
          cleanUrl,
          handleApiError,
          handleResponseError,
          storeBrandId,
        }}
      >
        {children}
      </ReauthContext.Provider>
    </AuthContext.Provider>
  );
}

let windowKeys = [
  'navigator',
  'origin',
  'outerHeight',
  'outerWidth',
  'innerHeight',
  'innerWidth',
  'menubar',
  'performance',
  'personalbar',
  'scrollbars',
  'screen',
  'statusbar',
  'styleMedia',
  'visualViewport',
  'devicePixelRatio',
  'clientInformation',
];

const extractProperty = (object, key, depth = 3) => {
  if (object && key in object) {
    let value = object[key];
    if (depth > 1 && typeof value === 'object') {
      return extractNestedProperties(value, depth - 1);
    } else {
      return typeof value === 'function' ? null : value;
    }
  } else {
    return null;
  }
};

const extractNestedProperties = (object, depth) => {
  let nestedProperties = {};
  for (let key in object) {
    if (typeof object[key] !== 'function') {
      nestedProperties[key] = extractProperty(object, key, depth);
    }
  }
  return nestedProperties;
};

const getWindowProperties = depth => {
  let windowProperties = {};
  windowKeys.forEach(key => {
    if (typeof window[key] !== 'function') {
      windowProperties[key] = extractProperty(window, key, depth);
    }
  });
  return windowProperties;
};
