import { Utils } from '../../../constants';
import { Icon } from '../../atoms';
import { useInlineStyle } from './AnimatedIcon.hook';
import { ClickableArea, Container, Recipe } from './AnimatedIcon.style';

const AnimatedIcon = ({
  name,
  color,
  theme,
  theme_,
  size,
  onClick,
  active,
  disabled,
  ...props
}) => {
  const coreStyle = Recipe('default');
  const [style, setStyle] = useInlineStyle(coreStyle, {
    theme: theme || theme_,
    active,
    disabled,
  });

  const setHover = to => () => setStyle('hover', to);

  return (
    <Container
      disabled={disabled}
      isClickable={!!onClick}
      {...props}
      className={`animated-icon ${props.className ?? ''}`}
    >
      <Icon name={name} color={style.color} size={size} />
      <ClickableArea
        onClick={
          !disabled ? onClick ?? Utils.emptyFunction : Utils.emptyFunction
        }
        onPointerOver={setHover(true)}
        onPointerLeave={setHover(false)}
      />
    </Container>
  );
};

export default AnimatedIcon;
