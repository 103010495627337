import { useEffect, useState } from 'react';
import { isArray } from '../../../lib/js';
import useDynamicRefs from '../../../utils/dynamicRefs';
import { Colors } from '../../style';
import { Label } from '../Label/Label';
import { ActiveSwitch, Container, LabelCSS, Track } from './ToggleText.style';

const ToggleText = ({
  options,
  activeIndex,
  size,
  select,
  backgroundColor,
  indicatorBg = Colors.Sharp.White,
  indicatorTextColor,
  indicatorInactiveTextColor,
  padding,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    const maxWidth = options.reduce((currentMax, _, index) => {
      const button = getRef(index).current;
      const buttonWidth = button?.getBoundingClientRect().width ?? 0;
      return Math.max(currentMax, buttonWidth);
    }, 0);

    setMaxWidth(maxWidth);
  }, [options]);

  if (!isArray(options)) return;
  return (
    <Container
      className="toggle-text"
      backgroundColor={backgroundColor}
      padding={padding}
    >
      <Track show={maxWidth}>
        <ActiveSwitch
          activeSlot={activeIndex}
          slots={options.length}
          color={indicatorBg}
        />
        {options.map((option, index) => {
          const active = activeIndex === index;
          return (
            <LabelCSS
              onClick={select(option, index)}
              ref={setRef(index)}
              width={maxWidth}
              className="label-container"
              key={index}
            >
              <Label
                {...option}
                textColor={
                  !active
                    ? indicatorInactiveTextColor ?? Colors.Neutral._500
                    : indicatorTextColor
                }
                background="transparent"
                size={size}
              />
            </LabelCSS>
          );
        })}
      </Track>
    </Container>
  );
};
export default ToggleText;
