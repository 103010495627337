import { Icon, Text } from '../../atoms';
import SidebarPageIcon from '../../molecules/SidebarPageIcon/SidebarPageIcon';
import { Colors, FontKeys, Size } from '../../style';
import {
  BrandContainer,
  BrandHoverBG,
  BrandOuterContainer,
  CountercheckLogoCSS,
  LogoAndVersionContainer,
  MenuContainer,
  MenuPointerPosition,
  MoreIconsScrollCSS,
  NavigationIconsContainer,
  ProfileContainer,
  UserAvatarContainer,
  VersionBadgeBackgroundCover,
  VersionBadgeCSS,
  sidebarBackgroundColor,
  sidebarIconDefaultColor,
} from './Sidebar.style';

import { forwardRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarBrandCount } from '../../../auth/components';
import { PropKeys, Prose, Utils } from '../../../constants';
import { useStore } from '../../../providers/StoreProvider';
import { BrandLogo } from '../../atoms/BrandLogo/BrandLogo';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import Pointer from '../../atoms/Pointer/Pointer';
import { Separator } from '../../atoms/Separator/Separator';
import Spacer from '../../atoms/Spacer/Spacer';
import Avatar from '../../molecules/Avatar/Avatar';
import countercheckBanner from './../../../assets/img/logo_horizontal_white.png';

export const NavigationIcons = ({
  icons,
  expanded,
  onSelectPage,
  transition,
  ...rest
}) => {
  const [top, setTop] = useState(0);
  const [selectedPage, setSelectedPage] = useState();
  const [hideIndicator, setHideIndicator] = useState(true);

  const navigate = useNavigate();
  let { pathname } = useLocation();

  const { store } = useStore();

  const isAllBrands = !store[PropKeys.brandId];

  const containerRef = useRef(null);

  useEffect(() => {
    pathname = `/${pathname.split('/')[1]}`;
    setSelectedPage(pathname);
    const isAtSidebarPage = icons.some(page => page.uri.includes(pathname));
    setHideIndicator(!isAtSidebarPage);
    if (!isAtSidebarPage) setTop(null);
  }, [pathname, icons]);

  const selectPage = (page, index) => e => {
    if (typeof onSelectPage === 'function') onSelectPage(page, index, e);
    const { uri } = page;
    const to = uri;
    navigate(to);
  };

  // const moveIndicator = currentTarget => {
  //   const { y: top } = screenPosition({ currentTarget }) ?? {};
  //   const { y: containerTop } =
  //     screenPosition({ currentTarget: containerRef.current }) ?? {};

  //   setTop(top - containerTop);
  // };

  return (
    <NavigationIconsContainer ref={containerRef}>
      {icons.map((page, index) => {
        const { name, size, label, uri, comingSoon } = page;
        const disabled = isAllBrands && allBrandsBlacklist.includes(label);

        return (
          <SidebarPageIcon
            {...page}
            selected={selectedPage === uri}
            name={name}
            size={size}
            label={label}
            expanded={expanded}
            transition={transition}
            onClick={disabled ? Utils.emptyFunction : selectPage(page, index)}
            uri={uri}
            // moveIndicator={moveIndicator}
            key={uri}
            icons={icons}
            comingSoon={comingSoon}
            disabled={disabled}
            tooltipContent={
              isAllBrands && disabled ? Prose.allBrandsDisabled : ''
            }
            {...rest}
          />
        );
      })}
      {/* {!hideIndicator && <PageIndicator top={top} hideIndicator={false} />} */}
    </NavigationIconsContainer>
  );
};

const allBrandsBlacklist = ['Parcel Processing'];

export const Brand = forwardRef(
  ({ brandImage, brandName, expanded, onClick }, ref) => {
    const { store } = useStore();
    const isAllBrands = !store[PropKeys.brandId];
    const noOfClients = store[PropKeys.clients]?.length;
    return (
      <BrandOuterContainer relative w expanded={expanded}>
        <BrandHoverBG className="brand-hover-bg" />
        <BrandContainer
          onClick={onClick}
          isClickable={!!onClick}
          ref={ref}
          expanded={expanded}
        >
          <Flex
            justify="start"
            align="center"
            padding={'2px'}
            isClickable
            className={'brand-logo-container'}
          >
            <BrandLogo height={'48px'} variant="sidebar" src={brandImage} />
          </Flex>
          <Flex w0 grow justify="between" align="center" isClickable>
            <Flex gap="_2XS" align="center">
              <BrandName text={brandName} ellipsis={expanded} />
              {isAllBrands ? <SidebarBrandCount count={noOfClients} /> : null}
            </Flex>
            <Icon
              name={Icons.ChevronsUpDown}
              size={Size.Icon._S}
              color={Colors.Sharp.White}
            />
          </Flex>
        </BrandContainer>
      </BrandOuterContainer>
    );
  }
);

const BrandName = ({ text, ellipsis }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Sharp.White}
      ellipsis={ellipsis}
      whiteSpace="nowrap"
    />
  );
};

export const LogoAndVersion = ({ version }) => {
  return (
    <LogoAndVersionContainer>
      <CountercheckLogo />
      <VersionBadge version={version} />
    </LogoAndVersionContainer>
  );
};

const CountercheckLogo = () => {
  return <CountercheckLogoCSS src={countercheckBanner} />;
};

const VersionText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={sidebarBackgroundColor}
    />
  );
};

const VersionBadge = ({ version }) => {
  return (
    <VersionBadgeBackgroundCover>
      <VersionBadgeCSS>
        <VersionText text={version} />
      </VersionBadgeCSS>
    </VersionBadgeBackgroundCover>
  );
};

export const Profile = ({
  image,
  username,
  email,
  userIconRef,
  onClick,
  expanded,
}) => {
  return (
    <ProfileContainer ref={userIconRef} onClick={onClick} expanded={expanded}>
      <BrandHoverBG className="brand-hover-bg" />
      <UserAvatar image={image} username={username} />
      <UserDetails username={username} email={email} />
    </ProfileContainer>
  );
};

const UserAvatar = ({ image, username }) => {
  return (
    <UserAvatarContainer>
      <Avatar image={image} username={username} size="_XS" />
    </UserAvatarContainer>
  );
};

const UserDetails = ({ username, email }) => {
  return (
    <Flex column gap="_3XS" isClickable>
      <UserDetailsName text={username} />
      <UserDetailsEmail text={email} />
    </Flex>
  );
};

const UserDetailsName = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Sharp.White}
      whiteSpace="nowrap"
    />
  );
};

const UserDetailsEmail = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      color={Colors.Sharp.White}
      whiteSpace="nowrap"
    />
  );
};

export const SidebarSeparator = () => {
  return (
    <>
      <Spacer height={'_XS'} />
      <Flex w relative>
        <Separator background={Colors.Neutral._400} />
      </Flex>
      <Spacer height={'_XS'} />
    </>
  );
};

export const Menu = ({ menuLayout, pointerTop, menuLayoutRef, menuStyle }) => {
  return (
    menuLayout && (
      <>
        <MenuContainer ref={menuLayoutRef} style={menuStyle ?? {}}>
          {menuLayout}
        </MenuContainer>
        <MenuPointer top={pointerTop} />
      </>
    )
  );
};

export const pointerSize = 8;

const MenuPointer = ({ top }) => {
  return (
    <MenuPointerPosition top={top}>
      <Pointer direction="left" size={pointerSize} color={Colors.Sharp.White} />
    </MenuPointerPosition>
  );
};

export const SectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};

export const MoreIconsScroll = forwardRef(({ direction }, ref) => {
  const name =
    direction === 'up'
      ? Icons.ChevronUp
      : direction === 'down'
      ? Icons.ChevronDown
      : null;
  if (direction == null) return;

  const scroll = () =>
    ref?.current?.scrollBy({
      top: direction === 'up' ? -100 : 100,
      behavior: 'smooth',
    });

  return (
    <Flex relative>
      <MoreIconsScrollCSS direction={direction} onClick={scroll}>
        <Icon
          name={name}
          size={Size.Icon._XS}
          color={sidebarIconDefaultColor}
        />
      </MoreIconsScrollCSS>
    </Flex>
  );
});
