import { Route, Routes } from 'react-router-dom';
    import WhiteLabel from './WhiteLabel';
    
    export const WhiteLabelRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<WhiteLabel />} />
        </Routes>
      );
    };
    