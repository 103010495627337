import { useNavigate } from 'react-router-dom';
import BrandSelection from '../../../../../auth/controllers/BrandSelection';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { Separator } from '../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { SectionTitle } from '../../../../../components/organisms/Sidebar/components';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { optionViews } from '../../../features/brand_settings/controllers/variables';
import { Labels, Pages, URIs } from '../../../routes/variables';
import { BrandManagement } from './components';
import { Container } from './style';

const {
  BRAND_MANAGEMENT: {
    SELF,
    VIEW_BRAND_USERS,
    VIEW_BRAND_CONTRACTS,
    VIEW_BRAND_BILLING,
    VIEW_BRAND_DETAILS,
  },
} = PermissionKeys.Brand;

export const BrandOptions = ({ clearMenu }) => {
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();

  const goToBrandManagement = option => () => {
    const to = URIs[Pages.brand_settings];
    const state = { option };
    navigate(to, { state });
    clearMenu();
  };

  const managementOptions = [
    {
      leftIcon: Icons.ImagePlus,
      text: 'Logo',
      permission: [VIEW_BRAND_DETAILS],
      onClick: goToBrandManagement(optionViews.brandLogo),
    },
    {
      leftIcon: Icons.Files,
      text: 'Billing',
      permission: [VIEW_BRAND_BILLING],
      onClick: goToBrandManagement(optionViews.billing),
    },
    {
      leftIcon: Icons.UserPlus,
      text: 'Users',
      permission: [VIEW_BRAND_USERS],
      onClick: goToBrandManagement(optionViews.users),
    },
    {
      leftIcon: Icons.Inbox,
      text: 'Contracts',
      permission: [VIEW_BRAND_CONTRACTS],
      onClick: goToBrandManagement(optionViews.contracts),
    },
  ].filter(({ permission }) => hasPermission(SELF, permission));

  return (
    <Container>
      <SectionTitle text={'Brands'} />
      <Spacer height={'_XS'} />
      <BrandSelection />
      {!!managementOptions.length && (
        <Flex w column relative>
          <Separator style={{ top: 0 }} />
          <Spacer height={'_XS'} />
          <SectionTitle text={Labels[Pages.brand_settings]} />
          <Spacer height={'_XS'} />
          <BrandManagement options={managementOptions} />
        </Flex>
      )}
    </Container>
  );
};
