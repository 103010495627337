import { includeConstants } from '../../../utils/styleUtils';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Colors, Layout, Size } from '../../style';

const constantStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: Layout.Spacing._2XS,
  padding: Layout.Spacing._2XS,
};

export const Recipe = variant => {
  return includeConstants(Recipes[variant], constantStyle);
};

const Recipes = {
  default: ({ selected, disabled, size, color }) => {
    return {
      cursor: disabled ? 'auto' : 'pointer',
      check: {
        name: selected ? Icons.CheckSquare : Icons.Square,
        color: selected ? color : Colors.Neutral._300,
        size: Size.Icon[size],
      },
    };
  },
};
