import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { FilesTable } from './organisms';

export const FilesLayout = ({ headers, data, grid, downloadFile }) => {
  return (
    <TableStatus
      table={
        <FilesTable
          headers={headers}
          data={data}
          grid={grid}
          downloadFile={downloadFile}
        />
      }
    />
  );
};
