import styled from '@emotion/styled';
import { Colors, Effects, Layout } from '../../style';

export const Dot = styled.div`
  z-index: 1;
  border-radius: ${Effects.Border_Radius.Circle};
  width: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._2XS};
  border: 1px solid
    ${({ inactive }) => (inactive ? Colors.Neutral._500 : Colors.Primary._600)};
  background: ${props =>
    props.isFirst
      ? props.inactive
        ? Colors.Neutral._500
        : Colors.Primary._600
      : Colors.Sharp.White};
`;

export const Line = styled.div`
  width: 1px;
  position: absolute;
  margin: auto;
  height: 100%;
  background: ${({ inactive }) =>
    inactive ? Colors.Neutral._500 : Colors.Primary._600};
  ${props => (props.down ? 'top' : 'bottom')}: 50%;
`;
