import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { maskEntry } from '../../../../../utils/utils';
import { knowledgeBaseKeys } from '../controllers/variables';

export const useGetRules = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);
  const [
    { data = { rules: [], ruleCount: 0 }, isLoading = true, isError, error },
    _,
    refreshTable,
  ] = useFetchData('office_knowledge_base', {
    params,
    ignore,
    formatter,
    large: true,
  });

  return {
    data,
    isLoading,
    isError,
    error,

    refreshTable,
  };
};

const formatter = data => {
  let { instructions: rules, instruction_count: ruleCount } = data;
  rules = maskEntry(rules, mask);

  let withFormattedCategory = rules.map(item => {
    const { kpProductCategory: productCategory } = item;
    const [cateogry, subCategory] = (productCategory ?? '').split(' - ');
    return {
      ...item,
      [knowledgeBaseKeys.productCategory]: cateogry,
      [knowledgeBaseKeys.productSubCategory]: subCategory,
    };
  });

  return {
    rules: withFormattedCategory,
    ruleCount,
  };
};

const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    ADDED_BY,
    PRODUCT_CATEGORY,
    PAGE,
    ITEMS_PER_PAGE,
    BRANDS,
  } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const adding_client_id = extractSelectedValues(ADDED_BY);
  const product_id = extractSelectedValues(PRODUCT_CATEGORY);
  const brand_id = extractSelectedValues(BRANDS);

  const formattedParams = {
    search,
    adding_client_id,
    product_id,
    from_date,
    to_date,
    brand_id,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

const mask = {
  characteristics_count: knowledgeBaseKeys.characteristicsCount,
  createdAt: knowledgeBaseKeys.date,
  kbClientAdded: knowledgeBaseKeys.addedBy,
  kbID: knowledgeBaseKeys.id,
  kbTitle: knowledgeBaseKeys.name,
  usDisplayName: knowledgeBaseKeys.addedByName,
  usImage: knowledgeBaseKeys.addedByImage,
  kbIsVisible: knowledgeBaseKeys.visibility,
  brName: knowledgeBaseKeys.customer.brand,
  brIsContracted: knowledgeBaseKeys.customer.isContracted,
};
