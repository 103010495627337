import { createContext, useContext, useState } from 'react';
import { PropKeys } from '../../constants';
import { cloneDeep, isArray } from '../../lib/js';
import useDeepCompareEffect from '../../utils/useDeepCompareEffect';
import { useStore } from '../StoreProvider';
import {
  PermissionConsts as _PermissionConsts,
  PermissionKeys as _PermissionKeys,
} from './variables';

export const PermissionKeys = _PermissionKeys;
export const PermissionConsts = _PermissionConsts;

const {
  PARCELS: { SELF: PARCELS },
  PRODUCTS: { SELF: PRODUCTS, VIEW_DASHBOARD_PRODUCTS },
  REFERENCES: { SELF: REFERENCES, VIEW_DASHBOARD_REFERENCES },
} = PermissionKeys.Brand;

const {
  OFFICE_PARCELS: {
    SELF: OFFICE_PARCELS,
    VIEW_PARCELS,
    VIEW_PARCEL_SUPPORT_TICKETS,
  },
  OFFICE_PRODUCTS: {
    SELF: OFFICE_PRODUCTS,
    VIEW_PRODUCT,
    VIEW_PRODUCT_SUPPORT_TICKETS,
  },
  OFFICE_SUPPORT: { SELF: OFFICE_SUPPORT, VIEW_GENERAL_SUPPORT_TICKETS },
} = PermissionKeys.Admin;

const PermissionsContext = createContext();

export function usePermissions() {
  return useContext(PermissionsContext);
}

const initialPermissions = { allow: true };

const PermissionsProvider = ({ children }) => {
  const { store, updateStore } = useStore();

  const [permissions, setPermissions] = useState(initialPermissions);
  const [isPermissionSet, setIsPermissionsSet] = useState(false);

  useDeepCompareEffect(() => {
    const { has_sorters } = store;
    updateStore({ [PropKeys.isLogisticsUser]: !!has_sorters });
  }, [store]);

  const setPermissionsCustom = x => {
    const permissionSet = getAllBrandsPermissions(x);
    setPermissions(permissionSet);
    setIsPermissionsSet(true);
  };

  const clearPermissions = () => {
    setIsPermissionsSet(false);
    setPermissions(initialPermissions);
  };

  const hasPermission = (
    service,
    permission,
    {
      arrayMethod = 'some',
      brandId = store[PropKeys.brandId],
      debug = false,
    } = {}
  ) => {
    // if brand is all brands, check if any brand has permission
    if (debug) console.info('hasPermission', { service, permission, brandId });
    if (!brandId) {
      if (
        isArray(permission) &&
        allBrandsPermissionBlacklist.some(p => permission.includes(p))
      )
        return false;
      if (
        isArray(service) &&
        allBrandsServiceBlacklist.some(s => service.includes(s))
      )
        return false;
      if (allBrandsServiceBlacklist.includes(service)) return false;
      if (allBrandsPermissionBlacklist.includes(permission)) return false;
      return Object.keys(permissions).some(id =>
        hasPermission(service, permission, { brandId: id, debug })
      );
    }

    if ([service, permission].some(p => p === PermissionConsts.allow))
      return true;
    if (isArray(service) && isArray(permission))
      throw Error('Service and Permission cannot both be arrays');

    if (isArray(service))
      return service[arrayMethod](s => permissions[brandId]?.[s]?.[permission]);
    if (isArray(permission))
      return permission[arrayMethod](p => permissions[brandId]?.[service]?.[p]);

    return permissions[brandId]?.[service]?.[permission] ?? false;
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        isPermissionSet,
        setPermissions: setPermissionsCustom,
        clearPermissions,
        hasPermission,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;

const getSingleBrandPermissions = x => {
  const viewDashboardProducts = x?.[PRODUCTS]?.[VIEW_DASHBOARD_PRODUCTS];
  const viewDashboardReferences = x?.[REFERENCES]?.[VIEW_DASHBOARD_REFERENCES];
  const showDashboard = [viewDashboardProducts, viewDashboardReferences].some(
    p => p
  );

  const viewOfficeDashboardProducts = x?.[OFFICE_PRODUCTS]?.[VIEW_PRODUCT];
  const viewOfficeDashboardParcels = x?.[OFFICE_PARCELS]?.[VIEW_PARCELS];
  const viewOfficeDashboardSupport = [
    x?.[OFFICE_PARCELS]?.[VIEW_PARCEL_SUPPORT_TICKETS],
    x?.[OFFICE_PRODUCTS]?.[VIEW_PRODUCT_SUPPORT_TICKETS],
    x?.[OFFICE_SUPPORT]?.[VIEW_GENERAL_SUPPORT_TICKETS],
  ].some(p => p);

  const showOfficeDashboard = [
    viewOfficeDashboardProducts,
    viewOfficeDashboardParcels,
    viewOfficeDashboardSupport,
  ].some(p => p);

  const customPermissions = {
    DASHBOARD: {
      SHOW: showDashboard,
      VIEW_DASHBOARD_PRODUCTS: viewDashboardProducts,
      VIEW_DASHBOARD_REFERENCES: viewDashboardReferences,
    },
    OFFICE_DASHBOARD: {
      SHOW: showOfficeDashboard,
    },
  };
  const SUPPORT = {
    CREATE_PRODUCT_SUPPORT_TICKETS:
      x?.[PRODUCTS]?.['CREATE_PRODUCT_SUPPORT_TICKETS'],
    VIEW_PRODUCT_SUPPORT_TICKETS:
      x?.[PRODUCTS]?.['VIEW_PRODUCT_SUPPORT_TICKETS'],
    VIEW_OWN_PRODUCT_SUPPORT_TICKETS:
      x?.[PRODUCTS]?.['VIEW_OWN_PRODUCT_SUPPORT_TICKETS'],
    EDIT_PRODUCT_SUPPORT_TICKETS:
      x?.[PRODUCTS]?.['EDIT_PRODUCT_SUPPORT_TICKETS'],
    EDIT_OWN_PRODUCT_SUPPORT_TICKETS:
      x?.[PRODUCTS]?.['EDIT_OWN_PRODUCT_SUPPORT_TICKETS'],

    CREATE_PARCEL_SUPPORT_TICKETS:
      x?.[PARCELS]?.['CREATE_PARCEL_SUPPORT_TICKETS'],
    VIEW_PARCEL_SUPPORT_TICKETS: x?.[PARCELS]?.['VIEW_PARCEL_SUPPORT_TICKETS'],
    VIEW_OWN_PARCEL_SUPPORT_TICKETS:
      x?.[PARCELS]?.['VIEW_OWN_PARCEL_SUPPORT_TICKETS'],
    EDIT_PARCEL_SUPPORT_TICKETS: x?.[PARCELS]?.['EDIT_PARCEL_SUPPORT_TICKETS'],
    EDIT_OWN_PARCEL_SUPPORT_TICKETS:
      x?.[PARCELS]?.['EDIT_OWN_PARCEL_SUPPORT_TICKETS'],
  };

  const OFFICE_SUPPORT__ = {
    EDIT_PARCEL_SUPPORT_TICKETS:
      x?.[OFFICE_PARCELS]?.['EDIT_PARCEL_SUPPORT_TICKETS'],
    VIEW_PARCEL_SUPPORT_TICKETS:
      x?.[OFFICE_PARCELS]?.['VIEW_PARCEL_SUPPORT_TICKETS'],

    EDIT_PRODUCT_SUPPORT_TICKETS:
      x?.[OFFICE_PRODUCTS]?.['EDIT_PRODUCT_SUPPORT_TICKETS'],
    VIEW_PRODUCT_SUPPORT_TICKETS:
      x?.[OFFICE_PRODUCTS]?.['VIEW_PRODUCT_SUPPORT_TICKETS'],
  };

  const permissionSet = {
    ...x,
    SUPPORT: {
      ...x['SUPPORT'],
      ...SUPPORT,
    },
    OFFICE_SUPPORT: {
      ...x['OFFICE_SUPPORT'],
      ...OFFICE_SUPPORT__,
    },
    ...customPermissions,
  };

  return permissionSet;
};

const getAllBrandsPermissions = _permissionsById => {
  const permissionsById = cloneDeep(_permissionsById);
  const brandIds = Object.keys(permissionsById);

  for (const brand of brandIds) {
    permissionsById[brand] = getSingleBrandPermissions(permissionsById[brand]);
  }

  return permissionsById;
};

const {
  CREATE_GENERAL_SUPPORT_TICKETS,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const { CREATE_WHITE_LABELS, EDIT_WHITE_LABELS } =
  PermissionKeys.Brand.WHITE_LABEL;

const { CREATE_REFERENCES } = PermissionKeys.Brand.REFERENCES;

const allBrandsPermissionBlacklist = [
  // CREATE_REFERENCES,
  // CREATE_WHITE_LABELS,
  // EDIT_WHITE_LABELS,
];

const allBrandsServiceBlacklist = [
  // PermissionKeys.Brand.BRAND_MANAGEMENT.SELF,
  // PermissionKeys.Facilitator.OFFICE_PARCEL_PROCESSING.SELF,
  // PermissionKeys.Brand.REPORTS.SELF,
];
