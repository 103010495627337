import styled from '@emotion/styled';
import {} from '../../../utils/stringUtils';
import { numWithCommas } from '../../../utils/utils';
import { Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, FontKeys, Layout } from '../../style';

export const ChartTooltip = props => {
  const {
    active,
    payload,
    label,
    coordinate: { x },
    ignoreTotalTooltip,
  } = props;

  if (active && payload && payload.length) {
    const { customLabel } = payload[0]?.payload;
    return (
      <CustomTooltipContainer x={x}>
        <Flex column gap="_XS">
          <TooltipText text={customLabel ?? label} bold />
          {!ignoreTotalTooltip ? (
            <Flex column gap="_XS">
              {payload.map(datapoint => (
                <DataPoint
                  {...datapoint}
                  name={datapoint.name}
                  key={datapoint.dataKey}
                />
              ))}
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      </CustomTooltipContainer>
    );
  }

  return null;
};

const CustomTooltipContainer = styled.div`
  position: relative;
  // left: ${props => props.x - 200}px;
  background: ${Colors.Sharp.White};
  padding: ${Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._M};
`;

const DataPoint = ({ name, payload, color, value }) => {
  const { tooltipColor } = payload;
  const text = `${name}: ${numWithCommas(value)}`;
  return (
    <Flex gap="_2XS" align="center">
      <DataSquare color={tooltipColor ?? color} />
      <TooltipText text={text} />
    </Flex>
  );
};

const DataSquare = styled.div`
  min-width: ${Layout.Spacing._2XS};
  min-height: ${Layout.Spacing._2XS};
  width: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._2XS};
  background: ${props => props.color};
  border-radius: calc(${Effects.Border_Radius._2XS} / 2);
`;

const TooltipText = ({ text, bold }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Caption}
    size={FontKeys.Size._M}
    weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
  />
);
