import { Flex } from '../../components/atoms/Flex/Flex';
import { PropKeys, Utils } from '../../constants';
import { formatAllBrandsSelection } from '../controllers/utils';
import { BrandsCatalog } from './organisms/Organisms';

export const BrandSelectionLayout = ({
  clients,
  handleBrandSwitch,
  toggleFavorite,
  isAllBrands,
}) => {
  return (
    <Flex relative column gap="_M" scroll hideScrollbar>
      {(clients?.length ?? 0) > 1 ? (
        <BrandsCatalog
          title=""
          brands={formatAllBrandsSelection({ clients, isAllBrands })}
          handleBrandSwitch={handleBrandSwitch}
          toggleFavorite={Utils.emptyFunction}
          hideFavorite
        />
      ) : null}
      <BrandsCatalog
        title="Favorites"
        brands={clients.filter(c => c[PropKeys.favoritedBrand])}
        handleBrandSwitch={handleBrandSwitch}
        toggleFavorite={toggleFavorite}
        favorite
        placeholder="No favorites yet"
      />
      <BrandsCatalog
        title="Brands"
        brands={clients.filter(c => !c[PropKeys.favoritedBrand])}
        handleBrandSwitch={handleBrandSwitch}
        toggleFavorite={toggleFavorite}
        favorite={false}
      />
      <div />
    </Flex>
  );
};
