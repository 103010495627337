import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { AdminServicesTable } from './organisms';

const AdminServicesLayout = ({
  tableProps,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  return (
    <TableStatus
      {...tableStatusProps}
      isLoading={isLoading}
      isError={isError}
      error={error}
      table={<AdminServicesTable tableProps={tableProps} />}
    />
  );
};

export default AdminServicesLayout;
