import styled from '@emotion/styled';
import {
  DisableContainer,
  extendStyledComponents,
} from '../../../utils/styleUtils';
import { Filter, FilterIcon, Layout } from '../../style';

export const Container = styled(DisableContainer)`
  ${extendStyledComponents(Filter)};
  ${extendStyledComponents(FilterIcon)};
  justify-content: center;
  align-items: center;
  min-width: ${Layout.Spacing._M};
  max-width: ${Layout.Spacing._M};
`;
