import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { useSearchQueue } from '../../../../../components/organisms/Filters/Filters.hook';
import {
  generateDropdown,
  hasCustomSearch,
  hasExcludeFilter,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useUI } from '../../../../../providers/UIProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { GalleryPages, GalleryProps } from '../../../../admin/constants';
import { Labels, NavURIs, Pages } from '../../../../admin/routes/variables';
import { useDoParcelAction } from '../api/doParcelAction';
import { useGetFilters } from '../api/getFilters';
import { useGetParcelsToEvaluate } from '../api/getParcelsToEvaluate';
import ParcelEvaluationLayout from '../components/ParcelEvaluationLayout';
import { useParcelEvaluationReducer } from './ParcelEvaluation.hook';
import {
  DC,
  allOptions,
  filtersConstants,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const { SELF, VIEW_REVIEWS } = PermissionKeys.Admin.OFFICE_REVIEW;

const ParcelEvaluation = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [data, setData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { dismiss, showModal } = useModal();

  const { setShowHeader, setHeaderChildren } = useHeader();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { setShowFooter } = useFooter();
  const { updateSharedUI } = useUI();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = hasPermission(VIEW_REVIEWS);
  const hasEditPermission = hasViewPermission;
  const resetFormRef = useRef(null);

  const ownOptions = {};

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: !hasViewPermission });

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useParcelEvaluationReducer({
    initialSearchParams,
    resetSearchParams,
  });
  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location) || true;
  const urlHasExclude = urlHasSearch && hasExcludeFilter(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // !hasViewPermission ||
    // (urlHasExclude && filtersIsLoading) ||
    // // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { reviews, reviewCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTable,
  } = useGetParcelsToEvaluate({
    searchParams,
    ignore: ignoreDataFetch,
  });

  const {
    data: actionData,
    body: actionBody,
    isLoading: actionIsLoading,
    isError: actionIsError,
    error: actionError,
    doParcelAction,
  } = useDoParcelAction();

  const triggerSearch = newValue =>
    hasViewPermission
      ? dispatch(DC.SEARCH_TRIGGER)(newValue)
      : Utils.emptyFunction;
  useSearchQueue({ searchParams, isLoading, triggerSearch });

  useEffect(() => {
    const to = NavURIs[Pages.parcel_evaluation];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isLoading || isError || reviews == null) return;
    setData(reviews);
  }, [reviews, isLoading, isError]);

  useDeepCompareEffect(() => {
    if (actionIsLoading || (actionData == null && actionBody == null)) return;
    if (actionIsError) {
      showSnackbarError(actionError);
      return;
    }
    actionBody?.actionKey == null &&
      showSnackbarSuccess(`${actionBody?.text} successful`);
    const updatedData = data.map(item => {
      if (item.id === actionData?.id) return actionData;
      return item;
    });

    setData(updatedData);
  }, [actionData, actionIsLoading, actionIsError]);

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    isError: filtersIsError,
  });

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRAND]: dropdown(DC.BRAND),
    [DC.LOCATION]: dropdown(DC.LOCATION),
    [DC.RISK_TYPE]: dropdown(DC.RISK_TYPE),
    [DC.REVIEW_STATUS]: dropdown(DC.REVIEW_STATUS),
    [DC.PRODUCT_STATUS]: dropdown(DC.PRODUCT_STATUS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const customStyles = `
      .filters-container {
        width: unset;
        min-width: 372px;
        flex-grow: 1;
        }
        .view-title {
          margin-right: clamp(0px, calc(80px - (1400px - 100%)/4), 80px);
        }
      .filters  
        {
          > .${DC.SEARCH} {
          padding-left: 8px;
          &.open {
            min-width: 80px;
          }
        }
      }
      `;

      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.parcel_evaluation]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          customStyles={customStyles}
          collapsible
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    const layoutCustomStyles = `
      .inner-content, .page-content {
        min-width: 310px;
      }
      .inner-content {
        padding: 0 4px;
      }
    `;
    updateSharedUI({ layoutCustomStyles });
    return () => updateSharedUI({ layoutCustomStyles: '' });
  }, []);

  const handleButtonClick = action => {
    if (action.should_confirm) {
      return () =>
        showModal(
          ConfirmModal,
          {
            title: action.text,
            description:
              'Are you sure? If you do this action, there is no way to revert it on this page.',
            confirmText: 'Confirm',
            dismiss,
            onCancel: dismiss,
            onConfirm: () => {
              doParcelAction(action);
              dismiss();
            },
          },
          { ignoreOverlayDismiss: true }
        );
    }
    return () => doParcelAction(action);
  };

  const showGallery = id => () => {
    showModal(
      Gallery,
      {
        ...GalleryProps[GalleryPages.parcels],
        id,
        title: `Parcel ${id}`,
      },
      { keepPrev: true }
    );
  };

  const noDataAction = undefined;

  return (
    <ParcelEvaluationLayout
      evalProps={{
        data,
        handleButtonClick,
        actionIsLoading,
        hasEditPermission,
        showGallery,
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default ParcelEvaluation;
