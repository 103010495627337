import { downloadFile } from '../../../../../utils/utils';
import { FilesLayout } from '../components/FilesLayout';
import { filesGrid, filesHeaders, parcelKeys } from './variables';

const Files = ({ data }) => {
  const downloadParcelFile = item => () => {
    const url = item[parcelKeys.file.url];
    downloadFile(url);
  };
  return (
    <FilesLayout
      data={data}
      headers={filesHeaders}
      grid={filesGrid}
      downloadFile={downloadParcelFile}
    />
  );
};
export default Files;
