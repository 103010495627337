import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Icons } from '../../../components/atoms/Icon/Icon.options';
import { Constants, PropKeys } from '../../../constants';
import { useFileUpload } from '../../../providers/FileUploadProvider';
import { buildFileSelector } from '../../../providers/FileUploadProvider.utils';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useStore } from '../../../providers/StoreProvider';
import { useDeleteUserImage } from '../api/deleteImage';
import { useUpdateUserDetails } from '../api/updateUserDetails';
import { UserInfoLayout } from '../components/UserInfoLayout';
import {
  getUserDetailsInitialValues,
  userDetailFields,
  userDetailsValidationSchema,
} from './variables';

const folder = 'user_images';
const fileSelector = buildFileSelector(false);

const UserInfo = () => {
  const [initialValues, setInitialValues] = useState(
    getUserDetailsInitialValues({})
  );

  const { store, updateStore } = useStore();
  const { showSnackbarError, showSnackbarWarning, showSnackbarSuccess } =
    useSnackbar();
  const { handleDrop, handleImport, fileState, resetFileUpload } =
    useFileUpload();

  const resetFormRef = useRef(null);

  const { body, isLoading, isError, error, doUpdateDetails } =
    useUpdateUserDetails();

  const {
    isCompleted,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    doDelete: doDeleteImage,
    error: deleteError,
  } = useDeleteUserImage();

  useEffect(() => {
    if (!fileSelector) return;
    const handleDrop__ = e => {
      const userUpload = e.target.files;
      handleDrop(Constants.FileUpload.uploadType.image, true)([userUpload[0]]);
    };
    fileSelector.addEventListener('change', handleDrop__);
    return () => {
      fileSelector.removeEventListener('change', handleDrop__);
    };
  }, [fileSelector]);

  useEffect(() => {
    const userFileProvided = !!fileState.files?.length;
    const { isUploading, uploadSuccess, errorType } = fileState;

    if (errorType) {
      showSnackbarWarning(
        Constants.FileUpload.uploadTypeUtils.image[errorType]
      );

      resetFileUpload();
      return;
    }
    const startUpload = userFileProvided && !isUploading && !uploadSuccess;
    if (startUpload) {
      handleImport({ folder, url: 'user_profile/image' });
    }
    if (uploadSuccess) {
      const file = fileState?.files[0];
      updateStore({ [PropKeys.userImage]: URL.createObjectURL(file) });
      resetFileUpload();
      showSnackbarSuccess('Image succesfully changed');
    }
  }, [fileState]);

  useEffect(() => {
    if (deleteIsLoading || !isCompleted) return;
    if (deleteIsError) {
      showSnackbarError(deleteError);
      return;
    }
    updateStore({ [PropKeys.userImage]: '' });
    showSnackbarSuccess('Image succesfully deleted');
  }, [isCompleted, deleteIsLoading, deleteIsError]);

  useEffect(() => {
    if (body == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    const { values } = body;
    updateStore(values);
  }, [body, isLoading, isError]);

  const onSubmit = values => doUpdateDetails(values, initialValues);

  const avatarEditOptions = [
    {
      icon: Icons.Upload,
      text: 'Upload image',
      onClick: () => fileSelector.click(),
    },
    {
      icon: Icons.Trash2,
      text: 'Remove image',
      onClick: doDeleteImage,
      disabled: !store[PropKeys.userImage],
    },
  ];

  useEffect(() => {
    const initialValues = getUserDetailsInitialValues(store);
    setInitialValues(initialValues);
    if (resetFormRef.current) resetFormRef.current(initialValues);
  }, [store]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userDetailsValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ isValid, resetForm, dirty }) => {
        resetFormRef.current = resetForm;
        return (
          <Form>
            <UserInfoLayout
              username={store[PropKeys.username] ?? ' '}
              image={store[PropKeys.userImage]}
              avatarEditOptions={avatarEditOptions}
              fields={userDetailFields}
              onCancel={resetForm}
              cancelDisabled={!dirty || isLoading}
              submitDisabled={!isValid || !dirty || isLoading}
              isLoading={isLoading}
              imageAvatarLoading={fileState.isUploading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default UserInfo;
