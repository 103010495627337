import { Route, Routes } from 'react-router-dom';
import Cases from './Cases';

export const CasesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Cases />} />
    </Routes>
  );
};
