import { useEffect, useRef } from 'react';
import { mergeFilterStateAndConsts } from '../../../../../components/organisms/Filters/utils';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { Utils } from '../../../../../constants';
import { useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import ContactSupportButton from '../../../components/molecules/ContactSupportButton/ContactSupportButton';
import { Labels, Pages } from '../../../routes/variables';
import { useGetPerformance } from '../api/getPerformance';
import AnalyticsLayout from '../components/AnalyticsLayout';
import { DC, allOptions, filtersConstants, titles } from '../variables';
import { dispatchAction, useAnalyticsdReducer } from './Analytics.hook';

const { SELF: ADR, VIEW_ANALYTICS_DANGEROUS_GOODS } = PermissionKeys.Brand.ADR;
const { SELF: LQ_PERMISSION, VIEW_ANALYTICS_LQ_DEPOT_AREA } =
  PermissionKeys.Brand.LQ;
const {
  SELF: SORTER_INSIGHTS,
  VIEW_DETECTION_DETAILS_STATS,
  VIEW_PARCEL_COUNT_STATS,
  VIEW_OVER345_SECONDS_STATS,
  VIEW_PROCESSING_TIME_STATS,
  VIEW_COUNTERFEIT_STATS,
  VIEW_RETURN_STATS,
  VIEW_SHIPPING_LABEL_STATS,
} = PermissionKeys.Brand.SORTER_INSIGHTS;

const Analytics = () => {
  let options;
  let ownOptions;
  let filtersIsError;

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useAnalyticsdReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission_ADR = permission => _(ADR, permission);
  const hasPermission_LQ = permission => _(LQ_PERMISSION, permission);
  const hasPermission_SORTER_INSIGHTS = permission =>
    _(SORTER_INSIGHTS, permission);

  const resetFormRef = useRef(null);

  const dispatch = dispatchAction(_dispatch);

  const {
    data,
    disabledData,
    isLoading,
    isError,
    refresh: refreshTable,
  } = useGetPerformance({
    searchParams,
  });

  const refetchFilters = Utils.emptyFunction;

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable,
    refetchFilters,
  });

  const filtersState = {
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
      fullWidth: true,
    },
    [DC.REFRESH]: { onClick: refreshView },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  useEffect(() => {
    const headerChildren = (
      <TablePageHeader
        title={Labels[Pages.analytics]}
        formInitialValues={formInitialValues}
        resetFormRef={resetFormRef}
        setFieldValueRef={null}
        hasViewPermission={true}
        searchParams={searchParams}
        filters={filters}
        isLoading={isLoading}
        endSlot={<ContactSupportButton />}
      />
    );

    setTimeout(() => setHeaderChildren(headerChildren), 0);
  }, [searchParams]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  return (
    <AnalyticsLayout
      data={data}
      disabledData={disabledData}
      overviewEnabled={hasPermission_SORTER_INSIGHTS(VIEW_PARCEL_COUNT_STATS)}
      detectionDetailsEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_DETECTION_DETAILS_STATS
      )}
      parcelCountEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_PARCEL_COUNT_STATS
      )}
      requestsSecondsEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_OVER345_SECONDS_STATS
      )}
      processingTimeEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_PROCESSING_TIME_STATS
      )}
      counterfeitsEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_COUNTERFEIT_STATS
      )}
      returnsEnabled={hasPermission_SORTER_INSIGHTS(VIEW_RETURN_STATS)}
      lqEnabled={hasPermission_ADR(VIEW_ANALYTICS_DANGEROUS_GOODS)}
      depotEnabled={hasPermission_LQ(VIEW_ANALYTICS_LQ_DEPOT_AREA)}
      hostName={data?.shippingLabels?.hostName ?? 'Host'}
      shippingLabelsEnabled={hasPermission_SORTER_INSIGHTS(
        VIEW_SHIPPING_LABEL_STATS
      )}
      dangerousGoodsEnabled={hasPermission_ADR(VIEW_ANALYTICS_DANGEROUS_GOODS)}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default Analytics;
