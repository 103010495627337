import { useEffect, useRef, useState } from 'react';
import { useModal } from '../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../utils/useDeepCompareEffect';
import { downloadFile } from '../../../../utils/utils';
import Modal from '../../Modal/Modal';
import { useGetImages } from '../api/getImages';
import GalleryLayout from '../components/GalleryLayout';

const maxZoom = 12.5;

const Gallery = ({
  id,
  url,
  idsKey,
  formatter,
  initialSelected = 0,
  images: externalImages,
  thumbnails: externalThumbnails,
  customImagesProps = {},
  appendRequested,
  title,
  galleryAliasExtractor,
}) => {
  const [selected, setSelected] = useState(initialSelected);
  const [rotation, setRotation] = useState(0);
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [images, setImages] = useState(externalImages ?? []);
  const [thumbnails, setThumbnails] = useState(
    externalImages ?? externalThumbnails ?? []
  );
  const [showHeaderBackground, setShowHeaderBackground] = useState(0);

  const [thumbnailGalleryType, setThumbnailGallerytype] = useState('static');

  const zoomRef = useRef(null);

  const { showSnackbarError, showSnackbarWarning } = useSnackbar();
  const { dismiss } = useModal();

  useEffect(() => {
    const updateShowHeaderBG = () => {
      const zoom = zoomRef?.current.getZoom();
      setShowHeaderBackground(zoom > 1.25);
    };

    const intervalId = setInterval(updateShowHeaderBG, 250);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const escapeDismiss = e => {
      const { code } = e;
      if (code === 'ArrowLeft') handleSelect('prev');
      if (code === 'ArrowRight') handleSelect('next');
      if (code === 'Escape') dismiss();
    };
    document.addEventListener('keydown', escapeDismiss);

    return () => document.removeEventListener('keydown', escapeDismiss);
  }, [images, selected]);

  const {
    data: { images: fetchedImages, thumbnails: fetchedThumbnails },
    isLoading,
    isError,
    error,
    getImages,
  } = useGetImages({ id, url, customImagesProps, idsKey, formatter });

  useEffect(() => {
    if (!externalImages || appendRequested) getImages();
  }, []);

  useDeepCompareEffect(() => {
    if ((!!externalImages && !appendRequested) || fetchedImages == null) return;

    if (appendRequested) {
      setImages(x => {
        const allImages = [...x, ...fetchedImages];
        if (!allImages.length) noImagesDismiss();
        return allImages;
      });
      setThumbnails(x => [...x, ...fetchedThumbnails]);
    } else {
      if (!fetchedImages.length) noImagesDismiss();
      setImages(fetchedImages);
      setThumbnails(fetchedThumbnails);
    }
  }, [fetchedImages, fetchedThumbnails]);

  useEffect(() => {
    if (!isError) return;
    showSnackbarError(error);
    dismiss();
  }, [isError]);

  useEffect(() => {
    const thumbnailGalleryType = thumbnails.length > 7 ? 'rotating' : 'static';
    setThumbnailGallerytype(thumbnailGalleryType);
  }, [thumbnails]);

  const noImagesDismiss = () => {
    showSnackbarWarning('No images found');
    dismiss();
  };

  const handleSelect = action => {
    const handle = number => {
      if (number === selected) return;
      setSelected(number);
      zoom('out')();
      setRotation(unSpinningRotation);
      setImageIsLoading(true);
    };
    if (action === 'next') {
      const atEnd = selected === images.length - 1;
      handle(atEnd ? 0 : selected + 1);
    }

    if (action === 'prev') {
      const atStart = selected === 0;
      handle(atStart ? images.length - 1 : selected - 1);
    }

    if (typeof action === 'number') handle(action);
  };

  const zoom = direction => () => {
    if (zoomRef.current == null) return;
    const zoomer = zoomRef.current;

    if (direction === 'in') zoomer.zoomIn(0.5);
    if (direction === 'out') zoomer.reset();
  };

  const rotate = degree => () => setRotation(rotation + degree);

  const unSpinningRotation = rotation => {
    const remainder = rotation % 360;
    switch (remainder) {
      case 0:
        return rotation;
      case 90:
      case -270:
        return rotation - 90;
      case 180:
      case -180:
        return rotation - 180;
      case 270:
      case -90:
        return rotation + 90;
      default:
        return 0;
    }
  };

  const handleImageLoad = () => setImageIsLoading(false);

  const handleDownload = e => {
    e?.stopPropagation();
    downloadFile(images[selected]);
  };

  return (
    <GalleryLayout
      title={title}
      galleryAliasExtractor={galleryAliasExtractor}
      zoomRef={zoomRef}
      handleSelect={handleSelect}
      thumbnails={thumbnails}
      thumbnailGalleryType={thumbnailGalleryType}
      image={images[selected]}
      rotation={rotation}
      maxZoom={maxZoom}
      imageIsLoading={imageIsLoading}
      getImagesLoading={isLoading}
      selected={selected}
      noOfImages={images.length}
      onLoad={handleImageLoad}
      rotate={rotate}
      zoom={zoom}
      download={handleDownload}
      dismiss={dismiss}
      showHeaderBackground={showHeaderBackground}
    />
  );
};

export default Modal(Gallery, false, {
  centerStyles: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(max(700px, 100vh))',
    position: 'relative',
  },
  overlayStyles: { cursor: 'unset' },
});
