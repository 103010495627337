import { useEffect, useState } from 'react';
import { Layout } from '../../../platform/brand/components/templates/Layout';
import { useReauth } from '../../../providers/AuthProvider';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import { noNetworkDisplay } from './variables';

const ConnectionErrorPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { doReauth } = useReauth();

  useEffect(() => {
    if (isLoading) handleReauth();
  }, [isLoading]);

  const onClick = () => setIsLoading(true);

  const handleReauth = async () => {
    await doReauth();
    setIsLoading(false);
  };

  const refreshButton = {
    text: 'Try again',
    onClick,
    size: '_M',
    variant: 'Primary',
    isLoading,
    disabled: isLoading,
  };

  return (
    <Layout>
      <NoSearchResults {...noNetworkDisplay} button={refreshButton} />
    </Layout>
  );
};
export default ConnectionErrorPage;
