import { Route, Routes } from 'react-router-dom';
import BrandDetails from './BrandDetails';
import AdminBrands from './AdminBrands';

export const AdminBrandsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AdminBrands />} />
    </Routes>
  );
};

export const AdminBrandsDetailsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<BrandDetails />} />
    </Routes>
  );
};
