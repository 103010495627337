import { useEffect } from 'react';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { CheckInInstructionLayout } from '../components/CheckInInstructionLayout';

const {
  OFFICE_PARCEL_PROCESSING: { SELF, VIEW_PARCELS },
} = PermissionKeys.Facilitator;

const CheckInInstruction = ({ isLoading, imageIsLoading, details }) => {
  const { dismiss } = useModal();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (!hasPermission(SELF, VIEW_PARCELS)) dismiss();
  }, []);

  return (
    <CheckInInstructionLayout
      isLoading={isLoading}
      details={details}
      imageIsLoading={imageIsLoading}
      onTimerEnd={dismiss}
      timerDuration={3}
    />
  );
};

export default Modal(CheckInInstruction);
