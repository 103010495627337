import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Layout } from '../../style';

export const Container = styled.div`
  gap: ${Layout.Spacing._XS};
  width: 100%;
  justify-content: flex-end;
  ${Styles.flex};
`;
