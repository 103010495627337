export const format = data => {
  return data.map(activity => formatActivity(activity));
};

const formatActivity = activity => {
  const {
    supID: itemId,
    suplContent: message,
    brImage: image,
    brName: name,
    createdAt: time,
  } = activity;

  return { image, message, name, itemId, time };
};
