import { Input } from '../../../../../components/molecules';
import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import Attachments from '../../../../../components/organisms/Attachments/Attachments';
import { displayType } from '../../../../../components/organisms/Attachments/variables';
import { knowledgeBaseKeys } from '../controllers/variables';
import { AddCharacteristicsButton } from './molecules';
import {
  AuthenticityBadge,
  Characteristics,
  Details,
  InstructionDescription,
  Links,
} from './organisms/organisms';
import {
  InstructionContainer,
  ItemDescripitionCSS,
  Major,
  Minor,
} from './style';

export const InstructionLayout = ({
  instructionId,
  username,
  image,
  brandName,
  brandImage,
  date,
  attachments,
  links,
  initialValues,
  fields,
  isEditing,
  toggleEditing,
  allowInstructionEditing,
  updateInstruction,
  updateIsLoading,
  isLoading,
  showAddLinks,
  editLink,
  showAddCharacteristic,
  characteristics,
  authenticityBadgeColors,
  instructionTypeLabels,
  infoIconDescription,
  showCharacteristicGallery,
  showEditCharacteristic,
  authenticityBadgeIcons,
  hasCharacteristicEditPermission,
  hasAttachmentEditPermission,
  hasAttachmentDeletePermission,
  hasLinkEditPermission,
}) => {
  return (
    <>
      {isLoading ? (
        <TableLoader />
      ) : (
        <InstructionContainer>
          <Major>
            <InstructionDescription
              initialValues={initialValues}
              fields={fields}
              isEditing={isEditing}
              toggleEditing={toggleEditing}
              allowEditing={allowInstructionEditing}
              updateInstruction={updateInstruction}
              updateIsLoading={updateIsLoading}
            />
            <Characteristics
              characteristics={characteristics}
              instructionTypeLabels={instructionTypeLabels}
              infoIconDescription={infoIconDescription}
              showCharacteristicGallery={showCharacteristicGallery}
              showEditCharacteristic={showEditCharacteristic}
              beforeElement={(file, index) => (
                <AuthenticityBadge
                  file={file}
                  color={authenticityBadgeColors[file.authenticity.value]}
                  selectedOption={file.authenticity}
                  icon={authenticityBadgeIcons[file.authenticity.value]}
                  readOnly
                />
              )}
              afterElement={(file, index) => (
                <ItemDescripitionCSS>
                  <Input
                    type="textarea"
                    name={String(index)}
                    placeholder="Item description"
                    readOnly
                  />
                </ItemDescripitionCSS>
              )}
            />
            {hasCharacteristicEditPermission && (
              <AddCharacteristicsButton onClick={showAddCharacteristic} />
            )}
          </Major>
          <Minor>
            <Details
              username={username}
              image={image}
              brandName={brandName}
              brandImage={brandImage}
              date={date}
            />
            <Attachments
              route="knowledge_base"
              folder="instruction_files"
              id={instructionId}
              attachmentIdKey={knowledgeBaseKeys.attachmentId}
              urlKey={knowledgeBaseKeys.attachmentURL}
              nameKey={knowledgeBaseKeys.attachmentName}
              attachments={attachments}
              displayType={displayType.unboxed}
              hasEditPermission={hasAttachmentEditPermission}
              hasDeletePermission={hasAttachmentDeletePermission}
            />
            <Links
              links={links}
              showAddLinks={showAddLinks}
              handleEdit={editLink}
              hasEditPermission={hasLinkEditPermission}
            />
          </Minor>
        </InstructionContainer>
      )}
    </>
  );
};
