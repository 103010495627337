import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects, Layout, UtilityColors } from '../../style';

const defaultHeaderHeight = height => height ?? '40px';

const Cell = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-left: ${Layout.Spacing._XS};
  background: ${({ rowSelected }) =>
    rowSelected ? Colors.Neutral._100 : Colors.Sharp.White};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  * {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  }
`;

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  z-index: 0;
  ${Styles.flexColumn};
`;

export const TableShadow = styled.div`
  height: 100%;
  max-height: ${props => props.maxShadowHeight}px;
  border-radius: ${Effects.Border_Radius._2XS};
  box-shadow: ${Effects.Box_Shadow._M};
  ${Styles.absoluteOveray};
`;

export const TableContainer = styled.div`
  position: relative;
  color: ${UtilityColors.Text};
  border-radius: 6px;
  height: 0;
  flex-grow: 1;
  overflow: ${props => (props.selection ? 'hidden' : 'auto')};
`;

export const TableBody = styled.div`
  justify-items: start;
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: grid;
  position: sticky;
  top: 0;
  z-index: 10;
  ${({ grid }) => grid};
  grid-template-rows: ${({ grid }) => defaultHeaderHeight(grid?.headerHeight)};
`;

export const baseTransition = arg => Effects.Transition.TableExpand(arg);
export const transition = baseTransition();
export const transitionOnly = (...args) =>
  args.map(arg => baseTransition(arg)).join(',');

export const TableRow = styled.div`
  display: grid;
  height: unset;
  width: 100%;
  z-index: 5;
  position: relative;
  ${({ grid }) => grid};
  transform: translateY(
    ${({
      grid,
      rowIsSelected,
      selected,
      beforeSelected,
      afterSelected,
      scrollHideUp,
      scrollHideDown,
    }) =>
      rowIsSelected
        ? selected || beforeSelected
          ? `calc(${scrollHideUp}px + ${defaultHeaderHeight(
              grid?.headerHeight
            )})`
          : afterSelected
          ? `calc(${scrollHideDown}px)`
          : 0
        : 0}
  );
  transition: ${transition};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  > div:nth-last-of-type(2) {
    padding-right: ${Layout.Spacing._XS};
  }
`;

export const Th = styled(Cell)`
  border-bottom: 1px solid ${Colors.Neutral._200};
  span {
    z-index: 1;
  }
`;

export const Td = styled(Cell)``;

export const SeparatorCSS = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  grid-column: 1/-1;
  height: 1px;
`;

export const separatorColor = Colors.Neutral._200;

export const ExpandItem = styled.div`
  overflow: auto;
  position: absolute;
  width: 100%;
  background: ${Colors.Neutral._200};
  z-index: ${({ expanded, inTransition }) =>
    expanded && !inTransition ? 10 : 0};
  top: ${({ offsetTop, rowHeight }) => `calc(${offsetTop}px + ${rowHeight})`};
  height: ${({ expanded, inTransition, grid, rowHeight }) =>
    expanded || inTransition
      ? // rowHeight should be grid?.rowHeight
        `calc(100% - ${defaultHeaderHeight(grid?.headerHeight)} - ${rowHeight})`
      : 0};
  transform: translateY(
    ${({ expanded, scrollHideUp, grid }) =>
      expanded
        ? `calc(${scrollHideUp}px + ${defaultHeaderHeight(grid?.headerHeight)})`
        : 0}
  );
  opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  transition: ${transitionOnly('transform', 'opacity')};
`;

const quantityBoxSizeProps = {
  _M: {
    width: Layout.Spacing._S,
    height: Layout.Spacing._S,
    padding: Layout.Spacing._3XS,
  },
  _XS: {
    width: Layout.Spacing._XS,
    height: Layout.Spacing._XS,
    padding: Layout.Spacing._3XS,
  },
};

export const ProductQuantityBox = styled.div`
  min-width: ${({ size }) => quantityBoxSizeProps[size].width};
  height: ${({ size }) => quantityBoxSizeProps[size].height};
  padding: 0 ${({ size }) => quantityBoxSizeProps[size].padding};
  border-radius: ${Effects.Border_Radius._2XS};
  background: ${({ color }) => color ?? Colors.Neutral._200};
  ${Styles.flexCenter};
`;
