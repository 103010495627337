import { parseStringModifiers } from '../../../utils/utils';

export const disabledText = {
  _1Row: 'To visualize this data, you need to have access to this service',
  _2Row: parseStringModifiers(
    'To visualize this data, you need to\nhave access to this service'
  ),
};

export const disabledText2Rows = disabledText._2Row;

export const disabledText1Row = disabledText._1Row;
