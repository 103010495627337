import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import { DISPATCH_CONSTANTS, Utils } from '../../../../../constants';

import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { tableComponents as logisticsParcelTableComponents } from '../../parcels/components';
import { parcelKeys } from '../../parcels/controllers/variables';

import { ticketBadgeTypes } from '../../../../../components/molecules/TicketTypeBadge/style';
import { RowSingleImage } from '../../../../../components/organisms/Table/Table.exports';
import { caseKeys } from '../../cases/controllers/variables';
import { productKeys } from '../../products/controllers/variables';
import { productListTableComponents } from '../components/molecules';

export const DC = {
  ...DISPATCH_CONSTANTS,
  BRANDS: 'BRANDS',
  BRAND: 'BRAND',
  FILTERS: 'FILTERS',
  SORTERS: 'SORTERS',
  STATUS: 'STATUS',
  PRIORITY: 'PRIORITY',
  TICKET_TYPE: 'TICKET_TYPE',
  SORT: 'SORT',
  ORDER: 'ORDER',
  OTHER_TITLE: 'OTHER_TITLE',
};

export const ticketTypes = {
  General: ticketBadgeTypes.General,
  Profile: 'Profile',
  Billing: 'Billing',
  Platform: 'Platform',
  Users: 'Users',
  Feature: 'Feature',
  Feedback: 'Feedback',
  Other: 'Other',
  // Case: 'Case',
  Parcel: ticketBadgeTypes.Parcel,
  Product: ticketBadgeTypes.Product,
};

export const setupChoice = {
  [ticketTypes.General]: {
    icon: Icons.UserCog,
    title: 'General support',
    text: 'Select this option to report a bug, request new feature or receive help with payments, plans, users.',
    buttonText: 'Select',
  },
  [ticketTypes.Parcel]: {
    icon: Icons.PackageCheck,
    title: 'Parcel support',
    text: 'Select this option to receive help or ask more information about a parcel.',
    buttonText: 'Select',
  },
  [ticketTypes.Product]: {
    icon: Icons.TagCheck,
    title: 'Product support',
    text: 'Select this option to receive help or ask more information about a product.',
    buttonText: 'Select',
  },
};

const logisticsParcelHeaders = {
  [parcelKeys.leftPadding]: '',
  [parcelKeys.parcelImages]: '',
  [parcelKeys.alias]: '',
  [parcelKeys.sideloadDate]: '',
  [parcelKeys.reason]: '',
  [parcelKeys.riskType]: '',
  [parcelKeys.status]: '',
  [parcelKeys.caseCount]: '',
};

const caseListHeaders = {
  [caseKeys.leftPadding]: '',
  [caseKeys.image]: '',
  [caseKeys.alias]: '',
  [caseKeys.productQuantity]: '',
  [caseKeys.sideloadDate]: '',
  [caseKeys.sorter]: '',
  [caseKeys.itemQuantity]: '',
  [caseKeys.statusShares]: '',
};

const productListHeaders = {
  [productKeys.leftPadding]: '',
  [productKeys.productImage]: '',
  [productKeys.productAlias]: '',
  [productKeys.productSideloadDate]: '',
  [productKeys.sorter]: '',
  [productKeys.productStatus]: '',
  [productKeys.productQuantity]: '',
};

const productListGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // product image //
  48px 
  // product alias //
  minmax(164px, 0.4fr)
  // sideload date //
  minmax(124px, 1fr)
  // sorter //
  minmax(200px, 1.2fr)
  // product status //
  minmax(120px, 1fr)
  // product quantity //
  minmax(40px, 0.5fr)
  `),
  gridAutoRows: '48px 0px',
  gridTemplateRows: 'unset',
};

const parcelListGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // scanned image //
  48px 
  // parcel ID //
  minmax(160px , 0.5fr)
  // sideload date //
  minmax(132px , 0.75fr)
  //  sideload reason //
  minmax(40px , 0.5fr)
  //  risk type //
  minmax(60px , 0.75fr)
  //  status //
  minmax(108px , 0.75fr)
  // cases //
  minmax(64px , 64px)`),
  gridAutoRows: '48px 0px',
  rowHeight: '48px',
};

export const caseListGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // left padding //
  16px 
  // case image //
  48px 
  // case alias //
  124px
  // product count //
  minmax(24px , 0.2fr)
  //  catch date //
  minmax(64px , 0.7fr)
  //   location //
  minmax(84px , 0.7fr)
  // item count //
  minmax(52px , 0.4fr)
  // status //
  minmax(112px , 0.75fr)`),
  gridAutoRows: '48px 0px',
  rowHeight: '48px',
};

export const searchHeaders = {
  [ticketTypes.Parcel]: logisticsParcelHeaders,
  [ticketTypes.Product]: productListHeaders,
};

export const searchComponents = {
  [ticketTypes.Parcel]: {
    ...logisticsParcelTableComponents({ showGallery: Utils.emptyFunction }),
    [parcelKeys.parcelImages]: {
      display: RowSingleImage(parcelKeys.photos)('_XS'),
    },
  },
  [ticketTypes.Product]: productListTableComponents,
};
export const searchGrid = {
  [ticketTypes.Parcel]: parcelListGrid,
  [ticketTypes.Product]: productListGrid,
};
