import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import InfoIcon from '../../../../../components/molecules/InfoIcon/InfoIcon';
import { actingBrandFilter } from '../../../../../components/organisms/ActingBrandSelection/utils';
import { extractSectionOptions } from '../../../../../components/organisms/Filters/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { useGetFilters } from '../../../features/reference_index/api/getFilters';
import { DC } from '../../../features/reference_index/controllers/variables';
import { useCreateNote } from '../api/createNote';
import { useCreateReference } from '../api/createReference';
import { ReferenceEntryLayout } from '../components/ReferenceEntryLayout';
import {
  editingInfoDescription,
  editingTitle,
  infoDescription,
  newReferenceLayout,
  referenceIndexKeys,
  saveText,
  title,
  views,
} from './variables';

const { SELF: REFERENCES, CREATE_REFERENCES } = PermissionKeys.Brand.REFERENCES;

const view = views.manual;

const initialValues = {};
newReferenceLayout.referenceFields.forEach(
  field => (initialValues[field] = '')
);

const fields = newReferenceLayout.referenceFields;

const ReferenceEntry = ({
  onCreateSuccess = Utils.emptyFunction,
  isAdmin = false,
  data: referenceData,
}) => {
  const isEditing = referenceData != null;
  const [createSuccess, setCreateSuccess] = useState(false);
  const [_, toggle] = useToggle(false);

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { permissions } = usePermissions();

  const { data, body, actions, isLoading, isError, error, doCreate } =
    useCreateReference({ isAdmin, isEditing });
  const { doCreateNote } = useCreateNote(isAdmin)({});

  const { data: options } = useGetFilters({ ignore: false, isAdmin });

  useEffect(() => {
    if (isLoading || !body) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const { [referenceIndexKeys.additionalDetails]: note } = body;
    const { seID: id } = data ?? {};
    if (note && id) createNote({ note, id });

    dismiss();
    // TODO: resets, but typed values persist in input field
    actions.resetForm();
    showSnackbarSuccess('Reference added to database');
    onCreateSuccess();
  }, [body, isLoading, isError]);

  useEffect(() => {
    if (options == null) return;
    const types = extractSectionOptions(options[DC.TYPE]) ?? [];
    newReferenceLayout.referenceFields.find(
      field => field.name === referenceIndexKeys.type
    ).options = types;
    toggle();
  }, [options]);

  const createNote = ({ note, id }) => {
    const body = { note };
    doCreateNote(body, `sender/${id}/notes`);
  };

  const handleDismiss = () => dismissAll();

  const onSubmit = (values, actions) => {
    doCreate(
      {
        ...values,
        [referenceIndexKeys.country]:
          values?.[referenceIndexKeys.country]?.value,
        brand_id: values?.['brand']?.value,
        [referenceIndexKeys.type]: values[referenceIndexKeys.type]?.value,
      },
      actions
    );
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues: referenceData,
        prevData: referenceData,
        // mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  let val = newReferenceLayout.validationSchema;
  if (isAdmin) val = val.concat(newReferenceLayout.weightsValidationSchema);
  return (
    <Formik
      initialValues={withPrevData(initialValues)}
      validationSchema={val}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount={isAdmin}
    >
      {({ isValid, setFieldValue, dirty, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });

        return (
          <Form>
            <ReferenceEntryLayout
              isAdmin={isAdmin}
              actingBrandProps={{
                selectOption,
                value: values['brand'],
                label: 'Brand',
                preselectedBrandId:
                  referenceData?.[referenceIndexKeys.brand.id],
                brandsFilter: isAdmin
                  ? Utils.unary
                  : actingBrandFilter(
                      permissions,
                      REFERENCES,
                      CREATE_REFERENCES
                    ),
                isAdmin,
              }}
              backToAdd={dismiss}
              onCancel={handleDismiss}
              disabled={!isValid || isLoading || !dirty}
              infoIcon={
                <InfoIcon
                  description={
                    isEditing
                      ? editingInfoDescription[view] ?? infoDescription[view]
                      : infoDescription[view]
                  }
                />
              }
              primaryClick={Utils.stopPropagation}
              referenceFields={newReferenceLayout.referenceFields}
              saveText={saveText({
                view,
                successfulUpload: createSuccess,
                isEditing,
              })}
              selectOption={selectOption}
              title={isEditing ? editingTitle[view] : title[view]}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(ReferenceEntry);
