import { useEffect, useRef, useState } from 'react';
import { useInlineStyle } from '../../../hooks/styleHook';
import { clickedBy, numWithCommas } from '../../../utils/utils';
import { Text } from '../../atoms';
import { Colors, FontKeys } from '../../style';
import { NoOfResults, PageSelection } from './Pagination.components';
import { Recipe } from './Pagination.style';

const ellipsis = '...';

const noOfOptionsShown = 6;

export const Pagination = ({
  page,
  itemsPerPage,
  setItemsPerPage,
  totalItems,
  paginate,
  options = [25, 50, 100],
  isLoading,
}) => {
  const [showPageOptions, setShowPageOptions] = useState(false);

  const resultsRef = useRef();

  const coreStyle = Recipe('default');
  const [style, setStyle] = useInlineStyle(coreStyle, {
    showPageOptions,
    isLoading,
  });

  useEffect(() => {
    const hidePageOptions = e => {
      if (clickedBy(resultsRef.current, e)) return;
      setShowPageOptions(false);
    };
    window.addEventListener('click', hidePageOptions);
    return () => window.removeEventListener('click', hidePageOptions);
  }, []);

  const lastPage = Math.ceil(totalItems / itemsPerPage);

  const pageOptionStart = 1;

  // 2 for fixed start and end of pagination. how much to spread to each side
  const spread = Math.floor((noOfOptionsShown - 2) / 2);

  const numbers = [];
  if (lastPage <= noOfOptionsShown) {
    numbers.push(pageOptionStart);
    for (let i = 2; i <= lastPage; i++) {
      numbers.push(i);
    }
  } else if (page <= pageOptionStart + spread) {
    for (let i = 1; i < noOfOptionsShown + 1; i++) {
      numbers.push(i);
    }
    numbers.push(lastPage);
    if (numbers[numbers.length - 2] < lastPage - 1)
      numbers[numbers.length - 2] = ellipsis;
  } else if (page >= lastPage - spread) {
    for (let i = lastPage; i > lastPage - noOfOptionsShown; i--) {
      numbers.unshift(i);
    }
    numbers.unshift(pageOptionStart);
    if (numbers[1] > pageOptionStart + 1) numbers[1] = ellipsis;
  } else {
    numbers.push(page);
    for (let i = 1; i < spread + 1; i++) {
      numbers.unshift(page - i);
      numbers.push(page + 1);
    }
    numbers.unshift(pageOptionStart);
    numbers.push(lastPage);

    if (numbers[1] > pageOptionStart + 1 || numbers[1] === numbers[0])
      numbers[1] = ellipsis;
    if (numbers[numbers.length - 2] < lastPage - 1)
      numbers[numbers.length - 2] = ellipsis;
  }

  const handlePagination = action => () => {
    if (isLoading) return;
    const isEllipsis = action === ellipsis;
    const tooFarBack = action < 1;
    const tooFarForward = action > lastPage;
    const ignore = isEllipsis || tooFarBack || tooFarForward;
    if (ignore) return;

    paginate(action);
  };

  const changeItemsPerPage = value => () => {
    if (isLoading) return;
    const numItems = parseInt(value);
    const start = (page - 1) * itemsPerPage + 1;

    const newPage = Math.floor(start / numItems) + 1;
    setItemsPerPage(numItems);
    paginate(newPage);
  };

  const resultsText = `Results: ${numWithCommas(
    1 + (page - 1) * itemsPerPage
  )} - ${numWithCommas(
    Math.min(page * itemsPerPage, totalItems)
  )} of ${numWithCommas(totalItems)}`;

  const toggleShowPageOptions = e => {
    if (isLoading) return;
    setShowPageOptions(x => !x);
  };

  return (
    <div style={style.container} id="pagination">
      <div style={style.results}>
        <Text
          text={resultsText}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._S}
          weight={FontKeys.Weight.Regular}
          color={Colors.Neutral._600}
        />
      </div>
      <PageSelection
        numbers={numbers}
        page={page}
        handlePagination={handlePagination}
        style={style}
      />
      <NoOfResults
        style={style}
        toggleShowPageOptions={toggleShowPageOptions}
        changeItemsPerPage={changeItemsPerPage}
        itemsPerPage={itemsPerPage}
        showPageOptions={showPageOptions}
        options={options}
        resultsRef={resultsRef}
      />
    </div>
  );
};
