import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { Text } from '../../atoms';
import { Colors } from '../../style';
import {
  BaseTimer,
  Circle,
  G,
  Path,
  SVG,
  TimerLabel,
} from './CountdownTimer.style';

const fullDashArray = 283;

const getCircleDashArray = ({ timeLeft, initialTime }) =>
  `${Math.max((timeLeft / initialTime) * fullDashArray, 0).toFixed(
    0
  )},  ${fullDashArray}`;

const CountdownTimer = ({
  time: initialTime,
  trackColor = Colors.Neutral._400,
  color = Colors.Primary._600,
  width = 8,
  size = 60,
  textProps,
  onTimerEnd = Utils.emptyFunction,
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [_, setTimePassed] = useState(0);
  const [strokeDashArray, setStrokeDashArray] = useState(
    `${fullDashArray} ${fullDashArray}`
  );

  const timerId = useRef('');

  useEffect(() => {
    const _timerId = startTimer();
    timerId.current = _timerId;
    setStrokeDashArray(
      getCircleDashArray({ initialTime, timeLeft: timeLeft - 1 })
    );
    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (timeLeft > 0) return;
    if (typeof onTimerEnd === 'function') onTimerEnd();

    try {
      clearInterval(timerId);
    } catch {}
  }, [timeLeft]);

  const startTimer = () => {
    const intervalId = setInterval(() => {
      setTimePassed(x => {
        const newTimePassed = x + 1;
        const newTimeLeft = initialTime - newTimePassed;
        if (newTimeLeft >= 0) setTimeLeft(newTimeLeft);

        setStrokeDashArray(
          getCircleDashArray({ initialTime, timeLeft: newTimeLeft - 1 })
        );
        return newTimePassed;
      });
    }, 1000);

    return intervalId;
  };

  return (
    <BaseTimer size={size}>
      <SVG viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <G>
          <Circle
            cx="50"
            cy="50"
            r="45"
            trackColor={trackColor}
            width={width}
          />
          <Path
            strokeDasharray={strokeDashArray}
            color={color}
            width={width}
            d="M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0"
          ></Path>
        </G>
      </SVG>
      <TimerLabel>{<Text text={timeLeft} {...textProps} />}</TimerLabel>
    </BaseTimer>
  );
};
export default CountdownTimer;
