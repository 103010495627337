import { extractSelectedValues } from '../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../hooks/fetchHook';
import { maskEntry } from '../../../../utils/utils';
import { adminServicesKeys as keys } from '../../features/admin_services/controllers/variables';
import { formatPermissions } from './utils';

export const useGetAdminServices = ({
  searchParams,
  ignore,
  with_permissions,
} = {}) => {
  const params = formatParams(searchParams, { with_permissions });

  const [
    {
      data: { services = [], service_count: serviceCount = 0 } = {},
      isLoading = true,
      isError,
      error,
    },
    _,
    __,
    refreshTable,
  ] = useFetchData(`admin_services`, {
    params,
    ignore,
  });

  return {
    data: {
      services: formatter(services, { with_permissions }),
      serviceCount,
    },
    isLoading,
    isError,
    error,
    refreshTable,
  };
};

export const formatParams = (params, { with_permissions }) => {
  const { SEARCH: search, SERVICE, PAGE, ITEMS_PER_PAGE } = params;

  const type = extractSelectedValues(SERVICE);

  const formattedParams = {
    search,
    type,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    with_permissions,
  };

  if (formattedParams?.status?.includes('Stopped'))
    formattedParams?.status?.push('Customs');
  if (
    formattedParams?.status?.includes('Customs') &&
    !formattedParams?.status?.includes('Stopped')
  )
    formattedParams.status = formattedParams?.status?.filter(
      x => x !== 'Customs'
    );

  return formattedParams;
};

const formatter = (data, { with_permissions }) => {
  maskEntry(data, mask);
  if (with_permissions)
    data.forEach(
      service => (service.permissions = formatPermissions(service.roles))
    );

  return data;
};

const mask = {
  serDetail: keys.description,
  serIcon: keys.icon,
  serID: keys.id,
  serName: keys.name,
  serType: keys.type,
  serKey: keys.systemName,
  brand_count: keys.brands.havingServiceCount,
  creator_name: keys.addedBy.name,
  creator_image: keys.addedBy.image,
  created_at: keys.addedAt,
  serrDetail: keys.role.detail,
  serrID: keys.role.id,
  serrKey: keys.role.key,
  serrName: keys.role.name,
  spKey: keys.permission.key,
  spName: keys.permission.name,
  spValue: keys.permission.value,
};
