import { DISPATCH_CONSTANTS } from '../constants';
import { cloneDeep, isValidDate } from '../lib/js';
import { getURLParams } from './utils';

export const DateTypes = {
  time12: 'time12',
  time24: 'time24',
  date: 'date',
  ddmmyy: 'ddmmyy',
  ddmmyyyy: 'ddmmyyyy',
  yyyymmdd: 'yyyymmdd',
  short: 'short',
  year: 'year',
  format12: 'format12',
  format24: 'format24',
};

export const formatDate = (dateString, type = DateTypes.date) => {
  try {
    let [day, month, year] = dateToUKFormat(dateString)?.split('/');

    const date = [day, month, year].join('/');

    const dateObj = isValidDate(new Date(dateString))
      ? new Date(dateString)
      : new Date(safariSafeDateString(dateString));

    let time = dateObj
      .toLocaleTimeString('en-US', { hour12: type !== DateTypes.format24 })
      .split(':');

    const amORpm = time.pop().slice(-2);
    time = time.join(':');

    switch (type) {
      case DateTypes.time12:
        return time + ' ' + amORpm;
      case DateTypes.time24:
        return time;
      case DateTypes.date:
        return date;
      case DateTypes.ddmmyy:
        return `${day}/${month}/${year.slice(2)}`;
      case DateTypes.ddmmyyyy:
        return `${day}/${month}/${year}`;
      case DateTypes.yyyymmdd:
        return `${year}-${month}-${day}`;
      case DateTypes.short:
        return shortMonths[parseInt(month)] + ' ' + day;
      case DateTypes.year:
        return year;
      case DateTypes.format24:
        return date + ' ' + time;
      case DateTypes.format12:
      default:
        return date + ' ' + time + ' ' + amORpm;
    }
  } catch {
    return null;
  }
};

const pad = n => {
  return n < 10 ? '0' + n : n;
};

const safariSafeDateString = dateString => {
  dateString = dateString.replace(/\.\d+$/, '').replace(/-/g, '/');
  return dateString;
};

export const dateToUKFormat = dateString => {
  if (dateString == null) throw Error('no null dates');

  let dateObj = new Date(dateString);

  if (!isValidDate(dateObj))
    dateObj = new Date(safariSafeDateString(dateString));

  if (!isValidDate(dateObj)) return null;

  return (
    pad(dateObj.getDate()) +
    '/' +
    pad(dateObj.getMonth() + 1) +
    '/' +
    dateObj.getFullYear()
  );
};

const shortMonths = [
  ' ',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const secondMS = 1000;
const minuteMS = secondMS * 60;
const hourMS = minuteMS * 60;
const dayMS = hourMS * 24;
const weekMS = dayMS * 7;
// Up to 30 days, round down to 4 weeks
// average out all months to 30.416... days.
const monthMS = (dayMS * 365) / 12;
const yearMS = dayMS * 365;

const today = 'Today';
const justNow = 'Just now';

export const calculateElapsedTime = (date, justDays = false) => {
  date = new Date(date);
  if (isNaN(date)) return null;
  const now = new Date();

  const elapsedMS = now - date;

  if (elapsedMS > yearMS) {
    const completedYears = Math.floor(elapsedMS / yearMS);
    const remainderMOnths = Math.round((elapsedMS % yearMS) / monthMS);

    return `${remainderMOnths > 3 ? 'over ' : ''} ${completedYears} year${
      completedYears > 1 ? 's' : ''
    }`;
  }
  if (elapsedMS > monthMS) {
    const completedMonths = Math.floor(elapsedMS / monthMS);

    return `${completedMonths} month${completedMonths > 1 ? 's' : ''}`;
  }
  if (elapsedMS > weekMS) {
    const completedWeeks = Math.floor(elapsedMS / weekMS);

    return `${completedWeeks} week${completedWeeks > 1 ? 's' : ''}`;
  }
  if (elapsedMS > dayMS || justDays) {
    const completedDays = Math.floor(elapsedMS / dayMS);
    if (!completedDays) return today;

    return `${completedDays} day${completedDays > 1 ? 's' : ''}`;
  }
  if (elapsedMS > hourMS) {
    const completedHours = Math.floor(elapsedMS / hourMS);

    return `${completedHours} hour${completedHours > 1 ? 's' : ''}`;
  }
  if (elapsedMS > minuteMS) {
    const completedMinutes = Math.floor(elapsedMS / minuteMS);

    return `${completedMinutes} minute${completedMinutes > 1 ? 's' : ''}`;
  }

  return justNow;
};

export const howLongAgo = (date, justDays = false) => {
  const elapsedTime = calculateElapsedTime(date, justDays);
  if (elapsedTime === today) return elapsedTime;
  if (elapsedTime === justNow) return justNow;

  return elapsedTime ? `${elapsedTime} ago` : '';
};

export const humanReadableDate = date => {
  if (date == null) return null;
  const dateString = new Date(date).toDateString();
  const [day, month, date_, year] = dateString.split(' ');
  return `${month} ${date_}, ${year}`;
};

export const humanFormalDate = date => {
  if (date == null) return null;

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  const day = new Date(date).getDate();
  let suffix = 'th';

  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  const [month, day_, year] = formattedDate.split(' ');

  const dayWithSuffix = day_.replace(/\b(\d{1,2})\b/, `$1${suffix}`);

  return `${month} ${dayWithSuffix} ${year}`;
};

export const formatInitValDates = date => {
  if (!date || !date.length) return null;
  return new Date(date);
};

export const firstDate = new Date('2021/3/1');

export const timeFrames = {
  getToday: () => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    return {
      start,
      end,
    };
  },

  getYesterday: () => {
    const start = new Date();
    start.setDate(start.getDate() - 1);
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(0, 0, 0, -1);
    return {
      start,
      end,
    };
  },

  getLastThreeDays: () => {
    const start = new Date();
    start.setDate(start.getDate() - 2);
    const end = new Date();
    return {
      start,
      end,
    };
  },

  getThisWeek: () => {
    const start = new Date();
    start.setDate(start.getDate() - (start.getDay() || 7) + 1);
    const end = new Date();
    return {
      start,
      end,
    };
  },

  getLastWeek: () => {
    const start = new Date();
    start.setDate(start.getDate() - start.getDay() - 6);
    const end = new Date();
    end.setDate(end.getDate() - end.getDay());

    return {
      start,
      end,
    };
  },

  getLast3Days: () => {
    const start = new Date();
    start.setDate(start.getDate() - 3);
    const end = new Date();

    return {
      start,
      end,
    };
  },

  getLast7Days: () => {
    const start = new Date();
    start.setDate(start.getDate() - 7);
    const end = new Date();

    return {
      start,
      end,
    };
  },

  getLast28Days: () => {
    const start = new Date();
    start.setDate(start.getDate() - 28);
    const end = new Date();

    return {
      start,
      end,
    };
  },

  getThisMonth: () => {
    let start = new Date();
    start = new Date(start.getFullYear(), start.getMonth(), 1);
    const end = new Date();
    return {
      start,
      end,
    };
  },

  getLastMonth: () => {
    let start = new Date();
    start = new Date(start.getFullYear(), start.getMonth() - 1, 1);
    let end = new Date();
    end = new Date(end.getFullYear(), end.getMonth(), 0);
    return {
      start,
      end,
    };
  },

  getLast3Months: () => {
    const start = new Date();
    start.setDate(start.getDate() - 90);
    const end = new Date();

    return {
      start,
      end,
    };
  },

  getLast6Months: () => {
    const start = new Date();
    start.setDate(start.getDate() - 180);
    const end = new Date();

    return {
      start,
      end,
    };
  },

  getThisQuarter: () => {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3) * 3;
    const start = new Date(today.getFullYear(), quarter, 1);
    const end = new Date(today.getFullYear(), quarter + 3, 0);

    return {
      start,
      end,
    };
  },

  getLastQuarter: () => {
    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3) * 3 - 3;
    const start = new Date(today.getFullYear(), quarter, 1);
    const end = new Date(today.getFullYear(), quarter + 3, 0);

    return {
      start,
      end,
    };
  },

  getThisYear: () => {
    const start = new Date();
    start.setFullYear(start.getFullYear(), 0, 1);
    const end = new Date();
    return {
      start,
      end,
    };
  },

  getLastYear: () => {
    const start = new Date();
    start.setFullYear(start.getFullYear() - 1, 0, 1);
    const end = new Date();
    end.setFullYear(end.getFullYear(), 0, 0);
    return {
      start,
      end,
    };
  },

  getAllTime: () => {
    const start = firstDate;
    const end = new Date();
    return {
      start,
      end,
    };
  },

  compareDates: (a, b, ignoreTime = true) => {
    if (!a || !b) return false;
    if (ignoreTime) {
      a.setHours(0, 0, 0);
      b.setHours(0, 0, 0);
    }
    return a.toString() === b.toString();
  },
};

const initialTimeParams = {
  from_date: '',
  to_date: '',
};

export const timeFrameIsSet = payload => {
  if (payload == null) return false;
  const { fromDate, toDate } = payload;
  const { from_date, to_date } = initialTimeParams;
  if ([fromDate, toDate, from_date, to_date].every(d => d == null)) return true;
  return fromDate == from_date && toDate == to_date;
};

export const formatQueryDate = date => {
  if (date == null || !date.length || dateToUKFormat(date) == null)
    return undefined;
  let splitDate = dateToUKFormat(date).split('/');
  let YYYYMMDD = splitDate.reverse();
  let formattedDate = YYYYMMDD.join('-');
  return formattedDate;
};

export const titleFromCalendar = ({
  searchParams,
  titles,
  key: TIME_FRAME,
}) => {
  const { label } = searchParams?.[TIME_FRAME] ?? {};
  if (label) return label;

  const { fromDate, toDate } = searchParams?.[TIME_FRAME]?.value ?? {};
  if (!fromDate || !toDate) return titles[TIME_FRAME];

  return `${humanReadableDate(fromDate)} - ${humanReadableDate(toDate)}`;
};

export const getTimeFrameUrlParams = params => {
  const {
    label,
    value: { fromDate, toDate },
  } = params;

  const timeFrameObject = { fromDate, toDate, label };

  const timeFrameURLParams = `?timeFrame=${JSON.stringify(timeFrameObject)}`;

  return timeFrameURLParams;
};

export const setTimeFrameUrlParams = (_, location, searchParams) => {
  const timeFrame = JSON.parse(getURLParams(location, 'timeFrame'));

  const { fromDate, toDate, label } = timeFrame;

  const timeFrameInitVals = cloneDeep(
    searchParams[DISPATCH_CONSTANTS.TIME_FRAME]
  );

  if (fromDate) timeFrameInitVals.value.fromDate = fromDate;
  if (toDate) timeFrameInitVals.value.toDate = toDate;
  if (fromDate || toDate) timeFrameInitVals.label = label;

  return timeFrameInitVals;
};

export const setTimeFrameToday = () => {
  const { start, end } = timeFrames.getToday();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'Today', value: { fromDate, toDate } };
};

export const setTimeFrameThisWeek = () => {
  const { start, end } = timeFrames.getThisWeek();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'This week', value: { fromDate, toDate } };
};

export const setTimeFrame3Days = () => {
  const { start, end } = timeFrames.getLast3Days();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'Last 3 days', value: { fromDate, toDate } };
};

export const setTimeFrame7Days = () => {
  const { start, end } = timeFrames.getLast7Days();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'Last 7 days', value: { fromDate, toDate } };
};

export const setTimeFrame28Days = () => {
  const { start, end } = timeFrames.getLast28Days();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'Last 28 days', value: { fromDate, toDate } };
};

export const setTimeFrame3Months = () => {
  const { start, end } = timeFrames.getLast3Months();
  const fromDate = formatQueryDate(String(start));
  const toDate = formatQueryDate(String(end));

  return { label: 'Last 3 Months', value: { fromDate, toDate } };
};

export const daysBetween = (startDate_, endDate_) => {
  const startDate = new Date(startDate_);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(endDate_);
  endDate.setHours(23, 59, 59, 999);

  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round((endDate - startDate) / millisecondsPerDay);
};

export const isSameDay = (a_, b_) => {
  const a = new Date(a_);
  a.setHours(0, 0, 0, 0);
  const b = new Date(b_);
  b.setHours(0, 0, 0, 0);
  return a.getTime() - b.getTime() === 0;
};
