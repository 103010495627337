import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants } from '../../../../../constants';
import { extractImageName } from '../../../../../features/file_upload/controllers/utils';
import { flow } from '../../../../../lib/js';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { dropdownValidationUnrequired } from '../../../../../utils/validationUtils';
import { useAddBrand } from '../api/addBrand';
import { useGetBrandAliases } from '../api/getBrandAliases';
import { useGetFilters } from '../api/getFilters';
import { AddBrandLayout } from '../components/AddBrandLayout';
import {
  DC,
  brandManagementKeys as keys,
  uploadStatusContent,
} from './variables';

const folder = 'brand_logos';

export const fieldNames = {
  name: 'name',
  alias: 'alias',
  group: 'group_name',
  industry: 'industry',
};

const fields = [
  {
    key: uniqueId(),
    name: fieldNames.name,
    label: 'Name',
    placeholder: 'Enter a brand name',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.alias,
    label: 'Alias',
    placeholder: 'Enter a brand alias',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.group,
    label: 'Group',
    placeholder: 'Enter a brand group',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.industry,
    label: 'Industry',
    placeholder: 'Select an industry',
    size: '_M',
    type: 'select',
    options: [],
  },
];

const addBrandInitialValues = {};
fields.forEach(field => (addBrandInitialValues[field.name] = ''));

const mapFieldsToResponse = {
  [fieldNames.name]: keys.brand.name,
  [fieldNames.alias]: keys.brand.alias,
  [fieldNames.group]: keys.brandGroup.name,
  [fieldNames.industry]: keys.industry,
};

const AddBrand = ({ onSuccess, mode = 'add', brandData }) => {
  const isEditing = mode === 'edit';
  const preexistingFile = isEditing ? brandData[keys.brand.image] : null;

  const [_, toggle] = useToggle(false);
  const [isContracted, toggleIsContracted] = useToggle(
    brandData?.[keys.brand.isContracted] ?? false
  );
  const [enableUpload, toggleEnableUpload] = useToggle(
    !isEditing || !preexistingFile
  );

  const { brandId } = useParams();

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
    refetchFilters,
  } = useGetFilters({ ignore: false });

  const {
    data: aliases,
    isLoading: aliasesIsLoading,
    isError: aliasesIsError,
  } = useGetBrandAliases({ ignore: isEditing });

  const addBrandValidationSchema = Yup.object().shape({
    [fieldNames.name]: Yup.string().required('Required'),
    [fieldNames.alias]: Yup.string()
      .notOneOf(isEditing ? [] : aliases, 'This alias is not allowed')
      .min(2, 'Alias must be at least 2 characters long')
      .required('Required'),
    [fieldNames.group]: Yup.string().notRequired('Required'),
    [fieldNames.industry]: dropdownValidationUnrequired,
  });

  const { body, isLoading, isError, error, doAddBrand } = useAddBrand({
    mode,
  });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess('Changes saved successfully');
    onSuccess();
  }, [body, isError, isLoading]);

  useEffect(() => {
    if (options == null || filtersIsLoading) return;
    const findIndustries = options =>
      options?.find(section => section?.section === DC.OTHERS)?.options ?? [];

    const removeEmpty = options =>
      options.filter(x => x?.value !== Constants.emptyKeyword);

    const getIndutries = flow([findIndustries, removeEmpty]);

    const industries = getIndutries(options[DC.INDUSTRY]);

    fields.find(field => field.name === fieldNames.industry).options =
      industries;
    toggle();
  }, [options]);

  useEffect(() => {
    fields.find(field => field.name === fieldNames.alias).readOnly = isEditing;
  }, []);

  useEffect(() => {
    return () => resetFileUpload();
  }, []);

  const onSubmit = values => {
    if (enableUpload && fileState.files?.length === 1)
      handleImport({
        folder,
        isPublic: true,
        parameterName: 'names',
        successCallback: ({ uploadNames }) => {
          showSnackbarSuccess('Attachments successfully uploaded');
          handleAddBrand(values, uploadNames);
          resetFileUpload();
        },
        errorCallback: () => {
          showSnackbarError('Error uploading attachments');
          resetFileUpload();
        },
      });
    else handleAddBrand(values);
  };
  const handleAddBrand = (values, uploadNames) => {
    const url = isEditing ? `admin_brand/${brandId}` : `admin_brands/create`;
    const body = {
      ...values,
      industry: values.industry?.value,
      is_contracted: isContracted,
    };

    const file = uploadNames?.[0] ?? brandData?.[keys.brand.image];

    body.image = file?.includes('http') ? extractImageName(file, folder) : file;

    doAddBrand(body, url);
  };

  const withPrevBrandData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: brandData,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const disabled =
    fileState.isUploading || isLoading || fileState.errorType !== null;
  // || (fileState.files?.length !== 1 && !preexistingFile);

  const nonFormikDirty = () => {
    if (isEditing) {
      if (enableUpload && fileState?.files?.length) return true;
      if (!!isContracted !== !!brandData[keys.brand.isContracted]) return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={withPrevBrandData(addBrandInitialValues)}
      validationSchema={addBrandValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, dirty }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });

        return (
          <Form>
            <AddBrandLayout
              isEditing={isEditing}
              brandFieldsProps={{
                fields,
                selectOption,
                selected: isContracted,
                toggleSelected: toggleIsContracted,
              }}
              brandDropzoneProps={{
                handleDrop: handleDrop(
                  Constants.FileUpload.uploadType.brand_details
                ),
                fileState,
                percentageUploaded: percentage,
                uploadStatusContent: uploadStatusContent({
                  fileState,
                  resetFileUpload,
                  preexistingFile,
                  enableUpload,
                }),
                multiple: false,
                enableUpload,
                toggleEnableUpload:
                  isEditing && preexistingFile && toggleEnableUpload,
              }}
              actionsProps={{
                disabled: !isValid || disabled || !(dirty || nonFormikDirty()),
                isLoading,
                primaryText: isEditing ? 'Save' : 'Add',
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddBrand);
