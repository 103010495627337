import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import Text from '../../atoms/Text/Text';
import { Colors, Effects, FontKeys, Layout } from '../../style';
import AnimatedIcon from '../AnimatedIcon/AnimatedIcon';

export const Container = styled.div`
  width: 400px;
  min-height: 244px;
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S};
  background: ${Colors.Sharp.White};
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const Title = ({ text, onCancel }) => {
  return (
    <Flex column relative gap="_S">
      <Flex justify="between">
        <Text
          text={text}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._L}
          weight={FontKeys.Weight.Semibold}
          color={Colors.Primary._600}
        />
        {onCancel ? (
          <AnimatedIcon name={Icons.X} onClick={onCancel} theme="neutral" />
        ) : (
          ''
        )}
      </Flex>
      <div />
      <Separator />
    </Flex>
  );
};

export const Description = ({ text, size }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Regular}
    />
  );
};
