import { Text } from '../../../../components/atoms';
import { Colors, FontKeys } from '../../../../components/style';
import { AnnouncementEmojiCSS } from '../style';

const BaseText = ({ text, color, weight = FontKeys.Weight.Regular }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={weight}
      color={color}
    />
  );
};

export const Title = ({ text, read }) => {
  const color = read ? Colors.Neutral._900 : Colors.Primary._600;
  return (
    <BaseText text={text} color={color} weight={FontKeys.Weight.Semibold} />
  );
};

export const Description = ({ text }) => {
  return <BaseText text={text} />;
};

export const Date = ({ text }) => {
  return <BaseText text={text} color={Colors.Neutral._500} />;
};

export const AnnouncementEmoji = ({ src }) => {
  return (
    <AnnouncementEmojiCSS>
      <img src={src} />
    </AnnouncementEmojiCSS>
  );
};

const BaseAnnouncementText = ({ text, color, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
      color={color}
    />
  );
};

export const AnnouncementTitle = ({ text, isRead }) => (
  <BaseAnnouncementText
    text={text}
    bold
    color={!isRead ? Colors.Primary._600 : undefined}
  />
);

export const AnnouncementDate = ({ text }) => (
  <BaseAnnouncementText text={text} color={Colors.Neutral._500} />
);
