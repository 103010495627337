import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { actingBrandFilter } from '../../../../../components/organisms/ActingBrandSelection/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { NavURIs, Pages } from '../../../routes/variables';
import { WizardDetailsLayout } from '../pages/WizardDetailsLayout';
import CharacteristicTypes from './CharacteristicTypes';
import { withStateVars } from './utils';
import {
  wizardInstructionFields,
  wizardInstructionInitialValues,
  wizardInstructionValidation,
} from './variables';

const { SELF: KNOWLEDGE_BASE, CREATE_KNOWLEDGE_BASE } =
  PermissionKeys.Brand.KNOWLEDGE_BASE;

const WizardDetails = ({ categories }) => {
  const navigate = useNavigate();
  const { dismiss, dismissAll, showModal } = useModal();
  const { permissions } = usePermissions();

  const showTypes = values => () =>
    showModal(
      CharacteristicTypes,
      { type: 'wizard', fieldValues: values, onSuccess },
      { stack: true }
    );

  const onSuccess = ({ instructionId }) => {
    const to = `${NavURIs[Pages.knowledge_base]}/${instructionId}`;
    navigate(to);
  };

  return (
    <Formik
      initialValues={wizardInstructionInitialValues}
      validationSchema={wizardInstructionValidation}
      validateOnMount
    >
      {({ isValid, setFieldValue, setTouched, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ => {
            setFieldValue(name, { value, label });
          };
        return (
          <WizardDetailsLayout
            actingBrandProps={{
              selectOption,
              value: values['brand'],
              label: 'Brand',
              brandsFilter: actingBrandFilter(
                permissions,
                KNOWLEDGE_BASE,
                CREATE_KNOWLEDGE_BASE
              ),
            }}
            onPrimary={showTypes(values)}
            onBack={dismiss}
            onCancel={() => dismissAll()}
            primaryText="Next"
            fields={withStateVars(wizardInstructionFields, categories)}
            selectOption={selectOption}
            setTouched={setTouched}
            disabled={!isValid}
          />
        );
      }}
    </Formik>
  );
};
export default Modal(WizardDetails);
