import { howLongAgo, humanReadableDate } from '../../../../../utils/timeUtils';

export const dateCreatedFormat = date => {
  return [
    'Created on:',
    <span style={{ marginRight: '4px' }} key={'key'}></span>,
    humanReadableDate(date),
  ];
};

export const lastMessageFormat = date => {
  return [
    'Last message:',
    <span style={{ marginRight: '4px' }} key={'key'}></span>,
    howLongAgo(date),
  ];
};

export const getAwaitingResponseMessage = (messages, resolved) => {
  if (resolved) return 'This ticket has been resolved';
  const lastMessage = messages[messages.length - 1];
  const lastMessageType = lastMessage?.type;
  const awaitingResponseMessage = `Awaiting ${
    lastMessageType === 'Brand' ? 'Facilitator' : 'your'
  } response`;

  return awaitingResponseMessage;
};
