import {
  referenceFieldsKeys,
  referenceIndexKeys,
} from '../../../features_public/reference_index/controllers/variables';

export const formikFormat = referenceInfo => {
  const formattedForEdit = {};

  referenceFieldsKeys
    .filter(key => key !== referenceIndexKeys.additionalDetails)
    .forEach(
      key =>
        (formattedForEdit[key] =
          referenceInfo[key]?.value ?? referenceInfo[key] ?? '')
    );

  return formattedForEdit;
};
