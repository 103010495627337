import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Image } from '../../../../../../components/atoms/Image/Image';
import { Separator } from '../../../../../../components/atoms/Separator/Separator';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import Button from '../../../../../../components/molecules/Button/Button';
import { Option } from '../../../../../../components/molecules/Dropdown/Dropdown.components';
import { Label } from '../../../../../../components/molecules/Label/Label';
import { Table } from '../../../../../../components/organisms/Table/Table';
import {
  QuantityBox,
  TableHeader,
} from '../../../../../../components/organisms/Table/Table.exports';
import {
  Colors,
  FontKeys,
  Layout,
  Size,
} from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { alphabetChars } from '../../../../../../utils/stringUtils';
import { processingKeys, stepKeys } from '../../controllers/variables';
import {
  CaseProductsTableHeaderText,
  InspectionStepTitle,
  ItalicizedSecond,
  SectionLetter,
} from '../atoms';
import {
  AddProductStep,
  BrandGridItem,
  SummaryDetail,
  caseProductsTableComponents,
  tableComponents,
} from '../molecules';
import {
  AddImageContainer,
  BlinkingCursor,
  BrandAlphabetSectionCSS,
  BrandGrid,
  BrandListCSS,
  CaseProductsCSS,
  CaseProductsTableHeaderCSS,
  CaseProductsTableHeaderContainer,
  CategoryAlphabetSectionCSS,
  CategoryListCSS,
  DeletPhotoButtonClickarea,
  DeletPhotoButtonContainer,
  NumberDisplayContainer,
  NumberDisplaySelectedBG,
  NumberPadGrid,
  PhotoQuantityValidatorContainer,
  ProductPhotoImageCSS,
  ProductPhotosGalleryGrid,
  ProductPhotosListCSS,
  ProductPhotosListScrollContainer,
  ZeroButton,
} from '../style';

export const InspectionStepHeader = ({ stepTitle, buttons }) => {
  return (
    <Flex w>
      <Flex
        justify="between"
        align="center"
        padding={`${Layout.Spacing._S} ${Layout.Spacing._M}`}
        relative
        w
      >
        <InspectionStepTitle text={stepTitle} />
        <Flex gap="_S">
          {buttons.map((button, index) => (
            <Button size="_M" {...button} key={index} />
          ))}
        </Flex>
        <Separator />
      </Flex>
    </Flex>
  );
};

export const CaseInfoTable = ({
  headers,
  data,
  rowAction,
  onRowExpand,
  grid,
  showGallery,
}) => {
  return (
    <Table
      headers={headers}
      data={data}
      grid={grid}
      rowAction={rowAction}
      onRowExpand={onRowExpand}
      components={tableComponents({ showGallery })}
    />
  );
};

export const CaseProducts =
  ({ headers, grid, rowAction }) =>
  item => {
    if (item == null) return <></>;
    return (
      <Flex w h padding={`0 ${Layout.Spacing._S}`} column>
        {CaseProductsTable({
          headers,
          data: item[processingKeys.products],
          grid,
          rowAction,
          own: true,
          brandName: item[processingKeys.products][0]?.brand,
        })}
        <Spacer w height="_S" />
      </Flex>
    );
  };

export const CaseProductsTable = ({
  headers,
  data,
  rowAction,
  grid,
  editProduct,
  productQuantity,
  disabled,
  showGallery,
}) => {
  return (
    <CaseProductsCSS>
      <CaseProductsTableHeader
        productQuantity={productQuantity}
        headers={headers}
        grid={grid}
      />
      <Table
        headers={headers}
        data={data}
        grid={grid}
        rowAction={rowAction}
        components={caseProductsTableComponents({
          editProduct,
          disabled,
          showGallery,
        })}
      />
      <Spacer height="_S" w />
    </CaseProductsCSS>
  );
};

const CaseProductsTableHeader = ({ productQuantity, grid, headers }) => {
  return (
    <CaseProductsTableHeaderContainer>
      <CaseProductTitle productQuantity={productQuantity} />
      <TableHeader grid={grid} headers={headers} />
    </CaseProductsTableHeaderContainer>
  );
};

const CaseProductTitle = ({ productQuantity: key }) => (
  <CaseProductsTableHeaderCSS own>
    <Flex align="center" gap="_2XS">
      <CaseProductsTableHeaderText text="PRODUCTS" own />
      {QuantityBox({
        size: '_XS',
        color: Colors.Sharp.White,
        textColor: Colors.Primary._600,
        key: 'key',
      })({ key })}
    </Flex>
  </CaseProductsTableHeaderCSS>
);

const displaySteps = [
  { step: stepKeys.selectBrand, stepLabel: 'Select a brand' },
  { step: stepKeys.category, stepLabel: 'Select a category' },
  { step: stepKeys.itemQuantity, stepLabel: 'Insert items quantity' },
  { step: stepKeys.photos, stepLabel: 'Add photos' },
  { step: stepKeys.summary, stepLabel: 'Summary' },
];

export const AddProductStepIndicator = ({
  step,
  displayValues,
  stepTo,
  validation,
}) => {
  const currentStepIndex = displaySteps.findIndex(s => s.step === step);
  return (
    <Flex column className="add-product-step-indicator" h>
      {displaySteps.map((dStep, index) => {
        const isLast = index === displaySteps.length - 1;
        return (
          <AddProductStep
            {...dStep}
            onClick={
              validation[dStep.step] ? stepTo(dStep.step) : Utils.emptyFunction
            }
            value={displayValues[dStep.step]}
            stepNumber={index + 1}
            selected={dStep.step === step}
            isLast={isLast}
            beforeCurrentStep={currentStepIndex > index}
            key={index}
          />
        );
      })}
    </Flex>
  );
};

// TODO: move this to shared package
export const AlphabetBar = ({ getRef, options }) => {
  return (
    <Flex w justify="between" className="alphabet-bar">
      {alphabetChars.map((char, index) => (
        <AlphabetChar
          char={char}
          getRef={getRef}
          hasEntries={options?.[index]?.[char]?.length > 0}
          key={index}
        />
      ))}
    </Flex>
  );
};

const AlphabetChar = ({ char, getRef, hasEntries }) => {
  const scrollToCharacterSection = () => {
    const ref = getRef(char);
    ref.current?.scrollIntoView();
  };

  return (
    // could be useful to hide alphabet buttons that are not available
    (hasEntries || true) && (
      <Flex
        onClick={scrollToCharacterSection}
        w0
        grow
        h
        justify="center"
        align="center"
      >
        <Text
          text={char}
          variant={FontKeys.Variant.Caption}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Regular}
          color={Colors.Neutral._600}
        />
      </Flex>
    )
  );
};

export const BrandList = ({
  brands: brandsByChar,
  setRef,
  selectBrand,
  chosenBrand,
}) => {
  return (
    <BrandListCSS>
      {(brandsByChar ?? []).map((brands, index) => (
        <BrandAlphabetSection
          brands={brands}
          setRef={setRef}
          selectBrand={selectBrand}
          chosenBrand={chosenBrand}
          key={index}
        />
      ))}
      <Spacer w height="_2XS" />
    </BrandListCSS>
  );
};

export const BrandAlphabetSection = ({
  brands: brandObject,
  setRef,
  selectBrand = Utils.emptyFunction,
  chosenBrand,
}) => {
  const [char, brands] = Object.entries(brandObject)[0];
  const charRef = setRef(char);
  return (
    <BrandAlphabetSectionCSS ref={charRef} hasEntries={brands.length > 0}>
      <Flex
        column
        relative
        padding={`0 0 ${Layout.Spacing._XS} ${Layout.Spacing._3XS}`}
      >
        <SectionLetter text={char} />
        <Separator />
      </Flex>
      <BrandGrid>
        {brands.map((brand, index) => (
          <BrandGridItem
            brand={brand}
            selectBrand={selectBrand}
            selected={
              chosenBrand?.[processingKeys.brand.id] ===
              brand[processingKeys.brand.id]
            }
            key={index}
          />
        ))}
      </BrandGrid>
    </BrandAlphabetSectionCSS>
  );
};

export const CategoryList = ({
  categories: categoriesByChar,
  setRef,
  selectCateogry,
  chosenCategory,
}) => {
  return (
    <CategoryListCSS>
      {(categoriesByChar ?? []).map((categories, index) => (
        <CategoryAlphabetSection
          categories={categories}
          setRef={setRef}
          selectCateogry={selectCateogry}
          chosenCategory={chosenCategory}
          key={index}
        />
      ))}
      <Spacer w height="_2XS" />
    </CategoryListCSS>
  );
};

export const CategoryAlphabetSection = ({
  categories: categoriesObject,
  setRef,
  selectCateogry,
  chosenCategory,
}) => {
  const [char, categories] = Object.entries(categoriesObject)[0];
  const charRef = setRef(char);
  return (
    <CategoryAlphabetSectionCSS
      ref={charRef}
      hasEntries={categories.length > 0}
    >
      <Flex
        column
        relative
        padding={`0 0 ${Layout.Spacing._XS} ${Layout.Spacing._3XS}`}
      >
        <SectionLetter text={char} />
        <Separator />
      </Flex>
      <Flex column gap="_2XS">
        {categories.map((category, index) => (
          <Option
            text={<ItalicizedSecond text={category.label} />}
            onClick={selectCateogry(category)}
            selected={category?.value === chosenCategory?.value}
            textStyles={{ size: FontKeys.Size._L }}
            highlight
            key={index}
          />
        ))}
      </Flex>
    </CategoryAlphabetSectionCSS>
  );
};

export const NumberDisplay = ({
  value,
  deleteNumber,
  toggleNumbersSelected,
  numbersSelected,
}) => {
  return (
    <NumberDisplayContainer onClick={toggleNumbersSelected}>
      <NumberDisplayValue
        text={value ?? ' '}
        numbersSelected={numbersSelected}
      />
      <BlinkingCursor />
      <DeleteButton onClick={deleteNumber} />
    </NumberDisplayContainer>
  );
};

const NumberDisplayValue = ({ text, numbersSelected }) => {
  return (
    <NumberDisplaySelectedBG numbersSelected={numbersSelected}>
      <Text
        text={text}
        variant={FontKeys.Variant.Heading}
        size={FontKeys.Size.H3}
        weight={FontKeys.Weight.Semibold}
      />
    </NumberDisplaySelectedBG>
  );
};

const nonZeroDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const NumberPad = ({ typeNumber }) => {
  return (
    <NumberPadGrid>
      {nonZeroDigits.map((digit, index) => (
        <NumberButton digit={digit} onClick={typeNumber(digit)} key={index} />
      ))}
      <ZeroButton>
        <NumberButton digit={0} onClick={typeNumber(0)} />
      </ZeroButton>
    </NumberPadGrid>
  );
};

const NumberButton = ({ digit, onClick }) => (
  <Button
    variant="Secondary"
    text={digit}
    size="_L"
    onClick={onClick}
    fullWidth
  />
);

const DeleteButton = ({ onClick }) => (
  <Button
    variant="Tertiary"
    text={''}
    size="_L"
    onClick={onClick}
    leftIcon={Icons.DeleteThin}
  />
);

export const PhotoQuantityValidator = ({
  valid,
  isContracted,
  accesorySlotEnd,
}) => {
  return (
    <PhotoQuantityValidatorContainer>
      <Icon
        name={valid ? Icons.CheckCircle : Icons.AlertCircle}
        color={valid ? Colors.Success._500 : Colors.Error._500}
        size={Size.Icon._S}
      />
      <Label
        text={`Add at least ${isContracted ? 3 : 1} photos`}
        size="_M"
        background={Colors.Neutral._100}
      />
      <Flex gap="_2XS" align="center" margin={'0 0 0 auto'}>
        {accesorySlotEnd}
      </Flex>
    </PhotoQuantityValidatorContainer>
  );
};

export const ProductPhotosGallery = ({
  dbImages,
  memoryImages,
  handleAddImages,
  deleteFileFromMemory,
  deleteFileFromDb,
  showGallery,
}) => {
  memoryImages = memoryImages ?? [];
  return (
    <ProductPhotosListScrollContainer>
      <ProductPhotosListCSS>
        <ProductPhotosGalleryGrid>
          <AddImage handleAddImages={handleAddImages} />
          {memoryImages.map((image, index) => (
            <ProductPhotoImage
              src={URL.createObjectURL(image)}
              file={image}
              onDelete={deleteFileFromMemory}
              key={image.name + index}
              onClick={showGallery(index)}
            />
          ))}
          {(dbImages ?? []).map((image, index) => (
            <ProductPhotoImage
              src={image.ppThumb}
              file={image}
              onDelete={deleteFileFromDb}
              key={index}
              onClick={showGallery(index + memoryImages.length)}
            />
          ))}
        </ProductPhotosGalleryGrid>
        <Spacer height="_S" w />
      </ProductPhotosListCSS>
    </ProductPhotosListScrollContainer>
  );
};

const AddImage = ({ handleAddImages }) => {
  return (
    <AddImageContainer>
      <input
        type="file"
        accept="image/*"
        capture
        multiple
        onChange={handleAddImages}
      />
      <Icon name={Icons.ImagePlus} color={Colors.Neutral._600} />
    </AddImageContainer>
  );
};

const ProductPhotoImage = ({ src, file, onDelete, onClick }) => {
  return (
    <ProductPhotoImageCSS onClick={onClick}>
      <Image src={src} />
      {/* probably prevent propagation, so we can show gallery on product photo image click */}
      <DeletPhotoButton
        onClick={e => {
          e.stopPropagation();
          onDelete(file)();
        }}
      />
    </ProductPhotoImageCSS>
  );
};

const DeletPhotoButton = ({ onClick }) => {
  return (
    <DeletPhotoButtonClickarea onClick={onClick}>
      <DeletPhotoButtonContainer>
        <Icon name={Icons.X} size={Size.Icon._2XS} />
      </DeletPhotoButtonContainer>
    </DeletPhotoButtonClickarea>
  );
};

export const SummaryDetails = ({ details }) => {
  return (
    <Flex column grow gap="_M">
      {details.map((detail, index) => (
        <SummaryDetail detail={detail} key={index} />
      ))}
    </Flex>
  );
};
