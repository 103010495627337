import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { extractNameFromStorageURL } from '../controllers/utils';
import {
  authenticityBadgeLabels,
  descriptionNames,
  knowledgeBaseKeys,
} from '../controllers/variables';

export const useGetInstruction = ({ instructionId, ignore }) => {
  const [{ data = [], isLoading = true, isError, error }, _, getInstruction] =
    useFetchData(`office_knowledge_base/${instructionId}`, {
      formatter: formatGetInstruction,
      ignore,
    });

  return { data, isLoading, isError, error, getInstruction };
};

export const formatGetInstruction = data => {
  maskEntry(data, mask);
  data.initialValues = {
    [descriptionNames.ruleName]: data[knowledgeBaseKeys.name],
    [descriptionNames.productCategory]: {
      value: data[knowledgeBaseKeys.categoryId],
      label: data[knowledgeBaseKeys.productCategory],
    },
    [descriptionNames.description]: data[knowledgeBaseKeys.description],
  };

  data[knowledgeBaseKeys.attachments] = data[knowledgeBaseKeys.attachments].map(
    attachment => {
      const url = attachment[knowledgeBaseKeys.attachmentURL];
      const it = {
        ...attachment,
        [knowledgeBaseKeys.attachmentURL]: url,
        [knowledgeBaseKeys.attachmentName]: extractNameFromStorageURL(url),
      };

      return it;
    }
  );

  data[knowledgeBaseKeys.characteristics].forEach(
    characteristic =>
      (characteristic.items = characteristic.items.map(item => {
        return {
          ...item,
          authenticity: {
            value: item[knowledgeBaseKeys.authenticityValue],
            label:
              authenticityBadgeLabels[
                item[knowledgeBaseKeys.authenticityValue]
              ],
          },
        };
      }))
  );

  data[knowledgeBaseKeys.customer.self] = {
    [knowledgeBaseKeys.customer.brand]: data[knowledgeBaseKeys.customer.brand],
    [knowledgeBaseKeys.customer.isContracted]:
      data[knowledgeBaseKeys.customer.isContracted],
    [knowledgeBaseKeys.customer.image]: data[knowledgeBaseKeys.customer.image],
    [knowledgeBaseKeys.customer.type]: data[knowledgeBaseKeys.hasSorters]
      ? 'Logistics'
      : 'Customer',
  };

  return data;
};

const mask = {
  attachments: knowledgeBaseKeys.attachments,
  iaID: knowledgeBaseKeys.attachmentId,
  iaName: knowledgeBaseKeys.attachmentURL,
  characteristics: knowledgeBaseKeys.characteristics,
  iciDetail: knowledgeBaseKeys.characteristicDescription,
  icType: knowledgeBaseKeys.characteristicType,
  iciImage: knowledgeBaseKeys.imageUrl,
  icID: knowledgeBaseKeys.characteristicId,
  iciID: knowledgeBaseKeys.characteristicItemId,
  iciType: knowledgeBaseKeys.authenticityValue,
  createdAt: knowledgeBaseKeys.date,
  kbContent: knowledgeBaseKeys.description,
  kbProduct: knowledgeBaseKeys.categoryId,
  kbTitle: knowledgeBaseKeys.name,
  links: knowledgeBaseKeys.links,
  ilUrl: knowledgeBaseKeys.link,
  ilID: knowledgeBaseKeys.linkId,
  product_name: knowledgeBaseKeys.productCategory,
  usDisplayName: knowledgeBaseKeys.addedByName,
  usImage: knowledgeBaseKeys.addedByImage,
  kbIsVisible: knowledgeBaseKeys.visibility,
  brName: knowledgeBaseKeys.customer.brand,
  brImage: knowledgeBaseKeys.customer.image,
  brIsContracted: knowledgeBaseKeys.customer.isContracted,
  has_sorters: knowledgeBaseKeys.hasSorters,
};
