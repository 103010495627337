import usePostData from '../../../../../hooks/postHook';

export const useCreateReport = () => {
  const [{ data, body, isLoading, isError, error }, doCreateReport] =
    usePostData('reports');

  return {
    data,
    body,
    isLoading,
    isError,
    error,
    doCreateReport,
  };
};
