import { Route, Routes } from 'react-router-dom';
import UserSettings from './UserSettings';

export const UserSettingsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<UserSettings />} />
    </Routes>
  );
};
