import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { timeFrameInitialFilterValues } from '../../../../../components/organisms/Filters/utils';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';

export const DC = {
  ...DISPATCH_CONSTANTS,
  REVIEW_STATUS: 'REVIEW_STATUS',
  BRAND: 'BRAND',
  LOCATION: 'LOCATION',
  OWN: 'OWN',
  OTHERS: 'OTHERS',
  RISK_TYPE: 'RISK_TYPE',
  PRODUCT_STATUS: 'PRODUCT_STATUS',
  PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
  TOGGLE_EXPAND: 'TOGGLE_EXPAND',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
    initial: timeFrameInitialFilterValues.last3days,
  },
  [DC.REVIEW_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
    param: 'status',
    initial: `status=${['No_Review'].join('+')}`,
  },
  [DC.BRAND]: {
    type: FilterTypes.dropdown,
    icon: Icons.Building,
    param: 'brand',
  },
  [DC.LOCATION]: {
    type: FilterTypes.dropdown,
    icon: Icons.MapPin,
  },
  [DC.PRODUCT_CATEGORY]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
  },
  [DC.PRODUCT_STATUS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Clock,
  },
  [DC.RISK_TYPE]: {
    type: FilterTypes.dropdown,
    icon: Icons.AlertCircle,
    param: 'riskType',
    initial: `riskType=${['Counterfeit Parcel', 'Illicit Parcel'].join('+')}`,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
  [DC.TOGGLE_EXPAND]: {
    type: FilterTypes.button,
    icon: Icons.ChevronDown,
  },
};

export const initialFilterValues = {
  [DC.LOCATION]: '',
  [DC.RISK_TYPE]: '',
  [DC.REVIEW_STATUS]: '',
  [DC.BRAND]: '',
  [DC.PRODUCT_CATEGORY]: '',
  [DC.PRODUCT_STATUS]: '',
};

export const titles = {
  [DC.TIME_FRAME]: 'All time',
  [DC.LOCATION]: 'Location',
  [DC.RISK_TYPE]: 'Risk type',
  [DC.REVIEW_STATUS]: 'Status',
  [DC.BRAND]: 'Customer',
  [DC.PRODUCT_CATEGORY]: 'Category',
  [DC.PRODUCT_STATUS]: 'Status',
};

export const productBrandIsContracted = 'productBrandIsContracted';
export const productBrandIsNotContracted = 'productBrandIsNotContracted';

export const allOptions = {
  [DC.RISK_TYPE]: { label: 'All risk types', value: Constants.all },
  [DC.REVIEW_STATUS]: { label: 'All status', value: Constants.all },
  [DC.BRAND]: [
    { label: 'All customers', value: Constants.all },
    {
      label: 'All non-contracted customers',
      value: productBrandIsNotContracted,
    },
    { label: 'All contracted customers', value: productBrandIsContracted },
  ],
  [DC.LOCATION]: { label: 'All locations', value: Constants.all },
  [DC.PRODUCT_CATEGORY]: { label: 'All categories', value: Constants.all },
  [DC.PRODUCT_STATUS]: { label: 'All status', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No parcels',
    description: 'As soon as we catch a new parcel, you will find it here',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all parcels',
  },
};
