import useFetchData from '../../../hooks/fetchHook';
import { formatNotifications } from '../formatters/formatNotifications';

export const useGetNotifications = params => {
  const [
    { data = [], isLoading, isError, error },
    getMoreNotifications,
    getMore,
  ] = useFetchData('notifications', {
    formatter: formatNotifications,
    params,
  });

  return { data, isLoading, isError, error, getMoreNotifications, getMore };
};
