import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { brandManagementKeys } from '../controllers/variables';

export const useGetOfficeBrands = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: { brands = [], brand_count: brandCount = 0 } = {},
      isLoading = true,
      isError,
    },
    getProducts,
    refreshTable,
  ] = useFetchData('admin_brands', {
    params,
    ignore,
    large: true,
  });

  return {
    data: {
      brands: formatter(brands, mask),
      brandCount,
    },
    isLoading,
    isError,
    getProducts,
    refreshTable,
  };
};

export const formatParams = params => {
  const {
    SEARCH: search,
    BRAND_GROUP,
    INDUSTRY,
    CONTRACTED,
    PAGE,
    ITEMS_PER_PAGE,
  } = params;

  const brand_group = extractSelectedValues(BRAND_GROUP);
  const industry = extractSelectedValues(INDUSTRY);
  const contracted = extractSelectedValues(CONTRACTED);

  const formattedParams = {
    search,
    brand_group,
    industry,
    contracted,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  };

  return formattedParams;
};

export const formatter = (data, mask) => {
  maskEntry(data, mask);
  return data;
};

export const mask = {
  bgName: brandManagementKeys.brandGroup.name,
  brAlias: brandManagementKeys.brand.alias,
  brGroup: brandManagementKeys.brandGroup.id,
  brID: brandManagementKeys.brand.id,
  brImage: brandManagementKeys.brand.image,
  brIndustry: brandManagementKeys.industry,
  brName: brandManagementKeys.brand.name,
  is_contracted: brandManagementKeys.brand.isContracted,
  client_count: brandManagementKeys.user.count,
  plan_end_date: brandManagementKeys.contract.endDate,
  plan_name: brandManagementKeys.plan.name,
  service_count: brandManagementKeys.service.count,
};
