const re = {
  upperRegex: /[A-Z]/,
  lowerRegex: /[a-z]/,
  digitRegex: /\d/,
  specialRegex: /([^\w\s]|[_])/,
};

const { upperRegex, lowerRegex, digitRegex, specialRegex } = re;

export const passwordTests = [
  {
    text: 'At least 1 uppercase character',
    key: 'At least 1 uppercase character',
    validator: password => upperRegex.test(password),
    valid: false,
  },
  {
    text: 'At least 1 lowercase character',
    key: 'At least 1 lowercase character',
    validator: password => lowerRegex.test(password),
    valid: false,
  },
  {
    text: 'At least 1 digit',
    key: 'At least 1 digit',
    validator: password => digitRegex.test(password),
    valid: false,
  },
  {
    text: 'At least 1 special character',
    key: 'At least 1 special character',
    validator: password => specialRegex.test(password),
    valid: false,
  },
  {
    text: 'At least 8 characters',
    key: 'At least 8 characters',
    validator: password => password.length >= 8,
    valid: false,
  },
  {
    text: 'At maximum 32 characters',
    key: 'At maximum 32 characters',
    validator: password => password.length <= 32,
    valid: false,
  },
];
