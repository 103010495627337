import { Constants, Utils } from '../../../constants';
import { parseStringModifiers } from '../../../utils/utils';
import { Icons } from '../../atoms/Icon/Icon.options';

export const attachmentUploadDescription =
  'Add any relevant files to save as attachments.';

const errorText = {
  [Constants.FileUpload.extensionError]: parseStringModifiers(
    Constants.FileUpload.uploadTypeUtils.knowledge_base.extensionError
  ),
  [Constants.FileUpload.uploadError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.unexpectedError]: parseStringModifiers(
    `There was an error uploading this file.\nPlease try again.`
  ),
  [Constants.FileUpload.fileSizeError]: parseStringModifiers(
    `${Constants.FileUpload.uploadTypeUtils.knowledge_base.fileSizeError}\nPlease try again.`
  ),
};

export const uploadStatusContent = ({ fileState, resetFileUpload }) => {
  const { files, isUploading, completedUpload, uploadSuccess, errorType } =
    fileState;

  if (!files) {
    return {
      icon: Icons.FileInput,
      text: parseStringModifiers(`Drag and drop your file \n\nor`),
      button: {
        onClick: Utils.emptyFunction,
        text: 'Browse files',
      },
    };
  }

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  if (!completedUpload) {
    const numOfFiles = files.length;
    let text = `Your file: **${files[0].name}** is ready to import`;
    if (numOfFiles > 1)
      text = `Your **${numOfFiles}** files are ready to import`;
    return {
      icon: Icons.Upload,
      title: 'Upload ready',
      text: parseStringModifiers(text),
    };
  }

  if (!uploadSuccess)
    return {
      icon: Icons.Warning,
      title: 'Upload failed',
      text: errorText[errorType],
      button: {
        onClick: resetFileUpload,
        text: 'Try again',
      },
    };

  if (uploadSuccess)
    return {
      icon: Icons.CheckCircle,
      title: 'Uploading success',
      text: 'Your file has been successfully imported',
    };
};
