import styled from '@emotion/styled';
import { ResponsiveSizes, Styles } from '../../../../constants';
import { hexOpacity } from '../../../../utils/styleUtils';
import { withoutPx } from '../../../../utils/utils';
import { Colors, Effects, Layout, UtilityEffects } from '../../../style';

export const styles = {
  separator: {
    height: Layout.Spacing._S,
    width: '1px',
    right: 0,
    top: 0,
    margin: `auto`,
    background: Colors.Neutral._300,
  },
};

export const Header = styled.div`
  align-items: center;
  position: absolute;
  top: 0;
  margin: 0 auto;
  z-index: 2;
  width: 100%;
  padding: ${Layout.Spacing._XS} ${Layout.Spacing._S};
  background: ${Colors.Sharp.White};
  ${Styles.flexBetween};
`;

export const OverlayS = styled.div`
  width: 100%;
  height: calc(max(700px, 100vh));
  top: 0;
  min-width: ${ResponsiveSizes.minPageWidth}px;
  right: ${props => props.sidebarWidth};
  transition: ${UtilityEffects.SidebarTransition()};
  left: ${props => props.sidebarWidth};
  background: ${Colors.Neutral._050}${hexOpacity(95)};
  z-index: 225;
`;

export const Container = styled.div`
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding: ${Layout.Spacing._M} 0;
  ${Styles.flexColumn}
`;

export const PreviewGalleryContainerS = styled.div`
  gap: 16px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  position: relative;
  margin-top: -${Layout.Spacing._XL};
  ${Styles.flexColumnCenter}
`;

export const TransformationsS = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  margin: ${Layout.Spacing._3XS} 0;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexEnd};
`;

export const TransformActionS = styled.div`
  position: relative;
  cursor: pointer;
  padding: ${Layout.Spacing._3XS};
  ${Styles.flexCenter};
  border-radius: ${Effects.Border_Radius._S};
  &:hover {
    background: ${Colors.Neutral._200};
  }
`;

export const TopRightMenuS = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
`;

export const DisplayedImageS = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const LoadingPositionS = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 1;
`;

export const ThumbnailGalleryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Button = styled.div`
  height: ${props =>
    Layout.Spacing[props.type === 'preview' ? '_S' : props.size ?? '_L']};
  width: ${props =>
    Layout.Spacing[props.type === 'preview' ? '_S' : props.size ?? '_L']};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius.Circle};
  ${Styles.flexCenter};
  &:hover {
    background: ${Colors.Neutral._200};
  }
`;

const horizontal = {
  prev: 'left',
  next: 'right',
};

export const ArrowCSS = styled(Button)`
  z-index: 2;
  position: absolute;
  bottom: 0;
  top: ${props =>
    props.type === 'preview' ? `-${Layout.Spacing._L}` : '-12px'};
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  box-shadow: ${Effects.Box_Shadow._S};
  ${props => horizontal[props.direction]}: ${props =>
    props.type === 'preview'
      ? `-${withoutPx(Layout.Spacing._S) / 2}px`
      : Layout.Spacing._M};
`;

export const ThumbnailsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  gap: ${props => Layout.Spacing[props.type !== 'preview' ? '_XS' : '_2XS']};
`;

const thumbnailStyle = {
  preview: {
    width: '44px',
    height: '44px',
    borderRadius: Effects.Border_Radius._S,
  },
  rotating: {
    width: Layout.Spacing._XL,
    height: Layout.Spacing._XL,
    borderRadius: Effects.Border_Radius._S,
  },
  static: {
    width: Layout.Spacing._XL,
    height: Layout.Spacing._XL,
    borderRadius: Effects.Border_Radius._S,
  },
};

export const ThumbnailCSS = styled.div`
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  ${props => thumbnailStyle[props.type]}
`;

export const ThumbnailHighlight = styled.div`
  position: absolute;
  inset: 0;
  border-radius: ${props => thumbnailStyle[props.type].borderRadius};
  border: ${props =>
    `${props.highlight ? 2 : 1}px solid ${
      props.highlight ? Colors.Primary._600 : Colors.Neutral._200
    }`};
`;

export const TransformationSeparatorS = styled.div`
  height: 100%;
  position: relative;
  margin: 0 ${Layout.Spacing._3XS};
`;

export const MainSliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

// TODO: background placeholder
// background: url(${placeholder200});
export const MainPreviewImageCSS = styled.div`
  aspect-ratio: 1;
  width: 100%;
  border-radius: ${Effects.Border_Radius._S};
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  border: 2px solid ${Colors.Neutral._200};
`;

export const RemainingCountOverlay = styled.div`
  position: absolute;
  inset: 0;
  ${Styles.flexCenter}
  background: ${Colors.Neutral._600 + hexOpacity(50)};
`;
