import CaseSnapshot from '../../../features_public/support/controllers/CaseSnapshot';
import ProductSnapshot from '../../../features_public/support/controllers/ProductSnapshot';
import { ticketTypes } from '../../../features_public/support/controllers/variables';
import MessageInput from '../controllers/MessageInput';
import TicketConversationDetails from '../controllers/TicketConversationDetails';
import { TicketConversationContainer } from '../style';
import { FacilitatorDetails, Messages } from './organisms/organisms';

export const TicketConversationLayout = ({
  hasProduct,
  productData,
  title,
  ticket,
  ticketId,
  messages,
  awaitingResponseMessage,
  conversationRef,
  facilitatorName,
  facilitatorImage,
  onSendMessageSuccess,
  showProductSummary,
  goToCasesTable,
  showGallery,
  caseImageRef,
  productDataIsLoading,
  messagesIsLoading,
  type,
  hasEditPermission,
}) => {
  return (
    <TicketConversationContainer className="ticket-conversation">
      {/* TODO: use a single snapshot component, DRY */}
      {hasProduct ? (
        type === ticketTypes.Product ? (
          <ProductSnapshot
            productData={productData}
            title={title}
            onClick={showProductSummary}
            showGallery={showGallery}
            caseImageRef={caseImageRef}
            isLoading={productDataIsLoading}
          />
        ) : type === ticketTypes.Parcel ? (
          <CaseSnapshot
            caseData={productData}
            title={title}
            onClick={goToCasesTable}
            showGallery={showGallery}
            caseImageRef={caseImageRef}
            isLoading={productDataIsLoading}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <TicketConversationDetails
        ticket={ticket ?? {}}
        ticketId={ticketId}
        hasEditPermission={hasEditPermission}
      />
      <Messages
        messages={messages}
        awaitingResponseMessage={awaitingResponseMessage}
        conversationRef={conversationRef}
        hasProduct={hasProduct}
      />
      <MessageInput
        ticketId={ticketId}
        facilitatorName={facilitatorName}
        onSendMessageSuccess={onSendMessageSuccess}
        messagesIsLoading={messagesIsLoading}
        disabled={!hasEditPermission}
      />
      <FacilitatorDetails
        facilitatorName={facilitatorName}
        facilitatorImage={facilitatorImage}
      />
    </TicketConversationContainer>
  );
};
