import {
  fieldNames,
  adminServicesKeys as keys,
} from '../../features/admin_services/controllers/variables';

export const formatPermissions = roles => {
  if (!roles) return;
  const allPermissions = roles[0].permissions;

  const permissionRows = allPermissions.map(permission => {
    const row = {};

    row[fieldNames.permissions.name] = permission[keys.permission.name];
    row[fieldNames.permissions.systemName] = permission[keys.permission.key];

    const permissionIdKey = keys.permission.key;
    const getPermissionType = findPermissionByKey(
      roles,
      permission[permissionIdKey],
      permissionIdKey
    );

    const adminPermission = getPermissionType(0);
    const editorPermission = getPermissionType(1);
    const viewerPermission = getPermissionType(2);

    row[fieldNames.permissions.admin] = adminPermission['srpValue'];
    row[fieldNames.permissions.editor] = editorPermission['srpValue'];
    row[fieldNames.permissions.viewer] = viewerPermission['srpValue'];

    return row;
  });

  return permissionRows;
};

const findPermissionByKey = (roles, id, idKey) => index =>
  roles[index].permissions.find(x => x[idKey] === id);
