import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import { Colors, FontKeys, Size } from '../../../../../../components/style';

export const RemainingTimeLabel = ({
  text,
  icon,
  color,
  hasSupportTickets,
}) => {
  return (
    <Flex justify="between" align="center" w>
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        color={color}
        whiteSpace={'nowrap'}
      />
      <Flex gap="_2XS">
        {hasSupportTickets && (
          <Tooltip
            content={'Support requested'}
            children={
              <Icon
                name={Icons.Mail}
                color={Colors.Neutral._500}
                size={Size.Icon._S}
              />
            }
            asChild={false}
          />
        )}
        <Icon name={icon} color={color} size={Size.Icon._S} />
      </Flex>
    </Flex>
  );
};

export const AchievementTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const AchievementTime = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H3}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const AchievementDescriptionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const AchievementDescriptionText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const AchievementTimeframeComparisonText = ({ text }) =>
  BaseText({ text, color: Colors.Neutral._500 });

export const BaseBlueText = ({ text, size }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const ProductSummaryAlias = ({ text }) =>
  BaseBlueText({ text, size: '_L' });
export const RemainingCountText = ({ text }) =>
  BaseBlueText({ text, size: '_M' });

export const BaseText = ({ text, weight, color }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={weight ?? FontKeys.Weight.Regular}
      color={color ?? Colors.Neutral._900}
    />
  );
};

export const DetailTitle = BaseText;
export const BoldDetail = ({ text }) => (
  <BaseText text={text} weight={FontKeys.Weight.Semibold} />
);

export const GrayBoldDetail = ({ text }) => (
  <BaseText
    text={text}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Neutral._500}
  />
);

export const BrandName = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
    />
  );
};

export const AuthDetailsSectionTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._L}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
    />
  );
};

export const FeedbackModalText = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._900}
    />
  );
};

export const ParcelDetailTitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Neutral._500}
    />
  );
};

export const ParcelDetailText = ({ text, bold = true }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
    />
  );
};
