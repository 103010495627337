import { useNavigate } from 'react-router-dom';
import { styles } from '../../../auth/components/style';
import { NavURIs, Pages } from '../../../routes/variables';
import { BrandLogo } from '../../atoms/BrandLogo/BrandLogo';

const { login } = Pages;
const { [login]: loginPath } = NavURIs;

export const PublicLayout = ({ children }) => {
  const navigate = useNavigate();

  const onClick = () => {
    const to = loginPath;
    navigate(to);
  };

  return (
    <div style={styles.layout}>
      <BrandLogo onClick={onClick} variant="auth" />
      {children}
    </div>
  );
};
