import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { clickedBy } from '../../../utils/utils';
import { Container } from './Filters.style';
import { FilterComponents } from './variables';

export const Filters = ({
  filters,
  searchValue,
  disabled,
  autoFocusSearch,
  handleSearchClick: customHandleSearchClick,
  formikBag,
  onSearchIsClicked = Utils.emptyFunction,
  searchTheme,
  ...props
}) => {
  const [openFilter, setOpenFilter] = useState(-1);
  const [searchIsClicked, setSearchIsClicked] = useState(false);
  const valueTyped = useRef(searchValue?.length ?? 0);

  const filtersRef = useRef(null);

  useEffect(() => {
    valueTyped.current = searchValue?.length;
  }, [searchValue]);

  useEffect(() => {
    onSearchIsClicked(searchIsClicked);
  }, [searchIsClicked]);

  useEffect(() => {
    window.addEventListener('click', hideAllFilters);
    return () => {
      window.removeEventListener('click', hideAllFilters);
    };
  }, []);

  const handleFilterClick = (index, onClick) => e => {
    setOpenFilter(index);
    setSearchIsClicked(false);
    if (onClick) onClick(e);
  };

  const handleSearchClick = e => {
    if (typeof customHandleSearchClick === 'function')
      customHandleSearchClick(e);
    setSearchIsClicked(true);
  };

  const hideAllFilters = e => {
    const isContainer = e?.srcElement?.classList?.contains('filters');
    if (!isContainer && clickedBy(filtersRef.current, e)) return;
    setOpenFilter(-1);
    setSearchIsClicked(false);
  };
  return (
    <Container
      className="filters"
      ref={filtersRef}
      {...props}
      onClick={hideAllFilters}
    >
      {(filters || []).map((filter, index) =>
        FilterComponents[filter.type]({
          ...filter,
          open: index === openFilter,
          onClick: handleFilterClick(index, filter.onClick),
          handleSearchClick,
          searchIsClicked,
          ...formikBag,
          searchIsActive: true,
          fullWidth: true,
          key: index,
          disabled,
          autoFocusSearch,
          searchTheme,
        })
      )}
    </Container>
  );
};
