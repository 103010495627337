import { Text } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import { Label } from '../../../../../../components/molecules/Label/Label';
import { Colors, FontKeys, Layout } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import { DateTypes, formatDate } from '../../../../../../utils/timeUtils';
import { UpdateSenderDetailsButtonCSS } from '../style';

export const ReferencesText = ({ text, bold, blue }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
    color={blue ? Colors.Primary._600 : Colors.Neutral._900}
    whiteSpace="nowrap"
    ellipsis
  />
);

export const IndentText = ({ size, children }) => (
  <div style={{ paddingLeft: Layout.Spacing[size] }}>{children}</div>
);

export const ReferenceDetailsText = ({ text, bold, blue }) => (
  <Text
    text={text ?? Constants.fallback}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._M}
    weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
    color={blue ? Colors.Primary._600 : Colors.Neutral._900}
    whiteSpace="nowrap"
    ellipsis
  />
);

export const ReferenceDetailsSubtext = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Paragraph}
    size={FontKeys.Size._S}
    weight={FontKeys.Weight.Regular}
    color={Colors.Neutral._500}
  />
);

export const ReferenceCaseSummaryData = ({ text }) => (
  <Text
    text={text}
    variant={FontKeys.Variant.Heading}
    size={FontKeys.Size.H4}
    weight={FontKeys.Weight.Semibold}
    color={Colors.Primary._600}
    ellipsis
  />
);

export const LastSideload = ({ date }) => {
  const text = `Last sideload time: ${
    formatDate(date, DateTypes.date) ?? Constants.fallback
  }`;
  return <ReferenceDetailsSubtext text={text} />;
};

export const InActiveLabel = ({ active }) => {
  const color = active ? Colors.Primary._400 : Colors.Neutral._500;
  const text = `${active ? 'Active' : 'Inactive'} reference`;
  return (
    <Label
      text={text}
      textColor={color}
      borderColor={color}
      hasBorder
      size="_S"
    />
  );
};

export const UpdateSenderDetailsButton = ({ onClick }) => {
  return (
    <UpdateSenderDetailsButtonCSS>
      <AnimatedIcon name={Icons.Edit3} onClick={onClick} theme={'light'} />
    </UpdateSenderDetailsButtonCSS>
  );
};
