import { Constants } from '../../../../../constants';
import { maskEntry } from '../../../../../utils/utils';
import { referenceIndexKeys } from '../../../features_public/reference_index/controllers/variables';

export const formatReferenceDetails = data => {
  maskEntry(data, mask);

  const caseStatistics = data[referenceIndexKeys.caseStatistics];

  const metrics = [
    {
      value:
        caseStatistics[referenceIndexKeys.casesTotal] ?? Constants.fallback,
      label: 'Total products',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.casesActive] ?? Constants.fallback,
      label: 'Active products',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.casesSeized] ?? Constants.fallback,
      label: 'Seizures',
    },
    {
      value: caseStatistics[referenceIndexKeys.accuracy] ?? Constants.fallback,
      label: 'Accuracy',
    },
    {
      value:
        caseStatistics[referenceIndexKeys.popularLocation] ??
        Constants.fallback,
      label: 'Popular Location',
    },
  ];

  data.metrics = metrics;
  data[referenceIndexKeys.active] = caseStatistics[referenceIndexKeys.active];
  data[referenceIndexKeys.lastSideload] =
    caseStatistics[referenceIndexKeys.lastSideload];
  return data;
};

const mask = {
  seID: referenceIndexKeys.id,
  seAlias: referenceIndexKeys.alias,
  seName: referenceIndexKeys.name,
  seCompany: referenceIndexKeys.company,
  seAddress: referenceIndexKeys.address,
  notes: referenceIndexKeys.notes,
  sePhone: referenceIndexKeys.phone,
  sePostcode: referenceIndexKeys.zipcode,
  seCity: referenceIndexKeys.city,
  seState: referenceIndexKeys.state,
  seCountry: referenceIndexKeys.country,
  seSenderType: referenceIndexKeys.type,
  case_stats: referenceIndexKeys.caseStatistics,
  total_case_count: referenceIndexKeys.casesTotal,
  active_case_count: referenceIndexKeys.casesActive,
  seized_case_count: referenceIndexKeys.casesSeized,
  accuracy: referenceIndexKeys.accuracy,
  popular_location: referenceIndexKeys.popularLocation,
  has_recent_sideload: referenceIndexKeys.active,
  last_sideload_time: referenceIndexKeys.lastSideload,
  brName: referenceIndexKeys.brand.name,
  brImage: referenceIndexKeys.brand.image,
  brID: referenceIndexKeys.brand.id,

  seSideloadLimit: referenceIndexKeys.weights.type,
  seNameWeight: referenceIndexKeys.weights.name,
  seCompanyWeight: referenceIndexKeys.weights.company,
  seAddressWeight: referenceIndexKeys.weights.address,
  sePostcodeWeight: referenceIndexKeys.weights.zipcode,
  seCityWeight: referenceIndexKeys.weights.city,
  seStateWeight: referenceIndexKeys.weights.state,
  seCountryWeight: referenceIndexKeys.weights.country,
  sePhoneWeight: referenceIndexKeys.weights.phone,
};
