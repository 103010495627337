import { getAlias } from '../../utils/utils';

const formatter = ([{ photos }]) => {
  const images = photos.map(imageObject => imageObject.ppImage);
  const thumbnails = photos.map(imageObject => imageObject.ppThumb);

  return { images, thumbnails };
};

const knowledgeBaseRulesFormatter = photos => {
  const images = photos.map(imageObject => imageObject.ppImage);
  const thumbnails = photos.map(imageObject => imageObject.ppThumb);
  return { images, thumbnails };
};

export const GalleryPages = {
  cases: 'cases',
  parcels: 'parcels',
  products: 'products',
  knowledge_base: 'knowledge_base',
  knowledge_base_rules: 'knowledge_base_rules',
  white_label: 'white_label',
};

export const GalleryProps = {
  [GalleryPages.cases]: {
    url: 'cases/images',
    idsKey: 'case_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
  [GalleryPages.parcels]: {
    url: 'parcels/images',
    idsKey: 'parcel_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
  [GalleryPages.products]: {
    url: 'cases/images',
    idsKey: 'case_ids',
    galleryAliasExtractor: getAlias,
    formatter,
  },
  [GalleryPages.knowledge_base]: ({ instructionId, characteristicId }) => ({
    url: `knowledge_base/${instructionId}/characteristic/${characteristicId}/images`,
    idsKey: 'unnecessary',
    formatter,
  }),
  [GalleryPages.knowledge_base_rules]: {
    url: `knowledge_base`,
    idsKey: 'unnecessary',
    formatter: knowledgeBaseRulesFormatter,
  },
  [GalleryPages.white_label]: {
    url: 'parcels/images',
    idsKey: 'parcel_ids',
    formatter,
  },
};
