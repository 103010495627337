import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Colors, FontKeys, Layout } from '../../style';

export const Container = styled.div`
  margin: auto;
  gap: ${({ showTitle }) =>
    showTitle ? Layout.Spacing._XL : Layout.Spacing._M};
  ${Styles.flexColumnCenter};
`;

export const Title = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H6}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._800}
      alignment="center"
    />
  );
};

export const Description = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      alignment="center"
    />
  );
};
