import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { Input } from '../../../../../../components/molecules';
import { Calendar } from '../../../../../../components/molecules/Calendar/Calendar';
import { ReportFormTitle } from '../atoms';

export const Form = ({ changeDates, title, timeFrameInitVals }) => {
  return (
    <Flex gap="_S" column w>
      <ReportFormTitle text={'Report details'} />
      <Flex w height="_L">
        <Calendar
          filter={changeDates}
          title={title}
          timeFrameInitVals={{ value: timeFrameInitVals }}
          fullWidth
          size="_M"
        />
      </Flex>
      <Input
        name="name"
        leftIcon={Icons.Edit3}
        placeholder="Insert a name for the report"
        size="_M"
      />
    </Flex>
  );
};
