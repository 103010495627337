import { Recipe } from './Button.style';

import { useInlineStyle } from './Button.hook';

import Text from '../../atoms/Text/Text';

import Icon from '../../atoms/Icon/Icon';

import Ellipsis from '../LoadingAnimation/Ellipsis';

import { forwardRef } from 'react';
import { FontKeys } from '../../style';

const Button = forwardRef(
  (
    {
      type = 'button',
      text,
      variant = 'Primary',
      size = '_M',
      fullWidth,
      isLoading,
      leftIcon,
      rightIcon,
      onClick,
      disabled,
      black,
      gray,
      status,
      ...props
    },
    ref
  ) => {
    const coreStyle = Recipe(variant);
    const [style, setStyle] = useInlineStyle(coreStyle, {
      size,
      disabled,
      fullWidth,
      leftIcon,
      rightIcon,
      black,
      gray,
      isClickable: !!onClick,
      status,
    });
    const setHover = to => () => setStyle('hover', to);
    const setFocus = to => () => setStyle('focus', to);

    const { tooltipContent, ...warningSafeProps } = props;
    return (
      <button
        type={type}
        style={style}
        ref={ref}
        onClick={onClick}
        onPointerOver={setHover(true)}
        onPointerOut={setHover(false)}
        onPointerDown={setFocus(true)}
        onPointerUp={setFocus(false)}
        onMouseLeave={setFocus(false)}
        disabled={disabled}
        className={`button ${props.className ?? ''}`}
        {...warningSafeProps}
      >
        {isLoading ? (
          <Ellipsis size={size} color={style.loadingColor} />
        ) : (
          <>
            {leftIcon && (
              <Icon name={leftIcon} color={style.color} size={style.iconSize} />
            )}
            <Text
              text={text}
              variant="Paragraph"
              size={size}
              weight={FontKeys.Weight.Semibold}
              alignment="center"
              color={style.color}
              whiteSpace="nowrap"
            />
            {rightIcon && (
              <Icon
                name={rightIcon}
                color={style.color}
                size={style.iconSize}
              />
            )}
          </>
        )}
      </button>
    );
  }
);

export default Button;
