import { TableLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';

import Attachments from '../../../../../../components/organisms/Attachments/Attachments';
import Notes from '../../../../../../components/organisms/Notes/Notes';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import { defaultErrorDisplay } from '../../../../../../components/templates/TableStatus/variables';
import {
  ParcelProducts,
  ProductDetailsSummary,
  RecentActivity,
  StatusSteps,
} from '../../../../features_public/products/components/organisms';

import { NoResultsCSS } from '../../../../features_public/products/components/style';
import { productKeys } from '../../../../features_public/products/controllers/variables';
import { useCreateNote } from '../../api/createNote';
import { caseNotesConstants } from '../../variables';
import { CaseDetailsContainer } from '../atoms';
import {
  AuthorityInfo,
  ParcelDetailsSummary,
  ProductTickets,
} from '../organisms';

export const ProductDetailsLayout = ({
  data,
  products,
  productId,
  productAlias,
  parcelAlias,
  showEditParcel,
  productDetails,
  showEditProduct,
  goToProductDetails,
  parcelProductsIsLoading,
  status,
  images,
  steps,
  statusInstructions,
  actions,
  actionClick,
  activity,
  componentSize,
  parcelDetails,
  parcelDetailsIsError,
  parcelDetailsIsLoading,
  shouldBlurActors,
  notes,
  onSuccessCreateNote,
  tickets,
  authorityDetails,
  showEditAuthority,
  isLoading,
  isError,
  handleTicketClick,
  attachments,
  hasParcelViewPermission,
}) => {
  return isLoading ? (
    <NoResultsCSS>
      <TableLoader />
    </NoResultsCSS>
  ) : isError ? (
    <NoResultsCSS>
      <NoSearchResults {...defaultErrorDisplay} />
    </NoResultsCSS>
  ) : (
    <CaseDetailsContainer>
      <ParcelDetailsSummary
        sections={parcelDetails}
        shouldBlurActors={shouldBlurActors}
        parcelAlias={parcelAlias}
        showEditParcel={showEditParcel}
        isLoading={parcelDetailsIsLoading}
        isError={parcelDetailsIsError}
        disabled={!hasParcelViewPermission}
      />
      <ProductDetailsSummary
        productId={productId}
        productDetails={productDetails}
        status={status}
        photos={images}
        size={componentSize}
        productAlias={productAlias}
        showEditProduct={showEditProduct(data)}
      />
      <ParcelProducts
        rowAction={goToProductDetails}
        data={products}
        isLoading={parcelProductsIsLoading}
        // if has no permission
        disabled={!hasParcelViewPermission}
      />
      <StatusSteps
        steps={steps}
        status={status}
        statusInstructions={statusInstructions}
        actions={actions}
        actionClick={actionClick}
        size={componentSize}
      />
      <Notes
        notes={notes}
        onSuccessCreateNote={onSuccessCreateNote}
        isLoading={isLoading}
        hook={useCreateNote}
        params={{ productId }}
        {...caseNotesConstants}
        // hasEditPermission={hasPermission(EDIT_PRODUCT_NOTES)}
        hasEditPermission={true}
      />
      <ProductTickets tickets={tickets} handleTicketClick={handleTicketClick} />
      <RecentActivity activity={activity} icon toggle />
      <Attachments
        route="office_case"
        folder="case_files"
        id={productId}
        attachmentIdKey={productKeys.attachmentId}
        urlKey={productKeys.attachmentURL}
        nameKey={productKeys.attachmentName}
        attachments={attachments}
        isLoading={isLoading}
        // hasEditPermission={hasPermission(EDIT_PRODUCT_ATTACHMENTS)}
        // hasDeletePermission={hasPermission(DELETE_PRODUCT_ATTACHMENTS)}
        hasEditPermission={true}
        hasDeletePermission={true}
      />
      <AuthorityInfo
        authorityDetails={authorityDetails}
        showEditAuthority={showEditAuthority}
      />
    </CaseDetailsContainer>
  );
};
