import { Colors, Layout, UtilityColors } from '../../style';

import { Recipe } from './Text.style';

import React, { Fragment } from 'react';
import { Utils } from '../../../constants';
import { isArray } from '../../../lib/js';
import { safeValue } from '../../../utils/utils';

const Text = React.forwardRef(
  (
    {
      text,
      variant,
      size,
      weight,
      alignment,
      color,
      whiteSpace,
      marginTop,
      ellipsis,
      className,
      onClick,
      ...props
    },
    ref
  ) => {
    if (!size) throw Error('Missing size property');
    const style = Recipe[variant]?.[size]?.({ weight });
    style.textAlign = alignment;
    style.whiteSpace = whiteSpace;
    style.color = safeValue(Colors, color) || UtilityColors.Text;
    style.marginTop = safeValue(Layout.Spacing, marginTop);
    if (ellipsis) {
      style.whiteSpace = 'nowrap';
      style.overflow = 'hidden';
      style.textOverflow = 'ellipsis';
    }
    style.cursor =
      !!onClick && onClick != Utils.emptyFunction && onClick != Utils.trueReturn
        ? 'pointer'
        : 'unset';

    return (
      <span
        className={className ?? ''}
        style={style}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {isArray(text)
          ? text.map((piece, index) => <Fragment key={index}>{piece}</Fragment>)
          : text}
      </span>
    );
  }
);

export default Text;
