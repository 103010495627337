import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import { lineChartDateformat } from '../../../../../components/organisms/LineChart/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { DC, colors } from '../variables';

export const useGetData = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);
  const ignoreWithoutFilters =
    params.categories?.length === 0 || params.tags?.length === 0;

  const [
    { data, isLoading = true, isError, error },
    getProducts,
    refreshTable,
    refresh,
  ] = useFetchData(
    'office_dashboard/sorter_data',

    {
      formatter,
      params,
      ignore:
        ignore == null ||
        ignore === true ||
        ignoreWithoutFilters == null ||
        ignoreWithoutFilters === true,
      large: true,
    }
  );

  return {
    data,
    isLoading: ignoreWithoutFilters ? false : isLoading,
    isError,
    error,
    getProducts,
    refreshTable,
    refresh,
  };
};

export const formatter = data => {
  return Object.entries(data ?? {}).map(
    ([title, { data, description, category, subcategory }], index) => ({
      data: data.map(({ value, date }) => ({
        [title]: value,
        date: lineChartDateformat(date),
      })),
      lines: [{ dataKey: title, stroke: colors[index % colors.length] }],
      title,
      description,
      [DC.CATEGORY]: category,
      [DC.SUBCATEGORY]: subcategory,
    })
  );
};

export const formatParams = params => {
  const { TIME_FRAME, CATEGORY, SUBCATEGORY } = params;
  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);
  const categories = extractSelectedValues(CATEGORY);
  const tags = extractSelectedValues(SUBCATEGORY);

  const formattedParams = {
    from_date,
    to_date,
    categories,
    tags,
  };

  return formattedParams;
};
