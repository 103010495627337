import React from 'react';
import { Utils } from '../../../constants';
import { Text } from '../../atoms';
import { FontKeys, SizeConstants, UtilityColors } from '../../style';
import { useInlineStyle } from './RadioButton.hook';
import { Recipe } from './RadioButton.style';

const RadioButton_ = (
  {
    variant = 'default',
    size = SizeConstants._M,
    selected,
    setSelected = Utils.emptyFunction,
    id,
    label,
    disabled,
    hoverIn = Utils.emptyFunction,
    hoverOut = Utils.emptyFunction,
    bold,
    ...props
  },
  ref
) => {
  const coreStyle = Recipe(variant);
  const [style, setStyle] = useInlineStyle(coreStyle, {
    size,
    selected,
    disabled,
  });

  const setHover = to => () => setStyle('hover', to);

  const handleHoverIn = e => {
    hoverIn(e);
    setHover(true);
  };

  const handleHoverOut = e => {
    hoverOut(e);
    setHover(false);
  };

  return (
    <div
      style={style}
      onClick={disabled ? () => {} : setSelected(id)}
      onPointerOver={handleHoverIn}
      onPointerOut={handleHoverOut}
      {...props}
      ref={ref}
    >
      <div style={style.selection}>
        <div style={style.selectionDot}></div>
      </div>
      {label && (
        <Text
          text={label}
          variant="Paragraph"
          size={size}
          weight={bold ? FontKeys.Weight.Semibold : FontKeys.Weight.Regular}
          color={disabled ? UtilityColors.Disabled : 'default'}
        />
      )}
    </div>
  );
};

export const RadioButton = React.forwardRef(RadioButton_);
