import { Card } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import PreviewGallery from '../../../../../../components/templates/Gallery/controllers/PreviewGallery';
import { SummaryTitle } from '../atoms';
import { SummaryDetails } from '../organisms';
import { ProductSummaryContainer } from '../style';

export const ProductSummary = ({ values }) => {
  return (
    <ProductSummaryContainer>
      <Card>
        <Flex column h gap="_L">
          <SummaryTitle text={'Product summary'} />
          <Flex gap align="center">
            <SummaryDetails details={Object.values(values.details)} />
            <PreviewGallery images={values.images} useParentImages />
          </Flex>
        </Flex>
      </Card>
    </ProductSummaryContainer>
  );
};
