import { Icon } from '../../../../../../components/atoms';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { StatusIndicator } from '../../../../../../components/atoms/StatusIndicator/StatusIndicator';
import Button from '../../../../../../components/molecules/Button/Button';
import FloatingMenu from '../../../../../../components/molecules/FloatingMenu/FloatingMenu';
import {
  RowDateHorizontal,
  RowMultipleImages,
  RowSimpleText,
  SimpleStatus,
  Space,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { parcelKeys } from '../../controllers/variables';

const options = [
  {
    label: 'Contact support',
    value: 'contact support',
  },
];

const OptionsMenu = onSupportClick => item => {
  const showSupport = item => () => () => onSupportClick(item);

  return (
    <FloatingMenu
      options={options}
      optionClick={showSupport(item)}
      optionDisplay={({ label }) => (
        <Button
          variant="Tertiary"
          size="_S"
          text={label}
          black
          // cursor pointer
          onClick={Utils.emptyFunction}
        />
      )}
    >
      <Icon
        name={Icons.MoreVertical}
        color={Colors.Neutral._500}
        size={Size.Icon._M}
      />
    </FloatingMenu>
  );
};

export const tableComponents = ({
  showGallery,
  onSupportClick,
  hasSupportPermission,
}) => ({
  [parcelKeys.leftPadding]: { display: Space },
  [parcelKeys.statusIndicator]: {
    display: StatusIndicator(parcelKeys.status),
  },
  [parcelKeys.parcelImages]: {
    display: RowMultipleImages({
      photosKey: parcelKeys.photos,
      showGallery,
      size: '_S',
    }),
  },
  [parcelKeys.alias]: {
    display: RowSimpleText(parcelKeys.alias, 'bold', true, { copyable: true }),
  },
  [parcelKeys.sideloadDate]: {
    display: RowDateHorizontal(parcelKeys.sideloadDate),
  },
  [parcelKeys.reason]: { display: RowSimpleText(parcelKeys.reason) },
  [parcelKeys.riskType]: { display: RowSimpleText(parcelKeys.riskType) },
  [parcelKeys.status]: { display: SimpleStatus(parcelKeys.status) },
  [parcelKeys.caseCount]: {
    display: RowSimpleText(parcelKeys.caseCount, 'bold'),
  },
  // action() prevents actionRow in Table from firing
  [parcelKeys.optionsMenu]: hasSupportPermission
    ? {
        display: OptionsMenu(onSupportClick),
        action: Utils.avoidRowExpand,
      }
    : { display: () => <></> },
});
