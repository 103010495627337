import { AddProductButton } from './atoms';
import { CaseInfoTable, CaseProductsTable } from './organisms';
import { CaseInfoContainer, CaseInfoSubtableContainer } from './style';

export const CaseInfoLayout = ({
  headers,
  data,
  grid,
  showGallery,
  subHeaders,
  subData,
  subGrid,
  editProduct,
  goToAddProduct,
  productQuantity,
  disabled,
  showProductGallery,
}) => {
  return (
    <CaseInfoContainer>
      <CaseInfoTable
        headers={headers}
        data={data}
        grid={grid}
        showGallery={showGallery}
      />
      <CaseInfoSubtableContainer>
        {subData.length === 0 && !disabled ? (
          <AddProductButton onClick={goToAddProduct} />
        ) : (
          <CaseProductsTable
            headers={subHeaders}
            data={subData}
            grid={subGrid}
            editProduct={editProduct}
            productQuantity={productQuantity}
            disabled={disabled}
            showGallery={showProductGallery}
          />
        )}
      </CaseInfoSubtableContainer>
    </CaseInfoContainer>
  );
};
