import { openNewTab } from '../../../../../utils/utils';
import { NavURIs, Pages } from '../../../routes/variables';
import { DC } from './variables';

export const withLightweightProductStatus = params => {
  if (params == null) return;
  const savedStatus = params[DC.PRODUCT_STATUS]?.[DC.OTHERS];
  // TODO: test and make sure first if check covers all cases of second
  if (!savedStatus) return params;
  if (savedStatus == null || savedStatus.length == 0) return params;
  const lightweightStatus = savedStatus.map(status => status.value);
  params[DC.PRODUCT_STATUS][DC.OTHERS] = lightweightStatus;
  return params;
};

export const withoutOwnerBrand = (brands = [], id) => {
  return brands.filter(brand => brand.value !== id);
};

export const ticketCountText = tickets => {
  const noOfTickets = tickets?.length;

  return `${noOfTickets} ${
    noOfTickets > 1 ? 'Support tickets' : 'Support ticket'
  }`;
};

export const goToTicketsForProduct = search => tickets => {
  let to;
  if (tickets?.length === 1) {
    const { ticketId } = tickets[0];
    to = `/support/${ticketId}`;
  } else to = `${NavURIs[Pages.support]}?search=${search}`;

  openNewTab(to);
  return tickets;
};
