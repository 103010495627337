import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import { Popup } from '../../../../../components/templates/Popup';
import { BaseText } from './atoms';
import { AddServiceActions, BrandDropzone, BrandFields } from './organisms';
import { SnapshotPopupContent } from './style';

const sectionTitleAdd = 'Add the requested information to add a new brand';
const sectionTitleEdit = 'Edit the requested information to update the brand';

export const AddBrandLayout = ({
  isEditing,
  brandFieldsProps,
  brandDropzoneProps,
  actionsProps,
}) => {
  return (
    <Popup size={'_M'} title={!isEditing ? 'Add brand' : 'Edit brand'}>
      <SnapshotPopupContent>
        <Spacer height={'_M'} />
        <Flex w justify={'center'}>
          <BaseText
            text={isEditing ? sectionTitleEdit : sectionTitleAdd}
            size="_L"
          />
        </Flex>
        <Spacer height={'_M'} />
        <Flex gap="_S" grow>
          <BrandFields brandFieldsProps={brandFieldsProps} />
          <BrandDropzone brandDropzoneProps={brandDropzoneProps} />
        </Flex>
        <AddServiceActions actionsProps={actionsProps} />
      </SnapshotPopupContent>
    </Popup>
  );
};
