import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import ReportsLayout from '../components/ReportsLayout';
import { styles } from '../components/style';
import CustomReports from './CustomReports';
import MonthlyReports from './MonthlyReports';
import { options as allOptions, optionViews } from './variables';

const { SELF } = PermissionKeys.Brand.REPORTS;

const Reports = () => {
  const { hasPermission } = usePermissions();

  const components = {
    [optionViews.monthly]: <MonthlyReports />,
    [optionViews.custom]: <CustomReports />,
  };

  const withPermissions = options =>
    options.filter(({ permission }) => hasPermission(SELF, permission));

  const options = withPermissions(allOptions);

  return (
    <ReportsLayout options={options} components={components} styles={styles} />
  );
};

export default Reports;
