import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import { FileUploadProvider } from './FileUploadProvider';
import { FirebaseProvider } from './FirebaseProvider';
import FooterProvider from './FooterProvider/FooterProvider';
import HeaderProvider from './HeaderProvider/HeaderProvider';
import { ModalProvider } from './ModalProvider';
import PermissionsProvider from './PermissionProvider/PermissionsProvider';
import PlatformProvider from './PlatformProvider';
import { SnackbarProvider } from './SnackbarProvider';
import StoreProvider from './StoreProvider';
import UIProvider from './UIProvider';

export const AppProvider = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SSO_CLIENT}>
      <BrowserRouter>
        <StoreProvider>
          <UIProvider>
            <SnackbarProvider>
              <FirebaseProvider>
                <PermissionsProvider>
                  <AuthProvider>
                    <PlatformProvider>
                      <FileUploadProvider>
                        <ModalProvider>
                          <HeaderProvider>
                            <FooterProvider>{children}</FooterProvider>
                          </HeaderProvider>
                        </ModalProvider>
                      </FileUploadProvider>
                    </PlatformProvider>
                  </AuthProvider>
                </PermissionsProvider>
              </FirebaseProvider>
            </SnackbarProvider>
          </UIProvider>
        </StoreProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};
