import styled from '@emotion/styled';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../style';

export const Dots = styled(Flex)``;

export const CarouselDot = styled.div`
  width: ${Layout.Spacing._2XS};
  height: ${Layout.Spacing._2XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius.Circle};
  opacity: ${({ selected }) => (selected ? 1 : 0.4)};
`;
