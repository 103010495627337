import { Input } from '../../../../../components/molecules';
import { StepPopup } from '../../../../../components/templates/StepPopup/StepPopup';
import FileUpload from '../../../../../features/file_upload/controllers/FileUpload';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { AuthenticityBadge } from '../components/organisms';
import { ItemDescripitionCSS } from '../components/style';

const {
  SELF,
  //  DELETE_KNOWLEDGE_BASE_CHARACTERISTICS
} = PermissionKeys.Admin.OFFICE_KNOWLEDGE_BASE;

export const CharacteristicEditLayout = ({
  onPrimary,
  assignHandleImport,
  onRemoveItem,
  onFileStateUpdate,
  onDetailChange,
  disabled,
  isLoading,
  filesUploading,
  // Authenticity dropdown
  authenticityBadgeMenuOptions,
  authenticityBadgeColors,
  selectOption,
  getSelectedOption,
  characteristicType,
  onCancel,
  onDelete,
  items,
  authenticityBadgeIcons,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <StepPopup
      title={`Edit characteristic - ${characteristicType}`}
      sectionTitle="Add your images and choose whether they refer to an non-counterfeit or a counterfeit reference"
      onPrimary={onPrimary}
      disabled={disabled}
      isLoading={isLoading}
      onCancel={onCancel}
      onDelete={
        // hasPermission(DELETE_KNOWLEDGE_BASE_CHARACTERISTICS) &&
        onDelete
      }
    >
      <FileUpload
        preexistingFiles={items}
        showMultiple
        assignHandleImport={assignHandleImport}
        onRemoveItem={onRemoveItem}
        onFileStateUpdate={onFileStateUpdate}
        parentIsLoading={isLoading}
        beforeElement={
          filesUploading
            ? undefined
            : (file, index) => (
                <AuthenticityBadge
                  file={file}
                  options={authenticityBadgeMenuOptions}
                  color={
                    authenticityBadgeColors[getSelectedOption(index)?.value]
                  }
                  icon={authenticityBadgeIcons[getSelectedOption(index)?.value]}
                  selectOption={selectOption(index)}
                  selectedOption={getSelectedOption(index)}
                  readOnly={index <= items.length - 1 || isLoading}
                />
              )
        }
        afterElement={(file, index) => (
          <ItemDescripitionCSS>
            <Input
              onChange={onDetailChange(index)}
              placeholder={'Provide a description for this image'}
              type="textarea"
              name={String(index)}
              readOnly={items.length >= index + 1 || isLoading}
            />
          </ItemDescripitionCSS>
        )}
      />
    </StepPopup>
  );
};
