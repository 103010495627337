import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../components/templates/Popup';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { Fields } from './organisms';

export const AddSorterLayout = ({
  isEditing,
  fields,
  selectOption,
  actionProps,
}) => {
  return (
    <Popup size="_M" title={isEditing ? 'Edit sorter' : 'Add sorter'}>
      <Content>
        <Flex w column h>
          <Spacer height="_M" />
          <Fields fields={fields} selectOption={selectOption} />
          <Actions {...actionProps} />
        </Flex>
      </Content>
    </Popup>
  );
};
