import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import {
  Badge,
  BadgeType,
} from '../../../../../components/molecules/Badge/Badge';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { ItemPreviewImage } from '../../../../../components/molecules/ItemPreviewImage/ItemPreviewImage';
import { CardLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import { SnapshotData } from './molecules';
import { SnapshotConent } from './style';

export const ProductSnapshotLayout = ({
  color,
  image,
  productDetails,
  status,
  title,
  onClick,
  showGallery = () => {},
  caseImageRef,
  isLoading,
  imagesIsLoading,
}) => {
  return (
    <Card
      color={color}
      className="product-snapshot"
      title={
        title ? (
          <Flex h justify={'between'}>
            {title ? (
              <CopyableText text={`Product ${title}`} copyText={title} />
            ) : (
              ''
            )}
            {/* <Icon
            name={Icons.ExternalLink}
            color={Colors.Neutral._400}
            size={Size.Icon._M}
          /> */}
          </Flex>
        ) : null
      }
      onClick={onClick}
    >
      {isLoading ? (
        <CardLoader />
      ) : (
        <Flex gap="_S">
          {imagesIsLoading ? (
            <div className="pulse">
              <ItemPreviewImage size="_XL" />
            </div>
          ) : (
            <ItemPreviewImage
              src={image}
              size="_XL"
              onClick={showGallery}
              caseImageRef={caseImageRef}
            />
          )}

          <SnapshotConent column gap="_XS" className="snapshot-content">
            <SnapshotData productDetails={productDetails} />
            <Flex>
              <Badge type={BadgeType.status} text={status} size="_S" />
            </Flex>
          </SnapshotConent>
        </Flex>
      )}
    </Card>
  );
};
