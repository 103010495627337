import { useEffect } from 'react';
import Modal from '../../components/templates/Modal/Modal';
import NewFeature from '../../components/templates/NewFeature/NewFeature';
import { useModal } from '../../providers/ModalProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useGetAnnouncementDetails } from './api/getAnnouncementDetails';
import { useMarkAnnouncementRead } from './api/markAnnouncementRead';

const FeaturePreview = ({ type, id, sync }) => {
  const { dismiss } = useModal();
  const { showSnackbarError } = useSnackbar();

  const { data, isLoading, isError, error } = useGetAnnouncementDetails({ id });

  const { doMarkAnnouncementRead } = useMarkAnnouncementRead({ id });

  useEffect(() => {
    if (isLoading || !data) return;
    if (isError) {
      showSnackbarError(error);
      dismiss();
    }

    doMarkAnnouncementRead().then(() => sync());
  }, [isError, isLoading, data]);

  return <NewFeature {...data} isLoading={isLoading} dismiss={dismiss} />;
};
export default Modal(FeaturePreview);
