import { Card } from '../../../../../components/atoms';
import Button from '../../../../../components/molecules/Button/Button';
import { Table } from '../../../../../components/organisms/Table/Table';
import TableStatus from '../../../../../components/templates/TableStatus/TableStatus';
import { customTableComponents } from './molecules';

export const CustomReportsLayout = ({
  headers,
  data,
  grid,
  handleAction,
  isLoading,
  isError,
  error,
  tableStatusProps,
  generateReportButton,
  hasCreatePermission,
}) => {
  return (
    <Card
      title="Custom reports"
      icon={hasCreatePermission && <Button {...generateReportButton} />}
      className="custom-reports"
    >
      {
        <TableStatus
          isLoading={isLoading}
          isError={isError}
          error={error}
          {...tableStatusProps}
          fullPage={false}
          table={
            <Table
              headers={headers}
              data={data}
              grid={grid}
              components={customTableComponents({ handleAction })}
            />
          }
        />
      }
    </Card>
  );
};
