import styled from '@emotion/styled';
import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Image } from '../../../../../components/atoms/Image/Image';
import { Label } from '../../../../../components/molecules/Label/Label';
import { FileDropzone } from '../../../../../components/organisms/FileDropzone/FileDropzone';
import { Table } from '../../../../../components/organisms/Table/Table';
import { Colors, Effects, Layout } from '../../../../../components/style';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { Styles } from '../../../../../constants';
import { fieldNames } from '../controllers/AddContract';

export const AdminBrandsTableContainer = styled(Table)`
  .label {
    height: 24px;
  }
  .table-cell, .table-header {
    &._0 {
      padding-left: ${Layout.Spacing._S};
    }
`;

export const AdminBrandsDetailsContainer = styled(Flex)`
  height: 100%;
  > .card {
    width: 100%;
  }
`;

export const BrandImage = styled(Image)`
  min-width: 104px;
  min-height: 104px;
  max-width: 104px;
  max-height: 104px;
  border: 1px solid ${Colors.Neutral._300};
  border-radius: ${Effects.Border_Radius._S};
`;

export const BrandOverviewPlan = styled(Label)`
  height: 24px;
`;

export const BrandSnapshotCSS = styled(Card)`
  height: 0;
  flex-grow: 1;
`;

export const SnapshotPopupContent = styled(Content)`
  display: flex;
  flex-direction: column;
  *::-webkit-scrollbar {
    width: 8px;
    display: block;
  }
`;

export const ServiceContent = styled(SnapshotPopupContent)``;

export const UserContent = styled(SnapshotPopupContent)`
  overflow: auto;
`;

export const PlanContent = styled(SnapshotPopupContent)`
  overflow: auto;
`;

const HighlightSelectedContainer = styled(Flex)`
  border-style: solid;
  border-width: 1px;
  box-shadow: inset 0 0 0 1px
    ${({ selected }) => (selected ? Colors.Primary._600 : 'transparent')};
  border-color: ${({ selected }) =>
    selected ? Colors.Primary._600 : Colors.Neutral._200};
  border-radius: ${Effects.Border_Radius._S};
  padding: ${Layout.Spacing._S};
`;

export const ServiceRowCSS = styled(HighlightSelectedContainer)``;

export const ServiceIconName = styled(Flex)`
  min-width: 200px;
`;

export const PersonalDetailsCSS = styled(Flex)`
  width: 50%;
`;

export const PlanItemCSS = styled(HighlightSelectedContainer)``;

export const PlanTextCSS = styled(Flex)`
  min-width: 400px;
`;

export const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 120px);
  grid-template-rows: repeat(2, 32px);
  justify-items: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid ${Colors.Neutral._100};
  border-radius: ${Effects.Border_Radius._S};
`;

export const GridGrayBG = styled(Flex)`
  width: 100%;
  height: 100%;
  background: ${Colors.Neutral._100};
  ${Styles.flexCenter};
`;

export const ContractContent = styled(SnapshotPopupContent)`
  .${fieldNames.description} {
    flex-grow: 1;
  }
`;

const SnapshotDropzoneComponent = styled(FileDropzone)`
  width: 100%;
  height: 100%;
`;

export const ContractDropzoneComponent = styled(SnapshotDropzoneComponent)``;

export const ContractFieldsCSS = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export const InvoiceFieldsCSS = styled(Flex)`
  width: 100%;
  height: 100%;
`;
export const BrandFieldsCSS = styled(Flex)`
  width: 100%;
  height: 100%;
`;

export const InvoiceDropzoneComponent = styled(SnapshotDropzoneComponent)``;
export const BrandDropzoneComponent = styled(SnapshotDropzoneComponent)``;
