import { Colors } from '../../style';
import { Progress, ProgressTrack } from './ProgressBar.style';

const ProgressBar = ({ percentage, color = Colors.Primary._600 }) => {
  return (
    <ProgressTrack>
      <Progress percentage={percentage} color={color} />
    </ProgressTrack>
  );
};

export default ProgressBar;
