import { useEffect, useRef, useState } from 'react';
import { Utils } from '../../../constants';
import { elementDimensions } from '../../../utils/utils';
import { TableHeader, TableRows } from './Table.exports';
import {
  Container,
  ExpandItem,
  TableBody,
  TableContainer,
  TableShadow,
} from './Table.style';
import { getClickedRow, getRowHeight } from './utils';

export const Table = ({
  headers,
  data,
  components,
  grid,
  selectedRows,
  rowAction = Utils.emptyFunction,
  rowHoverIn = Utils.emptyFunction,
  rowHoverOut = Utils.emptyFunction,
  scrollRef,
  onRowExpand = Utils.falseReturn,
  avoidSingleExpand,
  expandCountKey,
  ...props
}) => {
  const [maxShadowHeight, setMaxShadowHeight] = useState(0);

  // TODO: turn into a reducer. these are always related. also, would make TableRows api a bit shorter and cleaner
  const [selectedRow, setSelectedRow] = useState(-1);
  const [lastSelectedRow, setLastSelectedRow] = useState();
  const [offsetTop, setOffsetTop] = useState(0);
  const [scrollHideUp, setScrollHideUp] = useState(0);
  const [scrollHideDown, setScrollHideDown] = useState(0);
  const [inTransition, setInTransition] = useState(false);

  const expandSingleRow = item => {
    const action = Utils.expandRow;
    actionRowClick(action, item, 0)({ target: firstRowRef.current });
  };

  const tableBody = useRef(null);
  const headerRowRef = useRef(null);
  const localScrollRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => rszObserverCallback());
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  useEffect(() => {
    rszObserverCallback();
  }, [data]);

  const rszObserverCallback = () => {
    const table = tableBody.current;
    if (!table) return;
    const height = table.clientHeight;
    setMaxShadowHeight(height);
  };

  const actionRowClick =
    (action = () => true, item, index) =>
    e => {
      const willExpand = selectedRow === -1;
      const args = [item, index, e, willExpand];

      let nextStep = action(...args);

      if (nextStep?.expand) handleExpandRow(...args);
      if (!(nextStep.rowAction || nextStep === true)) return;

      nextStep = handleRowClick(...args);
      if (nextStep?.expand) handleExpandRow(...args);
    };

  const handleRowClick = (...args) => rowAction(...args);

  const handleRowHoverIn = (item, index) => e => rowHoverIn(item, index, e);
  const handleRowHoverOut = (item, index) => e => rowHoverOut(item, index, e);

  const handleExpandRow = (...args) => {
    const [item, index, e, willExpand] = args;
    const hasNoSubitems = !(item[expandCountKey]?.length > 0);
    if (expandCountKey && hasNoSubitems) return;
    if (!e?.target) return;

    setInTransition(true);

    const clickedRow = getClickedRow(e.target);
    const { offsetTop } = clickedRow ?? {};
    const { offsetTop: headerOffset } = headerRowRef.current;
    const { height: headerHeight } = elementDimensions(headerRowRef.current);
    const { height: tableHeight } = elementDimensions(localScrollRef.current);
    const { scrollTop } = localScrollRef.current;

    setOffsetTop(offsetTop);
    setScrollHideUp(-offsetTop + headerOffset);
    setScrollHideDown(tableHeight - offsetTop + scrollTop - headerHeight);

    setLastSelectedRow(index);
    toggleSelected(index);

    return onRowExpand(item, index, e, willExpand);
  };

  const toggleSelected = index =>
    setSelectedRow(current => (current === -1 ? index : -1));

  const rowIsSelected = selectedRow !== -1;

  const rowHeight = getRowHeight(grid);

  const handleExpandTransitionEnd = rowIsSelected => e => {
    setInTransition(false);
    if (!rowIsSelected) e?.target?.scrollTo(0, 0);
  };

  const keepRowInTransition = () => {
    if (rowIsSelected) return selectedRow;
    if (inTransition) return lastSelectedRow;
    return selectedRow;
  };

  const firstRowRef = useRef(null);

  const onlyOneRow = data.length === 1;
  const noRowSelected = selectedRow === -1;
  const tableHasExpandItem = components.expand;
  const tableHasExpandItemCount =
    expandCountKey && data?.[0]?.[expandCountKey].length > 1;

  if (
    !avoidSingleExpand &&
    onlyOneRow &&
    noRowSelected &&
    tableHasExpandItem &&
    firstRowRef.current &&
    tableHasExpandItemCount
  )
    expandSingleRow(data[0]);

  return (
    <Container id="table" {...props}>
      <TableShadow id="tableShadow" maxShadowHeight={maxShadowHeight} />
      <TableContainer
        id="tableContainer"
        ref={e => {
          if (scrollRef) scrollRef.current = e;
          localScrollRef.current = e;
        }}
        selection={rowIsSelected}
      >
        <TableBody id="tableBody" ref={tableBody}>
          <TableHeader
            headerRowRef={headerRowRef}
            headers={headers}
            grid={grid}
          />
          <TableRows
            data={data}
            selectedRow={selectedRow}
            grid={grid}
            rowIsSelected={rowIsSelected}
            scrollHideUp={scrollHideUp}
            scrollHideDown={scrollHideDown}
            headers={headers}
            components={components}
            actionRowClick={actionRowClick}
            handleRowHoverIn={handleRowHoverIn}
            handleRowHoverOut={handleRowHoverOut}
            rowIsClickable={rowAction !== Utils.emptyFunction}
            selectedRows={selectedRows}
            firstRowRef={firstRowRef}
          />
          <ExpandItem
            expanded={rowIsSelected}
            inTransition={inTransition}
            offsetTop={offsetTop}
            rowHeight={rowHeight}
            grid={grid}
            onTransitionEnd={handleExpandTransitionEnd(rowIsSelected)}
            scrollHideUp={scrollHideUp}
            className="expansion-item"
          >
            {components['expand']?.(
              data[keepRowInTransition()],
              keepRowInTransition(),
              data
            )}
          </ExpandItem>
        </TableBody>
      </TableContainer>
    </Container>
  );
};
