import { Card } from '../../../components/atoms';
import { Flex } from '../../../components/atoms/Flex/Flex';
import {
  Actions,
  PasswordFields,
  PasswordRulesDiv,
} from './organisms/organisms';

export const SecurityLayout = ({
  passwordRules,
  fields,
  onCancel,
  cancelDisabled,
  submitDisabled,
  isLoading,
}) => {
  return (
    <Card title="Security">
      <Flex gap="_S">
        <PasswordFields fields={fields} />
        <PasswordRulesDiv rules={passwordRules} />
      </Flex>
      <Actions
        submitDisabled={submitDisabled}
        cancelDisabled={cancelDisabled}
        onCancel={onCancel}
        isLoading={isLoading}
      />
    </Card>
  );
};
