import styled from '@emotion/styled';
import { ResponsiveSizes, Styles } from '../../../constants';
import { hexOpacity } from '../../../utils/styleUtils';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, Layout, UtilityEffects } from '../../style';

export const sidebarLogoSize = Layout.Spacing._XL;
export const collapsedWidth = '80px';
export const expandedWidth = '232px';
export const sidebarPadding = `calc(${Layout.Spacing._XS} - 2px)`;
export const sidebarContentWidth = `calc(${collapsedWidth} - ${sidebarPadding} - ${sidebarPadding})`;

export const sidebarBackgroundColor = Colors.Primary._700;
export const sidebarIconDefaultColor = Colors.Neutral._400;

export const TogglePositionContainer = styled.div`
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 250;
  width: ${({ expanded }) => (expanded ? expandedWidth : collapsedWidth)};
  min-width: ${({ expanded }) => (expanded ? expandedWidth : collapsedWidth)};
  transition: ${UtilityEffects.SidebarTransition()};
`;

export const Container = styled.div`
  z-index: -1;
  padding-right: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100dvh;
  overflow-y: auto;
  background: ${sidebarBackgroundColor};
  transition: ${UtilityEffects.SidebarTransition()};
  width: ${({ expanded }) => (expanded ? expandedWidth : collapsedWidth)};
  min-width: ${({ expanded }) => (expanded ? expandedWidth : collapsedWidth)};
  ${Styles.flexColumnStart};
`;

export const Scroll = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${ResponsiveSizes.minWindowHeight}px;
  padding: ${sidebarPadding};
`;

export const LogoContainer = styled.div`
  justify-content: flex-end;
  height: 93px;
  gap: ${Layout.Spacing._XS};
  transition: ${UtilityEffects.SidebarTransition()};
  transform: ${({ expanded }) =>
    expanded ? 'translateX(0)' : 'translateX(-12px)'};
  ${Styles.flexColumn};
`;

export const togglePlacement = {
  position: 'absolute',
  top: `calc(${sidebarPadding} + ${sidebarLogoSize} + ${Layout.Spacing._2XS})`,
  left: `calc(100% - 8px)`,
};

export const NavigationIconsContainer = styled.div`
  position: relative;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumnStart};
  .page-indicator {
    transform: translateX(16px);
  }
  #add_reference_data {
    span {
      color: ${Colors.Sharp.White} !important;
    }
  }
`;

export const transition = UtilityEffects.SidebarTransition();

const RightReveal = styled.div`
  display: flex;
  gap: ${Layout.Spacing._XS};
  ${Styles.flex};
  overflow: hidden;
`;

export const BrandOuterContainer = styled(Flex)`
  &:hover {
    .brand-hover-bg {
      background: ${({ expanded }) => (expanded ? Colors.Sharp.White : '')}${hexOpacity(10)};
    }
  }
`;

export const BrandContainer = styled(RightReveal)`
  position: relative;
  flex-grow: 1;
  ${({ isClickable, expanded }) =>
    isClickable
      ? `
  cursor: pointer;
  .brand-logo-container {
    transition: ${Effects.Transition.EaseInOut()};
  }
  min-height: 52px;
  &:hover {
    .brand-logo-container {
      transition: ${Effects.Transition.EaseInOut()};
      transform: ${expanded ? '' : 'scale(1.083)'};
      }
    }
  `
      : ''}
`;

const outerEdge = '-4px';
export const BrandHoverBG = styled.div`
  position: absolute;
  inset: ${outerEdge};
  border-radius: ${Effects.Border_Radius._S};
`;

export const ProfileContainer = styled(RightReveal)`
  gap: 8px;
  position: relative;
  cursor: pointer;
  overflow: ${({ expanded }) => (expanded ? 'unset' : '')};
  .avatar {
    transition: ${Effects.Transition.EaseInOut()};
  }
  &:hover {
    .avatar {
      transition: ${Effects.Transition.EaseInOut()};
      transform: ${({ expanded }) => (expanded ? '' : 'scale(1.125)')};
    }
    .brand-hover-bg {
      background: ${({ expanded }) => (expanded ? Colors.Sharp.White : '')}${hexOpacity(10)};
    }
  }
`;

export const UserAvatarContainer = styled.div`
  padding: 2px 0;
  min-width: ${sidebarContentWidth};
  background: ${sidebarBackgroundColor};
  ${Styles.flexCenter};
`;

export const LogoAndVersionContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  overflow: hidden;
  ${Styles.flex};
`;

export const CountercheckLogoCSS = styled.img`
  position: absolute;
  z-index: 0;
  min-width: 100px;
  max-width: 100px;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: unset;
`;

export const VersionBadgeBackgroundCover = styled.div`
  margin-left: auto;
  width: ${sidebarContentWidth};
  z-index: 1;
  height: ${Layout.Spacing._S};
  background: ${sidebarBackgroundColor};
  border-radius: ${Effects.Border_Radius._S};
  ${Styles.flexCenter};
`;

export const VersionBadgeCSS = styled.div`
  height: 12px;
  padding: 0 ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._2XL};
  background: ${Colors.Sharp.White};
  ${Styles.flexCenter};
`;

export const menuYAxisPadding = Layout.Spacing._XS;
export const menuXAxisPadding = Layout.Spacing._S;

export const MenuContainer = styled.div`
  background: ${Colors.Sharp.White};
  position: absolute;
  left: 100%;
  top: 0;
  padding: ${menuYAxisPadding} ${menuXAxisPadding};
  box-shadow: ${Effects.Box_Shadow._L};
`;

export const MenuPointerPosition = styled.div`
  top: ${({ top }) => top}px;
  right: 0;
  position: absolute;
`;

export const MoreIconsScrollCSS = styled.div`
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  width: 80%;
  background: ${sidebarBackgroundColor};
  ${({ direction }) => (direction === 'up' ? 'top: -12px' : 'bottom: -12px')};
  ${Styles.flexCenter};
`;
