import { Text } from '../../atoms';
import { Colors, FontKeys } from '../../style';

export const BadgeText = ({ text, size, color = Colors.Sharp.White }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
      color={color}
      ellipsis
    />
  );
};
