import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { Styles } from '../../../constants';
import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';
import { Colors, Effects, FontKeys, Layout, Size } from '../../style';

export const Container = styled.div`
  position: relative;
  border-radius: ${Effects.Border_Radius._S};
  background-color: ${Colors.Sharp.White};
  padding: ${Layout.Spacing._S};
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

const MenuOptionCSS = styled.div`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 20px;
  grid-gap: ${Layout.Spacing._2XS};
  svg {
    grid-column: 1;
    grid-row: 1;
  }
  .title {
    grid-column: 2;
    grid-row: 1;
  }
  .description {
    grid-column: 2;
    grid-row: 2;
  }
`;

const Title = ({ text, color }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Semibold}
      color={color}
      className="title"
    />
  );
};

const Description = ({ text, comingSoon }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Regular}
      color={comingSoon ? Colors.Neutral._500 : ''}
      className="description"
    />
  );
};

export const MenuOption = ({
  option,
  selected,
  handleClick,
  moveIndicator,
  comingSoon,
}) => {
  const { icon, title, description } = option;
  const color = comingSoon
    ? Colors.Neutral._500
    : selected
    ? Colors.Primary._600
    : undefined;

  const optionRef = useRef(null);

  useEffect(() => {
    if (selected) moveIndicator(optionRef.current);
  }, [selected]);

  return (
    <MenuOptionCSS
      ref={optionRef}
      onClick={comingSoon ? undefined : handleClick(option)}
    >
      <Icon name={icon} color={color} size={Size.Icon._M} />
      <Title text={title} color={color} />
      <Description text={description} comingSoon={comingSoon} />
    </MenuOptionCSS>
  );
};
