import styled from '@emotion/styled';
import { Text } from '../../atoms';
import { Colors, Effects, FontKeys, Layout } from '../../style';

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  min-width: 228px;
  max-width: 568px;
  z-index: 50;
  transform: translateY(
    calc(
      -${props => (Math.min(props.index, 4) + 1) * 100}% - calc(${Layout.Spacing
              ._XS} * ${props => Math.min(props.index, 4) + 1})
    )
  );
  left: ${props => props.sidebarWidth + 32}px;
  animation: showAndFade ${props => props.timeout}ms linear;
  transition: transform 450ms ease-in-out,
    ${Effects.Transition.EaseInOut('left')};

  @keyframes showAndFade {
    0%,
    100% {
      opacity: 0;
    }
    8%,
    90% {
      opacity: 1;
    }
  }
  .card {
    border-radius: ${Effects.Border_Radius._2XS};
  }
`;

export const Content = ({ text, size }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral._500}
    />
  );
};

export const Type = ({ text, size }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={FontKeys.Weight.Semibold}
    />
  );
};
