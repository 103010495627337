import { createContext, useContext, useState } from 'react';
import { strip } from '../lib/js';

const UIContext = createContext();

export function useUI() {
  return useContext(UIContext);
}

const UIProvider = ({ children }) => {
  const [UI, setUI] = useState({});

  const updateSharedUI = newUI => {
    const update = UI => {
      return {
        ...UI,
        // strip() prevents undefined/null values from overwriting non-null values.
        // Might mean stale values if we want something to be null/undefined.
        ...strip(newUI),
      };
    };
    setUI(update);
  };

  return (
    <UIContext.Provider value={{ UI, updateSharedUI }}>
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
