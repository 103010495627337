import { UtilityColors } from '../../style';

export const styles = ({
  background = UtilityColors.Border,
  customStyle = {},
}) => {
  return {
    height: '1px',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    background,
    ...customStyle,
  };
};
