import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import { StatusIndicator } from '../../../../../../components/atoms/StatusIndicator/StatusIndicator';
import Button from '../../../../../../components/molecules/Button/Button';
import { NoResults } from '../../../../../../components/molecules/NoResults/NoResults';
import {
  DotCompleted,
  DotStatus,
  JoinedStrings,
  RowAction,
  RowDateVertical,
  RowMultipleImages,
  RowSimpleText,
  Selected,
  SimpleStatus,
  TableContractedBrandName,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { parseStringModifiers } from '../../../../../../utils/utils';
import {
  parcelKeys,
  tabs,
} from '../../../../features_public/parcels/controllers/variables';
import {
  components,
  productKeys,
} from '../../../../features_public/products/controllers/variables';
import { SendMessageCSS } from '../../../support/style';
import { noResults } from '../../variables';
import {
  EntryInfo,
  EntryTitle,
  ErrorText,
  ParcelDetail,
  ParcelDetailTitle,
  PriorityBadge,
  StatusBadge,
} from '../atoms/atoms';
import { InfoS, ParcelDetailsSectionS, TicketS } from '../style';

const Aliases = ({
  hasParcelViewPermission,
  onProductClick,
  onParcelClick,
}) => {
  return item => {
    const product = RowSimpleText(productKeys.productAlias, 'bold', true, {
      onClick: Utils.emptyFunction,
      copyable: true,
    })(item);
    const parcel = hasParcelViewPermission
      ? RowSimpleText(productKeys.parcel.alias, 'regular', false, {
          onClick: Utils.emptyFunction,
          copyable: true,
        })(item)
      : Utils.emptyFragment;

    return (
      <Flex column w>
        <Flex>{product}</Flex>
        <Flex>{parcel}</Flex>
      </Flex>
    );
  };
};

export const tableComponents = ({
  showGallery,
  toggleRowChecked,
  checkedRows,
  hasParcelViewPermission,
  hasStatusEditPermission,
  openParcelSummary,
  rowAction,
}) => ({
  [productKeys.statusIndicator]: {
    display: StatusIndicator(productKeys.productStatus),
  },
  [productKeys.selected]: {
    display: hasStatusEditPermission
      ? Selected(toggleRowChecked, checkedRows)
      : Utils.emptyFragment,
    action: hasStatusEditPermission ? Utils.avoidRowExpand : Utils.trueReturn,
  },

  [productKeys.photos]: {
    display: RowMultipleImages({
      photosKey: productKeys.photos,
      size: '_S',
      amount: 3,
      showGallery,
      hoverZoom: true,
    }),
    action: Utils.falseReturn,
  },
  [productKeys.productAlias]: {
    display: Aliases({
      hasParcelViewPermission,
      // onProductClick: rowAction,
      // onParcelClick: openParcelSummary(tabs.overview),
    }),
    action: Utils.falseReturn,
  },
  [productKeys.brandObj.name]: {
    display: TableContractedBrandName(
      productKeys.brandObj.name,
      productKeys.isContracted
    ),
  },
  [productKeys.productSideloadDate]: {
    display: RowDateVertical(productKeys.productSideloadDate),
  },
  [productKeys.inspectionDate]: {
    display: RowDateVertical(productKeys.inspectionDate),
  },
  [productKeys.category]: {
    display: JoinedStrings(
      productKeys.subCategory,
      productKeys.category,
      undefined,
      { column: true }
    ),
  },
  [productKeys.productStatus]: {
    display: SimpleStatus(productKeys.productStatus),
  },
  [productKeys.productQuantity]: {
    display: RowSimpleText(productKeys.productQuantity, 'bold'),
  },
  [parcelKeys.nextStep]: {
    display: hasParcelViewPermission
      ? DotStatus(parcelKeys.nextStep)
      : Utils.emptyFragment,
    action: openParcelSummary(tabs.overview),
  },
  [productKeys.parcel.origin]: {
    display: hasParcelViewPermission
      ? DotCompleted(productKeys.parcel.origin)
      : Utils.emptyFragment,
    action: openParcelSummary(tabs.origin),
  },
  [productKeys.parcel.destination]: {
    display: hasParcelViewPermission
      ? DotCompleted(productKeys.parcel.destination)
      : Utils.emptyFragment,
    action: openParcelSummary(tabs.destination),
  },
  [productKeys.parcel.summary]: {
    display: hasParcelViewPermission
      ? RowAction({
          action: item => () => openParcelSummary(tabs.overview)(item),
          icon: Icons.PackageLink,
          size: Size.Icon._M,
          theme: 'light',
        })
      : null,
    action: Utils.avoidRowAction,
  },
});

export const ParcelDetailsSection = ({ section, index }) => {
  const { mock, data, className } = section;
  return (
    <ParcelDetailsSectionS index={index} className={className}>
      {data.map(detail => {
        const { title, info, element } = detail;
        return (
          <Flex column key={title} w>
            <ParcelDetailTitle text={title} />
            <Flex w relative justify="between" align={'center'}>
              <ParcelDetail mock={mock} text={parseStringModifiers(info)} />
              {element}
            </Flex>
          </Flex>
        );
      })}
    </ParcelDetailsSectionS>
  );
};

export const Ticket = ({ ticket, onClick }) => {
  const { ticketId, alias, mainTopic, priority, status } = ticket;
  return (
    <TicketS onClick={onClick(ticketId)}>
      <TicketInfo alias={alias} mainTopic={mainTopic} />
      <Badges priority={priority} status={status} />
    </TicketS>
  );
};

const TicketInfo = ({ alias, mainTopic }) => {
  return (
    <InfoS>
      <Text
        text={alias}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        color={Colors.Neutral._500}
        ellipsis
      />
      <Text
        text={`• ${mainTopic}`}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        ellipsis
      />
    </InfoS>
  );
};

const Badges = ({ priority, status }) => {
  return (
    <Flex justify="center" align="center" gap="_XS">
      <PriorityBadge priority={priority} />
      <StatusBadge status={status} />
      <Icon name={Icons.ChevronRight} size={Size.Icon._M} />
    </Flex>
  );
};

export const AuthorityEntry = ({ title, info }) => {
  return (
    <Flex column>
      <EntryTitle text={title} />
      <EntryInfo text={info} />
    </Flex>
  );
};

export const SendMessage = ({ onClick, isLoading, disabled }) => {
  return (
    <SendMessageCSS>
      <Button
        variant="Primary"
        onClick={onClick}
        text="Send"
        size="_S"
        disabled={disabled}
        isLoading={isLoading}
      />
    </SendMessageCSS>
  );
};

export const ProductTicketsContainer = ({ tickets, onTicketClick }) => {
  return (
    <Flex column scroll gap="_2XS">
      {tickets && tickets.length ? (
        tickets.map(ticket => {
          return (
            <Ticket onClick={onTicketClick} ticket={ticket} key={ticket} />
          );
        })
      ) : (
        <NoResults {...noResults[components.productTickets]} />
      )}
    </Flex>
  );
};

export const ErrorDisplay = () => (
  <Flex column gap="_S" justify="center" align="center" w h>
    <Icon
      name={Icons.AlertTriangle}
      color={Colors.Neutral._500}
      size={Size.Icon._L}
    />
    <ErrorText text={'Oops! Something went wrong'} />
  </Flex>
);
