import { Utils } from '../../../constants';
import { Image } from '../../atoms/Image/Image';
import { RemainingCount } from '../../templates/Gallery/components';
import { ImageStyle } from './ItemPreviewImage.style.';

export const ItemPreviewImage = ({
  src,
  size = '_M',
  onClick,
  caseImageRef: ref,
  index,
  noOfImages,
  amount,
  hoverZoom,
}) => {
  return (
    <ImageStyle
      size={size}
      onClick={onClick ?? Utils.emptyFunction}
      ref={ref}
      isClickable={!!onClick}
      data-index={index}
      hoverZoom={hoverZoom}
      className="item-preview-image"
    >
      <Image src={src} />
      <RemainingCount
        noOfImages={noOfImages}
        index={index}
        type={'preview'}
        amount={amount}
      />
    </ImageStyle>
  );
};
