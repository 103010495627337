import { useEffect, useState } from 'react';
import { daysBetween } from '../../../../utils/timeUtils';
import { Flex } from '../../../atoms/Flex/Flex';
import Actions from '../../../organisms/Actions/Actions';
import { Section } from '../../Dropdown/Dropdown.components';
import {
  CalendarStyles,
  Container,
  PresetOptions,
  Range,
} from './Datepicker.style';
import { markSelected } from './utils';
import { actions, presetOptions } from './variables';

const DatepickerContainer = ({ children, setAction, dateKit }) => {
  const [dateRange, setDateRange] = useState();
  const { selectedPreset, startDate, endDate, setSelectedPreset, changeDates } =
    dateKit;

  useEffect(() => {
    if (startDate && endDate) setDateRange(daysBetween(startDate, endDate));
    else setDateRange(null);
  }, [startDate, endDate]);

  const applyCalendarChanges = () => setAction(actions.apply);
  const cancelCalendarChanges = () => setAction(actions.cancel);

  const filterAction =
    ({ dates, value }) =>
    () => {
      if (value === selectedPreset) return;
      const { start, end } = dates();
      changeDates(start, end);
      setSelectedPreset(value);
    };

  return (
    <Container>
      <Flex gap="_S" justify="between">
        <PresetOptions>
          <Section
            filterAction={filterAction}
            section={{
              options: markSelected(presetOptions, selectedPreset),
            }}
            highlight
          />
        </PresetOptions>
        <CalendarStyles>{children}</CalendarStyles>
      </Flex>
      <Flex justify="between" align="center">
        <Range text={dateRange} />
        <Actions
          size="_S"
          primaryText="Apply"
          onPrimary={applyCalendarChanges}
          onCancel={cancelCalendarChanges}
          disabled={endDate == null}
        />
      </Flex>
    </Container>
  );
};

export default DatepickerContainer;
