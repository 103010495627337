import isoCountryList from '../../../../../utils/isoCountryList';
import { whiteLabelKeys } from './variables';

export const formatReceiverFields = (data, country) => ({
  [whiteLabelKeys.fullnameField]: data?.name,
  [whiteLabelKeys.companyField]: data?.company,
  [whiteLabelKeys.addressField]: data?.address,
  [whiteLabelKeys.phoneField]: data?.phone,
  [whiteLabelKeys.postcodeField]: data?.postcode,
  [whiteLabelKeys.cityField]: data?.city,
  [whiteLabelKeys.stateField]: data?.state,
  [whiteLabelKeys.countryField]: country,
});

export const formatInitialValues = (data, customerDataOptions = []) => {
  const { [whiteLabelKeys.customerIdField]: customerIdField_ } = data ?? {};

  const { [whiteLabelKeys.countryField]: countryField_ } = data?.receiver ?? {};

  const customerOption = customerDataOptions.find(
    option => option.value === customerIdField_
  );
  const countryOption = isoCountryList.find(option =>
    Object.values(option).includes(countryField_)
  );
  const receiverFields = formatReceiverFields(data?.receiver, countryOption);

  const senderField = { [whiteLabelKeys.customerIdField]: customerOption };

  const initialValues = {
    ...receiverFields,
    ...senderField,
    [whiteLabelKeys.countryField]: countryField_,
    [whiteLabelKeys.customerIdField]: customerIdField_,
  };

  return { initialValues, senderField, receiverFields };
};
