import { prepareDataForDropdown } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { DC } from '../controllers/variables';

export const useGetFilters = ({ ignore }) => {
  const [{ data, isLoading = true, isError, error }, _, refetchFilters] =
    useFetchData('knowledge_base/filters', {
      formatter,
      ignore,
    });

  return {
    data,
    isLoading,
    isError,
    error,
    refetchFilters,
  };
};

export const formatter = data => {
  const { categories, adding_clients, brands } = data;

  const newOps = {};

  newOps[DC.PRODUCT_CATEGORY] = prepareDataForDropdown(
    categories,
    'product_name',
    'kbProduct'
  );

  newOps[DC.ADDED_BY] = prepareDataForDropdown(
    adding_clients,
    'usDisplayName',
    'bguaID',
    {
      sectionTitle: 'Others',
    }
  );

  newOps[DC.BRANDS] = prepareDataForDropdown(brands, 'brName', 'brID');

  return newOps;
};
