import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import {
  formatProducts,
  noPermissionsData,
} from '../formatters/formatProducts';

export const useGetProducts = ({ searchParams, hasPermission }) => {
  const ignore = !hasPermission;
  const [
    {
      data = formatProducts({ hasPermission })({}),
      isLoading = hasPermission,
      isError,
    },
    _,
    __,
    refresh,
  ] = useFetchData('office_dashboard/product_counts', {
    formatter: formatProducts({ hasPermission }),
    failureFormatter: x => noPermissionsData,
    ignore,
    params: formatParams(searchParams),
  });

  return { data, isLoading, isError, refresh };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
