import { Icon } from '../../../../../components/atoms';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Colors } from '../../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';

export const adminServicesKeys = {
  //  //
  //  these names are specific for proper display on admin_brands  //
  icon: 'icon',
  id: 'id',
  name: 'text',
  description: 'detail',
  //  //
  //  //
  systemName: 'systemName',
  type: 'type',
  addedAt: 'addedAt',
  addedBy: {
    name: 'addedBy.name',
    image: 'addedBy.image',
  },
  role: {
    name: 'roles.name',
    key: 'roles.key',
    detail: 'roles.detail',
    id: 'roles.id',
  },
  permission: {
    name: 'roles.permissions.name',
    key: 'roles.permissions.key',
    value: 'roles.permissions.value',
  },
  brands: {
    havingServiceCount: 'brands.havingServiceCount',
  },
};

const keys = adminServicesKeys;

export const DC = {
  ...DISPATCH_CONSTANTS,
  SERVICE: 'SERVICE',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.SERVICE]: {
    type: FilterTypes.dropdown,
    icon: Icons.Component,
    param: '',
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const headers = {
  expansion: '',
  [keys.name]: 'Product service',
  [keys.description]: 'Description',
  [keys.addedAt]: 'Added at',
  [keys.addedBy.name]: 'Added by',
  [keys.brands.havingServiceCount]: (
    <Icon name={Icons.Building} color={Colors.Neutral._500} />
  ),
  options: '',
};

export const grid = {
  gridTemplateColumns: cleanGridStyle(`
    // expansion //
    48px
    // service //
    minmax(140px, 0.6fr)
    // description //
    minmax(200px, 1.15fr)
    // added at //
    minmax(80px, 0.5fr)
    // added by //
    minmax(120px, 0.55fr)
    // brands //
    54px
    // options //
    48px
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const initialFilterValues = {
  [DC.SERVICE]: '',
};

export const titles = {
  [DC.SERVICE]: 'Service type',
};

export const allOptions = {
  [DC.SERVICE]: { label: 'All', value: Constants.all },
};

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Cases,
    title: 'No services',
    description: 'Start by adding a new service',
  }),
  noSearchResults: {
    icon: NoResults.Cases,
    title: 'No results found',
    description: 'Please try another search or view all services',
  },
};

export const fieldNames = {
  type: 'type',
  icon: 'icon',
  name: 'name',
  systemName: 'key',
  description: 'detail',
  adminRoleDescription: 'adminRoleDescription',
  editorRoleDescription: 'editorRoleDescription',
  viewerRoleDescription: 'viewerRoleDescription',
  permissions: {
    row: 'permissions',
    name: 'permissionName',
    systemName: 'permissionSystemName',
    admin: 'admin',
    editor: 'editor',
    viewer: 'viewer',
    isNew: 'isNew',
  },
};

export const permissionInit = isNew => {
  return {
    [fieldNames.permissions.name]: '',
    [fieldNames.permissions.systemName]: '',
    [fieldNames.permissions.admin]: false,
    [fieldNames.permissions.editor]: false,
    [fieldNames.permissions.viewer]: false,
    [fieldNames.permissions.isNew]: isNew,
  };
};

export const permissionGridHeaders = [
  { text: '' },
  { text: 'Permission name' },
  { text: 'Permission ID' },
  { text: 'Admin', center: true },
  { text: 'Editor', center: true },
  { text: 'Viewer', center: true },
];

export const roleTypes = [
  fieldNames.permissions.admin,
  fieldNames.permissions.editor,
  fieldNames.permissions.viewer,
];

export const expansionHeaders = {
  [fieldNames.permissions.name]: 'Permission',
  [fieldNames.permissions.admin]: 'Admin',
  [fieldNames.permissions.editor]: 'Editor',
  [fieldNames.permissions.viewer]: 'Viewer',
  // space: '',
};

const permissionTypeWidth = '120px';

export const expansionGrid = {
  gridTemplateColumns: cleanGridStyle(`
    // permission //
    minmax(140px, 1fr)
    // admin //
    ${permissionTypeWidth}
    // editor //
    ${permissionTypeWidth}
    // viewer //
    ${permissionTypeWidth}
    `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};
