import styled from '@emotion/styled';
import { Styles } from '../../../constants';

import {
  Colors,
  Effects,
  FontKeys,
  Font_Properties,
  Layout,
  UtilityColors,
} from '../../style';

const calendarBlue = Colors.Primary._600;
const selectionBG = Colors.Neutral._100;
const dayHorizontalPadding = Layout.Spacing._3XS;

const selectionStyles = {
  content: '""',
  border: `1px solid ${calendarBlue}`,
  borderRadius: '50%',
  width: Layout.Spacing._M,
  height: Layout.Spacing._M,
  display: 'block',
  position: 'absolute',
  margin: 'auto',
  inset: 0,
};

const inRangeDayBackground = {
  content: '""',
  background: selectionBG,
  width: `calc(${Layout.Spacing._M} + calc(2 * ${dayHorizontalPadding}))`,
  height: Layout.Spacing._M,
  display: 'block',
  position: 'absolute',
  margin: 'auto',
  inset: 0,
  zIndex: -2,
  left: `calc(${dayHorizontalPadding} / -2)`,
};

// gray bg
const rangeLimit = {
  content: '""',
  width: `calc(${Layout.Spacing._M} + calc(2 * ${dayHorizontalPadding}))`,
  height: Layout.Spacing._M,
  display: 'block',
  position: 'absolute',
  margin: 'auto',
  inset: 0,
  zIndex: -2,
};

const limitBackground = {
  ...inRangeDayBackground,
  width: `calc(100% - ${dayHorizontalPadding})`,
};

const startLimitBackground = {
  ...limitBackground,
  borderRadius: ' 32px 0 0 32px',
};

const endLimitBackground = {
  ...limitBackground,
  borderRadius: ' 0 32px  32px 0',
};

const style = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  ...Styles.flexCenter,
  borderRadius: ' 16px',
  fontSize: Font_Properties.Size._S,
  '& .datepicker-custom': {
    height: '100%',
    width: '100%',
  },
  '& .react-datepicker': {
    '&-wrapper': {
      height: '100%',
    },
    '&__input-container': {
      height: '100%',
      width: '100%',
      '& input': {
        width: '105%',
        height: '100%',
        cursor: 'pointer',
        left: '-12px',
        position: 'relative',
      },
    },
    '&__tab-loop': {
      visibility: 'visible',
    },
    '&__navigation': {
      top: '-2px',
      '&-icon::before': {
        borderColor: Colors.Neutral._400,
        width: '8x',
        height: '8x',
        borderWidth: '2px 2px 0 0',
      },
    },
    '&__month-container': {
      width: '264px',
    },
    '&__month': {
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
      gap: Layout.Spacing._XS,
    },
    '&__current-month': {
      display: 'none',
    },
    '&__year': {
      '&-option': {
        '&:first-of-type, &:last-of-type': {
          height: '20px',
          ...Styles.flexCenter,
        },
        '&:first-of-type': {
          '& a': {
            transform: 'rotate(45deg)',
          },
        },
        '&:last-of-type': {
          '& a': {
            transform: 'rotate(225deg)',
            top: '-3px',
          },
        },
        '& a': {
          width: '10px',
          height: '10px',
          position: 'relative',
          border: `2px solid ${Colors.Neutral._900}`,
          borderBottom: 'unset',
          borderRight: 'unset',
        },
      },
    },

    '&__month, &__year': {
      '&-dropdown': {
        background: Colors.Sharp.White,
        border: 'unset',
        padding: Layout.Spacing._2XS,
        boxShadow: Effects.Box_Shadow._S,
      },
    },

    '&__month-dropdown-container, &__month-dropdown-container--scroll, &__year-dropdown-container, &__year-dropdown-container--scroll ':
      {
        height: Layout.Spacing._S,
        '& span': {
          visibility: 'initial',
          marginTop: '1px',
          fontSize: Font_Properties.Size._M,
          lineHeight: Font_Properties.Height._M,
          fontWeight: Font_Properties.Weight[FontKeys.Weight.Semibold],
          color: calendarBlue,
        },
      },

    '&__month-dropdown-container': {
      marginRight: '24px',
    },
    '&__day ': {
      height: Layout.Spacing._S,
      width: Layout.Spacing._S,
      padding: `${dayHorizontalPadding} 6px`,
      lineHeight: Layout.Spacing._S,
      boxSizing: 'unset',
      fontSize: '14px',
      margin: 0,
      position: 'relative',
      borderRadius: 'unset',
      background: 'transparent',
      '&-name ': {
        color: UtilityColors.Text,
        margin: `${Layout.Spacing._2XS} 6px`,
        width: '24px',
        lineHeight: '24px',
        fontWeight: Font_Properties.Weight[FontKeys.Weight.Semibold],
      },
      '&-names': {
        marginBottom: '8px',
        padding: '0 8px',
      },
      '&--outside-month, &--disabled ': {
        color: Colors.Neutral._500,
      },
      '&--today ': {
        fontWeight: 'unset',
      },
      '&--in-range, &--in-selecting-range': {
        background: 'unset',
        color: Colors.Neutral._900,
        '&::before': inRangeDayBackground,
        '&:last-child': {
          '&::before': {
            ...inRangeDayBackground,
            borderRadius: '0 32px 32px 0',
            left: `calc(${dayHorizontalPadding} / -1)`,
          },
        },
        '&:first-of-type': {
          '&::before': {
            content: '""',
            ...inRangeDayBackground,
            borderRadius: '32px 0 0 32px',
            left: 0,
          },
        },
        '&:hover': {
          background: 'unset',
        },
      },
      '&--selected': {
        color: 'unset',
      },
      '&:hover:not(.react-datepicker__day--disabled)': {
        // backgroundColor: 'unset',
        '&::after': selectionStyles,
      },
      '&--range-start, &--selecting-range-start': {
        '&::before': startLimitBackground,
        '&:not(.react-datepicker__day--range-end)::after': {
          background: `linear-gradient(90deg, transparent 50%, ${selectionBG} 50%)`,
          ...rangeLimit,
        },
      },
      '&--range-end, &--selecting-range-end': {
        '&::before': {
          background: `linear-gradient(90deg, ${selectionBG} 50%, transparent 50%)`,
          ...rangeLimit,
        },
        '&:not(.react-datepicker__day--range-start)::after': endLimitBackground,
      },
      '&--range-start, &--selecting-range-start, &--range-end.react-datepicker__day--range-end, &--selecting-range-end, &--selected.react-datepicker__day--selected':
        {
          color: Colors.Sharp.White,
          '&::before': {
            content: '""',
            background: calendarBlue,
            borderRadius: '50%',
            width: Layout.Spacing._M,
            height: Layout.Spacing._M,
            display: 'block',
            position: 'absolute',
            margin: 'auto',
            inset: 0,
            zIndex: -1,
          },
        },
      '&--keyboard-selected': {
        background: 'transparent',
      },
    },

    '&__triangle': {
      display: 'none',
    },
    '&__header': {
      background: Colors.Sharp.White,
      border: 'unset',
      padding: '0',
      '&__dropdown--scroll': {
        background: Colors.Sharp.White,
        padding: '0',
        border: 'unset',
        marginBottom: Layout.Spacing._XS,
      },
    },
    '&__year-read-view, &__month-read-view': {
      paddingRight: '16px',
      boxSizing: 'border-box',
      '&--down-arrow, &--down-arrow': {
        border: 'none',
        borderLeft: '1.5px solid #1f2025',
        borderBottom: '1.5px solid #1f2025',
        transform: 'rotate(-45deg)',
        display: 'inline-block',
        right: '0',
        top: '4px',
        width: '7px',
        height: '7px',
      },
    },
    '&-popper': {
      paddingTop: '0',
      backgroundColor: Colors.Sharp.White,
      marginTop: Layout.Spacing._2XS,
      borderRadius: '6px',
      marginLeft: `-${Layout.Spacing._2XS} !important`,
      border: `1px solid ${Colors.Neutral._200}`,
      '& > div': {
        overflow: 'hidden',
        boxShadow: Effects.Box_Shadow._S,
        borderRadius: Effects.Border_Radius._S,
      },
    },
  },
};

export const CalendarContainer = styled.div(style, props => ({
  '.react-datepicker-popper': { display: props.isOpen ? 'block' : 'none' },
  input: {
    opacity: 0,
  },
  transform: 'scale(1)',
}));

export const CalendarFilterPadding = styled.div`
  position: absolute;
  right: 8px;
  top: calc(100% - 4px);
  width: 100%;
  height: 16px;
`;
