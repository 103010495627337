import { Card } from '../../../../../components/atoms';
import { NoData } from '../../../../../components/atoms/Icon/Icon.options';
import { CardLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import { noResultsState } from '../controllers/variables';
import { Invoices, Payment, Plan } from './organisms/organisms';
import { BillingContainer, BillingNoResultsCSS } from './style';

export const BillingLayout = ({
  noPlan,
  planTitle,
  planPrice,
  planDetails,
  headers,
  invoices,
  rowAction,
  grid,
  isLoading,
  isError,
  error,
  noData,
  noDataDisplay,
}) => {
  return (
    <BillingContainer columns={8}>
      <Card title="Plan" className="plan">
        {isLoading ? (
          <CardLoader />
        ) : noPlan ? (
          <BillingNoResultsCSS>
            <NoSearchResults
              description={noResultsState.plan.description}
              icon={noResultsState.plan.icon}
            />
          </BillingNoResultsCSS>
        ) : (
          <Plan
            planTitle={planTitle}
            planPrice={planPrice}
            planDetails={planDetails}
          />
        )}
      </Card>
      <Card title="Payment method" className="payment" comingSoon>
        <Payment />
      </Card>
      <Card title="Invoices" className="invoices">
        {!invoices?.length ? (
          <NoSearchResults
            icon={NoData.Invoices}
            description={'**No invoices**'}
          />
        ) : (
          <Invoices
            data={invoices}
            headers={headers}
            grid={grid}
            rowAction={rowAction}
            isLoading={isLoading}
            isError={isError}
            error={error}
            noData={noData}
            noDataDisplay={noDataDisplay}
          />
        )}
      </Card>
    </BillingContainer>
  );
};
