import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants } from '../../../../../constants';
import { extractImageName } from '../../../../../features/file_upload/controllers/utils';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { useAddContract } from '../api/addContract';
import { AddContractLayout } from '../components/AddContractLayout';
import { brandManagementKeys as keys, uploadStatusContent } from './variables';

const folder = 'contracts';
export const fieldNames = {
  name: 'name',
  description: 'detail',
};

const fields = [
  {
    key: uniqueId(),
    name: fieldNames.name,
    label: 'Name',
    placeholder: 'Enter a contract name',
    size: '_M',
  },
  {
    key: uniqueId(),
    name: fieldNames.description,
    label: 'Description',
    placeholder: 'Enter a short description',
    size: '_M',
    type: 'textarea',
  },
];

const addContractInitialValues = {};
fields.forEach(field => (addContractInitialValues[field.name] = ''));

const addContractValidationSchema = Yup.object().shape({
  [fieldNames.name]: Yup.string().required('Required'),
  [fieldNames.description]: Yup.string().notRequired('Required'),
});

const mapFieldsToResponse = {
  [fieldNames.name]: keys.contract.name,
  [fieldNames.description]: keys.contract.description,
};

const AddContract = ({ onSuccess, mode = 'add', contract }) => {
  const isEditing = mode === 'edit';
  const preexistingFile = isEditing ? contract[keys.contract.file] : null;

  const [enableUpload, toggleEnableUpload] = useToggle(
    !isEditing || !preexistingFile
  );

  const { brandId } = useParams();

  const { dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();

  const { body, isLoading, isError, error, doAddContract } = useAddContract({
    isEditing,
  });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess(isEditing ? 'Contract updated' : 'Contract added');
    onSuccess();
  }, [body, isError, isLoading]);

  useEffect(() => {
    return () => resetFileUpload();
  }, []);

  const onSubmit = values => {
    if (enableUpload && fileState.files?.length === 1)
      handleImport({
        folder,
        parameterName: 'names',
        successCallback: ({ uploadNames }) => {
          showSnackbarSuccess('Attachments successfully uploaded');
          handleAddContract(values, uploadNames);
          resetFileUpload();
        },
        errorCallback: () => {
          showSnackbarError('Error uploading attachments');
          resetFileUpload();
        },
      });
    else handleAddContract(values);
  };

  const handleAddContract = (values, uploadNames) => {
    const url = isEditing
      ? `admin_brand/${brandId}/contract/${contract[keys.contract.id]}`
      : `admin_brand/${brandId}/contracts`;
    const body = {
      ...values,
    };
    const file = uploadNames?.[0] ?? contract?.[keys.contract.file];

    body.contract = file.includes('http')
      ? extractImageName(file, folder)
      : file;

    doAddContract(body, url);
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData: contract,
        mapFieldsToResponse,
        fields,
      });
    }
    return initialValues;
  };

  const disabled =
    fileState.isUploading ||
    isLoading ||
    fileState.errorType !== null ||
    (fileState.files?.length !== 1 && !preexistingFile);

  const nonFormikDirty = () => {
    if (isEditing) {
      if (enableUpload && fileState?.files?.length) return true;
    }
    return false;
  };

  return (
    <Formik
      initialValues={withPrevData(addContractInitialValues)}
      validationSchema={addContractValidationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => {
        return (
          <Form>
            <AddContractLayout
              isEditing={isEditing}
              contractFieldsProps={{
                fields,
              }}
              contractDropzoneProps={{
                handleDrop: handleDrop(
                  Constants.FileUpload.uploadType.brand_contract
                ),
                fileState,
                percentageUploaded: percentage,
                uploadStatusContent: uploadStatusContent({
                  fileState,
                  resetFileUpload,
                  preexistingFile,
                  enableUpload,
                  isNotImage: {
                    folder: 'contracts',
                  },
                }),
                multiple: false,
                enableUpload,
                disabled: !enableUpload,
                toggleEnableUpload:
                  isEditing && preexistingFile && toggleEnableUpload,
              }}
              actionsProps={{
                disabled: !isValid || disabled || !(dirty || nonFormikDirty()),
                isLoading,
                primaryText: isEditing ? 'Save' : 'Add',
                cancelText: 'Cancel',
                onCancel: dismiss,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddContract);
