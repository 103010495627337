import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFullHeightPointerTop } from '../../../../../components/organisms/Sidebar/utils';
import { iconProps } from '../../../../../components/organisms/Sidebar/variables';
import UserProfileCard from '../../../../../components/organisms/UserProfileCard/UserProfileCard';
import { Effects } from '../../../../../components/style';
import { PropKeys } from '../../../../../constants';
import FeaturePreview from '../../../../../features/notifications/FeaturePreview';
import { useGetAnnouncements } from '../../../../../features/notifications/api/getAnnouncements';
import { useGetNotifications } from '../../../../../features/notifications/api/getNotifications';
import { useUpdateNotification } from '../../../../../features/notifications/api/updateNotificationStatus';
import Notifications from '../../../../../features/notifications/controllers/Notifications';
import { notificationKeys } from '../../../../../features/notifications/controllers/variables';
import {
  formatNotifications,
  markAllNotificationsReadUI,
} from '../../../../../features/notifications/formatters/formatNotifications';
import { useScrollLoad } from '../../../../../hooks/scrollLoadHook';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePlatform } from '../../../../../providers/PlatformProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useStore } from '../../../../../providers/StoreProvider';
import { useUI } from '../../../../../providers/UIProvider';
import { clickedBy } from '../../../../../utils/utils';
import { useGetUnreadCount } from '../../../../admin/features/support/api/getUnreadCount';
import { NavURIs, Pages, URIs } from '../../../../admin/routes/variables';
import { BrandOptions } from '../BrandOptions/BrandOptions';
import PrivateSidebarLayout from './PrivateSidebarLayout';
import { menuOptionKeys } from './variables';

export const PrivateSidebar = () => {
  const [navigationIcons, setNavigationIcons] = useState([]);
  const [pointerTop, setPointerTop] = useState('-100000');
  const [menuOption, setMenuOption] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [hasUnreadNotifs, setHasUnreadNotifs] = useState(false);

  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);

  const location = useLocation();

  const { showSnackbarWarning } = useSnackbar();
  const { showModal } = useModal();
  const { store, updateStore } = useStore();
  const { platformRoutes } = usePlatform();
  const { sidebarWidth } = useUI().UI;

  const firstMount = useRef(true);

  const menuLayoutRef = useRef();
  const brandLogoRef = useRef();
  const navigationIconScrollRef = useRef();
  const notificationsIconsRef = useRef();
  const userIconRef = useRef();

  const notificationsRef = useRef();
  const prevCountRef = useRef();
  const tabFocusRef = useRef(true);

  const { doUpdateStatusAll } = useUpdateNotification();

  const { data: _notifications, isLoading: notificationsIsLoading } =
    useScrollLoad({
      hook: useGetNotifications,
      getMore: 'getMoreNotifications',
      moreActivityClicked: true,
      scrollRef: notificationsRef,
      initialLimit: 25,
      reassign: menuOption,
      formatter: formatNotifications,
    });

  const {
    data: announcements,
    isLoading: announcementsIsLoading,
    isError: announcementsIsError,
    refetchAnnouncements,
  } = useGetAnnouncements();

  const { refetchUnreadCount } = useGetUnreadCount();

  const handleUnreadCount = async () => {
    if (!tabFocusRef.current) return;
    const unreadCount = await refetchUnreadCount();
    const prevCount = prevCountRef.current;

    const loadedPageAndThereAreMessages = prevCount == null && unreadCount > 0;
    // unahndled edge case: getting a message at the same time as marking one unread
    // there's a new message, but count is the same, so no warning
    const newMessageWhileOnPlatform =
      prevCount != null && unreadCount > prevCount;

    const hasNewMessages =
      loadedPageAndThereAreMessages || newMessageWhileOnPlatform;

    if (hasNewMessages) showSnackbarWarning('You have new messages in Support');
    updateStore({ [PropKeys.unreadSupportMessageCount]: unreadCount });

    prevCountRef.current = unreadCount;
  };

  useEffect(() => {
    window.addEventListener('click', clearMenu);
    return () => window.removeEventListener('click', clearMenu);
  }, [menuOption]);

  useEffect(() => {
    handleUnreadCount();
    const timeoutId = setInterval(handleUnreadCount, 60_000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    if (firstMount.current) return;

    if (location.pathname.includes(URIs[Pages.support]))
      // allow time for unread count to update when a message is read
      setTimeout(handleUnreadCount, 250);
  }, [location.pathname]);

  useEffect(() => {
    const newIcons = withCustomElements(
      platformRoutes[0]?.children
        .filter(route => route.sidebarIcon)
        .map(route => iconProps(route))
    );
    setNavigationIcons(newIcons);
    // get from usePlatform so permission filter is already completed
    // performance improvement: only update when unread count changes
  }, [platformRoutes[0]?.children, store[PropKeys.unreadSupportMessageCount]]);

  useEffect(() => {
    setNotifications(_notifications);
  }, [_notifications]);

  useEffect(() => {
    const isAnyUnread = notifications.some(
      entry => entry[notificationKeys.read] === 'Unread'
    );

    setHasUnreadNotifs(isAnyUnread);
  }, [notifications]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = navigationIconScrollRef.current ?? {};
      const { scrollTop, scrollHeight, offsetHeight } = scrollContainer;

      const hasIconsAbove = scrollTop > 24;
      const hasIconsBelow = scrollTop + offsetHeight < scrollHeight - 24;

      setShowScrollUp(hasIconsAbove);
      setShowScrollDown(hasIconsBelow);
    };

    setTimeout(handleScroll, 200);

    const scrollContainer = navigationIconScrollRef.current;
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      announcementsIsLoading ||
      announcementsIsError ||
      !announcements?.length
    )
      return;

    const firstAnnouncement = announcements[0];
    if (firstAnnouncement.read === 'Unread') {
      const { id, type } = firstAnnouncement;
      showFeaturePreview({ id, type });
    }
  }, [announcements, announcementsIsLoading, announcementsIsError]);

  useEffect(() => {
    setTimeout(() => (window.tar = navigationIconScrollRef.current), 1000);
  }, []);

  useEffect(() => {
    if (firstMount.current) firstMount.current = false;
  }, []);

  const clearMenu = e => {
    const menuOptions = [
      menuLayoutRef.current,
      brandLogoRef.current,
      notificationsIconsRef.current,
      userIconRef.current,
    ];
    if (e && menuOptions.some(item => clickedBy(item, e))) return;
    setMenuOption(null);
    setPointerTop('-100000');
  };

  const showMenuOption = option => e => {
    if (option === menuOption) return clearMenu();
    const pointerTop = getFullHeightPointerTop(e);
    setPointerTop(pointerTop);
    setMenuOption(option);
  };

  const onNotificationsClose = () => {
    setHasUnreadNotifs(hasUnreadNotifs => {
      if (hasUnreadNotifs) markAllNotificationsRead();
      return hasUnreadNotifs;
    });
  };

  const markAllNotificationsRead = () => {
    const body = {
      status: 'Read',
    };
    doUpdateStatusAll(body);
    setNotifications(markAllNotificationsReadUI);
  };

  const showFeaturePreview = ({ id, type }) => {
    showModal(FeaturePreview, {
      id,
      type,
      sync: refetchAnnouncements,
    });
  };

  const withCustomElements = icons => {
    const _ = icons.map(icon => {
      const isAdminSupport = Object.values(NavURIs).includes(icon.uri);
      if (isAdminSupport) icon.showCustomElement = true;

      return icon;
    });
    return _;
  };

  const onFocus = () => {
    tabFocusRef.current = true;
  };

  const onBlur = () => {
    tabFocusRef.current = false;
  };

  const menuOptions = {
    [menuOptionKeys.brandOptions]: <BrandOptions clearMenu={clearMenu} />,
    [menuOptionKeys.notifications]: (
      <Notifications
        notificationProps={{
          notifications: notifications,
          announcements: announcements,
          onClose: onNotificationsClose,
          isLoading: notificationsIsLoading,
          clearMenu: clearMenu,
          showFeaturePreview: showFeaturePreview,
        }}
        ref={notificationsRef}
      />
    ),
    [menuOptionKeys.account]: <UserProfileCard clearMenu={clearMenu} />,
  };

  const menuStyles = {
    [menuOptionKeys.account]: {
      position: 'fixed',
      top: 'unset',
      bottom: 0,
      left: sidebarWidth,
      borderRadius: `0 ${Effects.Border_Radius._S} ${Effects.Border_Radius._S} 0`,
    },
  };

  const multipleIfAllBrands = store => {
    const isAllBrands = !store[PropKeys.brandId];
    const isMultipleBrands = store[PropKeys.clients]?.length > 1;
    const images =
      isAllBrands && isMultipleBrands
        ? store[PropKeys.clients].map(client => client[PropKeys.brandImage])
        : store[PropKeys.brandImage];

    return images;
  };

  return (
    <PrivateSidebarLayout
      navigationIcons={navigationIcons}
      image={store[PropKeys.userImage]}
      username={store[PropKeys.username]}
      email={store[PropKeys.email]}
      brandImage={multipleIfAllBrands(store)}
      brandName={store[PropKeys.brandName]}
      showMenuOption={showMenuOption}
      menuOption={menuOption}
      menuLayout={menuOptions[menuOption]}
      pointerTop={pointerTop}
      brandLogoRef={brandLogoRef}
      navigationIconScrollRef={navigationIconScrollRef}
      showScrollUp={showScrollUp}
      showScrollDown={showScrollDown}
      notificationsIconsRef={notificationsIconsRef}
      userIconRef={userIconRef}
      hasUnreadNotifs={hasUnreadNotifs}
      menuLayoutRef={menuLayoutRef}
      hideToggle={!!menuOption}
      menuStyle={menuStyles[menuOption]}
      unreadCount={store[PropKeys.unreadSupportMessageCount]}
    />
  );
};
