import { Card } from '../../../../../components/atoms';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Layout } from '../../../../../components/style';
import { UserDetailsInput, UserServicesPermissions } from './organisms';

export const WriteUserLayout = ({
  fields,
  showUserList,
  disabled,
  actionsLoading,
  allServices,
  services,
  servicesLoading,
  update,
  title,
  primaryText,
}) => {
  return (
    <Card
      title={title}
      type="interactive"
      separator
      icon={
        <Actions
          onCancel={showUserList}
          primaryText={primaryText}
          isLoading={actionsLoading}
          disabled={disabled}
          size="_S"
        />
      }
      style={{ marginBottom: Layout.Spacing._M }}
    >
      <Flex column gap="_M">
        <UserDetailsInput fields={fields} />
        <UserServicesPermissions
          allServices={allServices}
          services={services}
          update={update}
          isLoading={servicesLoading}
        />
      </Flex>
    </Card>
  );
};
