import { useReducer } from 'react';
import { customTableReducer } from '../../../../../hooks/tableHook';

export const useAdminSortersReducer = ({
  initialSearchParams,
  resetSearchParams,
}) => {
  const [searchParams, dispatch] = useReducer(
    customTableReducer({ resetSearchParams, customReducer }),
    initialSearchParams
  );
  return [searchParams, dispatch];
};

const customReducer = (state, { type, payload }) => {
  switch (type) {
    // case DC.SORTERS:
    //   return {
    //     ...state,
    //     [type]: [...payload],
    //     [DC.FILTERS_SET]: true,
    //   };
    default:
      return state;
  }
};
