export const emptyOption = {
  label: 'Empty fields',
  value: '!empty!',
};

export const sortByLabel = (a, b) => {
  if (a.label === emptyOption.label) return -1;
  if (b.label === emptyOption.label) return 1;
  return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
};

const sortBySelected = (a, b) => {
  if (a.selected && !b.selected) return -1;
  if (!a.selected && b.selected) return 1;
  return 0;
};

export const labelSort = array => array.sort(sortByLabel);
export const selectedSort = array => array.sort(sortBySelected);

export const sortByChar = (a, b) => {
  const [charA] = a;
  const [charB] = b;
  return charA < charB ? -1 : 1;
};
