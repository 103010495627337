import { useMemo, useReducer } from 'react';

export function useInlineStyle(coreStyle, props) {
  const [styleState, dispatch] = useReducer(reducer, {
    hover: false,
  });

  const style = useMemo(() => {
    return coreStyle(styleState, props);
  }, [coreStyle, styleState, props]);
  const setStyle = (type, value) => dispatch({ type, value });

  return [style, setStyle];
}

function reducer(state, action) {
  const { type, value } = action;
  if (type == null) throw Error('no null action type');
  switch (type) {
    case 'hover':
      return { ...state, hover: value };
    default:
      return state;
  }
}
