import Button from './Button';

export const ButtonPrimaryMedium = ({
  type,
  text,
  onClick,
  isLoading,
  leftIcon,
  rightIcon,
  disabled,
  ref,
}) => {
  return (
    <Button
      type={type}
      text={text}
      variant="Primary"
      size="_M"
      isLoading={isLoading}
      onClick={onClick}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      disabled={disabled}
      ref={ref}
    />
  );
};

export const ButtonPrimaryMedium100 = ({
  type,
  text,
  onClick,
  isLoading,
  leftIcon,
  rightIcon,
  disabled,
  ref,
}) => {
  return (
    <Button
      type={type}
      text={text}
      variant="Primary"
      size="_M"
      fullWidth={true}
      isLoading={isLoading}
      onClick={onClick}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      disabled={disabled}
      ref={ref}
    />
  );
};

export const ButtonTertiaryMedium = ({
  type,
  text,
  onClick,
  isLoading,
  leftIcon,
  rightIcon,
  disabled,
  ref,
}) => {
  return (
    <Button
      type={type}
      text={text}
      variant="Tertiary"
      size="_M"
      isLoading={isLoading}
      onClick={onClick}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      disabled={disabled}
      ref={ref}
    />
  );
};
