import { useHeader } from '../../../providers/HeaderProvider/HeaderProvider';
import { Centering, Container } from './style';

const ViewHeader = ({ children }) => {
  const { headerIndex } = useHeader();
  return (
    <Container headerIndex={headerIndex}>
      <Centering>{children}</Centering>
    </Container>
  );
};

export default ViewHeader;
