import { useUI } from '../../../providers/UIProvider';
import { PopupHeader } from '../../molecules/PopupHeader/PopupHeader';
import { Container } from './Popup.style';

// don't forwardRef to stop modal from dismissing here
// if you're trying to do that, you likely want to do the Modal wrapping
// in a component higher up the tree
export const Popup = ({
  size,
  children,
  title,
  label,
  goBack,
  onClick,
  element,
  iPad,
  fullWidth,
  ...props
}) => {
  const { sidebarWidth } = useUI().UI;
  return (
    <Container
      size={size}
      onClick={onClick}
      iPad={iPad}
      fullWidth={fullWidth}
      sidebarWidth={sidebarWidth}
      {...props}
      className={`popup ${props.className || ''}`}
    >
      <PopupHeader title={title} goBack={goBack} element={element} />
      {children}
    </Container>
  );
};
