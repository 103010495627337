import styled from '@emotion/styled';
import { Table } from '../../../../components/organisms/Table/Table';
import {
  SubtableCSS,
  indicatorStyles,
} from '../../../../components/organisms/Table/Table.exports';
import { Colors, Effects, Layout } from '../../../../components/style';
import { Styles } from '../../../../constants';

export const ParcelsTableCSS = styled(Table)`
  .table-header,
  .table-cell {
    &._1 {
      padding-left: 0;
    }
    &._2 {
      padding-left: 8px;
    }
    &._8,
    &._9,
    &._10,
    &._11 {
      padding-left: 0px;
      justify-content: center;
    }
    &._12 {
      // padding-left: 24px;
    }
  }
  .table-header {
    &._13 {
      span {
        overflow: visible !important;
      }
    }
  }
  .table-cell {
    &._13 {
      display: flex;
      justify-content: center;
    }
  }
  ${indicatorStyles};
`;

<div class="parent">
  <div class="header-row">
    <div class="child">
      <div class="header-row"></div>
    </div>
  </div>
</div>;

export const CaseProductsCSS = styled(SubtableCSS)`
  padding: 0;
  #tableContainer {
    border-radius: 0 0 ${Effects.Border_Radius._XS} ${Effects.Border_Radius._XS};
  }
  .header-row {
    display: none;
  }
`;

export const CaseProductsTableHeaderCSS = styled.div`
  position: sticky;
  left: 0;
  height: ${Layout.Spacing._S};
  border-radius: ${Effects.Border_Radius._S} ${Effects.Border_Radius._S} 0 0;
  background: ${({ main }) =>
    main ? Colors.Primary._600 : Colors.Neutral._100};
  padding-left: ${Layout.Spacing._M};
  ${Styles.flex};
`;

// share across apps to avoid 2 changes if style changes
export const CaseProductsTableHeaderContainer = styled.div`
  z-index: 10;
  position: sticky;
  top: 0px;
  width: 100%;
  overflow: scroll;
  padding-top: ${Layout.Spacing._S};
  background: ${Colors.Neutral._200};
  ${Styles.flexColumn};
  .header-row {
    display: grid;
  }
`;
