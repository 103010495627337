import { Flex } from '../../atoms/Flex/Flex';
import SlideDots from '../../molecules/SlideDots/SlideDots';
import { Colors, Layout } from '../../style';
import { Popup } from '../Popup';

const { default: styled } = require('@emotion/styled');

export const ModalContainer = styled(Popup)`
  padding: 0;
  position: relative;
  overflow: hidden;
`;

export const Content = styled(Flex)`
  position: absolute;
  inset: 0;
`;

export const Illustration = styled.img`
  width: 100%;
  height: 440px;
  object-fit: cover;
`;

export const DescriptionCSS = styled(Flex)`
  align-items: center;
  padding: 0 ${Layout.Spacing._S};
  margin: 0 auto;
  text-align: center;
  padding-top: ${Layout.Spacing._2XL};
`;

export const ActionsCSS = styled(Flex)`
  position: absolute;
  bottom: ${Layout.Spacing._M};
  right: ${Layout.Spacing._M};
  left: ${Layout.Spacing._M};
`;

export const Dots = styled(SlideDots)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
`;
export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  background: ${props =>
    props.selected ? Colors.Primary._600 : Colors.Neutral._300};
`;
