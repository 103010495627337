import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import { Badge, BadgeType } from '../../../../components/molecules/Badge/Badge';
import { timeFrameInitialFilterValues } from '../../../../components/organisms/Filters/utils';
import { FilterTypes } from '../../../../components/organisms/Filters/variables';
import { StatusColors } from '../../../../components/style';
import { Constants, DISPATCH_CONSTANTS } from '../../../../constants';
import { NavURIs, Pages } from '../../routes/variables';
import { productBrandIsContracted } from '../products/controllers/variables';
import {
  DC as SupportDC,
  ticketFilterValues,
} from '../support/controllers/variables';

export const dashboardKeys = {
  allCases: 'allCases',
  allAuthentications: 'allAuthentications',
  brandName: 'brandName',
  brandCount: 'brandCount',
  activityLogDate: 'activityLogDate',
  caseId: 'caseId',
  caseAlias: 'caseAlias',
  casesReleased: 'casesReleased',
  casesSeized: 'casesSeized',
  caseStatus: 'caseStatus',
  casesTotal: 'casesTotal',
  count: 'count',
  day: 'day',
  delta: 'delta',
  month: 'month',
  overview: 'overview',
  parcelCount: 'parcelCount',
  period: 'period',
  referenceAddress: 'referenceAddress',
  referenceCompany: 'referenceCompany',
  referenceCount: 'referenceCount',
  referenceCountry: 'referenceCountry',
  referenceName: 'referenceName',
  sorter: 'sorter',
  sorterCity: 'sorterCity',
  sorterCountry: 'sorterCountry',
  supportCount: 'supportCount',
  year: 'year',
  itemCount: 'itemCount',
  productCount: 'productCount',
  completeCount: Constants.Status.AuthenticationCompleted,
  inspectionCount: Constants.Status.Inspection,
  authenticationCount: Constants.Status.Authentication,
  counterfeitCount: 'Counterfeit',
  authenticCount: 'Non-counterfeit',
  releasedCount: Constants.Status.Released,
  stoppedCount: Constants.Status.Stopped,
  customsCount: Constants.Status.Customs,
  seizedCount: Constants.Status.Seized,
  pendingCount: Constants.Status.Authentication,
  referenceDetailsCompleteCount: 'referenceDetailsCompleteCount',
  noDestinationCount: 'noDestinationCount',
  noOriginCount: 'noOriginCount',
  openCount: 'openCount',
  resolvedCount: 'resolvedCount',
};

export const components = {
  overview: 'overview',
  populars: 'populars',
  timeline: 'timeline',
  origins: 'origins',
  categories: 'categories',
};

export const infoDescription = {
  [components.overview]:
    'This section shows all lifetime worldwide statistics.',
  [components.populars]:
    'This section shows Inspections, Stops, and Seizures by most popular locations.',
  [components.timeline]:
    'This section shows Inspections, Stops, and Seizures over time.',
  [components.origins]: 'This section shows the top countries of references.',
  [components.categories]:
    'This section shows the top categories of stopped and seized items.',
  [components.activity]: 'This section shows recent case developments.',
};

export const DC = { ...DISPATCH_CONSTANTS };

export const filtersConstants = {
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
    initial: timeFrameInitialFilterValues.today,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
};

export const allOptions = undefined;

export const overviewKeys = {
  [dashboardKeys.parcelCount]: {
    title: 'Parcels',
    description:
      'Amount of parcels by all and contracted brands, compared to the previous period.',
    titleIcon: Icons.PackageCheck,
    countKey: 'parcel_counts',
    comparisonKey: 'parcels_comparison',
    keys: [
      {
        key: 'sideloadeds',
        title: 'Sideloaded',
        tooltipContent: 'Selected parcels by the Countercheck Algorithm',
        link: NavURIs[Pages.parcels],
        dataType: 'sideloads',
      },
      {
        key: 'checked_ins',
        title: 'Checked in',
        tooltipContent: 'Parcels checked in by a facilitator',
        link: `${NavURIs[Pages.parcels]}?specialFilter=special_checked_ins`,
        dataType: 'check-ins',
      },
      {
        key: 'to_be_inspecteds',
        title: 'To be inspected',
        tooltipContent: 'Parcels marked as "to be inspected" by the office',
        link: `${
          NavURIs[Pages.parcels]
        }?specialFilter=special_to_be_inspecteds`,
        dataType: 'parcels to be inspected',
      },
      {
        key: 'inspecteds',
        title: 'Inspected',
        tooltipContent: 'Parcels inspected by a facilitator',
        link: `${NavURIs[Pages.parcels]}?specialFilter=special_inspecteds`,
        dataType: 'parcels inspected',
      },
      {
        key: 'misseds',
        title: 'Missed',
        tooltipContent:
          'Parcels marked as "to be inspected" but missed by facilitator',
        link: `${NavURIs[Pages.parcels]}?specialFilter=special_misseds`,
        dataType: 'parcels missed',
      },
      {
        key: 'losts',
        title: 'Lost',
        tooltipContent:
          'Parcels sideloaded by algorithm but never seen by facilitator',
        link: `${NavURIs[Pages.parcels]}?specialFilter=special_losts`,
        dataType: 'parcels lost',
      },
    ],
  },
  [dashboardKeys.productCount]: {
    title: 'Products',
    description:
      'Amount of products by all and contracted brands, compared to the previous period.',
    titleIcon: Icons.TagCheck,
    countKey: 'cases_count',
    comparisonKey: 'cases_comparison',
    keys: [
      {
        key: 'cases',
        title: 'Total products',
        tooltipContent: 'Total products added to the platform by a facilitator',
        link: NavURIs[Pages.products],
        dataType: 'total products',
      },
      {
        key: 'branded',
        title: 'Branded products',
        tooltipContent:
          'Total branded products added to the platform by a facilitator',
        link: `${NavURIs[Pages.products]}?brand=${
          Constants.excludeFilters
        }${JSON.stringify(Constants.unknownBrandIds)}`,
        dataType: 'branded products',
      },
      {
        key: 'contracted',
        title: 'Contracted products',
        tooltipContent:
          'Total products of contracted brands added to the platform by a facilitator',
        link: `${NavURIs[Pages.products]}?brand=${productBrandIsContracted}`,
        dataType: 'contracted products',
      },
    ],
  },
  [dashboardKeys.itemCount]: {
    title: 'Items',
    description:
      'Amount of items by all and contracted brands, compared to the previous period.',
    titleIcon: Icons.Tags,
    countKey: 'items_count',
    comparisonKey: 'items_comparison',
    keys: [
      {
        key: 'items',
        title: 'Total items',
        tooltipContent: 'Total items added to the platform by a facilitator',
        link: NavURIs[Pages.products],
        dataType: 'total items',
      },
      {
        key: 'branded',
        title: 'Branded items',
        tooltipContent:
          'Total branded items added to the platform by a facilitator',
        link: `${NavURIs[Pages.products]}?brand=${
          Constants.excludeFilters
        }${JSON.stringify(Constants.unknownBrandIds)}`,
        dataType: 'branded items',
      },
      {
        key: 'contracted',
        title: 'Contracted items',
        tooltipContent:
          'Total items of contracted brands added to the platform by a facilitator',
        link: `${NavURIs[Pages.products]}?brand=${productBrandIsContracted}`,
        dataType: 'contracted items',
      },
    ],
  },
  [dashboardKeys.brandCount]: {
    title: 'Brands',
    description:
      'Amount of brands for which we have detected a product, compared to the previous period.',
    titleIcon: Icons.Building,
    countKey: 'brands_count',
    comparisonKey: 'brands_comparison',
    keys: [
      {
        key: 'brands',
        title: 'Total brands',
        tooltipContent: 'Total brands caught in the selected period',
        link: NavURIs[Pages.products],
        dataType: 'total brands',
      },
      {
        key: 'contracted',
        title: 'Contracted',
        tooltipContent: 'Total contracted brands caught in the selected period',
        link: `${NavURIs[Pages.products]}?brand=${productBrandIsContracted}`,
        dataType: 'contracted brands',
      },
    ],
  },
  [dashboardKeys.supportCount]: {
    title: 'Support tickets',
    description:
      'Amount of open and resolved tickets, compared to the previous period.',
    titleIcon: Icons.MailCheck,
    countKey: 'support_ticket_counts',
    comparisonKey: 'support_ticket_comparison',
    keys: [
      {
        key: 'open',
        title: (
          <Badge
            type={BadgeType.ticket}
            text={ticketFilterValues.Open}
            size={'_S'}
          />
        ),
        tooltipContent: 'Total tickets opened in the selected period',
        link: `${NavURIs[Pages.support]}?filters=section:${SupportDC.STATUS}:${
          ticketFilterValues.Open
        }`,
        dataType: 'tickets opened',
      },
      {
        key: 'resolved',
        title: (
          <Badge
            type={BadgeType.ticket}
            text={ticketFilterValues.Resolved}
            size={'_S'}
          />
        ),
        tooltipContent: 'Total tickets solved in the selected period',
        link: `${NavURIs[Pages.support]}?filters=section:${SupportDC.STATUS}:${
          ticketFilterValues.Resolved
        }`,
        dataType: 'tickets solved',
      },
    ],
  },
};

export const authenticationStats = [
  dashboardKeys.pendingCount,
  // dashboardKeys.supportCount,
  dashboardKeys.completeCount,
];

export const authenticationTitles = {
  [dashboardKeys.pendingCount]: 'Requested',
  [dashboardKeys.supportCount]: 'Support',
  [dashboardKeys.completeCount]: 'Completed',
};

export const authenticationTooltipContent = {
  [dashboardKeys.pendingCount]: `Total authentication requested in the selected period`,
  [dashboardKeys.supportCount]: `Total authentication with support tickets in the selected period`,
  [dashboardKeys.completeCount]: `Total authentications completed in the selected period`,
};

export const actorStats = [
  dashboardKeys.noOriginCount,
  dashboardKeys.noDestinationCount,
  dashboardKeys.referenceDetailsCompleteCount,
];

export const actorStatsDestination = {
  [dashboardKeys.noOriginCount]: `${NavURIs[Pages.products]}?actorData=${[
    'has_only_destination',
    'has_none',
  ].join('+')}?status=${[
    Constants.Status.Stopped,
    Constants.Status.Seized,
  ].join('+')}`,
  [dashboardKeys.noDestinationCount]: `${NavURIs[Pages.products]}?actorData=${[
    'has_only_origin',
    'has_none',
  ].join('+')}?status=${[
    Constants.Status.Stopped,
    Constants.Status.Seized,
  ].join('+')}`,
  [dashboardKeys.referenceDetailsCompleteCount]: `${
    NavURIs[Pages.products]
  }?actorData=${'has_both'}&status=${[
    Constants.Status.Seized,
    Constants.Status.Stopped,
  ].join('+')}`,
};

export const authenticationDestination = {
  [dashboardKeys.pendingCount]: `${NavURIs[Pages.products]}?status=${
    Constants.Status.Authentication
  }`,
  [dashboardKeys.completeCount]: `${NavURIs[Pages.products]}?status=${[
    Constants.Status['Non-counterfeit'],
    Constants.Status.Counterfeit,
  ].join('+')}`,
};

export const actorStatCountsKeys = {
  parcel: {
    [dashboardKeys.noOriginCount]: 'no_origin_parcel_count',
    [dashboardKeys.noDestinationCount]: 'no_destination_parcel_count',
    [dashboardKeys.referenceDetailsCompleteCount]: 'complete_parcel_count',
  },
  item: {
    [dashboardKeys.noOriginCount]: 'no_origin_item_count',
    [dashboardKeys.noDestinationCount]: 'no_destination_item_count',
    [dashboardKeys.referenceDetailsCompleteCount]: 'complete_item_count',
  },
};

export const actorTitles = {
  [dashboardKeys.noOriginCount]: 'Origin missing',
  [dashboardKeys.noDestinationCount]: 'Destination missing',
  [dashboardKeys.referenceDetailsCompleteCount]: 'Complete information',
};

export const actorTooltipContent = {
  [dashboardKeys.noOriginCount]: `Total stopped and seized products with missing origin`,
  [dashboardKeys.noDestinationCount]: `Total stopped and seized products with missing destination`,
  [dashboardKeys.referenceDetailsCompleteCount]: `Total stopped and seized products with completed information`,
};

export const navToEvalCasesButton = {
  text: 'View all',
  variant: 'Tertiary',
  size: '_S',
  rightIcon: Icons.ChevronRight,
};

export const parcelStatuses = [
  { status: dashboardKeys.inspectionCount, color: StatusColors.Inspection },
  {
    status: dashboardKeys.authenticationCount,
    color: StatusColors.Authentication,
  },
  { status: dashboardKeys.counterfeitCount, color: StatusColors.Counterfeit },
  { status: dashboardKeys.stoppedCount, color: StatusColors.Stopped },
  { status: dashboardKeys.seizedCount, color: StatusColors.Seized },
  {
    status: dashboardKeys.authenticCount,
    color: StatusColors['Non-counterfeit'],
  },
  { status: dashboardKeys.releasedCount, color: StatusColors.Released },
];

export const parcelStatusesDestination = {
  [dashboardKeys.inspectionCount]: `?status=${Constants.Status.Inspection}`,
  [dashboardKeys.authenticationCount]: `?status=${Constants.Status.Authentication}`,
  [dashboardKeys.counterfeitCount]: `?status=${Constants.Status.Counterfeit}`,
  [dashboardKeys.authenticCount]: `?status=${Constants.Status['Non-counterfeit']}`,
  [dashboardKeys.stoppedCount]: `?status=${Constants.Status.Stopped}`,
  [dashboardKeys.seizedCount]: `?status=${Constants.Status.Seized}`,
  [dashboardKeys.releasedCount]: `?status=${Constants.Status.Released}`,
};

export const statusTooltipContent = {
  [dashboardKeys.inspectionCount]: `Products inspected in the selected period`,
  [dashboardKeys.authenticationCount]: `Products under authentication in the selected period`,
  [dashboardKeys.counterfeitCount]: `Counterfeit products in the selected period`,
  [dashboardKeys.authenticCount]: `Non-counterfeit products in the selected period`,
  [dashboardKeys.stoppedCount]: `Products stopped in the selected period`,
  [dashboardKeys.seizedCount]: `Products seized in the selected period`,
  [dashboardKeys.releasedCount]: `Products released in the selected period`,
};

export const titles = {
  [DC.TIME_FRAME]: 'Last 28 days',
};
