import { Route, Routes } from 'react-router-dom';
    import ParcelEvaluation from './ParcelEvaluation';
    
    export const ParcelEvaluationRoutes = () => {
      return (
        <Routes>
          <Route path="" element={<ParcelEvaluation />} />
        </Routes>
      );
    };
    