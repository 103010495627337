import usePostData from '../../../../../hooks/postHook';

export const useUpdateUserRoles = ({ id }) => {
  const [{ body, isLoading, isError, error }, updateUserRoles] = usePostData(
    `client/${id}/roles`
  );

  const doUpdateUserRoles = (values, user) => {
    const userRolesMap = {};
    user.roles.forEach(role => {
      userRolesMap[role.service_id] = role;
    });

    let roles;
    // updates here must happen on createUser.js
    const roles_ = values.services
      .filter(s => s.selectedRole)
      .map(service => ({
        id: service.selectedRole.id,
        service_id: service.id,
      }));

    const filteredRoles = roles_.filter(
      role => userRolesMap[role.service_id]?.id !== role.id
    );
    roles = filteredRoles;

    if (!roles.length) roles = roles_;

    const body = {
      roles,
    };
    updateUserRoles(body);
  };

  return {
    body,
    isLoading,
    isError,
    error,
    doUpdateUserRoles,
  };
};
