import usePostData from '../../../../../hooks/postHook';

export const useAddActor = ({ id }) => {
  const [{ body, isLoading, isError, error }, doAddActor] = usePostData(
    `office_parcel/${id}/actors`
  );

  const addOrigin = values => {
    const body = {
      ...values,
      actor_type: actorTypes.sender,
    };
    doAddActor(body);
  };

  const addDestination = values => {
    const body = {
      ...values,
      actor_type: actorTypes.receiver,
    };
    doAddActor(body);
  };

  return {
    body,
    isLoading,
    isError,
    error,
    addOrigin,
    addDestination,
  };
};

export const actorTypes = {
  sender: 'Sender',
  receiver: 'Receiver',
};
