import { envVars } from '../../../constants';
import { Size } from '../../style';

const size = Size.Icon._M;

// TODO: this component cannot know about the internals of protected routes.
// figure out a way to have it defined internally and understood here
const comingSoon = [];

export const iconProps = ({
  label,
  sidebarIcon: name,
  path: uri,
  ...rest
}) => ({
  ...rest,
  name,
  label,
  // makes pathname base match uri, so we can === check to detect selected
  uri: '/' + uri?.replaceAll('/', '').replaceAll('*', ''),
  size,
  comingSoon: comingSoon.includes(name),
});
const { environment } = envVars;

export const platformLabel =
  environment === 'prod'
    ? 'Beta'
    : environment === 'staging'
    ? 'Staging'
    : environment === 'dev'
    ? 'Dev'
    : environment === 'local'
    ? 'Local'
    : 'Beta';
