import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';

export const useGetAuthenticationPerformance = () => {
  const [{ data, isLoading, isError, error }, _, getPerformance] = useFetchData(
    'dashboard/authentication_performance',
    { formatter }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    getPerformance,
  };
};

const formatter = data => {
  data = maskEntry(data, mask);
  return data;
};

const mask = {};
