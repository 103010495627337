import styled from '@emotion/styled';
import { Colors } from '../../style';

const baseSize = 80;

export const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.size ?? baseSize}px;
  height: ${props => props.size ?? baseSize}px;
  div {
    position: absolute;
    width: ${props => props.dotSize ?? 6}px;
    height: ${props => props.dotSize ?? 6}px;
    background: ${props => props.color ?? Colors.Neutral._200};
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  div:nth-of-type(1) {
    animation-delay: 0s;
    top: ${props => (props.size * 37) / baseSize}px;
    left: ${props => (props.size * 66) / baseSize}px;
  }
  div:nth-of-type(2) {
    animation-delay: -0.1s;
    top: ${props => (props.size * 22) / baseSize}px;
    left: ${props => (props.size * 62) / baseSize}px;
  }
  div:nth-of-type(3) {
    animation-delay: -0.2s;
    top: ${props => (props.size * 11) / baseSize}px;
    left: ${props => (props.size * 52) / baseSize}px;
  }
  div:nth-of-type(4) {
    animation-delay: -0.3s;
    top: ${props => (props.size * 7) / baseSize}px;
    left: ${props => (props.size * 37) / baseSize}px;
  }
  div:nth-of-type(5) {
    animation-delay: -0.4s;
    top: ${props => (props.size * 11) / baseSize}px;
    left: ${props => (props.size * 22) / baseSize}px;
  }
  div:nth-of-type(6) {
    animation-delay: -0.5s;
    top: ${props => (props.size * 22) / baseSize}px;
    left: ${props => (props.size * 11) / baseSize}px;
  }
  div:nth-of-type(7) {
    animation-delay: -0.6s;
    top: ${props => (props.size * 37) / baseSize}px;
    left: ${props => (props.size * 7) / baseSize}px;
  }
  div:nth-of-type(8) {
    animation-delay: -0.7s;
    top: ${props => (props.size * 52) / baseSize}px;
    left: ${props => (props.size * 11) / baseSize}px;
  }
  div:nth-of-type(9) {
    animation-delay: -0.8s;
    top: ${props => (props.size * 62) / baseSize}px;
    left: ${props => (props.size * 22) / baseSize}px;
  }
  div:nth-of-type(10) {
    animation-delay: -0.9s;
    top: ${props => (props.size * 66) / baseSize}px;
    left: ${props => (props.size * 37) / baseSize}px;
  }
  div:nth-of-type(11) {
    animation-delay: -1s;
    top: ${props => (props.size * 62) / baseSize}px;
    left: ${props => (props.size * 52) / baseSize}px;
  }
  div:nth-of-type(12) {
    animation-delay: -1.1s;
    top: ${props => (props.size * 52) / baseSize}px;
    left: ${props => (props.size * 62) / baseSize}px;
  }
`;
