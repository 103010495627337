import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { actingBrandFilter } from '../../../../../components/organisms/ActingBrandSelection/utils';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Constants } from '../../../../../constants';
import { useFileUpload } from '../../../../../providers/FileUploadProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useAddLinks } from '../api/addLinks';
import { useCreateInstruction } from '../api/createInstruction';
import { ManualInstructionLayout } from '../pages/ManualInstructionLayout';
import { formatCreateValues, withStateVars } from './utils';
import {
  manualInstructionFields,
  manualInstructionInitialValues,
  manualInstructionValidation,
  uploadStatusContent,
} from './variables';

const { SELF: KNOWLEDGE_BASE, CREATE_KNOWLEDGE_BASE } =
  PermissionKeys.Brand.KNOWLEDGE_BASE;

const ManualInstruction = ({ categories, appendNewRule }) => {
  const [uploadValues, setUploadValues] = useState({});
  const [somethingIsLoading, setSomethingIsLoading] = useState(false);

  const { handleDrop, handleImport, fileState, percentage, resetFileUpload } =
    useFileUpload();

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  const { permissions } = usePermissions();

  const { data, body, isLoading, isError, error, doCreateInstruction } =
    useCreateInstruction();

  const avoidReset = useRef(null);

  const {
    body: addLinkBody,
    isLoading: addLinkIsLoading,
    isError: addLinkIsError,
    doAddLinks,
  } = useAddLinks({});

  useEffect(() => {
    return () => handleClose();
  }, []);

  useEffect(() => {
    const somethingIsLoading =
      addLinkIsLoading || isLoading || fileState.isUploading;
    setSomethingIsLoading(somethingIsLoading);
  }, [isLoading, isLoading, fileState.isUploading]);

  useEffect(() => {
    if (isLoading || data == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }

    const instruction_id = data;
    if (fileState.files)
      handleImport({
        folder: `instruction_files`,
        url: `knowledge_base/${instruction_id}/attachments`,
        parameterName: 'names',
        successCallback: () => {
          showSnackbarSuccess('Attachments successfully uploaded');
          resetFileUpload();
        },
        errorCallback: () => {
          showSnackbarError('Error uploading attachments');
          resetFileUpload();
        },
      });

    if (uploadValues?.urls.length) {
      const url = `knowledge_base/${instruction_id}/links`;
      const body_ = { urls: uploadValues.urls };
      doAddLinks(body_, url);
    }
    // appendNewRule({ ...uploadValues, instruction_id });
    // currently this just refreshes the table
    appendNewRule();
    dismissAll();
  }, [data, isLoading, isError]);

  const addRule = values => () => {
    const formattedValues = formatCreateValues(values);
    setUploadValues(formattedValues);
    doCreateInstruction(formattedValues);
    avoidReset.current = true;
  };

  const handleClose = () => {
    if (!avoidReset.current) resetFileUpload();
  };

  return (
    <Formik
      initialValues={manualInstructionInitialValues}
      validationSchema={manualInstructionValidation}
      validateOnMount
    >
      {({ isValid, setFieldValue, setTouched, values }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ => {
            setFieldValue(name, { value, label });
          };
        return (
          <Form>
            <ManualInstructionLayout
              actingBrandProps={{
                selectOption,
                value: values['brand'],
                label: 'Brand',
                brandsFilter: actingBrandFilter(
                  permissions,
                  KNOWLEDGE_BASE,
                  CREATE_KNOWLEDGE_BASE
                ),
              }}
              fields={withStateVars(manualInstructionFields, categories)}
              selectOption={selectOption}
              setTouched={setTouched}
              handleDrop={handleDrop(
                Constants.FileUpload.uploadType.knowledge_base
              )}
              fileState={fileState}
              percentageUploaded={percentage}
              uploadStatusContent={uploadStatusContent({
                fileState,
                resetFileUpload,
              })}
              onCancel={() => dismissAll()}
              onBack={dismiss}
              addRule={addRule(values)}
              disabled={!isValid || somethingIsLoading}
              isLoading={somethingIsLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(ManualInstruction);
