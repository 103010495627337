import { Card } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import AnimatedIcon from '../../molecules/AnimatedIcon/AnimatedIcon';
import {
  AttachmentsContainer,
  DetailsSectionTitle,
} from './Attachments.components';
import { UnboxedContainer } from './Attachments.style';
import { displayType as displayType_ } from './variables';

export const AttachmentsLayout = ({
  attachments,
  title = 'Attachments',
  urlKey,
  nameKey,
  cardResults,
  unboxedResults,
  showAddAttachments,
  isLoading,
  handleDelete,
  displayType,
  hasEditPermission,
  hasDeletePermission,
  props,
}) => {
  return displayType === displayType_.card ? (
    <Card title={title} className={`${props.className ?? ''} attachments`}>
      <AttachmentsContainer
        attachments={attachments}
        handleDelete={handleDelete}
        urlKey={urlKey}
        nameKey={nameKey}
        isLoading={isLoading}
        noResults={cardResults}
        showAddAttachments={showAddAttachments}
        hasEditPermission={hasEditPermission}
        hasDeletePermission={hasDeletePermission}
        scroll
      />
    </Card>
  ) : (
    <UnboxedContainer className={`${props.className ?? ''} attachments`}>
      <Flex align="center" justify="between" className="header">
        <DetailsSectionTitle text={'Attachments'} />
        {hasEditPermission && (
          <AnimatedIcon
            name={Icons.PlusCircle}
            onClick={showAddAttachments}
            theme="popup"
          />
        )}
      </Flex>
      <AttachmentsContainer
        attachments={attachments}
        handleDelete={handleDelete}
        urlKey={urlKey}
        nameKey={nameKey}
        isLoading={false}
        noResults={unboxedResults}
        showAddAttachments={showAddAttachments}
        hasDeletePermission={hasDeletePermission}
      />
    </UnboxedContainer>
  );
};
