import { Route, Routes } from 'react-router-dom';
import Support from './Support';
import TicketConversation from './TicketConversation';

export const SupportRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Support />} />
    </Routes>
  );
};

export const SupportDetailRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<TicketConversation />} />
    </Routes>
  );
};
