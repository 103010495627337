import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects, Layout, UtilityColors } from '../../style';

export const Container = styled.div`
  position: relative;
  transform: translate(0, 0);
  cursor: pointer;
  * {
    cursor: pointer;
  }
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const MenuOptionsHoverArea = styled.div`
  z-index: 1;
  position: fixed;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  padding-top: ${Layout.Spacing._2XS};
`;

export const MenuOptionsCSS = styled.div`
  cursor: pointer;
  padding: ${Layout.Spacing._2XS} ${Layout.Spacing._2XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const MenuOptionCSS = styled.div`
  min-width: 88px;
  cusror: pointer;
  height: ${Layout.Spacing._S};
  padding: 0px ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._S};
  background: ${({ selected }) =>
    selected ? UtilityColors.Background_App : 'transparent'};
  ${Styles.flex};
`;
