import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Colors, Effects } from '../../style';

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  border-radius: ${Effects.Border_Radius._S};
  background: ${Colors.Neutral._100};
  ${Styles.flexCenter};
`;

export const DescriptionCSS = styled.div`
  max-width: 241px;
  span {
    text-align: center;
    display: block;
  }
`;
