import Icon from '../../atoms/Icon/Icon';
import Text from '../../atoms/Text/Text';
import { styles } from './Badge.style';

export const Badge = ({ type, text: status, size, icon }) => {
  let subStatus;
  let text = status;

  const style = styles({ type, text, status, subStatus, size, icon });
  return (
    <div style={style.container} className={type + '-badge'}>
      <Text text={subStatus ?? text} {...style.text} />
      <Icon {...style.icon} />
    </div>
  );
};

export const BadgeType = {
  status: 'status',
  ticket: 'ticket',
};
