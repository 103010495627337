import usePostData from '../../../../../hooks/postHook';

export const useUpdateLink = (instructionId, linkId) => {
  const [{ data, body, isLoading, isError, error }, doUpdateLink] = usePostData(
    `knowledge_base/${instructionId}/link/${linkId}`,
    {
      type: 'put',
    }
  );

  return { data, body, isLoading, isError, error, doUpdateLink };
};
