import { Flex } from '../../components/atoms/Flex/Flex';
import { Label } from '../../components/molecules/Label/Label';
import { Colors } from '../../components/style';
import { InfoCard, Options2FA } from './organisms/Organisms';
import { Setup2FAContainer } from './style';

const Setup2FALayout = ({
  title,
  subtitle,
  options,
  minorButtonClick,
  minorButtonText,
  minorLeftIcon,
  mainRightIcon,
  mainButtonText,
  validationSchema,
  initialValues,
  onSubmit,
  isLoading,
}) => {
  return (
    <Setup2FAContainer>
      <InfoCard
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        title={title}
        minorButtonClick={minorButtonClick}
        minorButtonText={minorButtonText}
        mainButtonText={mainButtonText}
        minorLeftIcon={minorLeftIcon}
        mainRightIcon={mainRightIcon}
        isLoading={isLoading}
      >
        <Flex gap="_M" column>
          <Label text={subtitle} size="_L" textColor={Colors.Neutral._900} />
          <Options2FA options={options} />
          <div />
        </Flex>
      </InfoCard>
    </Setup2FAContainer>
  );
};
export default Setup2FALayout;
