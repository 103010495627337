import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import ActivityConnection from '../../../../../../components/molecules/ActivityConnection/ActivityConnection';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { FontKeys } from '../../../../../../components/style';
import { Styles } from '../../../../../../constants';
import {} from '../../../../../../utils/stringUtils';
import { stepNumber } from '../../../../features/products/variables';
import { statusStepsIcons } from '../../controllers/variables';
import {
  ConnectingLine,
  Date,
  Instructions,
  LogText,
  ProductText,
  SectionTitle,
  StatusActions,
  StatusTitle,
} from '../atoms/atoms';
import {
  ActivityEntryCSS,
  CasesListSmallCSS,
  DescriptiveIcon,
  DetailCSS,
  NoResultRowCSS,
  ReasonDescriptionCSS,
  StatusDescriptionCSS,
  StepCSS,
  StepDescriptionCSS,
  StepTextCSS,
  StepsCSS,
  TicketCountCSS,
  TicketCountContainerCSS,
  stepStyles,
} from '../style';

export const CasesListSmall = ({
  showResults,
  headers,
  results,
  components,
  grid,
  rowAction,
  scrollRef,
  rowHoverIn,
  rowHoverOut,
  search = true,
}) => {
  if (!showResults) return <></>;
  return (
    <CasesListSmallCSS search={search}>
      {results?.length ? (
        <Table
          headers={headers}
          data={results}
          components={components}
          grid={grid}
          rowAction={rowAction}
          scrollRef={scrollRef}
          rowHoverIn={rowHoverIn}
          rowHoverOut={rowHoverOut}
        />
      ) : (
        <NoResultRow />
      )}
    </CasesListSmallCSS>
  );
};

const NoResultRow = () => {
  return (
    <NoResultRowCSS>
      <ProductText text={'No results'} />
    </NoResultRowCSS>
  );
};

export const Detail = ({ title, text }) => {
  return (
    <DetailCSS>
      <Text
        text={title}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
      />
      <Text
        text={text}
        variant={FontKeys.Variant.Paragraph}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
      />
    </DetailCSS>
  );
};

export const Steps = ({ steps }) => {
  return (
    <StepsCSS>
      {(steps ?? []).map((step, index) => {
        return <Step step={step} index={index} key={step} />;
      })}
    </StepsCSS>
  );
};

const Step = ({ step, index }) => {
  return (
    <StepCSS>
      <ConnectingLine index={index}>
        <StepDescription step={step} index={index} />
      </ConnectingLine>
    </StepCSS>
  );
};

const StepDescription = ({ step, index }) => {
  return (
    <StepDescriptionCSS index={index}>
      <StepTextCSS index={index}>
        <Text
          text={step}
          variant={FontKeys.Variant.Caption}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Semibold}
          color={stepStyles[index].textColor}
          whiteSpace="nowrap"
        />
      </StepTextCSS>
      <Text
        text={stepNumber[step]}
        variant={FontKeys.Variant.Caption}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Semibold}
        color={stepStyles[index].stepColor}
      />
    </StepDescriptionCSS>
  );
};

export const StepIcon = ({ status, size }) => {
  return (
    <DescriptiveIcon size={size}>
      <Icon name={statusStepsIcons[status]} />
    </DescriptiveIcon>
  );
};

export const StatusDescription = ({
  status,
  statusInstructions,
  actions,
  hasEditPermission,
  isLoading,
}) => {
  return (
    <StatusDescriptionCSS>
      <StatusTitle status={status} />
      <Instructions statusInstructions={statusInstructions} />
      {hasEditPermission && !!actions?.length && (
        <StatusActions actions={actions} isLoading={isLoading} />
      )}
    </StatusDescriptionCSS>
  );
};

export const ReasonDescription = ({ reasonTitle, title, description }) => {
  return (
    <ReasonDescriptionCSS>
      <SectionTitle text={reasonTitle} />
      <div style={Styles.flexColumn}>
        <Text
          text={title}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Semibold}
        />
        <Text
          text={description}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Regular}
        />
      </div>
    </ReasonDescriptionCSS>
  );
};

export const TicketCountContainer = ({ text, onClick, display }) => {
  return (
    <TicketCountContainerCSS onClick={onClick} display={display}>
      <TicketCountCSS>
        <Icon name={Icons.MailCheck} />
        <Text
          text={text}
          variant={FontKeys.Variant.Button}
          size={FontKeys.Size._M}
          weight={FontKeys.Weight.Semibold}
          ellipsis
        />
      </TicketCountCSS>
      <div style={Styles.flex}>
        <Icon name={Icons.ChevronRight} />
      </div>
    </TicketCountContainerCSS>
  );
};

export const RecentActivityContainer = ({ activity, showThisMany }) => {
  return (
    <Flex column gap="_S" scroll className="content">
      {(activity ?? [])
        .slice(0, showThisMany ? showThisMany : activity?.length ?? 0)
        .map((entry, index) => {
          const { text, date } = entry;
          const isFirst = index === 0;
          const isLast =
            index + 1 ===
            Math.min(showThisMany ?? Infinity, activity?.length ?? Infinity);
          return (
            <ActivityEntry
              date={date}
              text={text}
              isFirst={isFirst}
              isLast={isLast}
              key={index}
            />
          );
        })}
    </Flex>
  );
};

export const ActivityEntry = ({ date, text, isFirst, isLast }) => {
  return (
    <ActivityEntryCSS className="entry">
      <ActivityConnection isFirst={isFirst} isLast={isLast} />
      <Log date={date} text={text} />
    </ActivityEntryCSS>
  );
};

const Log = ({ date, text }) => {
  return (
    <div style={Styles.flexColumn}>
      <Date text={date} />
      <LogText text={text} />
    </div>
  );
};
