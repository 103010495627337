import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import AnimatedIcon from '../AnimatedIcon/AnimatedIcon';
import { Container, Title, TitleText } from './PopupHeader.style';

export const PopupHeader = ({ title, label, goBack, element }) => {
  const hasSomeitem = !!title || !!goBack || !!element;
  return (
    <Container className="popup-header">
      {(title || label) && (
        <Title>
          {goBack && (
            <AnimatedIcon
              name={Icons.ChevronLeft}
              onClick={goBack}
              theme="popup"
            />
          )}
          {title && <TitleText text={title} />}
        </Title>
      )}
      {element}
      {hasSomeitem && <Separator />}
    </Container>
  );
};
