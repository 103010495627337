import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { TableLoader } from '../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import NoSearchResults from '../../../../../components/templates/NoSearchResults/NoSearchResults';
import { Content } from '../../../../../components/templates/Popup/Popup.style';
import { defaultErrorDisplay } from '../../../../../components/templates/TableStatus/variables';
import {
  CaseDetailsButton,
  ProductDetailsSummary,
  RecentActivity,
  RiskAnalysis,
  StatusSteps,
  TicketCount,
} from '../components/organisms';
import {
  Major,
  Minor,
  NoResultsCSS,
  ProductSummaryContainer,
} from '../components/style';

export const ProductSummaryLayout = ({
  title,
  goBack,
  productId,
  productAlias,
  productDetails,
  status,
  photos,
  steps,
  statusInstructions,
  actions,
  actionIsLoading,
  riskType,
  reason,
  parcelIsLoading,
  ticketCountText,
  goToTickets,
  displayTicketCount,
  activity,
  activityShown,
  productDetailsButtonText,
  goToProductDetails,
  componentSize,
  isLoading,
  isError,
  contactSupportButton,
  hasDetailsPermission,
  hasViewPermission,
  hasTicketCreatePermission,
  hasCaseViewPermission,
}) => {
  return (
    <ProductSummaryContainer
      size="_M"
      title={<CopyableText text={title} copyText={productAlias} />}
      goBack={goBack}
      element={hasTicketCreatePermission && contactSupportButton}
    >
      {isLoading ? (
        <Content>
          <TableLoader />
        </Content>
      ) : isError ? (
        <NoResultsCSS>
          <NoSearchResults {...defaultErrorDisplay} />
        </NoResultsCSS>
      ) : (
        <>
          <Major>
            <div></div>
            <ProductDetailsSummary
              productId={productId}
              productAlias={productAlias}
              productDetails={productDetails}
              status={status}
              photos={photos}
              noShadow={true}
              size={componentSize}
            />
            <StatusSteps
              steps={steps}
              status={status}
              statusInstructions={statusInstructions}
              actions={actions}
              size={componentSize}
              isLoading={actionIsLoading}
            />
          </Major>
          <Minor>
            <div></div>
            <RiskAnalysis
              riskType={riskType}
              reason={reason}
              noShadow={true}
              size={componentSize}
              isLoading={parcelIsLoading}
              disabled={!hasCaseViewPermission}
            />
            {hasViewPermission ? (
              <TicketCount
                text={ticketCountText}
                goToTickets={goToTickets}
                display={displayTicketCount}
              />
            ) : (
              <div />
            )}
            <RecentActivity
              activity={activity}
              showThisMany={activityShown}
              noShadow={true}
              toggle
            />
            {hasDetailsPermission && (
              <CaseDetailsButton
                text={productDetailsButtonText}
                onClick={goToProductDetails}
              />
            )}
          </Minor>
        </>
      )}
    </ProductSummaryContainer>
  );
};
