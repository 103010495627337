import * as Yup from 'yup';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { ticketBadgeTypes } from '../../../../../components/molecules/TicketTypeBadge/style';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../../constants';
import { dropdownValidation } from '../../../../../utils/validationUtils';

// should come from public/support, but get a weird import issue
const DC = {
  ...DISPATCH_CONSTANTS,
  BRANDS: 'BRANDS',
  FILTERS: 'FILTERS',
  SORTERS: 'SORTERS',
  STATUS: 'STATUS',
  PRIORITY: 'PRIORITY',
  TICKET_TYPE: 'TICKET_TYPE',
  SORT: 'SORT',
  ORDER: 'ORDER',
  OTHER_TITLE: 'OTHER_TITLE',
};

// should come from public/support, but get a weird import issue
const ticketTypes = {
  General: ticketBadgeTypes.General,
  Profile: 'Profile',
  Billing: 'Billing',
  Platform: 'Platform',
  Users: 'Users',
  Feature: 'Feature',
  Feedback: 'Feedback',
  Other: 'Other',
  // Case: 'Case',
  Parcel: ticketBadgeTypes.Parcel,
  Product: ticketBadgeTypes.Product,
};

export const titles = {
  [DC.TIME_FRAME]: 'Date',
  [DC.FILTERS]: 'Filter',
  [DC.SORTERS]: 'Sort',
  [DC.BRANDS]: 'Brand',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Search',
    param: 'search',
  },
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
  },
  [DC.BRANDS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Tag,
  },
  [DC.FILTERS]: {
    type: FilterTypes.dropdown,
    icon: Icons.Filter,
  },
  [DC.SORTERS]: {
    type: FilterTypes.dropdown,
    icon: Icons.ArrowUpDown,
    highlight: true,
    showCheckbox: false,
    hideSearch: true,
    hideCount: true,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
  [DC.RESET]: {
    type: FilterTypes.button,
    icon: Icons.X,
  },
};

export const createTicketFiltersConstants = type => ({
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder:
      type === ticketTypes.Parcel
        ? 'Search by parcel ID'
        : type === ticketTypes.Product
        ? 'Search by parcel or product ID'
        : 'Search',
    alwaysFullWidth: true,
    size: '_M',
  },
});

export const initialFilterValues = {
  [DC.FILTERS]: '',
  [DC.SORTERS]: '',
  [DC.BRANDS]: '',
};

export const ticketInitialFormValues = {
  [DC.SEARCH]: '',
  [DC.OTHER_TITLE]: '',
  [DC.BRANDS]: '',
};

export const allOptions = {
  [DC.FILTERS]: { label: 'All tickets', value: Constants.all },
  [DC.BRANDS]: { label: 'All brands', value: Constants.all },
};

export const sectionTitles = {
  [DC.FILTERS]: {
    [DC.STATUS]: 'Status',
    [DC.PRIORITY]: 'Priority',
    [DC.TICKET_TYPE]: 'Category',
  },
  [DC.SORTERS]: {
    [DC.SORT]: 'Sort by',
    [DC.ORDER]: 'Order',
  },
};

export const sortOptions = {
  creationDate: 'creation_date',
  lastMessageDate: 'last_message_date',
};

export const staticOptions = {
  [DC.FILTERS]: [
    {
      section: DC.STATUS,
      sectionTitle: 'Status',
      options: [
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Resolved',
          value: 'Resolved',
        },
      ],
    },
    {
      section: DC.PRIORITY,
      sectionTitle: 'Priority',
      options: [
        {
          label: 'Priority',
          value: 'Priority',
        },
        {
          label: 'Normal',
          value: 'Normal',
        },
      ],
    },
    {
      section: DC.TICKET_TYPE,
      sectionTitle: 'Category',
      options: [
        {
          label: 'General',
          value: ticketTypes.General,
        },
        {
          label: 'Parcel',
          value: ticketTypes.Parcel,
        },
        {
          label: 'Product',
          value: ticketTypes.Product,
        },
      ],
    },
  ],
  [DC.SORTERS]: [
    {
      section: DC.SORT,
      sectionTitle: 'Sort by',
      multiSelect: false,
      options: [
        {
          label: 'Date created',
          value: sortOptions.creationDate,
          selected: false,
        },
        {
          label: 'Last message',
          value: sortOptions.lastMessageDate,
          selected: true,
        },
      ],
    },
    {
      section: DC.ORDER,
      sectionTitle: 'Order',
      multiSelect: false,
      options: [
        {
          label: 'Oldest first',
          value: 'ascending',
          selected: false,
        },
        {
          label: 'Newest first',
          value: 'descending',
          selected: true,
        },
      ],
    },
  ],
};

Object.values(staticOptions).forEach(filter => {
  filter.forEach(({ options }) => {
    options.forEach(o => {
      o.selectableBy = [...(o.selectableBy ?? []), Constants.all];
      o.selected = o.selected ?? true;
    });
  });
});

export const TicketConstants = {
  Titles: {
    createTicket: 'New support ticket',
    selectPriority: 'What is the level of priority of your request?',
    moreDetails: 'Would you like to give us more details?',
    otherTicket: { label: 'Other', value: 'Other' },
    moreDetailsPlaceholder:
      'Add additional information to help us to handle better your support request',
    otherMaxLength: 199,
    newTicket: [
      {
        value: 'I need more information about the parcel',
        label: 'I need more information about the product',
      },
      {
        value: 'I need more photos of the items',
        label: 'I need more photos of the items',
      },
    ],
    ticketBadges: {
      [DC.STATUS]: 'TICKET STATUS',
      [DC.PRIORITY]: 'TICKET PRIORITY',
    },
  },

  priorityOptions: [
    {
      value: 'Priority',
      label: 'Priority',
    },
    {
      value: 'Normal',
      label: 'Normal',
    },
  ],

  Actions: {
    cancel: 'Cancel',
    createTicket: 'Submit ticket',
  },
};

export const HelpConstants = {
  Titles: {
    helpType: 'How can we help you?',
    helpTypeSubtitle:
      'Choose a category to create a support request. We will get back to you as soon as possible.',
    moreDetails: 'Please describe your request',
    moreDetailsPlaceholder:
      'Add more information to help us to better handle your request',
    createTicket: 'New support ticket',
  },
  Actions: {
    cancel: 'Cancel',
    createTicket: 'Submit',
  },
  helpTypeOptions: [
    {
      value: 'Profile',
      label: 'Profile',
    },
    {
      value: 'Billing',
      label: 'Billing',
    },
    {
      value: 'Platform',
      label: 'Platform',
    },
    {
      value: 'Users',
      label: 'Users',
    },
    {
      value: 'Feature',
      label: 'Feature',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
};

export const helpValidationSchema = Yup.object({
  moreDetails: Yup.string()
    .min(20, 'Please use at least 20 characters')
    .max(500, 'Max characters: 500')
    .required('Please provide a description'),
  brand: dropdownValidation,
});

export const noResultsState = {
  noData: onClick => ({
    icon: NoData.Support,
    title: 'No support tickets',
    description: 'Create a new ticket to ask for help from our support team',
    button: {
      text: 'Create new ticket',
      variant: 'Primary',
      size: '_M',
      onClick,
    },
  }),
  noSearchResults: {
    icon: NoResults.Support,
    title: 'No results',
    description: 'Please try another search or view all support tickets',
  },
};
