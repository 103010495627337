import { Indicator } from './PageIndicator.style';

export const PageIndicator = ({ top, transition, color, hideIndicator }) => {
  return (
    !hideIndicator && (
      <Indicator
        top={top}
        transition={transition}
        color={color}
        className="page-indicator"
      />
    )
  );
};
