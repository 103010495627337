export const Pages = {
  admin_categories: 'admin_categories',
  admin_brands: 'admin_brands',
  admin_references: 'admin_references',
  admin_services: 'admin_services',
  admin_sorters: 'admin_sorters',
  dashboard: 'office_dashboard',
  data: 'office_sorter_data',
  knowledge_base: 'office_knowledge_base',
  parcel_evaluation: 'office_review',
  parcels: 'office_parcels',
  products: 'office_products',
  support: 'office_support',
};

const {
  admin_categories,
  admin_brands,
  admin_references,
  admin_services,
  admin_sorters,
  dashboard,
  data,
  knowledge_base,
  parcel_evaluation,
  parcels,
  products,
  support,
} = Pages;

export const Labels = {
  [admin_categories]: 'Admin Categories',
  [admin_brands]: 'Admin Brands',
  [admin_references]: 'Admin References',
  [admin_services]: 'Admin Services',
  [admin_sorters]: 'Admin Sorters',
  [dashboard]: 'Office Dashboard',
  [data]: 'Office Sorter Data',
  [knowledge_base]: 'Office Knowledge Base',
  [parcel_evaluation]: 'Office Review',
  [parcels]: 'Office Parcels',
  [products]: 'Office Products',
  [support]: 'Office Support',
};

export const URIs = {
  [admin_categories]: admin_categories,
  [admin_brands]: admin_brands,
  [admin_references]: admin_references,
  [admin_services]: admin_services,
  [admin_sorters]: admin_sorters,
  [dashboard]: dashboard,
  [data]: data,
  [knowledge_base]: knowledge_base,
  [parcel_evaluation]: parcel_evaluation,
  [parcels]: parcels,
  [products]: products,
  [support]: support,
};

export const NavURIs = {
  [admin_categories]: `/${URIs[admin_categories]}`,
  [admin_brands]: `/${URIs[admin_brands]}`,
  [admin_references]: `/${URIs[admin_references]}`,
  [admin_services]: `/${URIs[admin_services]}`,
  [admin_sorters]: `/${URIs[admin_sorters]}`,
  [dashboard]: `/${URIs[dashboard]}`,
  [data]: `/${URIs[data]}`,
  [knowledge_base]: `/${URIs[knowledge_base]}`,
  [parcel_evaluation]: `/${URIs[parcel_evaluation]}`,
  [parcels]: `/${URIs[parcels]}`,
  [products]: `/${URIs[products]}`,
  [support]: `/${URIs[support]}`,
};
