import useFetchData from '../../../../../hooks/fetchHook';
import { whiteLabelKeys } from '../controllers/variables';

export const useGetWhitelabelPrint = () => {
  const [{ data = [], isLoading, isError, error }, _, doGetPrintData] =
    useFetchData(``, {
      formatter: formatWhitelabelPrint,
      ignore: true,
    });

  const getPrintData = ({ [whiteLabelKeys.id]: parcelId } = {}) => {
    if (!parcelId) return {};
    const url = `parcel/${parcelId}/whitelabel_print`;
    const printData = doGetPrintData({ url });

    return printData;
  };

  return { data, isLoading, isError, error, getPrintData };
};

const formatWhitelabelPrint = data => {
  return formatPrintData(JSON.parse(data));
};

export const formatPrintData = data => {
  const {
    title,
    receiver,
    sender,
    origin_depot,
    parcel_info,
    routing,
    additional_information,
    code_128: barcode,
  } = data ?? {};

  const { consignment, consignment_spaced } = parcel_info ?? {};
  const { name, phone } = receiver ?? {};
  const delivery_details = { name, phone, consignment };

  const { packages_text, weight_text } = parcel_info ?? {};
  const parcel_details = { packages_text, weight_text };

  const { service, route, origin_sort, destination_sort } = routing ?? {};

  const { text: additional_information_text, cc_parcel_id } =
    additional_information ?? {};

  const routing_ = {
    consignment_spaced,
    service,
    route,
    origin_sort,
    destination_sort,
  };

  return {
    [whiteLabelKeys.companyLabel.title]: title,
    [whiteLabelKeys.companyLabel.receiver]: {
      // TODO: use js.pick()
      header: receiver.header,
      name: receiver.name,
      company: receiver.company,
      address: receiver.address,
      postcode: receiver.postcode,
      city: receiver.city,
      state: receiver.state,
      country: receiver.country,
      phone: receiver.phone,
    },
    [whiteLabelKeys.companyLabel.sender]: {
      header: sender.header,
      name: sender.name,
      address: sender.address,
      location: [sender.postcode, sender.city, sender.country].join(' '),
      phone: sender.phone,
    },
    [whiteLabelKeys.companyLabel.originDepot]: {
      depot_name: origin_depot.depot_name,
      depot_address: origin_depot.depot_address,
      depot_postcode_city: origin_depot.depot_postcode_city,
    },
    [whiteLabelKeys.companyLabel.deliveryDetails]: delivery_details,
    [whiteLabelKeys.companyLabel.parcelDetails]: {
      Packages: parcel_details.packages_text,
      Weight: parcel_details.weight_text,
    },
    [whiteLabelKeys.companyLabel.additionalInformation]:
      additional_information_text,
    [whiteLabelKeys.companyLabel.ccParcelId]: cc_parcel_id,
    [whiteLabelKeys.companyLabel.aztec]: null,
    [whiteLabelKeys.companyLabel.routing]: routing_,
    [whiteLabelKeys.companyLabel.barcode]: barcode.content,
    readyToPrint: !!consignment,
    [whiteLabelKeys.customerIdField]: sender.cc_customer_number,
  };
};
