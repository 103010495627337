import { Icon, Text } from '../../atoms';
import { Colors, FontKeys, Size } from '../../style';
import {
  Container,
  IconEnd,
  IconStart,
  TextContainer,
} from './FilterTitle.style';

export const FilterTitle = ({
  title,
  placeholder,
  iconStart,
  iconEnd,
  iconSize,
  size = '_S',
  open,
}) => {
  return (
    <Container>
      {iconStart ? (
        <IconStart>
          <Icon name={iconStart} size={iconSize ?? Size.Icon[size]} />
        </IconStart>
      ) : (
        <div />
      )}
      <TextContainer open={open}>
        <Text
          text={title ?? placeholder}
          variant={FontKeys.Variant.Paragraph}
          size={FontKeys.Size[size]}
          weight={FontKeys.Weight.Semibold}
          color={title == null ? Colors.Neutral._400 : undefined}
          ellipsis
          whiteSpace="nowrap"
        />
      </TextContainer>
      <IconEnd>
        <Icon name={iconEnd} size={iconSize ?? Size.Icon[size]} />
      </IconEnd>
    </Container>
  );
};
