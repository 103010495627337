import { useState } from 'react';
import { useModal } from '../../../../providers/ModalProvider';
import { PreviewGalleryContainer } from '../components/atoms/atoms';
import { MainSlider } from '../components/molecules/molecules';
import ThumbnailGallery from '../components/organisms/organisms';
import Gallery from './Gallery';

export default function PreviewGallery({
  images = [],
  galleryProps,
  id,
  useParentImages = false,
  galleryTitle,
  amount = 4,
}) {
  const [selected, setSelected] = useState(0);

  const { showModal } = useModal();

  const handleSelect = action => {
    const handle = number => {
      if (number === selected) return;
      setSelected(number);
    };
    if (action === 'next') {
      const atEnd = selected === images.length - 1;
      handle(atEnd ? 0 : selected + 1);
    }

    if (action === 'prev') {
      const atStart = selected === 0;
      handle(atStart ? images.length - 1 : selected - 1);
    }

    if (typeof action === 'number') handle(action);
  };

  const showGallery = initialSelected => () => {
    showModal(
      Gallery,
      {
        ...(galleryProps ?? {}),
        id,
        initialSelected,
        images: useParentImages ? images : undefined,
        title: galleryTitle,
      },
      {
        stack: true,
        keepPrev: true,
      }
    );
  };

  return (
    <PreviewGalleryContainer className="preview-gallery">
      <MainSlider onClick={showGallery(selected)} image={images[selected]} />
      <ThumbnailGallery
        images={images}
        selected={selected}
        handleSelect={handleSelect}
        type="preview"
        amount={amount}
      />
    </PreviewGalleryContainer>
  );
}
