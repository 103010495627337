import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import BrandSettingsLayout from '../components/BrandSettingsLayout';
import { styles } from '../components/style';
import Billing from './Billing';
import Contracts from './Contracts';
import EditBrandLogo from './EditBrandLogo';
import Users from './Users';
import { options as allOptions, optionViews } from './variables';

const { SELF } = PermissionKeys.Brand.BRAND_MANAGEMENT;

const BrandSettings = () => {
  const { hasPermission } = usePermissions();

  const components = {
    [optionViews.brandLogo]: <EditBrandLogo />,
    [optionViews.billing]: <Billing />,
    [optionViews.users]: <Users />,
    [optionViews.contracts]: <Contracts />,
  };

  const withPermissions = options =>
    options.filter(({ permission }) => hasPermission(SELF, permission));

  const options = withPermissions(allOptions);

  return (
    <BrandSettingsLayout
      options={options}
      components={components}
      styles={styles}
    />
  );
};

export default BrandSettings;
