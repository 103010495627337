import { Flex } from '../../atoms/Flex/Flex';
import { Description } from './NoResults.style';

export const NoResults = ({ icon, description }) => {
  return (
    <Flex column gap="_M" className="no-results" margin="auto">
      {icon ? (
        <Flex align="center" justify="center">
          {/* TODO: this should be an <Icon /> */}
          {icon}
        </Flex>
      ) : (
        ''
      )}
      {description && <Description description={description} />}
    </Flex>
  );
};
