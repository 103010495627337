import { Flex } from '../../../../components/atoms/Flex/Flex';
import Avatar from '../../../../components/molecules/Avatar/Avatar';
import { Input } from '../../../../components/molecules/Input/Input';
import StaticSpinner from '../../../../components/molecules/LoadingAnimation/StaticSpinner';
import { PasswordInput } from '../../../../components/molecules/PasswordInput/PasswordInput';
import PasswordRules from '../../../../components/organisms/PasswordRules/PasswordRules';
import { Colors } from '../../../../components/style';
import {
  Cancel,
  EditAvatarIcon,
  PasswordFieldsTitle,
  PasswordRulesTitle,
  Submit,
  UsernameText,
} from '../atoms/atoms';
import { EditAvatarOptions } from '../molecules/molecules';
import {
  ActionsCSS,
  DetailsFormCSS,
  EditableAvatarCSS,
  PasswordFieldsCSS,
} from '../style';

export const EditableAvatar = ({ username, image, options, isLoading }) => {
  const size = '_3XL';
  return (
    <EditableAvatarCSS size={size} isLoading={isLoading}>
      {isLoading ? (
        <Flex w h justify="center" align="center">
          <StaticSpinner size={60} dotSize={4.5} color={Colors.Sharp.White} />
        </Flex>
      ) : (
        <>
          <Avatar username={username} image={image} size={size} />
          <EditAvatarOptions options={options} />
          <EditAvatarIcon />
        </>
      )}
    </EditableAvatarCSS>
  );
};

export const UserDetails = ({ username }) => {
  return (
    <Flex column gap="_2XS">
      <UsernameText text={username} />
    </Flex>
  );
};

export const DetailsForm = ({ fields }) => {
  return (
    <DetailsFormCSS columns={2}>
      {fields.map(field => (
        <Input {...field} size="_M" hasError key={field.name} />
      ))}
    </DetailsFormCSS>
  );
};

export const Actions = ({
  onCancel,
  cancelDisabled,
  submitDisabled,
  isLoading,
}) => {
  return (
    <ActionsCSS>
      <Cancel onClick={onCancel} disabled={cancelDisabled} />
      <Submit disabled={submitDisabled} isLoading={isLoading} />
    </ActionsCSS>
  );
};

export const PasswordFields = ({ fields }) => {
  return (
    <PasswordFieldsCSS>
      <PasswordFieldsTitle text="Change password" />
      <Flex column>
        {fields.map(field => (
          <PasswordInput {...field} key={field.name} />
        ))}
      </Flex>
    </PasswordFieldsCSS>
  );
};

export const PasswordRulesDiv = ({ rules }) => {
  return (
    <Flex column gap="_M">
      <PasswordRulesTitle text="Your new password must contain:" />
      <PasswordRules rules={rules} />
    </Flex>
  );
};
