import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { processingKeys } from '../controllers/variables';

export const useGetParcel = ({ id }) => {
  const [{ data, isLoading, isError, error }, _, getParcel] = useFetchData('', {
    formatter,
    ignore: id == null,
  });

  return { data, isLoading, isError, error, getParcel };
};

const formatter = data => {
  maskEntry(data, mask);
  data[processingKeys.productCount] = data[processingKeys.products].length;
  return data;
};

const mask = {
  // actors:
  // barcodes:
  // brImage:
  // caSenderType:
  cases: processingKeys.products,
  case_count: processingKeys.productCount,
  // createdAt:
  paAlias: processingKeys.alias,
  // paHeight:
  paID: processingKeys.id,
  // paLength:
  // paRiskType:
  paSideloadDate: processingKeys.sideloadDate,
  paStatus: processingKeys.status,
  // paTracking:
  // paVolume:
  // paWeight:
  // paWidth:
  // pbBarcode: ,
  // pdConfidence:
  // pdMatchText:
  // pdMatchType:
  // pdOCR:
  // pdSenderFull:
  photos: 'photos',
  // seCompany:
  // seCountry:
  // seName:
  // soCity:
  // soCountry:
  // soID:
  // soName:
  rest_quantity: processingKeys.product.itemCount,

  // cases
  brID: processingKeys.product.brand.id,
  brName: processingKeys.product.brand.name,
  caAlias: processingKeys.product.alias,
  caID: processingKeys.product.id,
  cpID: processingKeys.product.categoryId,
  brIsContracted: processingKeys.product.brand.isContracted,
  cspQuantity: processingKeys.product.itemCount,
  bcName: processingKeys.product.category,
  cpName: processingKeys.product.subcategory,
  stName: processingKeys.product.status,
};
