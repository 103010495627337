import { Colors } from '../../style';
import { Container } from './style';

const Pointer = ({
  direction = 'left',
  size = 8,
  color = Colors.Sharp.White,
}) => {
  return <Container color={color} size={size} direction={direction} />;
};

export default Pointer;
