import { Image } from '../../../../components/atoms/Image/Image';
import { FileDropzoneNew } from '../../../../components/organisms/FileDropzoneNew/FileDropzoneNew';
import { Utils } from '../../../../constants';
import { knowledgeBaseKeys } from '../../../../platform/brand/features/knowledge_base/controllers/variables';
import { RemoveInstance } from '../atoms';
import {
  FileInstanceCSS,
  FilePreviewCSS,
  HideTrack,
  InstanceTrack,
} from '../style';

export const FilePreview = ({ file, onClick }) => {
  return (
    <FilePreviewCSS onClick={onClick}>
      <Image
        src={
          file[knowledgeBaseKeys.imageUrl]
            ? file[knowledgeBaseKeys.imageUrl]
            : URL.createObjectURL(file)
        }
        style={{ objectFit: 'cover' }}
      />
    </FilePreviewCSS>
  );
};

export const FilesGallery = ({
  showAmount,
  files,
  fileState,
  rightSwipe,
  beforeElement,
  afterElement,
  isLoading,
  handleDrop,
  storageNames,
  percentageShares,
  uploadStatusContent,
  showMultiple,
  parentIsLoading,
  removeInstance,
  onPreviewClick = Utils.emptyFunction,
  preexistingFilesLength,
}) => (
  <HideTrack>
    <InstanceTrack
      instanceNumber={files.length}
      rightSwipe={rightSwipe}
      showAmount={showAmount}
      className="instance-track"
    >
      {files.map((file, index) => (
        <FileInstanceCSS
          key={file?.name + String(file?.size ?? index)}
          className="file-instance"
        >
          {typeof beforeElement === 'function' && beforeElement(file, index)}
          {file && (!isLoading || index <= preexistingFilesLength - 1) ? (
            <FilePreview file={file} onClick={onPreviewClick(file, index)} />
          ) : (
            <FileDropzoneNew
              handleDrop={handleDrop(index)}
              fileState={fileState}
              percentageUploaded={
                percentageShares[storageNames[index - preexistingFilesLength]]
              }
              uploadStatusContent={uploadStatusContent}
              multiple={
                showMultiple &&
                fileState.files.length + preexistingFilesLength === 1
              }
              preview={showMultiple}
            />
          )}

          {typeof removeInstance === 'function' &&
            (files.length > 1 || !!preexistingFilesLength) &&
            !isLoading &&
            !parentIsLoading && (
              <RemoveInstance onClick={removeInstance(index)} />
            )}
          {typeof afterElement === 'function' && afterElement(file, index)}
        </FileInstanceCSS>
      ))}
    </InstanceTrack>
  </HideTrack>
);
