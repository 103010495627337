import { useState } from 'react';
import { Utils } from '../../../../../constants';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { downloadFile } from '../../../../../utils/utils';
import { useGetContracts } from '../api/getContracts';
import { ContractsLayout } from '../components/ContractsLayout';
import {
  contractHeaders,
  contractKeys,
  contractsGrid,
  noResultsState,
} from './variables';

const Contracts = () => {
  const [data, setData] = useState([]);

  const { data: contracts, isLoading, isError, error } = useGetContracts();

  useDeepCompareEffect(() => {
    setData(contracts);
  }, [contracts]);

  const downloadContract = item => () => downloadFile(item[contractKeys.url]);

  return (
    <ContractsLayout
      headers={contractHeaders}
      data={data}
      grid={contractsGrid}
      rowAction={Utils.emptyFunction}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={{
        noData: data.length === 0,
        noDataDisplay: noResultsState.contracts.noData,
        noSearchResultsDisplay: noResultsState.contracts.noData,
        // no filter setting, so no need to handle noSearchResults
      }}
      downloadContract={downloadContract}
    />
  );
};
export default Contracts;
