import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../../../components/molecules/Button/Button';
import {
  generateDropdown,
  hasCustomSearch,
  mergeFilterStateAndConsts,
} from '../../../../../components/organisms/Filters/utils';
import { Pagination } from '../../../../../components/organisms/Pagination';
import TablePageHeader from '../../../../../components/templates/TablePageHeader/TablePageHeader';
import { tableStatusProps } from '../../../../../components/templates/TableStatus/utils';
import { Utils } from '../../../../../constants';
import { useScrollLoad } from '../../../../../hooks/scrollLoadHook';
import { dispatchAction, useTableClear } from '../../../../../hooks/tableHook';
import { useTableParams } from '../../../../../hooks/tableParamsHook';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import { usePermissions } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/variables';
import { useUI } from '../../../../../providers/UIProvider';
import { titleFromCalendar } from '../../../../../utils/timeUtils';
import { elementDimensions, screenPosition } from '../../../../../utils/utils';
import TicketTypeSelection from '../../../features_public/support/controllers/TicketTypeSelection';
import { DC } from '../../../features_public/support/controllers/variables';
import { Labels, NavURIs, Pages } from '../../../routes/variables';
import { useGetRecentActivity } from '../api/getRecentActivity';
import { useGetTickets } from '../api/getTickets';
import { useGetFilters } from '../api/useGetFilters';
import SupportLayout from '../components/SupportLayout';
import { useSupportReducer } from './Support.hook';
import {
  allOptions,
  filtersConstants,
  initialFilterValues,
  noResultsState,
  titles,
} from './variables';

const {
  SELF,
  // view
  VIEW_GENERAL_SUPPORT_TICKETS,
  VIEW_OWN_GENERAL_SUPPORT_TICKETS,
  VIEW_PARCEL_SUPPORT_TICKETS,
  VIEW_OWN_PARCEL_SUPPORT_TICKETS,
  VIEW_PRODUCT_SUPPORT_TICKETS,
  VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  // create
  CREATE_GENERAL_SUPPORT_TICKETS,
  CREATE_PARCEL_SUPPORT_TICKETS,
  CREATE_PRODUCT_SUPPORT_TICKETS,
} = PermissionKeys.Brand.SUPPORT;

const Support = () => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const [searchQueue, setSearchQueue] = useState([]);

  const [moreActivityClicked, setMoreActivityClicked] = useState(false);
  const [ticketLeft, setTicketLeft] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();
  const { setShowHeader, setHeaderChildren } = useHeader();
  const { setShowFooter, setFooterChildren } = useFooter();
  const { updateSharedUI } = useUI();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const hasViewPermission = [
    VIEW_GENERAL_SUPPORT_TICKETS,
    VIEW_OWN_GENERAL_SUPPORT_TICKETS,
    VIEW_PARCEL_SUPPORT_TICKETS,
    VIEW_OWN_PARCEL_SUPPORT_TICKETS,
    VIEW_PRODUCT_SUPPORT_TICKETS,
    VIEW_OWN_PRODUCT_SUPPORT_TICKETS,
  ].some(p => hasPermission(p));

  const hasCreatePermission = [
    CREATE_GENERAL_SUPPORT_TICKETS,
    CREATE_PARCEL_SUPPORT_TICKETS,
    CREATE_PRODUCT_SUPPORT_TICKETS,
  ].some(p => hasPermission(p));

  const skipFirst = useRef(true);
  const searchParamsRef = useRef(null);
  const resetFormRef = useRef(null);
  const ticketsRef = useRef(null);
  const activityRef = useRef(null);

  let ownOptions;

  const {
    data: options,
    isLoading: filtersIsLoading,
    isError: filtersIsError,
  } = useGetFilters({});

  const { formInitialValues, resetSearchParams, initialSearchParams } =
    useTableParams({
      options,
      ownOptions,
      allOptions,
      filtersConstants,
      isError: filtersIsError,
    });

  const [searchParams, _dispatch] = useSupportReducer({
    initialSearchParams,
    resetSearchParams,
  });

  const dispatch = dispatchAction(_dispatch);

  const urlHasSearch = hasCustomSearch(location);
  const wasReset = searchParams?.[DC.HAS_RESET];

  const ignoreDataFetch =
    !hasViewPermission ||
    // url has parameters but searchParams is empty
    (!wasReset && urlHasSearch && !searchParams[DC.SET]);

  // mark all options with as selected
  useEffect(() => {
    if (filtersIsLoading) return;
    _dispatch({ type: DC.SET, payload: initialSearchParams });
  }, [initialSearchParams]);

  const {
    data: { tickets, ticketCount: dataCount },
    isLoading,
    isError,
    error,
    refreshTickets: refreshTable,
  } = useGetTickets({
    searchParams,
    ignore: ignoreDataFetch,
  });

  useLayoutEffect(() => {
    const container = ticketsRef.current;
    if (!container) return;
    const resizeObserver = new ResizeObserver(() => rszObserverCallback());
    resizeObserver.observe(container);
    return () => {
      resizeObserver.unobserve(container);
    };
  }, [tickets]);

  const rszObserverCallback = () => {
    if (!ticketsRef.current) return;
    const { width, height } = elementDimensions({
      currentTarget: ticketsRef?.current,
    });

    const { x, y } = screenPosition({
      currentTarget: ticketsRef?.current,
    });

    const ticketLeft = x + width;

    setTicketLeft(ticketLeft);
  };

  const {
    data: activity,
    isLoading: activityIsLoading,
    isError: activityIsError,
    fetchMore,
    refetch: refetchActivity,
  } = useScrollLoad({
    hook: useGetRecentActivity,
    getMore: 'getActivity',
    scrollRef: activityRef,
    reassign: isLoading,
    ignore: !hasViewPermission,
    moreActivityClicked,
  });

  useEffect(() => {
    const to = NavURIs[Pages.support];
    const clearSavedFilters = () => navigate(to, { replace: true });
    window.addEventListener('beforeunload', clearSavedFilters);
    return () => {
      window.removeEventListener('beforeunload', clearSavedFilters);
    };
  }, []);

  useEffect(() => {
    if (skipFirst.current) {
      skipFirst.current = false;
      return;
    }
    const newSearchValue = searchParams?.searchValue;
    const functionalUpdate = values => [...values, newSearchValue];

    setSearchQueue(functionalUpdate);
  }, [searchParams.searchValue]);

  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  useEffect(() => {
    if (!searchQueue?.length || !hasViewPermission) return;
    if (isLoading) {
      const newValue = searchQueue[searchQueue.length - 1];
      if (newValue === searchQueue[0]) return;
      setSearchQueue([newValue]);
    } else {
      const newValues = [...searchQueue];
      const newValue = newValues.shift() ?? '';
      dispatch(DC.SEARCH_TRIGGER)(newValue);
      setSearchQueue(newValues);
    }
  }, [searchQueue, isLoading]);

  const showNewTicket = () => showModal(TicketTypeSelection, { navigate });

  const handleNotificationClick =
    ({ itemId }) =>
    () => {
      const to = `/support/${itemId}`;
      navigate(to);
    };

  const { resetForm, refreshView } = useTableClear({
    resetFormRef,
    dispatch: _dispatch,
    refreshTable: e => {
      refreshTable(e);
      refetchActivity();
    },
    refetchFilters: Utils.emptyFunction,
  });
  const firstActivityLoad = () => {
    setMoreActivityClicked(true);
    fetchMore();
  };

  const dropdown = generateDropdown({
    searchParams,
    resetSearchParams,
    filterValues,
    ownOptions,
    setFilterValues,
    dispatch: _dispatch,
    titles,
    allSectionsMustHaveSelection: true,
  });

  const filtersState = {
    [DC.SEARCH]: {
      filter: dispatch(DC.SEARCH),
      value: searchParams[DC.SEARCH],
      name: [DC.SEARCH],
    },
    [DC.TIME_FRAME]: {
      filter: dispatch(DC.TIME_FRAME),
      title: titleFromCalendar({ searchParams, titles, key: DC.TIME_FRAME }),
      timeFrameInitVals: searchParams[DC.TIME_FRAME],
    },
    [DC.BRANDS]: dropdown(DC.BRANDS),
    [DC.FILTERS]: dropdown(DC.FILTERS),
    [DC.SORTERS]: dropdown(DC.SORTERS),
    [DC.REFRESH]: { onClick: refreshView },
    [DC.RESET]: {
      onClick: dispatch(DC.RESET, { resetForm }),
    },
  };

  const filters = mergeFilterStateAndConsts({ filtersState, filtersConstants });

  const firstLoad =
    !searchParams[DC.FILTERS_SET] && !searchParams[DC.HAS_RESET];

  useEffect(() => {
    if (!hasViewPermission) {
      setShowHeader(false);
      return;
    }
    if (isLoading || filtersIsLoading) return;
    if (firstLoad && !dataCount) {
      setShowHeader(false);
    } else {
      const headerChildren = (
        <TablePageHeader
          title={Labels[Pages.support]}
          formInitialValues={formInitialValues}
          resetFormRef={resetFormRef}
          setFieldValueRef={null}
          hasViewPermission={hasViewPermission}
          searchParams={searchParams}
          filters={filters}
          isLoading={isLoading}
          endSlot={
            hasCreatePermission ? (
              <Button
                text={'New ticket'}
                onClick={showNewTicket}
                variant="Primary"
                size="_S"
              />
            ) : null
          }
        />
      );

      setHeaderChildren(headerChildren);
    }
  }, [isLoading, searchParams, filtersIsLoading, filterValues]);

  useEffect(() => {
    if (!hasViewPermission || (firstLoad && !dataCount)) {
      setShowFooter(false);
      return;
    }
    const props = {
      isLoading,
      ...tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
      }),
    };
    const footerChildren = <Pagination {...props} />;

    setFooterChildren(footerChildren);
  }, [isLoading, searchParams]);

  useEffect(() => {
    const layoutCustomStyles = `
      .inner-content {
        padding-top: 0;
        padding-bottom: 0;
      }
    `;
    updateSharedUI({ layoutCustomStyles });
    return () => updateSharedUI({ layoutCustomStyles: '' });
  }, []);

  const noDataAction = hasCreatePermission ? showNewTicket : undefined;

  return (
    <SupportLayout
      tickets={tickets}
      isError={isError}
      error={error}
      isLoading={isLoading}
      ticketsRef={ticketsRef}
      ticketLeft={ticketLeft}
      activity={activity}
      activityRef={activityRef}
      handleNotificationClick={handleNotificationClick}
      loadMoreActivity={firstActivityLoad}
      activityIsLoading={activityIsLoading}
      activityIsError={activityIsError}
      moreActivityClicked={moreActivityClicked}
      hasViewPermission={hasViewPermission}
      tableStatusProps={tableStatusProps({
        searchParams,
        dispatch,
        dataCount,
        noResultsState,
        noDataAction,
        hasViewPermission,
      })}
    />
  );
};

export default Support;
