import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { firstDate, isSameDay } from '../../../../utils/timeUtils';
import DatepickerContainer from './DatepickerContainer';
import { createParams } from './utils';
import { actions, presetOptions } from './variables';

export default function DatePickerCustom({
  filter,
  setIsOpen,
  isOpen,
  initialStart = null,
  initialEnd = null,
}) {
  const [startDate, setStartDate] = useState(initialStart);
  const [endDate, setEndDate] = useState(initialEnd);
  const [action, setAction] = useState(null);

  const [selectedPreset, setSelectedPreset] = useState(-1);

  const datePickerRef = useRef(null);

  useEffect(() => {
    if (!action) return;
    if (action === actions.apply) filter(createParams(startDate, endDate))();
    if (action === actions.cancel) {
      setStartDate(initialStart);
      setEndDate(initialEnd);
    }
    showModal(false);
    setAction(null);
  }, [action]);

  useEffect(() => {
    const preset = presetOptions.findIndex(({ dates }) => {
      const { start, end } = dates();
      return isSameDay(start, startDate) && isSameDay(end, endDate);
    });

    setSelectedPreset(preset);
  }, [startDate, endDate]);

  const showModal = boolean => {
    datePickerRef.current?.setOpen(boolean);
    setIsOpen(boolean);
  };

  const handleCalendarOpen = () => showModal(true);
  const handleCalendarClose = () => showModal(false);

  const onDateChange = dates => {
    setSelectedPreset(-1);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const changeDates = (start = startDate, end = endDate) => {
    setStartDate(start);
    setEndDate(end);
  };

  const __DatePickerContainer = args => {
    return DatepickerContainer({
      ...args,
      setAction,
      dateKit: {
        selectedPreset,
        setSelectedPreset,
        changeDates,
        startDate,
        endDate,
      },
    });
  };

  return (
    <div className="datepicker-custom">
      <DatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        shouldCloseOnSelect={false}
        includeDateIntervals={[
          {
            start: firstDate,
            end: new Date(),
          },
        ]}
        openToDate={endDate ?? new Date()}
        calendarContainer={__DatePickerContainer}
        calendarStartDay={1}
        selectsRange
        disabledKeyboardNavigation
        showMonthDropdown
        showYearDropdown
      />
    </div>
  );
}
