import styled from '@emotion/styled';
import { Table } from '../../../../../components/organisms/Table/Table';

export const CasesTableStyles = styled(Table)`
  .table-row {
    ._0,
    ._1 {
      padding: 0;
    }
  }
  .table-header._2,
  .table-header._11 {
    span {
      overflow: visible !important;
    }
  }
  .expansion-item {
    .table-header {
      span {
        overflow: visible !important;
      }
    }
  }
`;
