import { forwardRef } from 'react';
import Spacer from '../../../components/atoms/Spacer/Spacer';
import { Announcements, NotificationsDisplay } from './organisms';
import { NotificationsContainer } from './style';

const NotificationsLayout_ = (
  {
    notifications,
    announcements,
    handleNavigate,
    isLoading,
    clearMenu,
    showFeaturePreview,
  },
  ref
) => {
  return (
    <NotificationsContainer>
      <NotificationsDisplay
        scrollRef={ref}
        handleNavigate={handleNavigate}
        notifications={notifications}
        isLoading={isLoading}
      />
      <Announcements
        announcements={announcements}
        clearMenu={clearMenu}
        showFeaturePreview={showFeaturePreview}
      />
      <Spacer height={'_S'} />
    </NotificationsContainer>
  );
};

export const NotificationsLayout = forwardRef(NotificationsLayout_);
