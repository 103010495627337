import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useDownloadMonthlyReports } from '../api/downloadMonthlyReport';
import { useGetReportsMonths } from '../api/getReportsMonths';
import { MonthlyReportsLayout } from '../components/MonthlyReportsLayout';
import {
  monthlyReportsGrid,
  monthlyReportsHeaders,
  noResultsState,
} from './variables';

const MonthlyReports = () => {
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [displayYear, setDisplayYear] = useState();

  const { showSnackbarError } = useSnackbar();

  const { data: months, isLoading, isError, error } = useGetReportsMonths({});

  const {
    data: downloadData,
    isLoading: downloadIsLoading,
    isError: downloadIsError,
    downloadMonthlyReport,
  } = useDownloadMonthlyReports();

  useEffect(() => {
    if (isLoading || months == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const years = [];
    months.forEach(({ year }) => {
      if (!years.includes(year)) years.push(year);
    });

    setYears(
      years.map(year => ({
        value: year,
        label: year,
      }))
    );
    setDisplayYear(years[years.length - 1]);
    setData(months);
  }, [months]);

  const handleAction = item => () => downloadMonthlyReport(item);

  const changeDisplayYear =
    ({ value }) =>
    () =>
      setDisplayYear(value);

  const yearsFilter = {
    title: displayYear,
    sections: [{ options: years }],
    filter: changeDisplayYear,
    type: FilterTypes.dropdown,
    name: 'year',
    fullWidth: true,
    showCheckbox: false,
    onOptionsFilter: null,
    someOptionSelected: true,
    hideSearch: true,
  };

  return (
    <Formik initialValues={{ year: displayYear }} enableReinitialize>
      <MonthlyReportsLayout
        headers={monthlyReportsHeaders}
        data={data.filter(({ year }) => year === displayYear)}
        grid={monthlyReportsGrid}
        handleAction={handleAction}
        yearsFilter={yearsFilter}
        isLoading={isLoading}
        isError={isError}
        error={error}
        tableStatusProps={{
          noData: data.length === 0,
          noDataDisplay: noResultsState.monthly.noData(),
          noSearchResultsDisplay: noResultsState.monthly.noSearchResults,
          // no filter setting, so no need to handle noSearchResults
        }}
      />
    </Formik>
  );
};

export default MonthlyReports;
