import { useToggle } from '@react-hookz/web';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icons } from '../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Gallery from '../../../../../components/templates/Gallery/controllers/Gallery';
import { ViewTitle } from '../../../../../components/templates/ViewHeader/components';
import { Utils } from '../../../../../constants';
import { cloneDeep } from '../../../../../lib/js';
import { useFooter } from '../../../../../providers/FooterProvider/FooterProvider';
import { useHeader } from '../../../../../providers/HeaderProvider/HeaderProvider';
import { useModal } from '../../../../../providers/ModalProvider';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { GalleryProps } from '../../../constants';
import { NavURIs, Pages } from '../../../routes/variables';
import { useGetInstruction } from '../api/getInstruction';
import { useGetProductCategories } from '../api/getProductCategories';
import { useRemoveLink } from '../api/removeLink';
import { useToggleVisibility } from '../api/toggleVisibility';
import { useUpdateInstruction } from '../api/updateInstruction';
import { InstructionLayout } from '../components/InstructionLayout';
import CharacteristicEdit from './CharacteristicEdit';
import CharacteristicTypes from './CharacteristicTypes';
import LinkUpload from './LinkUpload';
import { withStateVars } from './utils';
import {
  authenticityBadgeColors,
  authenticityBadgeIcons,
  characteristicInfoIfonDescription,
  descriptionFields,
  descriptionNames,
  formatInitialValues,
  formatUpdateValues,
  instructionTypeLabels,
  knowledgeBaseKeys,
  linkUploadNames,
} from './variables';

const {
  SELF,
  VIEW_KNOWLEDGE_BASE,
  // EDIT_KNOWLEDGE_BASE,
  // EDIT_KNOWLEDGE_BASE_CHARACTERISTICS,
  // EDIT_KNOWLEDGE_BASE_ATTACHMENTS,
  // DELETE_KNOWLEDGE_BASE_ATTACHMENTS,
  // EDIT_KNOWLEDGE_BASE_LINKS,
  // DELETE_KNOWLEDGE_BASE_LINKS,
} = PermissionKeys.Admin.OFFICE_KNOWLEDGE_BASE;

const Instruction = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    [descriptionNames.ruleName]: '',
    [descriptionNames.productCategory]: {
      value: '',
      label: '',
    },
    [descriptionNames.description]: '',
  });
  const [visibility, toggleVisibility] = useToggle(false);
  const [ruleName, setRuleName] = useState('');
  const [links, setLinks] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { instructionId } = useParams();
  const { showSnackbarError, showSnackbarSuccess, showSnackbarWarning } =
    useSnackbar();
  const { showModal, dismiss, dismissAll } = useModal();
  const { setHeaderChildren } = useHeader();
  const { setShowFooter } = useFooter();
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  const { data, isLoading, isError, error, getInstruction } = useGetInstruction(
    {
      instructionId,
      ignore: !hasPermission(VIEW_KNOWLEDGE_BASE),
    }
  );

  const {
    body: updateBody,
    isLoading: updateIsLoading,
    isError: updateIsError,
    error: updateError,
    doUpdateInstruction,
  } = useUpdateInstruction({
    instructionId,
  });

  const {
    body: visibilityBody,
    isLoading: visibilityIsLoading,
    isError: visibilityIsError,
    error: visibilityError,
    doToggleVisibility,
  } = useToggleVisibility({ id: instructionId });

  const {
    data: { categories },
  } = useGetProductCategories();

  const {
    data: deleteLinkData,
    isLoading: deleteLinkIsLoading,
    isError: deleteLinkIsError,
    error: deleteLinkError,
    doRemoveLink,
  } = useRemoveLink();

  useEffect(() => {
    const headerChildren = (
      <ViewTitle text={ruleName} backPage={NavURIs[Pages.knowledge_base]} />
    );

    setHeaderChildren(headerChildren);
  }, [ruleName]);

  useEffect(() => {
    setShowFooter(false);
  }, []);

  useEffect(() => {
    toggleVisibility(!!data[knowledgeBaseKeys.visibility]);
  }, [data[knowledgeBaseKeys.visibility]]);

  useLayoutEffect(() => {
    if (isError) {
      showSnackbarError(error);
      onErrorGoBack();
      return;
    }
    setInitialValues(data.initialValues);
    setRuleName(data[knowledgeBaseKeys.name]);
    setLinks(data[knowledgeBaseKeys.links]);
    setCharacteristics(data[knowledgeBaseKeys.characteristics]);
  }, [data, isError]);

  useEffect(() => {
    if (visibilityBody == null || visibilityIsLoading) return;
    if (visibilityIsError) {
      showSnackbarError(visibilityError);
      return;
    }
    toggleVisibility();
  }, [visibilityBody, visibilityIsLoading, visibilityIsError]);

  useEffect(() => {
    if (updateIsLoading || updateBody == null) return;
    if (updateIsError) {
      showSnackbarError(updateError);
      return;
    }
    const newValues = formatInitialValues(updateBody);
    setInitialValues(newValues);
    setRuleName(updateBody[descriptionNames.ruleName]);
    setIsEditing(false);
    showSnackbarSuccess();
  }, [updateBody, updateIsLoading, updateIsError]);

  useEffect(() => {
    if (deleteLinkIsLoading || deleteLinkData == null) return;
    if (deleteLinkIsError) {
      showSnackbarError(deleteLinkError);
      return;
    }

    cleanupRemoveLink(deleteLinkData.link_id);
  }, [deleteLinkData, deleteLinkIsLoading, deleteLinkIsError]);

  // how can we extract this to share with all files?
  // reused in  referenceDetails
  const onErrorGoBack = () => {
    const { searchParams, from } = location.state ?? {};
    const hasPreviousState = location.key !== 'default';

    const to = from
      ? from
      : hasPreviousState
      ? -1
      : NavURIs[Pages.knowledge_base];
    const state = { searchParams };

    navigate(to, { state });
  };

  const toggleEditing = boolean => () => setIsEditing(x => boolean ?? !x);

  const updateInstruction = values => () =>
    // hasPermission(EDIT_KNOWLEDGE_BASE)
    true
      ? doUpdateInstruction(formatUpdateValues(values))
      : Utils.emptyFunction;

  const handleToggleVisibility = () => {
    if (visibilityIsLoading) return;
    const body = {
      is_visible: !visibility,
    };
    doToggleVisibility(body);
  };

  const showAddLinks = e => {
    // if (!hasPermission(EDIT_KNOWLEDGE_BASE_LINKS)) return;
    showModal(LinkUpload, {
      instructionId,
      onUploadSuccess: onLinkUploadSuccess,
      e,
    });
  };

  const onLinkUploadSuccess = urls => {
    const newUrls = urls.map(({ link_id, url }) => ({
      [knowledgeBaseKeys.link]: url,
      [knowledgeBaseKeys.linkId]: link_id,
    }));
    setLinks(x => [...newUrls, ...x]);
  };

  const onLinkUpdateSuccess = ({ data, body }) => {
    const { url } = body;
    const { link_id: linkId } = data;
    const updatedLink = {
      [knowledgeBaseKeys.link]: url,
      [knowledgeBaseKeys.linkId]: linkId,
    };
    const functonalUpdate = prevLinks => {
      const links = cloneDeep(prevLinks);
      const linkIndex = links.findIndex(
        link => link[knowledgeBaseKeys.linkId] === linkId
      );

      if (linkIndex === -1) return links;

      links[linkIndex] = updatedLink;
      return links;
    };

    setLinks(functonalUpdate);
  };

  const cleanupRemoveLink = linkId => {
    const functonalUpdate = prevLinks => {
      const links = cloneDeep(prevLinks);
      const linkIndex = links.findIndex(
        link => link[knowledgeBaseKeys.linkId] === linkId
      );

      if (linkIndex === -1) return links;

      const link = links.splice(linkIndex, 1)[0];
      return links;
    };

    setLinks(functonalUpdate);
  };

  const showEditLink = link => e => {
    // if (!hasPermission(EDIT_KNOWLEDGE_BASE_LINKS)) return;
    const showIt = () => {
      showModal(LinkUpload, {
        instructionId,
        onUploadSuccess: onLinkUpdateSuccess,
        e,
        type: 'edit',
        linkId: link[knowledgeBaseKeys.linkId],
        prevLinkValues: {
          [linkUploadNames.urls]: link[knowledgeBaseKeys.link],
        },
        element: true && (
          // element: hasPermission(DELETE_KNOWLEDGE_BASE_LINKS) && (
          <AnimatedIcon
            name={Icons.Trash2}
            theme="neutral"
            onClick={showConfirmDeleteLink(link)}
          />
        ),
      });
    };
    // let runDismiss() in Modal.js run first, then show new modal
    setTimeout(showIt, 0);
  };

  const showConfirmDeleteLink = link => () => {
    // if (!hasPermission(DELETE_KNOWLEDGE_BASE_LINKS)) return;
    const onConfirm = _ => {
      deleteLink(link);
      dismissAll();
    };

    const showIt = () =>
      showModal(
        ConfirmModal,
        {
          title: 'Delete link',
          description:
            parseStringModifiers(`Are you sure you want to delete this link?
        
        **This cannot be undone.**`),
          confirm: 'Delete',
          onConfirm,
          onCancel: dismiss,
        },
        { stack: true }
      );
    // let runDismiss() in Modal.js run first, then show new modal
    setTimeout(showIt, 0);
  };

  const deleteLink = link => {
    // if (!hasPermission(DELETE_KNOWLEDGE_BASE_LINKS)) return;
    const linkId = link[knowledgeBaseKeys.linkId];
    const url = `office_knowledge_base/${instructionId}/link/${linkId}`;
    doRemoveLink({ url });
  };

  const onCharacteristicUploadSuccess = characteristic => getInstruction();
  // backend doesn't send item ids back upon creation, so we're unable to remove unless we refresh.
  // backend must guarantee item ids returned come in order, or we must generate them client side
  // setCharacteristics(x => [...x, characteristic]);

  const showAddCharacteristic = () =>
    showModal(CharacteristicTypes, {
      instructionId,
      onSuccess: onCharacteristicUploadSuccess,
    });

  const showCharacteristicGallery =
    ({
      [knowledgeBaseKeys.characteristicId]: characteristicId,
      [knowledgeBaseKeys.characteristicType]: type,
    }) =>
    (_, index) =>
    () =>
      showModal(Gallery, {
        ...GalleryProps.knowledge_base({ characteristicId, instructionId }),
        initialSelected: index,
        title: instructionTypeLabels[type],
      });

  const showEditCharacteristic = characteristic => () => {
    showModal(CharacteristicEdit, {
      instructionId,
      characteristicId: characteristic[knowledgeBaseKeys.characteristicId],
      characteristicType: characteristic[knowledgeBaseKeys.characteristicType],
      //, TODO:, assign, mask, for, items,
      items: characteristic.items,
      onSuccess: editSuccess,
      onDeleteSuccess,
    });
  };

  const onDeleteSuccess = characteristicId => {
    const charIndex = characteristics.findIndex(
      char => char[knowledgeBaseKeys.characteristicId] === characteristicId
    );
    if (charIndex === -1) getInstruction();
    else
      setCharacteristics(x =>
        x.slice(0, charIndex).concat(x.slice(charIndex + 1))
      );
  };

  const editSuccess = () => {
    dismissAll();
    getInstruction();
  };

  const disableIfUnpermitted = (fields, allowed) =>
    fields.map(f => ({ ...f, disabled: !allowed }));

  return (
    <InstructionLayout
      instructionId={instructionId}
      username={data[knowledgeBaseKeys.addedByName]}
      image={data[knowledgeBaseKeys.addedByImage]}
      date={data[knowledgeBaseKeys.date]}
      customer={data[knowledgeBaseKeys.customer.self]}
      visibility={visibility}
      toggleVisibility={handleToggleVisibility}
      attachments={data[knowledgeBaseKeys.attachments]}
      links={links}
      fields={disableIfUnpermitted(
        withStateVars(descriptionFields, categories),
        // hasPermission(EDIT_KNOWLEDGE_BASE)
        true
      )}
      initialValues={initialValues}
      isEditing={isEditing}
      toggleEditing={
        true
          ? // hasPermission(EDIT_KNOWLEDGE_BASE)
            toggleEditing
          : () => Utils.emptyFunction
      }
      allowInstructionEditing={true}
      // allowInstructionEditing={hasPermission(EDIT_KNOWLEDGE_BASE)}
      updateInstruction={updateInstruction}
      updateIsLoading={updateIsLoading}
      isLoading={isLoading}
      showAddLinks={showAddLinks}
      editLink={deleteLinkIsLoading ? Utils.emptyFunction : showEditLink}
      // characteristics
      showAddCharacteristic={showAddCharacteristic}
      characteristics={characteristics}
      authenticityBadgeColors={authenticityBadgeColors}
      authenticityBadgeIcons={authenticityBadgeIcons}
      instructionTypeLabels={instructionTypeLabels}
      infoIconDescription={characteristicInfoIfonDescription}
      showCharacteristicGallery={showCharacteristicGallery}
      showEditCharacteristic={showEditCharacteristic}
      hasCharacteristicEditPermission={true}
      // hasCharacteristicEditPermission={hasPermission(
      //   EDIT_KNOWLEDGE_BASE_CHARACTERISTICS
      // )}
      hasAttachmentEditPermission={true}
      // hasAttachmentEditPermission={hasPermission(
      //   EDIT_KNOWLEDGE_BASE_ATTACHMENTS
      // )}
      hasAttachmentDeletePermission={true}
      // hasAttachmentDeletePermission={hasPermission(
      //   DELETE_KNOWLEDGE_BASE_ATTACHMENTS
      // )}
      hasLinkEditPermission={true}
      // hasLinkEditPermission={hasPermission(EDIT_KNOWLEDGE_BASE_LINKS)}
    />
  );
};

export default Instruction;
