import { useEffect, useState } from 'react';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import {
  DateTypes,
  formatDate,
  humanReadableDate,
} from '../../../../../utils/timeUtils';
import { DC } from '../../../features_public/support/controllers/variables';
import { useGetUsersInolved } from '../api/getUsersInvolved';
import { useUpdateTicketBadge } from '../api/updateTicketBadge';
import { TicketConversationDetailsLayout } from '../components/TicketConversationDetailsLayout';
import { TicketConstants, staticOptions } from './variables';

const TicketConversationDetails = ({ ticket, ticketId, hasEditPermission }) => {
  const { dateCreated, ticketCreator, alias } = ticket;
  const [ticketBadges, setTicketBadges] = useState({
    [DC.STATUS]: ticket.status,
    [DC.PRIORITY]: ticket.priority,
  });

  const { showSnackbarError } = useSnackbar();

  const { data: usersInvolved } = useGetUsersInolved({ ticketId });

  const { body, isLoading, isError, error, updateTicketBadge } =
    useUpdateTicketBadge();

  useEffect(() => {
    setTicketBadges({
      [DC.STATUS]: ticket.status,
      [DC.PRIORITY]: ticket.priority,
    });
  }, [ticket]);

  useEffect(() => {
    if (body == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    const { type } = body;
    setTicketBadges(x => {
      return { ...x, [type]: body[type.toLowerCase()] };
    });
  }, [body, isLoading, isError]);

  const selectOption = (type, value) => () => {
    const runSelect = badges => {
      if (!type || !value || isLoading) return badges;
      if (badges[type] === value) return badges;
      const url = `office_support_ticket/${ticketId}/${type.toLowerCase()}`;
      const body = {
        [type.toLowerCase()]: value,
        type,
      };
      updateTicketBadge(body, url);
      return badges;
    };
    setTicketBadges(runSelect);
  };

  const generateOptions = type => {
    const options =
      staticOptions[DC.FILTERS].find(({ section }) => type === section)
        ?.options ?? [];

    return options.map(option => {
      const value = option.value;
      return {
        text: option.label,
        onClick: selectOption(type, value),
        selected: ticketBadges[type] === value,
      };
    });
  };

  const generateBadge = type => ({
    options: generateOptions(type),
    text: ticketBadges[type],
    title: TicketConstants.Titles.ticketBadges[type],
  });

  const formattedDateCreated = `${humanReadableDate(dateCreated) ?? ''} - 
  ${formatDate(dateCreated, DateTypes.time12) ?? ''}`;

  return (
    <TicketConversationDetailsLayout
      title={<CopyableText text={`Ticket ${alias ?? ''}`} copyText={alias} />}
      dateCreated={formattedDateCreated}
      ticketCreator={{ name: ticket.userName, image: ticket.userImage }}
      statusBadge={generateBadge(DC.STATUS)}
      priorityBadge={generateBadge(DC.PRIORITY)}
      usersInvolved={usersInvolved}
      type={ticket.type}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default TicketConversationDetails;
