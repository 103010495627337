import { Icon, Text } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import {
  Icons,
  Illustrations,
} from '../../../../../../components/atoms/Icon/Icon.options';
import Button from '../../../../../../components/molecules/Button/Button';
import { Colors, FontKeys, Size } from '../../../../../../components/style';
import { Utils } from '../../../../../../constants';
import { processingKeys } from '../../../../features/processing/controllers/variables';

export const ConfirmationButtons = ({
  details,
  updatedConfirm,
  confirmationAction = Utils.emptyFunction,
}) => {
  return (
    <Flex gap="_S">
      {(details?.[processingKeys.situation.descriptionButtons] ?? []).map(
        (button, index) => {
          const { actionKey, descText, ...warningSafeButton } = button;
          return (
            <Flex gap="_XS" column w0 grow key={index}>
              <Button
                {...warningSafeButton}
                size="_M"
                onClick={confirmationAction(button.actionKey, { index })}
              />
              <Flex align="start" gap="_2XS">
                {button.descText && button.descText.length && (
                  <Icon
                    name={Icons.Info}
                    color={Colors.Neutral._500}
                    size={Size.Icon._S}
                  />
                )}
                <LastConfirmed
                  text={updatedConfirm[index] ?? button.descText}
                />
              </Flex>
            </Flex>
          );
        }
      )}
    </Flex>
  );
};

export const Illustration = ({ instructionAssets, statusStyle }) => {
  return (
    <Flex gap="_XS" align="center" column>
      <Flex grow justify="center">
        <Icon
          name={
            instructionAssets[statusStyle]?.[
              processingKeys.situation.illustration
            ] ?? Illustrations.Processing_Unknown
          }
        />
      </Flex>
    </Flex>
  );
};

export const Title = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H2}
      weight={FontKeys.Weight.Semibold}
      color={Colors.Primary._600}
    />
  );
};

export const Subtitle = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Heading}
      size={FontKeys.Size.H5}
      weight={FontKeys.Weight.Regular}
    />
  );
};

export const LastConfirmed = ({ text }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Caption}
      size={FontKeys.Size._M}
      weight={FontKeys.Weight.Regular}
      color={Colors.Neutral._500}
    />
  );
};
