import { ActingBrandSelection } from '../../../../../components/organisms/ActingBrandSelection/ActingBrandSelection';
import Actions from '../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../components/templates/Popup';
import {
  MoreDetails,
  PriorityOptions,
} from '../../../features_public/support/components/organisms';
import { HelpContent } from '../style';
import { HelpType } from './organisms/organisms';

export const HelpLayout = ({
  actingBrandProps,
  selectHelpType,
  chosenHelptype,
  helpTypeTitle,
  options,
  helpTypeTSubtitle,
  selectPriorityTitle,
  priorityOptions,
  selectPriority,
  chosenPriority,
  moreDetailsTitle,
  moreDetailsPlaceholder,
  detailsChangeHandler,
  onBack,
  cancel,
  cancelText,
  createTicket,
  createTicketText,
  disabled,
  isLoading,
}) => {
  return (
    <Popup size="_M" title="Help request">
      <HelpContent>
        <ActingBrandSelection {...actingBrandProps} />
        <HelpType
          selectHelpType={selectHelpType}
          chosenHelptype={chosenHelptype}
          title={helpTypeTitle}
          subtitle={helpTypeTSubtitle}
          options={options}
        />
        <PriorityOptions
          title={selectPriorityTitle}
          options={priorityOptions}
          selectPriority={selectPriority}
          chosenPriority={chosenPriority}
        />
        <MoreDetails
          title={moreDetailsTitle}
          placeholder={moreDetailsPlaceholder}
          detailsChangeHandler={detailsChangeHandler}
        />
        <Actions
          onCancel={cancel}
          cancelText={cancelText}
          onPrimary={createTicket}
          primaryText={createTicketText}
          disabled={disabled}
          isLoading={isLoading}
          onBack={onBack}
        />
      </HelpContent>
    </Popup>
  );
};
