import usePostData from '../../../../../hooks/postHook';

export const useRemoveBarcodes = ({ id }) => {
  const [{ body, isLoading, isError, error }, doRemoveBarcodes] = usePostData(
    `office_parcel/${id}/barcodes/remove`,
    {}
  );

  return {
    body,
    isLoading,
    isError,
    error,
    doRemoveBarcodes,
  };
};
