import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import { Table } from '../../../../../../components/organisms/Table/Table';
import { TableHeader } from '../../../../../../components/organisms/Table/Table.exports';
import { Layout } from '../../../../../../components/style';
import { CaseProductsTableHeaderText } from '../../../../features/cases/components/atoms';
import { productKeys } from '../../../products/controllers/variables';
import { caseKeys } from '../../controllers/variables';
import { caseProductsTableComponents } from '../molecules';
import {
  CaseProductsCSS,
  CaseProductsTableHeaderContainer,
  CaseProductsTableHeaderCSS,
} from '../style';

const ownProducts = products =>
  products.filter(p => p[productKeys.isProductOwner]);

const otherBrandProducts = products =>
  products?.filter(p => !p[productKeys.isProductOwner]);

export const CaseProducts =
  ({ headers, grid, rowAction, hideSpacer = false }) =>
  item => {
    if (item == null) return <></>;
    const products = item?.[caseKeys.products] ?? [];
    const clientOwnProducts = ownProducts(products);
    const otherClientProducts = otherBrandProducts(products);
    // assuming that all products are on the same parcel, thus same sorter, thus same isSorterCase value
    const disableOtherProducts =
      !otherClientProducts[0]?.[productKeys.isSorterCase];

    return (
      <Flex
        w
        h
        padding={`0 ${Layout.Spacing._S}`}
        column
        className={'case-products'}
      >
        {clientOwnProducts?.length
          ? CaseProductsTable({
              headers,
              data: clientOwnProducts,
              grid,
              rowAction,
              own: true,
              brandName: ownProducts(products)[0]?.brand,
            })
          : null}
        {CaseProductsTable({
          headers,
          data: otherClientProducts,
          grid,
          disabled: disableOtherProducts,
          rowAction: !disableOtherProducts ? rowAction : undefined,
        })}
        {hideSpacer ? '' : <Spacer w height="_S" />}
      </Flex>
    );
  };

const CaseProductsTableHeader = ({ own, brandName, grid, headers }) => {
  return (
    <CaseProductsTableHeaderContainer className={'case-products-table-header'}>
      <CaseProductTitle own={own} brandName={brandName} />
      <TableHeader grid={grid} headers={headers} />
    </CaseProductsTableHeaderContainer>
  );
};

const CaseProductTitle = ({ own, brandName }) => (
  <CaseProductsTableHeaderCSS own={own}>
    <CaseProductsTableHeaderText
      text={`${
        own
          ? brandName
            ? brandName[brandName.length - 1].toLowerCase() === 's'
              ? brandName.toUpperCase() + "'"
              : brandName.toUpperCase() + "'S"
            : "YOUR BRAND'S"
          : "OTHER BRANDS'"
      } PRODUCTS`}
      own={own}
    />
  </CaseProductsTableHeaderCSS>
);

export const CaseProductsTable = ({
  headers,
  data,
  rowAction,
  grid,
  own,
  brandName,
  disabled,
}) => {
  return (
    <CaseProductsCSS className={'case-products-table'}>
      <CaseProductsTableHeader
        own={own}
        brandName={brandName}
        headers={headers}
        grid={grid}
      />
      <Table
        headers={headers}
        data={data}
        grid={grid}
        rowAction={rowAction}
        components={caseProductsTableComponents(disabled)}
      />
    </CaseProductsCSS>
  );
};
