import { Icon } from '../../atoms';
import { Size } from '../../style';
import { Container } from './FilterButton.style';

export const FilterButton = ({ onClick, icon, disabled, name }) => {
  return (
    <Container
      onClick={onClick}
      disabled={disabled}
      className={'filter-button' + (name ? ' ' + name : '')}
    >
      <Icon name={icon} size={Size.Icon._S} />
    </Container>
  );
};
