import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import CopyableText from '../../../../../components/molecules/CopyableText/CopyableTitle';
import Modal from '../../../../../components/templates/Modal/Modal';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { useGetAuthority } from '../api/getAuthority';
import { useUpdateAuthority } from '../api/updateAuthority';
import { useAddAuthority } from '../api/useAddAuthority';
import { EditAuthorityLayout } from '../components/pages/EditAuthorityLayout';
import { authorityInitialValues, authorityValidationSchema } from './variables';

const EditAuthority = ({
  authorityId,
  parcelId,
  productAlias,
  onSuccess,
  dismiss,
}) => {
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const {
    data: authorities,
    isError: authoritiesIsError,
    error: authoritiesError,
  } = useGetAuthority();

  const {
    body: authorityAdd,
    isLoading: authorityAddIsLoading,
    isError: authorityAddIsError,
    error: authorityAddError,
    doAuthorityAdd,
  } = useAddAuthority({ id: parcelId });

  const { body, isLoading, isError, error, doAuthorityUpdate } =
    useUpdateAuthority({
      id: parcelId,
    });

  useEffect(() => {
    if (authorityAdd == null || authorityAddIsLoading) return;
    if (authorityAddIsError) {
      showSnackbarError(authorityAddError);
      return;
    }
    showSnackbarSuccess();
    dismiss();
    if (typeof onSuccess === 'function') onSuccess();
  }, [authorityAdd, authorityAddIsLoading, authorityAddIsError]);

  useEffect(() => {
    if (body == null || isLoading) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    dismiss();
    if (typeof onSuccess === 'function') onSuccess();
  }, [body, isLoading, isError]);

  useEffect(() => {
    if (authoritiesIsError) {
      showSnackbarError(authoritiesError);
      dismiss();
    }
  }, [authoritiesIsError]);

  const onSubmit = values => {
    const body = { authority_id: values.value };
    const request = authorityId == null ? doAuthorityAdd : doAuthorityUpdate;
    request(body);
  };

  const initialValues = authorities.find(({ value }) => value === authorityId);

  return (
    <Formik
      initialValues={initialValues ?? authorityInitialValues}
      validationSchema={authorityValidationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => {
        const selectOption = authority => _ => {
          Object.entries(authority).forEach(([name, value]) => {
            setFieldValue(name, value);
          });
          setFieldValue('dropdown', authority);
        };

        return (
          <Form>
            <EditAuthorityLayout
              title={
                <CopyableText
                  text={`Product ${productAlias ?? ''}`}
                  copyText={productAlias}
                />
              }
              onCancel={dismiss}
              isValid={values?.value && values.value !== authorityId}
              isLoading={isLoading}
              authorities={authorities}
              selectOption={selectOption}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(EditAuthority);
