const { Icon } = require('../../atoms');
const { Icons } = require('../../atoms/Icon/Icon.options');
const { Colors } = require('../../style');

export const EyeIcon = ({ revealPassword, toggleRevealPassword }) => {
  return (
    <div onClick={toggleRevealPassword}>
      <Icon
        name={revealPassword ? Icons.EyeOff : Icons.Eye}
        color={revealPassword ? Colors.Primary._400 : Colors.Neutral._500}
      />
    </div>
  );
};
