import { useToggle } from '@react-hookz/web';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/templates/Modal/Modal';
import { Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { prefillDataForEditing } from '../../../../../utils/utils';
import { dropdownValidation } from '../../../../../utils/validationUtils';
import { useAddSubcategory } from '../api/addSubcategory';
import { AddSubcategoryLayout } from '../components/organisms/AddSubcategoryLayout';
import { adminCategoriesKeys as keys } from './variables';

const fieldNames = {
  category: 'category',
  subCategory: 'name',
};

const fields = [
  {
    name: fieldNames.category,
    label: 'Category',
    placeholder: 'Enter a category name',
    size: '_M',
    type: 'select',
  },
  {
    name: fieldNames.subCategory,
    label: 'Subcategory',
    placeholder: 'Enter a subcategory name',
    size: '_M',
  },
];

const initialValues = {};
fields.forEach(field => (initialValues[field.name] = ''));

const validationSchema = Yup.object().shape({
  [fieldNames.category]: dropdownValidation,
  [fieldNames.subCategory]: Yup.string().required('Required'),
});

const mapFieldsToResponse = {
  [fieldNames.category]: keys.category.id,
  [fieldNames.subCategory]: keys.subCategory.name,
};

const AddSubcategory = ({ mode = 'add', prevData, categories, onSuccess }) => {
  const isEditing = mode === 'edit';
  const [_, toggle] = useToggle(false);

  const { dismiss, dismissAll } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const { body, isLoading, isError, error, doAddSubcategory } =
    useAddSubcategory({
      mode,
    });

  useEffect(() => {
    if (isLoading || body == null) return;
    if (isError) {
      showSnackbarError(error);
      return;
    }
    showSnackbarSuccess();
    dismissAll();
    onSuccess();
  }, [isLoading, body, isError]);

  useEffect(() => {
    if (categories == null) return;
    fields.find(field => field.name === fieldNames.category).options =
      categories;
    toggle();
  }, [categories]);

  useEffect(() => {
    fields.find(field => field.name === fieldNames.category).disabled =
      isEditing;
  }, []);

  const onSubmit = values => {
    const url = isEditing
      ? `admin_category/${values[fieldNames.category].value}/sub_category/${
          prevData[keys.subCategory.id]
        }`
      : `admin_category/${values[fieldNames.category].value}/sub_categories`;

    doAddSubcategory(values, url);
  };

  const withPrevData = initialValues => {
    if (isEditing) {
      initialValues = prefillDataForEditing({
        initialValues,
        prevData,
        mapFieldsToResponse,
        fields,
      });

      initialValues[fieldNames.category] = {
        label: prevData[keys.category.name],
        value: prevData[keys.category.id],
      };
    }
    return initialValues;
  };
  return (
    <Formik
      initialValues={withPrevData(initialValues)}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, dirty }) => {
        const selectOption =
          ({ name, value, label }) =>
          _ =>
            setFieldValue(name, { value, label });
        return (
          <Form>
            <AddSubcategoryLayout
              isEditing={isEditing}
              fieldProps={{
                fields,
                selectOption,
              }}
              actionsProps={{
                onBack: !isEditing ? dismiss : Utils.emptyFunction,
                onCancel: () => dismissAll(),
                primaryText: isEditing ? 'Save' : 'Add',
                disabled: !isValid || !dirty,
                isLoading,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(AddSubcategory);
