import { Flex } from '../../atoms/Flex/Flex';
import Avatar from '../../molecules/Avatar/Avatar';
import { ShiftCSS } from './style';
import { defaultShifts } from './variables';

export const StackedAvatars = ({ avatars, size, shift }) => {
  return (
    <Flex>
      {avatars.map((avatar, index) => (
        <ShiftCSS
          index={index}
          shift={shift ?? defaultShifts[size]}
          key={index}
        >
          <Avatar {...avatar} size={size} variant="Stackable" />
        </ShiftCSS>
      ))}
    </Flex>
  );
};
