import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { knowledgeBaseKeys } from '../controllers/variables';

export const useGetProductCategories = () => {
  const [
    {
      data: { categories = [], category_count: categoryCount = 0 } = {},
      isLoading,
      isError,
      error,
    },
  ] = useFetchData('categories', {});

  return {
    data: {
      categories: formatter(categories, mask),
      categoryCount,
    },
    isLoading,
    isError,
    error,
  };
};

export const formatter = data => {
  maskEntry(data, mask);
  return data;
};

const mask = {
  product_id: knowledgeBaseKeys.categoryId,
  product_name: knowledgeBaseKeys.productCategory,
};
