import { PhotoQuantityValidator, ProductPhotosGallery } from '../organisms';
import { ProductPhotosSelectContainer } from '../style';

export const ProductPhotos = ({
  values,
  handleAddImages,
  deleteFileFromMemory,
  deleteFileFromDb,
  showGallery,
  accesorySlotEnd,
}) => {
  const { dbImages, memoryImages, valid, isContracted } = values ?? {};
  return (
    <ProductPhotosSelectContainer>
      <PhotoQuantityValidator
        valid={valid}
        isContracted={isContracted}
        accesorySlotEnd={accesorySlotEnd}
      />
      <ProductPhotosGallery
        dbImages={dbImages}
        memoryImages={memoryImages}
        handleAddImages={handleAddImages}
        deleteFileFromMemory={deleteFileFromMemory}
        deleteFileFromDb={deleteFileFromDb}
        showGallery={showGallery}
      />
    </ProductPhotosSelectContainer>
  );
};
