import { PropKeys } from '../../constants';
import { API } from '../../lib/api';

export const request2FAcode = async ({
  email,
  password,
  authType: auth_type,
}) => {
  const body = {
    email,
    password,
    auth_type,
  };
  const { data, status, error } = await API.POST('request_2fa', body);

  if (error || status !== 200) return false;
  const { user_id, auth_code } = data;

  return { [PropKeys.userId]: user_id, [PropKeys.authCode]: auth_code };
};
