import { extractSelectedValues } from '../../../../../components/organisms/Filters/utils';
import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import { DC } from '../../../features_public/support/controllers/variables';
import { formatTicket } from './getTicket';

export const useGetTickets = ({ searchParams, ignore }) => {
  const params = formatParams(searchParams);

  const [
    {
      data: {
        support_tickets: tickets = [],
        support_ticket_count: ticketCount = 0,
      } = {},
      isLoading = true,
      isError,
      error,
    },
    _,
    refreshTickets,
  ] = useFetchData('support_tickets', {
    params,
    ignore,
    large: true,
  });

  return {
    data: { tickets: formatTickets(tickets), ticketCount },
    isLoading,
    isError,
    error,
    refreshTickets,
  };
};

const formatParams = params => {
  const {
    TIME_FRAME,
    SEARCH: search,
    PAGE,
    ITEMS_PER_PAGE,
    FILTERS,
    SORTERS,
    BRANDS,
  } = params;
  const from_date = formatQueryDate(TIME_FRAME.value.fromDate);
  const to_date = formatQueryDate(TIME_FRAME.value.toDate);

  const { priority, status, type } = extractFilterValues(FILTERS);

  const { sort_by, order_by } = extractSorterValues(SORTERS);

  const brand_id = extractSelectedValues(BRANDS);

  const formattedParams = {
    search,
    from_date,
    to_date,
    brand_id,
    priority,
    status,
    type,
    offset: (PAGE - 1) * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
    sort_by,
    order_by,
  };

  return formattedParams;
};

export const formatTickets = data => {
  if (data == null) return [];
  return data.map(ticket => formatTicket(ticket));
};

export const extractFilterValues = filters => {
  const all = getSection(filters, DC.ALL)?.options[0].selected;
  if (all) return {};
  const error = getSection(filters, DC.ERROR);
  if (error)
    return {
      priority: [],
      status: [],
      type: [],
    };

  const priority = extractSelectedValues([getSection(filters, DC.PRIORITY)]);
  const status = extractSelectedValues([getSection(filters, DC.STATUS)]);
  const type = extractSelectedValues([getSection(filters, DC.TICKET_TYPE)]);

  return { priority, status, type };
};

const getSection = (params, section) => {
  return params.find(s => s.section === section);
};

export const extractSorterValues = sorters => {
  const error = getSection(sorters, DC.ERROR);
  if (error) return { sort_by: [], order_by: [] };

  const sort_by = extractSelectedValues([getSection(sorters, DC.SORT)])[0];
  const order_by = extractSelectedValues([getSection(sorters, DC.ORDER)])[0];

  return { sort_by, order_by };
};
