import useFetchData from '../../../../../hooks/fetchHook';
import { formatQueryDate } from '../../../../../utils/timeUtils';
import {
  formatPerformance,
  inactivePerformance,
} from '../formatters/formatPerformance';

const defaultLineData = {
  keys: [],
  lines: [],
  data: [],
};

const defaultData = {
  overview: [],
  detectionDetails: [],
  parcelCount: defaultLineData,
  requestsSeconds: defaultLineData,
  processingTime: defaultLineData,
  counterfeits: defaultLineData,
  returns: defaultLineData,
  lq: defaultLineData,
  depot: defaultLineData,
  shippingLabels: defaultLineData,
  dangerousGoods: defaultLineData,
};

export const useGetPerformance = ({ searchParams }) => {
  const [{ data = defaultData, isLoading, isError, error }, _, __, refresh] =
    useFetchData('dashboard/performance', {
      formatter: formatPerformance,
      params: formatParams(searchParams),
      failureFormatter: _ => inactivePerformance(),
    });

  return {
    data,
    disabledData: inactivePerformance(),
    isLoading,
    isError,
    error,
    refresh,
  };
};

const formatParams = params => {
  const { TIME_FRAME } = params;

  const from_date = formatQueryDate(TIME_FRAME?.value?.fromDate);
  const to_date = formatQueryDate(TIME_FRAME?.value?.toDate);

  const formattedParams = {
    from_date,
    to_date,
  };

  return formattedParams;
};
