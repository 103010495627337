import { useRef, useState } from 'react';
import { screenPosition } from '../../../utils/utils';
import { PageIndicator } from '../../atoms/PageIndicator/PageIndicator';
import { Colors } from '../../style';
import { Container, MenuOption } from './InViewMenu.style';

const InViewMenu = ({ options, selectedView, selectView }) => {
  const [top, setTop] = useState();

  const containerRef = useRef(null);

  const moveIndicator = it => {
    const container = containerRef.current;
    const { y: containerTop } = screenPosition({ currentTarget: container });
    const { y: top } = screenPosition({ currentTarget: it }) ?? {};
    setTop(top - containerTop);
  };

  return (
    <Container ref={containerRef} className="in-view-menu">
      {options.map(option => (
        <MenuOption
          option={option}
          selected={selectedView === option.view}
          handleClick={selectView}
          moveIndicator={moveIndicator}
          comingSoon={option.comingSoon}
          key={option.view}
        />
      ))}
      {!!options.length && (
        <PageIndicator top={top} showIndicator color={Colors.Primary._600} />
      )}
    </Container>
  );
};
export default InViewMenu;
