import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Text } from '../../atoms';
import { Colors, Effects, FontKeys, Layout, UtilityColors } from '../../style';

export const Container = styled.div`
  position: relative;
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
  &:hover {
    .menu-options {
      display: ${props =>
        props.disabled || props.readOnly ? 'none' : 'block'};
    }
  }
`;

export const Badge = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: ${props => containerHeight[props.size]};
  background: ${props => (props.disabled ? Colors.Neutral._300 : props.color)};
  padding: 0 ${props => xPadding(props.size)};
  border-radius: ${Effects.Border_Radius._2XL};
  gap: ${Layout.Spacing._2XS};
`;

export const MenuOptionsHoverArea = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding-top: ${Layout.Spacing._2XS};
`;

export const MenuOptionsCSS = styled.div`
  cursor: pointer;
  padding: ${Layout.Spacing._2XS} ${Layout.Spacing._2XS};
  background: ${Colors.Sharp.White};
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn};
`;

export const MenuOptionCSS = styled.div`
  width: 88px;
  height: ${Layout.Spacing._S};
  padding: 0px ${Layout.Spacing._2XS};
  border-radius: ${Effects.Border_Radius._S};
  background: ${props =>
    props.selected ? UtilityColors.Background_App : 'transparent'};
  ${Styles.flex};
`;

export const BadgeMenu = ({ options, selectOption, selectedOption }) => {
  return (
    <MenuOptionsHoverArea className="menu-options">
      <MenuOptionsCSS>
        {options.map(option => {
          const { value } = option;
          return (
            <MenuOption
              key={value}
              option={option}
              selectOption={selectOption}
              selected={selectedOption.value === value}
            />
          );
        })}
      </MenuOptionsCSS>
    </MenuOptionsHoverArea>
  );
};

// TODO: send selected option instead? sending selected feels weird
const MenuOption = ({ option, selected, selectOption }) => {
  return (
    <MenuOptionCSS
      selected={selected}
      onClick={selectOption(option)}
      className="menu-option"
    >
      <OptionTitle bold={selected} text={option.label} />
    </MenuOptionCSS>
  );
};

export const OptionTitle = ({ text, bold }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight[bold ? 'Semibold' : 'Regular']}
      ellipsis
    />
  );
};

export const BadgeText = ({ text, size }) => {
  return (
    <Text
      text={text}
      {...textSizes(size)}
      color={Colors.Sharp.White}
      whiteSpace={'pre'}
    />
  );
};

const containerHeight = {
  _L: '40px',
  _M: '32px',
  _S: '24px',
  _XS: '20px',
};

export const iconSizeTransform = {
  _L: '_M',
  _M: '_S',
  _S: '_XS',
  _XS: '_XS',
};

const textSizes = size => {
  switch (size) {
    case '_L':
    case '_M':
    case '_S':
      return {
        variant: FontKeys.Variant.Paragraph,
        size: FontKeys.Size[size],
        weight: FontKeys.Weight.Semibold,
      };
    case '_XS': {
      return {
        variant: FontKeys.Variant.Caption,
        size: FontKeys.Size._M,
        weight: FontKeys.Weight.Semibold,
      };
    }
    default:
      throw Error('Incorrect size property');
  }
};

const xPadding = size => {
  switch (size) {
    case '_L':
    case '_M':
      return Layout.Spacing._S;
    case '_S':
    case '_XS':
      return Layout.Spacing._XS;
    default:
      throw Error('Incorrect size property');
  }
};
