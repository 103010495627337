import styled from '@emotion/styled';
import { Colors, Effects, Layout, Size } from '../../../../../components/style';
import { Styles } from '../../../../../constants';
import { DisableChartContainer } from '../../../../../utils/styleUtils';

export const NoResultRowCSS = styled.div`
  width: 100%;
  height: ${Layout.Spacing._L};
  ${Styles.flexCenter};
`;

export const Major = styled.div`
  grid-column: 1 / 9;
  gap: ${Layout.Spacing._S};
  ${Styles.flexColumn};
`;

export const Minor = styled.div`
  grid-column: 9 / 13;
  gap: ${Layout.Spacing._M};
  ${Styles.flexColumn};
  .recent-activity {
    flex-grow: 1;
  }
`;

export const NoResultsCSS = styled.div`
  height: 100%;
  grid-row-start: 2;
  grid-column: 1 / 13;
  ${Styles.flexCenter};
`;

export const Details = styled(Major)`
  gap: 16px;
  height: 100%;
  align-items: start;
  .status-badge {
    margin-top: auto;
    margin-bottom: ${Layout.Spacing._S};
  }
`;

export const DetailCSS = styled.div`
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  gap: ${Layout.Spacing._3XS};
  span {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StatusStepsContainerCSS = styled.div`
  flex-grow: 1;
  .card {
    height: 308px;
    display: flex;
    justify-content: space-between;
    padding: ${Layout.Spacing._S} ${Layout.Spacing._L};
  }
`;

export const StatusStepsS = styled.div`
  ${Styles.flexColumn};
`;

export const StepsCSS = styled.div`
  height: 68px;
  margin-bottom: -48px;
  grid-column: 2 / 8;
  ${Styles.flex}
`;

export const StepCSS = styled.div`
  justify-content: space-between;
  align-items: flex-end;
  &:nth-of-type(2) {
    // 2 out of 5 columns
    width: 40%;
  }
  &:nth-of-type(3) {
    flex-grow: 1;
  }
  ${Styles.flexColumn}
`;

export const StepTextCSS = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: -${Layout.Spacing._S};
  ${Styles.flexCenter}
`;

export const stepStyles = {
  0: {
    textColor: Colors.Primary._600,
    stepSize: Size.StatusStep._S,
    stepColor: Colors.Primary._600,
    stepBackground: Colors.Sharp.White,
    lineColor: 'transparent',
    border: `1.5px solid ${Colors.Primary._600}`,
  },
  1: {
    textColor: Colors.Primary._600,
    stepSize: Size.StatusStep._M,

    stepColor: Colors.Sharp.White,
    stepBackground: Colors.Primary._600,
    lineColor: Colors.Primary._600,
    border: `1.5px solid ${Colors.Primary._600}`,
    left: 'unset',
  },
  2: {
    textColor: Colors.Neutral._400,
    stepSize: Size.StatusStep._S,
    stepColor: Colors.Neutral._400,
    stepBackground: Colors.Sharp.White,
    lineColor: Colors.Neutral._400,
    border: `1.5px solid ${Colors.Neutral._400}`,
    left: 'unset',
  },
};

export const StepDescriptionCSS = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 50%;
  z-index: 1;
  left: ${props => stepStyles[props.index].left};
  border: ${props => stepStyles[props.index].border};
  height: ${props => stepStyles[props.index].stepSize};
  width: ${props => stepStyles[props.index].stepSize};
  background: ${props => stepStyles[props.index].stepBackground};
  ${Styles.flexCenter};
`;

export const ConnectingLineCSS = styled.div`
  width: 100%;
  height: 1px;
  position: relative;
  background-color: ${props => stepStyles[props.index].lineColor};
`;

const iconStyles = {
  _M: {
    width: '120px',
    height: '104px',
    transform: `translate(0,${Layout.Spacing._M} )`,
    svg: {
      width: '120px',
      height: '104px',
    },
  },
  _L: {
    width: '147px',
    height: '128px',
    transform: `translate(-${Layout.Spacing._S},${Layout.Spacing._S} )`,
    svg: {
      width: '147px',
      height: '128px',
    },
  },
};

export const DescriptiveIcon = styled.div`
  position: absolute;
  top: ${Layout.Spacing._XL};
  right: ${Layout.Spacing._M};
  ${props => iconStyles[props.size]}
  ${Styles.flexCenter};
`;

export const StatusDescriptionCSS = styled.div`
  gap: 16px;
  width: calc(100% - 168px);
  flex-grow: 1;
  ${Styles.flexColumn};
`;

export const StatusActionsCSS = styled.div`
  margin-top: auto;
  flex-wrap: wrap;
  gap: ${Layout.Spacing._XS};
  ${Styles.flex};
`;

const riskContentSize = {
  _M: {
    maxWidth: '100%',
  },
  _L: {
    maxWidth: '65%',
  },
};

export const RiskContentCSS = styled.div`
  gap: ${Layout.Spacing._S};
  ${props => riskContentSize[props.size]};
  ${Styles.flexColumn}
`;

export const ReasonDescriptionCSS = styled.div`
  gap: ${Layout.Spacing._2XS};
  ${Styles.flexColumn}
`;

const circleBarPosition = {
  _M: { right: 0, top: Layout.Spacing._2XS },
  _L: { right: Layout.Spacing._M, top: Layout.Spacing._XL },
};

export const RiskAnalysisCircleBarPosition = styled.div`
  position: absolute;
  ${props => circleBarPosition[props.size]};
`;

export const TicketCountContainerCSS = styled.div`
  justify-content: space-between;
  cursor: pointer;
  border-radius: ${Effects.Border_Radius._S};
  box-shadow: ${Effects.Box_Shadow._S};
  padding: ${Layout.Spacing._2XS} ${Layout.Spacing._XS};
  visibility: ${props => (props.display ? 'visible' : 'hidden')};
  ${Styles.flex};
`;

export const TicketCountCSS = styled.div`
  width: 0;
  flex-grow: 1;
  gap: ${Layout.Spacing._XS};
  ${Styles.flex}
`;

export const ActivityEntryCSS = styled.div`
  z-index: 1;
  align-items: center;
  position: relative;
  gap: ${Layout.Spacing._S};
  ${Styles.flex}
`;

export const DisableContainer = DisableChartContainer;
