import { Icon } from '../../../../../components/atoms';
import { Illustrations } from '../../../../../components/atoms/Icon/Icon.options';
import Spacer from '../../../../../components/atoms/Spacer/Spacer';
import Button from '../../../../../components/molecules/Button/Button';
import ToggleText from '../../../../../components/molecules/ToggleText/ToggleText';
import { Filters } from '../../../../../components/organisms/Filters';
import { Colors, Layout } from '../../../../../components/style';
import { ScanningModeTitle } from './atoms';
import { ProcessingContainer, ProcessingToggleCSS } from './style';

const ProcessingLayout = ({
  isProcessing,
  selectScanningMode,
  toggleOptions,
  scanningModeTitle,
  filters,
  searchValue,
  isModalActive,
  hasViewParcelPermission,
  confirmationAction,
  // updatedConfirm,
}) => {
  return (
    <ProcessingContainer>
      <ProcessingToggleCSS>
        <ToggleText
          activeIndex={isProcessing}
          options={toggleOptions}
          select={selectScanningMode}
          indicatorBg={Colors.Sharp.White}
          size="_M"
          padding={Layout.Spacing._3XS}
        />
      </ProcessingToggleCSS>
      <Spacer w height={'_XL'} />
      <ScanningModeTitle text={scanningModeTitle} />
      <Spacer w height={'_M'} />
      {hasViewParcelPermission && (
        <Filters
          // prevent scanning action if modal is active
          filters={isModalActive ? [] : filters}
          searchValue={searchValue}
          // focus on every state change
          autoFocusSearch={Math.random()}
          searchTheme={'full-page'}
        />
      )}
      <Spacer w height={'_XL'} />
      <Icon name={Illustrations.Processing_Illustration} />
      <Spacer w height={'_XL'} />
      <Button
        text="Complete processing"
        // {...warningSafeButton}
        variant="Secondary"
        size="_M"
        onClick={confirmationAction}
      />
      {/* <LastConfirmed text={updatedConfirm ?? ''} /> */}
    </ProcessingContainer>
  );
};
export default ProcessingLayout;
