import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import Modal from '../../../../../components/templates/Modal/Modal';
import { cloneDeep } from '../../../../../lib/js';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import { parseStringModifiers } from '../../../../../utils/utils';
import { useAddCharacteristicItem } from '../api/addCharacteristicItem';
import { useRemoveCharacteristic } from '../api/removeCharacteristic';
import { useRemoveCharacteristicItem } from '../api/removeCharacteristicItem';
import { CharacteristicEditLayout } from '../pages/CharacteristicEditLayout';
import {
  authenticityBadgeColors,
  authenticityBadgeIcons,
  authenticityBadgeMenuOptions,
  authenticityBadgeValues,
  getCharacteristicInitialValues,
  getCharacteristicValidationSchema,
  instructionTypeLabels,
  knowledgeBaseKeys,
} from './variables';

const CharactersticEdit = ({
  instructionId,
  characteristicId,
  characteristicType,
  onSuccess,
  onDeleteSuccess,
  items: prevItems,
}) => {
  const [handleImport, setHandleImport] = useState(null);
  const [types, setTypes] = useState(
    prevItems.map(i => i[knowledgeBaseKeys.authenticityValue])
  );
  const [details, setDetails] = useState(
    prevItems.map(i => i[knowledgeBaseKeys.characteristicDescription])
  );

  const [missingFiles, setMissingFiles] = useState(true);
  const [filesUploading, setFilesUploading] = useState(false);
  const [somethingIsLoading, setSomethingIsLoading] = useState(false);

  const [items, setItems] = useState(cloneDeep(prevItems));
  const [itemsStagedForDeletion, setItemsStagedForDeletion] = useState([]);

  const { dismissAll, showModal, dismiss } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();

  const {
    data: removeCharData,
    body: removeCharBody,
    isLoading: removeCharIsLoading,
    isError: removeCharIsError,
    error: removeCharError,
    doRemoveCharacteristic,
  } = useRemoveCharacteristic();

  const { data, body, isLoading, isError, error, doRemoveCharacteristicItem } =
    useRemoveCharacteristicItem();

  const {
    data: itemData,
    body: itemBody,
    isLoading: itemIsLoading,
    isError: itemIsError,
    error: itemError,
    doAddCharacteristicItem,
  } = useAddCharacteristicItem({});

  useEffect(() => {
    if (isLoading || itemIsLoading || (itemBody == null && body == null))
      return;
    if (isError) {
      showSnackbarError(error);
      dismissAll();
      return;
    }
    if (itemIsError) {
      showSnackbarError(itemError);
      dismissAll();
      return;
    }
    showSnackbarSuccess('Characteristic updated successfully');
    onSuccess();
  }, [isLoading, itemIsLoading, isError, itemIsError]);

  useEffect(() => {
    if (removeCharIsLoading || removeCharData == null) return;
    if (removeCharIsError) {
      showSnackbarError(removeCharError);
      return;
    }
    dismissAll();
    showSnackbarSuccess('Characteristic removed successfully');
    onDeleteSuccess(characteristicId);
  }, [removeCharIsLoading, removeCharData, removeCharIsError]);

  useEffect(() => {
    const somethingIsLoading =
      isLoading || itemIsLoading || filesUploading || removeCharIsLoading;

    setSomethingIsLoading(somethingIsLoading);
  }, [isLoading, itemIsLoading, filesUploading, removeCharIsLoading]);

  const assignHandleImport = childFunction => setHandleImport(childFunction);

  const handleSave = () => {
    handleImport({
      // no url because this method does not come from FileUploadProvider
      folder: `instruction_files`,
      successCallback: fileImportSuccess,
      errorCallback: () =>
        showSnackbarError('Error adding files. Please try again'),
    });
  };

  const fileImportSuccess = files => {
    removeItemsStagedForDeletion();

    addCharacteristicItems(
      characteristicId,
      instructionId,
      types,
      details
    )(files);
  };

  const removeItemsStagedForDeletion = () => {
    const characteristic_item_ids = itemsStagedForDeletion.filter(x => !!x);
    const body = {
      characteristic_item_ids,
    };

    const url = `knowledge_base/${instructionId}/characteristic/${characteristicId}/item/delete`;

    if (characteristic_item_ids.length) doRemoveCharacteristicItem(body, url);
  };

  const addCharacteristicItems =
    (characteristicId, instructionId, types, details) => files => {
      const items_ = files.map((file, index) => ({
        [knowledgeBaseKeys.imageUrl]: URL.createObjectURL(file.file),
        [knowledgeBaseKeys.characteristicId]: characteristicId,
        image: file.uploadName,
        type: types[index + items.length],
        detail: details[index + items.length],
      }));
      const body = {
        items: items_,
        [knowledgeBaseKeys.characteristicId]: characteristicId,
        instructionId,
      };
      const url = `knowledge_base/${instructionId}/characteristic/${characteristicId}/items`;
      if (items_.length) doAddCharacteristicItem(body, url);
    };

  const onDetailChange = index => e => {
    const { value } = e.target;
    setDetails(x => {
      x.splice(index, 1, value);
      return x;
    });
  };

  const onRemoveItem = index => {
    const update = x => {
      const removed = x.splice(index, 1)[0];
      setItemsStagedForDeletion(y => [
        ...y,
        removed?.[knowledgeBaseKeys.characteristicItemId],
      ]);
      return cloneDeep(x);
    };
    const simpleUpdate = x => {
      x.splice(index, 1);
      return x;
    };
    setItems(update);
    setTypes(simpleUpdate);
    setDetails(simpleUpdate);
  };

  const updateItemInfoAmounts = ({ files }) => {
    // TODO: update() and types() can be in utils.js file
    const update = x => {
      const difference = files.length + items.length - x.length;
      if (difference > 0) [...Array(difference)].forEach((_, i) => x.push(''));
      if (difference < 0) x.splice(x.length - 1 - difference);
      return x;
    };

    const types = x =>
      x.map(x => (x === '' ? authenticityBadgeValues['non-counterfeit'] : x));

    setTypes(x => types(update(x)));
    setDetails(update);
  };

  const checkMissingFiles = ({ files }) =>
    setMissingFiles(files.some(file => file == null));

  const updateFilesLoading = ({ isUploading }) =>
    setFilesUploading(isUploading);

  const onFileStateUpdate = fileState => {
    updateItemInfoAmounts(fileState);
    checkMissingFiles(fileState);
    updateFilesLoading(fileState);
  };

  const selectAuthenticity = index => option => () => {
    const update = x => {
      // prevX !== newX to trigger useState re-render
      x = cloneDeep(x);
      x[index] = option.value;
      return x;
    };
    setTypes(update);
  };

  const getSelectedOption = index =>
    authenticityBadgeMenuOptions.find(x => x.value === types[index]) ??
    authenticityBadgeMenuOptions[0];

  const showConfirmDelete = () => {
    const onConfirm = deleteCharacteristic;
    showModal(
      ConfirmModal,
      {
        title: 'Delete characteristic',
        description: parseStringModifiers(
          `Are you sure you want to delete this characteristic?\n\n**This action cannot be undone**`
        ),
        onCancel: dismiss,
        onConfirm,
        confirm: 'Delete',
      },
      { stack: true, keepPrev: true }
    );
  };

  const deleteCharacteristic = () => {
    const url = `knowledge_base/${instructionId}/characteristic/${characteristicId}`;
    doRemoveCharacteristic({ url });
    dismiss();
  };

  return (
    <Formik
      initialValues={getCharacteristicInitialValues(details)}
      validationSchema={getCharacteristicValidationSchema(details)}
      enableReinitialize
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form>
            <CharacteristicEditLayout
              onPrimary={handleSave}
              onDelete={showConfirmDelete}
              assignHandleImport={assignHandleImport}
              onRemoveItem={onRemoveItem}
              onFileStateUpdate={onFileStateUpdate}
              onDetailChange={onDetailChange}
              disabled={
                !isValid ||
                missingFiles ||
                somethingIsLoading ||
                (prevItems.length === types.length &&
                  prevItems.length === items.length)
              }
              isLoading={somethingIsLoading}
              filesUploading={filesUploading}
              // Authenticity dropdown
              authenticityBadgeMenuOptions={authenticityBadgeMenuOptions}
              authenticityBadgeColors={authenticityBadgeColors}
              authenticityBadgeIcons={authenticityBadgeIcons}
              selectOption={selectAuthenticity}
              getSelectedOption={getSelectedOption}
              characteristicType={instructionTypeLabels[characteristicType]}
              onCancel={() => dismissAll()}
              //   new edit stuff
              items={items}
              types={types}
              details={details}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Modal(CharactersticEdit);
