import { useState } from 'react';
import { Card, Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import AnimatedIcon from '../../../../../../components/molecules/AnimatedIcon/AnimatedIcon';
import Avatar from '../../../../../../components/molecules/Avatar/Avatar';
import Button from '../../../../../../components/molecules/Button/Button';
import ContractedBrandName from '../../../../../../components/molecules/ContractedBrandName/ContractedBrandName';
import InfoIcon from '../../../../../../components/molecules/InfoIcon/InfoIcon';
import { Input } from '../../../../../../components/molecules/Input/Input';
import { Toggle } from '../../../../../../components/molecules/Toggle/Toggle';
import {
  DotCompleted,
  JoinedStrings,
  RowDateHorizontal,
  RowSimpleText,
  Space,
  TableContractedBrandName,
  User,
} from '../../../../../../components/organisms/Table/Table.exports';
import { Colors, Size } from '../../../../../../components/style';
import { Constants } from '../../../../../../constants';
import { usePermissions } from '../../../../../../providers/PermissionProvider/PermissionsProvider';
import { DateTypes, formatDate } from '../../../../../../utils/timeUtils';
import { knowledgeBaseKeys, withURLBase } from '../../controllers/variables';
import {
  DetailsSectionSubttle,
  DetailsText,
  InstructionCustomerType,
  InstructionIcon,
  InstructionTypeDescription,
  InstructionTypeTitle,
  VisibilityState,
} from '../atoms/atoms';
import {
  CharacteristicCardCSS,
  DownloadTag,
  InstructionDescriptionFieldsCSS,
  InstructionLinks,
  InstructionTypeCSS,
  SectionItem,
} from '../style';

export const tableComponents = {
  [knowledgeBaseKeys.leftPadding]: { display: Space },
  [knowledgeBaseKeys.customer.self]: {
    display: TableContractedBrandName(
      knowledgeBaseKeys.customer.brand,
      knowledgeBaseKeys.customer.isContracted
    ),
  },
  [knowledgeBaseKeys.name]: {
    display: RowSimpleText(knowledgeBaseKeys.name, 'bold', true),
  },
  [knowledgeBaseKeys.productCategory]: {
    display: JoinedStrings(
      knowledgeBaseKeys.productSubCategory,
      knowledgeBaseKeys.productCategory
    ),
  },
  [knowledgeBaseKeys.date]: {
    display: RowDateHorizontal(knowledgeBaseKeys.date),
  },
  [knowledgeBaseKeys.characteristicsCount]: {
    display: RowSimpleText(knowledgeBaseKeys.characteristicsCount, 'bold'),
  },
  [knowledgeBaseKeys.addedBy]: {
    display: User(
      knowledgeBaseKeys.addedByName,
      knowledgeBaseKeys.addedByImage,
      { bold: false }
    ),
  },
  [knowledgeBaseKeys.visibility]: {
    display: DotCompleted(knowledgeBaseKeys.visibility),
  },
};

export const InstructionDescriptionFields = ({
  fields,
  selectOption,
  setTouched,
  focus,
  onFocus,
}) => {
  return (
    <InstructionDescriptionFieldsCSS>
      {fields.map((field, index) => (
        <Input
          {...field}
          selectOption={selectOption}
          setTouched={setTouched}
          autoFocus={index === 2 && focus}
          onFocus={onFocus}
          key={index}
        />
      ))}
    </InstructionDescriptionFieldsCSS>
  );
};

export const CreationDate = ({ date }) => {
  const dateText = date
    ? formatDate(date, DateTypes.date) +
      Constants.fallback +
      formatDate(date, DateTypes.time12)
    : Constants.fallback;
  return (
    <Flex column gap="_2XS">
      <DetailsSectionSubttle text={'CREATION DATE'} />
      <DetailsText text={dateText} />
    </Flex>
  );
};

export const AddedBy = ({ username, image }) => {
  return (
    <Flex column gap="_2XS">
      <DetailsSectionSubttle text={'ADDED BY'} />
      <Flex gap="_XS" align="center">
        <Avatar username={username} image={image} size="_S" />
        <DetailsText text={username} />
      </Flex>
    </Flex>
  );
};

export const Customer = ({ customer }) => {
  const {
    [knowledgeBaseKeys.customer.image]: image,
    [knowledgeBaseKeys.customer.brand]: brand,
    [knowledgeBaseKeys.customer.isContracted]: isContracted,
    [knowledgeBaseKeys.customer.type]: type,
  } = customer;

  return (
    <Flex column gap="_2XS">
      <DetailsSectionSubttle text={'CUSTOMER'} />
      <Flex gap="_XS">
        <Avatar image={image} username={'brand'} size={'_S'} border />
        <Flex column gap="_3XS">
          <ContractedBrandName brand={brand} isContracted={isContracted} />
          <InstructionCustomerType text={type} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const Visibility = ({ visibility, toggleVisibility }) => {
  const text = visibility ? 'Shown to brand' : 'Hidden from brand';

  return (
    <Flex column gap="_2XS">
      <DetailsSectionSubttle text={'VISIBILITY'} />
      <Flex gap="_XS" align="center" onClick={toggleVisibility}>
        <Toggle active={visibility} size={'_XS'} />
        <VisibilityState text={text} />
      </Flex>
    </Flex>
  );
};

export const Link = ({ link, handleEdit, hasEditPermission }) => {
  const [active, setActive] = useState(false);
  const { [knowledgeBaseKeys.link]: url } = link;
  return (
    <InstructionLinks
      justify="between"
      gap="_2XS"
      onPointerOver={() => setActive(true)}
      onPointerOut={() => setActive(false)}
    >
      <DownloadTag href={withURLBase(url)} target="_blank">
        <SectionItem>
          <Icon
            name={Icons.Link}
            size={Size.Icon._M}
            color={Colors.Neutral._900}
          />
          <DetailsText text={url} />
        </SectionItem>
      </DownloadTag>
      {hasEditPermission && (
        <AnimatedIcon
          name={Icons.Edit3}
          size={Size.Icon._M}
          theme="hidden_neutral"
          active={active}
          onClick={handleEdit(link)}
        />
      )}
    </InstructionLinks>
  );
};

export const AddCharacteristicsButton = ({ onClick }) => {
  return (
    <Flex w justify="center">
      <Button
        onClick={onClick}
        leftIcon={Icons.PlusCircle}
        text="Add more characteristics"
        size="_M"
        variant="Tertiary"
      />
    </Flex>
  );
};

export const InstructionType = ({ type, onClick, selected }) => {
  const { icon, label, description } = type;
  return (
    <InstructionTypeCSS onClick={onClick} selected={selected}>
      <InstructionIcon name={icon} />
      <Flex column h grow justify="between">
        <InstructionTypeTitle text={label} />
        <InstructionTypeDescription text={description} />
      </Flex>
    </InstructionTypeCSS>
  );
};

export const CharacteristicCard = ({
  children,
  title,
  infoIconDescription,
  showEditCharacteristic,
}) => {
  return (
    <CharacteristicCardCSS>
      <Card
        title={title}
        icon={
          <CharacteristicCornerIcons
            infoIconDescription={infoIconDescription}
            showEditCharacteristic={showEditCharacteristic}
          />
        }
      >
        <Flex w h relative column>
          {children}
        </Flex>
      </Card>
    </CharacteristicCardCSS>
  );
};

export const CharacteristicCornerIcons = ({
  showEditCharacteristic,
  infoIconDescription,
}) => {
  const { hasPermission } = usePermissions();
  return (
    <Flex align="center" gap="_XS">
      {true && (
        <AnimatedIcon
          onClick={showEditCharacteristic}
          theme="neutral"
          name={Icons.Edit3}
        />
      )}
      <InfoIcon description={infoIconDescription} />
    </Flex>
  );
};
