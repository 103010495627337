import { Flex } from '../../../../../components/atoms/Flex/Flex';
import Button from '../../../../../components/molecules/Button/Button';
import Instruction from '../../../components/templates/Layout/Instruction/Instruction';
import { processingKeys } from '../controllers/variables';

export const ProcessingInstructionLayout = ({
  isLoading,
  details,
  updatedConfirm,
  functions,
  confirmationAction,
  showGallery,
}) => {
  return (
    <Instruction
      isLoading={isLoading}
      details={details}
      confirmationAction={confirmationAction}
      showGallery={showGallery}
      updatedConfirm={updatedConfirm}
      headerElement={
        <Flex gap="_S">
          {(details?.[processingKeys.situation.headerButtons] ?? []).map(
            (button, index) => {
              const { actionKey, descText, ...warningSafeButton } = button;

              return (
                <Button
                  {...warningSafeButton}
                  size="_M"
                  onClick={functions[button.actionKey]}
                  key={index}
                />
              );
            }
          )}
        </Flex>
      }
    />
  );
};
