import { Constants } from '../../../constants';
import useFetchData from '../../../hooks/fetchHook';
import { DateTypes, formatDate } from '../../../utils/timeUtils';

export const useGetAnnouncements = params => {
  const [
    { data = [], isLoading, isError, error },
    _,
    __,
    refetchAnnouncements,
  ] = useFetchData('announcements', {
    formatter,
    failureFormatter: () => [],
    params,
  });

  return { data, isLoading, isError, error, refetchAnnouncements };
};

const formatAnnouncementDate = date => {
  const day = formatDate(date, DateTypes.short);
  const year = formatDate(date, DateTypes.year);
  if (!day || !year) return Constants.fallback;
  return `${day}, ${year}`;
};

const formatter = data => {
  const announcements = data.map(announcement => {
    const {
      annIcon: icon,
      anndStatus: read,
      annID: id,
      annTitle: title,
      createdAt: _date,
      anndLink: link,
      annType: type,
      annContent: content,
    } = announcement;

    const date = formatAnnouncementDate(_date);

    return { icon, read, id, title, date, link };
  });

  return announcements;
};
