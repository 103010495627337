import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import Spacer from '../../../../../../components/atoms/Spacer/Spacer';
import Actions from '../../../../../../components/organisms/Actions/Actions';
import { Popup } from '../../../../../../components/templates/Popup';
import { Content } from '../../../../../../components/templates/Popup/Popup.style';
import { FormSectionTitle } from '../atoms';
import { AuthorityEditFields, AuthorityOptionsSelect } from '../organisms';

export const EditAuthorityLayout = ({
  title,
  onCancel,
  isValid,
  isLoading,
  authorities,
  selectOption,
}) => {
  return (
    <Popup size="_M" title={title}>
      <Content>
        <Flex w h column gap="_S">
          <Spacer />
          <FormSectionTitle text={'Authority Info'} />
          <AuthorityOptionsSelect
            options={authorities}
            selectOption={selectOption}
          />
          <AuthorityEditFields />
          <Actions
            onCancel={onCancel}
            disabled={!isValid || isLoading}
            isLoading={isLoading}
          />
        </Flex>
      </Content>
    </Popup>
  );
};
