import styled from '@emotion/styled';
import { Styles } from '../../../constants';
import { Flex } from '../../atoms/Flex/Flex';
import { Colors, Effects, Layout } from '../../style';

export const TabsContainer = styled(Flex)`
  position: sticky;
  top: 0;
  background: ${Colors.Sharp.White};
  z-index: 1;
`;

export const TabCSS = styled.div`
  cursor: pointer;
`;

export const TabCountContainer = styled.div`
  height: 20px;
  background-color: ${({ selected }) =>
    selected ? Colors.Primary._600 : Colors.Neutral._200};
  padding: 0 ${Layout.Spacing._2XS};
  min-width: ${Layout.Spacing._M};
  border-radius: ${Effects.Border_Radius._2XL};
  ${Styles.flexCenter};
`;
