import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableLoader } from '../../molecules/LoadingAnimation/LoadingAnimation.exports';
import { Actions, Description } from './components';
import { Content, Illustration, ModalContainer } from './style';

const NewFeature = ({ slides = [], link, CTATitle, isLoading, dismiss }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const navigate = useNavigate();

  const handleFinish = () => {
    if (link) {
      const to = link;
      navigate(to);
    }
    dismiss();
  };

  const stepTo = step => () => {
    step = Math.max(0, Math.min(slideIndex + step, slides.length - 1));
    setSlideIndex(step);
  };

  const slide = slides[slideIndex] ?? {};
  const { title, description, image } = slide;

  const isFirst = slideIndex === 0;
  const isLast = slideIndex === slides.length - 1;

  return (
    <ModalContainer size="_M">
      <Content column w h>
        {isLoading ? (
          <TableLoader />
        ) : (
          <>
            <Illustration src={image} />
            <Description title={title} content={description} />
            <Actions
              step={slideIndex}
              steps={slides.length}
              stepTo={stepTo}
              skip={dismiss}
              isFirst={isFirst}
              isLast={isLast}
              CTATitle={CTATitle}
              handleFinish={handleFinish}
            />
          </>
        )}
      </Content>
    </ModalContainer>
  );
};

export default NewFeature;
