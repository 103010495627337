import styled from '@emotion/styled';
import { Flex } from '../../../../../components/atoms/Flex/Flex';
import { Table } from '../../../../../components/organisms/Table/Table';
import { Colors, Layout } from '../../../../../components/style';
import { Styles } from '../../../../../constants';

export const OverviewLayoutCSS = styled.div`
  padding-top: ${Layout.Spacing._L};
  height: 100%;
  .preview-gallery {
    margin-top: unset;
    margin-bottom: auto;
  }
  .recent-activity {
    flex-grow: 1;
  }
`;

export const ParcelSummaryCSS = styled.div`
  position: relative;
  .clear-fields {
    position: absolute;
    left: ${Layout.Spacing._M};
    bottom: ${Layout.Spacing._S};
  }
  .parcel-summary-content {
    grid-column: 1 / 13;
  }
  .popup-header .separator {
    display: none;
  }
`;

export const TabsContainer = styled(Flex)`
  position: sticky;
  top: 0;
  background: ${Colors.Sharp.White};
  z-index: 1;
`;

export const TabCSS = styled.div`
  cursor: pointer;
`;

export const DetailsCSS = styled.div`
  gap: 16px;
  height: 100%;
  align-items: start;
  width: 0;
  flex-grow: 1;
  padding-right: ${Layout.Spacing._S};
  ${Styles.flexColumn};
  .status-badge {
    margin-top: auto;
  }
`;

export const DetailCSS = styled(Flex)`
  min-height: 20px;
  width: 100%;
  span {
    height: 20px;
  }
`;

export const StatusDescriptionCSS = styled.div`
  height: 244px;
  .card {
    width: 100%;
    height: 100%;
    padding-left: ${Layout.Spacing._M};
  }
`;

export const DetailsLayoutCSS = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${Layout.Spacing._L};
  .barcodes {
    .table-cell,
    .table-header {
      &._0 {
        padding-left: 32px;
      }
    }
    .add-barcodes-icon {
      z-index: 1;
      > div:first-of-type {
        top: unset;
        right: 0;
      }
    }
  }
`;

export const AddBarcodeFormCSS = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + ${Layout.Spacing._S});
  width: 328px;
  .actions {
    margin-top: ${Layout.Spacing._2XS};
  }
`;

export const ReferenceLayoutCSS = styled(Flex)`
  .filters {
    > div {
      max-width: unset !important;
    }
  }
`;

export const FilesTableContainer = styled(Table)`
  margin-top: ${Layout.Spacing._M};
  .header-row,
  .table-row {
    ._0 {
      padding-left: 32px;
    }
  }
  .table-cell._1 {
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }
`;
