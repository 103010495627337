import { Icon } from '../../../../../../components/atoms';
import { Flex } from '../../../../../../components/atoms/Flex/Flex';
import { Icons } from '../../../../../../components/atoms/Icon/Icon.options';
import Avatar from '../../../../../../components/molecules/Avatar/Avatar';
import Button from '../../../../../../components/molecules/Button/Button';
import { TableLoader } from '../../../../../../components/molecules/LoadingAnimation/LoadingAnimation.exports';
import TicketTypeBadge from '../../../../../../components/molecules/TicketTypeBadge/TicketTypeBadge';
import { Colors, Layout, Size } from '../../../../../../components/style';
import NoSearchResults from '../../../../../../components/templates/NoSearchResults/NoSearchResults';
import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import { defaultNoActivity } from '../../../../../../components/templates/TableStatus/variables';
import { SectionTitle } from '../../../../features_public/support/components/atoms';
import { SectionOptions } from '../../../../features_public/support/components/molecules';
import Ticket from '../../controllers/Ticket';
import {
  FacilitatorDetailsContainer,
  MessagesCSS,
  RecentActivityCSS,
} from '../../style';
import {
  FacilitatorDetailsTitle,
  RecentActivityTitle,
  SectionSubtitle,
  SubsectionText,
  SubsectionTitle,
  TicketSubitleText,
  TicketTitleText,
} from '../atoms/atoms';
import {
  Activity,
  AwaitingResponse,
  BadgeDropdown,
  Badges,
  InvolvedUserAvatar,
  Message,
  TicketActions,
  TicketAliasAndTime,
  TicketCreator,
} from '../molecules/molecules';

export const Tickets = ({
  tickets,
  sort,
  ticketsRef,
  isLoading,
  isError,
  error,
  tableStatusProps,
}) => {
  const { searchParams = {} } = tableStatusProps ?? {};
  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      className="tickets"
      table={
        <Flex
          column
          gap="_XS"
          ref__={ticketsRef}
          className="tickets ref"
          scroll
          padding={`${Layout.Spacing._XS} 0`}
        >
          {tickets.map(ticket => (
            <Ticket
              ticket={ticket}
              sort={sort}
              searchParams={searchParams}
              key={ticket.ticketId}
              isLoading={isLoading}
            />
          ))}
          <div />
        </Flex>
      }
    />
  );
};

export const TicketMetadata = ({
  alias,
  showDateCreated,
  dateCreated,
  showLastMessage,
  lastMessage,
  messageCount,
  read,
  showOptions,
  shouldShowOptions,
  markReadUnread,
  ticketActionsRef,
  type,
}) => {
  return (
    <Flex justify="between">
      <Flex gap="_XS" align="center">
        <TicketTypeBadge type={type} size="_S" />
        <TicketAliasAndTime
          alias={alias}
          showDateCreated={showDateCreated}
          dateCreated={dateCreated}
          showLastMessage={showLastMessage}
          lastMessage={lastMessage}
          read={read}
        />
      </Flex>
      <TicketActions
        messageCount={messageCount}
        read={read}
        showOptions={showOptions}
        shouldShowOptions={shouldShowOptions}
        markReadUnread={markReadUnread}
        ticketActionsRef={ticketActionsRef}
      />
    </Flex>
  );
};

export const TicketLabel = ({ mainTopic, description }) => {
  return (
    <Flex column gap="_2XS" className="ticket-label">
      <TicketTitleText text={mainTopic} />
      <TicketSubitleText text={description} />
    </Flex>
  );
};

export const TicketOtherDetails = ({ image, name, priority, status }) => {
  return (
    <Flex justify="between">
      <TicketCreator image={image} name={name} />
      <Badges priority={priority} status={status} />
    </Flex>
  );
};

export const RecentActivity = ({
  activity,
  handleNotificationClick,
  activityRef,
  loadMoreActivity,
  moreActivityClicked,
  isLoading,
  isError,
  ticketLeft,
}) => {
  return (
    <RecentActivityCSS className="recent-activity" left={ticketLeft}>
      <RecentActivityTitle text="Recent activity" />
      {isLoading && !moreActivityClicked ? (
        <TableLoader />
      ) : activity == null || activity.length === 0 ? (
        <NoSearchResults {...defaultNoActivity} />
      ) : (
        <Activity
          activity={activity}
          handleNotificationClick={handleNotificationClick}
          activityRef={activityRef}
          isLoading={isLoading}
          isError={isError}
          moreActivityClicked={moreActivityClicked}
          showClickable
        />
      )}
      {!moreActivityClicked && !isLoading && activity && !!activity.length && (
        <Button
          variant="Secondary"
          text="Show more"
          fullWidth
          size="_M"
          onClick={loadMoreActivity}
        />
      )}
    </RecentActivityCSS>
  );
};

// TODO: call this from the public molecule
export const StatusAndPriority = ({
  statusBadge,
  priorityBadge,
  hasEditPermission,
}) => {
  return (
    <Flex column gap="_M">
      <BadgeDropdown
        badge={statusBadge}
        hasEditPermission={hasEditPermission}
      />
      <BadgeDropdown
        badge={priorityBadge}
        hasEditPermission={hasEditPermission}
      />
    </Flex>
  );
};

export const UsersInvolved = ({ usersInvolved }) => {
  return (
    <Flex column gap="_2XS">
      <SubsectionTitle text="PEOPLE IN THE CONVERSATION" />
      <Flex>
        {usersInvolved?.map((user, index) => {
          return <InvolvedUserAvatar user={user} index={index} key={index} />;
        })}
      </Flex>
    </Flex>
  );
};

export const Messages = ({
  messages,
  awaitingResponseMessage,
  conversationRef,
  hasProduct,
}) => {
  return (
    <MessagesCSS hasProduct={hasProduct}>
      {
        <Flex h column scroll gap="_XS" ref__={conversationRef}>
          {messages.map(message => {
            return (
              <Message message={message} key={message.message + message.date} />
            );
          })}
          <div></div>
          {!!messages.length && (
            <AwaitingResponse text={awaitingResponseMessage} />
          )}
        </Flex>
      }
    </MessagesCSS>
  );
};

export const FacilitatorDetails = ({
  brandImage,
  brandName,
  isLogisticsClient,
}) => {
  const customerDetailsText =
    isLogisticsClient == null
      ? null
      : isLogisticsClient
      ? 'Logistics customer'
      : 'Brand customer';
  return (
    <FacilitatorDetailsContainer>
      <FacilitatorDetailsTitle text={'Customer details'} />
      <Flex gap="_S" align="center">
        <Avatar image={brandImage} size="_L" username={brandName} />
        <Flex column>
          {/* wed may 20th, 20203 1:20m (UTC + 2) */}
          {/* this icon is hardcoded because all brands are contracted. we'll see how long until someone notices  */}
          {/* Bets */}
          {/* Onur: 0 - 3 months */}
          {/* Luisan: 3 - 6 months */}
          <Flex gap="_2XS" align="center">
            <SubsectionText text={brandName} />
            {brandName && (
              <Icon
                name={Icons.CC_CheckCircle}
                size={Size.Icon._XS}
                color={Colors.Primary._600}
              />
            )}
          </Flex>
          <SubsectionText text={customerDetailsText} regular />
        </Flex>
      </Flex>
    </FacilitatorDetailsContainer>
  );
};

export const HelpType = ({
  selectHelpType,
  chosenHelptype,
  title,
  subtitle,
  options,
}) => {
  return (
    <Flex column gap="_XS" align="start">
      <SectionTitle text={title} />
      <Flex gap="_2XS" column>
        <SectionSubtitle text={subtitle} />
        <div />
        <div />
      </Flex>
      <SectionOptions
        options={options}
        selectOption={selectHelpType}
        chosenOption={chosenHelptype}
      />
    </Flex>
  );
};
