import TableStatus from '../../../../../../components/templates/TableStatus/TableStatus';
import {
  PermissionKeys,
  usePermissions,
} from '../../../../../../providers/PermissionProvider/PermissionsProvider';
import { ProductsTable } from '../organisms';

const { SELF, VIEW_PRODUCTS } = PermissionKeys.Brand.PRODUCTS;

const ProductsLayout = ({
  headers,
  data,
  grid,
  rowAction,
  isLoading,
  isError,
  error,
  tableStatusProps,
  showGallery,
}) => {
  const { hasPermission: _ } = usePermissions();
  const hasPermission = permission => _(SELF, permission);

  return (
    <TableStatus
      isLoading={isLoading}
      isError={isError}
      error={error}
      {...tableStatusProps}
      table={
        hasPermission(VIEW_PRODUCTS) && (
          <ProductsTable
            headers={headers}
            data={data}
            rowAction={rowAction}
            grid={grid}
            showGallery={showGallery}
          />
        )
      }
    />
  );
};
export default ProductsLayout;
