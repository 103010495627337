import { Icons } from '../../../../components/atoms/Icon/Icon.options';
import { timeFrameInitialFilterValues } from '../../../../components/organisms/Filters/utils';
import { FilterTypes } from '../../../../components/organisms/Filters/variables';
import { Constants, DISPATCH_CONSTANTS } from '../../../../constants';
import { NavURIs, Pages } from '../../routes/variables';

export const dashboardKeys = {
  allCases: 'allCases',
  allAuthentications: 'allAuthentications',
  brandName: 'brandName',
  activityLogDate: 'activityLogDate',
  caseId: 'caseId',
  caseAlias: 'caseAlias',
  casesReleased: 'casesReleased',
  casesSeized: 'casesSeized',
  caseStatus: 'caseStatus',
  casesTotal: 'casesTotal',
  day: 'day',
  month: 'month',
  parcelCount: 'parcelCount',
  productCount: 'productCount',
  referenceAddress: 'referenceAddress',
  referenceCompany: 'referenceCompany',
  referenceCount: 'referenceCount',
  referenceCountry: 'referenceCountry',
  referenceName: 'referenceName',
  sorter: 'sorter',
  sorterCity: 'sorterCity',
  sorterCountry: 'sorterCountry',
  year: 'year',
  completeCount: Constants.Status.AuthenticationCompleted,
  inspectionCount: Constants.Status.Inspection,
  authenticationCount: Constants.Status.Authentication,
  counterfeitCount: Constants.Status.Counterfeit,
  authenticCount: Constants.Status['Non-counterfeit'],
  stoppedCount: Constants.Status.Stopped,
  releasedCount: Constants.Status.Released,
  seizedCount: Constants.Status.Seized,
  pendingCount: Constants.Status.Authentication,
};

export const components = {
  overview: 'overview',
  populars: 'populars',
  timeline: 'timeline',
  origins: 'origins',
  categories: 'categories',
};

export const infoDescription = {
  [components.overview]:
    'This section shows all lifetime worldwide statistics.',
  [components.populars]:
    'This section shows Inspections, Stops, and Seizures by most popular locations.',
  [components.timeline]:
    'This section shows Inspections, Stops, and Seizures over time.',
  [components.origins]: 'This section shows the top countries of references.',
  [components.categories]:
    'This section shows the top categories of stopped and seized items.',
  [components.activity]: 'This section shows recent case developments.',
};

export const DC = { ...DISPATCH_CONSTANTS };

export const allOptions = undefined;

export const filtersConstants = {
  [DC.TIME_FRAME]: {
    type: FilterTypes.calendar,
    icon: Icons.Calendar,
    param: 'timeFrame',
    initial: timeFrameInitialFilterValues.last28days,
  },
  [DC.REFRESH]: {
    type: FilterTypes.button,
    icon: Icons.RotateCw,
  },
};

export const overviewReferenceStats = [dashboardKeys.referenceCount];

export const overviewProductStats = [
  dashboardKeys.casesTotal,
  dashboardKeys.releasedCount,
  dashboardKeys.seizedCount,
];

export const overviewTitles = {
  [dashboardKeys.referenceCount]: 'References',
  [dashboardKeys.casesTotal]: 'All products',
  [dashboardKeys.releasedCount]: 'Releases',
  [dashboardKeys.seizedCount]: 'Seizures',
};

export const overviewDestination = {
  [dashboardKeys.referenceCount]: NavURIs[Pages.reference_index],
  [dashboardKeys.casesTotal]: '',
  [dashboardKeys.releasedCount]: `?status=${Constants.Status.Released}`,
  [dashboardKeys.seizedCount]: `?status=${Constants.Status.Seized}`,
};

export const authenticationStats = [
  dashboardKeys.pendingCount,
  dashboardKeys.completeCount,
];

export const authenticationTitles = {
  [dashboardKeys.pendingCount]: 'Authentication needed',
  [dashboardKeys.completeCount]: 'Authentication completed',
};

export const authenticationDestination = {
  [dashboardKeys.pendingCount]: `?activeTab=required`,
  // [dashboardKeys.pendingCount]: `?status=${Constants.Status.Authentication}`,
  [dashboardKeys.completeCount]: `?activeTab=completed`,
  // [dashboardKeys.completeCount]: `?status=${[
  //   Constants.Status.Counterfeit,
  //   Constants.Status['Non-counterfeit'],
  // ].join('+')}`,
};

export const navToEvalCasesButton = {
  text: 'View all',
  variant: 'Tertiary',
  size: '_S',
  rightIcon: Icons.ChevronRight,
};

export const parcelStatuses = [
  dashboardKeys.inspectionCount,
  dashboardKeys.authenticationCount,
  dashboardKeys.counterfeitCount,
  dashboardKeys.authenticCount,
  dashboardKeys.stoppedCount,
  dashboardKeys.seizedCount,
  dashboardKeys.releasedCount,
];

export const parcelStatusesDestination = {
  [dashboardKeys.inspectionCount]: `?status=${Constants.Status.Inspection}`,
  [dashboardKeys.authenticationCount]: `?status=${Constants.Status.Authentication}`,
  [dashboardKeys.counterfeitCount]: `?status=${Constants.Status.Counterfeit}`,
  [dashboardKeys.authenticCount]: `?status=${Constants.Status['Non-counterfeit']}`,
  [dashboardKeys.stoppedCount]: `?status=${Constants.Status.Stopped}`,
  [dashboardKeys.seizedCount]: `?status=${Constants.Status.Seized}`,
  [dashboardKeys.releasedCount]: `?status=${Constants.Status.Released}`,
};

export const statusNoun = {
  [Constants.Status.Sideload]: 'Sideload',
  [Constants.Status.Inspection]: 'Inspection',
  [Constants.Status.Authentication]: 'Authentication',
  [Constants.Status.Counterfeit]: 'Counterfeit',
  [Constants.Status['Non-counterfeit']]: 'Non-counterfeit',
  [Constants.Status.Stopped]: 'Stop',
  [Constants.Status.Seized]: 'Seizure',
  [Constants.Status.Released]: 'Release',
};

export const titles = {
  [DC.TIME_FRAME]: 'Last 28 days',
};

export const mockTimeline = [
  { Inspections: 100, Stops: 80, Seizures: 130 },
  { Inspections: 110, Stops: 88, Seizures: 143.3 },
  { Inspections: 138, Stops: 110, Seizures: 179 },
  { Inspections: 173, Stops: 138, Seizures: 224 },
  { Inspections: 216, Stops: 173, Seizures: 280 },
  { Inspections: 270, Stops: 216, Seizures: 350 },
  { Inspections: 338, Stops: 270, Seizures: 438 },
  { Inspections: 423, Stops: 338, Seizures: 548 },
  { Inspections: 529, Stops: 423, Seizures: 685 },
  { Inspections: 661, Stops: 529, Seizures: 856 },
  { Inspections: 826, Stops: 661, Seizures: 1070 },
  { Inspections: 1033, Stops: 826, Seizures: 1338 },
  { Inspections: 1291, Stops: 1033, Seizures: 1673 },
  { Inspections: 1614, Stops: 1291, Seizures: 2091 },
  { Inspections: 2018, Stops: 1614, Seizures: 2614 },
  { Inspections: 2523, Stops: 2018, Seizures: 3268 },
  { Inspections: 3154, Stops: 2523, Seizures: 4085 },
  { Inspections: 3943, Stops: 3154, Seizures: 5106 },
  { Inspections: 4929, Stops: 3943, Seizures: 6383 },
  { Inspections: 6161, Stops: 4929, Seizures: 7979 },
  { Inspections: 7701, Stops: 6161, Seizures: 9974 },
  { Inspections: 9626, Stops: 7701, Seizures: 12468 },
  { Inspections: 12033, Stops: 9626, Seizures: 15585 },
  { Inspections: 15041, Stops: 12033, Seizures: 19481 },
  { Inspections: 18801, Stops: 15041, Seizures: 24351 },
  { Inspections: 23501, Stops: 18801, Seizures: 30439 },
  { Inspections: 29376, Stops: 23501, Seizures: 38049 },
  { Inspections: 36720, Stops: 29376, Seizures: 47561 },
  { Inspections: 45900, Stops: 36720, Seizures: 59451 },
  { Inspections: 57375, Stops: 45900, Seizures: 74314 },
  { Inspections: 71719, Stops: 57375, Seizures: 92893 },
];
