import { useRef } from 'react';
import { useUI } from '../../../../providers/UIProvider';
import { Text } from '../../../atoms';
import Spacer from '../../../atoms/Spacer/Spacer';
import CopyableText from '../../../molecules/CopyableText/CopyableTitle';
import { FontKeys } from '../../../style';
import { GalleryContainer, Overlay, Title } from './atoms/atoms';
import { DisplayedImage } from './molecules/molecules';
import ThumbnailGallery, { Transformations } from './organisms/organisms';
import { Header } from './style';

const GalleryLayout = ({
  zoomRef,
  handleSelect,
  thumbnails,
  thumbnailGalleryType,
  image,
  noOfImages,
  rotation,
  maxZoom,
  imageIsLoading,
  getImagesLoading,
  selected,
  onLoad,
  rotate,
  zoom,
  download,
  dismiss,
  title,
  galleryAliasExtractor = () => {},
  showHeaderBackground,
}) => {
  const containerRef = useRef(null);
  const { sidebarWidth } = useUI().UI;

  return (
    <Overlay sidebarWidth={sidebarWidth}>
      <GalleryContainer
        containerRef={containerRef}
        onClick={e => e.stopPropagation()}
      >
        <Header showBackground={showHeaderBackground}>
          <Title
            text={
              <CopyableText
                text={title}
                copyText={galleryAliasExtractor(title)}
              />
            }
          />
          {noOfImages ? (
            <Text
              text={`${selected + 1}/${noOfImages}`}
              weight={FontKeys.Weight.Regular}
              variant={FontKeys.Variant.Paragraph}
              size={FontKeys.Size._M}
            />
          ) : (
            ''
          )}
          <Transformations
            rotate={rotate}
            zoom={zoom}
            download={download}
            dismiss={dismiss}
          />
        </Header>
        <Spacer height={'_XL'} />
        <DisplayedImage
          image={image}
          zoomRef={zoomRef}
          rotation={rotation}
          maxZoom={maxZoom}
          isLoading={imageIsLoading}
          onLoad={onLoad}
        />
        <Spacer height={'_L'} />
        <ThumbnailGallery
          images={thumbnails}
          handleSelect={handleSelect}
          selected={selected}
          type={thumbnailGalleryType}
          isLoading={getImagesLoading}
        />
      </GalleryContainer>
    </Overlay>
  );
};
export default GalleryLayout;
