import useDeleteData from '../../../../../hooks/deleteHook';
import { tabs } from '../controllers/variables';

const typeMap = {
  [tabs.services]: 'service',
  [tabs.users]: 'client',
  [tabs.contracts]: 'contract',
  [tabs.plans]: 'subscription_plan',
  [tabs.invoices]: 'invoice',
};
export const useDeleteItem = ({ id, type }) => {
  const [{ data, isLoading, isError, error }, doDeleteItem] = useDeleteData();

  const appendingItemId = itemId => {
    const url = `admin_brand/${id}/${typeMap[type]}/${itemId}`;

    doDeleteItem({ url });
  };

  return { data, isLoading, isError, error, doDeleteItem: appendingItemId };
};
