import { parseStringModifiers } from '../../../utils/utils';
import { Text } from '../../atoms';
import { FontKeys } from '../../style';

export const Description = ({ description }) => {
  description = parseStringModifiers(description);
  return (
    <Text
      text={description}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      alignment="center"
    />
  );
};
