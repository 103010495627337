import {
  Icons,
  Illustrations,
} from '../../../../../components/atoms/Icon/Icon.options';
import { FilterTypes } from '../../../../../components/organisms/Filters/variables';
import { Colors } from '../../../../../components/style';
import { DISPATCH_CONSTANTS } from '../../../../../constants';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { parcelKeys } from '../../../../admin/features_public/parcels/controllers/variables';

export const processingKeys = {
  alias: 'alias',
  barcode: 'barcode',
  brand: {
    id: 'id',
    isContracted: 'isContracted',
    image: 'image',
    name: 'name',
  },
  city: 'city',
  id: 'id',
  illustration: 'illustration',
  image: 'image',
  instructionTitle: 'instructionTitle',
  instructionSubtitle: 'instructionSubtitle',
  itemCount: 'itemCount',
  leftPadding: 'leftPadding',
  location: 'location',
  photos: 'photos',
  product: {
    action: 'action',
    alias: 'alias',
    brand: {
      name: 'name',
      id: 'id',
      isContracted: 'isContracted',
    },
    category: 'category',
    categoryId: 'categoryId',
    fullCategory: 'fullCategory',
    id: parcelKeys.product.id,
    itemCount: 'itemCount',
    image: 'image',
    photos: 'photos',
    status: 'status',
    subcategory: 'subcategory',
  },
  products: 'products',
  productCount: 'productCount',
  riskType: 'riskType',
  snapshotDetails: 'snapshotDetails',
  sideloadDate: 'sideloadDate',
  situation: {
    title: 'title',
    description: 'description',
    styleStatus: 'styleStatus',
    headerButtons: 'headerButtons',
    descriptionButtons: 'descriptionButtons',
    color: 'color',
    illustration: 'illustration',
  },
  situationCode: 'situationCode',
  sorter: 'sorter',
  status: 'status',
  statusColor: 'statusColor',
};

export const caseHeaders = {
  [processingKeys.leftPadding]: '',
  [processingKeys.image]: 'Scanned images',
  [processingKeys.alias]: 'Parcel ID',
  [processingKeys.sideloadDate]: 'Catch date',
  [processingKeys.itemCount]: 'Total items',
};

export const caseProductHeaders = {
  [processingKeys.leftPadding]: '',
  [processingKeys.product.image]: 'Product ID',
  [processingKeys.product.alias]: '',
  [processingKeys.product.brand.name]: 'Brand',
  [processingKeys.product.category]: 'Product category',
  [processingKeys.product.itemCount]: 'Items',
  [processingKeys.product.action]: '',
};

export const caseGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // image //
  300px 
  // case alias //
  minmax(130px , 0.75fr)
  // sideload date //
  minmax(136px , 1fr)
  // item count //
  minmax(62px , 0.5fr)
  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
  headerHeight: '40px',
};

export const caseProductGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // image //
  60px 
  // case alias //
  148px 
  // brand //
  minmax(120px , 0.75fr)
  // category //
  minmax(150px , 1fr)
  // item count //
  54px 
  // edit //
  64px  `),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
  headerHeight: '40px',
};

export const DC = {
  ...DISPATCH_CONSTANTS,
  ENTER_SEARCH: 'ENTER_SEARCH',
};

export const toggleOptions = [
  {
    leftIcon: Icons.PackageCheck,
    text: 'Check in',
    value: 'checkin',
  },
  {
    leftIcon: Icons.PackageSearch,
    text: 'Processing',
    value: 'processing',
  },
];

export const scanningModeTitle = {
  // isProcessing
  true: 'Parcel processing',
  // !isProcessing
  false: 'Parcel check in',
};

export const filtersConstants = {
  [DC.SEARCH]: {
    type: FilterTypes.search,
    placeholder: 'Scan a barcode',
    param: 'search',
    icon: Icons.ScanLine,
    round: true,
    alwaysFullWidth: true,
    size: '_L',
  },
};

export const allOptions = {};

export const instructionAssets = {
  Inspection: {
    [processingKeys.situation.illustration]:
      Illustrations.Processing_Inspection,
    [processingKeys.situation.color]: Colors.Primary._400,
  },
  Released: {
    [processingKeys.situation.illustration]: Illustrations.Processing_Release,
    [processingKeys.situation.color]: Colors.Success._500,
  },
  Authentication: {
    [processingKeys.situation.illustration]:
      Illustrations.Processing_Authentication,
    [processingKeys.situation.color]: Colors.Error._500,
  },
  Stopped: {
    [processingKeys.situation.illustration]: Illustrations.Processing_Stop,
    [processingKeys.situation.color]: Colors.Warning._500,
  },
  Seized: {
    [processingKeys.situation.illustration]: Illustrations.Processing_Seize,
    [processingKeys.situation.color]: Colors.Primary._600,
  },
  Unknown: {
    [processingKeys.situation.illustration]: Illustrations.Processing_Unknown,
    [processingKeys.situation.color]: Colors.Neutral._300,
  },
};

export const stepKeys = {
  details: 'details',
  selectBrand: 'selectBrand',
  category: 'category',
  itemQuantity: 'itemQuantity',
  photos: 'photos',
  summary: 'summary',
};

export const productEditSteps = [
  stepKeys.selectBrand,
  stepKeys.category,
  stepKeys.itemQuantity,
  stepKeys.photos,
  stepKeys.summary,
];

export const inspectionStep = {
  [stepKeys.details]: {
    title: () => 'Parcel inspection',
  },
  [stepKeys.selectBrand]: {
    title: isUpdatingProduct =>
      `${isUpdatingProduct ? 'Update' : 'Add'} product`,
  },
  [stepKeys.category]: {
    title: isUpdatingProduct =>
      `${isUpdatingProduct ? 'Update' : 'Add'} product`,
  },
  [stepKeys.itemQuantity]: {
    title: isUpdatingProduct =>
      `${isUpdatingProduct ? 'Update' : 'Add'} product`,
  },
  [stepKeys.photos]: {
    title: isUpdatingProduct =>
      `${isUpdatingProduct ? 'Update' : 'Add'} product`,
  },
  [stepKeys.summary]: {
    title: isUpdatingProduct =>
      `${isUpdatingProduct ? 'Update' : 'Add'} product`,
  },
};
