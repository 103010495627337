import { ModalSectionTitle } from '../../../../../../components/templates/StepPopup/style';
import { AddLayout } from './AddLayout';
import { Fields } from './organisms';

const sectionTitleAdd = 'Add the requested information to add a new category';
const sectionTitleEdit = 'Add the requested information to edit a category';

export const AddCategoryLayout = ({ isEditing, fieldProps, actionsProps }) => {
  return (
    <AddLayout
      title={isEditing ? 'Edit category' : 'Add category - New category'}
      actionsProps={actionsProps}
    >
      <ModalSectionTitle
        text={isEditing ? sectionTitleEdit : sectionTitleAdd}
      />
      <Fields {...fieldProps} />
    </AddLayout>
  );
};
